function $parcel$interopDefault(a) {
    return a && a.__esModule ? a.default : a;
}
function $parcel$defineInteropFlag(a) {
    Object.defineProperty(a, '__esModule', {
        value: true,
        configurable: true
    });
}
function $parcel$export(e, n, v, s) {
    Object.defineProperty(e, n, {
        get: v,
        set: s,
        enumerable: true,
        configurable: true
    });
}
var $parcel$global = globalThis;
var parcelRequire = $parcel$global["parcelRequire94c2"];
var parcelRegister = parcelRequire.register;
parcelRegister("9DfkH", function(module, exports) {
    $parcel$defineInteropFlag(module.exports);
    $parcel$export(module.exports, "AtlassianNavigation", ()=>$daeb815c48fa02e2$export$f66ccb39f60980e5);
    $parcel$export(module.exports, "default", ()=>$daeb815c48fa02e2$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $eCCin;
    var $AameC;
    var $5e7oM;
    var $azzrO;
    var $13iNl;
    var $4keKa;
    var $9WcgH;
    var $bcn6L;
    var $4R6GH;
    var $koVbs;
    var $jQsty;
    var $jOWa1;
    var $c0dQj;
    var $6cNb0;
    var $bBulW;
    var $jzLyJ;
    var $3HwlS;
    var $6jY6G;
    var $dAfqg;
    var $apeap;
    var $daoB1;
    var $koeKL;
    var $cZpsT;
    var $cNd4s;
    var $l0cYe;
    var $eoNiZ;
    var $6U0zt;
    var $6RKvu;
    var $jUhOq;
    var $96WTB;
    var $3bzLW;
    var $jSplz;
    var $crC7i;
    var $MBTxm;
    var $9i62K;
    var $aq0Kn;
    var $jyW1v;
    var $inFGE;
    var $cvQRb;
    var $1mNGt;
    var $klog1;
    var $7bwNu;
    var $a5zoF;
    var $el0Fl;
    var $0396H;
    var $fwJYI;
    var $ag3PC;
    var $2YzXO;
    var $lbp13;
    const $daeb815c48fa02e2$var$RenderNotificationsWithConversationAssistant = ()=>{
        const isAnonymous = (0, (0, parcelRequire("4R6GH")).fg)('rovo_chat_hide_for_jira_anon_users') ? (0, (0, parcelRequire("cZpsT")).useIsAnonymous)() : false;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, !isAnonymous && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jUhOq")).ConversationAssistant), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("inFGE")).Notifications), null));
    };
    const $daeb815c48fa02e2$var$SearchWithoutProps = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fwJYI")).Search), null);
    const $daeb815c48fa02e2$var$EditionAwarenessAndSearch = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement($daeb815c48fa02e2$var$EditionAwarenessWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bBulW")).EditionAwareness), null)), (0, ($parcel$interopDefault($5uXOq))).createElement($daeb815c48fa02e2$var$SearchWithoutProps, null));
    const $daeb815c48fa02e2$var$isSPA = ()=>{
        if (false) return false;
        if (typeof window !== 'undefined') return window.__SPA__;
        return false;
    };
    const $daeb815c48fa02e2$export$f66ccb39f60980e5 = ()=>{
        const topNavSegment = (0, (0, parcelRequire("azzrO")).useNavPerformanceMetrics)();
        topNavSegment.mark((0, (0, parcelRequire("5e7oM")).NAVIGATION_TOP_MARKS).renderStart);
        const theme = (0, (0, parcelRequire("eoNiZ")).useTheme)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const applicationPermissions = (0, (0, parcelRequire("daoB1")).useApplicationPermissions)();
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const isSiteAdmin = (0, (0, parcelRequire("cNd4s")).useIsSiteAdmin)();
        const isScreenLocked = (0, (0, parcelRequire("jOWa1")).useIsScreenLocked)();
        const showAssets = (0, (0, parcelRequire("9i62K")).useShowAssets)();
        const showRoadmaps = (0, (0, parcelRequire("el0Fl")).useShowRoadmaps)();
        const isMobileExperience = (0, (0, parcelRequire("c0dQj")).isMobileAndInMVPExperiment)();
        const PrimaryMenuItemsNav3 = (0, $5uXOq.useMemo)(()=>[
                (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1mNGt")).OpenOverflowMenuForPlansSpotlightObserver), {
                    key: "arj-open-overflow-menu-for-plans-spotlight-observer"
                }),
                !isAnonymous && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lbp13")).YourWorkDropdown), {
                    key: "yourWorkDropdown"
                }),
                (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("a5zoF")).Projects), {
                    key: "projects"
                }),
                (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jSplz")).Filters), {
                    key: "filters"
                }),
                (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bzLW")).Dashboards), {
                    key: "dashboards"
                }),
                (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cvQRb")).People), {
                    key: "people"
                }),
                (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1mNGt")).Plans), {
                    key: "plans"
                }),
                showRoadmaps && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("el0Fl")).Roadmaps), {
                    key: "roadmaps"
                }),
                showAssets && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9i62K")).Insight), {
                    key: "insight"
                }),
                (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6U0zt")).Addons), {
                    key: "addons"
                })
            ].filter(Boolean), [
            isAnonymous,
            showAssets,
            showRoadmaps
        ]);
        const nav4NavigationProps = (0, $5uXOq.useMemo)(()=>isMobileExperience ? {
                hideSideNavToggleButton: true
            } : {
                renderAppSwitcher: (0, (0, parcelRequire("6RKvu")).AppSwitcher),
                renderSearch: (0, (0, parcelRequire("fwJYI")).Search),
                renderEditionAwareness: (0, (0, parcelRequire("bBulW")).EditionAwareness),
                renderHelp: (0, (0, parcelRequire("crC7i")).Help),
                renderNotifications: (0, (0, parcelRequire("inFGE")).Notifications),
                renderConversationAssistant: (0, (0, parcelRequire("jUhOq")).ConversationAssistant),
                renderSettings: (0, (0, parcelRequire("ag3PC")).Settings),
                renderProfile: (0, (0, parcelRequire("7bwNu")).Profile),
                renderSignIn: (0, (0, parcelRequire("2YzXO")).SignIn)
            }, [
            isMobileExperience
        ]);
        const renderNavigation = (0, $5uXOq.useCallback)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, (0, parcelRequire("3HwlS")).getWillShowNav4)() ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("AameC")).default), {
                label: formatMessage((0, (0, parcelRequire("jyW1v")).default).topNav),
                renderProductHome: (0, (0, parcelRequire("klog1")).ProductHome),
                renderCreate: (0, (0, parcelRequire("96WTB")).Create),
                ...nav4NavigationProps,
                theme: theme
            }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eCCin")).AtlassianNavigation), {
                primaryItems: PrimaryMenuItemsNav3,
                label: formatMessage((0, (0, parcelRequire("jyW1v")).default).topNav),
                moreLabel: formatMessage((0, (0, parcelRequire("jyW1v")).default).more),
                renderAppSwitcher: (0, (0, parcelRequire("6RKvu")).AppSwitcher),
                renderCreate: (0, (0, parcelRequire("96WTB")).Create),
                renderHelp: (0, (0, parcelRequire("crC7i")).Help),
                renderNotifications: $daeb815c48fa02e2$var$RenderNotificationsWithConversationAssistant,
                renderProductHome: (0, (0, parcelRequire("klog1")).ProductHome),
                renderProfile: (0, (0, parcelRequire("7bwNu")).Profile),
                renderSearch: $daeb815c48fa02e2$var$EditionAwarenessAndSearch,
                renderSettings: (0, (0, parcelRequire("ag3PC")).Settings),
                renderSignIn: (0, (0, parcelRequire("2YzXO")).SignIn),
                theme: theme,
                testId: (0, (0, parcelRequire("6cNb0")).testIdGenerate)(null)
            }), !false && (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, !isAnonymous && !(0, (0, parcelRequire("jQsty")).default)('navigation.disable.prefetched.data.for.dropdowns') && (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("MBTxm")).InitialDataLoader), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4keKa")).RecommendationLoader), {
                recommendations: (0, (0, parcelRequire("13iNl")).recommendations)
            })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aq0Kn")).Integrations), null))), [
            PrimaryMenuItemsNav3,
            formatMessage,
            isAnonymous,
            nav4NavigationProps,
            theme
        ]);
        const route = (0, (0, parcelRequire("6jY6G")).useCurrentRoute)();
        const routeName = route.name ?? route.group ?? 'UNKNOWN';
        const projectContext = (0, (0, parcelRequire("dAfqg")).useProjectContext)();
        const customData = (0, $5uXOq.useMemo)(()=>{
            return {
                route: routeName,
                projectType: projectContext?.data?.projectType ?? 'UNKNOWN',
                isSPA: $daeb815c48fa02e2$var$isSPA()
            };
        }, [
            routeName,
            projectContext
        ]);
        if (isScreenLocked) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l0cYe")).NavigationSuccessTrackerForNav3), null, isAnonymous ? renderNavigation() : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("apeap")).RecentsContainer), {
            isGlobal: true,
            cloudId: cloudId,
            applicationPermissions: applicationPermissions
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bcn6L")).FavoritesContainer), {
            isGlobal: true,
            applicationPermissions: applicationPermissions
        }, renderNavigation()))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("0396H")).NavigationRouterListener), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("azzrO")).NavTopPerformanceMark), {
            metricKey: (0, (0, parcelRequire("5e7oM")).NAVIGATION_TOP_MARKS).renderEnd
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("azzrO")).NavTopPerformanceReport), {
            customData: customData
        }), isSiteAdmin && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9WcgH")).default), null), (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4') && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jzLyJ")).default), null), (0, (0, parcelRequire("4R6GH")).fg)('jira_mobile_web_mvp_feature_gate') && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c0dQj")).MobileWebExperimentExposure), null));
    };
    var $daeb815c48fa02e2$export$2e2bcd8739ae039 = $daeb815c48fa02e2$export$f66ccb39f60980e5;
    const $daeb815c48fa02e2$var$EditionAwarenessWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_2hwxpxbi _j2s1glyw",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("AameC", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$60452d4d89241ae5$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $lfTZh;
    var $kxZPA;
    const $60452d4d89241ae5$var$LazyAtlaskitAtlassianNavigationNav4 = (0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>(parcelRequire("iK9ai")).then(({ AtlaskitAtlassianNavigationNav4: AtlaskitAtlassianNavigationNav4 })=>AtlaskitAtlassianNavigationNav4), {
        moduleId: "./src/packages/navigation-apps/atlassian-navigation-nav4/src/index.tsx"
    });
    const $60452d4d89241ae5$var$AsyncAtlaskitAtlassianNavigationNav4 = (0, $5uXOq.memo)((props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "asyncAtlaskitAtlassianNavigationNav4",
            packageName: "jiraAtlassianNavigationNav4",
            teamName: "jira-cosmos"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "lazyAtlaskitAtlassianNavigationNav4"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($60452d4d89241ae5$var$LazyAtlaskitAtlassianNavigationNav4, props))));
    $60452d4d89241ae5$var$AsyncAtlaskitAtlassianNavigationNav4.displayName = 'AsyncAtlaskitAtlassianNavigationNav4';
    var $60452d4d89241ae5$export$2e2bcd8739ae039 = $60452d4d89241ae5$var$AsyncAtlaskitAtlassianNavigationNav4;
});
parcelRegister("iK9ai", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("5ZAcu").then(()=>parcelRequire('43icZ'));
});
parcelRegister("13iNl", function(module, exports) {
    $parcel$export(module.exports, "recommendations", ()=>$56dde49bc4054ac6$export$b7e5cef71fa42514);
    var $a334R;
    var $8tFwp;
    const $56dde49bc4054ac6$var$createRecommendationsList = ()=>{
        const recommendationsList = [];
        recommendationsList.push((0, (0, parcelRequire("a334R")).default));
        recommendationsList.push((0, (0, parcelRequire("8tFwp")).default));
        return recommendationsList;
    };
    const $56dde49bc4054ac6$export$b7e5cef71fa42514 = $56dde49bc4054ac6$var$createRecommendationsList();
});
parcelRegister("a334R", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$fccc347b1e897d64$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $jFnH8 = parcelRequire("jFnH8");
    var $941eD = parcelRequire("941eD");
    var $fVnbM;
    var $34gGM;
    var $lj8SI;
    var $kgoHi;
    var $cgL5K;
    var $8CUq3;
    var $89SaW;
    var $jcw0u;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $90yGq;
    var $2tT7R;
    var $cmVQ7;
    var $iKwST;
    var $5ZJgM;
    var $9SPvU;
    var $kwgZt;
    var $cmzJ2;
    var $kklBj;
    var $66CIA;
    var $hb557;
    var $5RxQU;
    var $7Y8xQ;
    const $fccc347b1e897d64$export$da956fe610b96f73 = ()=>{
        const [moreInformationModal, setMoreInformationModal] = (0, $5uXOq.useState)(false);
        const { fireViewedUIEvent: fireViewedUIEvent, fireInteractedUIEvent: fireInteractedUIEvent } = (0, (0, parcelRequire("cmVQ7")).useFireCrossFlowAnalyticsEvents)();
        const [, { setRecommendationDismissal: setRecommendationDismissal }] = (0, (0, parcelRequire("cmzJ2")).useProjectMenuRecommendations)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const accountId = (0, (0, parcelRequire("90yGq")).useAccountId)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const crossFlow = (0, $941eD.useCrossFlow)();
        const environment = (0, (0, parcelRequire("2tT7R")).useEnvironment)();
        const jpdCloudCCPProductID = (0, (0, parcelRequire("hb557")).getProductTypeCcpReferenceIdMapForEnv)(environment).PRODUCT_DISCOVERY;
        (0, $5uXOq.useEffect)(()=>{
            if (crossFlow.isEnabled) fireViewedUIEvent({
                action: 'viewed',
                actionSubject: 'section',
                actionSubjectId: 'projectsMenu'
            }, {
                recommendedProductIds: [
                    jpdCloudCCPProductID
                ],
                source: 'projectsMenuSection'
            });
            else (0, (0, parcelRequire("jcw0u")).default)({
                error: new Error(crossFlow.reason),
                meta: {
                    id: crossFlow.reason,
                    packageName: 'jiraAtlassianNavigationRecommendations'
                }
            });
        }, [
            createAnalyticsEvent,
            crossFlow,
            fireViewedUIEvent,
            jpdCloudCCPProductID
        ]);
        const openCrossFlow = (0, $5uXOq.useCallback)(async (templateId)=>{
            const extensions = (0, $jFnH8.createProvisioningPageExtensions)({
                actions: {
                    primary: {
                        behaviour: (0, $jFnH8.Behaviours).LAND,
                        prompt: (0, $jFnH8.Prompts).NEXT,
                        options: {
                            targetTab: (0, $jFnH8.TargetTabs).SAME,
                            urlParams: {
                                templateId: templateId
                            }
                        }
                    }
                }
            });
            if (crossFlow.isEnabled) crossFlow.api.open({
                journey: (0, $941eD.Journeys).GET_STARTED,
                targetProduct: (0, $941eD.Targets).JIRA_PRODUCT_DISCOVERY,
                sourceComponent: 'projectsMenuSection',
                sourceContext: 'tryThisRecommendation',
                extensions: extensions
            });
        }, [
            crossFlow
        ]);
        const onRecommendationClicked = (0, $5uXOq.useCallback)(()=>{
            fireInteractedUIEvent({
                action: 'clicked',
                actionSubject: 'button',
                actionSubjectId: 'tryThisRecommendation'
            }, {
                source: 'projectsMenuSection',
                targetProductId: jpdCloudCCPProductID,
                recommendedProductIds: [
                    jpdCloudCCPProductID
                ]
            });
            openCrossFlow((0, (0, parcelRequire("hb557")).JPD_KEY));
        }, [
            fireInteractedUIEvent,
            jpdCloudCCPProductID,
            openCrossFlow
        ]);
        const onLearnMoreClicked = (0, $5uXOq.useCallback)(()=>{
            fireInteractedUIEvent({
                action: 'clicked',
                actionSubject: 'button',
                actionSubjectId: 'learnMore'
            }, {
                source: 'projectsMenuSection',
                targetProductId: jpdCloudCCPProductID,
                recommendedProductIds: [
                    jpdCloudCCPProductID
                ]
            });
            setMoreInformationModal(true);
        }, [
            fireInteractedUIEvent,
            jpdCloudCCPProductID
        ]);
        const onLearnMoreClosed = (0, $5uXOq.useCallback)(()=>{
            setMoreInformationModal(false);
        }, []);
        const onDismissClicked = (0, $5uXOq.useCallback)(()=>{
            fireInteractedUIEvent({
                action: 'clicked',
                actionSubject: 'button',
                actionSubjectId: 'dismissRecommendation'
            }, {
                source: 'projectsMenuSection',
                targetProductId: jpdCloudCCPProductID,
                recommendedProductIds: [
                    jpdCloudCCPProductID
                ]
            });
            setRecommendationDismissal();
            if (accountId) (0, (0, parcelRequire("89SaW")).setUserProperties)(accountId, (0, (0, parcelRequire("hb557")).JPD_RECOMMENDATION_DISMISS_USER_PROPERTY), 'true').catch((error)=>(0, (0, parcelRequire("jcw0u")).default)({
                    error: error,
                    meta: {
                        id: 'dismissJpdRecommendation',
                        packageName: 'jiraAtlassianNavigationRecommendations',
                        teamName: 'navigation'
                    }
                }));
        }, [
            accountId,
            fireInteractedUIEvent,
            jpdCloudCCPProductID,
            setRecommendationDismissal
        ]);
        const onClickPrivacyPolicy = (0, $5uXOq.useCallback)(()=>{
            const clickedLinkBaseEvent = createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'link'
            });
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(clickedLinkBaseEvent, 'adControlsPrivacyPolicy');
        }, [
            createAnalyticsEvent
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iKwST")).RecommendationContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5ZJgM")).RecommendationHeader), {
            title: formatMessage((0, (0, parcelRequire("5RxQU")).default).recommendedText),
            onDismissClicked: onDismissClicked,
            onLearnMoreClicked: onLearnMoreClicked
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9SPvU")).RecommendationMenuItem), {
            title: formatMessage((0, (0, parcelRequire("5RxQU")).default).projectTitle),
            description: formatMessage((0, (0, parcelRequire("5RxQU")).default).projectDescription),
            onClick: onRecommendationClicked,
            lozenge: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lj8SI")).default), {
                appearance: "new",
                isBold: false
            }, formatMessage((0, (0, parcelRequire("5RxQU")).default).lozengeText)),
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kgoHi")).default), {
                xcss: $fccc347b1e897d64$var$logoStyles,
                justifyContent: "center",
                alignItems: "center"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("34gGM")).JiraProductDiscoveryIcon), {
                appearance: "brand",
                size: "small"
            }))
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kwgZt")).RecommendationModal), {
            isOpen: moreInformationModal,
            onClose: onLearnMoreClosed,
            title: formatMessage((0, (0, parcelRequire("5RxQU")).default).reasonsModalTitle),
            body: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, formatMessage((0, (0, parcelRequire("5RxQU")).default).reasonsModalExplanation, {
                strong: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
                        as: "strong"
                    }, chunks)
            }), (0, ($parcel$interopDefault($5uXOq))).createElement("ul", null, (0, ($parcel$interopDefault($5uXOq))).createElement("li", null, formatMessage((0, (0, parcelRequire("5RxQU")).default).reasonsModalBulletOneSpork)), (0, ($parcel$interopDefault($5uXOq))).createElement("li", null, formatMessage((0, (0, parcelRequire("5RxQU")).default).reasonsModalBulletTwo))), (0, ($parcel$interopDefault($5uXOq))).createElement("br", null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                appearance: "link",
                spacing: "none",
                href: (0, (0, parcelRequire("hb557")).DATA_PRIVACY_LINK),
                target: "_blank",
                onClick: onClickPrivacyPolicy
            }, formatMessage((0, (0, parcelRequire("5RxQU")).default).privacyPolicy))),
            primaryCta: formatMessage((0, (0, parcelRequire("5RxQU")).default).reasonsModalButtonOk)
        }));
    };
    const $fccc347b1e897d64$var$logoStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        width: '28px',
        height: '28px',
        borderRadius: 'border.radius.100',
        backgroundColor: 'color.background.neutral'
    });
    const $fccc347b1e897d64$var$checkEligibility = async (recommendationContext, analyticsEvent)=>{
        const { areProductSuggestionsEnabled: areProductSuggestionsEnabled, licensedProducts: licensedProducts, canCreateProject: canCreateProject, accountId: accountId, cloudId: cloudId, environment: environment } = recommendationContext;
        if (!areProductSuggestionsEnabled) return {
            isEligible: false,
            reason: 'Product suggestions are disabled'
        };
        if ((0, (0, parcelRequire("7Y8xQ")).hasJpdLicense)(licensedProducts)) return {
            isEligible: false,
            reason: 'Instance has JPD license'
        };
        if (!canCreateProject) return {
            isEligible: false,
            reason: 'User cannot create projects'
        };
        const [dismissed, appRecommendations] = await Promise.all([
            (0, (0, parcelRequire("89SaW")).getUserProperty)(accountId, (0, (0, parcelRequire("hb557")).JPD_RECOMMENDATION_DISMISS_USER_PROPERTY)).catch((error)=>{
                if (error.statusCode === 404) return false;
                throw Error(`${error.statusCode}, JPD recommendation dismissal failed. ${error.name}: ${error.message}`, error);
            }),
            (0, (0, parcelRequire("66CIA")).fetchAppRecommendations)(cloudId, environment, analyticsEvent)
        ]);
        if (dismissed) return {
            isEligible: false,
            reason: 'JPD recommendation dismissed'
        };
        if (!appRecommendations?.some((recommendation)=>recommendation?.entityId === (0, (0, parcelRequire("hb557")).JPD_ENTITY_ID))) return {
            isEligible: false,
            reason: 'JPD was not in the recommend apps'
        };
        return {
            isEligible: true,
            reason: 'Pass all checks'
        };
    };
    var $fccc347b1e897d64$export$2e2bcd8739ae039 = {
        key: (0, (0, parcelRequire("hb557")).JPD_RECOMMENDATION_KEY),
        shouldRender: async (recommendationContext, analyticsEvent)=>{
            (0, (0, parcelRequire("kklBj")).projectsMenuDropdownLoadJpdRecommendation).start();
            const { isEligible: isEligible, reason: reason } = await $fccc347b1e897d64$var$checkEligibility(recommendationContext, analyticsEvent);
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(analyticsEvent, 'jiraAtlassianNavigation.loadJpdRecommendation succeeded', {
                reason: reason,
                isEligible: isEligible
            });
            (0, (0, parcelRequire("kklBj")).projectsMenuDropdownLoadJpdRecommendation).success();
            return isEligible;
        },
        onLoadError: (error)=>{
            (0, (0, parcelRequire("kklBj")).projectsMenuDropdownLoadJpdRecommendation).failure();
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: 'loadJpdRecommendation',
                    packageName: 'jiraAtlassianNavigationRecommendations',
                    teamName: 'navigation'
                }
            });
        },
        Component: $fccc347b1e897d64$export$da956fe610b96f73
    };
});
parcelRegister("iKwST", function(module, exports) {
    $parcel$export(module.exports, "RecommendationContainer", ()=>$3f1017feb0eddea7$export$97f31f44c68c1d5);
    var $5uXOq = parcelRequire("5uXOq");
    var $9UCCQ;
    var $iXVV6;
    const $3f1017feb0eddea7$export$97f31f44c68c1d5 = ({ children: children, attributes: attributes })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            attributes: attributes
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            hasSeparator: true
        }, children));
});
parcelRegister("5ZJgM", function(module, exports) {
    $parcel$export(module.exports, "RecommendationHeader", ()=>$04eaa3b92b265d65$export$3beeaceb068c75b1);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $fVnbM;
    var $1dJ6S;
    var $3oUqw;
    var $kJpBP;
    var $eZUJu;
    var $9UCCQ;
    var $1I7E8;
    var $f5TWP;
    var $8CUq3;
    var $koVbs;
    var $9xvcL;
    const $04eaa3b92b265d65$export$3beeaceb068c75b1 = ({ title: title, onDismissClicked: onDismissClicked, onLearnMoreClicked: onLearnMoreClicked })=>{
        const [isActionsMenuOpen, setIsActionsMenuOpen] = (0, $5uXOq.useState)(false);
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
            xcss: $04eaa3b92b265d65$var$HeadingItemStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1dJ6S")).default), {
            size: "xxsmall",
            as: "div"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
            xcss: $04eaa3b92b265d65$var$TitleItemStyles
        }, title)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1I7E8")).default), {
            isOpen: isActionsMenuOpen,
            placement: "bottom-start",
            onClose: ()=>setIsActionsMenuOpen(false),
            content: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eZUJu")).default), {
                    onClick: (e)=>e.stopPropagation()
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kJpBP")).default), {
                    onClick: onDismissClicked
                }, formatMessage((0, (0, parcelRequire("9xvcL")).default).dismissButtonText)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kJpBP")).default), {
                    onClick: onLearnMoreClicked
                }, formatMessage((0, (0, parcelRequire("9xvcL")).default).learnMoreButtonText)))),
            shouldUseCaptureOnOutsideClick: true,
            trigger: (triggerProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement($04eaa3b92b265d65$var$MeatballMenuTrigger, {
                    ...triggerProps,
                    appearance: "subtle",
                    onClick: ()=>setIsActionsMenuOpen(()=>!isActionsMenuOpen),
                    iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("3oUqw"))))), {
                        size: "medium",
                        label: "more"
                    })
                })
        }));
    };
    const $04eaa3b92b265d65$var$HeadingItemStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 'space.200',
        paddingRight: 'space.200',
        marginTop: 'space.050'
    });
    const $04eaa3b92b265d65$var$TitleItemStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        color: 'color.text.accent.gray',
        textTransform: 'uppercase'
    });
    const $04eaa3b92b265d65$var$MeatballMenuTrigger = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("fVnbM")).default), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1bsbzwfg _4t3izwfg _1di67btw",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("9xvcL", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$aa08673ea438366b$export$2e2bcd8739ae039);
    var $7VHMR;
    var $aa08673ea438366b$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        learnMoreButtonText: {
            "id": "atlassian-navigation-recommendations.common.recommendation-section.recommendation-header.learn-more-button-text",
            "defaultMessage": "Why am I seeing this?"
        },
        dismissButtonText: {
            "id": "atlassian-navigation-recommendations.common.recommendation-section.recommendation-header.dismiss-button-text",
            "defaultMessage": "Not interested"
        },
        dismissConfirmationText: {
            "id": "atlassian-navigation-recommendations.common.recommendation-section.recommendation-header.dismiss-confirmation-text",
            "defaultMessage": "You won't be seeing this here anymore"
        }
    });
});
parcelRegister("9SPvU", function(module, exports) {
    $parcel$export(module.exports, "RecommendationMenuItem", ()=>$56fe10413c20ceb0$export$aab9ce3de285230c);
    var $5uXOq = parcelRequire("5uXOq");
    var $3Z96c;
    var $dC5iT;
    var $f5TWP;
    const $56fe10413c20ceb0$export$aab9ce3de285230c = ({ iconBefore: iconBefore, onClick: onClick, title: title, lozenge: lozenge = null, description: description })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3Z96c")).default), {
            iconBefore: iconBefore,
            onClick: onClick,
            description: description,
            href: "#"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
            space: "space.100"
        }, title, lozenge && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), null, lozenge)));
});
parcelRegister("kwgZt", function(module, exports) {
    $parcel$export(module.exports, "RecommendationModal", ()=>$6e7f095537455847$export$d5c06ba24e862b67);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $1R1Ge;
    var $7QSqG;
    var $juxqI;
    var $jgVsb;
    var $gofOI;
    var $i8Qvm;
    var $cgL5K;
    const $6e7f095537455847$export$d5c06ba24e862b67 = ({ isOpen: isOpen, onClose: onClose, title: title, body: body, primaryCta: primaryCta })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("i8Qvm")).default), null, isOpen && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1R1Ge")).default), {
            shouldCloseOnEscapePress: true,
            onClose: onClose
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jgVsb")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gofOI")).default), null, title)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7QSqG")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            as: "p"
        }, body)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("juxqI")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            appearance: "primary",
            onClick: onClose
        }, primaryCta))));
});
parcelRegister("cmzJ2", function(module, exports) {
    $parcel$export(module.exports, "useProjectMenuRecommendations", ()=>$5442a73b6e3e578d$export$d4048a5b8ab1acff);
    var $3sQ5x;
    var $iXqE5;
    var $5hXPP;
    const $5442a73b6e3e578d$var$initialState = {
        status: 'idle'
    };
    const $5442a73b6e3e578d$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        actions: (0, parcelRequire("5hXPP")).actions,
        initialState: $5442a73b6e3e578d$var$initialState,
        name: 'atlassian-navigation-recommendation'
    });
    const $5442a73b6e3e578d$export$d4048a5b8ab1acff = (0, (0, parcelRequire("3sQ5x")).createHook)($5442a73b6e3e578d$var$store);
});
parcelRegister("5hXPP", function(module, exports) {
    $parcel$export(module.exports, "actions", ()=>$5033aa90e3adb7fd$export$e324594224ef24da);
    var $s7Ri8 = parcelRequire("s7Ri8");
    const $5033aa90e3adb7fd$export$e95368803793838f = (recommendations, recommendationContext, analyticsEvent)=>async ({ getState: getState, setState: setState })=>{
            const { status: status } = getState();
            if (status !== 'idle') return;
            setState({
                status: 'loading'
            });
            const shuffledRecommendations = (0, ($parcel$interopDefault($s7Ri8)))(recommendations);
            try {
                const recommendationStatus = await Promise.all(shuffledRecommendations.map(async (recommendation)=>{
                    let isEnabled = false;
                    try {
                        isEnabled = await recommendation.shouldRender(recommendationContext, analyticsEvent);
                    } catch (error) {
                        recommendation.onLoadError(error);
                    }
                    return {
                        key: recommendation.key,
                        isEnabled: isEnabled
                    };
                }));
                const selectedRecommendation = recommendationStatus.find((result)=>result.isEnabled);
                setState({
                    status: 'success',
                    recommendationKey: selectedRecommendation?.key || null
                });
            } catch (error) {
                setState({
                    status: 'error',
                    error: error
                });
            }
        };
    const $5033aa90e3adb7fd$var$setRecommendationDismissal = ()=>({ setState: setState })=>{
            setState({
                recommendationKey: null
            });
        };
    const $5033aa90e3adb7fd$export$e324594224ef24da = {
        loadRecommendations: $5033aa90e3adb7fd$export$e95368803793838f,
        setRecommendationDismissal: $5033aa90e3adb7fd$var$setRecommendationDismissal
    };
});
parcelRegister("kklBj", function(module, exports) {
    $parcel$export(module.exports, "projectsMenuDropdownRecommendationSelector", ()=>$4c06b486be8bb39b$export$2b83859c1548ffb5);
    $parcel$export(module.exports, "projectsMenuDropdownLoadJpdRecommendation", ()=>$4c06b486be8bb39b$export$a784c5bbb21ea554);
    $parcel$export(module.exports, "projectsMenuDropdownLoadJwmSampleProjectRecommendation", ()=>$4c06b486be8bb39b$export$901fa2452d4f9051);
    var $atdLB;
    var $duUGa;
    const $4c06b486be8bb39b$export$2b83859c1548ffb5 = new (0, (0, parcelRequire("duUGa")).UFOExperience)('projects-menu-dropdown.recommendation-selector', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Experience,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).InlineResult
    });
    const $4c06b486be8bb39b$export$a784c5bbb21ea554 = new (0, (0, parcelRequire("duUGa")).UFOExperience)('projects-menu-dropdown.load-jpd-recommendation', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Experience,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).InlineResult
    });
    const $4c06b486be8bb39b$export$901fa2452d4f9051 = new (0, (0, parcelRequire("duUGa")).UFOExperience)('projects-menu-dropdown.load-jwm-sample-project-recommendation', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Experience,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).InlineResult
    });
});
parcelRegister("66CIA", function(module, exports) {
    $parcel$export(module.exports, "fetchAppRecommendations", ()=>$78725ead7cfc4b7c$export$8665956217263d00);
    var $5rZL1;
    var $8Uumt = parcelRequire("8Uumt");
    var $8zOmE;
    var $6FSDe;
    const $78725ead7cfc4b7c$export$f2d50cdc12f57d0b = (0, (0, parcelRequire("5rZL1")).default);
    const $78725ead7cfc4b7c$export$8665956217263d00 = async (cloudId, environment, analyticsEvent)=>{
        const appRecommendationState = (0, (0, parcelRequire("6FSDe")).getCachedAppRecommendationState)();
        if (appRecommendationState !== null && Date.now() - appRecommendationState.fetchRecommendationTimeStamp < (0, $3202ab0bf207425f$export$424ba99fce04562)) return appRecommendationState.appRecommendations ?? [];
        const data = await (0, $8Uumt.fetchQuery)(environment, $78725ead7cfc4b7c$export$f2d50cdc12f57d0b, {
            cloudId: cloudId
        }).toPromise().catch((err)=>{
            throw Error(`App recommendation ${err.name} error: ${err.message}`, {
                cause: err
            });
        });
        const appRecommendations = data?.growthRecommendations?.recommendations?.data;
        if (!appRecommendations) (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(analyticsEvent, 'jiraAtlassianNavigation.fetchAppRecommendations empty', {
            response: `${JSON.stringify(data)}`
        });
        (0, (0, parcelRequire("6FSDe")).setCachedAppRecommendationState)({
            appRecommendations: appRecommendations,
            fetchRecommendationTimeStamp: Date.now()
        });
        return appRecommendations ?? [];
    };
});
parcelRegister("5rZL1", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$739e1bf50f170603$export$2e2bcd8739ae039);
    const $739e1bf50f170603$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cloudId"
            }
        ], v1 = [
            {
                "fields": [
                    {
                        "kind": "Literal",
                        "name": "product",
                        "value": "jira"
                    },
                    {
                        "kind": "Variable",
                        "name": "tenantId",
                        "variableName": "cloudId"
                    },
                    {
                        "kind": "Literal",
                        "name": "useCase",
                        "value": "jiraProjectMenuRecommendations"
                    }
                ],
                "kind": "ObjectValue",
                "name": "context"
            },
            {
                "kind": "Literal",
                "name": "first",
                "value": 2
            }
        ], v2 = {
            "kind": "ScalarField",
            "name": "entityId"
        }, v3 = {
            "kind": "ScalarField",
            "name": "__typename"
        };
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "fetchAppRecommendationsV2Query",
                "selections": [
                    {
                        "concreteType": "GrowthRecQuery",
                        "kind": "LinkedField",
                        "name": "growthRecommendations",
                        "plural": false,
                        "selections": [
                            {
                                "args": v1,
                                "kind": "LinkedField",
                                "name": "recommendations",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "kind": "LinkedField",
                                                "name": "data",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                            {
                                                                "kind": "RequiredField",
                                                                "field": v2,
                                                                "action": "THROW",
                                                                "path": "growthRecommendations.recommendations.data.entityId"
                                                            }
                                                        ],
                                                        "type": "GrowthRecJiraTemplateRecommendation"
                                                    }
                                                ]
                                            }
                                        ],
                                        "type": "GrowthRecRecommendations"
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "fetchAppRecommendationsV2Query",
                "selections": [
                    {
                        "concreteType": "GrowthRecQuery",
                        "kind": "LinkedField",
                        "name": "growthRecommendations",
                        "plural": false,
                        "selections": [
                            {
                                "args": v1,
                                "kind": "LinkedField",
                                "name": "recommendations",
                                "plural": false,
                                "selections": [
                                    v3,
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "kind": "LinkedField",
                                                "name": "data",
                                                "plural": true,
                                                "selections": [
                                                    v3,
                                                    {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                            v2
                                                        ],
                                                        "type": "GrowthRecJiraTemplateRecommendation"
                                                    }
                                                ]
                                            }
                                        ],
                                        "type": "GrowthRecRecommendations"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "620b7134d06fa893e7db6526963c4d8afbe3dc9c5b0428d93f35a2c73fa2ea86",
                "metadata": {},
                "name": "fetchAppRecommendationsV2Query",
                "operationKind": "query",
                "text": null
            }
        };
    }();
    $739e1bf50f170603$var$node.hash = "eaaab66d3f41b01ce8e8a969a422ff60";
    var $739e1bf50f170603$export$2e2bcd8739ae039 = $739e1bf50f170603$var$node;
});
const $3202ab0bf207425f$export$424ba99fce04562 = 600000;
parcelRegister("6FSDe", function(module, exports) {
    $parcel$export(module.exports, "setCachedAppRecommendationState", ()=>$69d5e6aa5b3a01d0$export$4bb2da71ae03947f);
    $parcel$export(module.exports, "getCachedAppRecommendationState", ()=>$69d5e6aa5b3a01d0$export$951c5f8396f4dcb);
    var $bSSG7;
    const $69d5e6aa5b3a01d0$var$store = (0, (0, parcelRequire("bSSG7")).createLocalStorageProvider)('projects-menu-jpd-recommendation');
    const $69d5e6aa5b3a01d0$var$APP_RECOMMENDATION_STATE = 'app-recommendation-state';
    function $69d5e6aa5b3a01d0$export$4bb2da71ae03947f(appRecommendationState) {
        $69d5e6aa5b3a01d0$var$store.set($69d5e6aa5b3a01d0$var$APP_RECOMMENDATION_STATE, JSON.stringify(appRecommendationState));
    }
    function $69d5e6aa5b3a01d0$export$951c5f8396f4dcb() {
        const state = $69d5e6aa5b3a01d0$var$store.get($69d5e6aa5b3a01d0$var$APP_RECOMMENDATION_STATE);
        return state ? JSON.parse(state) : null;
    }
    function $69d5e6aa5b3a01d0$export$a73955031198b1b8() {
        $69d5e6aa5b3a01d0$var$store.remove($69d5e6aa5b3a01d0$var$APP_RECOMMENDATION_STATE);
    }
});
parcelRegister("hb557", function(module, exports) {
    $parcel$export(module.exports, "JPD_RECOMMENDATION_KEY", ()=>$aa2162acaf3862c0$export$3e6ff24c4f517c4f);
    $parcel$export(module.exports, "JPD_KEY", ()=>$aa2162acaf3862c0$export$a9c4e38d8a74acd2);
    $parcel$export(module.exports, "JPD_RECOMMENDATION_DISMISS_USER_PROPERTY", ()=>$aa2162acaf3862c0$export$52b9dedc0088f8);
    $parcel$export(module.exports, "DATA_PRIVACY_LINK", ()=>$aa2162acaf3862c0$export$aefe1c8741b24a28);
    $parcel$export(module.exports, "JPD_ENTITY_ID", ()=>$aa2162acaf3862c0$export$909712ccf16883d);
    $parcel$export(module.exports, "getProductTypeCcpReferenceIdMapForEnv", ()=>$aa2162acaf3862c0$export$29629758934356a6);
    var $iDfVE;
    const $aa2162acaf3862c0$export$3e6ff24c4f517c4f = 'jpd-recommendation';
    const $aa2162acaf3862c0$export$a9c4e38d8a74acd2 = 'jpd.product-discovery';
    const $aa2162acaf3862c0$export$52b9dedc0088f8 = 'JpdRecommendationDismissed';
    const $aa2162acaf3862c0$export$24de1334acd05ac2 = 'RecommendJpdInProjectsMenuKey';
    const $aa2162acaf3862c0$export$aefe1c8741b24a28 = 'https://www.atlassian.com/legal/privacy-policy';
    const $aa2162acaf3862c0$export$32601d21b6bdcb50 = 'recommending_jpd_in_projects_menu';
    const $aa2162acaf3862c0$export$909712ccf16883d = 'jira.polaris:discovery';
    const $aa2162acaf3862c0$export$29629758934356a6 = (environment)=>{
        const env = environment === 'prod' ? 'prod' : 'staging';
        return {
            BUSINESS: (0, (0, parcelRequire("iDfVE")).FIRST_PARTY_CCP_PRODUCT_IDS)[env].jira_work_management_cloud,
            SOFTWARE: (0, (0, parcelRequire("iDfVE")).FIRST_PARTY_CCP_PRODUCT_IDS)[env].jira_software_cloud,
            SERVICE_DESK: (0, (0, parcelRequire("iDfVE")).FIRST_PARTY_CCP_PRODUCT_IDS)[env].jira_service_management_cloud,
            PRODUCT_DISCOVERY: (0, (0, parcelRequire("iDfVE")).FIRST_PARTY_CCP_PRODUCT_IDS)[env].jira_product_discovery
        };
    };
});
parcelRegister("5RxQU", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3f55eacc0b9ee9dc$export$2e2bcd8739ae039);
    var $7VHMR;
    var $3f55eacc0b9ee9dc$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        learnMoreButtonText: {
            "id": "atlassian-navigation-recommendations.jpd-recommendation.learn-more-button-text",
            "defaultMessage": "Why am I seeing this?"
        },
        projectDescription: {
            "id": "atlassian-navigation-recommendations.jpd-recommendation.project-description",
            "defaultMessage": "Capture ideas with Jira Product Discovery"
        },
        lozengeText: {
            "id": "atlassian-navigation-recommendations.jpd-recommendation.lozenge-text",
            "defaultMessage": "try"
        },
        dismissButtonText: {
            "id": "atlassian-navigation-recommendations.jpd-recommendation.dismiss-button-text",
            "defaultMessage": "Not interested"
        },
        recommendedText: {
            "id": "atlassian-navigation-recommendations.jpd-recommendation.recommended-text",
            "defaultMessage": "Recommended"
        },
        dismissConfirmationTitle: {
            "id": "atlassian-navigation-recommendations.jpd-recommendation.dismiss-confirmation-title",
            "defaultMessage": "Got it, thanks for letting us know"
        },
        dismissConfirmationText: {
            "id": "atlassian-navigation-recommendations.jpd-recommendation.dismiss-confirmation-text",
            "defaultMessage": "You won't be seeing this here anymore"
        },
        projectTitle: {
            "id": "atlassian-navigation-recommendations.jpd-recommendation.project-title",
            "defaultMessage": "New discovery project"
        },
        reasonsModalTitle: {
            "id": "atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-title",
            "defaultMessage": "Why am I seeing this?"
        },
        reasonsModalExplanation: {
            "id": "atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-explanation",
            "defaultMessage": "We think <strong>Jira Product Discovery</strong> will improve your team's productivity. The recommendation is based on:"
        },
        reasonsModalBulletOneSpork: {
            "id": "atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-bullet-one-spork",
            "defaultMessage": "the tools you use (Jira)"
        },
        reasonsModalBulletTwo: {
            "id": "atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-bullet-two",
            "defaultMessage": "the way you use your existing tools and products"
        },
        privacyPolicy: {
            "id": "atlassian-navigation-recommendations.jpd-recommendation.privacy-policy",
            "defaultMessage": "Learn more about your data privacy with Atlassian"
        },
        reasonsModalButtonOk: {
            "id": "atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-button-ok",
            "defaultMessage": "Ok"
        },
        reasonsModalButtonOkCapitalised: {
            "id": "atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-button-ok-capitalised",
            "defaultMessage": "OK"
        }
    });
});
parcelRegister("7Y8xQ", function(module, exports) {
    $parcel$export(module.exports, "hasJpdLicense", ()=>$b21ff31ac89e5c24$export$2ea69a0925803655);
    const $b21ff31ac89e5c24$export$2ea69a0925803655 = (licensedProducts)=>licensedProducts['jira-product-discovery'] === true;
});
parcelRegister("8tFwp", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a4b7416812bdfee6$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $4mBKK;
    var $lj8SI;
    var $kgoHi;
    var $8CUq3;
    var $4Pf62;
    var $jcw0u;
    var $eusud;
    var $7HGHK;
    var $pa9q9;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $ldyPw;
    var $6qJCA;
    var $iKwST;
    var $5ZJgM;
    var $9SPvU;
    var $kwgZt;
    var $cmzJ2;
    var $kklBj;
    var $7oMrD;
    var $3J67Z;
    const $a4b7416812bdfee6$export$1a4d033f2094f608 = ()=>{
        const [moreInformationModal, setMoreInformationModal] = (0, $5uXOq.useState)(false);
        const [, { setRecommendationDismissal: setRecommendationDismissal }] = (0, (0, parcelRequire("cmzJ2")).useProjectMenuRecommendations)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { showFlag: showFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        (0, $5uXOq.useEffect)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'dropdown viewed', 'projectsMenuRecommendationDropdown');
        }, [
            createAnalyticsEvent
        ]);
        const createSampleProjectClicked = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                actionSubject: 'button',
                action: 'clicked'
            }), 'jwmSampleProjectExperimentCreateProjectButton');
            $a4b7416812bdfee6$var$createJwmSampleProject(showFlag);
        }, [
            createAnalyticsEvent,
            showFlag
        ]);
        const onLearnMoreClicked = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                actionSubject: 'button',
                action: 'clicked'
            }), 'explainButton');
            setMoreInformationModal(true);
        }, [
            createAnalyticsEvent
        ]);
        const onLearnMoreLinkClicked = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                actionSubject: 'link',
                action: 'clicked'
            }), 'jwmSampleProjectLink');
        }, [
            createAnalyticsEvent
        ]);
        const onLearnMoreClosed = (0, $5uXOq.useCallback)(()=>{
            setMoreInformationModal(false);
        }, []);
        const onDismissClicked = (0, $5uXOq.useCallback)(()=>{
            setRecommendationDismissal();
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                actionSubject: 'button',
                action: 'clicked'
            }), 'jwmSampleProjectExperimentDismissButton');
            showFlag({
                type: 'success',
                isAutoDismiss: true,
                title: formatMessage((0, (0, parcelRequire("3J67Z")).default).dismissConfirmationTitle),
                description: formatMessage((0, (0, parcelRequire("3J67Z")).default).dismissConfirmationText)
            });
        }, [
            createAnalyticsEvent,
            setRecommendationDismissal,
            showFlag,
            formatMessage
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iKwST")).RecommendationContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5ZJgM")).RecommendationHeader), {
            title: formatMessage((0, (0, parcelRequire("3J67Z")).default).trySampleSectionHeader),
            onDismissClicked: onDismissClicked,
            onLearnMoreClicked: onLearnMoreClicked
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9SPvU")).RecommendationMenuItem), {
            title: formatMessage((0, (0, parcelRequire("3J67Z")).default).ctaHeadingText),
            description: formatMessage((0, (0, parcelRequire("3J67Z")).default).ctaDescriptionText),
            onClick: createSampleProjectClicked,
            lozenge: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lj8SI")).default), {
                appearance: "new",
                isBold: false
            }, formatMessage((0, (0, parcelRequire("3J67Z")).default).lozengeText)),
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kgoHi")).default), {
                xcss: $a4b7416812bdfee6$var$logoStyles,
                justifyContent: "center",
                alignItems: "center"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6qJCA")).SampleProjectAvatar), null))
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kwgZt")).RecommendationModal), {
            isOpen: moreInformationModal,
            onClose: onLearnMoreClosed,
            title: formatMessage((0, (0, parcelRequire("3J67Z")).default).modalTitle),
            body: formatMessage((0, (0, parcelRequire("3J67Z")).default).modalBodySpork, {
                br: (0, ($parcel$interopDefault($5uXOq))).createElement("br", null),
                link: (0, ($parcel$interopDefault($5uXOq))).createElement("a", {
                    href: "https://www.atlassian.com/software/jira/work-management",
                    target: "_blank",
                    onClick: onLearnMoreLinkClicked
                }, formatMessage((0, (0, parcelRequire("3J67Z")).default).learnMoreLinkSpork))
            }),
            primaryCta: formatMessage((0, (0, parcelRequire("3J67Z")).default).modalCta)
        }));
    };
    const $a4b7416812bdfee6$var$logoStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        width: '28px',
        height: '28px'
    });
    const $a4b7416812bdfee6$var$checkEligibility = async (recommendationContext)=>{
        const { licensedProducts: licensedProducts, firstActivationDateMs: firstActivationDateMs, edition: edition, canCreateProject: canCreateProject, accountId: accountId, cloudId: cloudId } = recommendationContext;
        if (!licensedProducts?.[(0, (0, parcelRequire("4Pf62")).LICENSED_PRODUCTS).JIRA_SOFTWARE]) return {
            isEligible: false,
            reason: 'User does not have jira software'
        };
        if (edition === (0, (0, parcelRequire("ldyPw")).UNLICENSED)) return {
            isEligible: false,
            reason: 'User is unlicensed'
        };
        if (!canCreateProject) return {
            isEligible: false,
            reason: 'User cannot create projects'
        };
        const [userTraits, siteTraits] = await Promise.all([
            (0, (0, parcelRequire("7oMrD")).fetchUserTraits)(cloudId, accountId),
            (0, (0, parcelRequire("7oMrD")).fetchSiteTraits)(cloudId)
        ]);
        if (userTraits.some((trait)=>trait.name === (0, $81af7e5cda82bb03$export$ce71307e913afd12) && trait.value)) return {
            isEligible: false,
            reason: `User has trait ${0, $81af7e5cda82bb03$export$ce71307e913afd12}`
        };
        if (userTraits.some((trait)=>trait.name === (0, $81af7e5cda82bb03$export$fc53f723f5163588) && trait.value)) return {
            isEligible: false,
            reason: `User has trait ${0, $81af7e5cda82bb03$export$fc53f723f5163588}`
        };
        if (userTraits.some((trait)=>trait.name === (0, $81af7e5cda82bb03$export$a1a2e9d60f36ed80) && trait.value)) return {
            isEligible: false,
            reason: `User has trait ${0, $81af7e5cda82bb03$export$a1a2e9d60f36ed80}`
        };
        if (!firstActivationDateMs) return {
            isEligible: false,
            reason: 'firstActivationDateMs is undefined'
        };
        if ((0, (0, parcelRequire("4mBKK")).default)(new Date(), firstActivationDateMs) > 28 && userTraits.some((trait)=>trait.name === (0, $81af7e5cda82bb03$export$a92ddfff80995acf) && (0, (0, parcelRequire("4mBKK")).default)(new Date(), new Date(trait.value)) > 28)) return {
            isEligible: false,
            reason: 'Experiment dropdown date trait is older than 28 days'
        };
        if ((0, (0, parcelRequire("4mBKK")).default)(new Date(), firstActivationDateMs) > 28 && !siteTraits.some(({ name: name, value: value })=>name === (0, $81af7e5cda82bb03$export$9365f74cb008dc7c) && value)) return {
            isEligible: false,
            reason: 'User activation date is greater than 28 days and JSW to JWM ML trait is not set'
        };
        return {
            isEligible: true,
            reason: 'Pass all checks'
        };
    };
    var $a4b7416812bdfee6$export$2e2bcd8739ae039 = {
        key: (0, $81af7e5cda82bb03$export$4e0e125b835dc0d),
        shouldRender: async (recommendationContext, analyticsEvent)=>{
            (0, (0, parcelRequire("kklBj")).projectsMenuDropdownLoadJwmSampleProjectRecommendation).start();
            const { isEligible: isEligible, reason: reason } = await $a4b7416812bdfee6$var$checkEligibility(recommendationContext);
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(analyticsEvent, 'jiraAtlassianNavigation.loadJwmSampleProjectRecommendation succeeded', {
                isEligible: isEligible,
                reason: reason
            });
            (0, (0, parcelRequire("kklBj")).projectsMenuDropdownLoadJwmSampleProjectRecommendation).success();
            return isEligible;
        },
        onLoadError: (error)=>{
            (0, (0, parcelRequire("kklBj")).projectsMenuDropdownLoadJwmSampleProjectRecommendation).failure();
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: 'loadJwmSampleProjectRecommendation',
                    packageName: 'jiraAtlassianNavigationRecommendations',
                    teamName: 'navigation'
                },
                sendToPrivacyUnsafeSplunk: true
            });
        },
        Component: $a4b7416812bdfee6$export$1a4d033f2094f608
    };
    const $a4b7416812bdfee6$var$jwmSampleProjectUrlWithOriginSource = (taskId, originSource)=>`/jira/core/sample/onboarding/${taskId}?originSource=moon-${originSource}`;
    const $a4b7416812bdfee6$var$createJwmSampleProject = async (showFlag)=>{
        try {
            const response = await (0, (0, parcelRequire("eusud")).performPostRequest)((0, $81af7e5cda82bb03$export$7b412f30d02cee13));
            if (response?.taskId) window.open($a4b7416812bdfee6$var$jwmSampleProjectUrlWithOriginSource(response.taskId, (0, $81af7e5cda82bb03$export$63fbd2796586b8eb)));
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: 'createJwmSampleProjectService',
                    packageName: (0, $81af7e5cda82bb03$export$b8b9d90f9e5bd72b),
                    teamName: 'navigation'
                },
                error: error,
                sendToPrivacyUnsafeSplunk: true
            });
            showFlag({
                key: (0, (0, parcelRequire("7HGHK")).toFlagId)((0, $81af7e5cda82bb03$export$529244c5c5612bf2)),
                type: 'error',
                title: (0, (0, parcelRequire("3J67Z")).default).errorFlagTitle,
                description: (0, (0, parcelRequire("3J67Z")).default).errorFlagText
            });
            return error;
        }
    };
});
parcelRegister("6qJCA", function(module, exports) {
    $parcel$export(module.exports, "SampleProjectAvatar", ()=>$206024975b6fe1f5$export$b8d1b0731803f79e);
    var $5uXOq = parcelRequire("5uXOq");
    var $dV6Te;
    const $206024975b6fe1f5$export$b8d1b0731803f79e = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dV6Te")).default), {
            primaryColor: "#00C7E6",
            size: "medium",
            label: "test"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            id: "Page-1",
            stroke: "none",
            strokeWidth: "1",
            fill: "none",
            fillRule: "evenodd",
            transform: "matrix(0.254436, 0, 0, 0.252786, -5.761572, 0.007035)"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            id: "project_avatar_plane"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            id: "plane"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("g", null, (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            id: "Rectangle",
            x: "0",
            y: "0",
            width: "128",
            height: "128",
            transform: "matrix(1, 0, 0, 1, 0, 0)",
            fill: "#00C7E6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M68.101,74.324 L44.671,97.754 L73.279,130.588 C73.279,130.588 92.075,138.572 96.55,136.575 L68.101,74.324 Z",
            id: "Shape",
            fill: "#2A5083",
            fillRule: "nonzero"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M84.991,80.553 L92.112,87.674 C93.444,89.006 93.444,91.186 92.112,92.518 L75.49,109.141 C72.199,112.432 66.815,112.432 63.525,109.141 L63.525,109.141 C60.234,105.85 60.234,100.466 63.525,97.176 L80.147,80.554 C81.479,79.221 83.659,79.221 84.991,80.553 Z",
            id: "Shape",
            fill: "#DEEBFF",
            fillRule: "nonzero"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M84.991,80.553 L88.552,84.114 L63.525,109.141 C60.234,105.85 60.234,100.466 63.525,97.176 L80.147,80.554 C81.479,79.221 83.659,79.221 84.991,80.553 Z",
            id: "Shape",
            fill: "#194063",
            fillRule: "nonzero",
            opacity: "0.2"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M-0.498,55.283 L28.325,80.937 L51.755,57.507 L-3.36,33.539 C-3.36,33.539 0.359,49.035 -0.498,55.283 Z",
            id: "Shape",
            fill: "#2A5083",
            fillRule: "nonzero"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M101.639,24.782 C95.065,18.207 79.54,23.071 66.966,35.646 L-3.8,104.412 C-0.71,109.906 -7.835,125.894 -3.324,130.231 L102.759,26.148 C102.431,25.659 102.059,25.201 101.639,24.782 Z",
            id: "Shape",
            fill: "#FFFFFF",
            fillRule: "nonzero"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M-2.536,128.037 C1.44,132.069 9.95,132.567 14.874,135.437 L90.856,59.455 C103.431,46.88 108.295,31.356 101.721,24.781 L-2.536,128.037 Z",
            id: "Shape",
            fill: "#DEEBFF",
            fillRule: "nonzero"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M45.639,41.201 L38.518,34.08 C37.186,32.748 35.006,32.748 33.674,34.08 L17.051,50.702 C13.76,53.993 13.76,59.377 17.051,62.667 L17.051,62.667 C20.342,65.958 25.726,65.958 29.016,62.667 L45.638,46.045 C46.971,44.713 46.971,42.533 45.639,41.201 Z",
            id: "Shape",
            fill: "#FFFFFF",
            fillRule: "nonzero"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M45.639,41.201 L42.078,37.64 L17.051,62.667 C20.342,65.958 25.726,65.958 29.016,62.667 L45.638,46.045 C46.971,44.713 46.971,42.533 45.639,41.201 Z",
            id: "Shape",
            fill: "#DEEBFF",
            fillRule: "nonzero",
            opacity: "0.2"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M71.965,63.574 L63.024,54.633 C61.318,52.927 61.318,50.137 63.024,48.431 L65.099,46.356 C66.805,44.65 69.595,44.65 71.301,46.356 L80.242,55.297 C81.948,57.003 81.948,59.793 80.242,61.499 L78.167,63.574 C76.461,65.28 73.67,65.28 71.965,63.574 Z",
            id: "Shape",
            fill: "#194063",
            fillRule: "nonzero",
            opacity: "0.2"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M71.905,60.107 L71.193,59.395 C70.607,58.809 70.607,57.851 71.193,57.266 L73.946,54.513 C74.532,53.927 75.49,53.927 76.075,54.513 L76.788,55.225 C77.374,55.811 77.374,56.769 76.788,57.354 L74.035,60.107 C73.449,60.693 72.491,60.693 71.905,60.107 Z",
            id: "Shape",
            fill: "#194063",
            fillRule: "nonzero"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M66.965,55.167 L66.252,54.455 C65.666,53.869 65.666,52.911 66.252,52.326 L69.005,49.573 C69.591,48.987 70.549,48.987 71.134,49.573 L71.846,50.285 C72.432,50.871 72.432,51.829 71.846,52.414 L69.093,55.167 C68.509,55.752 67.55,55.752 66.965,55.167 Z",
            id: "Shape",
            fill: "#194063",
            fillRule: "nonzero"
        }))))));
});
parcelRegister("7oMrD", function(module, exports) {
    $parcel$export(module.exports, "fetchUserTraits", ()=>$b19b708ee9b901ca$export$2a353ada541e501f);
    $parcel$export(module.exports, "fetchSiteTraits", ()=>$b19b708ee9b901ca$export$954f05a5dfd487e4);
    var $jcw0u;
    var $eusud;
    const $b19b708ee9b901ca$export$2a353ada541e501f = async (cloudId, accountId)=>{
        try {
            const url = `/gateway/api/tap-delivery/api/v3/personalization/site/${cloudId}/user/${accountId}`;
            const response = await (0, (0, parcelRequire("eusud")).performGetRequest)(url);
            if (response?.attributes) return response.attributes;
            throw Error('response is not defined or null');
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: 'jwmSampleProjectService',
                    packageName: 'jiraAtlassianNavigationRecommendations',
                    teamName: 'navigation'
                },
                error: error,
                sendToPrivacyUnsafeSplunk: true
            });
        }
        return [];
    };
    const $b19b708ee9b901ca$export$954f05a5dfd487e4 = async (cloudId)=>{
        try {
            const url = `/gateway/api/tap-delivery/api/v3/personalization/site/${cloudId}`;
            const response = await (0, (0, parcelRequire("eusud")).performGetRequest)(url);
            if (response?.attributes) return response.attributes;
            throw Error('response is not defined or null');
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: 'jwmSampleProjectService',
                    packageName: 'jiraAtlassianNavigationRecommendations',
                    teamName: 'navigation'
                },
                error: error,
                sendToPrivacyUnsafeSplunk: true
            });
        }
        return [];
    };
});
const $81af7e5cda82bb03$export$b8b9d90f9e5bd72b = 'jira-business.sample-project';
const $81af7e5cda82bb03$export$4e0e125b835dc0d = 'create-jwm-sample-project';
const $81af7e5cda82bb03$export$a1a2e9d60f36ed80 = 'jira_software_ui_moon_experiment_create_jwm_sample_experiment_project_dismissed_first';
const $81af7e5cda82bb03$export$fc53f723f5163588 = 'jira_software_ui_moon_experiment_create_jwm_sample_experiment_project_created_first';
const $81af7e5cda82bb03$export$ce71307e913afd12 = 'jira-core_business_project_created_first';
const $81af7e5cda82bb03$export$7b412f30d02cee13 = '/rest/internal/latest/jwm/sample-project';
const $81af7e5cda82bb03$export$529244c5c5612bf2 = 'jwmSampleProjectCreateFailure';
const $81af7e5cda82bb03$export$63fbd2796586b8eb = 'projects-menu-existing-users';
const $81af7e5cda82bb03$export$a92ddfff80995acf = 'jira_screen_viewed_jwmsampleprojectexperimentdropdown_first';
const $81af7e5cda82bb03$export$9365f74cb008dc7c = 'brain_jsw_to_jwm_implicit_site_boolean';
parcelRegister("3J67Z", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$48f95349ddb3aa80$export$2e2bcd8739ae039);
    var $7VHMR;
    var $48f95349ddb3aa80$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        ctaDescriptionText: {
            "id": "atlassian-navigation-recommendations.jwm-sample-project-recommendation.cta-description-text",
            "defaultMessage": "Business project"
        },
        lozengeText: {
            "id": "atlassian-navigation-recommendations.jwm-sample-project-recommendation.lozenge-text",
            "defaultMessage": "TRY NOW"
        },
        trySampleSectionHeader: {
            "id": "atlassian-navigation-recommendations.jwm-sample-project-recommendation.try-sample-section-header",
            "defaultMessage": "INCLUDED FREE WITH YOUR PLAN"
        },
        dismissConfirmationTitle: {
            "id": "atlassian-navigation-recommendations.jwm-sample-project-recommendation.dismiss-confirmation-title",
            "defaultMessage": "Got it, thanks for letting us know"
        },
        dismissConfirmationText: {
            "id": "atlassian-navigation-recommendations.jwm-sample-project-recommendation.dismiss-confirmation-text",
            "defaultMessage": "You won't be seeing this here anymore"
        },
        ctaHeadingText: {
            "id": "atlassian-navigation-recommendations.jwm-sample-project-recommendation.cta-heading-text",
            "defaultMessage": "Go-to-market sample"
        },
        modalTitle: {
            "id": "atlassian-navigation-recommendations.jwm-sample-project-recommendation.modal-title",
            "defaultMessage": "Why am I seeing sample projects?"
        },
        modalBodySpork: {
            "id": "atlassian-navigation-recommendations.jwm-sample-project-recommendation.modal-body-spork",
            "defaultMessage": "Jira is a powerful project management tool with two project types built for software and business teams. {br} {br}\n        The best part is, since you\u2019re using a software project, you already have access to business projects! {br} {br}\n        Create a sample business project to get set up quickly and start exploring. {br} {br}\n        {link}"
        },
        modalCta: {
            "id": "atlassian-navigation-recommendations.jwm-sample-project-recommendation.modal-cta",
            "defaultMessage": "Ok"
        },
        modalCtaCapitalised: {
            "id": "atlassian-navigation-recommendations.jwm-sample-project-recommendation.modal-cta-capitalised",
            "defaultMessage": "OK"
        },
        learnMoreLinkSpork: {
            "id": "atlassian-navigation-recommendations.jwm-sample-project-recommendation.learn-more-link-spork",
            "defaultMessage": "Learn more about business projects in Jira"
        },
        errorFlagTitle: {
            "id": "atlassian-navigation-recommendations.jwm-sample-project-recommendation.error-flag-title",
            "defaultMessage": "Something went wrong"
        },
        errorFlagText: {
            "id": "atlassian-navigation-recommendations.jwm-sample-project-recommendation.error-flag-text",
            "defaultMessage": "Please reload the page to try again. If you\u2019re still having trouble, please contact Atlassian support to resolve this."
        }
    });
});
parcelRegister("4keKa", function(module, exports) {
    $parcel$export(module.exports, "RecommendationLoader", ()=>$f62159ce20629294$export$f17bc1d33db805f2);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $2NMNM;
    var $d40Fj;
    var $90yGq;
    var $k9JTq;
    var $koeKL;
    var $dkqym;
    var $hqBSi;
    var $DVljb;
    var $9OXo1;
    var $cmzJ2;
    const $f62159ce20629294$export$f17bc1d33db805f2 = ({ recommendations: recommendations })=>{
        const [, { loadRecommendations: loadRecommendations }] = (0, (0, parcelRequire("cmzJ2")).useProjectMenuRecommendations)();
        const accountId = (0, (0, parcelRequire("90yGq")).useAccountId)();
        const locale = (0, (0, parcelRequire("DVljb")).useLocale)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const licensedProducts = (0, (0, parcelRequire("hqBSi")).useLicensedProducts)();
        const firstActivationDateMs = (0, (0, parcelRequire("dkqym")).useFirstActivationDateMs)();
        const environment = (0, $8Uumt.useRelayEnvironment)();
        const { software: edition } = (0, (0, parcelRequire("k9JTq")).useAppEditions)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const [{ hasSuccessOnce: hasSuccessOnce, canCreateProject: canCreateProject }] = (0, (0, parcelRequire("d40Fj")).useCreateProjectPermission)();
        const { areProductSuggestionsEnabled: areProductSuggestionsEnabled } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        (0, $5uXOq.useEffect)(()=>{
            if (hasSuccessOnce && accountId) loadRecommendations(recommendations, {
                locale: locale,
                accountId: accountId,
                cloudId: cloudId,
                licensedProducts: licensedProducts,
                firstActivationDateMs: firstActivationDateMs,
                areProductSuggestionsEnabled: areProductSuggestionsEnabled,
                edition: edition,
                environment: environment,
                canCreateProject: canCreateProject
            }, createAnalyticsEvent({}));
        }, [
            hasSuccessOnce,
            canCreateProject,
            accountId,
            loadRecommendations,
            createAnalyticsEvent,
            locale,
            cloudId,
            licensedProducts,
            firstActivationDateMs,
            areProductSuggestionsEnabled,
            edition,
            environment,
            recommendations
        ]);
        return null;
    };
});
parcelRegister("d40Fj", function(module, exports) {
    $parcel$export(module.exports, "useCreateProjectPermission", ()=>$2e786b3606f3b4dc$export$c92733e8f8c1640f);
    $parcel$export(module.exports, "useCreateProjectPermissionActions", ()=>$2e786b3606f3b4dc$export$d2b8d6f60fe99e2a);
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    var $kEnf8;
    const $2e786b3606f3b4dc$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'create-project-permissions',
        initialState: {
            canCreateProject: false,
            canCreateClassicProject: false,
            canCreateNextGenProject: false,
            isFetching: false,
            fetchError: null,
            promise: null,
            hasFetchedOnce: false,
            hasSuccessOnce: false
        },
        actions: (0, parcelRequire("kEnf8")).actions
    });
    const $2e786b3606f3b4dc$export$5a47fbae725b8f2 = (0, (0, parcelRequire("gg7kZ")).createContainer)($2e786b3606f3b4dc$var$Store);
    const $2e786b3606f3b4dc$export$c92733e8f8c1640f = (0, (0, parcelRequire("3sQ5x")).createHook)($2e786b3606f3b4dc$var$Store);
    const $2e786b3606f3b4dc$export$d2b8d6f60fe99e2a = (0, (0, parcelRequire("3sQ5x")).createHook)($2e786b3606f3b4dc$var$Store, {
        selector: null
    });
});
parcelRegister("kEnf8", function(module, exports) {
    $parcel$export(module.exports, "actions", ()=>$005c9d409c181858$export$e324594224ef24da);
    var $7f4tk;
    const $005c9d409c181858$export$963556ef97965f0b = async ()=>{
        const url = '/rest/internal/2/projectpermissions';
        const result = await (0, (0, parcelRequire("7f4tk")).default)(url);
        return result;
    };
    const $005c9d409c181858$var$handleFetch = ()=>async ({ setState: setState, getState: getState })=>{
            const { isFetching: isFetching } = getState();
            if (!isFetching) try {
                const result = await $005c9d409c181858$export$963556ef97965f0b();
                setState({
                    ...result,
                    isFetching: false,
                    fetchError: null,
                    hasFetchedOnce: true,
                    hasSuccessOnce: true
                });
            } catch (error) {
                setState({
                    canCreateProject: false,
                    canCreateClassicProject: false,
                    canCreateNextGenProject: false,
                    isFetching: false,
                    fetchError: error,
                    hasFetchedOnce: true
                });
            }
        };
    const $005c9d409c181858$export$e324594224ef24da = {
        fetchCreateProjectPermission: ()=>async ({ setState: setState, dispatch: dispatch })=>{
                const promise = dispatch($005c9d409c181858$var$handleFetch());
                setState({
                    isFetching: true,
                    promise: promise
                });
            }
    };
});
parcelRegister("dkqym", function(module, exports) {
    $parcel$export(module.exports, "useFirstActivationDateMs", ()=>$61794ef454831b86$export$9df3a539e50a284b);
    var $9OXo1;
    const $61794ef454831b86$export$9df3a539e50a284b = ()=>{
        const tenantContext = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        return tenantContext && tenantContext.firstActivationDateMs != null ? tenantContext.firstActivationDateMs : null;
    };
});
parcelRegister("9WcgH", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$430f11f9411f5e73$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $lfTZh;
    var $kxZPA;
    const $430f11f9411f5e73$var$LazyCustomThemeAdminFlag = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("aRAQu")), {
        ssr: false,
        moduleId: "./src/packages/theme/custom-theme-admin-flag/src/index.tsx"
    });
    var $430f11f9411f5e73$export$2e2bcd8739ae039 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "async.jira.custom-theme-admin-flag",
            packageName: "customThemeAdminFlag"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "custom-theme-admin-flag",
            fallback: null
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($430f11f9411f5e73$var$LazyCustomThemeAdminFlag, null)));
});
parcelRegister("aRAQu", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("hmVFt").then(()=>parcelRequire('jWgLY'));
});
parcelRegister("bcn6L", function(module, exports) {
    $parcel$export(module.exports, "useFavoriteActions", ()=>$23bcf74af5f22e12$export$e17993c41bc4dc38);
    $parcel$export(module.exports, "useFavoriteBoards", ()=>$23bcf74af5f22e12$export$896ce0ae2b68d03a);
    $parcel$export(module.exports, "useFavoriteDashboards", ()=>$23bcf74af5f22e12$export$7f929adce53e9217);
    $parcel$export(module.exports, "useFavoriteProjects", ()=>$23bcf74af5f22e12$export$e92f6fc9e0c6d135);
    $parcel$export(module.exports, "useFavoriteFilters", ()=>$23bcf74af5f22e12$export$49e6afd92506d2b4);
    $parcel$export(module.exports, "useFavoriteQueues", ()=>$23bcf74af5f22e12$export$b09f7ee747d8bc9);
    $parcel$export(module.exports, "useFavoritePlans", ()=>$23bcf74af5f22e12$export$56f40dc3d5e1af94);
    $parcel$export(module.exports, "FavoritesContainer", ()=>$23bcf74af5f22e12$export$878515009e0790e0);
    var $20jWj;
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    const $23bcf74af5f22e12$var$emptyItemState = {
        items: null,
        isFetching: false,
        fetchError: null,
        promise: null,
        hasFetchedOnce: false,
        hasSuccessOnce: false
    };
    const $23bcf74af5f22e12$export$d4c72bab9d6cc13a = {
        [(0, $49a076eb01652100$export$f95f1a684a17f189)]: {
            ...$23bcf74af5f22e12$var$emptyItemState
        },
        [(0, $49a076eb01652100$export$6e58b6b0aa48a271)]: {
            ...$23bcf74af5f22e12$var$emptyItemState
        },
        [(0, $49a076eb01652100$export$6737b9227f60f39d)]: {
            ...$23bcf74af5f22e12$var$emptyItemState
        },
        [(0, $49a076eb01652100$export$91a9dba2d38d9a6f)]: {
            ...$23bcf74af5f22e12$var$emptyItemState
        },
        [(0, $49a076eb01652100$export$20f1031cc9fdf29e)]: {
            ...$23bcf74af5f22e12$var$emptyItemState
        },
        [(0, $49a076eb01652100$export$eebccb886b064713)]: {
            ...$23bcf74af5f22e12$var$emptyItemState
        }
    };
    const $23bcf74af5f22e12$var$transformItems = (items)=>items.reduce((acc, itemResponse)=>{
            if (itemResponse.id === 'favourite-boards') acc[0, $49a076eb01652100$export$f95f1a684a17f189] = itemResponse.items;
            if (itemResponse.id === 'favourite-filters') acc[0, $49a076eb01652100$export$6737b9227f60f39d] = itemResponse.items;
            if (itemResponse.id === 'favourite-dashboards') acc[0, $49a076eb01652100$export$6e58b6b0aa48a271] = itemResponse.items;
            if (itemResponse.id === 'favourite-projects') acc[0, $49a076eb01652100$export$91a9dba2d38d9a6f] = itemResponse.items;
            if (itemResponse.id === 'favourite-queues') acc[0, $49a076eb01652100$export$20f1031cc9fdf29e] = itemResponse.items;
            if (itemResponse.id === 'favourite-plans') acc[0, $49a076eb01652100$export$eebccb886b064713] = itemResponse.items;
            return acc;
        }, {});
    const $23bcf74af5f22e12$var$loadFavoriteItems = (amount = (0, $7a1562bd0ca086b0$export$ea341e6cc435ed40), types)=>async ({ setState: setState, getState: getState })=>{
            const typesToFetch = types.filter((type)=>!getState()[type].isFetching);
            if (typesToFetch.length) try {
                const promise = (0, (0, parcelRequire("20jWj")).fetchFavoriteItems)({
                    count: amount,
                    types: typesToFetch
                });
                const loadingState = typesToFetch.reduce((acc, type)=>{
                    acc[type] = {
                        ...getState()[type],
                        isFetching: true,
                        fetchError: null,
                        promise: promise
                    };
                    return acc;
                }, {});
                setState(loadingState);
                const itemResponse = await promise;
                const transformed = $23bcf74af5f22e12$var$transformItems(itemResponse);
                const responseState = typesToFetch.reduce((acc, type)=>{
                    acc[type] = {
                        items: transformed[type],
                        isFetching: false,
                        promise: null,
                        fetchError: null,
                        hasFetchedOnce: true,
                        hasSuccessOnce: true
                    };
                    return acc;
                }, {});
                setState(responseState);
            } catch (fetchError) {
                const errorState = typesToFetch.reduce((acc, type)=>{
                    acc[type] = {
                        ...getState()[type],
                        isFetching: false,
                        promise: null,
                        fetchError: fetchError,
                        hasFetchedOnce: true
                    };
                    return acc;
                }, {});
                setState(errorState);
            }
        };
    const $23bcf74af5f22e12$export$e324594224ef24da = {
        loadFavoriteAll: (amount)=>async (actions2, props)=>{
                $23bcf74af5f22e12$var$loadFavoriteItems(amount, [
                    props.applicationPermissions.hasSoftwareAccess ? (0, $49a076eb01652100$export$f95f1a684a17f189) : undefined,
                    (0, $49a076eb01652100$export$6e58b6b0aa48a271),
                    (0, $49a076eb01652100$export$91a9dba2d38d9a6f),
                    (0, $49a076eb01652100$export$6737b9227f60f39d),
                    props.applicationPermissions.hasServiceDeskAccess ? (0, $49a076eb01652100$export$20f1031cc9fdf29e) : undefined,
                    props.applicationPermissions.hasSoftwareAccess ? (0, $49a076eb01652100$export$eebccb886b064713) : undefined
                ].filter(Boolean))(actions2);
            },
        loadFavouriteBoards: (amount)=>$23bcf74af5f22e12$var$loadFavoriteItems(amount, [
                (0, $49a076eb01652100$export$f95f1a684a17f189)
            ]),
        loadFavoriteProjects: (amount)=>$23bcf74af5f22e12$var$loadFavoriteItems(amount, [
                (0, $49a076eb01652100$export$91a9dba2d38d9a6f)
            ]),
        loadFavoriteDashboards: (amount)=>$23bcf74af5f22e12$var$loadFavoriteItems(amount, [
                (0, $49a076eb01652100$export$6e58b6b0aa48a271)
            ]),
        loadFavoriteFilters: (amount)=>$23bcf74af5f22e12$var$loadFavoriteItems(amount, [
                (0, $49a076eb01652100$export$6737b9227f60f39d)
            ]),
        loadFavouriteQueues: (amount)=>$23bcf74af5f22e12$var$loadFavoriteItems(amount, [
                (0, $49a076eb01652100$export$20f1031cc9fdf29e)
            ]),
        loadFavoritePlans: (amount)=>$23bcf74af5f22e12$var$loadFavoriteItems(amount, [
                (0, $49a076eb01652100$export$eebccb886b064713)
            ])
    };
    const $23bcf74af5f22e12$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: (0, $7a1562bd0ca086b0$export$7a3d0824e907e616),
        actions: $23bcf74af5f22e12$export$e324594224ef24da,
        initialState: $23bcf74af5f22e12$export$d4c72bab9d6cc13a
    });
    const $23bcf74af5f22e12$export$e17993c41bc4dc38 = (0, (0, parcelRequire("3sQ5x")).createHook)($23bcf74af5f22e12$var$store, {
        selector: null
    });
    const $23bcf74af5f22e12$export$896ce0ae2b68d03a = (0, (0, parcelRequire("3sQ5x")).createHook)($23bcf74af5f22e12$var$store, {
        selector: (state)=>state[0, $49a076eb01652100$export$f95f1a684a17f189]
    });
    const $23bcf74af5f22e12$export$7f929adce53e9217 = (0, (0, parcelRequire("3sQ5x")).createHook)($23bcf74af5f22e12$var$store, {
        selector: (state)=>state[0, $49a076eb01652100$export$6e58b6b0aa48a271]
    });
    const $23bcf74af5f22e12$export$e92f6fc9e0c6d135 = (0, (0, parcelRequire("3sQ5x")).createHook)($23bcf74af5f22e12$var$store, {
        selector: (state)=>state[0, $49a076eb01652100$export$91a9dba2d38d9a6f]
    });
    const $23bcf74af5f22e12$export$49e6afd92506d2b4 = (0, (0, parcelRequire("3sQ5x")).createHook)($23bcf74af5f22e12$var$store, {
        selector: (state)=>state[0, $49a076eb01652100$export$6737b9227f60f39d]
    });
    const $23bcf74af5f22e12$export$b09f7ee747d8bc9 = (0, (0, parcelRequire("3sQ5x")).createHook)($23bcf74af5f22e12$var$store, {
        selector: (state)=>state[0, $49a076eb01652100$export$20f1031cc9fdf29e]
    });
    const $23bcf74af5f22e12$export$56f40dc3d5e1af94 = (0, (0, parcelRequire("3sQ5x")).createHook)($23bcf74af5f22e12$var$store, {
        selector: (state)=>state[0, $49a076eb01652100$export$eebccb886b064713]
    });
    const $23bcf74af5f22e12$export$878515009e0790e0 = (0, (0, parcelRequire("gg7kZ")).createContainer)($23bcf74af5f22e12$var$store);
});
const $49a076eb01652100$export$91a9dba2d38d9a6f = 'projects';
const $49a076eb01652100$export$ebb48b08205007c5 = 'issues';
const $49a076eb01652100$export$6737b9227f60f39d = 'filters';
const $49a076eb01652100$export$6e58b6b0aa48a271 = 'dashboards';
const $49a076eb01652100$export$20f1031cc9fdf29e = 'queues';
const $49a076eb01652100$export$f95f1a684a17f189 = 'boards';
const $49a076eb01652100$export$3c11110d46d67e8c = 'jwmBoards';
const $49a076eb01652100$export$5e2cc09b58170ca2 = 'addons';
const $49a076eb01652100$export$eebccb886b064713 = 'plans';
const $49a076eb01652100$export$1c2ea36059c73937 = 'alerts';
parcelRegister("20jWj", function(module, exports) {
    $parcel$export(module.exports, "fetchFavoriteItems", ()=>$7a715a28552f71dc$export$ba7e2966560509a6);
    var $6eBRn = parcelRequire("6eBRn");
    var $ebyGx = parcelRequire("ebyGx");
    var $aDvGo;
    var $fPruT;
    const $7a715a28552f71dc$var$handleError = (error)=>{
        throw error;
    };
    const $7a715a28552f71dc$var$convertTypeToPlural = ({ items: items, ...rest })=>({
            ...rest,
            items: items.filter(Boolean).map((item)=>({
                    ...item,
                    type: `${item.type}s`
                }))
        });
    const $7a715a28552f71dc$export$ba7e2966560509a6 = async ({ count: count, types: types = [
        (0, $49a076eb01652100$export$91a9dba2d38d9a6f),
        (0, $49a076eb01652100$export$6e58b6b0aa48a271),
        (0, $49a076eb01652100$export$6737b9227f60f39d),
        (0, $49a076eb01652100$export$eebccb886b064713)
    ] })=>{
        const query = (0, $ebyGx.stringify)((0, ($parcel$interopDefault($6eBRn)))(types.map((type)=>[
                type,
                count
            ])));
        const url = `/rest/internal/2/favourites?${query}`;
        try {
            const response = await fetch(url, (0, (0, parcelRequire("aDvGo")).default));
            if (!response.ok) {
                const errorCode = response.status;
                if (errorCode === 401) return $7a715a28552f71dc$var$handleError(new (0, (0, parcelRequire("fPruT")).AuthenticationError)(`${errorCode}`));
                return $7a715a28552f71dc$var$handleError(new Error(errorCode));
            }
            const resp = await response.json();
            return resp.map($7a715a28552f71dc$var$convertTypeToPlural);
        } catch (error) {
            return $7a715a28552f71dc$var$handleError(error);
        }
    };
    const $7a715a28552f71dc$export$77fc2acdef452939 = async (item)=>{
        const url = '/rest/internal/2/favourites';
        try {
            const response = await fetch(url, {
                ...(0, (0, parcelRequire("aDvGo")).default),
                method: 'POST',
                body: JSON.stringify({
                    entity: {
                        id: item.id,
                        type: item.type
                    },
                    beforeEntityPosition: null
                })
            });
            if (!response.ok) {
                const errorCode = response.status;
                return $7a715a28552f71dc$var$handleError(new Error(errorCode));
            }
            const result = await response.json();
            return result;
        } catch (error) {
            return $7a715a28552f71dc$var$handleError(error);
        }
    };
    const $7a715a28552f71dc$export$acbe6ca9570854bd = async (item)=>{
        const url = `/rest/internal/2/favourites/${item.type}/${item.id}`;
        try {
            const response = await fetch(url, {
                ...(0, (0, parcelRequire("aDvGo")).default),
                method: 'DELETE'
            });
            if (!response.ok) {
                const errorCode = response.status;
                return $7a715a28552f71dc$var$handleError(new Error(errorCode));
            }
            const result = await response.json();
            return result;
        } catch (error) {
            return $7a715a28552f71dc$var$handleError(error);
        }
    };
});
parcelRegister("fPruT", function(module, exports) {
    $parcel$export(module.exports, "AuthenticationError", ()=>$95af1b43dc18895e$export$cf0c46b07324e9c5);
    class $95af1b43dc18895e$export$cf0c46b07324e9c5 extends Error {
        constructor(message){
            super(message);
            this.name = 'AuthenticationError';
            this.skipSentry = true;
            this.statusCode = 401;
        }
    }
});
const $7a1562bd0ca086b0$export$ea341e6cc435ed40 = 15;
const $7a1562bd0ca086b0$export$7a3d0824e907e616 = 'navigation-favourite-store';
parcelRegister("jOWa1", function(module, exports) {
    $parcel$export(module.exports, "useLockScreenController", ()=>$7311a60b2564fbbc$export$e035a0795b3eefc0);
    $parcel$export(module.exports, "useIsScreenLocked", ()=>$7311a60b2564fbbc$export$8a9d757ad1774a77);
    var $lpwtK;
    var $k9JTq;
    var $3sQ5x;
    var $iXqE5;
    var $inMms;
    const $7311a60b2564fbbc$export$111f5c5aa8d2ad30 = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: {
            isLocked: false
        },
        actions: {
            lockScreen: ()=>({ setState: setState })=>setState({
                        isLocked: true
                    }),
            unlockScreen: ()=>({ setState: setState })=>setState({
                        isLocked: false
                    })
        },
        name: 'LockScreenExperiment'
    });
    const $7311a60b2564fbbc$export$e035a0795b3eefc0 = (0, (0, parcelRequire("3sQ5x")).createHook)($7311a60b2564fbbc$export$111f5c5aa8d2ad30);
    const $7311a60b2564fbbc$export$8a9d757ad1774a77 = ()=>{
        const [lockScreenState] = $7311a60b2564fbbc$export$e035a0795b3eefc0();
        const { loading: loadingProject, data: project } = (0, (0, parcelRequire("lpwtK")).useProject)();
        const appEditions = (0, (0, parcelRequire("k9JTq")).useAppEditions)();
        return (0, (0, parcelRequire("inMms")).isTrialEndLockScreenEnabledForAnyProjectType)(appEditions) && !loadingProject && (0, (0, parcelRequire("inMms")).isSupportedProjectType)(project?.type) && lockScreenState.isLocked;
    };
});
parcelRegister("inMms", function(module, exports) {
    $parcel$export(module.exports, "getStatSigCohortAndExposureFunction", ()=>$a02343de72fbd5a9$export$dcea8823e3724239);
    $parcel$export(module.exports, "isSupportedProjectType", ()=>$a02343de72fbd5a9$export$fa630a47d84fb39a);
    $parcel$export(module.exports, "isTrialEndLockScreenEnabledForProject", ()=>$a02343de72fbd5a9$export$cd0c634567b0c255);
    $parcel$export(module.exports, "isTrialEndLockScreenEnabledForAnyProjectType", ()=>$a02343de72fbd5a9$export$26a64e0070bbd19d);
    $parcel$export(module.exports, "getShowForUserUsageUnderLimit", ()=>$a02343de72fbd5a9$export$8d52cd5130a1ee4e);
    var $8Rkzz = parcelRequire("8Rkzz");
    var $2vSU6;
    var $6s1PB;
    var $co1wz;
    var $ldyPw;
    const $a02343de72fbd5a9$var$TRIAL_END_LOCK_SCREEN_JSM_STATSIG_FLAG = 'jsm_trial_end_lock_screen_experiment';
    const $a02343de72fbd5a9$var$TRIAL_END_LOCK_SCREEN_JPD_STATSIG_FLAG = 'jpd_trial_end_lock_screen_experiment';
    const $a02343de72fbd5a9$var$TRIAL_END_LOCK_SCREEN_JSW_STATSIG_FLAG = 'jsw_trial_end_lock_screen_experiment';
    const $a02343de72fbd5a9$var$TRIAL_END_LOCK_SCREEN_JSM_FF = 'jsm-trial-end-lock-screen-experiment_nowmr';
    const $a02343de72fbd5a9$var$TRIAL_END_LOCK_SCREEN_JPD_FF = 'jpd-trial-end-lock-screen-experiment';
    const $a02343de72fbd5a9$var$TRIAL_END_LOCK_SCREEN_JSW_FF = 'jsw-trial-end-lock-screen-experiment_ieq79';
    const $a02343de72fbd5a9$export$37f449592e04a567 = [
        (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT),
        (0, (0, parcelRequire("2vSU6")).PRODUCT_DISCOVERY_PROJECT),
        (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT)
    ];
    const $a02343de72fbd5a9$var$PROJECT_FLAGS = {
        [(0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT)]: {
            [(0, (0, parcelRequire("ldyPw")).STANDARD_EDITION)]: {
                gatingFlagType: 'ff',
                gatingFlag: $a02343de72fbd5a9$var$TRIAL_END_LOCK_SCREEN_JSM_FF,
                statsig: $a02343de72fbd5a9$var$TRIAL_END_LOCK_SCREEN_JSM_STATSIG_FLAG
            }
        },
        [(0, (0, parcelRequire("2vSU6")).PRODUCT_DISCOVERY_PROJECT)]: {
            [(0, (0, parcelRequire("ldyPw")).STANDARD_EDITION)]: {
                gatingFlagType: 'ff',
                gatingFlag: $a02343de72fbd5a9$var$TRIAL_END_LOCK_SCREEN_JPD_FF,
                statsig: $a02343de72fbd5a9$var$TRIAL_END_LOCK_SCREEN_JPD_STATSIG_FLAG
            }
        },
        [(0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT)]: {
            [(0, (0, parcelRequire("ldyPw")).STANDARD_EDITION)]: {
                gatingFlagType: 'ff',
                gatingFlag: $a02343de72fbd5a9$var$TRIAL_END_LOCK_SCREEN_JSW_FF,
                statsig: $a02343de72fbd5a9$var$TRIAL_END_LOCK_SCREEN_JSW_STATSIG_FLAG
            }
        }
    };
    const $a02343de72fbd5a9$export$dcea8823e3724239 = ({ projectType: projectType, edition: edition })=>{
        let expConfig;
        let fireExpExperimentExposure;
        switch(projectType){
            case 0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT:
                if (edition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION)) [expConfig, fireExpExperimentExposure] = (0, (0, parcelRequire("6s1PB")).UNSAFE_noExposureExp)('jsm_trial_end_lock_screen_experiment');
                break;
            case 0, (0, parcelRequire("2vSU6")).PRODUCT_DISCOVERY_PROJECT:
                if (edition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION)) [expConfig, fireExpExperimentExposure] = (0, (0, parcelRequire("6s1PB")).UNSAFE_noExposureExp)('jpd_trial_end_lock_screen_experiment');
                break;
            case 0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT:
                if (edition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION)) [expConfig, fireExpExperimentExposure] = (0, (0, parcelRequire("6s1PB")).UNSAFE_noExposureExp)('jsw_trial_end_lock_screen_experiment');
                break;
            default:
                break;
        }
        return {
            cohort: expConfig ? expConfig.get('cohort', 'not-enrolled') : 'not-enrolled',
            fireExpExperimentExposure: fireExpExperimentExposure ?? (0, ($parcel$interopDefault($8Rkzz)))
        };
    };
    const $a02343de72fbd5a9$export$fa630a47d84fb39a = (value)=>{
        const projectType = (0, (0, parcelRequire("2vSU6")).toProjectType)(value);
        return !!projectType && $a02343de72fbd5a9$export$37f449592e04a567.includes(projectType);
    };
    const $a02343de72fbd5a9$export$cd0c634567b0c255 = ({ projectType: projectType, edition: edition })=>{
        const { cohort: cohort } = $a02343de72fbd5a9$export$dcea8823e3724239({
            projectType: projectType,
            edition: edition
        });
        switch(projectType){
            case 0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT:
                if (edition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION)) return (0, (0, parcelRequire("co1wz")).ff)('jsm-trial-end-lock-screen-experiment_nowmr') && cohort !== 'not-enrolled';
                break;
            case 0, (0, parcelRequire("2vSU6")).PRODUCT_DISCOVERY_PROJECT:
                if (edition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION)) return (0, (0, parcelRequire("co1wz")).ff)('jpd-trial-end-lock-screen-experiment') && cohort !== 'not-enrolled';
                break;
            case 0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT:
                if (edition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION)) return (0, (0, parcelRequire("co1wz")).ff)('jsw-trial-end-lock-screen-experiment_ieq79') && cohort !== 'not-enrolled';
                break;
            default:
                return false;
        }
        return false;
    };
    const $a02343de72fbd5a9$export$26a64e0070bbd19d = (appEditions)=>$a02343de72fbd5a9$export$37f449592e04a567.some((projectType)=>{
            const edition = projectType && appEditions && (0, (0, parcelRequire("ldyPw")).getEditionForProject)(projectType, appEditions);
            return $a02343de72fbd5a9$export$cd0c634567b0c255({
                projectType: projectType,
                edition: edition
            });
        });
    const $a02343de72fbd5a9$export$8d52cd5130a1ee4e = ({ projectType: projectType, edition: edition })=>!!edition && !!$a02343de72fbd5a9$var$PROJECT_FLAGS[projectType][edition]?.showForUserUsageUnderLimit;
});
parcelRegister("6cNb0", function(module, exports) {
    $parcel$export(module.exports, "testIdConcat", ()=>$c1f136d19203abbc$export$794609946480f79c);
    $parcel$export(module.exports, "testIdGenerate", ()=>$c1f136d19203abbc$export$3b86b974ae586844);
    const $c1f136d19203abbc$var$PREFIX = 'atlassian-navigation-';
    const $c1f136d19203abbc$var$SEPARATOR = '--';
    const $c1f136d19203abbc$var$isValid = (value)=>value != null && value !== '';
    const $c1f136d19203abbc$export$794609946480f79c = (id, ...items)=>[
            id,
            ...items
        ].filter(Boolean).join($c1f136d19203abbc$var$SEPARATOR).toLowerCase();
    const $c1f136d19203abbc$export$3b86b974ae586844 = (first, ...rest)=>$c1f136d19203abbc$export$794609946480f79c($c1f136d19203abbc$var$PREFIX + ($c1f136d19203abbc$var$isValid(first) ? `-${first}` : ''), ...rest);
});
parcelRegister("bBulW", function(module, exports) {
    $parcel$export(module.exports, "EditionAwareness", ()=>$60ceaac5e0b6e842$export$a55b02efe3d62583);
    var $5uXOq = parcelRequire("5uXOq");
    var $ayv8Y;
    var $2vSU6;
    var $39xOx;
    var $6s1PB;
    var $4R6GH;
    var $lpwtK;
    var $lfTZh;
    var $ldyPw;
    var $k9JTq;
    var $l8ruj;
    var $e7Hfh;
    var $aeP9L;
    var $bocwx;
    var $4OggB;
    var $4XZMs;
    var $h1xoN;
    const $60ceaac5e0b6e842$var$isEnrolledInJPDFreeToStandard = ()=>{
        const [config] = (0, (0, parcelRequire("6s1PB")).UNSAFE_noExposureExp)('jpd-free-to-upgrade-pill-au-awareness');
        const cohort = config.get('cohort', 'not-enrolled');
        return cohort !== 'not-enrolled';
    };
    const $60ceaac5e0b6e842$var$isEnrolledInCommerceExperiencePaymentMethodEmbed = ()=>{
        const [config] = (0, (0, parcelRequire("6s1PB")).UNSAFE_noExposureExp)('commerce_experience_payment_method_embed');
        const cohort = config.get('cohort', 'not-enrolled');
        return cohort !== 'not-enrolled';
    };
    const $60ceaac5e0b6e842$var$ImplicitJWMTrialPill = (props)=>{
        const isImplicitJWMLicense = (0, (0, parcelRequire("aeP9L")).useIsImplicitJWMLicense)();
        if (!isImplicitJWMLicense) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
            name: "edition-awareness-implicit-jwm-premium-trial"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4XZMs")).TrialPill), {
            ...props,
            productKey: (0, (0, parcelRequire("2vSU6")).projectTypeToCanonicalId)((0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT)),
            projectType: (0, (0, parcelRequire("2vSU6")).CORE_PROJECT)
        }));
    };
    const $60ceaac5e0b6e842$var$ProductAndEditionChecker = ()=>{
        const appEditions = (0, (0, parcelRequire("k9JTq")).useAppEditions)();
        const { loading: loading, data: project } = (0, (0, parcelRequire("lpwtK")).useProject)();
        const siteHasOnlyJira = (0, (0, parcelRequire("e7Hfh")).useSiteHasOnlyJira)();
        const { isEligibleForJsmPremiumPillExperiment: isEligibleForJsmPremiumPillExperiment } = (0, (0, parcelRequire("h1xoN")).useJsmPremiumPillExperiment)({
            edition: (0, (0, parcelRequire("ldyPw")).getEditionForProject)((0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT), appEditions)
        });
        const isJsmAssetsPage = isEligibleForJsmPremiumPillExperiment ? (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT) : undefined;
        const defaultProjectType = siteHasOnlyJira ? (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT) : isJsmAssetsPage;
        const projectType = (0, (0, parcelRequire("2vSU6")).toProjectType)(project?.type) || defaultProjectType;
        if (loading || !projectType) return null;
        const edition = (0, (0, parcelRequire("ldyPw")).getEditionForProject)(projectType, appEditions);
        const productKey = (0, (0, parcelRequire("2vSU6")).projectTypeToCanonicalId)(projectType);
        if (projectType === (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT) || projectType === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT)) switch(edition){
            case 0, (0, parcelRequire("ldyPw")).FREE_EDITION:
                if (projectType === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT)) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
                    name: "edition-awareness-jsm-free-to-standard"
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
                    name: "edition-awareness-button-placeholder",
                    fallback: null
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bocwx")).EditionAwarenessButton), {
                    applicationEdition: "FREE_EDITION",
                    applicationKey: "jira-servicedesk",
                    projectType: projectType
                })));
                return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
                    name: "edition-awareness-jsw-jsm-free"
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4OggB")).FreePill), {
                    projectType: projectType,
                    siteHasJswOnly: siteHasOnlyJira
                }));
            case 0, (0, parcelRequire("ldyPw")).STANDARD_EDITION:
            case 0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION:
                return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
                    name: "edition-awareness-jsw-jsm-standard-premium-trial"
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4XZMs")).TrialPill), {
                    productKey: productKey,
                    projectType: projectType,
                    edition: edition
                }));
            default:
                return null;
        }
        else if (projectType === (0, (0, parcelRequire("2vSU6")).PRODUCT_DISCOVERY_PROJECT)) {
            if ((0, (0, parcelRequire("4R6GH")).fg)('jpd_disable_edition_awareness')) return null;
            switch(edition){
                case 0, (0, parcelRequire("ldyPw")).FREE_EDITION:
                    if ($60ceaac5e0b6e842$var$isEnrolledInJPDFreeToStandard()) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
                        name: "edition-awareness-jpd-free-to-standard"
                    }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
                        name: "edition-awareness-button-placeholder",
                        fallback: null
                    }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bocwx")).EditionAwarenessButton), {
                        applicationEdition: "FREE_EDITION",
                        applicationKey: "jira-product-discovery",
                        projectType: projectType
                    })));
                    return null;
                case 0, (0, parcelRequire("ldyPw")).STANDARD_EDITION:
                case 0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION:
                    return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
                        name: "edition-awareness-jpd-standard-premium-trial"
                    }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4XZMs")).TrialPill), {
                        productKey: productKey,
                        projectType: projectType,
                        edition: edition
                    }));
                default:
                    return null;
            }
        } else if (projectType === (0, (0, parcelRequire("2vSU6")).CORE_PROJECT)) switch(edition){
            case 0, (0, parcelRequire("ldyPw")).FREE_EDITION:
                if (siteHasOnlyJira) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
                    name: "edition-awareness-jsw-jsm-free"
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4OggB")).FreePill), {
                    projectType: (0, (0, parcelRequire("2vSU6")).CORE_PROJECT),
                    siteHasJswOnly: siteHasOnlyJira
                }));
                return null;
            case 0, (0, parcelRequire("ldyPw")).STANDARD_EDITION:
                if (siteHasOnlyJira) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
                    name: "edition-awareness-implicit-jwm-standard-trial"
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4XZMs")).TrialPill), {
                    edition: edition,
                    productKey: "jira-software",
                    projectType: (0, (0, parcelRequire("2vSU6")).CORE_PROJECT)
                }));
                return null;
            case 0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION:
                return (0, ($parcel$interopDefault($5uXOq))).createElement($60ceaac5e0b6e842$var$ImplicitJWMTrialPill, {
                    edition: edition
                });
            default:
                return null;
        }
        return null;
    };
    const $60ceaac5e0b6e842$export$a55b02efe3d62583 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: (0, $af6ccefcb1f83341$export$f19f2573d2fc7010),
            packageName: (0, $af6ccefcb1f83341$export$b8b9d90f9e5bd72b),
            fallback: "unmount",
            teamName: "growth-tako"
        }, $60ceaac5e0b6e842$var$isEnrolledInCommerceExperiencePaymentMethodEmbed() ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ayv8Y")).CommerceExperienceDrawer), null) : null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
            name: "edition-awareness"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($60ceaac5e0b6e842$var$ProductAndEditionChecker, null)));
});
parcelRegister("ayv8Y", function(module, exports) {
    $parcel$export(module.exports, "useCommerceExperienceDrawer", ()=>$70763545ce505f7a$export$3506d4aac350e65);
    $parcel$export(module.exports, "CommerceExperienceDrawer", ()=>$70763545ce505f7a$export$c0cb1f0bf1204191);
    var $5uXOq = parcelRequire("5uXOq");
    var $9kPzn;
    var $1vIa3;
    var $3sQ5x;
    var $iXqE5;
    const $70763545ce505f7a$var$initialState = {
        isDrawerOpen: false,
        url: null
    };
    const $70763545ce505f7a$var$actions = {
        openDrawer: (url)=>({ setState: setState })=>{
                setState({
                    isDrawerOpen: true,
                    url: url
                });
            },
        closeDrawer: ()=>({ setState: setState })=>{
                setState({
                    isDrawerOpen: false,
                    url: null
                });
            }
    };
    const $70763545ce505f7a$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: $70763545ce505f7a$var$initialState,
        actions: $70763545ce505f7a$var$actions,
        name: 'commerce-experience-drawer'
    });
    const $70763545ce505f7a$export$3506d4aac350e65 = (0, (0, parcelRequire("3sQ5x")).createHook)($70763545ce505f7a$var$Store);
    const $70763545ce505f7a$export$c0cb1f0bf1204191 = ()=>{
        const [{ isDrawerOpen: isDrawerOpen, url: url }, { closeDrawer: closeDrawer }] = $70763545ce505f7a$export$3506d4aac350e65();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9kPzn")).default), {
            testId: "commerce-experience-drawer.ui.drawer",
            isOpen: isDrawerOpen,
            onClose: closeDrawer,
            width: "wide",
            overrides: {
                Sidebar: {
                    component: ()=>null
                }
            }
        }, url ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1vIa3")).default), {
            url: url,
            onComplete: closeDrawer,
            onError: closeDrawer
        }) : null);
    };
});
const $af6ccefcb1f83341$export$f19f2573d2fc7010 = 'EditionAwareness';
const $af6ccefcb1f83341$export$b8b9d90f9e5bd72b = 'jiraNavigationAppsSidebarEditionAwareness';
const $af6ccefcb1f83341$export$37efcda5d411f9dc = 10;
const $af6ccefcb1f83341$export$71bbf33843c4812a = 7;
parcelRegister("e7Hfh", function(module, exports) {
    $parcel$export(module.exports, "useSiteHasOnlyJira", ()=>$5e080700484beb4b$export$8ce0db5ac54a6136);
    var $cVzoY;
    var $9OXo1;
    function $5e080700484beb4b$export$8ce0db5ac54a6136() {
        const tenantContext = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        try {
            const explicitlyLicensedProducts = (0, (0, parcelRequire("cVzoY")).default)(tenantContext);
            const firstExplicitlyLicensedProduct = explicitlyLicensedProducts[0];
            return explicitlyLicensedProducts.length === 1 && firstExplicitlyLicensedProduct === (0, $c06e1abca7c13910$export$1346c9aa3cbb477d);
        } catch  {
            return false;
        }
    }
});
parcelRegister("cVzoY", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$386aacc800b616d8$export$2e2bcd8739ae039);
    var $386aacc800b616d8$export$2e2bcd8739ae039 = (tenantContext)=>{
        const { licensedProducts: licensedProducts } = tenantContext;
        const result = [];
        licensedProducts[0, $c06e1abca7c13910$export$db515c8cec1e6f79] && result.push((0, $c06e1abca7c13910$export$db515c8cec1e6f79));
        licensedProducts[0, $c06e1abca7c13910$export$c8c1c8c3f8ddba61] && result.push((0, $c06e1abca7c13910$export$c8c1c8c3f8ddba61));
        licensedProducts[0, $c06e1abca7c13910$export$1346c9aa3cbb477d] && result.push((0, $c06e1abca7c13910$export$1346c9aa3cbb477d));
        licensedProducts[0, $c06e1abca7c13910$export$202f50ee62667e32] && result.push((0, $c06e1abca7c13910$export$202f50ee62667e32));
        return result;
    };
});
const $c06e1abca7c13910$export$c8c1c8c3f8ddba61 = 'jira-servicedesk';
const $c06e1abca7c13910$export$e9c56aa3b027d98f = 'jira-customer-service';
const $c06e1abca7c13910$export$1346c9aa3cbb477d = 'jira-software';
const $c06e1abca7c13910$export$202f50ee62667e32 = 'jira-product-discovery';
const $c06e1abca7c13910$export$db515c8cec1e6f79 = 'jira-core';
const $c06e1abca7c13910$export$c811f21c9ad05dc2 = 'confluence';
const $c06e1abca7c13910$export$961148c98d198270 = 'opsgenie';
parcelRegister("aeP9L", function(module, exports) {
    $parcel$export(module.exports, "useIsImplicitJWMLicense", ()=>$b83d8fd6a0627e41$export$c6283efc3e1cfce);
    var $4Pf62;
    var $ef5Xn;
    var $7trqb;
    var $hqBSi;
    const $b83d8fd6a0627e41$export$c6283efc3e1cfce = ()=>{
        const licensedProducts = (0, (0, parcelRequire("hqBSi")).useLicensedProducts)();
        const licenseStates = (0, (0, parcelRequire("7trqb")).useLicenseStates)();
        return licensedProducts[(0, (0, parcelRequire("4Pf62")).LICENSED_PRODUCTS).JIRA_SOFTWARE] && !licensedProducts[(0, (0, parcelRequire("4Pf62")).LICENSED_PRODUCTS).JIRA_CORE] && licenseStates.core === (0, (0, parcelRequire("ef5Xn")).LICENSE_STATE_ACTIVE) && licenseStates.software === (0, (0, parcelRequire("ef5Xn")).LICENSE_STATE_ACTIVE);
    };
});
parcelRegister("bocwx", function(module, exports) {
    $parcel$export(module.exports, "EditionAwarenessButton", ()=>$61e6eaefd3d39783$export$57ddd9301662f8e4);
    var $jHoa7;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $dvyoy;
    var $2vSU6;
    var $5CSGy;
    var $koVbs;
    var $2KAtx;
    var $8zOmE;
    var $2NMNM;
    var $efvDm;
    var $ldyPw;
    var $koeKL;
    var $cNd4s;
    var $5oBMG;
    var $aKJYy;
    var $dnl6l;
    var $kUyoc;
    const $61e6eaefd3d39783$export$925bb9ccf400a715 = {
        [(0, $c06e1abca7c13910$export$202f50ee62667e32)]: 'user',
        [(0, $c06e1abca7c13910$export$c8c1c8c3f8ddba61)]: 'agent',
        [(0, $c06e1abca7c13910$export$1346c9aa3cbb477d)]: 'user'
    };
    const $61e6eaefd3d39783$var$TargetOfferings = {
        [(0, (0, parcelRequire("ldyPw")).FREE_EDITION)]: (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION)
    };
    const $61e6eaefd3d39783$var$JPD_LIMITED_USERS_LEFT_LIMIT = 1;
    const $61e6eaefd3d39783$var$JSM_LIMITED_USERS_LEFT_LIMIT = 1;
    const $61e6eaefd3d39783$var$JSW_LIMITED_USERS_LEFT_LIMIT = 7;
    const $61e6eaefd3d39783$var$useGetButtonState = (applicationKey, licensedUsers, licensedUserLimit)=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        if (!licensedUsers || !licensedUserLimit) return {
            text: formatMessage((0, (0, parcelRequire("kUyoc")).default).upgrade),
            status: 'default'
        };
        if (applicationKey === (0, $c06e1abca7c13910$export$202f50ee62667e32)) {
            if (licensedUsers >= licensedUserLimit) return {
                text: formatMessage((0, (0, parcelRequire("kUyoc")).default).noCreatorsLeft),
                status: 'danger'
            };
            if (licensedUsers > $61e6eaefd3d39783$var$JPD_LIMITED_USERS_LEFT_LIMIT) return {
                text: formatMessage((0, (0, parcelRequire("kUyoc")).default).limitedCreatorsLeft),
                status: 'warning'
            };
        }
        if (applicationKey === (0, $c06e1abca7c13910$export$c8c1c8c3f8ddba61)) {
            if (licensedUsers >= licensedUserLimit) return {
                text: formatMessage((0, (0, parcelRequire("kUyoc")).default).noAgentsLeft),
                status: 'danger'
            };
            if (licensedUsers > $61e6eaefd3d39783$var$JSM_LIMITED_USERS_LEFT_LIMIT) return {
                text: formatMessage((0, (0, parcelRequire("kUyoc")).default).limitedAgentsLeft),
                status: 'warning'
            };
        }
        if (applicationKey === (0, $c06e1abca7c13910$export$1346c9aa3cbb477d)) {
            if (licensedUsers >= licensedUserLimit) return {
                text: formatMessage((0, (0, parcelRequire("kUyoc")).default).noUsersLeft),
                status: 'danger'
            };
            if (licensedUsers >= $61e6eaefd3d39783$var$JSW_LIMITED_USERS_LEFT_LIMIT) return {
                text: formatMessage((0, (0, parcelRequire("kUyoc")).default).limitedUsersLeft),
                status: 'warning'
            };
        }
        return {
            text: formatMessage((0, (0, parcelRequire("kUyoc")).default).upgrade),
            status: 'default'
        };
    };
    const $61e6eaefd3d39783$var$MODAL_PROPS = {
        width: 968
    };
    const $61e6eaefd3d39783$export$57ddd9301662f8e4 = (props)=>{
        const { applicationEdition: applicationEdition, applicationKey: applicationKey, projectType: projectType } = props;
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const productKey = (0, (0, parcelRequire("2vSU6")).projectTypeToCanonicalId)(projectType);
        const chargeElement = $61e6eaefd3d39783$export$925bb9ccf400a715[applicationKey];
        const offeringKey = (0, (0, parcelRequire("5CSGy")).getProductOfferingKey)(applicationKey, $61e6eaefd3d39783$var$TargetOfferings[applicationEdition]);
        const data = (0, $8Uumt.useLazyLoadQuery)((0, (0, parcelRequire("jHoa7")).default), {
            cloudId: cloudId,
            hamsProductKey: productKey,
            chargeElement: chargeElement,
            offeringKey: offeringKey
        });
        const entitlement = data.tenantContexts?.[0]?.entitlementInfo?.entitlement;
        const licensedUsers = entitlement?.latestUsageForChargeElement;
        const canUpgrade = entitlement?.experienceCapabilities?.changeOfferingV2?.isAvailableToUser && entitlement?.experienceCapabilities?.changeOfferingV2.experienceUrl;
        const shouldSeeButton = (0, (0, parcelRequire("aKJYy")).useShouldSeeButton)({
            applicationEdition: applicationEdition,
            applicationKey: applicationKey,
            canUpgrade: Boolean(canUpgrade)
        });
        if (!shouldSeeButton || !offeringKey) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement($61e6eaefd3d39783$var$EditionAwarenessButtonInner, {
            ...props,
            licensedUsers: licensedUsers,
            productKey: productKey,
            offeringKey: offeringKey
        });
    };
    const $61e6eaefd3d39783$var$EditionAwarenessButtonInner = (props)=>{
        const { applicationEdition: applicationEdition, applicationKey: applicationKey, projectType: projectType, licensedUsers: licensedUsers, productKey: productKey, offeringKey: offeringKey } = props;
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const isSiteAdmin = (0, (0, parcelRequire("cNd4s")).useIsSiteAdmin)();
        const productEntitlementDetails = (0, (0, parcelRequire("5oBMG")).useProductEntitlementDetails)();
        const licensedUserLimit = productEntitlementDetails?.[applicationKey]?.unitCount;
        const billingSourceSystem = productEntitlementDetails?.[applicationKey]?.billingSourceSystem === 'CCP' ? 'CCP' : 'HAMS';
        const { text: text, status: status } = $61e6eaefd3d39783$var$useGetButtonState(applicationKey, licensedUsers, licensedUserLimit);
        const buttonAnalyticsAttributes = (0, $5uXOq.useMemo)(()=>({
                applicationEdition: applicationEdition,
                applicationKey: applicationKey,
                appearance: status,
                licensedUsers: licensedUsers,
                licensedUserLimit: licensedUserLimit,
                isSiteAdmin: isSiteAdmin,
                billingSourceSystem: billingSourceSystem
            }), [
            applicationEdition,
            applicationKey,
            status,
            licensedUsers,
            licensedUserLimit,
            isSiteAdmin,
            billingSourceSystem
        ]);
        const handleOnClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'button'
            }), 'editionAwarenessButton', buttonAnalyticsAttributes);
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'button'
            }), 'editionAwarenessFreePill', buttonAnalyticsAttributes);
        }, [
            createAnalyticsEvent,
            buttonAnalyticsAttributes
        ]);
        (0, $5uXOq.useEffect)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'viewed',
                actionSubject: 'button'
            }), 'editionAwarenessButton', buttonAnalyticsAttributes);
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'viewed',
                actionSubject: 'button'
            }), 'editionAwarenessFreePill', buttonAnalyticsAttributes);
        }, [
            buttonAnalyticsAttributes,
            createAnalyticsEvent
        ]);
        const entryPointProps = (0, $5uXOq.useMemo)(()=>({
                applicationEdition: applicationEdition,
                applicationKey: applicationKey
            }), [
            applicationEdition,
            applicationKey
        ]);
        const entryPointParams = (0, $5uXOq.useMemo)(()=>({
                jiraApplicationKey: (0, (0, parcelRequire("efvDm")).getApplicationKeyForProject)(projectType),
                cloudId: cloudId,
                productKey: productKey,
                offeringKey: offeringKey ?? '',
                chargeElement: $61e6eaefd3d39783$export$925bb9ccf400a715[applicationKey]
            }), [
            applicationKey,
            cloudId,
            offeringKey,
            productKey,
            projectType
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2KAtx")).ModalEntryPointPressableTrigger), {
            entryPoint: (0, (0, parcelRequire("dnl6l")).editionAwarenessModalEntryPoint),
            entryPointParams: entryPointParams,
            entryPointProps: entryPointProps,
            modalProps: $61e6eaefd3d39783$var$MODAL_PROPS,
            interactionName: "edition-awareness-modal",
            useInternalModal: false
        }, ({ ref: ref })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dvyoy")).EditionAwarenessButton), {
                status: status,
                upgradeIconType: "gem",
                ref: ref,
                onClick: handleOnClick
            }, text));
    };
});
parcelRegister("jHoa7", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$5211c7c5cc82f413$export$2e2bcd8739ae039);
    const $5211c7c5cc82f413$var$node = function() {
        var v0 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "chargeElement"
        }, v1 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cloudId"
        }, v2 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "hamsProductKey"
        }, v3 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "offeringKey"
        }, v4 = [
            {
                "items": [
                    {
                        "kind": "Variable",
                        "name": "cloudIds.0",
                        "variableName": "cloudId"
                    }
                ],
                "kind": "ListValue",
                "name": "cloudIds"
            }
        ], v5 = [
            {
                "kind": "Variable",
                "name": "hamsProductKey",
                "variableName": "hamsProductKey"
            }
        ], v6 = [
            {
                "kind": "Variable",
                "name": "offeringKey",
                "variableName": "offeringKey"
            }
        ], v7 = {
            "kind": "ScalarField",
            "name": "experienceUrl"
        }, v8 = {
            "kind": "ScalarField",
            "name": "isAvailableToUser"
        }, v9 = {
            "args": [
                {
                    "kind": "Variable",
                    "name": "chargeElement",
                    "variableName": "chargeElement"
                }
            ],
            "kind": "ScalarField",
            "name": "latestUsageForChargeElement"
        }, v10 = {
            "kind": "ScalarField",
            "name": "__typename"
        };
        return {
            "fragment": {
                "argumentDefinitions": [
                    v0,
                    v1,
                    v2,
                    v3
                ],
                "kind": "Fragment",
                "name": "editionAwarenessNextQuery",
                "selections": [
                    {
                        "args": v4,
                        "concreteType": "TenantContext",
                        "kind": "LinkedField",
                        "name": "tenantContexts",
                        "plural": true,
                        "selections": [
                            {
                                "args": v5,
                                "kind": "LinkedField",
                                "name": "entitlementInfo",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "LinkedField",
                                        "name": "entitlement",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "LinkedField",
                                                "name": "experienceCapabilities",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "args": v6,
                                                        "kind": "LinkedField",
                                                        "name": "changeOfferingV2",
                                                        "plural": false,
                                                        "selections": [
                                                            v7,
                                                            v8
                                                        ]
                                                    }
                                                ]
                                            },
                                            v9
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": [
                    v1,
                    v2,
                    v0,
                    v3
                ],
                "kind": "Operation",
                "name": "editionAwarenessNextQuery",
                "selections": [
                    {
                        "args": v4,
                        "concreteType": "TenantContext",
                        "kind": "LinkedField",
                        "name": "tenantContexts",
                        "plural": true,
                        "selections": [
                            {
                                "args": v5,
                                "kind": "LinkedField",
                                "name": "entitlementInfo",
                                "plural": false,
                                "selections": [
                                    v10,
                                    {
                                        "kind": "LinkedField",
                                        "name": "entitlement",
                                        "plural": false,
                                        "selections": [
                                            v10,
                                            {
                                                "kind": "LinkedField",
                                                "name": "experienceCapabilities",
                                                "plural": false,
                                                "selections": [
                                                    v10,
                                                    {
                                                        "args": v6,
                                                        "kind": "LinkedField",
                                                        "name": "changeOfferingV2",
                                                        "plural": false,
                                                        "selections": [
                                                            v10,
                                                            v7,
                                                            v8
                                                        ]
                                                    }
                                                ]
                                            },
                                            v9,
                                            {
                                                "kind": "ScalarField",
                                                "name": "id"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "1feba7d011b8326c5b311fec396ac9bfe8057f79358ce35fe89f18f579dcad11",
                "metadata": {},
                "name": "editionAwarenessNextQuery",
                "operationKind": "query",
                "text": null
            }
        };
    }();
    $5211c7c5cc82f413$var$node.hash = "470a189e90dc4df1cb98da57e70731aa";
    var $5211c7c5cc82f413$export$2e2bcd8739ae039 = $5211c7c5cc82f413$var$node;
});
parcelRegister("5CSGy", function(module, exports) {
    $parcel$export(module.exports, "getProductOfferingKey", ()=>$75290a6120824a25$export$581b2a05d59c1826);
    $parcel$export(module.exports, "getProductOfferingKeyForProject", ()=>$75290a6120824a25$export$3ad43d25ded909ee);
    $parcel$export(module.exports, "getProductOfferingKeyOrThrow", ()=>$75290a6120824a25$export$92c311d982d129d5);
    var $4R6GH;
    var $1laI5;
    const $75290a6120824a25$var$JSW_PRODUCT_OFFERING_KEYS = {
        FREE_EDITION: 'de2887c9-8a55-41d5-b5cf-ad6a5589ebed',
        STANDARD_EDITION: 'a70b5cbb-1ae1-4003-8f4a-9001a4a50526',
        PREMIUM_EDITION: '6dd805b4-da75-4374-a7a7-cf0b12f7ea07',
        UNLICENSED: null
    };
    const $75290a6120824a25$var$JPD_PRODUCT_OFFERING_KEYS = {
        FREE_EDITION: 'd843dd9e-23c5-4d27-af44-5009d568cb6e',
        STANDARD_EDITION: '35b9268e-66e2-45fb-81d3-dacd1e31831a',
        PREMIUM_EDITION: 'f0b24ca7-c3d8-4cac-b5ed-60f4152d4dcd',
        UNLICENSED: null
    };
    const $75290a6120824a25$var$JSM_PRODUCT_OFFERING_KEYS_OLD = {
        FREE_EDITION: '24656f1f-5c14-45ea-88ea-142b9c633661',
        STANDARD_EDITION: '396efb16-749b-41fa-9cd1-ad10cfefa1d0',
        PREMIUM_EDITION: '0c6683b4-4633-4673-beca-690d171de6a5',
        UNLICENSED: null
    };
    const $75290a6120824a25$var$JSM_PRODUCT_OFFERING_KEYS = {
        FREE_EDITION: '24656f1f-5c14-45ea-88ea-142b9c633661',
        STANDARD_EDITION: '99bcbf6c-4fff-4a45-8303-a854e7fbd17c',
        PREMIUM_EDITION: '0c6683b4-4633-4673-beca-690d171de6a5',
        UNLICENSED: null
    };
    const $75290a6120824a25$export$581b2a05d59c1826 = (applicationKey, applicationEdition)=>{
        switch(applicationKey){
            case 0, $c06e1abca7c13910$export$1346c9aa3cbb477d:
                return $75290a6120824a25$var$JSW_PRODUCT_OFFERING_KEYS[applicationEdition];
            case 0, $c06e1abca7c13910$export$c8c1c8c3f8ddba61:
                return (0, (0, parcelRequire("4R6GH")).fg)('jsm_repackaged_offering_key') ? $75290a6120824a25$var$JSM_PRODUCT_OFFERING_KEYS[applicationEdition] : $75290a6120824a25$var$JSM_PRODUCT_OFFERING_KEYS_OLD[applicationEdition];
            case 0, $c06e1abca7c13910$export$202f50ee62667e32:
                return $75290a6120824a25$var$JPD_PRODUCT_OFFERING_KEYS[applicationEdition];
            default:
                return null;
        }
    };
    const $75290a6120824a25$export$3ad43d25ded909ee = (project, edition)=>{
        const application = (0, (0, parcelRequire("1laI5")).projectToApplicationKey)(project);
        const offeringKey = $75290a6120824a25$export$581b2a05d59c1826(application, edition);
        if (offeringKey === null) throw new Error(`Missing offering key for: ${application} ${edition}`);
        return offeringKey;
    };
    const $75290a6120824a25$export$92c311d982d129d5 = (applicationKey, applicationEdition)=>{
        const offeringKey = $75290a6120824a25$export$581b2a05d59c1826(applicationKey, applicationEdition);
        if (offeringKey === null) throw new Error(`Missing offering key for: ${applicationKey} ${applicationEdition}`);
        return offeringKey;
    };
});
parcelRegister("1laI5", function(module, exports) {
    $parcel$export(module.exports, "getTrialDaysLeft", ()=>$39ed199f3e5d9f33$export$954628993bf3188e);
    $parcel$export(module.exports, "projectToApplicationKey", ()=>$39ed199f3e5d9f33$export$1af4fd6f5c543149);
    var $2vSU6;
    const $39ed199f3e5d9f33$export$9dbbf537dffaafc7 = (timestamp)=>new Date(timestamp).setUTCHours(0, 0, 0, 0);
    const $39ed199f3e5d9f33$export$954628993bf3188e = (trialEndTime)=>trialEndTime && Math.ceil(($39ed199f3e5d9f33$export$9dbbf537dffaafc7(trialEndTime) - Date.now()) / 86400000);
    const $39ed199f3e5d9f33$export$271517afaa0c15eb = (trialEndTime)=>{
        const trialDaysLeft = $39ed199f3e5d9f33$export$954628993bf3188e(trialEndTime);
        return !trialDaysLeft || trialDaysLeft < 0;
    };
    const $39ed199f3e5d9f33$export$1af4fd6f5c543149 = (project)=>{
        switch(project){
            case 0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT:
            case 0, (0, parcelRequire("2vSU6")).CORE_PROJECT:
                return 0, $c06e1abca7c13910$export$1346c9aa3cbb477d;
            case 0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT:
                return 0, $c06e1abca7c13910$export$c8c1c8c3f8ddba61;
            case 0, (0, parcelRequire("2vSU6")).PRODUCT_DISCOVERY_PROJECT:
                return 0, $c06e1abca7c13910$export$202f50ee62667e32;
            default:
                throw new Error(`Unsupported project: ${project}`);
        }
    };
});
parcelRegister("2KAtx", function(module, exports) {
    $parcel$export(module.exports, "ModalEntryPointPressableTrigger", ()=>$a17c22dd70443cfd$export$defad645fcc21c0a);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $iwwXK;
    var $1R1Ge;
    var $7QSqG;
    var $juxqI;
    var $jgVsb;
    var $gofOI;
    var $luBm6;
    var $kMSay;
    var $9fuVs;
    var $4R6GH;
    var $c5vfY;
    var $7mPT9;
    var $gJdig;
    var $fiv1r;
    var $dWBzX;
    const $a17c22dd70443cfd$var$emptyObject = {};
    const $a17c22dd70443cfd$export$defad645fcc21c0a = ({ children: children, entryPoint: entryPoint, entryPointParams: entryPointParams, entryPointProps: entryPointProps, errorAttributes: errorAttributes, Fallback: Fallback, interactionName: interactionName, title: title, modalProps: modalProps, useInternalModal: useInternalModal, forwardedRef: forwardedRef })=>{
        const { openModal: openModal } = (0, $5uXOq.useContext)((0, (0, parcelRequire("gJdig")).ModalContext));
        const environmentProvider = (0, (0, parcelRequire("fiv1r")).useJiraRelayEnvironmentProvider)();
        const pressTracing = (0, (0, parcelRequire("kMSay")).default)(interactionName ?? '');
        const load = (0, $5uXOq.useCallback)(()=>(0, $8Uumt.loadEntryPoint)(environmentProvider, entryPoint, entryPointParams ?? $a17c22dd70443cfd$var$emptyObject), [
            entryPoint,
            entryPointParams,
            environmentProvider
        ]);
        const onBeforeLoad = (0, $5uXOq.useCallback)(()=>{
            if (interactionName) pressTracing();
        }, [
            interactionName,
            pressTracing
        ]);
        const onLoad = (0, $5uXOq.useCallback)(({ reference: entryPointReference, dispose: dispose })=>{
            openModal(({ onClose: closeModal })=>{
                const onClose = ()=>{
                    entryPointProps?.onClose?.();
                    closeModal();
                    dispose();
                };
                const defaultFallbackInner = (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jgVsb")).default), null, title ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gofOI")).default), null, title) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("luBm6")).default), {
                    grow: "fill"
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iwwXK")).default), null))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7QSqG")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iwwXK")).default), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iwwXK")).default), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iwwXK")).default), null)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("juxqI")).default), null));
                const defaultFallback = useInternalModal ? defaultFallbackInner : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1R1Ge")).default), {
                    onClose: closeModal,
                    width: modalProps?.width,
                    height: modalProps?.height
                }, defaultFallbackInner);
                const container = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c5vfY")).InternalEntryPointContainer), {
                    entryPointReference: entryPointReference,
                    errorAttributes: errorAttributes,
                    errorFallback: "flag",
                    fallback: Fallback ? (0, ($parcel$interopDefault($5uXOq))).createElement(Fallback, {
                        onClose: closeModal
                    }) : defaultFallback,
                    id: entryPoint.root.getModuleName?.() ?? 'unknown',
                    placeholderName: "modal-entry-point-pressable-trigger-container",
                    runtimeProps: {
                        ...entryPointProps ?? $a17c22dd70443cfd$var$emptyObject,
                        onClose: onClose
                    }
                });
                return useInternalModal ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9fuVs")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1R1Ge")).default), {
                    ...modalProps,
                    onClose: onClose
                }, container)) : container;
            });
        }, [
            entryPoint,
            entryPointProps,
            errorAttributes,
            Fallback,
            openModal,
            title,
            modalProps,
            useInternalModal
        ]);
        const triggerRef = (0, (0, parcelRequire("dWBzX")).usePressablePreloadRef)({
            load: load,
            onBeforeLoad: onBeforeLoad,
            onLoad: onLoad
        });
        if (forwardedRef != null && (0, (0, parcelRequire("4R6GH")).fg)('forward_ref_modalentrypointdropdownitemtrigger')) {
            const ref = (0, (0, parcelRequire("7mPT9")).default)(triggerRef, forwardedRef);
            return children({
                ref: ref
            });
        }
        return children({
            ref: triggerRef
        });
    };
});
parcelRegister("9fuVs", function(module, exports) {
    $parcel$export(module.exports, "ShortcutRegistryContext", ()=>$1dd87679d9ca363b$export$ebbb59327adb551a);
    $parcel$export(module.exports, "default", ()=>$1dd87679d9ca363b$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $k4r7E;
    const $1dd87679d9ca363b$export$ebbb59327adb551a = (0, $5uXOq.createContext)((0, (0, parcelRequire("k4r7E")).rootRegistry));
    const $1dd87679d9ca363b$var$ShortcutScope = ({ children: children = null })=>{
        const registry = (0, $5uXOq.useMemo)(()=>new (0, (0, parcelRequire("k4r7E")).ShortcutRegistry)(), []);
        const parentRegistry = (0, $5uXOq.useContext)($1dd87679d9ca363b$export$ebbb59327adb551a) || (0, (0, parcelRequire("k4r7E")).rootRegistry);
        (0, $5uXOq.useEffect)(()=>{
            const removeRegistry = parentRegistry.addChildRegistry(registry);
            return removeRegistry;
        }, []);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($1dd87679d9ca363b$export$ebbb59327adb551a.Provider, {
            value: registry
        }, children);
    };
    var $1dd87679d9ca363b$export$2e2bcd8739ae039 = false ? ({ children: children = null })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children) : $1dd87679d9ca363b$var$ShortcutScope;
});
parcelRegister("k4r7E", function(module, exports) {
    $parcel$export(module.exports, "ShortcutRegistry", ()=>$a65fecdbaed23de2$export$ca1c75b575abf5ff);
    $parcel$export(module.exports, "rootRegistry", ()=>$a65fecdbaed23de2$export$1dd4c61d1ce1fa15);
    var $lbWm4 = parcelRequire("lbWm4");
    var $561FV = parcelRequire("561FV");
    var $hGgHi = parcelRequire("hGgHi");
    var $FISJv = parcelRequire("FISJv");
    var $lIZQG;
    const $a65fecdbaed23de2$var$validateKeyMaps = (keyMaps)=>{
        (0, ($parcel$interopDefault($lbWm4)))(keyMaps, (keyMap, keyMapIndex)=>{
            const shortcuts = Object.keys(keyMap);
            (0, ($parcel$interopDefault($lbWm4)))(shortcuts, (shortcut, shortcutIndex)=>{
                if (!(0, (0, parcelRequire("lIZQG")).isValidShortcut)(shortcut)) console.error(`"${shortcut}" is an incorrect shortcut. Please, update it according to a playbook entry on shortcuts.`);
                for(let indexForOtherKeymaps = keyMapIndex + 1; indexForOtherKeymaps < keyMaps.length; indexForOtherKeymaps += 1){
                    const anotherKeyMap = keyMaps[indexForOtherKeymaps];
                    (0, ($parcel$interopDefault($lbWm4)))(anotherKeyMap, (anotherAction, anotherShortcut)=>{
                        if ((0, (0, parcelRequire("lIZQG")).isShortcutCollision)(anotherShortcut, shortcut)) {
                            if ((0, ($parcel$interopDefault($hGgHi)))(keyMap[shortcut].priority) && (0, ($parcel$interopDefault($hGgHi)))(anotherKeyMap[anotherShortcut].priority) || keyMap[shortcut].priority === anotherKeyMap[anotherShortcut].priority) console.error(`"${shortcut}" has a collision with "${anotherShortcut}" shortcut from another keyMap.`);
                        }
                    });
                }
                for(let indexToCompareSelf = shortcutIndex + 1; indexToCompareSelf < shortcuts.length; indexToCompareSelf += 1)if ((0, (0, parcelRequire("lIZQG")).isShortcutCollision)(shortcut, shortcuts[indexToCompareSelf])) console.error(`"${shortcut}" has a collision with "${shortcuts[indexToCompareSelf]}" shortcut from the same keyMap.`);
            });
        });
    };
    const $a65fecdbaed23de2$var$mergeKeyMaps = (keyMapsToMerge)=>keyMapsToMerge.reduce((prev, keyMap)=>({
                ...prev,
                ...Object.keys(keyMap).reduce((newAccumulator, newKey)=>{
                    if (!(0, ($parcel$interopDefault($561FV)))(prev, newKey) || (prev[newKey].priority ?? 0) < (keyMap[newKey].priority ?? 1)) return {
                        ...newAccumulator,
                        [newKey]: keyMap[newKey]
                    };
                    return newAccumulator;
                }, {})
            }), {});
    class $a65fecdbaed23de2$export$ca1c75b575abf5ff {
        addChildRegistry(child) {
            this.children.push(child);
            return ()=>{
                (0, ($parcel$interopDefault($FISJv)))(this.children, child);
            };
        }
        addKeyMap(getKeyMap) {
            this.keyMapRetrievers.push(getKeyMap);
            return ()=>{
                (0, ($parcel$interopDefault($FISJv)))(this.keyMapRetrievers, getKeyMap);
            };
        }
        getActiveKeyMap() {
            const keyMaps = this.children.length ? this.children.map((child)=>child.getActiveKeyMap()) : this.keyMapRetrievers.map((getKeyMap)=>getKeyMap().keyMap);
            return {
                ...$a65fecdbaed23de2$var$mergeKeyMaps(keyMaps)
            };
        }
        getKeyMapInfo() {
            const info = this.children.length ? this.children.map((child)=>child.getKeyMapInfo()) : this.keyMapRetrievers.map((getInfo)=>getInfo());
            const flattenedInfo = [].concat(...info);
            return flattenedInfo;
        }
        constructor(){
            this.children = [];
            this.keyMapRetrievers = [];
        }
    }
    const $a65fecdbaed23de2$export$1dd4c61d1ce1fa15 = new $a65fecdbaed23de2$export$ca1c75b575abf5ff();
});
parcelRegister("lIZQG", function(module, exports) {
    $parcel$export(module.exports, "toKeyWithModifiers", ()=>$ca3344250c7b7406$export$8cf0d2c3d060a0c2);
    $parcel$export(module.exports, "isValidShortcut", ()=>$ca3344250c7b7406$export$8e231506c5580162);
    $parcel$export(module.exports, "isShortcutCollision", ()=>$ca3344250c7b7406$export$4add9e50cb49d130);
    const $ca3344250c7b7406$var$isNonCharacterKey = (e)=>e.key.length > 1 || e.key === ' ';
    const $ca3344250c7b7406$var$modifiers = [
        {
            name: 'ctrl',
            test: (e)=>e.ctrlKey
        },
        {
            name: 'shift',
            test: (e)=>e.shiftKey && $ca3344250c7b7406$var$isNonCharacterKey(e)
        },
        {
            name: 'meta',
            test: (e)=>e.metaKey || e.getModifierState && e.getModifierState('OS')
        }
    ];
    const $ca3344250c7b7406$var$toKey = (e)=>{
        if ($ca3344250c7b7406$var$isNonCharacterKey(e)) return e.key.toLowerCase();
        return e.shiftKey ? e.key.toUpperCase() : e.key.toLowerCase();
    };
    const $ca3344250c7b7406$export$8cf0d2c3d060a0c2 = (e)=>{
        if (!e || !e.key) return {
            sequence: '',
            e: e
        };
        return {
            sequence: $ca3344250c7b7406$var$modifiers.filter((modifier)=>modifier.test(e)).map((modifier)=>modifier.name).concat($ca3344250c7b7406$var$toKey(e)).join('+'),
            e: e
        };
    };
    const $ca3344250c7b7406$var$orSpecialChars = ' |arrowdown|arrowleft|arrowright|arrowup|backspace|clear|delete|end|enter|escape|home|insert|pagedown|pageup|pause|printscreen|tab';
    const $ca3344250c7b7406$var$singleShortcutEntry = `(ctrl\\+)?(shift\\+(?=(?:(?:meta\\+)?(?:${$ca3344250c7b7406$var$orSpecialChars}))))?(meta\\+)?(${$ca3344250c7b7406$var$orSpecialChars}|[!-~])`;
    const $ca3344250c7b7406$var$shortcutRegex = RegExp(`^${$ca3344250c7b7406$var$singleShortcutEntry}( ${$ca3344250c7b7406$var$singleShortcutEntry})*$`);
    const $ca3344250c7b7406$export$8e231506c5580162 = (shortcut)=>$ca3344250c7b7406$var$shortcutRegex.test(shortcut);
    const $ca3344250c7b7406$export$4add9e50cb49d130 = (leftShortcut, rightShortcut)=>{
        if (!leftShortcut || !rightShortcut) return leftShortcut === rightShortcut;
        if (rightShortcut === leftShortcut) return true;
        if (leftShortcut.indexOf(rightShortcut) === 0) return leftShortcut[rightShortcut.length] === ' ';
        if (rightShortcut.indexOf(leftShortcut) === 0) return rightShortcut[leftShortcut.length] === ' ';
        return false;
    };
});
parcelRegister("7mPT9", function(module, exports) {
    $parcel$export(module.exports, "mergeRefs", ()=>$d1da29f25d5b5e0a$export$c9058316764c140e);
    $parcel$export(module.exports, "default", ()=>$d1da29f25d5b5e0a$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    function $d1da29f25d5b5e0a$export$c9058316764c140e(...refs) {
        return (value)=>{
            refs.forEach((ref)=>{
                if (typeof ref === 'function') ref(value);
                else if (ref != null) ref.current = value;
            });
        };
    }
    function $d1da29f25d5b5e0a$export$2e2bcd8739ae039(...refs) {
        return (0, $5uXOq.useMemo)(()=>$d1da29f25d5b5e0a$export$c9058316764c140e(...refs), refs);
    }
});
parcelRegister("dWBzX", function(module, exports) {
    $parcel$export(module.exports, "usePressablePreloadRef", ()=>$01294bc664dce1e5$export$1ff47f24e06a5d2d);
    var $5uXOq = parcelRequire("5uXOq");
    const $01294bc664dce1e5$var$PRELOAD_MAX_AGE = 2000;
    const $01294bc664dce1e5$var$TIME_TO_INTENT = 200;
    function $01294bc664dce1e5$export$1ff47f24e06a5d2d({ load: load, onBeforeLoad: onBeforeLoad, onLoad: onLoad }) {
        const ref = (0, $5uXOq.useRef)(null);
        (0, $5uXOq.useEffect)(()=>{
            const el = ref.current;
            if (!el) return;
            const request = {};
            const clearPreloadTimeouts = ()=>{
                if (typeof request.preloadIntentTimeout === 'number') {
                    clearTimeout(request.preloadIntentTimeout);
                    delete request.preloadIntentTimeout;
                }
                if (typeof request.preloadMaxAgeTimeout === 'number') {
                    clearTimeout(request.preloadMaxAgeTimeout);
                    delete request.preloadMaxAgeTimeout;
                }
            };
            const cancelPreload = ()=>{
                clearPreloadTimeouts();
                request.preload?.dispose();
            };
            const loadReference = ()=>{
                if (request.load) return request.load;
                const reference = request.preload?.reference ?? load();
                if (request.preload) delete request.preload;
                request.load = {
                    reference: reference,
                    dispose () {
                        reference.dispose();
                        delete request.load;
                    }
                };
                return request.load;
            };
            const clickListener = ()=>{
                clearPreloadTimeouts();
                onBeforeLoad();
                onLoad(loadReference());
            };
            const mouseDownListener = ()=>{
                clearPreloadTimeouts();
                loadReference();
            };
            const preloadReference = ()=>{
                if (request.preloadIntentTimeout || request.preload) return;
                request.preloadIntentTimeout = setTimeout(()=>{
                    delete request.preloadIntentTimeout;
                    const reference = load();
                    request.preload = {
                        reference: reference,
                        dispose () {
                            reference.dispose();
                            delete request.preload;
                        }
                    };
                    request.preloadMaxAgeTimeout = setTimeout(()=>{
                        delete request.preloadMaxAgeTimeout;
                        request.preload?.dispose();
                    }, $01294bc664dce1e5$var$PRELOAD_MAX_AGE);
                }, $01294bc664dce1e5$var$TIME_TO_INTENT);
            };
            el.addEventListener('click', clickListener);
            el.addEventListener('mousedown', mouseDownListener);
            el.addEventListener('mouseenter', preloadReference);
            el.addEventListener('mouseleave', cancelPreload);
            return ()=>{
                cancelPreload();
                el.removeEventListener('click', clickListener);
                el.removeEventListener('mousedown', mouseDownListener);
                el.removeEventListener('mouseenter', preloadReference);
                el.removeEventListener('mouseleave', cancelPreload);
            };
        }, [
            load,
            onBeforeLoad,
            onLoad
        ]);
        return ref;
    }
});
parcelRegister("5oBMG", function(module, exports) {
    $parcel$export(module.exports, "useProductEntitlementDetails", ()=>$3295ae4955475ae4$export$e02d6385b5d47f5d);
    var $9OXo1;
    const $3295ae4955475ae4$export$e02d6385b5d47f5d = ()=>{
        const tenantContext = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        return tenantContext && tenantContext.productEntitlementDetails || null;
    };
});
parcelRegister("aKJYy", function(module, exports) {
    $parcel$export(module.exports, "useShouldSeeButton", ()=>$653e38467b5cc68a$export$dc0322901b5ae67b);
    var $5uXOq = parcelRequire("5uXOq");
    var $6s1PB;
    var $8zOmE;
    var $2NMNM;
    const $653e38467b5cc68a$var$trackJDPFreeToStandardExposure = ({ createAnalyticsEvent: createAnalyticsEvent, applicationKey: applicationKey, applicationEdition: applicationEdition })=>{
        (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(createAnalyticsEvent({
            type: 'sendTrackEvent',
            action: 'exposed',
            actionSubject: 'feature'
        }), 'feature exposed', 'editionAwarenessButton', {
            flagKey: 'jpd-free-to-upgrade-pill-au-awareness',
            value: (0, (0, parcelRequire("6s1PB")).expVal)('jpd-free-to-upgrade-pill-au-awareness', 'cohort', 'not-enrolled'),
            growthInitiative: 'transmuters',
            applicationKey: applicationKey,
            applicationEdition: applicationEdition
        });
    };
    const $653e38467b5cc68a$var$useShouldSeeJPDFreeToStandardExperiment = ({ applicationKey: applicationKey, applicationEdition: applicationEdition, canUpgrade: canUpgrade })=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const [config] = (0, (0, parcelRequire("6s1PB")).UNSAFE_noExposureExp)('jpd-free-to-upgrade-pill-au-awareness');
        const cohort = config.get('cohort', 'not-enrolled');
        const isEnrolled = cohort !== 'not-enrolled';
        const shouldSeeButton = canUpgrade && applicationKey === 'jira-product-discovery' && applicationEdition === 'FREE_EDITION';
        (0, $5uXOq.useEffect)(()=>{
            if (!isEnrolled) return;
            if (!shouldSeeButton) return;
            $653e38467b5cc68a$var$trackJDPFreeToStandardExposure({
                createAnalyticsEvent: createAnalyticsEvent,
                applicationEdition: applicationEdition,
                applicationKey: applicationKey
            });
        }, [
            isEnrolled,
            createAnalyticsEvent,
            applicationEdition,
            applicationKey,
            shouldSeeButton
        ]);
        return cohort === 'variation' && shouldSeeButton;
    };
    const $653e38467b5cc68a$export$dc0322901b5ae67b = ({ applicationKey: applicationKey, applicationEdition: applicationEdition, canUpgrade: canUpgrade })=>{
        const shouldSeeJPDFreeToStandardExperiment = $653e38467b5cc68a$var$useShouldSeeJPDFreeToStandardExperiment({
            applicationEdition: applicationEdition,
            applicationKey: applicationKey,
            canUpgrade: canUpgrade
        });
        if (shouldSeeJPDFreeToStandardExperiment) return true;
        return canUpgrade && applicationEdition === 'FREE_EDITION' && (applicationKey === 'jira-servicedesk' || applicationKey === 'jira-software');
    };
});
parcelRegister("dnl6l", function(module, exports) {
    $parcel$export(module.exports, "editionAwarenessModalEntryPoint", ()=>$2cefc61447230c0a$export$dd136db78a637fd0);
    var $1zkg6;
    var $eufEc;
    var $fEDBv;
    const $2cefc61447230c0a$export$dd136db78a637fd0 = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("aEkLI")), {
            moduleId: "31852d1c"
        }),
        getPreloadProps: ({ cloudId: cloudId, jiraApplicationKey: jiraApplicationKey, productKey: productKey, offeringKey: offeringKey, chargeElement: chargeElement })=>({
                entryPoints: {},
                extraProps: {},
                queries: {
                    editionAwareness: {
                        parameters: (0, (0, parcelRequire("1zkg6")).default),
                        variables: {
                            cloudId: cloudId,
                            jiraApplicationKey: jiraApplicationKey,
                            hamsProductKey: productKey,
                            offeringKey: offeringKey,
                            chargeElement: chargeElement
                        }
                    }
                }
            })
    });
});
parcelRegister("1zkg6", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$40f30b931ec7517b$export$2e2bcd8739ae039);
    const $40f30b931ec7517b$var$node = {
        "kind": "PreloadableConcreteRequest",
        "params": {
            "id": "6e79b2358ef819cc93e18973fbceae455694652a34bbda48bb4e1f48055b77ab",
            "metadata": {},
            "name": "wrapperEditionAwarenessModalWrapperQuery",
            "operationKind": "query",
            "text": null
        }
    };
    var $40f30b931ec7517b$export$2e2bcd8739ae039 = $40f30b931ec7517b$var$node;
});
parcelRegister("aEkLI", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("3Q6GO")
    ]).then(()=>parcelRequire('flkxC'));
});
parcelRegister("kUyoc", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$80fa0347e5551aca$export$2e2bcd8739ae039);
    var $7VHMR;
    var $80fa0347e5551aca$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        noCreatorsLeft: {
            "id": "navigation-apps-sidebar-edition-awareness.edition-awareness-next.no-creators-left",
            "defaultMessage": "No creators left"
        },
        limitedCreatorsLeft: {
            "id": "navigation-apps-sidebar-edition-awareness.edition-awareness-next.limited-creators-left",
            "defaultMessage": "Limited creators left"
        },
        noAgentsLeft: {
            "id": "navigation-apps-sidebar-edition-awareness.edition-awareness-next.no-agents-left",
            "defaultMessage": "No agents left"
        },
        limitedAgentsLeft: {
            "id": "navigation-apps-sidebar-edition-awareness.edition-awareness-next.limited-agents-left",
            "defaultMessage": "Limited agents left"
        },
        noUsersLeft: {
            "id": "navigation-apps-sidebar-edition-awareness.edition-awareness-next.no-users-left",
            "defaultMessage": "No users left"
        },
        limitedUsersLeft: {
            "id": "navigation-apps-sidebar-edition-awareness.edition-awareness-next.limited-users-left",
            "defaultMessage": "Limited users left"
        },
        upgrade: {
            "id": "navigation-apps-sidebar-edition-awareness.edition-awareness-next.upgrade",
            "defaultMessage": "Upgrade"
        }
    });
});
parcelRegister("4OggB", function(module, exports) {
    $parcel$export(module.exports, "FreePill", ()=>$a89063929339c7af$export$517557a682bf3d22);
    var $5uXOq = parcelRequire("5uXOq");
    var $fNobW;
    var $2vSU6;
    var $6s1PB;
    var $iXVV6;
    var $5oFIy;
    var $cNd4s;
    var $6ZmIe;
    var $5tfgc;
    var $5mIGq;
    var $kud4A;
    function $a89063929339c7af$export$517557a682bf3d22(props) {
        const { projectType: projectType, siteHasJswOnly: siteHasJswOnly } = props;
        if (projectType === (0, (0, parcelRequire("2vSU6")).CORE_PROJECT) && !siteHasJswOnly) throw new Error('CORE_PROJECT is only supported for Jira Software only sites');
        const projectTypeToUpgrade = projectType === (0, (0, parcelRequire("2vSU6")).CORE_PROJECT) ? (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT) : projectType;
        const { userCanView: userCanView, billingSourceSystem: billingSourceSystem, isBillingAdmin: isBillingAdmin } = (0, (0, parcelRequire("kud4A")).useCheckEditionAwarenessRequisites)({
            projectType: projectTypeToUpgrade
        });
        if (userCanView === undefined || isBillingAdmin === undefined) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fNobW")).default), {
            name: "edition-awareness-requisites-loading"
        });
        if (userCanView) {
            if (projectType === (0, (0, parcelRequire("2vSU6")).CORE_PROJECT)) {
                if (!(0, (0, parcelRequire("6s1PB")).expValEquals)('edition_awareness_on_jwm', 'isEnabled', true)) return null;
            }
            return (0, ($parcel$interopDefault($5uXOq))).createElement($a89063929339c7af$var$EditionAwarenessFreePill, {
                projectType: projectTypeToUpgrade,
                billingSourceSystem: billingSourceSystem,
                isBillingAdmin: isBillingAdmin,
                siteHasJswOnly: siteHasJswOnly
            });
        }
        return null;
    }
    function $a89063929339c7af$var$EditionAwarenessFreePill(props) {
        const { projectType: projectType, billingSourceSystem: billingSourceSystem, isBillingAdmin: isBillingAdmin, siteHasJswOnly: siteHasJswOnly } = props;
        const isSiteAdmin = (0, (0, parcelRequire("cNd4s")).useIsSiteAdmin)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            attributes: {
                billingSourceSystem: billingSourceSystem,
                isSiteAdmin: isSiteAdmin,
                isBillingAdmin: isBillingAdmin
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6ZmIe")).EditionAwarenessContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement($a89063929339c7af$var$ButtonForProduct, {
            projectType: projectType,
            siteHasJswOnly: siteHasJswOnly
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireUiAnalytics), {
            action: "viewed",
            actionSubject: "button",
            actionSubjectId: "editionAwarenessFreePill"
        })));
    }
    function $a89063929339c7af$var$ButtonForProduct(props) {
        const { projectType: projectType, siteHasJswOnly: siteHasJswOnly } = props;
        return projectType === (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT) ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5mIGq")).UsersRemainingCtaButton), {
            projectType: projectType,
            siteHasJswOnly: siteHasJswOnly
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5tfgc")).EditionAwarenessCtaButton), null);
    }
});
parcelRegister("6ZmIe", function(module, exports) {
    $parcel$export(module.exports, "EditionAwarenessContainer", ()=>$db10faa007327a9e$export$cf82788c7ae9c400);
    $parcel$export(module.exports, "EATopNavAnimatedButtonContainer", ()=>$db10faa007327a9e$export$9dd5ec29fa7b42c5);
    $parcel$export(module.exports, "EATopNavPillCss", ()=>$db10faa007327a9e$export$ab6aa6c8d30bef15);
    $parcel$export(module.exports, "EATopNavPillTheme", ()=>$db10faa007327a9e$export$1ec138ad4719cfee);
    $parcel$export(module.exports, "IconWrapper", ()=>$db10faa007327a9e$export$be58b4326e23250f);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $db10faa007327a9e$export$cf82788c7ae9c400 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _2lx21bp4 _1bah1h6o _4cvr1y6m _zulpu2gc _1o9zidpf",
                __cmplp.className
            ])
        });
    });
    const $db10faa007327a9e$export$71b6aed4f8256b2 = "var(--ds-background-discovery-bold-pressed, #352C63)";
    const $db10faa007327a9e$export$c9d89eb8e0b59a5a = "var(--ds-background-selected-bold-pressed, #09326C)";
    const $db10faa007327a9e$export$d4accc94291dcf84 = "var(--ds-chart-purple-boldest, #5E4DB2)";
    const $db10faa007327a9e$export$16a15d5a9b73591b = "var(--ds-chart-blue-boldest, #0055CC)";
    const $db10faa007327a9e$export$6836610752de4c9b = 16;
    const $db10faa007327a9e$var$EATopNavExperimentGradientBorderStyles = {
        borderRadius: '3px',
        border: `1px solid ${`var(--ds-border-accent-purple, ${(0, $829f609a65d26a98$export$147899de772240ec)})`}`,
        padding: '1px',
        display: 'inline-flex',
        height: '30px',
        '&:hover': {
            background: `linear-gradient(45deg,
              ${$db10faa007327a9e$export$d4accc94291dcf84} 0%,
              ${$db10faa007327a9e$export$16a15d5a9b73591b} 100%)`
        },
        '&:active': {
            background: `linear-gradient(45deg,
              ${$db10faa007327a9e$export$71b6aed4f8256b2} 0%,
              ${$db10faa007327a9e$export$c9d89eb8e0b59a5a} 100%)`
        }
    };
    const $db10faa007327a9e$export$9dd5ec29fa7b42c5 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_2rko1l7b _19it97ho _ca0qt94y _u5f3t94y _n3tdt94y _19bvt94y _1e0c116y _4t3i1ul9 _19lc1nic _j6xte2ew",
                __cmplp.className
            ])
        });
    });
    const $db10faa007327a9e$export$ab6aa6c8d30bef15 = {
        display: 'inline-flex',
        alignItems: 'center',
        border: 'none',
        gap: "var(--ds-space-050, 4px)",
        borderRadius: '3px',
        transition: 'all 0.3s ease-in-out 0s',
        backgroundColor: `${`var(--ds-text-inverse, ${(0, $829f609a65d26a98$export$29a4f05edc054374)})`}`,
        padding: `${"var(--ds-space-075, 6px)"} ${"var(--ds-space-150, 12px)"}`,
        color: `${"var(--ds-background-accent-purple-bolder, #6E5DC6)"}`,
        '> span': {
            margin: '0px'
        },
        '&:hover': {
            cursor: 'pointer',
            '& span': {
                color: `${`var(--ds-text-inverse, ${(0, $829f609a65d26a98$export$29a4f05edc054374)})`}`
            },
            background: `linear-gradient(45deg,
              ${$db10faa007327a9e$export$d4accc94291dcf84} 0%,
              ${$db10faa007327a9e$export$16a15d5a9b73591b} 100%)`,
            textDecoration: 'none'
        },
        '&:active': {
            cursor: 'pointer',
            color: `${`var(--ds-text-inverse, ${(0, $829f609a65d26a98$export$29a4f05edc054374)})`}`,
            '& span': {
                color: `${`var(--ds-text-inverse, ${(0, $829f609a65d26a98$export$29a4f05edc054374)})`}`
            },
            background: `linear-gradient(45deg,
              ${$db10faa007327a9e$export$71b6aed4f8256b2} 0%,
              ${$db10faa007327a9e$export$c9d89eb8e0b59a5a} 100%)`
        }
    };
    const $db10faa007327a9e$export$1ec138ad4719cfee = (currentTheme, themeProps)=>{
        const theme = currentTheme(themeProps);
        return {
            ...theme,
            buttonStyles: {
                ...$db10faa007327a9e$export$ab6aa6c8d30bef15
            }
        };
    };
    const $db10faa007327a9e$export$be58b4326e23250f = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_2hwx1b66",
                __cmplp.className
            ])
        });
    });
});
var $829f609a65d26a98$exports = {};
$parcel$export($829f609a65d26a98$exports, "R50", ()=>$829f609a65d26a98$export$4b84fd0e2c617bd);
$parcel$export($829f609a65d26a98$exports, "R75", ()=>$829f609a65d26a98$export$b34b069eff244586);
$parcel$export($829f609a65d26a98$exports, "R100", ()=>$829f609a65d26a98$export$be802dd0d8b57f35);
$parcel$export($829f609a65d26a98$exports, "R200", ()=>$829f609a65d26a98$export$60847d42f9da92a6);
$parcel$export($829f609a65d26a98$exports, "R300", ()=>$829f609a65d26a98$export$4a7404f376229999);
$parcel$export($829f609a65d26a98$exports, "R400", ()=>$829f609a65d26a98$export$2010d0fb4295b346);
$parcel$export($829f609a65d26a98$exports, "R500", ()=>$829f609a65d26a98$export$2837b5a1a71d5c02);
$parcel$export($829f609a65d26a98$exports, "Y50", ()=>$829f609a65d26a98$export$8d9596b529bbb9cf);
$parcel$export($829f609a65d26a98$exports, "Y75", ()=>$829f609a65d26a98$export$41060a744ab0b7ae);
$parcel$export($829f609a65d26a98$exports, "Y100", ()=>$829f609a65d26a98$export$1cc05bbbf340e559);
$parcel$export($829f609a65d26a98$exports, "Y200", ()=>$829f609a65d26a98$export$5aae31392d72caaa);
$parcel$export($829f609a65d26a98$exports, "Y300", ()=>$829f609a65d26a98$export$c6930421db9fa514);
$parcel$export($829f609a65d26a98$exports, "Y400", ()=>$829f609a65d26a98$export$59619663aa6500fb);
$parcel$export($829f609a65d26a98$exports, "Y500", ()=>$829f609a65d26a98$export$874739ea9e379ab6);
$parcel$export($829f609a65d26a98$exports, "G50", ()=>$829f609a65d26a98$export$8a6341c32b6a3ee6);
$parcel$export($829f609a65d26a98$exports, "G75", ()=>$829f609a65d26a98$export$69a3bf21b9686c2b);
$parcel$export($829f609a65d26a98$exports, "G100", ()=>$829f609a65d26a98$export$afbce1382ec95f73);
$parcel$export($829f609a65d26a98$exports, "G200", ()=>$829f609a65d26a98$export$747d86715d4175de);
$parcel$export($829f609a65d26a98$exports, "G300", ()=>$829f609a65d26a98$export$940e1f69e873f202);
$parcel$export($829f609a65d26a98$exports, "G400", ()=>$829f609a65d26a98$export$a49394138f7b8e85);
$parcel$export($829f609a65d26a98$exports, "G500", ()=>$829f609a65d26a98$export$6fa04827ac68a4fb);
$parcel$export($829f609a65d26a98$exports, "B50", ()=>$829f609a65d26a98$export$226de0355ed012f8);
$parcel$export($829f609a65d26a98$exports, "B75", ()=>$829f609a65d26a98$export$8c13c2ee36917869);
$parcel$export($829f609a65d26a98$exports, "B100", ()=>$829f609a65d26a98$export$ecfc1dff386a3e7a);
$parcel$export($829f609a65d26a98$exports, "B200", ()=>$829f609a65d26a98$export$215b7063051591f1);
$parcel$export($829f609a65d26a98$exports, "B300", ()=>$829f609a65d26a98$export$e62e8919cca44969);
$parcel$export($829f609a65d26a98$exports, "B400", ()=>$829f609a65d26a98$export$b3de0ae08da2ce64);
$parcel$export($829f609a65d26a98$exports, "B500", ()=>$829f609a65d26a98$export$a6ee3d71bc067634);
$parcel$export($829f609a65d26a98$exports, "B600", ()=>$829f609a65d26a98$export$67f15409e87642a1);
$parcel$export($829f609a65d26a98$exports, "B700", ()=>$829f609a65d26a98$export$f0539dbc42dbb067);
$parcel$export($829f609a65d26a98$exports, "P50", ()=>$829f609a65d26a98$export$363e4475840015bb);
$parcel$export($829f609a65d26a98$exports, "P75", ()=>$829f609a65d26a98$export$4b0a4417ed59760f);
$parcel$export($829f609a65d26a98$exports, "P100", ()=>$829f609a65d26a98$export$6011520a64d9a187);
$parcel$export($829f609a65d26a98$exports, "P200", ()=>$829f609a65d26a98$export$a7175d4e9206bc80);
$parcel$export($829f609a65d26a98$exports, "P300", ()=>$829f609a65d26a98$export$b48405b95304b5a1);
$parcel$export($829f609a65d26a98$exports, "P400", ()=>$829f609a65d26a98$export$c71e4279dd54a18d);
$parcel$export($829f609a65d26a98$exports, "P500", ()=>$829f609a65d26a98$export$147899de772240ec);
$parcel$export($829f609a65d26a98$exports, "T50", ()=>$829f609a65d26a98$export$2cf711fe14c66f06);
$parcel$export($829f609a65d26a98$exports, "T75", ()=>$829f609a65d26a98$export$7a8f4d33f6f9772c);
$parcel$export($829f609a65d26a98$exports, "T100", ()=>$829f609a65d26a98$export$776c9c497ef08533);
$parcel$export($829f609a65d26a98$exports, "T200", ()=>$829f609a65d26a98$export$69cb3ca10c1e330e);
$parcel$export($829f609a65d26a98$exports, "T300", ()=>$829f609a65d26a98$export$9dc1ef0f3f48059b);
$parcel$export($829f609a65d26a98$exports, "T400", ()=>$829f609a65d26a98$export$7c835c78b6fcdef8);
$parcel$export($829f609a65d26a98$exports, "T500", ()=>$829f609a65d26a98$export$6036577f95c3877e);
$parcel$export($829f609a65d26a98$exports, "N0", ()=>$829f609a65d26a98$export$29a4f05edc054374);
$parcel$export($829f609a65d26a98$exports, "N10", ()=>$829f609a65d26a98$export$e655ef011c4aa1a2);
$parcel$export($829f609a65d26a98$exports, "N20", ()=>$829f609a65d26a98$export$9fdfb749e33cdf97);
$parcel$export($829f609a65d26a98$exports, "N30", ()=>$829f609a65d26a98$export$3e51e8f1440314ba);
$parcel$export($829f609a65d26a98$exports, "N40", ()=>$829f609a65d26a98$export$1ce2d1575d444ceb);
$parcel$export($829f609a65d26a98$exports, "N50", ()=>$829f609a65d26a98$export$8754fc7e6d8b3000);
$parcel$export($829f609a65d26a98$exports, "N60", ()=>$829f609a65d26a98$export$c2e91cbeada39b8b);
$parcel$export($829f609a65d26a98$exports, "N70", ()=>$829f609a65d26a98$export$5f71e19f3a7ad9de);
$parcel$export($829f609a65d26a98$exports, "N80", ()=>$829f609a65d26a98$export$eaa8c8a70ac22806);
$parcel$export($829f609a65d26a98$exports, "N90", ()=>$829f609a65d26a98$export$7c87d337fb91a97d);
$parcel$export($829f609a65d26a98$exports, "N100", ()=>$829f609a65d26a98$export$d6447c357493214f);
$parcel$export($829f609a65d26a98$exports, "N200", ()=>$829f609a65d26a98$export$f563ee71e9ee74cd);
$parcel$export($829f609a65d26a98$exports, "N300", ()=>$829f609a65d26a98$export$e2ef2124cf4024b1);
$parcel$export($829f609a65d26a98$exports, "N400", ()=>$829f609a65d26a98$export$eece5f492e429f08);
$parcel$export($829f609a65d26a98$exports, "N500", ()=>$829f609a65d26a98$export$c39c0fe0f26207ae);
$parcel$export($829f609a65d26a98$exports, "N600", ()=>$829f609a65d26a98$export$7090862dc3038a9f);
$parcel$export($829f609a65d26a98$exports, "N700", ()=>$829f609a65d26a98$export$ad0bacf983b7ddaa);
$parcel$export($829f609a65d26a98$exports, "N800", ()=>$829f609a65d26a98$export$7d61b15e0da132af);
$parcel$export($829f609a65d26a98$exports, "N900", ()=>$829f609a65d26a98$export$fbda843f0a27fe96);
$parcel$export($829f609a65d26a98$exports, "N10A", ()=>$829f609a65d26a98$export$791e57b27ca738ef);
$parcel$export($829f609a65d26a98$exports, "N20A", ()=>$829f609a65d26a98$export$8d43898f60b96a82);
$parcel$export($829f609a65d26a98$exports, "N30A", ()=>$829f609a65d26a98$export$920c0d5c49070351);
$parcel$export($829f609a65d26a98$exports, "N40A", ()=>$829f609a65d26a98$export$46987b49aab6d1a8);
$parcel$export($829f609a65d26a98$exports, "N50A", ()=>$829f609a65d26a98$export$7d3359664e8bb0e5);
$parcel$export($829f609a65d26a98$exports, "N60A", ()=>$829f609a65d26a98$export$d2d3bedfb276cac0);
$parcel$export($829f609a65d26a98$exports, "N70A", ()=>$829f609a65d26a98$export$6e5e7499ff054f6d);
$parcel$export($829f609a65d26a98$exports, "N80A", ()=>$829f609a65d26a98$export$59264b346c877fa7);
$parcel$export($829f609a65d26a98$exports, "N90A", ()=>$829f609a65d26a98$export$d092757d7c64925c);
$parcel$export($829f609a65d26a98$exports, "N100A", ()=>$829f609a65d26a98$export$486ab785e4bac3c6);
$parcel$export($829f609a65d26a98$exports, "N200A", ()=>$829f609a65d26a98$export$fba592bbc6419fea);
$parcel$export($829f609a65d26a98$exports, "N300A", ()=>$829f609a65d26a98$export$13609db0e6812ab8);
$parcel$export($829f609a65d26a98$exports, "N400A", ()=>$829f609a65d26a98$export$fbae4efaa892d883);
$parcel$export($829f609a65d26a98$exports, "N500A", ()=>$829f609a65d26a98$export$f13946d26f440ce6);
$parcel$export($829f609a65d26a98$exports, "N600A", ()=>$829f609a65d26a98$export$19f58225ac52a306);
$parcel$export($829f609a65d26a98$exports, "N700A", ()=>$829f609a65d26a98$export$6384f09da70e7b62);
$parcel$export($829f609a65d26a98$exports, "N800A", ()=>$829f609a65d26a98$export$d0dccc730683edc2);
$parcel$export($829f609a65d26a98$exports, "DN900", ()=>$829f609a65d26a98$export$2f6319cf7d736c2a);
$parcel$export($829f609a65d26a98$exports, "DN800", ()=>$829f609a65d26a98$export$95126bc8884d6634);
$parcel$export($829f609a65d26a98$exports, "DN700", ()=>$829f609a65d26a98$export$8338d8c35d44ff1);
$parcel$export($829f609a65d26a98$exports, "DN600", ()=>$829f609a65d26a98$export$5368d76ae4fbbda7);
$parcel$export($829f609a65d26a98$exports, "DN500", ()=>$829f609a65d26a98$export$9f0a771d16ec8d36);
$parcel$export($829f609a65d26a98$exports, "DN400", ()=>$829f609a65d26a98$export$abcf8c38338b4aa5);
$parcel$export($829f609a65d26a98$exports, "DN300", ()=>$829f609a65d26a98$export$97e81faa16a182c7);
$parcel$export($829f609a65d26a98$exports, "DN200", ()=>$829f609a65d26a98$export$bfe21c59123ecf33);
$parcel$export($829f609a65d26a98$exports, "DN100", ()=>$829f609a65d26a98$export$ee82aa2ea6a1558);
$parcel$export($829f609a65d26a98$exports, "DN90", ()=>$829f609a65d26a98$export$682927c6ed76be02);
$parcel$export($829f609a65d26a98$exports, "DN80", ()=>$829f609a65d26a98$export$91fd9994c5be9757);
$parcel$export($829f609a65d26a98$exports, "DN70", ()=>$829f609a65d26a98$export$d281fbfe3cff2df6);
$parcel$export($829f609a65d26a98$exports, "DN60", ()=>$829f609a65d26a98$export$3902c91d8b98a753);
$parcel$export($829f609a65d26a98$exports, "DN50", ()=>$829f609a65d26a98$export$8e8133c1ba5845b5);
$parcel$export($829f609a65d26a98$exports, "DN40", ()=>$829f609a65d26a98$export$33ff357f6620b39);
$parcel$export($829f609a65d26a98$exports, "DN30", ()=>$829f609a65d26a98$export$633847cf062b69e0);
$parcel$export($829f609a65d26a98$exports, "DN20", ()=>$829f609a65d26a98$export$9776a6e503bdbf76);
$parcel$export($829f609a65d26a98$exports, "DN10", ()=>$829f609a65d26a98$export$6c813d5160dff5db);
$parcel$export($829f609a65d26a98$exports, "DN0", ()=>$829f609a65d26a98$export$3d4f271a500072a);
$parcel$export($829f609a65d26a98$exports, "DN800A", ()=>$829f609a65d26a98$export$fe151285c22759ec);
$parcel$export($829f609a65d26a98$exports, "DN700A", ()=>$829f609a65d26a98$export$a101c6190d0da34);
$parcel$export($829f609a65d26a98$exports, "DN600A", ()=>$829f609a65d26a98$export$4b16f52932fda51);
$parcel$export($829f609a65d26a98$exports, "DN500A", ()=>$829f609a65d26a98$export$a74bcd7b9845b0d8);
$parcel$export($829f609a65d26a98$exports, "DN400A", ()=>$829f609a65d26a98$export$9209c179316d1751);
$parcel$export($829f609a65d26a98$exports, "DN300A", ()=>$829f609a65d26a98$export$88f55133f636c8dd);
$parcel$export($829f609a65d26a98$exports, "DN200A", ()=>$829f609a65d26a98$export$51126f85d1009512);
$parcel$export($829f609a65d26a98$exports, "DN100A", ()=>$829f609a65d26a98$export$a069b547bd23af78);
$parcel$export($829f609a65d26a98$exports, "DN90A", ()=>$829f609a65d26a98$export$40cce05af3b2600a);
$parcel$export($829f609a65d26a98$exports, "DN80A", ()=>$829f609a65d26a98$export$b78942185925a0d);
$parcel$export($829f609a65d26a98$exports, "DN70A", ()=>$829f609a65d26a98$export$e058fb44b8eaafca);
$parcel$export($829f609a65d26a98$exports, "DN60A", ()=>$829f609a65d26a98$export$92b0b8de26920c18);
$parcel$export($829f609a65d26a98$exports, "DN50A", ()=>$829f609a65d26a98$export$67564f7459d3fc9e);
$parcel$export($829f609a65d26a98$exports, "DN40A", ()=>$829f609a65d26a98$export$77868e1ca884ffa3);
$parcel$export($829f609a65d26a98$exports, "DN30A", ()=>$829f609a65d26a98$export$2afd71d7d3ea2cd5);
$parcel$export($829f609a65d26a98$exports, "DN20A", ()=>$829f609a65d26a98$export$f2a0acaab9972c70);
$parcel$export($829f609a65d26a98$exports, "DN10A", ()=>$829f609a65d26a98$export$9ab70e67d6475361);
$parcel$export($829f609a65d26a98$exports, "T30", ()=>$829f609a65d26a98$export$d2adc5dedf09fced);
$parcel$export($829f609a65d26a98$exports, "L50", ()=>$829f609a65d26a98$export$f51762073d2bb381);
$parcel$export($829f609a65d26a98$exports, "L75", ()=>$829f609a65d26a98$export$95e26d54def96891);
const $829f609a65d26a98$export$4b84fd0e2c617bd = '#FFEBE6';
const $829f609a65d26a98$export$b34b069eff244586 = '#FFBDAD';
const $829f609a65d26a98$export$be802dd0d8b57f35 = '#FF8F73';
const $829f609a65d26a98$export$60847d42f9da92a6 = '#FF7452';
const $829f609a65d26a98$export$4a7404f376229999 = '#FF5630';
const $829f609a65d26a98$export$2010d0fb4295b346 = '#DE350B';
const $829f609a65d26a98$export$2837b5a1a71d5c02 = '#BF2600';
const $829f609a65d26a98$export$8d9596b529bbb9cf = '#FFFAE6';
const $829f609a65d26a98$export$41060a744ab0b7ae = '#FFF0B3';
const $829f609a65d26a98$export$1cc05bbbf340e559 = '#FFE380';
const $829f609a65d26a98$export$5aae31392d72caaa = '#FFC400';
const $829f609a65d26a98$export$c6930421db9fa514 = '#FFAB00';
const $829f609a65d26a98$export$59619663aa6500fb = '#FF991F';
const $829f609a65d26a98$export$874739ea9e379ab6 = '#FF8B00';
const $829f609a65d26a98$export$8a6341c32b6a3ee6 = '#E3FCEF';
const $829f609a65d26a98$export$69a3bf21b9686c2b = '#ABF5D1';
const $829f609a65d26a98$export$afbce1382ec95f73 = '#79F2C0';
const $829f609a65d26a98$export$747d86715d4175de = '#57D9A3';
const $829f609a65d26a98$export$940e1f69e873f202 = '#36B37E';
const $829f609a65d26a98$export$a49394138f7b8e85 = '#00875A';
const $829f609a65d26a98$export$6fa04827ac68a4fb = '#006644';
const $829f609a65d26a98$export$226de0355ed012f8 = '#DEEBFF';
const $829f609a65d26a98$export$8c13c2ee36917869 = '#B3D4FF';
const $829f609a65d26a98$export$ecfc1dff386a3e7a = '#4C9AFF';
const $829f609a65d26a98$export$215b7063051591f1 = '#2684FF';
const $829f609a65d26a98$export$e62e8919cca44969 = '#0065FF';
const $829f609a65d26a98$export$b3de0ae08da2ce64 = '#0052CC';
const $829f609a65d26a98$export$a6ee3d71bc067634 = '#0747A6';
const $829f609a65d26a98$export$67f15409e87642a1 = '#669DF1';
const $829f609a65d26a98$export$f0539dbc42dbb067 = '#8FB8F6';
const $829f609a65d26a98$export$363e4475840015bb = '#EAE6FF';
const $829f609a65d26a98$export$4b0a4417ed59760f = '#C0B6F2';
const $829f609a65d26a98$export$6011520a64d9a187 = '#998DD9';
const $829f609a65d26a98$export$a7175d4e9206bc80 = '#8777D9';
const $829f609a65d26a98$export$b48405b95304b5a1 = '#6554C0';
const $829f609a65d26a98$export$c71e4279dd54a18d = '#5243AA';
const $829f609a65d26a98$export$147899de772240ec = '#403294';
const $829f609a65d26a98$export$2cf711fe14c66f06 = '#E6FCFF';
const $829f609a65d26a98$export$7a8f4d33f6f9772c = '#B3F5FF';
const $829f609a65d26a98$export$776c9c497ef08533 = '#79E2F2';
const $829f609a65d26a98$export$69cb3ca10c1e330e = '#00C7E6';
const $829f609a65d26a98$export$9dc1ef0f3f48059b = '#00B8D9';
const $829f609a65d26a98$export$7c835c78b6fcdef8 = '#00A3BF';
const $829f609a65d26a98$export$6036577f95c3877e = '#008DA6';
const $829f609a65d26a98$export$29a4f05edc054374 = '#FFFFFF';
const $829f609a65d26a98$export$e655ef011c4aa1a2 = '#FAFBFC';
const $829f609a65d26a98$export$9fdfb749e33cdf97 = '#F4F5F7';
const $829f609a65d26a98$export$3e51e8f1440314ba = '#EBECF0';
const $829f609a65d26a98$export$1ce2d1575d444ceb = '#DFE1E6';
const $829f609a65d26a98$export$8754fc7e6d8b3000 = '#C1C7D0';
const $829f609a65d26a98$export$c2e91cbeada39b8b = '#B3BAC5';
const $829f609a65d26a98$export$5f71e19f3a7ad9de = '#A5ADBA';
const $829f609a65d26a98$export$eaa8c8a70ac22806 = '#97A0AF';
const $829f609a65d26a98$export$7c87d337fb91a97d = '#8993A4';
const $829f609a65d26a98$export$d6447c357493214f = '#7A869A';
const $829f609a65d26a98$export$f563ee71e9ee74cd = '#6B778C';
const $829f609a65d26a98$export$e2ef2124cf4024b1 = '#5E6C84';
const $829f609a65d26a98$export$eece5f492e429f08 = '#505F79';
const $829f609a65d26a98$export$c39c0fe0f26207ae = '#42526E';
const $829f609a65d26a98$export$7090862dc3038a9f = '#344563';
const $829f609a65d26a98$export$ad0bacf983b7ddaa = '#253858';
const $829f609a65d26a98$export$7d61b15e0da132af = '#172B4D';
const $829f609a65d26a98$export$fbda843f0a27fe96 = '#091E42';
const $829f609a65d26a98$export$791e57b27ca738ef = 'rgba(9, 30, 66, 0.02)';
const $829f609a65d26a98$export$8d43898f60b96a82 = 'rgba(9, 30, 66, 0.04)';
const $829f609a65d26a98$export$920c0d5c49070351 = 'rgba(9, 30, 66, 0.08)';
const $829f609a65d26a98$export$46987b49aab6d1a8 = 'rgba(9, 30, 66, 0.13)';
const $829f609a65d26a98$export$7d3359664e8bb0e5 = 'rgba(9, 30, 66, 0.25)';
const $829f609a65d26a98$export$d2d3bedfb276cac0 = 'rgba(9, 30, 66, 0.31)';
const $829f609a65d26a98$export$6e5e7499ff054f6d = 'rgba(9, 30, 66, 0.36)';
const $829f609a65d26a98$export$59264b346c877fa7 = 'rgba(9, 30, 66, 0.42)';
const $829f609a65d26a98$export$d092757d7c64925c = 'rgba(9, 30, 66, 0.48)';
const $829f609a65d26a98$export$486ab785e4bac3c6 = 'rgba(9, 30, 66, 0.54)';
const $829f609a65d26a98$export$fba592bbc6419fea = 'rgba(9, 30, 66, 0.60)';
const $829f609a65d26a98$export$13609db0e6812ab8 = 'rgba(9, 30, 66, 0.66)';
const $829f609a65d26a98$export$fbae4efaa892d883 = 'rgba(9, 30, 66, 0.71)';
const $829f609a65d26a98$export$f13946d26f440ce6 = 'rgba(9, 30, 66, 0.77)';
const $829f609a65d26a98$export$19f58225ac52a306 = 'rgba(9, 30, 66, 0.82)';
const $829f609a65d26a98$export$6384f09da70e7b62 = 'rgba(9, 30, 66, 0.89)';
const $829f609a65d26a98$export$d0dccc730683edc2 = 'rgba(9, 30, 66, 0.95)';
const $829f609a65d26a98$export$2f6319cf7d736c2a = '#E6EDFA';
const $829f609a65d26a98$export$95126bc8884d6634 = '#DCE5F5';
const $829f609a65d26a98$export$8338d8c35d44ff1 = '#CED9EB';
const $829f609a65d26a98$export$5368d76ae4fbbda7 = '#B8C7E0';
const $829f609a65d26a98$export$9f0a771d16ec8d36 = '#ABBBD6';
const $829f609a65d26a98$export$abcf8c38338b4aa5 = '#9FB0CC';
const $829f609a65d26a98$export$97e81faa16a182c7 = '#8C9CB8';
const $829f609a65d26a98$export$bfe21c59123ecf33 = '#7988A3';
const $829f609a65d26a98$export$ee82aa2ea6a1558 = '#67758F';
const $829f609a65d26a98$export$682927c6ed76be02 = '#56637A';
const $829f609a65d26a98$export$91fd9994c5be9757 = '#455166';
const $829f609a65d26a98$export$d281fbfe3cff2df6 = '#3B475C';
const $829f609a65d26a98$export$3902c91d8b98a753 = '#313D52';
const $829f609a65d26a98$export$8e8133c1ba5845b5 = '#283447';
const $829f609a65d26a98$export$33ff357f6620b39 = '#202B3D';
const $829f609a65d26a98$export$633847cf062b69e0 = '#1B2638';
const $829f609a65d26a98$export$9776a6e503bdbf76 = '#121A29';
const $829f609a65d26a98$export$6c813d5160dff5db = '#0E1624';
const $829f609a65d26a98$export$3d4f271a500072a = '#0D1424';
const $829f609a65d26a98$export$fe151285c22759ec = 'rgba(13, 20, 36, 0.06)';
const $829f609a65d26a98$export$a101c6190d0da34 = 'rgba(13, 20, 36, 0.14)';
const $829f609a65d26a98$export$4b16f52932fda51 = 'rgba(13, 20, 36, 0.18)';
const $829f609a65d26a98$export$a74bcd7b9845b0d8 = 'rgba(13, 20, 36, 0.29)';
const $829f609a65d26a98$export$9209c179316d1751 = 'rgba(13, 20, 36, 0.36)';
const $829f609a65d26a98$export$88f55133f636c8dd = 'rgba(13, 20, 36, 0.40)';
const $829f609a65d26a98$export$51126f85d1009512 = 'rgba(13, 20, 36, 0.47)';
const $829f609a65d26a98$export$a069b547bd23af78 = 'rgba(13, 20, 36, 0.53)';
const $829f609a65d26a98$export$40cce05af3b2600a = 'rgba(13, 20, 36, 0.63)';
const $829f609a65d26a98$export$b78942185925a0d = 'rgba(13, 20, 36, 0.73)';
const $829f609a65d26a98$export$e058fb44b8eaafca = 'rgba(13, 20, 36, 0.78)';
const $829f609a65d26a98$export$92b0b8de26920c18 = 'rgba(13, 20, 36, 0.81)';
const $829f609a65d26a98$export$67564f7459d3fc9e = 'rgba(13, 20, 36, 0.85)';
const $829f609a65d26a98$export$77868e1ca884ffa3 = 'rgba(13, 20, 36, 0.89)';
const $829f609a65d26a98$export$2afd71d7d3ea2cd5 = 'rgba(13, 20, 36, 0.92)';
const $829f609a65d26a98$export$f2a0acaab9972c70 = 'rgba(13, 20, 36, 0.95)';
const $829f609a65d26a98$export$9ab70e67d6475361 = 'rgba(13, 20, 36, 0.97)';
const $829f609a65d26a98$export$d2adc5dedf09fced = '#292A2E';
const $829f609a65d26a98$export$f51762073d2bb381 = '#94C748';
const $829f609a65d26a98$export$95e26d54def96891 = '#B3DF72';
parcelRegister("5tfgc", function(module, exports) {
    $parcel$export(module.exports, "EditionAwarenessCtaButton", ()=>$67a3604885be73d7$export$ebccf7539146cc49);
    var $5uXOq = parcelRequire("5uXOq");
    var $9yOBd;
    var $dC5iT;
    var $8CUq3;
    var $47gW9;
    var $ivDCs;
    var $4mO32;
    var $4R6GH;
    var $koVbs;
    var $7XTqQ;
    var $8zOmE;
    var $2NMNM;
    var $bVDaO;
    var $koeKL;
    var $6ZmIe;
    var $8dyGZ;
    function $67a3604885be73d7$export$ebccf7539146cc49() {
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const entryPoint = (0, (0, parcelRequire("4mO32")).useEntryPoint)((0, (0, parcelRequire("7XTqQ")).jsmUpsellModalEntryPoint), {
            cloudIds: [
                cloudId
            ],
            isJsmRepackagedOfferingKeyFgEnabled: (0, (0, parcelRequire("4R6GH")).fg)('jsm_repackaged_offering_key')
        });
        const triggerRef = (0, (0, parcelRequire("47gW9")).useEntryPointButtonTrigger)(entryPoint.entryPointActions);
        const fireButtonAnalytics = (0, $5uXOq.useCallback)((action)=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: action,
                actionSubject: 'button'
            }), 'editionAwarenessFreePill', {});
        }, [
            createAnalyticsEvent
        ]);
        const onClick = (0, $5uXOq.useCallback)(()=>{
            fireButtonAnalytics('clicked');
        }, [
            fireButtonAnalytics
        ]);
        const onHoverButton = (0, $5uXOq.useCallback)(()=>{
            fireButtonAnalytics('hovered');
        }, [
            fireButtonAnalytics
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6ZmIe")).EATopNavAnimatedButtonContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9yOBd")).default), {
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6ZmIe")).IconWrapper), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bVDaO")).GemIcon), {
                label: ""
            })),
            onClick: onClick,
            theme: (0, (0, parcelRequire("6ZmIe")).EATopNavPillTheme),
            onMouseEnter: onHoverButton,
            ref: triggerRef,
            interactionName: "edition-awareness-free-pill",
            testId: "navigation-apps-sidebar-edition-awareness.ui.free-pill.edition-awareness-cta-button.edition-awareness-free-pill-cta-button"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "span",
            xcss: $67a3604885be73d7$var$eaTopNavButtonTextStyles
        }, formatMessage((0, (0, parcelRequire("8dyGZ")).default).upgradeToStandardCtaLabel)))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ivDCs")).ModalEntryPointContainer), {
            entryPointReferenceSubject: entryPoint.entryPointReferenceSubject,
            id: "jsm-upsell-modal",
            packageName: "edition-awareness",
            teamName: "growth-tako",
            runtimeProps: {},
            entryPointActions: entryPoint.entryPointActions
        }));
    }
    const $67a3604885be73d7$var$eaTopNavButtonTextStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        font: 'font.body',
        color: 'color.text.accent.blue'
    });
});
parcelRegister("47gW9", function(module, exports) {
    $parcel$export(module.exports, "useEntryPointButtonTrigger", ()=>$1be876415a945060$export$25d2524a63315ed);
    $parcel$export(module.exports, "getDefaultEntrypointActionEventMap", ()=>$1be876415a945060$export$5f13515bd3a35325);
    var $5uXOq = parcelRequire("5uXOq");
    var $iRKpN;
    const $1be876415a945060$export$25d2524a63315ed = (actions, isActive = true, eventMap = $1be876415a945060$export$5f13515bd3a35325(actions))=>{
        const removePreviousListeners = (0, $5uXOq.useRef)(null);
        const isMounted = (0, $5uXOq.useRef)(true);
        (0, $5uXOq.useEffect)(()=>()=>{
                removePreviousListeners.current?.();
                removePreviousListeners.current = null;
                isMounted.current = false;
            }, []);
        return (0, $5uXOq.useCallback)((element)=>{
            if (!isMounted.current) return;
            removePreviousListeners.current?.();
            if (element && isActive) {
                (0, (0, parcelRequire("iRKpN")).modifyListeners)({
                    element: element,
                    operationName: 'add',
                    eventMap: eventMap
                });
                removePreviousListeners.current = ()=>{
                    (0, (0, parcelRequire("iRKpN")).modifyListeners)({
                        element: element,
                        operationName: 'remove',
                        eventMap: eventMap
                    });
                };
            } else removePreviousListeners.current = null;
        }, [
            eventMap,
            isActive
        ]);
    };
    const $1be876415a945060$export$5f13515bd3a35325 = (actions)=>({
            click: actions.load,
            mouseenter: actions.preload,
            mouseleave: actions.cancelPreload,
            focusin: actions.preload,
            focusout: actions.cancelPreload
        });
});
parcelRegister("iRKpN", function(module, exports) {
    $parcel$export(module.exports, "modifyListeners", ()=>$62bae43418420b8a$export$15257a36a7e3f9d6);
    function $62bae43418420b8a$export$15257a36a7e3f9d6(params) {
        const { element: element, operationName: operationName, eventMap: eventMap } = params;
        const functionName = operationName === 'add' ? 'addEventListener' : 'removeEventListener';
        if (element) Object.keys(eventMap).forEach((eventName)=>element[functionName](eventName, eventMap[eventName]));
    }
});
parcelRegister("ivDCs", function(module, exports) {
    $parcel$export(module.exports, "ModalEntryPointContainer", ()=>$832a9e21b9f9d7ff$export$4d67c6ee49f3d14d);
    var $5uXOq = parcelRequire("5uXOq");
    var $i8Qvm;
    var $8Bm0k;
    var $c5vfY;
    const $832a9e21b9f9d7ff$export$4d67c6ee49f3d14d = ({ entryPointActions: { unload: unload }, entryPointReferenceSubject: entryPointReferenceSubject, errorFallback: errorFallback, runtimeProps: runtimeProps, ...containerProps })=>{
        const entryPointReference = (0, (0, parcelRequire("8Bm0k")).useEntryPointSubscription)(entryPointReferenceSubject);
        const props = (0, $5uXOq.useMemo)(()=>({
                ...runtimeProps,
                onClose () {
                    runtimeProps.onClose?.();
                    unload();
                }
            }), [
            runtimeProps,
            unload
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("i8Qvm")).default), null, entryPointReference && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c5vfY")).InternalEntryPointContainer), {
            ...containerProps,
            entryPointReference: entryPointReference,
            errorFallback: errorFallback || 'flag',
            placeholderName: "modal-entry-point-container",
            runtimeProps: props
        }));
    };
});
parcelRegister("7XTqQ", function(module, exports) {
    $parcel$export(module.exports, "jsmUpsellModalEntryPoint", ()=>$a4c337f72f3c2495$export$744f919e2acd7bff);
    var $9pIfq;
    var $eufEc;
    var $fEDBv;
    const $a4c337f72f3c2495$export$744f919e2acd7bff = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("apAxC")), {
            moduleId: "5c4ccabb"
        }),
        getPreloadProps: ({ cloudIds: cloudIds, isJsmRepackagedOfferingKeyFgEnabled: isJsmRepackagedOfferingKeyFgEnabled })=>({
                queries: {
                    getStartStandardTrialUrlQuery: {
                        parameters: (0, (0, parcelRequire("9pIfq")).default),
                        variables: {
                            cloudIds: cloudIds,
                            isJsmRepackagedOfferingKeyFgEnabled: isJsmRepackagedOfferingKeyFgEnabled
                        }
                    }
                }
            })
    });
});
parcelRegister("9pIfq", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7145e7f0ae40552e$export$2e2bcd8739ae039);
    const $7145e7f0ae40552e$var$node = {
        "kind": "PreloadableConcreteRequest",
        "params": {
            "id": "72e216fbc7f1849302b5317053782d56dc79efaa2150016d048c555725356733",
            "metadata": {},
            "name": "uiGrowthJsmUpsellModal_GetStartStandardTrialUrlQuery",
            "operationKind": "query",
            "text": null
        }
    };
    var $7145e7f0ae40552e$export$2e2bcd8739ae039 = $7145e7f0ae40552e$var$node;
});
parcelRegister("apAxC", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("8r3rm")
    ]).then(()=>parcelRequire('fLjht'));
});
parcelRegister("bVDaO", function(module, exports) {
    $parcel$export(module.exports, "GemIcon", ()=>$cf7175eb6b3644c0$export$8199e869bb63c31b);
    var $5uXOq = parcelRequire("5uXOq");
    var $5LWLA;
    const $cf7175eb6b3644c0$var$GemGlyph = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "16",
            height: "16",
            viewBox: "0 0 16 16",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M2.61401 1.3525C2.75106 1.13321 2.99141 1 3.25001 1H12.75C13.0086 1 13.249 1.13321 13.386 1.3525L15.886 5.3525C16.0594 5.62995 16.033 5.98778 15.8206 6.23671L8.57063 14.7367C8.42813 14.9038 8.21958 15 8 15C7.78043 15 7.57188 14.9038 7.42938 14.7367L0.17938 6.23671C-0.0329407 5.98778 -0.059397 5.62995 0.114006 5.3525L2.61401 1.3525ZM3.66569 2.5L2.10319 5H4.69861L5.47986 2.5H3.66569ZM7.0514 2.5L6.27015 5H9.72986L8.94861 2.5H7.0514ZM10.5201 2.5L11.3014 5H13.8968L12.3343 2.5H10.5201ZM13.6245 6.5H11.2956L9.87241 10.899L13.6245 6.5ZM8 11.8135L9.71908 6.5H6.28093L8 11.8135ZM4.70438 6.5L6.1276 10.899L2.37547 6.5H4.70438Z",
            fill: "currentColor"
        }));
    const $cf7175eb6b3644c0$export$8199e869bb63c31b = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5LWLA")).default), {
            glyph: $cf7175eb6b3644c0$var$GemGlyph,
            ...props
        });
});
parcelRegister("8dyGZ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$24fa7eed21e594d2$export$2e2bcd8739ae039);
    var $7VHMR;
    var $24fa7eed21e594d2$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        upgradeToStandardCtaLabel: {
            "id": "navigation-apps-sidebar-edition-awareness.free-pill.edition-awareness-cta-button.upgrade-to-standard-cta-label",
            "defaultMessage": "Upgrade"
        }
    });
});
parcelRegister("5mIGq", function(module, exports) {
    $parcel$export(module.exports, "UsersRemainingCtaButton", ()=>$7525aa76a967bde0$export$426d5e998fcc59ac);
    var $5uXOq = parcelRequire("5uXOq");
    var $47gW9;
    var $ivDCs;
    var $4mO32;
    var $6s1PB;
    var $6QqGY;
    var $4Rfxh;
    var $dRVix;
    var $8zOmE;
    var $2NMNM;
    var $koeKL;
    var $cNd4s;
    var $i8PkL;
    function $7525aa76a967bde0$export$426d5e998fcc59ac(props) {
        const { projectType: projectType, siteHasJswOnly: siteHasJswOnly } = props;
        const isUsingCcp = (0, (0, parcelRequire("dRVix")).useIsProductUsingCcp)(projectType);
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const isSiteAdmin = (0, (0, parcelRequire("cNd4s")).useIsSiteAdmin)();
        const shouldUseCache = (0, $5uXOq.useCallback)((userCountData)=>userCountData.unitCount < (0, $af6ccefcb1f83341$export$37efcda5d411f9dc), []);
        const { data: data } = (0, (0, parcelRequire("6QqGY")).useGetJswUserCount)({
            cloudId: cloudId,
            shouldUseCache: shouldUseCache,
            skip: !isSiteAdmin
        });
        const userCount = data?.unitCount;
        (0, $5uXOq.useEffect)(()=>{
            if (userCount !== undefined) (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'viewed',
                actionSubject: 'button'
            }), 'usersRemainingFreePill', {
                userCount: userCount
            });
        }, [
            createAnalyticsEvent,
            userCount
        ]);
        const fireButtonAnalytics = (0, $5uXOq.useCallback)((action)=>(0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: action,
                actionSubject: 'button'
            }), 'editionAwarenessFreePill', {
                userCount: userCount
            }), [
            createAnalyticsEvent,
            userCount
        ]);
        const onButtonClick = ()=>fireButtonAnalytics('clicked');
        const onHoverButton = ()=>fireButtonAnalytics('hovered');
        const [mustBnConfig] = (0, (0, parcelRequire("6s1PB")).UNSAFE_noExposureExp)('jsw_upgrade_skip_trial_buy_now');
        const jswBuyNowExperimentCohort = (0, $5uXOq.useMemo)(()=>mustBnConfig.get('cohort', 'not-enrolled'), [
            mustBnConfig
        ]);
        const isCcpJswOnlySite = isUsingCcp && siteHasJswOnly;
        const shouldRequestSkipTrialFields = isCcpJswOnlySite && jswBuyNowExperimentCohort !== 'not-enrolled';
        const { entryPointActions: entryPointActions, entryPointReferenceSubject: entryPointReferenceSubject } = (0, (0, parcelRequire("4mO32")).useEntryPoint)((0, (0, parcelRequire("4Rfxh")).jswUpsellModalEntryPoint), {
            cloudIds: [
                cloudId
            ],
            shouldRequestSkipTrialFields: shouldRequestSkipTrialFields
        });
        const triggerRef = (0, (0, parcelRequire("47gW9")).useEntryPointButtonTrigger)(entryPointActions);
        const Button = $7525aa76a967bde0$var$getUsersRemainingButton(userCount);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement(Button, {
            triggerRef: triggerRef,
            onButtonClick: onButtonClick,
            onHoverButton: onHoverButton
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ivDCs")).ModalEntryPointContainer), {
            entryPointReferenceSubject: entryPointReferenceSubject,
            id: "jsw-upsell-modal",
            packageName: "edition-awareness",
            teamName: "growth-tako",
            runtimeProps: {
                shouldRequestSkipTrialFields: shouldRequestSkipTrialFields
            },
            entryPointActions: entryPointActions
        }));
    }
    const $7525aa76a967bde0$var$getUsersRemainingButton = (userCount)=>{
        if (userCount === undefined) return 0, (0, parcelRequire("i8PkL")).UpgradeButton;
        if (userCount >= (0, $af6ccefcb1f83341$export$37efcda5d411f9dc)) return 0, (0, parcelRequire("i8PkL")).NoUsersLeftButton;
        if (userCount >= (0, $af6ccefcb1f83341$export$71bbf33843c4812a) && userCount < (0, $af6ccefcb1f83341$export$37efcda5d411f9dc)) return 0, (0, parcelRequire("i8PkL")).LimitedUsersLeftButton;
        return 0, (0, parcelRequire("i8PkL")).UpgradeButton;
    };
});
parcelRegister("6QqGY", function(module, exports) {
    $parcel$export(module.exports, "useGetJswUserCount", ()=>$3178cc8fe7d8b310$export$69ca982bc6a05f52);
    var $5uXOq = parcelRequire("5uXOq");
    var $bSSG7;
    var $jcw0u;
    var $7f4tk;
    var $8zOmE;
    var $2NMNM;
    var $5oBMG;
    const $3178cc8fe7d8b310$var$jiraEntitlementFilter = (entitlement)=>entitlement.productKey === 'jira-software.ondemand' || entitlement.productKey === 'jira-software.ondemand.billedinccp';
    const $3178cc8fe7d8b310$var$userUsageFilter = (usages)=>usages.unitType === 'USER';
    const $3178cc8fe7d8b310$var$jswUserCountLocalStorage = (0, (0, parcelRequire("bSSG7")).createLocalExpirableStorageProvider)('jsw-user-count');
    const $3178cc8fe7d8b310$var$JSW_USER_COUNT_CACHE_KEY = 'jswUserCount';
    const $3178cc8fe7d8b310$var$JSW_USER_COUNT_CACHE_EXPIRY_MS = 86400000;
    function $3178cc8fe7d8b310$export$9f4725d8995b98a0() {
        const cachedValue = $3178cc8fe7d8b310$var$jswUserCountLocalStorage.get($3178cc8fe7d8b310$var$JSW_USER_COUNT_CACHE_KEY);
        if (cachedValue?.unitCount !== undefined && cachedValue?.unitLimit !== undefined) return cachedValue;
        return undefined;
    }
    function $3178cc8fe7d8b310$var$setCachedUserCountData(data) {
        $3178cc8fe7d8b310$var$jswUserCountLocalStorage.set($3178cc8fe7d8b310$var$JSW_USER_COUNT_CACHE_KEY, data, Date.now() + $3178cc8fe7d8b310$var$JSW_USER_COUNT_CACHE_EXPIRY_MS);
    }
    const $3178cc8fe7d8b310$export$69ca982bc6a05f52 = ({ cloudId: cloudId, shouldUseCache: shouldUseCache, skip: skip = false })=>{
        const productEntitlementDetails = (0, (0, parcelRequire("5oBMG")).useProductEntitlementDetails)();
        const [loading, setLoading] = (0, $5uXOq.useState)(false);
        const [error, setError] = (0, $5uXOq.useState)();
        const [data, setData] = (0, $5uXOq.useState)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        (0, $5uXOq.useEffect)(()=>{
            if (skip) return;
            const jswProductEntitlementDetails = productEntitlementDetails?.['jira-software'];
            if (!jswProductEntitlementDetails) return;
            setLoading(true);
            const cachedData = $3178cc8fe7d8b310$export$9f4725d8995b98a0();
            if (cachedData && shouldUseCache?.(cachedData)) {
                setData(cachedData);
                setLoading(false);
                return;
            }
            (0, (0, parcelRequire("7f4tk")).default)(`/gateway/api/billing-ux/cloud/${cloudId}/billing/entitlement-group-detail`).then((response)=>{
                (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'useGetJswUserCount called', {
                    entitlementGroupDetail: JSON.stringify(response),
                    productEntitlementDetails: JSON.stringify(productEntitlementDetails)
                });
                let unitCount = 0;
                let unitLimit = 0;
                unitLimit = jswProductEntitlementDetails?.unitCount ?? 0;
                const jiraEntitlement = response.entitlements.find($3178cc8fe7d8b310$var$jiraEntitlementFilter);
                if (jiraEntitlement) {
                    const usage = jiraEntitlement.usages.find($3178cc8fe7d8b310$var$userUsageFilter);
                    if (usage) unitCount = usage.unitCount;
                }
                if (unitCount === 0 || unitLimit === 0) (0, (0, parcelRequire("jcw0u")).default)({
                    error: new Error(`unitCount or unitLimit is 0. unitCount: ${unitCount}, unitLimit: ${unitLimit}`),
                    meta: {
                        id: 'useGetJswUserCount-zero-unitCount-or-unitLimit',
                        packageName: (0, $05e1e29a026d4606$export$b8b9d90f9e5bd72b),
                        teamName: 'growth-tako'
                    }
                });
                const userCountData = {
                    unitCount: unitCount,
                    unitLimit: unitLimit
                };
                setData(userCountData);
                $3178cc8fe7d8b310$var$setCachedUserCountData(userCountData);
            }).catch((e)=>{
                setError(e);
                (0, (0, parcelRequire("jcw0u")).default)({
                    error: e,
                    meta: {
                        id: 'useGetJswUserCount',
                        packageName: (0, $05e1e29a026d4606$export$b8b9d90f9e5bd72b),
                        teamName: 'growth-tako'
                    },
                    sendToPrivacyUnsafeSplunk: true
                });
            }).finally(()=>{
                setLoading(false);
            });
        }, [
            cloudId,
            createAnalyticsEvent,
            productEntitlementDetails,
            shouldUseCache,
            skip
        ]);
        return {
            data: data,
            loading: loading,
            error: error
        };
    };
});
const $05e1e29a026d4606$export$b8b9d90f9e5bd72b = 'jiraGrowthUtils';
parcelRegister("4Rfxh", function(module, exports) {
    $parcel$export(module.exports, "jswUpsellModalEntryPoint", ()=>$37d31e88d49c6c1b$export$482106168ab726c5);
    var $a7zw7;
    var $eufEc;
    var $fEDBv;
    const $37d31e88d49c6c1b$export$482106168ab726c5 = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("cg6tW")), {
            moduleId: "bc88d891"
        }),
        getPreloadProps: ({ cloudIds: cloudIds, shouldRequestSkipTrialFields: shouldRequestSkipTrialFields })=>({
                queries: {
                    getStartStandardTrialUrlQuery: {
                        parameters: (0, (0, parcelRequire("a7zw7")).default),
                        variables: {
                            cloudIds: cloudIds,
                            shouldRequestSkipTrialFields: shouldRequestSkipTrialFields
                        }
                    }
                }
            })
    });
});
parcelRegister("a7zw7", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9770f6eac21e7320$export$2e2bcd8739ae039);
    const $9770f6eac21e7320$var$node = {
        "kind": "PreloadableConcreteRequest",
        "params": {
            "id": "fe1f7ce057d6ef9e8a31dfb04c8b8966c1233f4666bdb972e6dc959a9cd84e8b",
            "metadata": {},
            "name": "uiGrowthJswUpsellModal_GetStartStandardTrialUrlQuery",
            "operationKind": "query",
            "text": null
        }
    };
    var $9770f6eac21e7320$export$2e2bcd8739ae039 = $9770f6eac21e7320$var$node;
});
parcelRegister("cg6tW", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("63z7c")
    ]).then(()=>parcelRequire('br21z'));
});
parcelRegister("dRVix", function(module, exports) {
    $parcel$export(module.exports, "useIsJswUsingCcp", ()=>$9ce9420d6adb67d7$export$f8e1c288fefa6e7d);
    $parcel$export(module.exports, "useIsProductUsingCcp", ()=>$9ce9420d6adb67d7$export$b367e04990bbb76d);
    $parcel$export(module.exports, "useLoggedInPage", ()=>$9ce9420d6adb67d7$export$a7ca0608784708a1);
    $parcel$export(module.exports, "getTrialDaysLeft", ()=>$9ce9420d6adb67d7$export$954628993bf3188e);
    $parcel$export(module.exports, "isInGracePeriod", ()=>$9ce9420d6adb67d7$export$271517afaa0c15eb);
    var $kTK0a;
    var $2vSU6;
    var $cYlk7;
    var $9OXo1;
    function $9ce9420d6adb67d7$export$f8e1c288fefa6e7d() {
        const { productEntitlementDetails: productEntitlementDetails } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const jswEntitlement = productEntitlementDetails?.[0, $c06e1abca7c13910$export$1346c9aa3cbb477d];
        return jswEntitlement?.billingSourceSystem === 'CCP';
    }
    function $9ce9420d6adb67d7$export$b367e04990bbb76d(projectType) {
        const { productEntitlementDetails: productEntitlementDetails } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const productKey = (0, (0, parcelRequire("2vSU6")).projectTypeToCanonicalId)(projectType);
        const productEntitlement = productEntitlementDetails?.[productKey];
        return productEntitlement?.billingSourceSystem === 'CCP';
    }
    function $9ce9420d6adb67d7$export$a7ca0608784708a1({ pageUrl: pageUrl }) {
        const { data: currentUserData } = (0, (0, parcelRequire("cYlk7")).useCurrentUser)();
        const encodedPageUrl = encodeURIComponent(pageUrl);
        const encodedUserEmail = encodeURIComponent(currentUserData.user.emailAddress ?? '');
        return `https://${(0, (0, parcelRequire("kTK0a")).getATLContextDomain)('id')}/login?continue=${encodedPageUrl}&login_hint=${encodedUserEmail}&prompt=none`;
    }
    const $9ce9420d6adb67d7$export$9dbbf537dffaafc7 = (timestamp)=>new Date(timestamp).setUTCHours(0, 0, 0, 0);
    const $9ce9420d6adb67d7$export$954628993bf3188e = (currentDate, trialEndTime)=>trialEndTime ? Math.ceil(($9ce9420d6adb67d7$export$9dbbf537dffaafc7(trialEndTime) - currentDate) / 86400000) : undefined;
    const $9ce9420d6adb67d7$export$271517afaa0c15eb = (currentDate, trialEndTime)=>{
        const trialDaysLeft = $9ce9420d6adb67d7$export$954628993bf3188e(currentDate, trialEndTime);
        return !trialDaysLeft || trialDaysLeft < 0;
    };
});
parcelRegister("i8PkL", function(module, exports) {
    $parcel$export(module.exports, "UpgradeButton", ()=>$3dfbb01d45f1a80c$export$5f79cadb3a637092);
    $parcel$export(module.exports, "LimitedUsersLeftButton", ()=>$3dfbb01d45f1a80c$export$60952751aac2977b);
    $parcel$export(module.exports, "NoUsersLeftButton", ()=>$3dfbb01d45f1a80c$export$170587001f205989);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $9yOBd;
    var $gtreQ;
    var $dC5iT;
    var $8CUq3;
    var $koVbs;
    var $bVDaO;
    var $6ZmIe;
    var $7zLIB;
    const $3dfbb01d45f1a80c$export$5f79cadb3a637092 = (props)=>{
        const { triggerRef: triggerRef, onButtonClick: onButtonClick, onHoverButton: onHoverButton } = props;
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6ZmIe")).EATopNavAnimatedButtonContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9yOBd")).default), {
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6ZmIe")).IconWrapper), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bVDaO")).GemIcon), {
                label: ""
            })),
            onClick: onButtonClick,
            theme: (0, (0, parcelRequire("6ZmIe")).EATopNavPillTheme),
            onMouseEnter: onHoverButton,
            ref: triggerRef,
            interactionName: "edition-awareness-users-remaining-free-pill",
            testId: "navigation-apps-sidebar-edition-awareness.ui.free-pill.users-remaining-free-pill.buttons.upgrade"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "span",
            xcss: $3dfbb01d45f1a80c$var$eaTopNavButtonTextStyles
        }, formatMessage((0, (0, parcelRequire("7zLIB")).default).upgrade))));
    };
    const $3dfbb01d45f1a80c$export$60952751aac2977b = (props)=>{
        const { triggerRef: triggerRef, onButtonClick: onButtonClick, onHoverButton: onHoverButton } = props;
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement($3dfbb01d45f1a80c$var$TopNavWarningButtonContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9yOBd")).default), {
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement($3dfbb01d45f1a80c$var$TopNavDoritoIconWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("gtreQ"))))), {
                label: "",
                size: "small"
            })),
            onClick: onButtonClick,
            theme: $3dfbb01d45f1a80c$var$TopNavWarningButtonTheme,
            onMouseEnter: onHoverButton,
            ref: triggerRef,
            interactionName: "edition-awareness-users-remaining-free-pill",
            testId: "navigation-apps-sidebar-edition-awareness.ui.free-pill.users-remaining-free-pill.buttons.limited-users-left"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "span",
            xcss: $3dfbb01d45f1a80c$var$topNavWarningButtonTextStyles
        }, formatMessage((0, (0, parcelRequire("7zLIB")).default).limitedUsersLeft))));
    };
    const $3dfbb01d45f1a80c$export$170587001f205989 = (props)=>{
        const { triggerRef: triggerRef, onButtonClick: onButtonClick, onHoverButton: onHoverButton } = props;
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement($3dfbb01d45f1a80c$var$TopNavWarningButtonContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9yOBd")).default), {
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement($3dfbb01d45f1a80c$var$TopNavDoritoIconWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("gtreQ"))))), {
                label: "",
                size: "small"
            })),
            onClick: onButtonClick,
            theme: $3dfbb01d45f1a80c$var$TopNavWarningButtonTheme,
            onMouseEnter: onHoverButton,
            ref: triggerRef,
            interactionName: "edition-awareness-users-remaining-free-pill",
            testId: "navigation-apps-sidebar-edition-awareness.ui.free-pill.users-remaining-free-pill.buttons.no-users-left"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "span",
            xcss: $3dfbb01d45f1a80c$var$topNavWarningButtonTextStyles
        }, formatMessage((0, (0, parcelRequire("7zLIB")).default).noUsersLeft))));
    };
    const $3dfbb01d45f1a80c$var$TopNavDoritoIconWrapper = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, props.children);
    const $3dfbb01d45f1a80c$var$WarningButtonStyles = {
        color: `var(--ds-icon-warning, ${(0, $829f609a65d26a98$exports).Y500})`,
        '&:hover': {
            cursor: 'pointer',
            background: `linear-gradient(79.51deg, ${`var(--ds-background-accent-orange-subtler-pressed, ${(0, $829f609a65d26a98$exports).Y300})`} 0%, ${`var(--ds-icon-warning, ${(0, $829f609a65d26a98$exports).Y500})`} 98.15%)`,
            color: "var(--ds-text-inverse, #FFFFFF)",
            svg: {
                fill: `var(--ds-chart-orange-bold, ${(0, $829f609a65d26a98$exports).Y400})`
            }
        },
        '&:active': {
            background: `linear-gradient(79.51deg,
                ${`var(--ds-chart-orange-bold, ${(0, $829f609a65d26a98$exports).Y400})`} 0%,
                ${`var(--ds-chart-orange-bolder, ${(0, $829f609a65d26a98$exports).Y500})`} 100%)`
        }
    };
    const $3dfbb01d45f1a80c$var$TopNavWarningButtonTheme = (currentTheme, themeProps)=>{
        const theme = currentTheme(themeProps);
        return {
            ...theme,
            buttonStyles: {
                ...(0, (0, parcelRequire("6ZmIe")).EATopNavPillCss),
                ...$3dfbb01d45f1a80c$var$WarningButtonStyles,
                transition: 'all 0.3s ease-in-out 0s, color 0s'
            }
        };
    };
    const $3dfbb01d45f1a80c$var$topNavWarningButtonTextStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        font: 'font.body'
    });
    const $3dfbb01d45f1a80c$var$eaTopNavButtonTextStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        font: 'font.body',
        color: 'color.text.accent.blue'
    });
    const $3dfbb01d45f1a80c$var$TopNavWarningButtonContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_2rko1l7b _19it1qx2 _4t3i1ul9 _1e0c116y",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("7zLIB", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ee79f2ddd215c59f$export$2e2bcd8739ae039);
    var $7VHMR;
    var $ee79f2ddd215c59f$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        limitedUsersLeft: {
            "id": "navigation-apps-sidebar-edition-awareness.free-pill.users-remaining-free-pill.buttons.limited-users-left",
            "defaultMessage": "Limited users left"
        },
        noUsersLeft: {
            "id": "navigation-apps-sidebar-edition-awareness.free-pill.users-remaining-free-pill.buttons.no-users-left",
            "defaultMessage": "No users left"
        },
        startTrial: {
            "id": "navigation-apps-sidebar-edition-awareness.free-pill.users-remaining-free-pill.buttons.start-trial",
            "defaultMessage": "Start trial"
        },
        startFreeTrial: {
            "id": "navigation-apps-sidebar-edition-awareness.free-pill.users-remaining-free-pill.buttons.start-free-trial",
            "defaultMessage": "Start free trial"
        },
        upgrade: {
            "id": "navigation-apps-sidebar-edition-awareness.free-pill.users-remaining-free-pill.buttons.upgrade",
            "defaultMessage": "Upgrade"
        }
    });
});
parcelRegister("kud4A", function(module, exports) {
    $parcel$export(module.exports, "useCheckEditionAwarenessRequisites", ()=>$e18398aef690783f$export$e574f2d2bec618b6);
    var $5uXOq = parcelRequire("5uXOq");
    var $luVGE;
    var $2vSU6;
    var $jcw0u;
    var $dRVix;
    var $8zOmE;
    var $2NMNM;
    var $ldyPw;
    var $9OXo1;
    const $e18398aef690783f$var$getProductKeyForProductUsersCanUpgradeAndPay = (projectType)=>{
        switch(projectType){
            case 0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT:
                return 'jira-software.ondemand';
            case 0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT:
                return 'jira-servicedesk.ondemand';
            default:
                return undefined;
        }
    };
    function $e18398aef690783f$export$e574f2d2bec618b6(props) {
        const { projectType: projectType } = props;
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { isSiteAdmin: isSiteAdmin } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const isUsingCcp = (0, (0, parcelRequire("dRVix")).useIsProductUsingCcp)(projectType);
        const billingSourceSystem = isUsingCcp ? 'CCP' : 'HAMS';
        const { canUpgradeAndPay: canUpgradeAndPayForJswFree, loading: isCanUpgradeAndPayForJswFreeLoading, ineligibilityReason: jswIneligibilityReason } = (0, (0, parcelRequire("luVGE")).useCanUpgradeAndPayForJswFree)({
            caller: (0, $af6ccefcb1f83341$export$b8b9d90f9e5bd72b),
            skip: projectType !== (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT),
            billingAdminCheckMethod: 'ccpApi'
        });
        const canUpgradeAndPayForJsmFree = isSiteAdmin;
        const shouldSkipProductUserChecks = !isUsingCcp || projectType === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT) && canUpgradeAndPayForJsmFree || projectType === (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT) && (canUpgradeAndPayForJswFree || canUpgradeAndPayForJswFree === undefined) || projectType !== (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT) && projectType !== (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT);
        const { canProductUserUpgradeAndPay: canProductUserUpgradeAndPay, isProductUserBillingAdmin: isProductUserBillingAdmin, isLoading: isUseCanProductUserUpgradeAndPayLoading, error: useCanProductUserUpgradeAndPayError } = (0, (0, parcelRequire("luVGE")).useCanProductUserUpgradeAndPay)({
            skip: shouldSkipProductUserChecks,
            productKey: $e18398aef690783f$var$getProductKeyForProductUsersCanUpgradeAndPay(projectType)
        });
        const sendEligibilityCriteria = (0, $5uXOq.useCallback)((ineligibilityReason)=>(0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'eligibilityCriteria checked', 'editionAwarenessFreePill', {
                ...ineligibilityReason && {
                    ineligibilityReason: ineligibilityReason !== '' ? ineligibilityReason : undefined
                },
                productKey: (0, (0, parcelRequire("2vSU6")).projectTypeToCanonicalId)(projectType),
                billingSourceSystem: billingSourceSystem,
                isSiteAdmin: isSiteAdmin,
                isBillingAdmin: Boolean(isProductUserBillingAdmin || canUpgradeAndPayForJswFree),
                edition: (0, (0, parcelRequire("ldyPw")).FREE_EDITION)
            }), [
            billingSourceSystem,
            canUpgradeAndPayForJswFree,
            createAnalyticsEvent,
            isProductUserBillingAdmin,
            isSiteAdmin,
            projectType
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (!shouldSkipProductUserChecks && isUseCanProductUserUpgradeAndPayLoading) return;
            if (projectType === (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT) && isCanUpgradeAndPayForJswFreeLoading) return;
            if (projectType === (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT)) {
                if (shouldSkipProductUserChecks) sendEligibilityCriteria(jswIneligibilityReason);
                else if (!jswIneligibilityReason || canProductUserUpgradeAndPay) sendEligibilityCriteria(undefined);
                else sendEligibilityCriteria(!canProductUserUpgradeAndPay ? 'cannot upgrade and pay' : jswIneligibilityReason);
            } else if (projectType === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT)) {
                if (shouldSkipProductUserChecks) sendEligibilityCriteria(!canUpgradeAndPayForJsmFree ? 'not a site admin' : undefined);
                else sendEligibilityCriteria(canProductUserUpgradeAndPay === false ? 'cannot upgrade and pay' : undefined);
            }
        }, [
            billingSourceSystem,
            canProductUserUpgradeAndPay,
            canUpgradeAndPayForJsmFree,
            createAnalyticsEvent,
            isCanUpgradeAndPayForJswFreeLoading,
            isSiteAdmin,
            isUseCanProductUserUpgradeAndPayLoading,
            jswIneligibilityReason,
            projectType,
            sendEligibilityCriteria,
            shouldSkipProductUserChecks
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (useCanProductUserUpgradeAndPayError) (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: 'useCheckEditionAwarenessRequisites',
                    packageName: (0, $af6ccefcb1f83341$export$b8b9d90f9e5bd72b)
                },
                error: useCanProductUserUpgradeAndPayError,
                sendToPrivacyUnsafeSplunk: true,
                attributes: {
                    isSiteAdmin: isSiteAdmin,
                    isBillingAdmin: Boolean(isProductUserBillingAdmin || canUpgradeAndPayForJswFree),
                    edition: (0, (0, parcelRequire("ldyPw")).FREE_EDITION)
                }
            });
        }, [
            canUpgradeAndPayForJswFree,
            isProductUserBillingAdmin,
            isSiteAdmin,
            projectType,
            useCanProductUserUpgradeAndPayError
        ]);
        const userCanView = Boolean(projectType === (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT) && (canUpgradeAndPayForJswFree || canProductUserUpgradeAndPay) || projectType === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT) && (canUpgradeAndPayForJsmFree || canProductUserUpgradeAndPay));
        return {
            userCanView: isCanUpgradeAndPayForJswFreeLoading || isUseCanProductUserUpgradeAndPayLoading ? undefined : userCanView,
            billingSourceSystem: billingSourceSystem,
            isBillingAdmin: isCanUpgradeAndPayForJswFreeLoading || isUseCanProductUserUpgradeAndPayLoading ? undefined : Boolean(isProductUserBillingAdmin || canUpgradeAndPayForJswFree)
        };
    }
});
parcelRegister("luVGE", function(module, exports) {
    $parcel$export(module.exports, "useCanUpgradeAndPayForJswFree", ()=>$67815aac06127855$export$f4f07d855739fba1);
    $parcel$export(module.exports, "useCanUpgradeAndPayForCurrentProduct", ()=>$67815aac06127855$export$2e34435b19772971);
    $parcel$export(module.exports, "useCanProductUserUpgradeAndPay", ()=>$67815aac06127855$export$53827a0ff095f14b);
    var $5uXOq = parcelRequire("5uXOq");
    var $8QpSo;
    var $cVzoY;
    var $jcw0u;
    var $iHAwr;
    var $eusud;
    var $8ilPk;
    var $16v4H;
    var $9OXo1;
    var $grETT;
    const $67815aac06127855$var$fetchEntitlementDetails = (entitlementId)=>(0, (0, parcelRequire("eusud")).performGetRequest)(`/gateway/api/ccp/api/v1/entitlements/${entitlementId}/details`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    const $67815aac06127855$var$fetchBillingAdmins = (transactionAccountId)=>(0, (0, parcelRequire("eusud")).performGetRequest)('/gateway/api/ccp/api/v1/billing-admins', {
            headers: {
                'Content-Type': 'application/json',
                'X-transaction-account': transactionAccountId
            }
        });
    const $67815aac06127855$export$2abcbc88aea6e581 = (0, (0, parcelRequire("8QpSo")).default)($67815aac06127855$var$fetchEntitlementDetails);
    const $67815aac06127855$export$294eed4c864e0f71 = (0, (0, parcelRequire("8QpSo")).default)($67815aac06127855$var$fetchBillingAdmins);
    const $67815aac06127855$export$f4f07d855739fba1 = ({ caller: caller, skip: skip = false, billingAdminCheckMethod: billingAdminCheckMethod = 'traits' })=>{
        const [canUpgradeAndPay, setCanUpgradeAndPay] = (0, $5uXOq.useState)(undefined);
        const [loading, setLoading] = (0, $5uXOq.useState)(false);
        const [error, setError] = (0, $5uXOq.useState)(undefined);
        const [ineligibilityReason, setIneligibilityReason] = (0, $5uXOq.useState)(undefined);
        const { isSiteAdmin: isSiteAdmin, cloudId: cloudId, atlassianAccountId: atlassianAccountId, productEntitlementDetails: productEntitlementDetails } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const { fetch: fetch, error: personalizationApiError } = (0, (0, parcelRequire("8ilPk")).useSiteUserPersonalization)();
        const isBillingSystemCcp = productEntitlementDetails?.[0, $c06e1abca7c13910$export$1346c9aa3cbb477d]?.billingSourceSystem === 'CCP';
        const entitlementId = productEntitlementDetails?.[0, $c06e1abca7c13910$export$1346c9aa3cbb477d]?.entitlementId;
        const checkIsCcpBillingAdmin = (0, $5uXOq.useCallback)(async ()=>{
            if (!entitlementId) throw new Error('Empty entitlementId');
            if (!atlassianAccountId) throw new Error('Empty atlassianAccountId');
            if (billingAdminCheckMethod === 'ccpApi') {
                const entitlementDetails = await $67815aac06127855$export$2abcbc88aea6e581(entitlementId);
                const transactionAccountId = entitlementDetails?.transactionAccountId;
                const billingAdminsResponse = await $67815aac06127855$export$294eed4c864e0f71(transactionAccountId);
                const isBillingAdmin = !!billingAdminsResponse?.data?.find(({ id: id = null })=>id === atlassianAccountId);
                return isBillingAdmin;
            }
            const personalizationData = await fetch({
                cloudId: cloudId,
                userId: atlassianAccountId
            });
            if (!personalizationData) return undefined;
            const isBillingAdmin = personalizationData.find((trait)=>trait.name === (0, $02e6bdfbfa6ba416$export$3aad3de2cc377789) && trait.value === true) !== undefined;
            return isBillingAdmin;
        }, [
            atlassianAccountId,
            billingAdminCheckMethod,
            cloudId,
            entitlementId,
            fetch
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (skip) return;
            setLoading(true);
            if (isSiteAdmin) {
                if (isBillingSystemCcp) checkIsCcpBillingAdmin().then((isBillingAdmin)=>{
                    setCanUpgradeAndPay(isBillingAdmin);
                    setIneligibilityReason(isBillingAdmin ? '' : 'not a billing admin');
                }).catch((e)=>{
                    setError(e);
                    setIneligibilityReason('error checking eligibility');
                }).finally(()=>{
                    setLoading(false);
                });
                else {
                    setCanUpgradeAndPay(true);
                    setLoading(false);
                    setIneligibilityReason('');
                }
            } else {
                setCanUpgradeAndPay(false);
                setLoading(false);
                setIneligibilityReason('not a site admin');
            }
        }, [
            atlassianAccountId,
            caller,
            checkIsCcpBillingAdmin,
            isBillingSystemCcp,
            isSiteAdmin,
            skip
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (personalizationApiError) setError(personalizationApiError);
        }, [
            personalizationApiError
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (error) (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: 'useCanUpgradeAndPayForJswFree',
                    packageName: (0, $02e6bdfbfa6ba416$export$b8b9d90f9e5bd72b),
                    teamName: 'growth-tako'
                },
                attributes: {
                    billingSourceSystem: isBillingSystemCcp ? 'CCP' : 'HAMS',
                    caller: caller,
                    billingAdminCheckMethod: billingAdminCheckMethod
                },
                sendToPrivacyUnsafeSplunk: true
            });
        }, [
            error,
            isBillingSystemCcp,
            caller,
            billingAdminCheckMethod
        ]);
        return {
            canUpgradeAndPay: canUpgradeAndPay,
            loading: loading,
            error: error,
            ineligibilityReason: ineligibilityReason
        };
    };
    const $67815aac06127855$export$2e34435b19772971 = ({ caller: caller, skip: skip = false })=>{
        const [canUpgradeAndPay, setCanUpgradeAndPay] = (0, $5uXOq.useState)(undefined);
        const [loading, setLoading] = (0, $5uXOq.useState)(false);
        const [error, setError] = (0, $5uXOq.useState)(undefined);
        const tenantContext = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const subProduct = (0, (0, parcelRequire("16v4H")).useSubProduct)();
        const explicitlyLicensedProducts = (0, (0, parcelRequire("cVzoY")).default)(tenantContext);
        const applicationKey = (0, (0, parcelRequire("iHAwr")).getProductKey)(subProduct, explicitlyLicensedProducts);
        (0, $5uXOq.useEffect)(()=>{
            if (skip) return;
            if (!applicationKey) {
                setError(new Error('Empty applicationKey'));
                return;
            }
            const isBillingSystemCcp = tenantContext.productEntitlementDetails?.[applicationKey]?.billingSourceSystem === 'CCP';
            const entitlementId = tenantContext.productEntitlementDetails?.[applicationKey]?.entitlementId;
            const checkIsCcpBillingAdmin = async ()=>{
                if (!entitlementId) throw new Error('Empty entitlementId');
                if (!tenantContext.atlassianAccountId) throw new Error('Empty atlassianAccountId');
                const entitlementDetails = await $67815aac06127855$export$2abcbc88aea6e581(entitlementId);
                const transactionAccountId = entitlementDetails?.transactionAccountId;
                const billingAdminsResponse = await $67815aac06127855$export$294eed4c864e0f71(transactionAccountId);
                const isBillingAdmin = billingAdminsResponse?.data?.some(({ id: id = null })=>id === tenantContext.atlassianAccountId) ?? false;
                return isBillingAdmin;
            };
            setLoading(true);
            if (tenantContext.isSiteAdmin && tenantContext.atlassianAccountId) {
                if (isBillingSystemCcp) checkIsCcpBillingAdmin().then((isBillingAdmin)=>{
                    setCanUpgradeAndPay(isBillingAdmin);
                }).catch((e)=>{
                    setError(e);
                    (0, (0, parcelRequire("jcw0u")).default)({
                        error: e,
                        meta: {
                            id: 'useCanUpgradeAndPayForCurrentProduct',
                            packageName: (0, $02e6bdfbfa6ba416$export$b8b9d90f9e5bd72b),
                            teamName: 'growth-tako'
                        },
                        attributes: {
                            billingSourceSystem: isBillingSystemCcp ? 'CCP' : 'HAMS',
                            caller: caller,
                            subProduct: subProduct,
                            applicationKey: applicationKey,
                            licensedProducts: tenantContext.licensedProducts,
                            productEntitlementDetails: tenantContext.productEntitlementDetails
                        },
                        sendToPrivacyUnsafeSplunk: true
                    });
                }).finally(()=>{
                    setLoading(false);
                });
                else {
                    setCanUpgradeAndPay(true);
                    setLoading(false);
                }
            } else {
                setCanUpgradeAndPay(false);
                setLoading(false);
            }
        }, [
            applicationKey,
            caller,
            skip,
            subProduct,
            tenantContext.atlassianAccountId,
            tenantContext.isSiteAdmin,
            tenantContext.licensedProducts,
            tenantContext.productEntitlementDetails
        ]);
        return {
            canUpgradeAndPay: canUpgradeAndPay,
            loading: loading,
            error: error
        };
    };
    const $67815aac06127855$export$422d9ad995b055f = ({ caller: caller, onError: onError })=>{
        const tenantContext = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const subProduct = (0, (0, parcelRequire("16v4H")).useSubProduct)();
        const explicitlyLicensedProducts = (0, (0, parcelRequire("cVzoY")).default)(tenantContext);
        const applicationKey = (0, (0, parcelRequire("iHAwr")).getProductKey)(subProduct, explicitlyLicensedProducts);
        const checkIsCcpBillingAdmin = (0, $5uXOq.useCallback)(async (entitlementId)=>{
            if (!entitlementId) throw new Error('Empty entitlementId');
            if (!tenantContext.atlassianAccountId) throw new Error('Empty atlassianAccountId');
            const entitlementDetails = await $67815aac06127855$export$2abcbc88aea6e581(entitlementId);
            const transactionAccountId = entitlementDetails?.transactionAccountId;
            const billingAdminsResponse = await $67815aac06127855$export$294eed4c864e0f71(transactionAccountId);
            const isBillingAdmin = billingAdminsResponse?.data?.some(({ id: id = null })=>id === tenantContext.atlassianAccountId) ?? false;
            return isBillingAdmin;
        }, [
            tenantContext.atlassianAccountId
        ]);
        const hasUpgradeAndBillingPermission = (0, $5uXOq.useCallback)(async ()=>{
            if (!applicationKey) {
                const error = new Error('Empty applicationKey');
                const analyticsAttributes = {
                    caller: caller,
                    subProduct: subProduct,
                    applicationKey: applicationKey,
                    licensedProducts: tenantContext.licensedProducts,
                    productEntitlementDetails: tenantContext.productEntitlementDetails
                };
                onError?.(error, analyticsAttributes);
                return null;
            }
            if (!tenantContext.isSiteAdmin || !tenantContext.atlassianAccountId) return false;
            const isBillingSystemCcp = tenantContext.productEntitlementDetails?.[applicationKey]?.billingSourceSystem === 'CCP';
            if (isBillingSystemCcp) {
                const entitlementId = tenantContext.productEntitlementDetails?.[applicationKey]?.entitlementId;
                return checkIsCcpBillingAdmin(entitlementId).then((isBillingAdmin)=>isBillingAdmin).catch((e)=>{
                    const analyticsAttributes = {
                        billingSourceSystem: isBillingSystemCcp ? 'CCP' : 'HAMS',
                        caller: caller,
                        subProduct: subProduct,
                        applicationKey: applicationKey,
                        licensedProducts: tenantContext.licensedProducts,
                        productEntitlementDetails: tenantContext.productEntitlementDetails
                    };
                    onError?.(e, analyticsAttributes);
                    return null;
                });
            }
            return true;
        }, [
            applicationKey,
            caller,
            checkIsCcpBillingAdmin,
            onError,
            subProduct,
            tenantContext.atlassianAccountId,
            tenantContext.isSiteAdmin,
            tenantContext.licensedProducts,
            tenantContext.productEntitlementDetails
        ]);
        return {
            hasUpgradeAndBillingPermission: hasUpgradeAndBillingPermission
        };
    };
    function $67815aac06127855$export$53827a0ff095f14b({ skip: skip, productKey: productKey }) {
        const { data: data, isLoading: isLoading, error: error, fetch: fetchCanProductUserUpgradeAndPay } = (0, (0, parcelRequire("grETT")).useCanProductUserUpgradeAndPayQuery)();
        (0, $5uXOq.useEffect)(()=>{
            if (skip || !productKey) return;
            fetchCanProductUserUpgradeAndPay(productKey);
        }, [
            fetchCanProductUserUpgradeAndPay,
            productKey,
            skip
        ]);
        const canProductUserUpgradeAndPay = data?.canProductUserUpgradeAndPay;
        const isProductUserBillingAdmin = data?.isBillingAdmin;
        return {
            isProductUserBillingAdmin: isProductUserBillingAdmin,
            canProductUserUpgradeAndPay: canProductUserUpgradeAndPay,
            isLoading: isLoading,
            error: error
        };
    }
});
parcelRegister("iHAwr", function(module, exports) {
    $parcel$export(module.exports, "getProductKey", ()=>$a2785a755bdb8c08$export$d0b7f02493f85477);
    const $a2785a755bdb8c08$export$d0b7f02493f85477 = (subProduct, explicitlyLicensedProducts)=>{
        if (subProduct === 'software') return 0, $c06e1abca7c13910$export$1346c9aa3cbb477d;
        if (subProduct === 'serviceDesk') return 0, $c06e1abca7c13910$export$c8c1c8c3f8ddba61;
        if (subProduct === 'polaris') return 0, $c06e1abca7c13910$export$202f50ee62667e32;
        if (subProduct === 'core') return 0, $c06e1abca7c13910$export$db515c8cec1e6f79;
        if (explicitlyLicensedProducts.length === 1) return explicitlyLicensedProducts[0];
        return null;
    };
});
parcelRegister("8ilPk", function(module, exports) {
    $parcel$export(module.exports, "memoizedFetchSiteUserPersonalization", ()=>$4ab23cf8c5a935d6$export$de93ec54b11be450);
    $parcel$export(module.exports, "useSiteUserPersonalization", ()=>$4ab23cf8c5a935d6$export$8dc79ff315095801);
    var $8QpSo;
    var $ld2hZ;
    var $7f4tk;
    const $4ab23cf8c5a935d6$var$getPersonalizationSiteUserTraitsUrl = (cloudId, userId)=>`/gateway/api/tap-delivery/api/v3/personalization/site/${cloudId}/user/${userId}`;
    const $4ab23cf8c5a935d6$export$8f40704723ec6690 = async ({ cloudId: cloudId, userId: userId })=>{
        if (!cloudId) throw new Error('empty cloudId');
        if (!userId) throw new Error('empty userId');
        const { attributes: attributes } = await (0, (0, parcelRequire("7f4tk")).default)($4ab23cf8c5a935d6$var$getPersonalizationSiteUserTraitsUrl(cloudId, userId), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-atlassian-mau-ignore': 'true'
            }
        });
        return attributes;
    };
    const $4ab23cf8c5a935d6$export$de93ec54b11be450 = (0, (0, parcelRequire("8QpSo")).default)($4ab23cf8c5a935d6$export$8f40704723ec6690, ([{ cloudId: newCloudId, userId: newUserId }], [{ cloudId: lastCloudId, userId: lastUserId }])=>newCloudId === lastCloudId && newUserId === lastUserId);
    const $4ab23cf8c5a935d6$export$8dc79ff315095801 = ()=>(0, (0, parcelRequire("ld2hZ")).useService)($4ab23cf8c5a935d6$export$de93ec54b11be450);
});
parcelRegister("ld2hZ", function(module, exports) {
    $parcel$export(module.exports, "useService", ()=>$b3ad55f76ff82cb6$export$6de4685a6c88b4e9);
    $parcel$export(module.exports, "useLoadingStatusService", ()=>$b3ad55f76ff82cb6$export$c963713a27525aaf);
    var $5uXOq = parcelRequire("5uXOq");
    const $b3ad55f76ff82cb6$export$6de4685a6c88b4e9 = (request, initialState = {})=>{
        const [state, setState] = (0, $5uXOq.useState)({
            loading: false,
            data: undefined,
            error: undefined,
            ...initialState
        });
        const fetch = (0, $5uXOq.useCallback)(async (requestData)=>{
            setState({
                loading: true,
                error: undefined,
                data: undefined
            });
            try {
                const data = await request(requestData);
                setState({
                    loading: false,
                    error: undefined,
                    data: data
                });
                return data;
            } catch (error) {
                setState({
                    loading: false,
                    error: error,
                    data: undefined
                });
                return undefined;
            }
        }, [
            request
        ]);
        return {
            ...state,
            fetch: fetch
        };
    };
    const $b3ad55f76ff82cb6$export$c963713a27525aaf = (request, initialState = {})=>{
        const [loadingStatus, setLoadingStatus] = (0, $5uXOq.useState)('idle');
        const { data: data, loading: loading, error: error, fetch: fetch } = $b3ad55f76ff82cb6$export$6de4685a6c88b4e9(request, initialState);
        (0, $5uXOq.useEffect)(()=>{
            if (loading) setLoadingStatus('started');
            else if (error) setLoadingStatus('error');
            else if (data) setLoadingStatus('success');
        }, [
            data,
            loading,
            error
        ]);
        return {
            data: data,
            loadingStatus: loadingStatus,
            error: error,
            fetch: fetch
        };
    };
});
const $02e6bdfbfa6ba416$export$3aad3de2cc377789 = 'jira_software_free_is_ccp_billing_admins';
const $02e6bdfbfa6ba416$export$b8b9d90f9e5bd72b = 'jiraBilling';
parcelRegister("grETT", function(module, exports) {
    $parcel$export(module.exports, "useCanProductUserUpgradeAndPayQuery", ()=>$112732b26b85a7cd$export$44d5ccfd376c6700);
    var $8MTii;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $koeKL;
    const $112732b26b85a7cd$export$62297b13309008b2 = (0, (0, parcelRequire("8MTii")).default);
    const $112732b26b85a7cd$export$44d5ccfd376c6700 = ()=>{
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const relayEnvironment = (0, $8Uumt.useRelayEnvironment)();
        const [data, setData] = (0, $5uXOq.useState)(undefined);
        const [isLoading, setIsLoading] = (0, $5uXOq.useState)(false);
        const [error, setError] = (0, $5uXOq.useState)(null);
        const fetch = (0, $5uXOq.useCallback)((productKey)=>{
            setIsLoading(true);
            const variables = {
                cloudId: cloudId,
                productKey: productKey
            };
            (0, $8Uumt.fetchQuery)(relayEnvironment, $112732b26b85a7cd$export$62297b13309008b2, variables, {
                fetchPolicy: 'store-or-network'
            }).subscribe({
                complete: ()=>{
                    setIsLoading(false);
                },
                next: (response)=>{
                    const entitlement = response?.tenantContexts?.[0]?.entitlementInfo?.entitlement;
                    const canPay = entitlement?.transactionAccount?.experienceCapabilities?.addPaymentMethodV2?.isAvailableToUser;
                    const canUpgrade = entitlement?.experienceCapabilities?.changeOfferingV2?.isAvailableToUser;
                    const isCurrentUserBillingAdmin = entitlement?.transactionAccount?.isCurrentUserBillingAdmin;
                    const canUpgradeAndPay = canUpgrade && canPay;
                    setData({
                        canProductUserUpgradeAndPay: canUpgradeAndPay ?? false,
                        isBillingAdmin: isCurrentUserBillingAdmin ?? false
                    });
                },
                error: (fetchError)=>{
                    setIsLoading(false);
                    setError(fetchError);
                }
            });
        }, [
            cloudId,
            relayEnvironment
        ]);
        return {
            fetch: fetch,
            data: data,
            isLoading: isLoading,
            error: error
        };
    };
});
parcelRegister("8MTii", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a332fe2790ce2104$export$2e2bcd8739ae039);
    const $a332fe2790ce2104$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cloudId"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "productKey"
            }
        ], v1 = [
            {
                "items": [
                    {
                        "kind": "Variable",
                        "name": "cloudIds.0",
                        "variableName": "cloudId"
                    }
                ],
                "kind": "ListValue",
                "name": "cloudIds"
            }
        ], v2 = [
            {
                "kind": "Variable",
                "name": "hamsProductKey",
                "variableName": "productKey"
            }
        ], v3 = {
            "kind": "ScalarField",
            "name": "isCurrentUserBillingAdmin"
        }, v4 = {
            "kind": "ScalarField",
            "name": "isAvailableToUser"
        }, v5 = {
            "kind": "ScalarField",
            "name": "__typename"
        }, v6 = [
            v5,
            v4
        ], v7 = {
            "kind": "ScalarField",
            "name": "id"
        };
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "canProductUserUpgradeAndPayQuery",
                "selections": [
                    {
                        "args": v1,
                        "concreteType": "TenantContext",
                        "kind": "LinkedField",
                        "name": "tenantContexts",
                        "plural": true,
                        "selections": [
                            {
                                "args": v2,
                                "kind": "LinkedField",
                                "name": "entitlementInfo",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "LinkedField",
                                        "name": "entitlement",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "LinkedField",
                                                "name": "transactionAccount",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "kind": "RequiredField",
                                                        "field": v3,
                                                        "action": "THROW",
                                                        "path": "tenantContexts.entitlementInfo.entitlement.transactionAccount.isCurrentUserBillingAdmin"
                                                    },
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "experienceCapabilities",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "kind": "LinkedField",
                                                                "name": "addPaymentMethodV2",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "kind": "RequiredField",
                                                                        "field": v4,
                                                                        "action": "THROW",
                                                                        "path": "tenantContexts.entitlementInfo.entitlement.transactionAccount.experienceCapabilities.addPaymentMethodV2.isAvailableToUser"
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            },
                                            {
                                                "kind": "LinkedField",
                                                "name": "experienceCapabilities",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "changeOfferingV2",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "kind": "RequiredField",
                                                                "field": v4,
                                                                "action": "THROW",
                                                                "path": "tenantContexts.entitlementInfo.entitlement.experienceCapabilities.changeOfferingV2.isAvailableToUser"
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "canProductUserUpgradeAndPayQuery",
                "selections": [
                    {
                        "args": v1,
                        "concreteType": "TenantContext",
                        "kind": "LinkedField",
                        "name": "tenantContexts",
                        "plural": true,
                        "selections": [
                            {
                                "args": v2,
                                "kind": "LinkedField",
                                "name": "entitlementInfo",
                                "plural": false,
                                "selections": [
                                    v5,
                                    {
                                        "kind": "LinkedField",
                                        "name": "entitlement",
                                        "plural": false,
                                        "selections": [
                                            v5,
                                            {
                                                "kind": "LinkedField",
                                                "name": "transactionAccount",
                                                "plural": false,
                                                "selections": [
                                                    v5,
                                                    v3,
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "experienceCapabilities",
                                                        "plural": false,
                                                        "selections": [
                                                            v5,
                                                            {
                                                                "kind": "LinkedField",
                                                                "name": "addPaymentMethodV2",
                                                                "plural": false,
                                                                "selections": v6
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                            v7
                                                        ],
                                                        "type": "CcpTransactionAccount"
                                                    }
                                                ]
                                            },
                                            {
                                                "kind": "LinkedField",
                                                "name": "experienceCapabilities",
                                                "plural": false,
                                                "selections": [
                                                    v5,
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "changeOfferingV2",
                                                        "plural": false,
                                                        "selections": v6
                                                    }
                                                ]
                                            },
                                            v7
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "9308b51a7dfc7d956d54b611c603679c467cba651b645e963aac170dd0f5ba0b",
                "metadata": {},
                "name": "canProductUserUpgradeAndPayQuery",
                "operationKind": "query",
                "text": null
            }
        };
    }();
    $a332fe2790ce2104$var$node.hash = "018980114a88416ec612e7565b16db6c";
    var $a332fe2790ce2104$export$2e2bcd8739ae039 = $a332fe2790ce2104$var$node;
});
parcelRegister("4XZMs", function(module, exports) {
    $parcel$export(module.exports, "TrialPill", ()=>$6970f6913a0b966d$export$dd77a69c423022b0);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $fNobW;
    var $2vSU6;
    var $ld2hZ;
    var $6s1PB;
    var $dRVix;
    var $ldyPw;
    var $koeKL;
    var $9OXo1;
    var $6ZmIe;
    var $6Myxd;
    var $73N85;
    var $jasTs;
    var $h1xoN;
    const $6970f6913a0b966d$var$TrialPillComponent = ({ edition: edition, productKey: productKey, projectType: projectType, entitlementDetails: entitlementDetails })=>{
        const { billingSourceSystem: billingSourceSystem } = entitlementDetails;
        const [shouldRenderPill, setShouldRenderPill] = (0, $5uXOq.useState)(null);
        const relayEnvironment = (0, $8Uumt.useRelayEnvironment)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const fireEligibilityCheckEvent = (0, (0, parcelRequire("h1xoN")).useEligibilityCheckEvent)({
            productKey: productKey,
            projectType: projectType,
            edition: edition,
            entitlementDetails: entitlementDetails
        });
        const { loading: billingInformationLoading, data: billingInformation, error: billingInformationError, fetch: fetchBillingInformation } = (0, (0, parcelRequire("ld2hZ")).useService)((0, (0, parcelRequire("73N85")).getBillingInformation));
        const { isEligibleForJsmPremiumPillExperiment: isEligibleForJsmPremiumPillExperiment } = (0, (0, parcelRequire("h1xoN")).useJsmPremiumPillExperiment)({
            edition: edition
        });
        (0, $5uXOq.useEffect)(()=>{
            fetchBillingInformation({
                cloudId: cloudId,
                productKey: productKey,
                relayEnvironment: relayEnvironment
            });
        }, [
            fetchBillingInformation,
            cloudId,
            productKey,
            relayEnvironment
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (billingInformationError) {
                fireEligibilityCheckEvent('error', {
                    error: (0, (0, parcelRequire("h1xoN")).transformError)(billingInformationError)
                });
                setShouldRenderPill(false);
                return;
            }
            if (billingInformation) {
                const ineligibilityReasons = (0, (0, parcelRequire("h1xoN")).getIneligibilityReasons)(billingInformation);
                if (isEligibleForJsmPremiumPillExperiment) {
                    fireEligibilityCheckEvent(null, {
                        billingInformation: billingInformation,
                        isEligibleForJsmPremiumPillExperiment: isEligibleForJsmPremiumPillExperiment
                    });
                    setShouldRenderPill(true);
                    return;
                }
                if (ineligibilityReasons.length > 0) {
                    const shouldPerformProductUserChecks = billingSourceSystem === 'CCP' && billingInformation.isBillingAdmin === false && ineligibilityReasons.length === 1 && ineligibilityReasons[0] === 'notBillingAdmin';
                    if (shouldPerformProductUserChecks) try {
                        (0, (0, parcelRequire("6Myxd")).canProductUserPay)(cloudId, productKey, relayEnvironment).then((canPay)=>{
                            setShouldRenderPill(canPay);
                            fireEligibilityCheckEvent(canPay ? null : 'productUserCannotPay', {
                                billingInformation: billingInformation,
                                ineligibilityReasons: ineligibilityReasons
                            });
                        });
                    } catch (error) {
                        fireEligibilityCheckEvent('canProductUserPayError', {
                            error: (0, (0, parcelRequire("h1xoN")).transformError)(error),
                            billingInformation: billingInformation,
                            ineligibilityReasons: ineligibilityReasons
                        });
                        setShouldRenderPill(false);
                    }
                    else {
                        fireEligibilityCheckEvent(ineligibilityReasons[0], {
                            billingInformation: billingInformation,
                            ineligibilityReasons: ineligibilityReasons
                        });
                        setShouldRenderPill(false);
                    }
                } else {
                    fireEligibilityCheckEvent(null, {
                        billingInformation: billingInformation
                    });
                    setShouldRenderPill(true);
                }
            } else setShouldRenderPill(false);
        }, [
            billingInformation,
            billingInformationError,
            billingSourceSystem,
            cloudId,
            fireEligibilityCheckEvent,
            isEligibleForJsmPremiumPillExperiment,
            productKey,
            relayEnvironment
        ]);
        if (billingInformationLoading) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fNobW")).default), {
            name: "edition-awareness-billing-information-loading"
        });
        if (!billingInformation) return null;
        if (shouldRenderPill) {
            if (projectType === (0, (0, parcelRequire("2vSU6")).CORE_PROJECT) && edition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION)) {
                if (!(0, (0, parcelRequire("6s1PB")).expValEquals)('edition_awareness_on_jwm', 'isEnabled', true)) return null;
            }
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6ZmIe")).EditionAwarenessContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jasTs")).TrialPillButton), {
                productKey: productKey,
                projectType: projectType,
                edition: edition,
                entitlementDetails: entitlementDetails,
                invoiceGroupId: billingInformation.invoiceGroupId,
                transactionAccountId: billingInformation.transactionAccountId,
                isBillingAdmin: billingInformation.isBillingAdmin,
                trialLengthDays: billingInformation.trialLengthDays,
                isInvoiceable: billingInformation.invoiceable
            }));
        }
        return null;
    };
    const $6970f6913a0b966d$export$dd77a69c423022b0 = ({ productKey: productKey, projectType: projectType, edition: edition })=>{
        const [shouldRender, setShouldRender] = (0, $5uXOq.useState)(null);
        const { productEntitlementDetails: productEntitlementDetails } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const entitlementDetails = productEntitlementDetails?.[productKey];
        const billingSourceSystem = entitlementDetails?.billingSourceSystem || 'HAMS';
        const memoizedEntitlementDetails = (0, $5uXOq.useMemo)(()=>({
                ...entitlementDetails,
                billingSourceSystem: billingSourceSystem
            }), [
            billingSourceSystem,
            entitlementDetails
        ]);
        const currentDate = (0, $5uXOq.useMemo)(()=>Date.now(), []);
        const trialDaysLeft = (0, (0, parcelRequire("dRVix")).getTrialDaysLeft)(currentDate, entitlementDetails?.trialEndTime);
        const notInTrialOrGracePeriod = billingSourceSystem === 'HAMS' && (!trialDaysLeft || trialDaysLeft < -14);
        const fireEligibilityCheckEvent = (0, (0, parcelRequire("h1xoN")).useEligibilityCheckEvent)({
            productKey: productKey,
            projectType: projectType,
            edition: edition,
            entitlementDetails: entitlementDetails
        });
        (0, $5uXOq.useEffect)(()=>{
            if (edition !== (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION) && edition !== (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION)) {
                fireEligibilityCheckEvent('invalidEdition');
                setShouldRender(false);
            } else if (!entitlementDetails) {
                fireEligibilityCheckEvent('missingEntitlementDetails', {
                    productEntitlementDetails: productEntitlementDetails
                });
                setShouldRender(false);
            } else if (notInTrialOrGracePeriod) {
                fireEligibilityCheckEvent('notInTrialOrGracePeriod');
                setShouldRender(false);
            } else setShouldRender(true);
        }, [
            edition,
            entitlementDetails,
            fireEligibilityCheckEvent,
            productEntitlementDetails,
            notInTrialOrGracePeriod
        ]);
        if (shouldRender) return (0, ($parcel$interopDefault($5uXOq))).createElement($6970f6913a0b966d$var$TrialPillComponent, {
            edition: edition,
            productKey: productKey,
            projectType: projectType,
            entitlementDetails: memoizedEntitlementDetails
        });
        return null;
    };
});
parcelRegister("6Myxd", function(module, exports) {
    $parcel$export(module.exports, "canProductUserPay", ()=>$1afb83811cce850d$export$2559b2eb40941f10);
    var $fD89J;
    var $8Uumt = parcelRequire("8Uumt");
    const $1afb83811cce850d$export$62297b13309008b2 = (0, (0, parcelRequire("fD89J")).default);
    const $1afb83811cce850d$export$2559b2eb40941f10 = async (cloudId, productKey, relayEnvironment)=>{
        const commerceProductKey = productKey === (0, $c06e1abca7c13910$export$202f50ee62667e32) ? productKey : `${productKey}.ondemand`;
        const variables = {
            cloudId: cloudId,
            productKey: commerceProductKey
        };
        const response = await (0, $8Uumt.fetchQuery)(relayEnvironment, $1afb83811cce850d$export$62297b13309008b2, variables, {
            fetchPolicy: 'store-or-network'
        }).toPromise();
        const entitlementInfo = response?.tenantContexts && response.tenantContexts.length > 0 ? response.tenantContexts[0]?.entitlementInfo : undefined;
        const canPay = entitlementInfo?.entitlement?.subscription?.accountDetails?.invoiceGroup?.experienceCapabilities?.configurePaymentV2?.isAvailableToUser;
        return Boolean(canPay);
    };
});
parcelRegister("fD89J", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$52c41a3edf7df773$export$2e2bcd8739ae039);
    const $52c41a3edf7df773$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cloudId"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "productKey"
            }
        ], v1 = [
            {
                "items": [
                    {
                        "kind": "Variable",
                        "name": "cloudIds.0",
                        "variableName": "cloudId"
                    }
                ],
                "kind": "ListValue",
                "name": "cloudIds"
            }
        ], v2 = [
            {
                "kind": "Variable",
                "name": "hamsProductKey",
                "variableName": "productKey"
            }
        ], v3 = {
            "kind": "ScalarField",
            "name": "isAvailableToUser"
        }, v4 = {
            "kind": "ScalarField",
            "name": "__typename"
        }, v5 = {
            "kind": "ScalarField",
            "name": "id"
        }, v6 = [
            v5
        ];
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "canProductUserPayQuery",
                "selections": [
                    {
                        "args": v1,
                        "concreteType": "TenantContext",
                        "kind": "LinkedField",
                        "name": "tenantContexts",
                        "plural": true,
                        "selections": [
                            {
                                "args": v2,
                                "kind": "LinkedField",
                                "name": "entitlementInfo",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "LinkedField",
                                        "name": "entitlement",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "LinkedField",
                                                "name": "subscription",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "accountDetails",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "kind": "LinkedField",
                                                                "name": "invoiceGroup",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "kind": "LinkedField",
                                                                        "name": "experienceCapabilities",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            {
                                                                                "kind": "LinkedField",
                                                                                "name": "configurePaymentV2",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    {
                                                                                        "kind": "RequiredField",
                                                                                        "field": v3,
                                                                                        "action": "THROW",
                                                                                        "path": "tenantContexts.entitlementInfo.entitlement.subscription.accountDetails.invoiceGroup.experienceCapabilities.configurePaymentV2.isAvailableToUser"
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "canProductUserPayQuery",
                "selections": [
                    {
                        "args": v1,
                        "concreteType": "TenantContext",
                        "kind": "LinkedField",
                        "name": "tenantContexts",
                        "plural": true,
                        "selections": [
                            {
                                "args": v2,
                                "kind": "LinkedField",
                                "name": "entitlementInfo",
                                "plural": false,
                                "selections": [
                                    v4,
                                    {
                                        "kind": "LinkedField",
                                        "name": "entitlement",
                                        "plural": false,
                                        "selections": [
                                            v4,
                                            {
                                                "kind": "LinkedField",
                                                "name": "subscription",
                                                "plural": false,
                                                "selections": [
                                                    v4,
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "accountDetails",
                                                        "plural": false,
                                                        "selections": [
                                                            v4,
                                                            {
                                                                "kind": "LinkedField",
                                                                "name": "invoiceGroup",
                                                                "plural": false,
                                                                "selections": [
                                                                    v4,
                                                                    {
                                                                        "kind": "LinkedField",
                                                                        "name": "experienceCapabilities",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            v4,
                                                                            {
                                                                                "kind": "LinkedField",
                                                                                "name": "configurePaymentV2",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    v4,
                                                                                    v3
                                                                                ]
                                                                            }
                                                                        ]
                                                                    },
                                                                    {
                                                                        "kind": "InlineFragment",
                                                                        "selections": v6,
                                                                        "type": "CcpInvoiceGroup"
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "kind": "InlineFragment",
                                                        "selections": v6,
                                                        "type": "CcpSubscription"
                                                    }
                                                ]
                                            },
                                            v5
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "214adfa29ea1bab0c1a4893dc453d76b1c749f86c52652514fb64580b5f60ffb",
                "metadata": {},
                "name": "canProductUserPayQuery",
                "operationKind": "query",
                "text": null
            }
        };
    }();
    $52c41a3edf7df773$var$node.hash = "0064ac2d6628f537533e7f6c91f85010";
    var $52c41a3edf7df773$export$2e2bcd8739ae039 = $52c41a3edf7df773$var$node;
});
parcelRegister("73N85", function(module, exports) {
    $parcel$export(module.exports, "getBillingInformation", ()=>$bf6e8e3c9b87751c$export$8299b86bb18aafc1);
    var $fKZAU;
    const $bf6e8e3c9b87751c$export$8299b86bb18aafc1 = async ({ cloudId: cloudId, productKey: productKey, relayEnvironment: relayEnvironment })=>{
        if (!cloudId) throw new Error('empty cloudId');
        if (!productKey) throw new Error('empty productKey');
        return (0, (0, parcelRequire("fKZAU")).getBillingInformationGraphql)(cloudId, productKey, relayEnvironment);
    };
});
parcelRegister("fKZAU", function(module, exports) {
    $parcel$export(module.exports, "getBillingInformationGraphql", ()=>$e0e609b1459fd4e4$export$719aff12724d0b22);
    var $ltbFE;
    var $eiowN = parcelRequire("eiowN");
    var $4R6GH;
    const $e0e609b1459fd4e4$export$62297b13309008b2 = (0, (0, parcelRequire("ltbFE")).default);
    const $e0e609b1459fd4e4$export$719aff12724d0b22 = async (cloudId, productKey, relayEnvironment)=>{
        const commerceProductKey = productKey === (0, $c06e1abca7c13910$export$202f50ee62667e32) ? productKey : `${productKey}.ondemand`;
        const response = await (0, $eiowN.fetchQuery)(relayEnvironment, $e0e609b1459fd4e4$export$62297b13309008b2, {
            cloudId: cloudId,
            productKey: commerceProductKey,
            isTrialCountdownFgExpEnabled: (0, (0, parcelRequire("4R6GH")).fg)('jira_edition_awareness_trial_countdown_gate')
        }, {
            fetchPolicy: 'store-or-network'
        }).toPromise();
        const entitlementInfo = response?.tenantContexts && response.tenantContexts.length > 0 ? response.tenantContexts[0]?.entitlementInfo : undefined;
        const entitlement = entitlementInfo?.entitlement;
        const subscription = entitlement?.subscription;
        const invoiceGroup = subscription?.accountDetails?.invoiceGroup;
        const pricingPlan = subscription?.pricingPlan;
        const transactionAccount = entitlement?.transactionAccount;
        const offering = entitlement?.offering;
        return {
            notInTrialOrPredunning: entitlement === null,
            invoiceable: invoiceGroup?.invoiceable ?? null,
            transactionAccountId: transactionAccount?.key ?? null,
            invoiceGroupId: invoiceGroup?.id ?? null,
            isBillingAdmin: transactionAccount?.isCurrentUserBillingAdmin ?? null,
            partnerManaged: transactionAccount?.isManagedByPartner ?? null,
            billingInterval: pricingPlan?.primaryCycle?.interval ?? null,
            pricingPlanType: pricingPlan?.type ?? null,
            overriddenEdition: entitlement?.overriddenEdition ?? null,
            trialLengthDays: offering?.trial?.lengthDays ?? null,
            rawResponse: response
        };
    };
});
parcelRegister("ltbFE", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$1e95e63bf2bebacd$export$2e2bcd8739ae039);
    const $1e95e63bf2bebacd$var$node = function() {
        var v0 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cloudId"
        }, v1 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "isTrialCountdownFgExpEnabled"
        }, v2 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "productKey"
        }, v3 = [
            {
                "items": [
                    {
                        "kind": "Variable",
                        "name": "cloudIds.0",
                        "variableName": "cloudId"
                    }
                ],
                "kind": "ListValue",
                "name": "cloudIds"
            }
        ], v4 = [
            {
                "kind": "Variable",
                "name": "hamsProductKey",
                "variableName": "productKey"
            }
        ], v5 = [
            {
                "kind": "Literal",
                "name": "where",
                "value": {
                    "AND": [
                        {
                            "inTrialOrPreDunning": true
                        }
                    ]
                }
            }
        ], v6 = {
            "kind": "ScalarField",
            "name": "invoiceable"
        }, v7 = {
            "kind": "ScalarField",
            "name": "id"
        }, v8 = [
            v7
        ], v9 = {
            "kind": "InlineFragment",
            "selections": v8,
            "type": "CcpInvoiceGroup"
        }, v10 = {
            "kind": "ScalarField",
            "name": "interval"
        }, v11 = {
            "kind": "ScalarField",
            "name": "type"
        }, v12 = {
            "kind": "ScalarField",
            "name": "key"
        }, v13 = {
            "kind": "ScalarField",
            "name": "isCurrentUserBillingAdmin"
        }, v14 = {
            "kind": "ScalarField",
            "name": "isManagedByPartner"
        }, v15 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "overriddenEdition"
                }
            ],
            "type": "HamsEntitlement"
        }, v16 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "CcpOfferingTrial",
                    "kind": "LinkedField",
                    "name": "trial",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "name": "lengthDays"
                        }
                    ]
                }
            ],
            "type": "CcpOffering"
        }, v17 = {
            "kind": "ScalarField",
            "name": "__typename"
        }, v18 = {
            "kind": "InlineFragment",
            "selections": v8,
            "type": "Node",
            "abstractKey": "__isNode"
        };
        return {
            "fragment": {
                "argumentDefinitions": [
                    v0,
                    v1,
                    v2
                ],
                "kind": "Fragment",
                "name": "commerceSharedApiEditionAwarenessTrialQuery",
                "selections": [
                    {
                        "args": v3,
                        "concreteType": "TenantContext",
                        "kind": "LinkedField",
                        "name": "tenantContexts",
                        "plural": true,
                        "selections": [
                            {
                                "args": v4,
                                "kind": "LinkedField",
                                "name": "entitlementInfo",
                                "plural": false,
                                "selections": [
                                    {
                                        "args": v5,
                                        "kind": "LinkedField",
                                        "name": "entitlement",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "LinkedField",
                                                "name": "subscription",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "accountDetails",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "kind": "LinkedField",
                                                                "name": "invoiceGroup",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "kind": "RequiredField",
                                                                        "field": v6,
                                                                        "action": "THROW",
                                                                        "path": "tenantContexts.entitlementInfo.entitlement.subscription.accountDetails.invoiceGroup.invoiceable"
                                                                    },
                                                                    v9
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "pricingPlan",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "kind": "LinkedField",
                                                                "name": "primaryCycle",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "kind": "RequiredField",
                                                                        "field": v10,
                                                                        "action": "THROW",
                                                                        "path": "tenantContexts.entitlementInfo.entitlement.subscription.pricingPlan.primaryCycle.interval"
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                "kind": "RequiredField",
                                                                "field": v11,
                                                                "action": "THROW",
                                                                "path": "tenantContexts.entitlementInfo.entitlement.subscription.pricingPlan.type"
                                                            }
                                                        ]
                                                    }
                                                ]
                                            },
                                            {
                                                "kind": "LinkedField",
                                                "name": "transactionAccount",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "kind": "RequiredField",
                                                        "field": v12,
                                                        "action": "THROW",
                                                        "path": "tenantContexts.entitlementInfo.entitlement.transactionAccount.key"
                                                    },
                                                    {
                                                        "kind": "RequiredField",
                                                        "field": v13,
                                                        "action": "THROW",
                                                        "path": "tenantContexts.entitlementInfo.entitlement.transactionAccount.isCurrentUserBillingAdmin"
                                                    },
                                                    {
                                                        "kind": "RequiredField",
                                                        "field": v14,
                                                        "action": "THROW",
                                                        "path": "tenantContexts.entitlementInfo.entitlement.transactionAccount.isManagedByPartner"
                                                    }
                                                ]
                                            },
                                            v15,
                                            {
                                                "condition": "isTrialCountdownFgExpEnabled",
                                                "kind": "Condition",
                                                "passingValue": true,
                                                "selections": [
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "offering",
                                                        "plural": false,
                                                        "selections": [
                                                            v16
                                                        ]
                                                    }
                                                ]
                                            }
                                        ],
                                        "storageKey": "entitlement(where:{\"AND\":[{\"inTrialOrPreDunning\":true}]})"
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": [
                    v0,
                    v2,
                    v1
                ],
                "kind": "Operation",
                "name": "commerceSharedApiEditionAwarenessTrialQuery",
                "selections": [
                    {
                        "args": v3,
                        "concreteType": "TenantContext",
                        "kind": "LinkedField",
                        "name": "tenantContexts",
                        "plural": true,
                        "selections": [
                            {
                                "args": v4,
                                "kind": "LinkedField",
                                "name": "entitlementInfo",
                                "plural": false,
                                "selections": [
                                    v17,
                                    {
                                        "args": v5,
                                        "kind": "LinkedField",
                                        "name": "entitlement",
                                        "plural": false,
                                        "selections": [
                                            v17,
                                            {
                                                "kind": "LinkedField",
                                                "name": "subscription",
                                                "plural": false,
                                                "selections": [
                                                    v17,
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "accountDetails",
                                                        "plural": false,
                                                        "selections": [
                                                            v17,
                                                            {
                                                                "kind": "LinkedField",
                                                                "name": "invoiceGroup",
                                                                "plural": false,
                                                                "selections": [
                                                                    v17,
                                                                    v6,
                                                                    v9
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "pricingPlan",
                                                        "plural": false,
                                                        "selections": [
                                                            v17,
                                                            {
                                                                "kind": "LinkedField",
                                                                "name": "primaryCycle",
                                                                "plural": false,
                                                                "selections": [
                                                                    v17,
                                                                    v10
                                                                ]
                                                            },
                                                            v11,
                                                            v18
                                                        ]
                                                    },
                                                    {
                                                        "kind": "InlineFragment",
                                                        "selections": v8,
                                                        "type": "CcpSubscription"
                                                    }
                                                ]
                                            },
                                            {
                                                "kind": "LinkedField",
                                                "name": "transactionAccount",
                                                "plural": false,
                                                "selections": [
                                                    v17,
                                                    v12,
                                                    v13,
                                                    v14,
                                                    {
                                                        "kind": "InlineFragment",
                                                        "selections": v8,
                                                        "type": "CcpTransactionAccount"
                                                    }
                                                ]
                                            },
                                            v15,
                                            {
                                                "condition": "isTrialCountdownFgExpEnabled",
                                                "kind": "Condition",
                                                "passingValue": true,
                                                "selections": [
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "offering",
                                                        "plural": false,
                                                        "selections": [
                                                            v17,
                                                            v16,
                                                            v18
                                                        ]
                                                    }
                                                ]
                                            },
                                            v7
                                        ],
                                        "storageKey": "entitlement(where:{\"AND\":[{\"inTrialOrPreDunning\":true}]})"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "cc0ff0a99bd7b8f27a86750e90c3592de08f7bbd4b73a073bbda4fa57e355f6f",
                "metadata": {},
                "name": "commerceSharedApiEditionAwarenessTrialQuery",
                "operationKind": "query",
                "text": null
            }
        };
    }();
    $1e95e63bf2bebacd$var$node.hash = "9cc1096a838ef1785f076b6ad1eb2e94";
    var $1e95e63bf2bebacd$export$2e2bcd8739ae039 = $1e95e63bf2bebacd$var$node;
});
parcelRegister("jasTs", function(module, exports) {
    $parcel$export(module.exports, "TrialPillButton", ()=>$59e268577d5e3e0c$export$19063b5b00c8046c);
    var $5uXOq = parcelRequire("5uXOq");
    var $2gQ83;
    var $5Fk4Z;
    var $3bDjY;
    var $dvyoy;
    var $ayv8Y;
    var $2vSU6;
    var $4du81;
    var $5Q4f1;
    var $6s1PB;
    var $4R6GH;
    var $ousew;
    var $2KAtx;
    var $aIlMT;
    var $cIObI;
    var $dRVix;
    var $iXVV6;
    var $8zOmE;
    var $2NMNM;
    var $efvDm;
    var $ldyPw;
    var $koeKL;
    var $2tT7R;
    var $cNd4s;
    var $DVljb;
    var $h1xoN;
    var $1fNl5;
    var $eb7dL;
    var $8qT8y;
    var $87Yl2;
    const $59e268577d5e3e0c$var$TrialButtonText = ({ inGracePeriod: inGracePeriod, trialDaysLeft: trialDaysLeft, edition: edition, trialCountdownExperimentEnabled: trialCountdownExperimentEnabled, trialLengthDays: trialLengthDays })=>{
        if (inGracePeriod) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), {
            id: "navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.add-payment-details-sentence-case",
            defaultMessage: "Add payment details"
        });
        const shouldShowTrialMessage = trialCountdownExperimentEnabled && !!trialDaysLeft && !!trialLengthDays && trialDaysLeft > Math.ceil(trialLengthDays / 2);
        if (shouldShowTrialMessage) {
            if (edition === (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION)) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), {
                id: "navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.premium-trial",
                defaultMessage: "Premium trial"
            });
            if (edition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION)) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), {
                id: "navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.standard-trial",
                defaultMessage: "Standard trial"
            });
        }
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), {
            id: "navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.days-left-button",
            defaultMessage: "{trialDaysLeft, plural, one {# day} other {# days}} left",
            values: {
                trialDaysLeft: trialDaysLeft
            }
        });
    };
    const $59e268577d5e3e0c$var$TooltipText = ({ showAddPaymentDetails: showAddPaymentDetails })=>{
        return showAddPaymentDetails ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), {
            id: "navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.add-payment-details-tooltip",
            defaultMessage: "Add payment details to keep your subscription"
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), {
            id: "navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.trial-button-tooltip",
            defaultMessage: "More about your trial and how to pay"
        });
    };
    const $59e268577d5e3e0c$var$TrialPillButtonInner = ({ productKey: productKey, projectType: projectType, edition: edition, entitlementDetails: entitlementDetails, invoiceGroupId: invoiceGroupId, transactionAccountId: transactionAccountId, isBillingAdmin: isBillingAdmin, isInvoiceable: isInvoiceable, trialLengthDays: trialLengthDays })=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const locale = (0, (0, parcelRequire("DVljb")).useLocale)();
        const environment = (0, (0, parcelRequire("2tT7R")).useEnvironment)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const isSiteAdmin = (0, (0, parcelRequire("cNd4s")).useIsSiteAdmin)();
        const { trialEndTime: trialEndTime, billingSourceSystem: billingSourceSystem } = entitlementDetails;
        const atlOrigin = (0, $5uXOq.useMemo)(()=>new (0, (0, parcelRequire("2gQ83")).default)({
                product: 'jira'
            }), []);
        const currentDate = (0, $5uXOq.useMemo)(()=>Date.now(), []);
        const trialDaysLeft = (0, (0, parcelRequire("dRVix")).getTrialDaysLeft)(currentDate, trialEndTime);
        const inGracePeriod = (0, (0, parcelRequire("dRVix")).isInGracePeriod)(currentDate, trialEndTime);
        const isEnglishLocale = locale.startsWith('en');
        const isJswStandard = productKey === 'jira-software' && edition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION);
        const shouldShowJsmJpdTrialAwarenessModalV2 = (0, (0, parcelRequire("cIObI")).isEligibleForJsmJpdTrialAwarenessV2)(projectType);
        const { cohort: premiumFeaturesCohort } = (0, (0, parcelRequire("87Yl2")).getDemonstratingTheValueOfJiraFeaturesCohort)({
            shouldFire: false,
            productKey: productKey,
            edition: edition,
            inGracePeriod: inGracePeriod
        });
        const jstoTarget = !!isBillingAdmin && !inGracePeriod && isSiteAdmin && isJswStandard;
        const rawJstoUrl = `${(0, (0, parcelRequire("4du81")).WAC_URL)[environment]}/software/jira?ref=edition_awareness_standard_trial_${billingSourceSystem.toLowerCase()}&cloudSiteId=${cloudId}`;
        const jstoUrl = (0, (0, parcelRequire("dRVix")).useLoggedInPage)({
            pageUrl: rawJstoUrl
        });
        const addBillingDetailsUrl = entitlementDetails?.billingSourceSystem === 'CCP' ? `${(0, (0, parcelRequire("5Q4f1")).getAdminBaseUrl)()}/billing/${transactionAccountId}/${invoiceGroupId}/payment-flow?referrer=${(0, (0, parcelRequire("eb7dL")).productAbbreviations)[productKey]}` : `${(0, (0, parcelRequire("5Q4f1")).getAdminBaseUrl)()}/s/${cloudId}/billing/paymentdetails/add`;
        const [trialCountdownExperimentConfig, fireTrialCountdownExperimentExposure] = (0, (0, parcelRequire("6s1PB")).UNSAFE_noExposureExp)('jira_edition_awareness_trial_countdown');
        const trialCountdownExperimentValue = trialCountdownExperimentConfig.get('isEnabled', undefined);
        const isEligibleForTrialCountdownExperiment = (0, (0, parcelRequire("4R6GH")).fg)('jira_edition_awareness_trial_countdown_gate') && !inGracePeriod && !!trialDaysLeft && !!trialLengthDays && billingSourceSystem === 'CCP';
        const trialCountdownExperimentEnabled = isEligibleForTrialCountdownExperiment && trialCountdownExperimentValue;
        const trialCountdownExperimentAnalyticsAttributes = (0, $5uXOq.useMemo)(()=>isEligibleForTrialCountdownExperiment ? {
                trialCountdownExperimentEnabled: trialCountdownExperimentEnabled,
                trialLengthDays: trialLengthDays,
                trialDaysLeft: trialDaysLeft
            } : {}, [
            isEligibleForTrialCountdownExperiment,
            trialCountdownExperimentEnabled,
            trialLengthDays,
            trialDaysLeft
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (isEligibleForTrialCountdownExperiment) fireTrialCountdownExperimentExposure();
        }, [
            fireTrialCountdownExperimentExposure,
            isEligibleForTrialCountdownExperiment
        ]);
        const { isEligibleForJsmPremiumPillExperiment: isEligibleForJsmPremiumPillExperiment } = (0, (0, parcelRequire("h1xoN")).useJsmPremiumPillExperiment)({
            edition: edition
        });
        const analyticsAttributes = (0, $5uXOq.useMemo)(()=>{
            const attributes = {
                edition: edition,
                productKey: productKey,
                projectType: projectType,
                isEnglishLocale: isEnglishLocale,
                entitlementDetails: entitlementDetails,
                transactionAccountId: transactionAccountId,
                invoiceGroupId: invoiceGroupId,
                isBillingAdmin: isBillingAdmin
            };
            if (premiumFeaturesCohort !== (0, (0, parcelRequire("87Yl2")).PREMIUM_FEATURES_COHORT_NOT_ENROLLED)) attributes.premiumFeaturesCohort = premiumFeaturesCohort;
            return attributes;
        }, [
            edition,
            entitlementDetails,
            invoiceGroupId,
            isEnglishLocale,
            productKey,
            projectType,
            transactionAccountId,
            isBillingAdmin,
            premiumFeaturesCohort
        ]);
        const shouldLinkToJstoPage = !shouldShowJsmJpdTrialAwarenessModalV2;
        const getUrl = ()=>{
            if (shouldLinkToJstoPage && jstoTarget) return atlOrigin.addToUrl(jstoUrl);
            if (inGracePeriod) return atlOrigin.addToUrl(addBillingDetailsUrl);
            return undefined;
        };
        const url = getUrl();
        const shouldShowLinkButton = url !== undefined;
        const shouldParticipateInPaymentMethodEmbedExperiment = entitlementDetails?.billingSourceSystem === 'CCP' && !jstoTarget;
        const [config, fireCommercePaymentMethodEmbedExposure] = (0, (0, parcelRequire("6s1PB")).UNSAFE_noExposureExp)('commerce_experience_payment_method_embed');
        const commerceExperiencePaymentMethodEmbedCohort = config.get('cohort', 'not-enrolled');
        const isVariantInCommercePaymentMethodEmbed = shouldParticipateInPaymentMethodEmbedExperiment && commerceExperiencePaymentMethodEmbedCohort === 'variant';
        const [_, commerceExperienceDrawerActions] = (0, (0, parcelRequire("ayv8Y")).useCommerceExperienceDrawer)();
        const onClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("87Yl2")).firePremiumFeaturesExperimentExposure)({
                productKey: productKey,
                edition: edition,
                inGracePeriod: inGracePeriod,
                createAnalyticsEvent: createAnalyticsEvent
            });
            const target = jstoTarget ? 'JSTO' : 'BillingScreen';
            if (shouldParticipateInPaymentMethodEmbedExperiment) {
                fireCommercePaymentMethodEmbedExposure();
                if (isVariantInCommercePaymentMethodEmbed) commerceExperienceDrawerActions.openDrawer(url || '');
            }
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'button'
            }), 'editionAwarenessTrialPill', {
                ...analyticsAttributes,
                ...jstoTarget || inGracePeriod ? {
                    target: target,
                    ...atlOrigin.toAnalyticsAttributes({
                        hasGeneratedId: true
                    })
                } : {},
                ...shouldShowJsmJpdTrialAwarenessModalV2 ? {
                    jsmJpdTrialAwarenessModalV2: {
                        shouldShowJsmJpdTrialAwarenessModalV2: shouldShowJsmJpdTrialAwarenessModalV2
                    }
                } : {},
                shouldParticipateInPaymentMethodEmbedExperiment: shouldParticipateInPaymentMethodEmbedExperiment,
                ...shouldParticipateInPaymentMethodEmbedExperiment ? {
                    commerceExperiencePaymentMethodEmbedCohort: commerceExperiencePaymentMethodEmbedCohort
                } : {},
                ...trialCountdownExperimentAnalyticsAttributes
            });
        }, [
            jstoTarget,
            createAnalyticsEvent,
            analyticsAttributes,
            inGracePeriod,
            productKey,
            edition,
            atlOrigin,
            shouldShowJsmJpdTrialAwarenessModalV2,
            shouldParticipateInPaymentMethodEmbedExperiment,
            commerceExperiencePaymentMethodEmbedCohort,
            trialCountdownExperimentAnalyticsAttributes,
            commerceExperienceDrawerActions,
            fireCommercePaymentMethodEmbedExposure,
            isVariantInCommercePaymentMethodEmbed,
            url
        ]);
        (0, $5uXOq.useEffect)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'viewed',
                actionSubject: 'button'
            }), 'editionAwarenessTrialPill', {
                ...analyticsAttributes,
                isSiteAdmin: isSiteAdmin,
                isBillingAdmin: isBillingAdmin,
                ...trialCountdownExperimentAnalyticsAttributes
            });
        }, [
            analyticsAttributes,
            createAnalyticsEvent,
            isBillingAdmin,
            isSiteAdmin,
            trialCountdownExperimentAnalyticsAttributes
        ]);
        const trialModalEntryPointParams = (0, $5uXOq.useMemo)(()=>({
                cloudId: cloudId,
                productKey: productKey,
                jiraProjectType: (0, (0, parcelRequire("2vSU6")).convertToJiraProjectType)(projectType),
                jiraApplicationKey: (0, (0, parcelRequire("efvDm")).getApplicationKeyForProject)(projectType)
            }), [
            cloudId,
            productKey,
            projectType
        ]);
        const trialModalEntryPointProps = (0, $5uXOq.useMemo)(()=>({
                projectType: projectType,
                edition: edition
            }), [
            projectType,
            edition
        ]);
        const shouldShowWarningStatus = trialCountdownExperimentEnabled && trialDaysLeft && trialDaysLeft <= 3;
        const buttonStatus = shouldShowWarningStatus ? 'warning' : 'default';
        if (premiumFeaturesCohort === (0, (0, parcelRequire("87Yl2")).PREMIUM_FEATURES_COHORT_VARIANT) || isEligibleForJsmPremiumPillExperiment) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8qT8y")).Dropdown), {
            applicationEdition: isEligibleForJsmPremiumPillExperiment ? (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION) : (0, (0, parcelRequire("87Yl2")).getJiraFeaturesDropdownSupportedEdition)(edition, productKey),
            productKey: productKey,
            status: buttonStatus,
            analyticsAttributes: {
                ...analyticsAttributes,
                ...trialCountdownExperimentAnalyticsAttributes
            },
            trialEndTime: trialEndTime,
            isInvoiceable: isInvoiceable
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($59e268577d5e3e0c$var$TrialButtonText, {
            inGracePeriod: inGracePeriod,
            trialDaysLeft: trialDaysLeft,
            edition: edition,
            trialCountdownExperimentEnabled: trialCountdownExperimentEnabled,
            trialLengthDays: trialLengthDays
        }));
        if (shouldShowLinkButton) {
            if (isVariantInCommercePaymentMethodEmbed) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
                content: (0, ($parcel$interopDefault($5uXOq))).createElement($59e268577d5e3e0c$var$TooltipText, {
                    showAddPaymentDetails: inGracePeriod
                }),
                position: "bottom"
            }, (tooltipProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dvyoy")).EditionAwarenessButton), {
                    ...tooltipProps,
                    onClick: onClick,
                    status: buttonStatus,
                    ...inGracePeriod ? {
                        icon: 'missing-payment-details'
                    } : {
                        upgradeIconType: 'gem'
                    },
                    testId: "navigation-apps-sidebar-edition-awareness.ui.trial-pill.trial-pill-button.variant-in-payment-method-embed"
                }, (0, ($parcel$interopDefault($5uXOq))).createElement($59e268577d5e3e0c$var$TrialButtonText, {
                    inGracePeriod: inGracePeriod,
                    trialDaysLeft: trialDaysLeft,
                    edition: edition,
                    trialCountdownExperimentEnabled: trialCountdownExperimentEnabled,
                    trialLengthDays: trialLengthDays
                })));
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
                content: (0, ($parcel$interopDefault($5uXOq))).createElement($59e268577d5e3e0c$var$TooltipText, {
                    showAddPaymentDetails: inGracePeriod
                }),
                position: "bottom"
            }, (tooltipProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dvyoy")).EditionAwarenessLinkButton), {
                    ...tooltipProps,
                    onClick: onClick,
                    href: url,
                    ref: tooltipProps.ref,
                    status: buttonStatus,
                    ...inGracePeriod ? {
                        icon: 'missing-payment-details'
                    } : {
                        upgradeIconType: 'gem'
                    },
                    target: "_blank",
                    testId: "navigation-apps-sidebar-edition-awareness.ui.trial-pill.trial-pill-button.edition-awareness-trial-pill"
                }, (0, ($parcel$interopDefault($5uXOq))).createElement($59e268577d5e3e0c$var$TrialButtonText, {
                    inGracePeriod: inGracePeriod,
                    trialDaysLeft: trialDaysLeft,
                    edition: edition,
                    trialCountdownExperimentEnabled: trialCountdownExperimentEnabled,
                    trialLengthDays: trialLengthDays
                })));
        }
        if (shouldShowJsmJpdTrialAwarenessModalV2) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2KAtx")).ModalEntryPointPressableTrigger), {
            entryPoint: (0, (0, parcelRequire("aIlMT")).editionAwarenessTrialModalEntryPoint),
            entryPointParams: trialModalEntryPointParams,
            entryPointProps: trialModalEntryPointProps,
            interactionName: "edition-awareness-trial-modal",
            useInternalModal: false
        }, (modalProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dvyoy")).EditionAwarenessButton), {
                onClick: onClick,
                ref: modalProps.ref,
                status: buttonStatus,
                upgradeIconType: "gem",
                testId: "navigation-apps-sidebar-edition-awareness.ui.trial-pill.trial-pill-button.edition-awareness-trial-pill"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement($59e268577d5e3e0c$var$TrialButtonText, {
                inGracePeriod: inGracePeriod,
                trialDaysLeft: trialDaysLeft,
                edition: edition,
                trialCountdownExperimentEnabled: trialCountdownExperimentEnabled,
                trialLengthDays: trialLengthDays
            })));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1fNl5")).BillingDetailsPopup), {
            analyticsAttributes: analyticsAttributes,
            edition: edition,
            inGracePeriod: inGracePeriod,
            addBillingDetailsUrl: addBillingDetailsUrl,
            productKey: productKey
        }, (triggerProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dvyoy")).EditionAwarenessButton), {
                ...triggerProps.triggerProps,
                onClick: (...args)=>{
                    onClick();
                    triggerProps.onClick && triggerProps.onClick(...args);
                },
                status: buttonStatus,
                upgradeIconType: "gem",
                testId: "navigation-apps-sidebar-edition-awareness.ui.trial-pill.trial-pill-button.edition-awareness-trial-pill"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement($59e268577d5e3e0c$var$TrialButtonText, {
                inGracePeriod: inGracePeriod,
                trialDaysLeft: trialDaysLeft,
                edition: edition,
                trialCountdownExperimentEnabled: trialCountdownExperimentEnabled,
                trialLengthDays: trialLengthDays
            })));
    };
    const $59e268577d5e3e0c$export$19063b5b00c8046c = (props)=>{
        const isSiteAdmin = (0, (0, parcelRequire("cNd4s")).useIsSiteAdmin)();
        const { isBillingAdmin: isBillingAdmin } = props;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            attributes: {
                isSiteAdmin: isSiteAdmin,
                isBillingAdmin: isBillingAdmin
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Fk4Z")).default), {
            name: "edition-awareness-trial-pill"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($59e268577d5e3e0c$var$TrialPillButtonInner, props)));
    };
});
parcelRegister("4du81", function(module, exports) {
    $parcel$export(module.exports, "WAC_URL", ()=>$fc5bec1ad49de5dc$export$5e8c8041aa206ba5);
    const $fc5bec1ad49de5dc$export$5e8c8041aa206ba5 = {
        prod: 'https://www.atlassian.com',
        dev: 'https://wac.stg.internal.atlassian.com',
        local: 'https://wac.stg.internal.atlassian.com',
        staging: 'https://wac.stg.internal.atlassian.com'
    };
});
parcelRegister("5Q4f1", function(module, exports) {
    $parcel$export(module.exports, "getAdminBaseUrl", ()=>$2c9346932f4dad13$export$76bb37a926058cca);
    var $kTK0a;
    const $2c9346932f4dad13$export$76bb37a926058cca = ()=>`https://${(0, (0, parcelRequire("kTK0a")).getATLContextDomain)('admin')}`;
});
parcelRegister("aIlMT", function(module, exports) {
    $parcel$export(module.exports, "editionAwarenessTrialModalEntryPoint", ()=>$c80dd7db540444a3$export$de2f955c8fd39788);
    var $6DUTU;
    var $eufEc;
    var $fEDBv;
    const $c80dd7db540444a3$export$de2f955c8fd39788 = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("grC8Q")), {
            moduleId: "3928dd3c"
        }),
        getPreloadProps: ({ cloudId: cloudId, productKey: productKey, jiraProjectType: jiraProjectType, jiraApplicationKey: jiraApplicationKey })=>({
                extraProps: {
                    productKey: productKey
                },
                entryPoints: {},
                queries: {
                    editionAwarenessTrialModalQueryRef: {
                        parameters: (0, (0, parcelRequire("6DUTU")).default),
                        variables: {
                            cloudId: cloudId,
                            hamsProductKey: productKey,
                            projectType: jiraProjectType,
                            jiraApplicationKey: jiraApplicationKey
                        }
                    }
                }
            })
    });
});
parcelRegister("6DUTU", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$935ec38654561fdb$export$2e2bcd8739ae039);
    const $935ec38654561fdb$var$node = {
        "kind": "PreloadableConcreteRequest",
        "params": {
            "id": "6546241374c7e5f338745b6f42592108f946e14ec69934c2a80661773984a24e",
            "metadata": {},
            "name": "main_EditionAwarenessTrialModalQuery",
            "operationKind": "query",
            "text": null
        }
    };
    var $935ec38654561fdb$export$2e2bcd8739ae039 = $935ec38654561fdb$var$node;
});
parcelRegister("grC8Q", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("iTtmS")
    ]).then(()=>parcelRequire('knOvt'));
});
parcelRegister("cIObI", function(module, exports) {
    $parcel$export(module.exports, "fireButtonAnalytics", ()=>$cbdf4524f0c3a34f$export$ac665b9f9662b94a);
    $parcel$export(module.exports, "isSupportedProjectType", ()=>$cbdf4524f0c3a34f$export$fa630a47d84fb39a);
    $parcel$export(module.exports, "isEligibleForJsmJpdTrialAwarenessV2", ()=>$cbdf4524f0c3a34f$export$e4cf86ec01cea940);
    $parcel$export(module.exports, "projectTypeToApplicationKey", ()=>$cbdf4524f0c3a34f$export$83de85c996456cf6);
    $parcel$export(module.exports, "getApplicationName", ()=>$cbdf4524f0c3a34f$export$e248db7c9a3cd59);
    $parcel$export(module.exports, "getProductReferrerKey", ()=>$cbdf4524f0c3a34f$export$b4fb3304d5d8fc5f);
    var $2vSU6;
    var $8zOmE;
    const $cbdf4524f0c3a34f$export$37f449592e04a567 = [
        (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT),
        (0, (0, parcelRequire("2vSU6")).PRODUCT_DISCOVERY_PROJECT)
    ];
    const $cbdf4524f0c3a34f$export$ac665b9f9662b94a = (createAnalyticsEvent, actionSubjectId, attributes)=>{
        (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
            action: 'clicked',
            actionSubject: 'button'
        }), actionSubjectId, {
            ...attributes
        });
    };
    const $cbdf4524f0c3a34f$export$fa630a47d84fb39a = (value)=>{
        const projectType = (0, (0, parcelRequire("2vSU6")).toProjectType)(value);
        return !!projectType && $cbdf4524f0c3a34f$export$37f449592e04a567.includes(projectType);
    };
    const $cbdf4524f0c3a34f$export$e4cf86ec01cea940 = (projectType)=>(0, (0, parcelRequire("2vSU6")).toProjectType)(projectType) === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT) || (0, (0, parcelRequire("2vSU6")).toProjectType)(projectType) === (0, (0, parcelRequire("2vSU6")).PRODUCT_DISCOVERY_PROJECT);
    const $cbdf4524f0c3a34f$export$83de85c996456cf6 = (projectType)=>{
        switch(projectType){
            case 0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT:
                return 0, $c06e1abca7c13910$export$1346c9aa3cbb477d;
            case 0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT:
                return 0, $c06e1abca7c13910$export$c8c1c8c3f8ddba61;
            case 0, (0, parcelRequire("2vSU6")).CORE_PROJECT:
                return 0, $c06e1abca7c13910$export$db515c8cec1e6f79;
            case 0, (0, parcelRequire("2vSU6")).PRODUCT_DISCOVERY_PROJECT:
                return 0, $c06e1abca7c13910$export$202f50ee62667e32;
            default:
                return 0, $c06e1abca7c13910$export$db515c8cec1e6f79;
        }
    };
    const $cbdf4524f0c3a34f$var$JIRA_SERVICE_MANAGEMENT_APP_NAME = 'Jira Service Management';
    const $cbdf4524f0c3a34f$var$JIRA_PRODUCT_DISCOVERY_APP_NAME = 'Jira Product Discovery';
    const $cbdf4524f0c3a34f$export$e248db7c9a3cd59 = (projectType)=>{
        switch(projectType){
            case 0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT:
                return 'Jira';
            case 0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT:
                return $cbdf4524f0c3a34f$var$JIRA_SERVICE_MANAGEMENT_APP_NAME;
            case 0, (0, parcelRequire("2vSU6")).PRODUCT_DISCOVERY_PROJECT:
                return $cbdf4524f0c3a34f$var$JIRA_PRODUCT_DISCOVERY_APP_NAME;
            default:
                throw new Error(`Unknown project type: ${projectType}`);
        }
    };
    const $cbdf4524f0c3a34f$export$b4fb3304d5d8fc5f = (projectType)=>{
        if (projectType === (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT)) return 'jsw';
        if (projectType === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT)) return 'jsm';
        if (projectType === (0, (0, parcelRequire("2vSU6")).PRODUCT_DISCOVERY_PROJECT)) return 'jpd';
        if (projectType === (0, (0, parcelRequire("2vSU6")).CORE_PROJECT)) return 'jwm';
        return null;
    };
});
parcelRegister("h1xoN", function(module, exports) {
    $parcel$export(module.exports, "useEligibilityCheckEvent", ()=>$2b432038ec40b196$export$a49d52669a91865e);
    $parcel$export(module.exports, "useJsmPremiumPillExperiment", ()=>$2b432038ec40b196$export$b59e3fcc3b941876);
    $parcel$export(module.exports, "getIneligibilityReasons", ()=>$2b432038ec40b196$export$3a5909afc297b5c6);
    $parcel$export(module.exports, "transformError", ()=>$2b432038ec40b196$export$78c6267359db7e73);
    var $5uXOq = parcelRequire("5uXOq");
    var $2vSU6;
    var $65Ltd;
    var $6s1PB;
    var $4R6GH;
    var $6jY6G;
    var $8zOmE;
    var $2NMNM;
    var $dAfqg;
    var $ldyPw;
    var $cNd4s;
    const $2b432038ec40b196$export$a49d52669a91865e = ({ productKey: productKey, edition: edition, entitlementDetails: entitlementDetails, projectType: projectType })=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const isSiteAdmin = (0, (0, parcelRequire("cNd4s")).useIsSiteAdmin)();
        return (0, $5uXOq.useCallback)((ineligibilityReason, attributes = {})=>{
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'eligibilityCriteria checked', 'editionAwarenessTrialPill', {
                ineligibilityReason: ineligibilityReason,
                isSiteAdmin: isSiteAdmin,
                productKey: productKey,
                projectType: projectType,
                edition: edition,
                entitlementDetails: entitlementDetails,
                ...attributes
            });
        }, [
            createAnalyticsEvent,
            edition,
            entitlementDetails,
            isSiteAdmin,
            productKey,
            projectType
        ]);
    };
    const $2b432038ec40b196$export$b59e3fcc3b941876 = ({ edition: edition })=>{
        const route = (0, (0, parcelRequire("6jY6G")).useCurrentRoute)();
        const isOnCMDBPage = $2b432038ec40b196$export$acdcb04b84a589b3(route.name);
        const { data: project } = (0, (0, parcelRequire("dAfqg")).useProjectContext)();
        const isJSM = project?.projectType === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT);
        const isTMP = !!project?.simplified;
        const isProjectAdmin = !!project?.isProjectAdmin;
        const isSiteAdmin = (0, (0, parcelRequire("cNd4s")).useIsSiteAdmin)();
        if (isOnCMDBPage) return {
            isEligibleForJsmPremiumPillExperiment: edition === (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION) && isSiteAdmin && (0, (0, parcelRequire("6s1PB")).expValEquals)('jsm_premium_trial_pill_experiment', 'cohort', 'variation')
        };
        return {
            isEligibleForJsmPremiumPillExperiment: isJSM && edition === (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION) && !isTMP && (isProjectAdmin || isSiteAdmin) && (0, (0, parcelRequire("6s1PB")).expValEquals)('jsm_premium_trial_pill_experiment', 'cohort', 'variation')
        };
    };
    const $2b432038ec40b196$export$3a5909afc297b5c6 = (billingInformation)=>{
        const ineligibilityReasons = [];
        if (billingInformation.rawResponse) {
            if (billingInformation.rawResponse?.tenantContexts == null) {
                ineligibilityReasons.push('missingTenantContexts');
                return ineligibilityReasons;
            }
            if (billingInformation.rawResponse?.tenantContexts[0] == null) {
                ineligibilityReasons.push('missingTenantContext');
                return ineligibilityReasons;
            }
            if (billingInformation.rawResponse?.tenantContexts[0]?.entitlementInfo == null) {
                ineligibilityReasons.push('missingEntitlementInfo');
                return ineligibilityReasons;
            }
        }
        if (billingInformation.notInTrialOrPredunning) {
            ineligibilityReasons.push('notInTrialOrPredunning');
            return ineligibilityReasons;
        }
        if (billingInformation.isBillingAdmin !== true) {
            if (billingInformation.isBillingAdmin === false) ineligibilityReasons.push('notBillingAdmin');
            else ineligibilityReasons.push('billingAdminIsNull');
        }
        if (billingInformation.invoiceable !== false) {
            if (billingInformation.invoiceable === null) ineligibilityReasons.push('invoiceableIsNull');
            else ineligibilityReasons.push('invoiceable');
        }
        if (!(0, (0, parcelRequire("4R6GH")).fg)('trial_edition_awareness_extra_eligibility_check')) return ineligibilityReasons;
        if (billingInformation.partnerManaged === true) ineligibilityReasons.push('partnerManaged');
        if (billingInformation?.billingInterval === 'YEAR') ineligibilityReasons.push('annualBillingCycle');
        if (billingInformation.pricingPlanType && ![
            'COMMERCIAL',
            'COMMUNITY'
        ].includes(billingInformation.pricingPlanType)) ineligibilityReasons.push(`pricingType:${billingInformation.pricingPlanType}`);
        if ([
            'enterprise',
            'atlassian-together'
        ].includes(billingInformation.overriddenEdition || '')) ineligibilityReasons.push(`overriddenEdition:${billingInformation.overriddenEdition}`);
        return ineligibilityReasons;
    };
    const $2b432038ec40b196$export$78c6267359db7e73 = (error)=>{
        const errorObject = {};
        Object.getOwnPropertyNames(error).filter((name)=>name !== 'stack').forEach((name)=>{
            if (name === 'cause') errorObject[name] = error[name]?.message;
            else errorObject[name] = error[name];
        });
        return errorObject;
    };
    const $2b432038ec40b196$export$acdcb04b84a589b3 = (routeName)=>{
        return [
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_CONFIGURE),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA_CONFIGURATION),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_TYPE_CONFIGURATION),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_DEFAULT_REDIRECT),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_UNSUBSCRIBE),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_HOME),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_CONFIGURE),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_LANDING_PAGE),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA_CONFIGURATION),
            (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_TYPE_CONFIGURATION)
        ].includes(routeName);
    };
});
parcelRegister("1fNl5", function(module, exports) {
    $parcel$export(module.exports, "BillingDetailsPopup", ()=>$662b99a3c507e81e$export$9ba9c2a8da5fd7c5);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $2gQ83;
    var $fVnbM;
    var $1I7E8;
    var $dC5iT;
    var $8CUq3;
    var $39xOx;
    var $4R6GH;
    var $eaVnq;
    var $koVbs;
    var $iXVV6;
    var $5oFIy;
    var $8zOmE;
    var $2NMNM;
    var $70Wxp;
    var $e2N0p;
    var $eydIU;
    var $ldyPw;
    var $87Yl2;
    var $61Rd1;
    const $662b99a3c507e81e$var$ReverseTrialExperimentErrorBoundary = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: (0, $af6ccefcb1f83341$export$f19f2573d2fc7010),
            packageName: (0, $af6ccefcb1f83341$export$b8b9d90f9e5bd72b),
            fallback: "unmount",
            teamName: "growth-tako"
        }, children);
    const $662b99a3c507e81e$var$PopupInner = ({ url: url, defaultPopupInner: defaultPopupInner, onFireUiAnalyticsEvent: onFireUiAnalyticsEvent })=>{
        const { premiumTrialOnSignup: premiumTrialOnSignup, isReady: isReady } = (0, (0, parcelRequire("70Wxp")).useFetchOnboardingReverseTrial)();
        if (!isReady) return null;
        if (isReady && premiumTrialOnSignup) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eydIU")).ReverseTrialExperimentPopupContent), {
            billingPageUrl: url,
            onFireUiAnalyticsEvent: onFireUiAnalyticsEvent
        });
        return defaultPopupInner;
    };
    const $662b99a3c507e81e$var$ReverseTrialExperimentForceOpen = ({ onPopupToggle: onPopupToggle })=>{
        const { premiumTrialOnSignup: premiumTrialOnSignup } = (0, (0, parcelRequire("70Wxp")).useFetchOnboardingReverseTrial)();
        (0, (0, parcelRequire("e2N0p")).useForceOpenPremiumPopupOnDay3)({
            shouldRun: premiumTrialOnSignup,
            onPopupToggle: onPopupToggle
        });
        return null;
    };
    const $662b99a3c507e81e$var$PopupBackground = ({ defaultBackground: defaultBackground })=>{
        const { premiumTrialOnSignup: premiumTrialOnSignup, isReady: isReady } = (0, (0, parcelRequire("70Wxp")).useFetchOnboardingReverseTrial)();
        if (!isReady || premiumTrialOnSignup) return null;
        return defaultBackground;
    };
    const $662b99a3c507e81e$export$9ba9c2a8da5fd7c5 = (props)=>{
        const { initialOpenState: initialOpenState = false, edition: edition, addBillingDetailsUrl: addBillingDetailsUrl, analyticsAttributes: analyticsAttributes, inGracePeriod: inGracePeriod, productKey: productKey, children: children } = props;
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [isOpen, setIsOpen] = (0, $5uXOq.useState)(initialOpenState);
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { premiumTrialOnSignup: premiumTrialOnSignup, isReady: isReady } = (0, (0, parcelRequire("70Wxp")).useFetchOnboardingReverseTrial)();
        const popupTitle = formatMessage(edition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION) ? (0, (0, parcelRequire("61Rd1")).default).addPaymentDetailsPopupStandardTitle : (0, (0, parcelRequire("61Rd1")).default).addPaymentDetailsPopupPremiumTitle);
        const popupContent = formatMessage(edition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION) ? (0, (0, parcelRequire("61Rd1")).default).addPaymentDetailsPopupStandardContent : (0, (0, parcelRequire("61Rd1")).default).addPaymentDetailsPopupPremiumContent);
        const atlOrigin = (0, $5uXOq.useMemo)(()=>new (0, (0, parcelRequire("2gQ83")).default)({
                product: 'jira'
            }), []);
        const url = atlOrigin.addToUrl(addBillingDetailsUrl);
        const fireUIAnalyticsEvent = (0, $5uXOq.useCallback)((action, actionSubject, actionSubjectId, attributes = {})=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: action,
                actionSubject: actionSubject
            }), actionSubjectId, {
                ...analyticsAttributes,
                ...attributes
            });
        }, [
            analyticsAttributes,
            createAnalyticsEvent
        ]);
        const onClickButton = (0, $5uXOq.useCallback)(()=>{
            fireUIAnalyticsEvent('clicked', 'button', 'paymentDetailsPopupItem', {
                ...atlOrigin.toAnalyticsAttributes({
                    hasGeneratedId: true
                })
            });
            setIsOpen(false);
        }, [
            atlOrigin,
            fireUIAnalyticsEvent
        ]);
        const onPopupToggle = (0, $5uXOq.useCallback)((show, autoOpened)=>{
            const { cohort: premiumFeaturesCohort } = (0, (0, parcelRequire("87Yl2")).firePremiumFeaturesExperimentExposure)({
                productKey: productKey,
                edition: edition,
                inGracePeriod: inGracePeriod,
                createAnalyticsEvent: createAnalyticsEvent
            });
            fireUIAnalyticsEvent('toggled', 'popup', 'editionAwarenessTrialPillPopup', {
                show: show,
                ...premiumFeaturesCohort !== (0, (0, parcelRequire("87Yl2")).PREMIUM_FEATURES_COHORT_NOT_ENROLLED) && {
                    premiumFeaturesCohort: premiumFeaturesCohort
                },
                ...autoOpened !== undefined && {
                    autoOpened: autoOpened
                }
            });
            setIsOpen(show);
        }, [
            createAnalyticsEvent,
            edition,
            fireUIAnalyticsEvent,
            inGracePeriod,
            productKey
        ]);
        const onTriggerClick = (0, $5uXOq.useCallback)(()=>{
            onPopupToggle(!isOpen);
        }, [
            onPopupToggle,
            isOpen
        ]);
        const getPopupPlacement = ()=>{
            if (isReady && premiumTrialOnSignup) return 'bottom-end';
            return 'bottom-start';
        };
        const defaultPopupInner = (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "h3",
            xcss: $662b99a3c507e81e$var$popupHeadingStyles
        }, popupTitle), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "p",
            xcss: $662b99a3c507e81e$var$popupContentStyles
        }, popupContent), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            appearance: "primary",
            href: url,
            target: "_blank",
            onClick: onClickButton
        }, formatMessage((0, (0, parcelRequire("61Rd1")).default).addPaymentDetailsPopupButton)));
        const defaultBackground = (0, ($parcel$interopDefault($5uXOq))).createElement($662b99a3c507e81e$var$Background, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eaVnq")).default), {
            alt: "premium-trial-banner"
        }));
        const adjustAnalyticsAttributes = ()=>{
            if (isReady && premiumTrialOnSignup !== undefined) return {
                ...analyticsAttributes,
                isPremiumTrialOnSignup: premiumTrialOnSignup
            };
            return analyticsAttributes;
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, (0, parcelRequire("4R6GH")).fg)('jsw_reverse_trials_feature_gate') && (0, ($parcel$interopDefault($5uXOq))).createElement($662b99a3c507e81e$var$ReverseTrialExperimentErrorBoundary, null, (0, ($parcel$interopDefault($5uXOq))).createElement($662b99a3c507e81e$var$ReverseTrialExperimentForceOpen, {
            onPopupToggle: onPopupToggle
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1I7E8")).default), {
            isOpen: isOpen,
            onClose: ()=>onPopupToggle(false),
            content: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
                    sourceName: "trialPillPopup",
                    sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a)
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), {
                    attributes: adjustAnalyticsAttributes()
                }), (0, (0, parcelRequire("4R6GH")).fg)('jsw_reverse_trials_feature_gate') ? (0, ($parcel$interopDefault($5uXOq))).createElement($662b99a3c507e81e$var$ReverseTrialExperimentErrorBoundary, null, (0, ($parcel$interopDefault($5uXOq))).createElement($662b99a3c507e81e$var$PopupBackground, {
                    defaultBackground: defaultBackground
                })) : defaultBackground, (0, ($parcel$interopDefault($5uXOq))).createElement($662b99a3c507e81e$var$PopupWrapper, null, (0, (0, parcelRequire("4R6GH")).fg)('jsw_reverse_trials_feature_gate') ? (0, ($parcel$interopDefault($5uXOq))).createElement($662b99a3c507e81e$var$ReverseTrialExperimentErrorBoundary, null, (0, ($parcel$interopDefault($5uXOq))).createElement($662b99a3c507e81e$var$PopupInner, {
                    url: url,
                    defaultPopupInner: defaultPopupInner,
                    onFireUiAnalyticsEvent: fireUIAnalyticsEvent
                })) : defaultPopupInner)),
            placement: getPopupPlacement(),
            trigger: (triggerProps)=>children({
                    triggerProps: triggerProps,
                    onClick: onTriggerClick
                })
        }));
    };
    const $662b99a3c507e81e$var$PopupWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1bsb1ogy _1yt4v47k",
                __cmplp.className
            ])
        });
    });
    const $662b99a3c507e81e$var$Background = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1bah1h6o _11q71osb",
                __cmplp.className
            ])
        });
    });
    const $662b99a3c507e81e$var$popupHeadingStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        color: 'color.text',
        font: 'font.heading.medium',
        fontWeight: '700'
    });
    const $662b99a3c507e81e$var$popupContentStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginTop: 'space.100',
        marginBottom: 'space.150',
        color: 'color.text.subtle',
        font: 'font.body.small',
        lineHeight: '14px'
    });
});
parcelRegister("eaVnq", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f5b1a8d0d5980476$export$2e2bcd8739ae039);
    var $dCLgq;
    var $10uhO = parcelRequire("10uhO");
    const $f5b1a8d0d5980476$export$abdf1e325e38b834 = (0, ($parcel$interopDefault($10uhO)));
    const $f5b1a8d0d5980476$export$87ec2877821a8f12 = (0, ($parcel$interopDefault($10uhO)));
    const $f5b1a8d0d5980476$var$Illustration = (0, (0, parcelRequire("dCLgq")).createIllustration)({
        srcLight: $f5b1a8d0d5980476$export$abdf1e325e38b834,
        srcDark: $f5b1a8d0d5980476$export$87ec2877821a8f12,
        autoDark: false
    });
    var $f5b1a8d0d5980476$export$2e2bcd8739ae039 = $f5b1a8d0d5980476$var$Illustration;
    const $f5b1a8d0d5980476$export$a179c88182cb9d56 = [
        0,
        0,
        241,
        107
    ];
});
parcelRegister("10uhO", function(module, exports) {
    module.exports = new URL("premium-trial.bdfe4386.svg", import.meta.url).toString();
});
const $4d5e0871c06cee03$export$f636b5b56f1d624a = 'Screen';
const $4d5e0871c06cee03$export$9d2de6eebf7462b5 = 'Drawer';
const $4d5e0871c06cee03$export$1171103ea9083ffa = 'Modal';
const $4d5e0871c06cee03$export$cae2783c9279926 = 'InlineDialog';
const $4d5e0871c06cee03$export$d1123261ca8831d2 = 'Dropdown';
const $4d5e0871c06cee03$export$238d30785c4bca84 = 'Banner';
const $4d5e0871c06cee03$export$a1562d08625f5d7e = 'UI';
const $4d5e0871c06cee03$export$947dac6c72b26f81 = 'TRACK';
const $4d5e0871c06cee03$export$f1d537d508d2945a = 'SCREEN';
const $4d5e0871c06cee03$export$12346a674644c156 = 'OPERATIONAL';
parcelRegister("70Wxp", function(module, exports) {
    $parcel$export(module.exports, "useFetchOnboardingReverseTrial", ()=>$5ec4a2aef6f6d0e2$export$349fbee95cd03b58);
    var $5uXOq = parcelRequire("5uXOq");
    var $AK64q;
    var $3sQ5x;
    var $iXqE5;
    var $gh567;
    var $7aHUB;
    const $5ec4a2aef6f6d0e2$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'jsw-onboarding-reverse-trial',
        initialState: (0, (0, parcelRequire("AK64q")).initialCachedEndpointState),
        actions: (0, parcelRequire("7aHUB")).actions
    });
    const $5ec4a2aef6f6d0e2$export$6758b294795e1644 = (0, (0, parcelRequire("3sQ5x")).createHook)($5ec4a2aef6f6d0e2$var$store);
    const $5ec4a2aef6f6d0e2$export$349fbee95cd03b58 = ()=>{
        const isTargetedForReverseTrials = (0, (0, parcelRequire("gh567")).useIsTargetedForReverseTrials)();
        const [{ fetchedOnce: fetchedOnce, isFetching: isFetching, data: data }, { getPremiumTrialOnSignup: getPremiumTrialOnSignup }] = $5ec4a2aef6f6d0e2$export$6758b294795e1644();
        (0, $5uXOq.useEffect)(()=>{
            if (isTargetedForReverseTrials && !fetchedOnce && !isFetching && data === undefined) getPremiumTrialOnSignup();
        }, [
            data,
            fetchedOnce,
            getPremiumTrialOnSignup,
            isFetching,
            isTargetedForReverseTrials
        ]);
        if (!isTargetedForReverseTrials) return {
            premiumTrialOnSignup: undefined,
            reverseTrialCohort: data?.reverseTrialCohort,
            isReady: true
        };
        return {
            premiumTrialOnSignup: data?.premiumTrialOnSignup,
            reverseTrialCohort: data?.reverseTrialCohort,
            isReady: fetchedOnce && !isFetching
        };
    };
});
parcelRegister("AK64q", function(module, exports) {
    $parcel$export(module.exports, "initialCachedEndpointState", ()=>$e18633c22e50182e$export$aaed2d023c5dc2c);
    const $e18633c22e50182e$export$aaed2d023c5dc2c = {
        isFetching: false,
        wasFetched: false,
        fetchedOnce: false,
        fetchError: null
    };
});
parcelRegister("gh567", function(module, exports) {
    $parcel$export(module.exports, "useIsTargetedForReverseTrials", ()=>$b8cd58920d764600$export$897219a3ca9649b9);
    var $2vSU6;
    var $4R6GH;
    var $dAfqg;
    var $ldyPw;
    var $k9JTq;
    const $b8cd58920d764600$export$897219a3ca9649b9 = ()=>{
        const { data: projectContextData } = (0, (0, parcelRequire("dAfqg")).useProjectContext)();
        const jswEdition = (0, (0, parcelRequire("k9JTq")).useAppEditions)().software;
        if ((0, (0, parcelRequire("4R6GH")).fg)('jsw_reverse_trials_feature_gate')) {
            const isCurrentProjectJSW = projectContextData?.projectType === (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT);
            if (jswEdition === (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION) && isCurrentProjectJSW) return true;
            return false;
        }
        return false;
    };
});
parcelRegister("7aHUB", function(module, exports) {
    $parcel$export(module.exports, "actions", ()=>$9bc9ce12e98e96a1$export$e324594224ef24da);
    var $7bRcT;
    var $bfWlw;
    const $9bc9ce12e98e96a1$var$getPremiumTrialOnSignup = (0, (0, parcelRequire("7bRcT")).cachedEndpointCall)((0, (0, parcelRequire("bfWlw")).getOnboardingPremiumTrialWithRethrow));
    const $9bc9ce12e98e96a1$export$e324594224ef24da = {
        getPremiumTrialOnSignup: $9bc9ce12e98e96a1$var$getPremiumTrialOnSignup
    };
});
parcelRegister("7bRcT", function(module, exports) {
    $parcel$export(module.exports, "cachedEndpointCall", ()=>$69bbbca5d7164078$export$9ab04ab45a9b99da);
    const $69bbbca5d7164078$export$9ab04ab45a9b99da = (call)=>(params)=>async ({ getState: getState, setState: setState })=>{
                const { isFetching: isFetching, wasFetched: wasFetched, fetchedOnce: fetchedOnce } = getState();
                if (isFetching || wasFetched) return;
                setState({
                    isFetching: true
                });
                try {
                    const data = await call(params);
                    if (data !== null) setState({
                        data: data,
                        wasFetched: true
                    });
                    else setState({
                        wasFetched: fetchedOnce
                    });
                } catch (error) {
                    setState({
                        fetchError: error
                    });
                } finally{
                    setState({
                        isFetching: false,
                        fetchedOnce: true
                    });
                }
            };
});
parcelRegister("bfWlw", function(module, exports) {
    $parcel$export(module.exports, "getOnboardingPremiumTrialWithRethrow", ()=>$a55f3307b43c0521$export$d9a9dd6206da5bef);
    $parcel$export(module.exports, "getOnboardingPremiumTrial", ()=>$a55f3307b43c0521$export$ef676f7f369f74fe);
    var $jcw0u;
    var $eusud;
    var $6c7t9;
    const $a55f3307b43c0521$var$endpoint = '/rest/onboarding/1.0/premium-trial';
    const $a55f3307b43c0521$export$d9a9dd6206da5bef = async ()=>{
        try {
            const localStorageOnboardingPremiumTrial = (0, (0, parcelRequire("6c7t9")).localStorageGetOnboardingPremiumTrial)();
            const localStorageOnboardingPremiumTrialCohort = (0, (0, parcelRequire("6c7t9")).localStorageGetOnboardingPremiumTrialCohort)();
            if (typeof localStorageOnboardingPremiumTrial === 'boolean' && typeof localStorageOnboardingPremiumTrialCohort === 'string') return {
                premiumTrialOnSignup: localStorageOnboardingPremiumTrial,
                reverseTrialCohort: localStorageOnboardingPremiumTrialCohort
            };
            const response = await (0, (0, parcelRequire("eusud")).performGetRequestWithRetry)($a55f3307b43c0521$var$endpoint);
            const premiumTrialOnSignup = response?.premiumTrialOnSignup;
            const reverseTrialCohort = response?.reverseTrialCohort;
            if (typeof premiumTrialOnSignup === 'boolean' && typeof reverseTrialCohort === 'string') {
                (0, (0, parcelRequire("6c7t9")).localStorageSetOnboardingPremiumTrial)(premiumTrialOnSignup);
                (0, (0, parcelRequire("6c7t9")).localStorageSetOnboardingPremiumTrialCohort)(reverseTrialCohort);
                return {
                    premiumTrialOnSignup: premiumTrialOnSignup,
                    reverseTrialCohort: reverseTrialCohort
                };
            }
            throw new Error('response?.premiumTrialOnSignup or response?.reverseTrialCohort is undefined');
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: 'getOnboardingPremiumTrialWithRethrow',
                    packageName: 'jiraReverseTrialUtils'
                },
                sendToPrivacyUnsafeSplunk: true
            });
            throw error;
        }
    };
    const $a55f3307b43c0521$export$ef676f7f369f74fe = async ()=>{
        try {
            return await $a55f3307b43c0521$export$d9a9dd6206da5bef();
        } catch (e) {}
    };
});
parcelRegister("6c7t9", function(module, exports) {
    $parcel$export(module.exports, "localStorageGetOnboardingPremiumTrial", ()=>$fab9df2877e9db77$export$782df5489e5cfd1e);
    $parcel$export(module.exports, "localStorageGetOnboardingPremiumTrialCohort", ()=>$fab9df2877e9db77$export$c3378895d06b5528);
    $parcel$export(module.exports, "localStorageSetOnboardingPremiumTrial", ()=>$fab9df2877e9db77$export$f83a7a49524e74ad);
    $parcel$export(module.exports, "localStorageSetOnboardingPremiumTrialCohort", ()=>$fab9df2877e9db77$export$f389f8ca168b4a3a);
    var $bSSG7;
    var $jcw0u;
    const $fab9df2877e9db77$export$3538ee3f5c6c8b5c = 'premiumTrialOnSignup';
    const $fab9df2877e9db77$export$2f93fdc3330233dc = 'reverseTrialCohort';
    const $fab9df2877e9db77$var$localStorage = (0, (0, parcelRequire("bSSG7")).createLocalStorageProvider)('jsw-onboarding-premium-reverse-trial');
    const $fab9df2877e9db77$export$782df5489e5cfd1e = ()=>{
        try {
            const value = $fab9df2877e9db77$var$localStorage.get($fab9df2877e9db77$export$3538ee3f5c6c8b5c);
            return value;
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: 'localStorageGetOnboardingPremiumTrial',
                    packageName: 'jiraReverseTrialUtils'
                },
                sendToPrivacyUnsafeSplunk: true
            });
            return undefined;
        }
    };
    const $fab9df2877e9db77$export$c3378895d06b5528 = ()=>{
        try {
            const value = $fab9df2877e9db77$var$localStorage.get($fab9df2877e9db77$export$2f93fdc3330233dc);
            return value;
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: 'localStorageGetOnboardingPremiumTrial',
                    packageName: 'jiraReverseTrialUtils'
                },
                sendToPrivacyUnsafeSplunk: true
            });
            return undefined;
        }
    };
    const $fab9df2877e9db77$export$f83a7a49524e74ad = (premiumTrialOnSignup)=>{
        try {
            $fab9df2877e9db77$var$localStorage.set($fab9df2877e9db77$export$3538ee3f5c6c8b5c, premiumTrialOnSignup);
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: 'localStorageSetOnboardingPremiumTrial',
                    packageName: 'jiraReverseTrialUtils'
                },
                sendToPrivacyUnsafeSplunk: true
            });
        }
    };
    const $fab9df2877e9db77$export$f389f8ca168b4a3a = (reverseTrialCohort)=>{
        try {
            $fab9df2877e9db77$var$localStorage.set($fab9df2877e9db77$export$2f93fdc3330233dc, reverseTrialCohort);
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: 'localStorageSetOnboardingPremiumTrial',
                    packageName: 'jiraReverseTrialUtils'
                },
                sendToPrivacyUnsafeSplunk: true
            });
        }
    };
});
parcelRegister("e2N0p", function(module, exports) {
    $parcel$export(module.exports, "useForceOpenPremiumPopupOnDay3", ()=>$02b1ccb7eeb6fc53$export$6cc86e1de6373a57);
    var $5uXOq = parcelRequire("5uXOq");
    var $9yQlD;
    const $02b1ccb7eeb6fc53$export$6cc86e1de6373a57 = ({ shouldRun: shouldRun, onPopupToggle: onPopupToggle })=>{
        (0, $5uXOq.useEffect)(()=>{
            if (shouldRun) {
                const onboardingStartedTimestamp = Number((0, (0, parcelRequire("9yQlD")).localStorage).get((0, $6784cbc7d62e5d7c$export$69fe8832c4f79a32)));
                if (onboardingStartedTimestamp) {
                    const threeDays = 259200000;
                    if (Date.now() - onboardingStartedTimestamp > threeDays) {
                        onPopupToggle(true, true);
                        (0, (0, parcelRequire("9yQlD")).localStorage).remove((0, $6784cbc7d62e5d7c$export$69fe8832c4f79a32));
                    }
                }
            }
        }, [
            shouldRun,
            onPopupToggle
        ]);
    };
});
const $6784cbc7d62e5d7c$export$67abe0a3b8dfe03f = 'should-show-scrum-modal-v2';
const $6784cbc7d62e5d7c$export$4e1360a3e7785935 = 'should-show-kanban-modal';
const $6784cbc7d62e5d7c$export$e3884212029f7bef = 'should-show-welcome-modal';
const $6784cbc7d62e5d7c$export$eaf6b96a17b6476c = 'can-enroll-in-select-initial-view';
const $6784cbc7d62e5d7c$export$891ca65264b256bb = 'should-show-personlized-sample-data-for-kanban';
const $6784cbc7d62e5d7c$export$f47dca9dda77d157 = 'should-hide-quickstart-for-personalized-sample-data';
const $6784cbc7d62e5d7c$export$928ffae10e4c0006 = 'template-key-to-show-onboarding-modal';
const $6784cbc7d62e5d7c$export$8a00ecf6498ee2fe = 'onboarding-selected-project-template-type';
const $6784cbc7d62e5d7c$export$69fe8832c4f79a32 = 'onboarding_started_timestamp';
const $6784cbc7d62e5d7c$export$a8d62e500b59f31e = 'boardCreateColumnButtonNudge';
const $6784cbc7d62e5d7c$export$92c7f0eacc8d8d98 = 'assigneeFilterAddPeopleButtonNudge';
const $6784cbc7d62e5d7c$export$dd63d0099d95ba4f = 'navigationAppsNavItemNudge';
const $6784cbc7d62e5d7c$export$aba87e019f5ccb8d = 'navigationCreateIssueButtonNudge';
const $6784cbc7d62e5d7c$export$46d9a3d441dfd22b = 'navigationPlansNavItemNudge';
const $6784cbc7d62e5d7c$export$e20072f7d0c5357f = 'roadmapCreateEpicButtonNudge';
const $6784cbc7d62e5d7c$export$1e39959907a7f359 = 'roadmapCreateEpicChildIssueButtonNudge';
const $6784cbc7d62e5d7c$export$b74a8fdb2c75b869 = 'navigationPeopleNavItemNudge';
const $6784cbc7d62e5d7c$export$b1a6ab17737f33e = 'backlogAccordionNudge';
const $6784cbc7d62e5d7c$export$3366ddefe77d5112 = 'boardCreateColumnNudge';
const $6784cbc7d62e5d7c$export$9d9e95a66a2c6abe = 'addItemsCalendarNudge';
const $6784cbc7d62e5d7c$export$69aca8ebee0d34c8 = 'trelloCrossflowAddItemsCalendarNudge';
const $6784cbc7d62e5d7c$export$dd4b359b5a58cdc0 = 'trelloCrossflowTimelineTabNudge';
const $6784cbc7d62e5d7c$export$7aa8bceae32c7862 = 'navigationAppsProfileItemNudge';
const $6784cbc7d62e5d7c$export$c6df9a2c7447e12d = 'getStartedListNudge';
const $6784cbc7d62e5d7c$export$468488291ccd988 = 'timelineAddItemNudge';
const $6784cbc7d62e5d7c$export$7a33548109ac931d = 'createFormNudge';
const $6784cbc7d62e5d7c$export$b65ba9ea0c0ced43 = 'jsw-onboarding-checklist.open';
const $6784cbc7d62e5d7c$export$3b5d230b66f60471 = 'jsw-onboarding-checklist.visibility';
const $6784cbc7d62e5d7c$export$594d3436316492be = 'summaryViewNudge';
const $6784cbc7d62e5d7c$export$77bc79debbf5c069 = 'inviteTeamNudge';
parcelRegister("9yQlD", function(module, exports) {
    $parcel$export(module.exports, "sessionStorage", ()=>$816fbeb16311dd64$export$f119d83aa936d634);
    $parcel$export(module.exports, "sessionStorageProvider", ()=>$816fbeb16311dd64$export$5a24cb3021127ff7);
    $parcel$export(module.exports, "localStorage", ()=>$816fbeb16311dd64$export$c2ed3351cac403d1);
    var $bSSG7;
    var $eNf8a;
    const $816fbeb16311dd64$export$f119d83aa936d634 = (0, (0, parcelRequire("eNf8a")).createSessionExpirableStorageProvider)('onboarding-core');
    const $816fbeb16311dd64$export$5a24cb3021127ff7 = (0, (0, parcelRequire("eNf8a")).createSessionExpirableStorageProvider)('onboarding-core');
    const $816fbeb16311dd64$export$c2ed3351cac403d1 = (0, (0, parcelRequire("bSSG7")).createLocalExpirableStorageProvider)('onboarding-core');
});
parcelRegister("eydIU", function(module, exports) {
    $parcel$export(module.exports, "ReverseTrialExperimentPopupContent", ()=>$80d9c8248bf39770$export$2409664383bedc18);
    var $5uXOq = parcelRequire("5uXOq");
    var $1dJ6S;
    var $kbAiU;
    var $8EYBJ;
    var $5IlYG;
    var $dC5iT;
    var $luBm6;
    var $8CUq3;
    var $koVbs;
    var $8Bc5A;
    var $cE2Qr;
    var $4i99S;
    var $j5B5j;
    var $5OXJT;
    var $1VQfH;
    const $80d9c8248bf39770$export$2409664383bedc18 = ({ billingPageUrl: billingPageUrl, onFireUiAnalyticsEvent: onFireUiAnalyticsEvent })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const openChecklist = (0, (0, parcelRequire("4i99S")).useOpenChecklist)();
        const [{ currentTaskGroupParent: currentTaskGroupParent }, { changeTaskGroupParent: changeTaskGroupParent, nextOpenTask: nextOpenTask }] = (0, (0, parcelRequire("cE2Qr")).useJswOnboarding)();
        const onClickExplorePremiumFeatures = (0, $5uXOq.useCallback)(()=>{
            onFireUiAnalyticsEvent('clicked', 'button', 'trialPillExplorePremium');
            const taskGroupToEnter = (0, (0, parcelRequire("8Bc5A")).PREMIUM_REVERSE_TRIAL_TASK);
            if (currentTaskGroupParent !== taskGroupToEnter) changeTaskGroupParent(taskGroupToEnter);
            openChecklist({
                trigger: 'ReverseTrialPopup'
            });
            nextOpenTask((0, (0, parcelRequire("8Bc5A")).ADVANCED_ROADMAPS_TASK), {
                closeNudge: true
            });
        }, [
            onFireUiAnalyticsEvent,
            currentTaskGroupParent,
            openChecklist,
            nextOpenTask,
            changeTaskGroupParent
        ]);
        const onLinkClick = (0, $5uXOq.useCallback)((title)=>{
            onFireUiAnalyticsEvent('clicked', 'button', title);
        }, [
            onFireUiAnalyticsEvent
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1dJ6S")).default), {
            size: "small"
        }, formatMessage((0, (0, parcelRequire("1VQfH")).default).title)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("luBm6")).default), {
            as: "ul",
            xcss: $80d9c8248bf39770$var$listContainerStyles,
            alignInline: "stretch"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5OXJT")).ListItem), {
            title: formatMessage((0, (0, parcelRequire("1VQfH")).default).explorePremiumFeaturesLink),
            onClick: onClickExplorePremiumFeatures,
            icon: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("8EYBJ"))))), {
                label: "",
                size: "small"
            })
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5OXJT")).ListItem), {
            title: formatMessage((0, (0, parcelRequire("1VQfH")).default).addPaymentDetailsLink),
            link: billingPageUrl,
            onClick: ()=>onLinkClick('trialPillAddPaymentDetails'),
            icon: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("kbAiU"))))), {
                label: "",
                size: "small"
            })
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j5B5j")).ChangePlansUrlProvider), null, (changePlansUrl)=>changePlansUrl ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5OXJT")).ListItem), {
                title: formatMessage((0, (0, parcelRequire("1VQfH")).default).endTrialAndChangePlansLink),
                link: changePlansUrl,
                onClick: ()=>onLinkClick('trialPillChangePlans'),
                icon: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("5IlYG"))))), {
                    label: "",
                    size: "small"
                })
            }) : null)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "p",
            xcss: $80d9c8248bf39770$var$downgradeTextStyles
        }, formatMessage((0, (0, parcelRequire("1VQfH")).default).afterTrial)));
    };
    const $80d9c8248bf39770$var$listContainerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        listStyle: 'none',
        marginTop: 'space.100',
        padding: 'space.0'
    });
    const $80d9c8248bf39770$var$downgradeTextStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        font: "var(--ds-font-body-UNSAFE_small, normal 400 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
        color: 'color.text.subtle'
    });
});
parcelRegister("8Bc5A", function(module, exports) {
    $parcel$export(module.exports, "PACKAGE_NAME", ()=>$5f07ce956337ba8b$export$b8b9d90f9e5bd72b);
    $parcel$export(module.exports, "PACKAGE_TEAM_NAME", ()=>$5f07ce956337ba8b$export$2b3151a089bc8780);
    $parcel$export(module.exports, "DEFAULT_NUDGE_WIDTH", ()=>$5f07ce956337ba8b$export$6826d33e9a2c0119);
    $parcel$export(module.exports, "ROOT_TASK", ()=>$5f07ce956337ba8b$export$b580da85daad7757);
    $parcel$export(module.exports, "REVERSE_TRIAL_ROOT_TASK", ()=>$5f07ce956337ba8b$export$5eef19b26a4bbe70);
    $parcel$export(module.exports, "QUICKSTART_TYPE", ()=>$5f07ce956337ba8b$export$c57d251ae83aff51);
    $parcel$export(module.exports, "getBackendVisibilityKey", ()=>$5f07ce956337ba8b$export$d9df7fd23ab00c49);
    $parcel$export(module.exports, "KEY_OPEN", ()=>$5f07ce956337ba8b$export$93a67c1026e8cb7c);
    $parcel$export(module.exports, "KEY_DISMISSED", ()=>$5f07ce956337ba8b$export$a7dcb0b5013e932b);
    $parcel$export(module.exports, "KEY_TASK_GROUP", ()=>$5f07ce956337ba8b$export$f962b14e410d598f);
    $parcel$export(module.exports, "KEY_OPEN_TASK", ()=>$5f07ce956337ba8b$export$21e8355b28449456);
    $parcel$export(module.exports, "KEY_HAS_EVER_SEEN_ROUTE", ()=>$5f07ce956337ba8b$export$536a90031123e004);
    $parcel$export(module.exports, "KEY_PREMIUM_TRIAL_REOPENED", ()=>$5f07ce956337ba8b$export$3ccd291a1a3a4f6d);
    $parcel$export(module.exports, "KEY_IS_DEVOPS_PROJECT", ()=>$5f07ce956337ba8b$export$e5b557e3bbb7ec52);
    $parcel$export(module.exports, "ACTION_SUBJECT_ID", ()=>$5f07ce956337ba8b$export$9957974f8b4d8f74);
    $parcel$export(module.exports, "SOURCE", ()=>$5f07ce956337ba8b$export$83c8d7110915321e);
    $parcel$export(module.exports, "KEY_JSW_ONBOARDING", ()=>$5f07ce956337ba8b$export$8b9bd2a9275b0187);
    $parcel$export(module.exports, "convertToHaveSeenItTask", ()=>$5f07ce956337ba8b$export$ec43e7bf5973b10f);
    $parcel$export(module.exports, "ADD_COLUMNS_TASK", ()=>$5f07ce956337ba8b$export$cbeb719208f32c8a);
    $parcel$export(module.exports, "CREATE_PROJECT_TASK", ()=>$5f07ce956337ba8b$export$88c918f2cfaff86);
    $parcel$export(module.exports, "CREATE_ISSUE_TASK", ()=>$5f07ce956337ba8b$export$abbbea7c37c93cb5);
    $parcel$export(module.exports, "CREATE_ISSUE_TASK_SIMPLIFIED", ()=>$5f07ce956337ba8b$export$2a0253cb20f48a8);
    $parcel$export(module.exports, "INVITE_TEAMMATES_TASK", ()=>$5f07ce956337ba8b$export$f97428bb4b1991ec);
    $parcel$export(module.exports, "INVITE_TEAMMATES_VIDEO_TASK", ()=>$5f07ce956337ba8b$export$45eaf4204fcc7);
    $parcel$export(module.exports, "CAPTURE_INTEGRATIONS_TASK", ()=>$5f07ce956337ba8b$export$af57685e201438e8);
    $parcel$export(module.exports, "FIND_HELP_TASK", ()=>$5f07ce956337ba8b$export$d6c67e9aab4e2b26);
    $parcel$export(module.exports, "SET_UP_BOARD_TASK", ()=>$5f07ce956337ba8b$export$3de8c242d2b82f26);
    $parcel$export(module.exports, "GET_MOBILE_APP_TASK", ()=>$5f07ce956337ba8b$export$a01fa44f793dbfac);
    $parcel$export(module.exports, "MAP_OUT_YOUR_PROJECT_GOALS_TASK", ()=>$5f07ce956337ba8b$export$6a4507e462fd41da);
    $parcel$export(module.exports, "IDENTIFY_SMALL_CHUNKS_OF_WORK_TASK", ()=>$5f07ce956337ba8b$export$e64c1dced6ac4458);
    $parcel$export(module.exports, "IDENTIFY_SMALL_CHUNKS_OF_WORK_KANBAN_TASK", ()=>$5f07ce956337ba8b$export$63b0e55f9c6454ac);
    $parcel$export(module.exports, "MONITOR_MANAGE_RISK_TASK", ()=>$5f07ce956337ba8b$export$c1effb1c8979fe22);
    $parcel$export(module.exports, "PREMIUM_REVERSE_TRIAL_TASK", ()=>$5f07ce956337ba8b$export$d97d31d934dbb194);
    $parcel$export(module.exports, "PREMIUM_EDITION_OVERVIEW_TASK", ()=>$5f07ce956337ba8b$export$cafb5bd13b02056b);
    $parcel$export(module.exports, "PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN", ()=>$5f07ce956337ba8b$export$e55c18b809946e72);
    $parcel$export(module.exports, "STANDARD_EDITION_OVERVIEW_TASK", ()=>$5f07ce956337ba8b$export$de5640b9ae8140a7);
    $parcel$export(module.exports, "ADVANCED_ROADMAPS_TASK", ()=>$5f07ce956337ba8b$export$540b5c3c4b6ce459);
    $parcel$export(module.exports, "CHANGE_CONTROL_TASK", ()=>$5f07ce956337ba8b$export$6f71dff531ff592);
    $parcel$export(module.exports, "AUTOMATIONS_TASK", ()=>$5f07ce956337ba8b$export$83255ab79e45f34f);
    $parcel$export(module.exports, "SITE_ADMINISTRATIONS_TASK", ()=>$5f07ce956337ba8b$export$b57d172af9ad7647);
    $parcel$export(module.exports, "USER_MANAGEMENT_TASK", ()=>$5f07ce956337ba8b$export$ff4c933c1fe90b68);
    $parcel$export(module.exports, "SCRUM_ONBOARDING_TASK", ()=>$5f07ce956337ba8b$export$3e2776d61f3662dc);
    $parcel$export(module.exports, "CUSTOMIZE_SITE_NAME_TASK", ()=>$5f07ce956337ba8b$export$9048315f2c03fd9d);
    $parcel$export(module.exports, "GET_TO_KNOW_YOUR_TEMPLATE_TASK", ()=>$5f07ce956337ba8b$export$a333714599f14a6);
    $parcel$export(module.exports, "CREATE_BUSINESS_PROJECT_FROM_JSW_QUICKSTART_TASK", ()=>$5f07ce956337ba8b$export$4145c2cec2da6f9b);
    $parcel$export(module.exports, "TRY_ATLASSIAN_UNIVERSITY_ONBOARDING_TASK", ()=>$5f07ce956337ba8b$export$4957a1ad203cf6dd);
    $parcel$export(module.exports, "CALENDAR_VIEW_TASK", ()=>$5f07ce956337ba8b$export$190b5dd0a1322552);
    $parcel$export(module.exports, "JWM_PROJECT_SUMMARY_TASK", ()=>$5f07ce956337ba8b$export$501166b25f254aab);
    $parcel$export(module.exports, "JWM_CREATE_SAMPLE_PROJECT_TASK", ()=>$5f07ce956337ba8b$export$94ad6507a703f4eb);
    $parcel$export(module.exports, "JWM_BACKGROUND_COLOR_TASK", ()=>$5f07ce956337ba8b$export$be1c522400a1bd7c);
    $parcel$export(module.exports, "JWM_CALENDAR_TASK", ()=>$5f07ce956337ba8b$export$2aa6e7b6e24a04f5);
    $parcel$export(module.exports, "JWM_TIMELINE_TASK", ()=>$5f07ce956337ba8b$export$f3b53199b07af2a1);
    $parcel$export(module.exports, "JWM_BUILD_FORM_TASK", ()=>$5f07ce956337ba8b$export$d37415ffddcf2142);
    $parcel$export(module.exports, "JWM_FEEDBACK_TASK", ()=>$5f07ce956337ba8b$export$2ae00586da7cb606);
    $parcel$export(module.exports, "JWM_FEEDBACK_SAMPLE_PROJECT_TASK", ()=>$5f07ce956337ba8b$export$b86266e6594a97bb);
    $parcel$export(module.exports, "JWM_CREATE_YOUR_OWN_PROJECT_TASK", ()=>$5f07ce956337ba8b$export$20bc3dc137ac1c38);
    $parcel$export(module.exports, "JWM_CREATE_PROJECT_TASK", ()=>$5f07ce956337ba8b$export$77071b77e2801b18);
    $parcel$export(module.exports, "JWM_SHARE_PROJECT_TASK", ()=>$5f07ce956337ba8b$export$9c89f946f05d884a);
    $parcel$export(module.exports, "JWM_TRELLO_CROSSFLOW_TASK_IMPORT", ()=>$5f07ce956337ba8b$export$b52a7c936684cfc5);
    $parcel$export(module.exports, "JWM_SUMMARY_AND_REPORTS_TASK", ()=>$5f07ce956337ba8b$export$b5555f24061f9fb);
    $parcel$export(module.exports, "JWM_TRELLO_CROSSFLOW_CALENDAR_AND_TIMELINE_TASK", ()=>$5f07ce956337ba8b$export$ff25449f34b8abf);
    $parcel$export(module.exports, "JWM_TRELLO_CROSSFLOW_TASK_KEY_DIFFERENCES", ()=>$5f07ce956337ba8b$export$3380e677209260fe);
    $parcel$export(module.exports, "GET_STARTED_LIST_TASK", ()=>$5f07ce956337ba8b$export$7a2fcaad1564e6b7);
    $parcel$export(module.exports, "OPS_JTBD_1_LIST", ()=>$5f07ce956337ba8b$export$da23b72d32bfad9e);
    $parcel$export(module.exports, "OPS_JTBD_2_BOARD", ()=>$5f07ce956337ba8b$export$4b064659282e0238);
    $parcel$export(module.exports, "OPS_JTBD_2_FORMS", ()=>$5f07ce956337ba8b$export$3ab51851f6d823e);
    $parcel$export(module.exports, "OPS_JTBD_2_SUMMARY", ()=>$5f07ce956337ba8b$export$e4a1a37a9a1350ad);
    $parcel$export(module.exports, "OPS_JTBD_3_LIST", ()=>$5f07ce956337ba8b$export$b7f7da5e795f0327);
    $parcel$export(module.exports, "OPS_JTBD_3_CALENDAR", ()=>$5f07ce956337ba8b$export$80a401c1aa47a377);
    $parcel$export(module.exports, "OPS_JTBD_3_PAGES", ()=>$5f07ce956337ba8b$export$934ac7a251b26dc7);
    $parcel$export(module.exports, "OPS_JTBD_4_LIST", ()=>$5f07ce956337ba8b$export$4634f6395df3ebbe);
    $parcel$export(module.exports, "OPS_JTBD_4_CALENDAR", ()=>$5f07ce956337ba8b$export$768fb41107a1e8d7);
    $parcel$export(module.exports, "OPS_JTBD_4_TIMELINE", ()=>$5f07ce956337ba8b$export$d797a81bace17184);
    $parcel$export(module.exports, "OPS_JTBD_4_SUMMARY", ()=>$5f07ce956337ba8b$export$746102c94db9df1f);
    $parcel$export(module.exports, "OPS_JTBD_5_LIST", ()=>$5f07ce956337ba8b$export$5f1efd797d65e349);
    $parcel$export(module.exports, "OPS_JTBD_5_TIMELINE", ()=>$5f07ce956337ba8b$export$cccc812ccafbf11f);
    $parcel$export(module.exports, "OPS_JTBD_5_PAGES", ()=>$5f07ce956337ba8b$export$64822db87abd8a47);
    $parcel$export(module.exports, "OPS_JTBD_6_PAGES", ()=>$5f07ce956337ba8b$export$64f764d8be495c82);
    $parcel$export(module.exports, "OPS_JTBD_7_LIST", ()=>$5f07ce956337ba8b$export$24eae23dc1c68bbd);
    $parcel$export(module.exports, "OPS_JTBD_7_CALENDAR", ()=>$5f07ce956337ba8b$export$50fa92eba6f82feb);
    $parcel$export(module.exports, "OPS_JTBD_0_LIST", ()=>$5f07ce956337ba8b$export$4155d22fe3f14cd6);
    $parcel$export(module.exports, "OPS_JTBD_0_TIMELINE", ()=>$5f07ce956337ba8b$export$76813cbead887191);
    $parcel$export(module.exports, "OPS_JTBD_0_PAGES", ()=>$5f07ce956337ba8b$export$90aab2ff89daf770);
    $parcel$export(module.exports, "OPS_JTBD_DEFAULT_LIST", ()=>$5f07ce956337ba8b$export$a2bc0cbad2e6e605);
    $parcel$export(module.exports, "OPS_JTBD_DEFAULT_BOARD", ()=>$5f07ce956337ba8b$export$f90872a1280728bb);
    $parcel$export(module.exports, "OPS_JTBD_DEFAULT_INVITES", ()=>$5f07ce956337ba8b$export$bce644e96518cbd2);
    $parcel$export(module.exports, "MKT_JTBD_1_LIST", ()=>$5f07ce956337ba8b$export$241aaebffac0a3e5);
    $parcel$export(module.exports, "MKT_JTBD_1_CALENDAR", ()=>$5f07ce956337ba8b$export$f9dfc43b5b31c4b7);
    $parcel$export(module.exports, "MKT_JTBD_1_TIMELINE", ()=>$5f07ce956337ba8b$export$79759e9e62fb9312);
    $parcel$export(module.exports, "MKT_JTBD_2_CALENDAR", ()=>$5f07ce956337ba8b$export$992d1e0dce255d19);
    $parcel$export(module.exports, "MKT_JTBD_2_PAGES", ()=>$5f07ce956337ba8b$export$d28c1b0e5b3e768);
    $parcel$export(module.exports, "MKT_JTBD_3_PAGES", ()=>$5f07ce956337ba8b$export$ca714ee197bed4ec);
    $parcel$export(module.exports, "MKT_JTBD_3_TIMELINE", ()=>$5f07ce956337ba8b$export$faae55ceea4b6073);
    $parcel$export(module.exports, "MKT_JTBD_4_LIST", ()=>$5f07ce956337ba8b$export$200bc24cd120667e);
    $parcel$export(module.exports, "MKT_JTBD_4_SUMMARY", ()=>$5f07ce956337ba8b$export$a0d784a48b4e886c);
    $parcel$export(module.exports, "MKT_JTBD_4_PAGES", ()=>$5f07ce956337ba8b$export$baf5bd09b4b33b8a);
    $parcel$export(module.exports, "MKT_JTBD_5_TIMELINE", ()=>$5f07ce956337ba8b$export$4702007eedbca8ab);
    $parcel$export(module.exports, "MKT_JTBD_5_SUMMARY", ()=>$5f07ce956337ba8b$export$aa9d2d635ea63898);
    $parcel$export(module.exports, "MKT_JTBD_6_FORMS", ()=>$5f07ce956337ba8b$export$2ea449225c1f6cac);
    $parcel$export(module.exports, "MKT_JTBD_0_LIST", ()=>$5f07ce956337ba8b$export$deb8b557e735e26a);
    $parcel$export(module.exports, "MKT_JTBD_0_CALENDAR", ()=>$5f07ce956337ba8b$export$d45754df44e4c309);
    $parcel$export(module.exports, "MKT_JTBD_0_TIMELINE", ()=>$5f07ce956337ba8b$export$78b2913b550edbb);
    $parcel$export(module.exports, "MKT_JTBD_0_SUMMARY", ()=>$5f07ce956337ba8b$export$71243272608c58ab);
    $parcel$export(module.exports, "MKT_JTBD_DEFAULT_LIST", ()=>$5f07ce956337ba8b$export$39a08f215c33bc39);
    $parcel$export(module.exports, "MKT_JTBD_DEFAULT_BOARD", ()=>$5f07ce956337ba8b$export$d2377f6a6fa3b49c);
    $parcel$export(module.exports, "MKT_JTBD_DEFAULT_PROJECT", ()=>$5f07ce956337ba8b$export$befa8f6533309779);
    $parcel$export(module.exports, "MKT_JTBD_DEFAULT_INVITES", ()=>$5f07ce956337ba8b$export$736b40db8701ba6c);
    $parcel$export(module.exports, "JSM_CUSTOMIZE_YOUR_REQUEST_TYPES_TASK", ()=>$5f07ce956337ba8b$export$99b0e09ff6f00490);
    $parcel$export(module.exports, "JSM_REVIEW_YOUR_CHANNELS_TASK", ()=>$5f07ce956337ba8b$export$2a82344eb273eeff);
    $parcel$export(module.exports, "JSM_INVITE_YOUR_TEAM_TASK", ()=>$5f07ce956337ba8b$export$1de5766b7e640d6b);
    $parcel$export(module.exports, "DEVOPS_WELCOME_TO_YOUR_PROJECT_TASK", ()=>$5f07ce956337ba8b$export$8ab0859f5b30b013);
    $parcel$export(module.exports, "DEVOPS_GET_TO_KNOW_JIRA_TASK", ()=>$5f07ce956337ba8b$export$d1f6d18c6698104c);
    $parcel$export(module.exports, "DEVOPS_NAVIGATE_YOUR_TOOLS_TASK", ()=>$5f07ce956337ba8b$export$e6ee159eee7adc1c);
    $parcel$export(module.exports, "DEVOPS_CAPTURE_INTEGRATIONS_TASK", ()=>$5f07ce956337ba8b$export$dd267ca1e3cd3bad);
    $parcel$export(module.exports, "DEVOPS_CREATE_AUTOMATION_RULES_TASK", ()=>$5f07ce956337ba8b$export$f14c8bffc35200d3);
    $parcel$export(module.exports, "DEVOPS_INVITE_TEAMMATES_TASK", ()=>$5f07ce956337ba8b$export$36cc22c9997d92a);
    $parcel$export(module.exports, "DEVOPS_FIND_HELP_TASK", ()=>$5f07ce956337ba8b$export$d651cc94a46ff383);
    $parcel$export(module.exports, "KEY_HAS_NAVIGATED_FROM_LINK", ()=>$5f07ce956337ba8b$export$11c0696d49e68c93);
    $parcel$export(module.exports, "ACCOUNT_ID", ()=>$5f07ce956337ba8b$export$c8ab6996f562d0db);
    $parcel$export(module.exports, "KANBAN_ONBOARDING_TASK", ()=>$5f07ce956337ba8b$export$2b5f951ad8e70cfe);
    $parcel$export(module.exports, "QUICKSTART_UI_STATUS", ()=>$5f07ce956337ba8b$export$5703447487aaccdf);
    $parcel$export(module.exports, "BACKLOG_CREATE_SPRINT_NUDGE_ID", ()=>$5f07ce956337ba8b$export$e0197e09cccd4e50);
    $parcel$export(module.exports, "PROJECT_SETTING_PAGES", ()=>$5f07ce956337ba8b$export$f779bbfe667acddc);
    $parcel$export(module.exports, "JSM_BUSINESS_TASKS", ()=>$5f07ce956337ba8b$export$38fbac7a5bf4aa33);
    $parcel$export(module.exports, "allTasksExcludingJSMBusinessTasks", ()=>$5f07ce956337ba8b$export$7578902c7f420a3);
    var $65Ltd;
    var $g5X3G;
    const $5f07ce956337ba8b$var$completeJwmTaskName = (taskId)=>`jwm-onboarding-checklist.${taskId}`;
    const $5f07ce956337ba8b$var$completeJswTaskName = (taskId)=>`jsw-onboarding-checklist.${taskId}`;
    const $5f07ce956337ba8b$var$completeJsmTaskName = (taskId)=>`jsm-onboarding-checklist.${taskId}`;
    const $5f07ce956337ba8b$var$completeDevopsTaskName = (taskId)=>`devops-onboarding-checklist.${taskId}`;
    const $5f07ce956337ba8b$export$b8b9d90f9e5bd72b = 'jiraSoftwareOnboardingQuickstart';
    const $5f07ce956337ba8b$export$2b3151a089bc8780 = 'jlove-makkuro';
    const $5f07ce956337ba8b$export$6826d33e9a2c0119 = 320;
    const $5f07ce956337ba8b$export$b580da85daad7757 = 'root';
    const $5f07ce956337ba8b$export$5eef19b26a4bbe70 = 'reverseTrialRoot';
    const $5f07ce956337ba8b$export$c57d251ae83aff51 = {
        JSM_BUSINESS_QUICKSTART_KEY: 'jsm-business-checklist',
        OLD_GLOBAL_QUICKSTART_KEY: 'jsw-onboarding-checklist'
    };
    const $5f07ce956337ba8b$export$d9df7fd23ab00c49 = (type)=>`${type}.visibility`;
    const $5f07ce956337ba8b$export$93a67c1026e8cb7c = 'jsw-onboarding-checklist.open';
    const $5f07ce956337ba8b$export$a7dcb0b5013e932b = 'jsw-onboarding-checklist.dismissed';
    const $5f07ce956337ba8b$export$f962b14e410d598f = 'jsw-onboarding-checklist.task-group';
    const $5f07ce956337ba8b$export$21e8355b28449456 = 'jsw-onboarding-checklist.open-task';
    const $5f07ce956337ba8b$export$536a90031123e004 = 'jsw-onboarding-checklist.has-ever-seen-route';
    const $5f07ce956337ba8b$export$3ccd291a1a3a4f6d = 'jsw-onboarding-checklist.reopened-on-premium-trial';
    const $5f07ce956337ba8b$export$e5b557e3bbb7ec52 = 'isDevopsProject';
    const $5f07ce956337ba8b$export$9957974f8b4d8f74 = 'nuxOnboardingQuickstart';
    const $5f07ce956337ba8b$export$83c8d7110915321e = 'nuxOnboardingQuickstart';
    const $5f07ce956337ba8b$export$8b9bd2a9275b0187 = 'jsw.onboarding';
    const $5f07ce956337ba8b$export$ec43e7bf5973b10f = (taskId)=>`${taskId}.have-seenit`;
    const $5f07ce956337ba8b$export$cbeb719208f32c8a = $5f07ce956337ba8b$var$completeJswTaskName('add-columns-task');
    const $5f07ce956337ba8b$export$88c918f2cfaff86 = $5f07ce956337ba8b$var$completeJswTaskName('create-project-task');
    const $5f07ce956337ba8b$export$abbbea7c37c93cb5 = $5f07ce956337ba8b$var$completeJswTaskName('create-issue-task');
    const $5f07ce956337ba8b$export$2a0253cb20f48a8 = $5f07ce956337ba8b$var$completeJswTaskName('create-issue-task-simplified');
    const $5f07ce956337ba8b$export$f97428bb4b1991ec = $5f07ce956337ba8b$var$completeJswTaskName('invite-teammates-task');
    const $5f07ce956337ba8b$export$45eaf4204fcc7 = $5f07ce956337ba8b$var$completeJswTaskName('invite-teammates-video-task');
    const $5f07ce956337ba8b$export$af57685e201438e8 = $5f07ce956337ba8b$var$completeJswTaskName('capture-integrations-task');
    const $5f07ce956337ba8b$export$d6c67e9aab4e2b26 = $5f07ce956337ba8b$var$completeJswTaskName('find-help-task');
    const $5f07ce956337ba8b$export$3de8c242d2b82f26 = $5f07ce956337ba8b$var$completeJswTaskName('set-up-board-task');
    const $5f07ce956337ba8b$export$a01fa44f793dbfac = $5f07ce956337ba8b$var$completeJswTaskName('get-mobile-app-task');
    const $5f07ce956337ba8b$export$6a4507e462fd41da = $5f07ce956337ba8b$var$completeJswTaskName('map-out-your-project-goals');
    const $5f07ce956337ba8b$export$e64c1dced6ac4458 = $5f07ce956337ba8b$var$completeJswTaskName('identify-small-chunks-of-work');
    const $5f07ce956337ba8b$export$63b0e55f9c6454ac = $5f07ce956337ba8b$var$completeJswTaskName('identify-small-chunks-of-work-kanban');
    const $5f07ce956337ba8b$export$c1effb1c8979fe22 = $5f07ce956337ba8b$var$completeJswTaskName('monitor-manage-risk');
    const $5f07ce956337ba8b$export$d97d31d934dbb194 = $5f07ce956337ba8b$var$completeJswTaskName('premium-reverse-trial-task');
    const $5f07ce956337ba8b$export$cafb5bd13b02056b = $5f07ce956337ba8b$var$completeJswTaskName('premium-edition-overview-task');
    const $5f07ce956337ba8b$export$e55c18b809946e72 = $5f07ce956337ba8b$var$completeJswTaskName('premium-edition-overview-task-non-admin');
    const $5f07ce956337ba8b$export$de5640b9ae8140a7 = $5f07ce956337ba8b$var$completeJswTaskName('standard-edition-overview-task');
    const $5f07ce956337ba8b$export$540b5c3c4b6ce459 = $5f07ce956337ba8b$var$completeJswTaskName('advanced-roadmaps-task');
    const $5f07ce956337ba8b$export$6f71dff531ff592 = $5f07ce956337ba8b$var$completeJswTaskName('change-control-task');
    const $5f07ce956337ba8b$export$83255ab79e45f34f = $5f07ce956337ba8b$var$completeJswTaskName('automations-task');
    const $5f07ce956337ba8b$export$b57d172af9ad7647 = $5f07ce956337ba8b$var$completeJswTaskName('site-administrations-task');
    const $5f07ce956337ba8b$export$ff4c933c1fe90b68 = $5f07ce956337ba8b$var$completeJswTaskName('user-management-task');
    const $5f07ce956337ba8b$export$3e2776d61f3662dc = $5f07ce956337ba8b$var$completeJswTaskName('scrum-onboarding-task');
    const $5f07ce956337ba8b$export$9048315f2c03fd9d = $5f07ce956337ba8b$var$completeJswTaskName('customize-site-name-task');
    const $5f07ce956337ba8b$export$a333714599f14a6 = $5f07ce956337ba8b$var$completeJswTaskName('get-to-know-your-template-task');
    const $5f07ce956337ba8b$export$4145c2cec2da6f9b = $5f07ce956337ba8b$var$completeJswTaskName('create-business-project-from-jsw-quickstart-task');
    const $5f07ce956337ba8b$export$4957a1ad203cf6dd = $5f07ce956337ba8b$var$completeJswTaskName('try-atlassian-university-task');
    const $5f07ce956337ba8b$export$190b5dd0a1322552 = $5f07ce956337ba8b$var$completeJswTaskName('calendar-view-task');
    const $5f07ce956337ba8b$export$501166b25f254aab = $5f07ce956337ba8b$var$completeJwmTaskName('jwm-project-summary-task');
    const $5f07ce956337ba8b$export$94ad6507a703f4eb = $5f07ce956337ba8b$var$completeJwmTaskName('jwm-create-sample-project-task');
    const $5f07ce956337ba8b$export$be1c522400a1bd7c = $5f07ce956337ba8b$var$completeJwmTaskName('background-color-task');
    const $5f07ce956337ba8b$export$2aa6e7b6e24a04f5 = $5f07ce956337ba8b$var$completeJwmTaskName('calendar-task');
    const $5f07ce956337ba8b$export$f3b53199b07af2a1 = $5f07ce956337ba8b$var$completeJwmTaskName('timeline-task');
    const $5f07ce956337ba8b$export$d37415ffddcf2142 = $5f07ce956337ba8b$var$completeJwmTaskName('build-form-task');
    const $5f07ce956337ba8b$export$2ae00586da7cb606 = $5f07ce956337ba8b$var$completeJwmTaskName('feedback-task');
    const $5f07ce956337ba8b$export$b86266e6594a97bb = $5f07ce956337ba8b$var$completeJwmTaskName('feedback-sample-project-task');
    const $5f07ce956337ba8b$export$20bc3dc137ac1c38 = $5f07ce956337ba8b$var$completeJwmTaskName('create-your-own-project-task');
    const $5f07ce956337ba8b$export$77071b77e2801b18 = $5f07ce956337ba8b$var$completeJwmTaskName('jwm-create-project-task');
    const $5f07ce956337ba8b$export$9c89f946f05d884a = $5f07ce956337ba8b$var$completeJwmTaskName('share-project-task');
    const $5f07ce956337ba8b$export$ce8b25442735839a = $5f07ce956337ba8b$var$completeJwmTaskName('trello-crossflow-boards');
    const $5f07ce956337ba8b$export$31015cf4e430c94d = $5f07ce956337ba8b$var$completeJwmTaskName('trello-crossflow-cards');
    const $5f07ce956337ba8b$export$b52a7c936684cfc5 = $5f07ce956337ba8b$var$completeJwmTaskName('trello-crossflow-import');
    const $5f07ce956337ba8b$export$b5555f24061f9fb = $5f07ce956337ba8b$var$completeJwmTaskName('summary-and-reports-task');
    const $5f07ce956337ba8b$export$ff25449f34b8abf = $5f07ce956337ba8b$var$completeJwmTaskName('trello-crossflow-calendar-and-timeline');
    const $5f07ce956337ba8b$export$3380e677209260fe = $5f07ce956337ba8b$var$completeJwmTaskName('trello-crossflow-key-differences');
    const $5f07ce956337ba8b$export$7a2fcaad1564e6b7 = $5f07ce956337ba8b$var$completeJwmTaskName('get-started-list-task');
    const $5f07ce956337ba8b$export$da23b72d32bfad9e = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_1_LIST');
    const $5f07ce956337ba8b$export$4b064659282e0238 = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_2_BOARD');
    const $5f07ce956337ba8b$export$3ab51851f6d823e = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_2_FORMS');
    const $5f07ce956337ba8b$export$e4a1a37a9a1350ad = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_2_SUMMARY');
    const $5f07ce956337ba8b$export$b7f7da5e795f0327 = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_3_LIST');
    const $5f07ce956337ba8b$export$80a401c1aa47a377 = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_3_CALENDAR');
    const $5f07ce956337ba8b$export$934ac7a251b26dc7 = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_3_PAGES');
    const $5f07ce956337ba8b$export$4634f6395df3ebbe = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_4_LIST');
    const $5f07ce956337ba8b$export$768fb41107a1e8d7 = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_4_CALENDAR');
    const $5f07ce956337ba8b$export$d797a81bace17184 = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_4_TIMELINE');
    const $5f07ce956337ba8b$export$746102c94db9df1f = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_4_SUMMARY');
    const $5f07ce956337ba8b$export$5f1efd797d65e349 = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_5_LIST');
    const $5f07ce956337ba8b$export$cccc812ccafbf11f = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_5_TIMELINE');
    const $5f07ce956337ba8b$export$64822db87abd8a47 = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_5_PAGES');
    const $5f07ce956337ba8b$export$64f764d8be495c82 = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_6_PAGES');
    const $5f07ce956337ba8b$export$24eae23dc1c68bbd = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_7_LIST');
    const $5f07ce956337ba8b$export$50fa92eba6f82feb = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_7_CALENDAR');
    const $5f07ce956337ba8b$export$4155d22fe3f14cd6 = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_0_LIST');
    const $5f07ce956337ba8b$export$76813cbead887191 = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_0_TIMELINE');
    const $5f07ce956337ba8b$export$90aab2ff89daf770 = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_0_PAGES');
    const $5f07ce956337ba8b$export$a2bc0cbad2e6e605 = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_DEFAULT_LIST');
    const $5f07ce956337ba8b$export$f90872a1280728bb = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_DEFAULT_BOARD');
    const $5f07ce956337ba8b$export$bce644e96518cbd2 = $5f07ce956337ba8b$var$completeJwmTaskName('OPS_JTBD_DEFAULT_INVITES');
    const $5f07ce956337ba8b$export$241aaebffac0a3e5 = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_1_LIST');
    const $5f07ce956337ba8b$export$f9dfc43b5b31c4b7 = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_1_CALENDAR');
    const $5f07ce956337ba8b$export$79759e9e62fb9312 = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_1_TIMELINE');
    const $5f07ce956337ba8b$export$992d1e0dce255d19 = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_2_CALENDAR');
    const $5f07ce956337ba8b$export$d28c1b0e5b3e768 = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_2_PAGES');
    const $5f07ce956337ba8b$export$ca714ee197bed4ec = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_3_PAGES');
    const $5f07ce956337ba8b$export$faae55ceea4b6073 = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_3_TIMELINE');
    const $5f07ce956337ba8b$export$200bc24cd120667e = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_4_LIST');
    const $5f07ce956337ba8b$export$a0d784a48b4e886c = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_4_SUMMARY');
    const $5f07ce956337ba8b$export$baf5bd09b4b33b8a = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_4_PAGES');
    const $5f07ce956337ba8b$export$4702007eedbca8ab = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_5_TIMELINE');
    const $5f07ce956337ba8b$export$aa9d2d635ea63898 = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_5_SUMMARY');
    const $5f07ce956337ba8b$export$2ea449225c1f6cac = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_6_FORMS');
    const $5f07ce956337ba8b$export$deb8b557e735e26a = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_0_LIST');
    const $5f07ce956337ba8b$export$d45754df44e4c309 = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_0_CALENDAR');
    const $5f07ce956337ba8b$export$78b2913b550edbb = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_0_TIMELINE');
    const $5f07ce956337ba8b$export$71243272608c58ab = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_0_SUMMARY');
    const $5f07ce956337ba8b$export$39a08f215c33bc39 = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_DEFAULT_LIST');
    const $5f07ce956337ba8b$export$d2377f6a6fa3b49c = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_DEFAULT_BOARD');
    const $5f07ce956337ba8b$export$befa8f6533309779 = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_DEFAULT_PROJECT');
    const $5f07ce956337ba8b$export$736b40db8701ba6c = $5f07ce956337ba8b$var$completeJwmTaskName('MKT_JTBD_DEFAULT_INVITES');
    const $5f07ce956337ba8b$export$99b0e09ff6f00490 = $5f07ce956337ba8b$var$completeJsmTaskName('customize-your-request-types-task');
    const $5f07ce956337ba8b$export$2a82344eb273eeff = $5f07ce956337ba8b$var$completeJsmTaskName('review-your-channels-task');
    const $5f07ce956337ba8b$export$1de5766b7e640d6b = $5f07ce956337ba8b$var$completeJsmTaskName('invite-your-team-task');
    const $5f07ce956337ba8b$export$8ab0859f5b30b013 = $5f07ce956337ba8b$var$completeDevopsTaskName('welcome-to-your-project-task');
    const $5f07ce956337ba8b$export$d1f6d18c6698104c = $5f07ce956337ba8b$var$completeDevopsTaskName('get-to-know-jira-task');
    const $5f07ce956337ba8b$export$e6ee159eee7adc1c = $5f07ce956337ba8b$var$completeDevopsTaskName('navigate-your-tools-task');
    const $5f07ce956337ba8b$export$dd267ca1e3cd3bad = $5f07ce956337ba8b$var$completeDevopsTaskName('capture-integrations-task');
    const $5f07ce956337ba8b$export$f14c8bffc35200d3 = $5f07ce956337ba8b$var$completeDevopsTaskName('create-automation-rules-task');
    const $5f07ce956337ba8b$export$36cc22c9997d92a = $5f07ce956337ba8b$var$completeDevopsTaskName('invite-teammates-task');
    const $5f07ce956337ba8b$export$d651cc94a46ff383 = $5f07ce956337ba8b$var$completeDevopsTaskName('find-help-task');
    const $5f07ce956337ba8b$export$11c0696d49e68c93 = 'jsw-onboarding-checklist.has.navigated.from.link';
    const $5f07ce956337ba8b$export$c8ab6996f562d0db = (0, (0, parcelRequire("g5X3G")).toAccountId)('accountId');
    const $5f07ce956337ba8b$export$2b5f951ad8e70cfe = $5f07ce956337ba8b$var$completeJswTaskName('kanban-onboarding-task');
    const $5f07ce956337ba8b$export$5703447487aaccdf = {
        NOT_LOADED: 'NOT_LOADED',
        OPEN: 'OPEN',
        MINIMIZED: 'MINIMIZED',
        DISMISSED: 'DISMISSED'
    };
    const $5f07ce956337ba8b$export$a402f2c11da96cd7 = 'boardCreateIssueNudge';
    const $5f07ce956337ba8b$export$e0197e09cccd4e50 = 'backlogCreateSprintNudge';
    const $5f07ce956337ba8b$export$f779bbfe667acddc = [
        (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PEOPLE),
        (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_ACCESS)
    ];
    const $5f07ce956337ba8b$export$38fbac7a5bf4aa33 = [
        $5f07ce956337ba8b$export$2a82344eb273eeff,
        $5f07ce956337ba8b$export$99b0e09ff6f00490,
        $5f07ce956337ba8b$export$1de5766b7e640d6b
    ];
    const $5f07ce956337ba8b$export$7578902c7f420a3 = [
        $5f07ce956337ba8b$export$88c918f2cfaff86,
        $5f07ce956337ba8b$export$6a4507e462fd41da,
        $5f07ce956337ba8b$export$e64c1dced6ac4458,
        $5f07ce956337ba8b$export$63b0e55f9c6454ac,
        $5f07ce956337ba8b$export$c1effb1c8979fe22,
        $5f07ce956337ba8b$export$cbeb719208f32c8a,
        $5f07ce956337ba8b$export$abbbea7c37c93cb5,
        $5f07ce956337ba8b$export$2a0253cb20f48a8,
        $5f07ce956337ba8b$export$f97428bb4b1991ec,
        $5f07ce956337ba8b$export$d6c67e9aab4e2b26,
        $5f07ce956337ba8b$export$3de8c242d2b82f26,
        $5f07ce956337ba8b$export$a01fa44f793dbfac,
        $5f07ce956337ba8b$export$45eaf4204fcc7,
        $5f07ce956337ba8b$export$af57685e201438e8,
        $5f07ce956337ba8b$export$d97d31d934dbb194,
        $5f07ce956337ba8b$export$cafb5bd13b02056b,
        $5f07ce956337ba8b$export$e55c18b809946e72,
        $5f07ce956337ba8b$export$de5640b9ae8140a7,
        $5f07ce956337ba8b$export$540b5c3c4b6ce459,
        $5f07ce956337ba8b$export$6f71dff531ff592,
        $5f07ce956337ba8b$export$83255ab79e45f34f,
        $5f07ce956337ba8b$export$b57d172af9ad7647,
        $5f07ce956337ba8b$export$ff4c933c1fe90b68,
        $5f07ce956337ba8b$export$77071b77e2801b18,
        $5f07ce956337ba8b$export$94ad6507a703f4eb,
        $5f07ce956337ba8b$export$501166b25f254aab,
        $5f07ce956337ba8b$export$be1c522400a1bd7c,
        $5f07ce956337ba8b$export$2aa6e7b6e24a04f5,
        $5f07ce956337ba8b$export$f3b53199b07af2a1,
        $5f07ce956337ba8b$export$d37415ffddcf2142,
        $5f07ce956337ba8b$export$2ae00586da7cb606,
        $5f07ce956337ba8b$export$9c89f946f05d884a,
        $5f07ce956337ba8b$export$20bc3dc137ac1c38,
        $5f07ce956337ba8b$export$b52a7c936684cfc5,
        $5f07ce956337ba8b$export$b5555f24061f9fb,
        $5f07ce956337ba8b$export$ff25449f34b8abf,
        $5f07ce956337ba8b$export$3380e677209260fe,
        $5f07ce956337ba8b$export$b86266e6594a97bb,
        $5f07ce956337ba8b$export$8ab0859f5b30b013,
        $5f07ce956337ba8b$export$d1f6d18c6698104c,
        $5f07ce956337ba8b$export$e6ee159eee7adc1c,
        $5f07ce956337ba8b$export$dd267ca1e3cd3bad,
        $5f07ce956337ba8b$export$f14c8bffc35200d3,
        $5f07ce956337ba8b$export$36cc22c9997d92a,
        $5f07ce956337ba8b$export$d651cc94a46ff383,
        $5f07ce956337ba8b$export$3e2776d61f3662dc,
        $5f07ce956337ba8b$export$2b5f951ad8e70cfe,
        $5f07ce956337ba8b$export$9048315f2c03fd9d,
        $5f07ce956337ba8b$export$a333714599f14a6,
        $5f07ce956337ba8b$export$4145c2cec2da6f9b,
        $5f07ce956337ba8b$export$4957a1ad203cf6dd,
        $5f07ce956337ba8b$export$7a2fcaad1564e6b7,
        $5f07ce956337ba8b$export$190b5dd0a1322552,
        $5f07ce956337ba8b$export$da23b72d32bfad9e,
        $5f07ce956337ba8b$export$4b064659282e0238,
        $5f07ce956337ba8b$export$3ab51851f6d823e,
        $5f07ce956337ba8b$export$e4a1a37a9a1350ad,
        $5f07ce956337ba8b$export$b7f7da5e795f0327,
        $5f07ce956337ba8b$export$80a401c1aa47a377,
        $5f07ce956337ba8b$export$934ac7a251b26dc7,
        $5f07ce956337ba8b$export$4634f6395df3ebbe,
        $5f07ce956337ba8b$export$768fb41107a1e8d7,
        $5f07ce956337ba8b$export$d797a81bace17184,
        $5f07ce956337ba8b$export$746102c94db9df1f,
        $5f07ce956337ba8b$export$5f1efd797d65e349,
        $5f07ce956337ba8b$export$cccc812ccafbf11f,
        $5f07ce956337ba8b$export$64822db87abd8a47,
        $5f07ce956337ba8b$export$64f764d8be495c82,
        $5f07ce956337ba8b$export$24eae23dc1c68bbd,
        $5f07ce956337ba8b$export$50fa92eba6f82feb,
        $5f07ce956337ba8b$export$4155d22fe3f14cd6,
        $5f07ce956337ba8b$export$76813cbead887191,
        $5f07ce956337ba8b$export$90aab2ff89daf770,
        $5f07ce956337ba8b$export$a2bc0cbad2e6e605,
        $5f07ce956337ba8b$export$f90872a1280728bb,
        $5f07ce956337ba8b$export$bce644e96518cbd2,
        $5f07ce956337ba8b$export$241aaebffac0a3e5,
        $5f07ce956337ba8b$export$f9dfc43b5b31c4b7,
        $5f07ce956337ba8b$export$79759e9e62fb9312,
        $5f07ce956337ba8b$export$992d1e0dce255d19,
        $5f07ce956337ba8b$export$d28c1b0e5b3e768,
        $5f07ce956337ba8b$export$ca714ee197bed4ec,
        $5f07ce956337ba8b$export$faae55ceea4b6073,
        $5f07ce956337ba8b$export$200bc24cd120667e,
        $5f07ce956337ba8b$export$a0d784a48b4e886c,
        $5f07ce956337ba8b$export$baf5bd09b4b33b8a,
        $5f07ce956337ba8b$export$4702007eedbca8ab,
        $5f07ce956337ba8b$export$aa9d2d635ea63898,
        $5f07ce956337ba8b$export$2ea449225c1f6cac,
        $5f07ce956337ba8b$export$deb8b557e735e26a,
        $5f07ce956337ba8b$export$d45754df44e4c309,
        $5f07ce956337ba8b$export$78b2913b550edbb,
        $5f07ce956337ba8b$export$71243272608c58ab,
        $5f07ce956337ba8b$export$39a08f215c33bc39,
        $5f07ce956337ba8b$export$d2377f6a6fa3b49c,
        $5f07ce956337ba8b$export$befa8f6533309779,
        $5f07ce956337ba8b$export$736b40db8701ba6c
    ];
});
parcelRegister("cE2Qr", function(module, exports) {
    $parcel$export(module.exports, "useJswOnboarding", ()=>$796be817ff5bbbfa$export$7d91c0af4772787a);
    $parcel$export(module.exports, "useOpenTask", ()=>$796be817ff5bbbfa$export$594f006fa6a1b2e5);
    $parcel$export(module.exports, "useNudgeStep", ()=>$796be817ff5bbbfa$export$c5e63e44ad178ae8);
    $parcel$export(module.exports, "useQuickstartActions", ()=>$796be817ff5bbbfa$export$2c3cd14fff9d6e43);
    $parcel$export(module.exports, "useShouldShowNudge", ()=>$796be817ff5bbbfa$export$b0db7e46e369e1d9);
    $parcel$export(module.exports, "useInviteToProject", ()=>$796be817ff5bbbfa$export$71de79653346a1ea);
    $parcel$export(module.exports, "useShowVideoCards", ()=>$796be817ff5bbbfa$export$61185a9266f1f57a);
    $parcel$export(module.exports, "useProjectState", ()=>$796be817ff5bbbfa$export$ef4747ba36826d6a);
    $parcel$export(module.exports, "useQuickstartUiStatus", ()=>$796be817ff5bbbfa$export$522993b46d9f8266);
    $parcel$export(module.exports, "useIsLoaded", ()=>$796be817ff5bbbfa$export$aa4ba29241532382);
    $parcel$export(module.exports, "useIsFocusedOn", ()=>$796be817ff5bbbfa$export$ed5c755b733bf2b1);
    $parcel$export(module.exports, "useIsDismissed", ()=>$796be817ff5bbbfa$export$35fe0366802512dd);
    $parcel$export(module.exports, "useCompletedTaskList", ()=>$796be817ff5bbbfa$export$a41ee0d3b6938099);
    $parcel$export(module.exports, "useAllTasksCompleted", ()=>$796be817ff5bbbfa$export$4ae7b465b076be9);
    $parcel$export(module.exports, "useMountedNudges", ()=>$796be817ff5bbbfa$export$bf6e646c0476acad);
    $parcel$export(module.exports, "useHasEverSeen", ()=>$796be817ff5bbbfa$export$6b706bff94798d11);
    $parcel$export(module.exports, "useRoadmapState", ()=>$796be817ff5bbbfa$export$c98db656edc5b3e1);
    $parcel$export(module.exports, "useShouldShowScrumOnboardingExperience", ()=>$796be817ff5bbbfa$export$a823278ac9d1112b);
    $parcel$export(module.exports, "useShouldShowOnboardingKanbanTour", ()=>$796be817ff5bbbfa$export$cb9e0e8fe4df8490);
    $parcel$export(module.exports, "useHasNudgeActive", ()=>$796be817ff5bbbfa$export$521909e3dc7ab811);
    $parcel$export(module.exports, "QUICKSTART_UI_STATUS", ()=>(parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS);
    var $58GFP;
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    var $51nlc;
    var $8Bc5A;
    var $6RAg1;
    var $ieqoN;
    var $cGE52;
    var $3B5jO;
    var $4ji4w;
    const $796be817ff5bbbfa$export$390f32400eaf98c9 = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'jsw-onboarding-quickstart',
        initialState: (0, parcelRequire("cGE52")).initialState,
        actions: (0, parcelRequire("6RAg1"))
    });
    const $796be817ff5bbbfa$export$1cbc60d8dd921852 = (0, (0, parcelRequire("51nlc")).createSubscriber)($796be817ff5bbbfa$export$390f32400eaf98c9);
    const $796be817ff5bbbfa$export$7d91c0af4772787a = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9);
    const $796be817ff5bbbfa$export$638bfcfce1254bb8 = (0, (0, parcelRequire("gg7kZ")).createContainer)($796be817ff5bbbfa$export$390f32400eaf98c9, {});
    const $796be817ff5bbbfa$export$9d731f44f82af09f = (state, { itemNames: itemNames })=>{
        const commonConditionsToShowNudge = state.shouldRenderComponent && state.visibility[(0, (0, parcelRequire("3B5jO")).getQuickstartTypeSelector)(state)] === (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).OPEN && itemNames.includes(state.openTask);
        return commonConditionsToShowNudge && state.hasNudgeActive;
    };
    const $796be817ff5bbbfa$export$ea14030920a5d8e6 = (state)=>state.openTask;
    const $796be817ff5bbbfa$export$594f006fa6a1b2e5 = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: $796be817ff5bbbfa$export$ea14030920a5d8e6
    });
    const $796be817ff5bbbfa$export$a943afa434e9487a = (state)=>state.nudgeStep;
    const $796be817ff5bbbfa$export$c5e63e44ad178ae8 = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: $796be817ff5bbbfa$export$a943afa434e9487a
    });
    const $796be817ff5bbbfa$export$2c3cd14fff9d6e43 = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: null
    });
    const $796be817ff5bbbfa$export$b0db7e46e369e1d9 = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: $796be817ff5bbbfa$export$9d731f44f82af09f
    });
    const $796be817ff5bbbfa$export$71de79653346a1ea = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>Boolean(state.projectState && state.projectState.canInvitePeopleToProject)
    });
    const $796be817ff5bbbfa$export$61185a9266f1f57a = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>{
            if (!state.projectState) return false;
            const { isNextGen: isNextGen, userRole: userRole, isProjectAdmin: isProjectAdmin } = state.projectState;
            return Boolean(isNextGen && (userRole === (0, (0, parcelRequire("ieqoN")).USER_ROLE).JIRA_ADMIN || userRole === (0, (0, parcelRequire("ieqoN")).USER_ROLE).SITE_ADMIN || isProjectAdmin));
        }
    });
    const $796be817ff5bbbfa$export$ef4747ba36826d6a = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>state.projectState
    });
    const $796be817ff5bbbfa$export$522993b46d9f8266 = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>{
            const quickstartType = (0, (0, parcelRequire("3B5jO")).getQuickstartTypeSelector)(state);
            if (state.shouldRenderComponent === false) return (0, (0, parcelRequire("4ji4w")).getLocalStorageItem)(`${quickstartType}.visibility`) ?? (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).NOT_LOADED;
            return state.visibility[quickstartType];
        }
    });
    const $796be817ff5bbbfa$export$aa4ba29241532382 = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>state.isQuickstartLoaded
    });
    const $796be817ff5bbbfa$export$ed5c755b733bf2b1 = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>state.isFocusedOn
    });
    const $796be817ff5bbbfa$export$35fe0366802512dd = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>{
            const visibility = state.visibility[(0, (0, parcelRequire("3B5jO")).getQuickstartTypeSelector)(state)];
            return visibility === (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).DISMISSED || visibility === (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).NOT_LOADED;
        }
    });
    const $796be817ff5bbbfa$export$a41ee0d3b6938099 = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>state.completedTaskList
    });
    const $796be817ff5bbbfa$export$4ae7b465b076be9 = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>{
            if (!state.tasks) return null;
            return state.tasks.items.every((task)=>state.completedTaskList.includes(task));
        }
    });
    const $796be817ff5bbbfa$export$bf6e646c0476acad = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>state.mountedNudges
    });
    const $796be817ff5bbbfa$export$6b706bff94798d11 = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>state.hasEverSeenRoute
    });
    const $796be817ff5bbbfa$export$c98db656edc5b3e1 = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>({
                roadmapIsCreateEnabled: state.roadmapIsCreateEnabled,
                roadmapIsListEmpty: state.roadmapIsListEmpty
            })
    });
    const $796be817ff5bbbfa$export$a823278ac9d1112b = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>Boolean(state.isQuickstartLoaded && !state.isDevOpsProject && state.selectedProjectTemplateKeyFromOnboarding === (0, (0, parcelRequire("58GFP")).SCRUM_TEAM_MANAGED_KEY) && state.projectState?.userRole === (0, (0, parcelRequire("ieqoN")).USER_ROLE).SITE_ADMIN)
    });
    const $796be817ff5bbbfa$export$cb9e0e8fe4df8490 = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>Boolean(state.isQuickstartLoaded && !state.isDevOpsProject && state.selectedProjectTemplateKeyFromOnboarding === (0, (0, parcelRequire("58GFP")).KANBAN_TEAM_MANAGED_KEY) && state.projectState?.userRole === (0, (0, parcelRequire("ieqoN")).USER_ROLE).SITE_ADMIN && !state.completedTaskList.includes((0, (0, parcelRequire("8Bc5A")).KANBAN_ONBOARDING_TASK)))
    });
    const $796be817ff5bbbfa$export$521909e3dc7ab811 = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>state.hasNudgeActive
    });
    const $796be817ff5bbbfa$export$f4a2be940259bf95 = (0, (0, parcelRequire("3sQ5x")).createHook)($796be817ff5bbbfa$export$390f32400eaf98c9, {
        selector: (state)=>state.selectedProjectTemplateKeyFromOnboarding
    });
});
parcelRegister("6RAg1", function(module, exports) {
    $parcel$export(module.exports, "changeTaskGroupParent", ()=>(parcelRequire("6fSBR")).nextChangeTaskGroupParent);
    $parcel$export(module.exports, "goBackToPreviousTaskGroup", ()=>(parcelRequire("6fSBR")).nextGoBackToPreviousTaskGroup);
    $parcel$export(module.exports, "setIsOpen", ()=>(parcelRequire("j6wxT")).setIsOpen);
    $parcel$export(module.exports, "refreshAppState", ()=>(parcelRequire("7mZ3t")).refreshAppState);
    $parcel$export(module.exports, "setNudgeStep", ()=>(parcelRequire("8UXiA")).setNudgeStep);
    $parcel$export(module.exports, "addMountedNudge", ()=>(parcelRequire("3iEb4")).addMountedNudge);
    $parcel$export(module.exports, "removeMountedNudge", ()=>(parcelRequire("3iEb4")).removeMountedNudge);
    $parcel$export(module.exports, "setShouldRenderComponent", ()=>(parcelRequire("7yDu9")).setShouldRenderComponent);
    $parcel$export(module.exports, "setProjectState", ()=>(parcelRequire("5UBG2")).setProjectState);
    $parcel$export(module.exports, "setHasNudgeActive", ()=>(parcelRequire("lHcRB")).setHasNudgeActive);
    $parcel$export(module.exports, "forceHideNudge", ()=>(parcelRequire("eiyXc")).forceHideNudge);
    $parcel$export(module.exports, "setHasEverSeenRoute", ()=>(parcelRequire("jZvbN")).setHasEverSeenRoute);
    $parcel$export(module.exports, "setRoadmapIsCreateEnabled", ()=>(parcelRequire("azcAL")).setRoadmapIsCreateEnabled);
    $parcel$export(module.exports, "setRoadmapIsListEmpty", ()=>(parcelRequire("azcAL")).setRoadmapIsListEmpty);
    $parcel$export(module.exports, "openNextTask", ()=>(parcelRequire("gbXQ5")).openNextTask);
    $parcel$export(module.exports, "resetIsQuickstartLoaded", ()=>(parcelRequire("iZuFy")).resetIsQuickstartLoaded);
    $parcel$export(module.exports, "refreshAppStateUiStatusOnly", ()=>(parcelRequire("70Fss")).refreshAppStateUiStatusOnly);
    $parcel$export(module.exports, "nextMarkAsCompletedTask", ()=>(parcelRequire("erLQY")).nextMarkAsCompletedTask);
    $parcel$export(module.exports, "nextSetChecklistItemSeen", ()=>(parcelRequire("dQPsy")).nextSetChecklistItemSeen);
    $parcel$export(module.exports, "nextSetChecklistIsDismissed", ()=>(parcelRequire("abQXL")).nextSetChecklistIsDismissed);
    $parcel$export(module.exports, "nextSetChecklistIsOpen", ()=>(parcelRequire("4VXxO")).nextSetChecklistIsOpen);
    $parcel$export(module.exports, "nextOpenTask", ()=>(parcelRequire("cUGWT")).nextOpenTask);
    $parcel$export(module.exports, "nextAutoExpand", ()=>(parcelRequire("boV1S")).nextAutoExpand);
    $parcel$export(module.exports, "nextShouldShowNudgeAfterNavigation", ()=>(parcelRequire("4Viq4")).nextShouldShowNudgeAfterNavigation);
    $parcel$export(module.exports, "nextGetTasks", ()=>(parcelRequire("jIoq4")).nextGetTasks);
    $parcel$export(module.exports, "syncVisibilityWithLocal", ()=>(parcelRequire("75A1x")).syncVisibilityWithLocal);
    var $6fSBR;
    var $j6wxT;
    var $7mZ3t;
    var $8UXiA;
    var $3iEb4;
    var $7yDu9;
    var $5UBG2;
    var $lHcRB;
    var $eiyXc;
    var $jZvbN;
    var $azcAL;
    var $gbXQ5;
    var $iZuFy;
    var $70Fss;
    var $erLQY;
    var $dQPsy;
    var $abQXL;
    var $4VXxO;
    var $cUGWT;
    var $boV1S;
    var $4Viq4;
    var $jIoq4;
    var $75A1x;
});
parcelRegister("6fSBR", function(module, exports) {
    $parcel$export(module.exports, "nextChangeTaskGroupParent", ()=>$0f1566e1d3fc2987$export$76708cce5888039a);
    $parcel$export(module.exports, "nextGoBackToPreviousTaskGroup", ()=>$0f1566e1d3fc2987$export$57534cbc400a434c);
    var $8Bc5A;
    var $1ftPQ;
    const $0f1566e1d3fc2987$var$setTaskGroup = (taskGroup)=>({ setState: setState })=>{
            setState({
                ...taskGroup,
                openTask: ''
            });
            (0, (0, parcelRequire("1ftPQ")).pushToBackend)({
                key: (0, (0, parcelRequire("8Bc5A")).KEY_TASK_GROUP),
                value: {
                    taskGroup: taskGroup
                }
            });
        };
    const $0f1566e1d3fc2987$export$76708cce5888039a = (newCurrentTaskGroupParent)=>({ getState: getState, dispatch: dispatch })=>{
            const { previousTaskGroupParents: previousTaskGroupParents, currentTaskGroupParent: currentTaskGroupParent } = getState();
            if (previousTaskGroupParents.includes(newCurrentTaskGroupParent)) return;
            previousTaskGroupParents.push(currentTaskGroupParent);
            const taskGroup = {
                previousTaskGroupParents: previousTaskGroupParents,
                currentTaskGroupParent: newCurrentTaskGroupParent
            };
            dispatch($0f1566e1d3fc2987$var$setTaskGroup(taskGroup));
        };
    const $0f1566e1d3fc2987$export$57534cbc400a434c = ()=>({ dispatch: dispatch, getState: getState })=>{
            const { previousTaskGroupParents: previousTaskGroupParents } = getState();
            const newTaskGroupParent = previousTaskGroupParents.length === 0 ? (0, (0, parcelRequire("8Bc5A")).ROOT_TASK) : previousTaskGroupParents.pop();
            const taskGroup = {
                previousTaskGroupParents: previousTaskGroupParents,
                currentTaskGroupParent: newTaskGroupParent
            };
            dispatch($0f1566e1d3fc2987$var$setTaskGroup(taskGroup));
        };
});
parcelRegister("1ftPQ", function(module, exports) {
    $parcel$export(module.exports, "pushToBackend", ()=>$d3dd40bf54ad27bf$export$583b2d2422a6757f);
    var $89SaW;
    var $4Pf62;
    var $jcw0u;
    var $jJZqs;
    const $d3dd40bf54ad27bf$var$getAccountId = ()=>{
        const { atlassianAccountId: atlassianAccountId } = (0, (0, parcelRequire("4Pf62")).getTenantContext_DEPRECATED_DO_NOT_USE)();
        if (atlassianAccountId === null) throw new Error('Atlassian Account Id is empty');
        return atlassianAccountId;
    };
    const $d3dd40bf54ad27bf$var$setRemoteUserProperties = async ({ key: key, value: value })=>(0, (0, parcelRequire("89SaW")).setUserProperties)($d3dd40bf54ad27bf$var$getAccountId(), key, JSON.stringify(value)).then((response)=>{
            if (!response.ok) throw new (0, (0, parcelRequire("jJZqs")).default)(response.status, response.statusText);
        });
    const $d3dd40bf54ad27bf$var$trySetUserProperties = async (props, attemptCount)=>{
        try {
            await $d3dd40bf54ad27bf$var$setRemoteUserProperties(props);
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: 'pushToBackend',
                    packageName: 'jiraOnboardingQuickstartCore',
                    teamName: 'jlove-makkuro'
                },
                attributes: {
                    action: 'pushToBackend',
                    actionSubject: 'jiraOnboardingChecklistCore',
                    attemptCount: attemptCount,
                    key: props.key,
                    reason: error && error.message
                },
                error: error
            });
            return false;
        }
        return true;
    };
    const $d3dd40bf54ad27bf$export$583b2d2422a6757f = async (props)=>{
        const success = await $d3dd40bf54ad27bf$var$trySetUserProperties(props, 1);
        if (!success) await $d3dd40bf54ad27bf$var$trySetUserProperties(props, 2);
    };
});
parcelRegister("j6wxT", function(module, exports) {
    $parcel$export(module.exports, "setIsOpen", ()=>$eefde484654068e9$export$dcf23146e0a40195);
    var $8Bc5A;
    var $1ftPQ;
    var $3B5jO;
    const $eefde484654068e9$export$dcf23146e0a40195 = (isOpen)=>({ getState: getState, setState: setState })=>{
            const state = getState();
            const quickstartType = (0, (0, parcelRequire("3B5jO")).getQuickstartTypeSelector)(state);
            const currentIsOpen = state.visibility[quickstartType] === (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).OPEN;
            if (isOpen === currentIsOpen) return;
            const quickstartStatus = isOpen ? (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).OPEN : (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).MINIMIZED;
            setState({
                visibility: {
                    ...state.visibility,
                    [quickstartType]: quickstartStatus
                }
            });
            (0, (0, parcelRequire("1ftPQ")).pushToBackend)({
                key: (0, (0, parcelRequire("8Bc5A")).getBackendVisibilityKey)(quickstartType),
                value: quickstartStatus
            });
            (0, (0, parcelRequire("1ftPQ")).pushToBackend)({
                key: (0, (0, parcelRequire("8Bc5A")).KEY_OPEN),
                value: isOpen
            });
        };
    var $eefde484654068e9$export$2e2bcd8739ae039 = $eefde484654068e9$export$dcf23146e0a40195;
});
parcelRegister("3B5jO", function(module, exports) {
    $parcel$export(module.exports, "getQuickstartTypeSelector", ()=>$47424244d57f6abc$export$eae834ce0286017a);
    var $2vSU6;
    var $8Bc5A;
    const $47424244d57f6abc$export$6f17f2cb816105eb = (state)=>state.projectState?.projectType === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT);
    const $47424244d57f6abc$export$eae834ce0286017a = (state)=>{
        if ($47424244d57f6abc$export$6f17f2cb816105eb(state)) return (0, (0, parcelRequire("8Bc5A")).QUICKSTART_TYPE).JSM_BUSINESS_QUICKSTART_KEY;
        return (0, (0, parcelRequire("8Bc5A")).QUICKSTART_TYPE).OLD_GLOBAL_QUICKSTART_KEY;
    };
});
parcelRegister("7mZ3t", function(module, exports) {
    $parcel$export(module.exports, "refreshAppState", ()=>$ecf6e94ad9c4f8d6$export$797c35e2e1d0d014);
    var $4R6GH;
    var $8fqy8;
    var $8zOmE;
    var $bfWlw;
    var $ldyPw;
    var $8Bc5A;
    var $6yhDy;
    var $1ftPQ;
    var $52Vcw;
    var $ljzlb;
    var $3ORcf;
    var $6ypzr;
    var $4ji4w;
    var $abQXL;
    var $4VXxO;
    var $erLQY;
    var $6fSBR;
    const $ecf6e94ad9c4f8d6$var$allTasks = [
        ...(0, (0, parcelRequire("8Bc5A")).allTasksExcludingJSMBusinessTasks),
        ...(0, (0, parcelRequire("8Bc5A")).JSM_BUSINESS_TASKS)
    ];
    const $ecf6e94ad9c4f8d6$export$797c35e2e1d0d014 = ({ atlassianAccountId: atlassianAccountId, cloudId: cloudId, appEditions: appEditions, isAdmin: isAdmin, canEditPlans: canEditPlans, isAdvancedRoadmapsTrial: isAdvancedRoadmapsTrial, isTargetedForReverseTrials: isTargetedForReverseTrials, createAnalyticsEvent: createAnalyticsEvent, projectType: projectType })=>async ({ getState: getState, setState: setState, dispatch: dispatch })=>{
            const updatedState = {};
            const hasSeenTasks = [
                (0, (0, parcelRequire("8Bc5A")).convertToHaveSeenItTask)((0, (0, parcelRequire("8Bc5A")).PREMIUM_EDITION_OVERVIEW_TASK)),
                (0, (0, parcelRequire("8Bc5A")).convertToHaveSeenItTask)((0, (0, parcelRequire("8Bc5A")).PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN)),
                (0, (0, parcelRequire("8Bc5A")).convertToHaveSeenItTask)((0, (0, parcelRequire("8Bc5A")).STANDARD_EDITION_OVERVIEW_TASK))
            ];
            const { projectState: projectState = null } = getState();
            updatedState.isOpen = true;
            updatedState.isDismissed = false;
            updatedState.quickstartStateProjectType = projectType;
            let backendVisibilityKeys = [];
            let jsmTaskProjectKeys = [];
            updatedState.visibility = Object.values((0, (0, parcelRequire("8Bc5A")).QUICKSTART_TYPE)).reduce((acc, type)=>{
                acc[type] = (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).OPEN;
                return acc;
            }, {});
            backendVisibilityKeys = Object.values((0, (0, parcelRequire("8Bc5A")).QUICKSTART_TYPE)).map((type)=>(0, (0, parcelRequire("8Bc5A")).getBackendVisibilityKey)(type));
            if (projectState?.projectId) jsmTaskProjectKeys = (0, (0, parcelRequire("8Bc5A")).JSM_BUSINESS_TASKS).map((task)=>`${task}.${projectState?.projectId}`);
            const backendState = await (0, (0, parcelRequire("6yhDy")).pollBackend)({
                keys: [
                    (0, (0, parcelRequire("8Bc5A")).KEY_OPEN),
                    (0, (0, parcelRequire("8Bc5A")).KEY_DISMISSED),
                    (0, (0, parcelRequire("8Bc5A")).KEY_OPEN_TASK),
                    (0, (0, parcelRequire("8Bc5A")).KEY_TASK_GROUP),
                    (0, (0, parcelRequire("8Bc5A")).KEY_HAS_EVER_SEEN_ROUTE),
                    (0, (0, parcelRequire("8Bc5A")).KEY_JSW_ONBOARDING),
                    (0, (0, parcelRequire("8Bc5A")).KEY_PREMIUM_TRIAL_REOPENED),
                    ...$ecf6e94ad9c4f8d6$var$allTasks,
                    ...hasSeenTasks,
                    ...backendVisibilityKeys,
                    ...jsmTaskProjectKeys
                ]
            });
            if (projectState && projectState.projectKey) updatedState.isDevOpsProject = await (0, (0, parcelRequire("3ORcf")).getIsDevOpsProject)(projectState.projectKey);
            const backendStateExists = backendState !== undefined && Object.keys(backendState).length > 0;
            if (backendStateExists) {
                updatedState.completedTaskList = Object.keys(backendState).filter((key)=>[
                        ...(0, (0, parcelRequire("8Bc5A")).allTasksExcludingJSMBusinessTasks),
                        ...jsmTaskProjectKeys
                    ].includes(key) && backendState[key] === true);
                updatedState.hasSeenTasks = hasSeenTasks.filter((key)=>backendState[key] === true);
                Object.values((0, (0, parcelRequire("8Bc5A")).QUICKSTART_TYPE)).forEach((type)=>{
                    if ((0, (0, parcelRequire("8Bc5A")).getBackendVisibilityKey)(type) in backendState) updatedState.visibility[type] = backendState[(0, (0, parcelRequire("8Bc5A")).getBackendVisibilityKey)(type)];
                    else if ((0, (0, parcelRequire("8Bc5A")).KEY_DISMISSED) in backendState && backendState[0, (0, parcelRequire("8Bc5A")).KEY_DISMISSED]) updatedState.visibility[type] = (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).DISMISSED;
                    else if ((0, (0, parcelRequire("8Bc5A")).KEY_OPEN) in backendState) updatedState.visibility[type] = backendState[0, (0, parcelRequire("8Bc5A")).KEY_OPEN] ? (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).OPEN : (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).MINIMIZED;
                    (0, (0, parcelRequire("4ji4w")).setLocalStorageItem)(`${type}.visibility`, updatedState.visibility[type]);
                });
                if ((0, (0, parcelRequire("8Bc5A")).KEY_DISMISSED) in backendState) updatedState.isDismissed = backendState[0, (0, parcelRequire("8Bc5A")).KEY_DISMISSED];
                if ((0, (0, parcelRequire("8Bc5A")).KEY_OPEN) in backendState) updatedState.isOpen = backendState[0, (0, parcelRequire("8Bc5A")).KEY_OPEN];
                if ((0, (0, parcelRequire("8Bc5A")).KEY_OPEN_TASK) in backendState) updatedState.openTask = backendState[0, (0, parcelRequire("8Bc5A")).KEY_OPEN_TASK];
                if ((0, (0, parcelRequire("8Bc5A")).KEY_HAS_EVER_SEEN_ROUTE) in backendState) updatedState.hasEverSeenRoute = backendState[0, (0, parcelRequire("8Bc5A")).KEY_HAS_EVER_SEEN_ROUTE];
                if ((0, (0, parcelRequire("8Bc5A")).KEY_TASK_GROUP) in backendState) {
                    updatedState.currentTaskGroupParent = backendState[0, (0, parcelRequire("8Bc5A")).KEY_TASK_GROUP].taskGroup.currentTaskGroupParent;
                    updatedState.previousTaskGroupParents = backendState[0, (0, parcelRequire("8Bc5A")).KEY_TASK_GROUP].taskGroup.previousTaskGroupParents;
                }
                if ((0, (0, parcelRequire("8Bc5A")).KEY_JSW_ONBOARDING) in backendState) {
                    if (typeof backendState[0, (0, parcelRequire("8Bc5A")).KEY_JSW_ONBOARDING] === 'object' && backendState[0, (0, parcelRequire("8Bc5A")).KEY_JSW_ONBOARDING] !== null && (0, $6784cbc7d62e5d7c$export$8a00ecf6498ee2fe) in backendState[0, (0, parcelRequire("8Bc5A")).KEY_JSW_ONBOARDING]) updatedState.selectedProjectTemplateKeyFromOnboarding = backendState[0, (0, parcelRequire("8Bc5A")).KEY_JSW_ONBOARDING][0, $6784cbc7d62e5d7c$export$8a00ecf6498ee2fe];
                }
            }
            setState(updatedState);
            if ((updatedState.currentTaskGroupParent === (0, (0, parcelRequire("8Bc5A")).PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN) || updatedState.currentTaskGroupParent === (0, (0, parcelRequire("8Bc5A")).PREMIUM_REVERSE_TRIAL_TASK)) && !canEditPlans || (updatedState.currentTaskGroupParent === (0, (0, parcelRequire("8Bc5A")).PREMIUM_EDITION_OVERVIEW_TASK) || updatedState.currentTaskGroupParent === (0, (0, parcelRequire("8Bc5A")).PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN) || updatedState.currentTaskGroupParent === (0, (0, parcelRequire("8Bc5A")).PREMIUM_REVERSE_TRIAL_TASK)) && appEditions.software !== (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION)) {
                dispatch((0, (0, parcelRequire("6fSBR")).nextGoBackToPreviousTaskGroup)());
                const analyticsEvent = createAnalyticsEvent({});
                (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(analyticsEvent, 'refreshAppState premiumCardPermissionsRevoked');
            }
            if (!(backendStateExists && (0, (0, parcelRequire("8Bc5A")).KEY_OPEN) in backendState)) try {
                const skipApplicationProperty = await (0, (0, parcelRequire("6ypzr")).getOnboardingSkipApplicationProperty)();
                if (skipApplicationProperty.quickstart) dispatch((0, (0, parcelRequire("abQXL")).nextSetChecklistIsDismissed)(true));
            } catch (error) {
                (0, (0, parcelRequire("52Vcw")).fireAPIErrorAnalytics)(error, 'skipOnboarding');
            }
            const jswTrackDeferSitenameSiteTrait = await (0, (0, parcelRequire("8fqy8")).getJswTrackDeferSitenameSiteTrait)(cloudId, {
                packageName: (0, (0, parcelRequire("8Bc5A")).PACKAGE_NAME),
                teamName: (0, (0, parcelRequire("8Bc5A")).PACKAGE_TEAM_NAME)
            });
            setState({
                isEligibleToUpdateSitename: jswTrackDeferSitenameSiteTrait?.value === 'experiment-generated-site-name'
            });
            const setPremiumCardAudienceTrait = async (accountId)=>{
                if (accountId === null) return;
                const trait = await (0, (0, parcelRequire("ljzlb")).getCrossProjectBoardTrait)(createAnalyticsEvent, cloudId, accountId);
                setState({
                    isCrossProjectBoardToPlanUpsell: trait?.value === true || false
                });
            };
            if (appEditions.software === (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION) && !isAdmin) await setPremiumCardAudienceTrait(atlassianAccountId);
            const { isCrossProjectBoardToPlanUpsell: isCrossProjectBoardToPlanUpsell } = getState();
            let isReverseTrialForJSW;
            if (isTargetedForReverseTrials && (0, (0, parcelRequire("4R6GH")).fg)('jsw_reverse_trials_feature_gate')) {
                const isOnboardingPremiumTrial = await (0, (0, parcelRequire("bfWlw")).getOnboardingPremiumTrial)();
                isReverseTrialForJSW = isOnboardingPremiumTrial?.premiumTrialOnSignup;
            }
            if (!isReverseTrialForJSW && appEditions.software === (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION) && isAdvancedRoadmapsTrial && (isAdmin || isCrossProjectBoardToPlanUpsell) && atlassianAccountId != null && !backendState[0, (0, parcelRequire("8Bc5A")).KEY_PREMIUM_TRIAL_REOPENED]) {
                dispatch((0, (0, parcelRequire("4VXxO")).nextSetChecklistIsOpen)(true));
                dispatch((0, (0, parcelRequire("abQXL")).nextSetChecklistIsDismissed)(false));
                const taskToDispatch = isAdmin ? (0, (0, parcelRequire("8Bc5A")).PREMIUM_EDITION_OVERVIEW_TASK) : (0, (0, parcelRequire("8Bc5A")).PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN);
                dispatch((0, (0, parcelRequire("erLQY")).nextMarkAsCompletedTask)(taskToDispatch));
                dispatch((0, (0, parcelRequire("6fSBR")).nextChangeTaskGroupParent)(taskToDispatch));
                await (0, (0, parcelRequire("1ftPQ")).pushToBackend)({
                    key: (0, (0, parcelRequire("8Bc5A")).KEY_PREMIUM_TRIAL_REOPENED),
                    value: true
                });
                const analyticsEvent = createAnalyticsEvent({});
                (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(analyticsEvent, 'advancedRoadmapsTask reopened', {
                    isAdmin: isAdmin
                });
            }
            setState({
                canEditPlans: canEditPlans
            });
            setState({
                isQuickstartLoaded: true
            });
        };
});
parcelRegister("8fqy8", function(module, exports) {
    $parcel$export(module.exports, "getSignupTrackDeferSitenameUserTrait", ()=>$77364531dfabe700$export$686752df505238be);
    $parcel$export(module.exports, "getJswTrackDeferSitenameSiteTrait", ()=>$77364531dfabe700$export$6da79f996c267010);
    var $jcw0u;
    var $eusud;
    const $77364531dfabe700$var$PERSONALIZATION_ENDPOINT = '/gateway/api/tap-delivery/api/v3/personalization';
    const $77364531dfabe700$var$SITE_PERSONALIZATION_ENDPOINT = `${$77364531dfabe700$var$PERSONALIZATION_ENDPOINT}/site/`;
    const $77364531dfabe700$var$USER_PERSONALIZATION_ENDPOINT = `${$77364531dfabe700$var$PERSONALIZATION_ENDPOINT}/user/`;
    const $77364531dfabe700$var$SIGNUP_TRACK_DEFER_SITENAME_USER_TRAIT = 'marketing-signup_track_deferred-sitename_signup_server_enrollment';
    const $77364531dfabe700$var$JSW_TRACK_DEFER_SITENAME_TRAIT = 'jira_track_instance_created_server_defer_site_rename_cohort';
    const $77364531dfabe700$var$fetchPersonalization = (endpoint, subject)=>async (id, meta)=>{
            try {
                const url = endpoint + id;
                return await (0, (0, parcelRequire("eusud")).performGetRequest)(url);
            } catch (error) {
                (0, (0, parcelRequire("jcw0u")).default)({
                    meta: {
                        id: 'onboarding',
                        teamName: 'jlove-makkuro',
                        ...meta
                    },
                    attributes: {
                        subject: subject
                    },
                    error: error
                });
                return undefined;
            }
        };
    const $77364531dfabe700$var$getTraits = (fetch, traitName)=>async (id, meta)=>{
            const personalization = await fetch(id, meta);
            return personalization?.attributes?.find((att)=>att.name === traitName);
        };
    const $77364531dfabe700$export$686752df505238be = $77364531dfabe700$var$getTraits($77364531dfabe700$var$fetchPersonalization($77364531dfabe700$var$USER_PERSONALIZATION_ENDPOINT, 'fetchUserPersonalization'), $77364531dfabe700$var$SIGNUP_TRACK_DEFER_SITENAME_USER_TRAIT);
    const $77364531dfabe700$export$6da79f996c267010 = $77364531dfabe700$var$getTraits($77364531dfabe700$var$fetchPersonalization($77364531dfabe700$var$SITE_PERSONALIZATION_ENDPOINT, 'fetchSitePersonalization'), $77364531dfabe700$var$JSW_TRACK_DEFER_SITENAME_TRAIT);
});
parcelRegister("6yhDy", function(module, exports) {
    $parcel$export(module.exports, "pollBackend", ()=>$760653e5a4f27459$export$8420ef0cee98de);
    var $1sYcL;
    var $g5X3G;
    var $8Bc5A;
    const $760653e5a4f27459$export$8420ef0cee98de = async ({ keys: keys })=>{
        const { user: user } = await (0, (0, parcelRequire("1sYcL")).get)((0, (0, parcelRequire("g5X3G")).toBaseUrl)(''), {
            userProperties: keys,
            consumerMeta: {
                id: 'fetchUserProperties',
                packageName: (0, (0, parcelRequire("8Bc5A")).PACKAGE_NAME),
                teamName: (0, (0, parcelRequire("8Bc5A")).PACKAGE_TEAM_NAME)
            }
        });
        return user;
    };
});
parcelRegister("1sYcL", function(module, exports) {
    $parcel$export(module.exports, "get", ()=>$7fc92c544211ac93$export$3988ae62b71be9a3);
    var $lbWm4 = parcelRequire("lbWm4");
    var $3T6Xo = parcelRequire("3T6Xo");
    var $jcw0u;
    var $4R6GH;
    var $fZneA;
    var $eusud;
    var $fjcgS;
    var $atdLB;
    var $duUGa;
    let $7fc92c544211ac93$var$ufoExperiencesMap = {};
    const $7fc92c544211ac93$var$getUfoExperience = (key)=>{
        if (!$7fc92c544211ac93$var$ufoExperiencesMap[key]) $7fc92c544211ac93$var$ufoExperiencesMap[key] = new (0, (0, parcelRequire("duUGa")).UFOExperience)(key, {
            type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Operation,
            performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).Custom
        });
        return $7fc92c544211ac93$var$ufoExperiencesMap[key];
    };
    const $7fc92c544211ac93$export$c907f169d33b2e25 = ()=>{
        $7fc92c544211ac93$var$ufoExperiencesMap = {};
    };
    const $7fc92c544211ac93$export$a5b7a8c3b1c6e775 = (baseUrl, { userProperties: userProperties, projectId: projectId, projectProperties: projectProperties, instanceProperties: instanceProperties })=>{
        const params = new URLSearchParams();
        userProperties && params.append('user', userProperties.join(','));
        projectId !== undefined && params.append('projectId', projectId);
        projectProperties && params.append('project', projectProperties.join(','));
        instanceProperties && params.append('instance', instanceProperties.join(','));
        return `/rest/internal/latest/frontendconfig/properties?${params.toString()}`;
    };
    const $7fc92c544211ac93$var$patchProperties = (response, propertiesPath)=>((0, (0, parcelRequire("4R6GH")).fg)('handle_null_response_for_fe_config_properties_call') ? response : true) && (0, ($parcel$interopDefault($lbWm4)))(response[propertiesPath], (value, prop)=>{
            try {
                response[propertiesPath][prop] = JSON.parse(value);
            } catch (error) {
                if (prop === 'initial.project.template.name') response[propertiesPath][prop] = value;
                else throw error;
            }
        });
    const $7fc92c544211ac93$export$3988ae62b71be9a3 = async (baseUrl, options)=>{
        const ufoExperience = $7fc92c544211ac93$var$getUfoExperience(`${(0, ($parcel$interopDefault($3T6Xo)))(options.consumerMeta.packageName)}.${(0, ($parcel$interopDefault($3T6Xo)))(options.consumerMeta.id)}`);
        try {
            ufoExperience?.start();
            const url = $7fc92c544211ac93$export$a5b7a8c3b1c6e775(baseUrl, options);
            const response = await (0, (0, parcelRequire("eusud")).performGetRequest)(url);
            if (response === null) (0, (0, parcelRequire("fjcgS")).getAnalyticsWebClientPromise)().then((client)=>{
                const payload = {
                    action: 'succeeded',
                    actionSubject: 'jiraCommonRestInternalLatest.getConfigProperties',
                    source: 'frontendConfigProperties',
                    attributes: {
                        package: consumerMeta.packageName,
                        id: consumerMeta.id,
                        isNullResponse: true
                    }
                };
                client.getInstance().sendOperationalEvent(payload);
            });
            $7fc92c544211ac93$var$patchProperties(response, 'user');
            $7fc92c544211ac93$var$patchProperties(response, 'instance');
            $7fc92c544211ac93$var$patchProperties(response, 'project');
            const { consumerMeta: consumerMeta } = options;
            (0, (0, parcelRequire("fjcgS")).getAnalyticsWebClientPromise)().then((client)=>{
                const payload = {
                    source: 'frontendConfigProperties',
                    action: 'success',
                    actionSubject: `${consumerMeta.packageName}.${consumerMeta.id}`
                };
                client.getInstance().sendOperationalEvent(payload);
            });
            ufoExperience?.success();
            return (0, (0, parcelRequire("4R6GH")).fg)('handle_null_response_for_fe_config_properties_call') ? response || {
                user: {},
                instance: {},
                project: {}
            } : response;
        } catch (error) {
            const { consumerMeta: consumerMeta } = options;
            !(0, (0, parcelRequire("fZneA")).isClientFetchError)(error) && (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: consumerMeta.id,
                    packageName: consumerMeta.packageName,
                    teamName: consumerMeta.teamName
                },
                sendToPrivacyUnsafeSplunk: true
            });
            ufoExperience?.failure();
            throw error;
        }
    };
});
parcelRegister("52Vcw", function(module, exports) {
    $parcel$export(module.exports, "fireAnalyticsOnQuickstartClose", ()=>$5dab66e6da9554ae$export$c98a6ee6aadb8055);
    $parcel$export(module.exports, "fireAnalyticsOnQuickstartDismiss", ()=>$5dab66e6da9554ae$export$45d8c76e0490b3ec);
    $parcel$export(module.exports, "fireAnalyticsOnQuickstartBack", ()=>$5dab66e6da9554ae$export$b1be4ae35a43115d);
    $parcel$export(module.exports, "fireAnalyticsOnQuickstartExperienceComplete", ()=>$5dab66e6da9554ae$export$fa4f266bd2444d14);
    $parcel$export(module.exports, "fireAnalyticsOnChecklistItemCompleted", ()=>$5dab66e6da9554ae$export$a68a2e5a69c8cd74);
    $parcel$export(module.exports, "fireAPIErrorAnalytics", ()=>$5dab66e6da9554ae$export$a4d7f1a52677d339);
    $parcel$export(module.exports, "fireAnalyticsOnOpenRightHandSidebar", ()=>$5dab66e6da9554ae$export$160bb78a3b11a5a9);
    $parcel$export(module.exports, "fireAnalyticsOnMinimiseRightHandSidebar", ()=>$5dab66e6da9554ae$export$6ccbe54cd3a5a12);
    $parcel$export(module.exports, "fireAnalyticsOnCloseRightHandSidebar", ()=>$5dab66e6da9554ae$export$6c39ca65ca354e59);
    var $jcw0u;
    var $8zOmE;
    var $8Bc5A;
    var $izv86;
    const $5dab66e6da9554ae$export$c98a6ee6aadb8055 = (analyticsEvent, attributes)=>{
        analyticsEvent.update({
            action: 'closed',
            actionSubject: 'button'
        });
        (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'button closed', (0, (0, parcelRequire("8Bc5A")).ACTION_SUBJECT_ID), attributes);
    };
    const $5dab66e6da9554ae$export$45d8c76e0490b3ec = (analyticsEvent, attributes, source)=>{
        if (source === 'button') (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'button clicked', 'dismissQuickstartButton', attributes);
        else if (source === 'checklist') (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'button clicked', 'dismissQuickstart', attributes);
    };
    const $5dab66e6da9554ae$export$b1be4ae35a43115d = (analyticsEvent)=>{
        (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'button clicked', 'backButton');
    };
    const $5dab66e6da9554ae$export$fa4f266bd2444d14 = (createAnalyticsEvent, attributes)=>{
        const analyticsEvent = createAnalyticsEvent({});
        (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(analyticsEvent, 'experience completed', (0, (0, parcelRequire("8Bc5A")).ACTION_SUBJECT_ID), {
            ...attributes
        });
    };
    const $5dab66e6da9554ae$export$a68a2e5a69c8cd74 = (createAnalyticsEvent, completedTaskId)=>{
        const analyticsEvent = createAnalyticsEvent({});
        (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(analyticsEvent, 'checklistItem completed', (0, (0, parcelRequire("izv86")).convertTaskIdToActionSubjectId)(completedTaskId), {
            taskId: completedTaskId
        });
    };
    const $5dab66e6da9554ae$export$a4d7f1a52677d339 = (error, endpointSource)=>{
        (0, (0, parcelRequire("jcw0u")).default)({
            error: error,
            meta: {
                id: 'quickstartApiError',
                packageName: 'jiraOnboardingQuickstartCore',
                teamName: 'jlove-makkuro'
            },
            attributes: {
                error: error.message,
                endpointSource: endpointSource
            }
        });
    };
    const $5dab66e6da9554ae$export$160bb78a3b11a5a9 = (createAnalyticsEvent, attributes)=>{
        const analyticsEvent = createAnalyticsEvent({});
        (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(analyticsEvent, 'nuxOnboardingQuickstart openRightSidebar', (0, (0, parcelRequire("8Bc5A")).ACTION_SUBJECT_ID), {
            ...attributes
        });
    };
    const $5dab66e6da9554ae$export$6ccbe54cd3a5a12 = (createAnalyticsEvent, attributes)=>{
        const analyticsEvent = createAnalyticsEvent({});
        (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(analyticsEvent, 'nuxOnboardingQuickstart minimiseRightSidebar', (0, (0, parcelRequire("8Bc5A")).ACTION_SUBJECT_ID), {
            ...attributes
        });
    };
    const $5dab66e6da9554ae$export$6c39ca65ca354e59 = (createAnalyticsEvent, attributes)=>{
        const analyticsEvent = createAnalyticsEvent({});
        (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(analyticsEvent, 'nuxOnboardingQuickstart closeRightSidebar', (0, (0, parcelRequire("8Bc5A")).ACTION_SUBJECT_ID), {
            ...attributes
        });
    };
});
parcelRegister("izv86", function(module, exports) {
    $parcel$export(module.exports, "getProductType", ()=>$4725f5ccfabd2231$export$27e53caf66bae1d);
    $parcel$export(module.exports, "convertTaskIdToActionSubjectId", ()=>$4725f5ccfabd2231$export$3b914201960fa7a6);
    var $e4eHl = parcelRequire("e4eHl");
    var $2vSU6;
    const $4725f5ccfabd2231$export$d64a7dd8c7cd80e0 = {
        [(0, (0, parcelRequire("2vSU6")).CORE_PROJECT)]: 'workManagement',
        [(0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT)]: 'software',
        [(0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT)]: 'serviceManagement'
    };
    const $4725f5ccfabd2231$export$27e53caf66bae1d = (projectType)=>{
        if (projectType) return $4725f5ccfabd2231$export$d64a7dd8c7cd80e0[projectType] || (0, ($parcel$interopDefault($e4eHl)))(projectType);
        return null;
    };
    const $4725f5ccfabd2231$export$3b914201960fa7a6 = (taskId)=>{
        if (!taskId) return '';
        return (0, ($parcel$interopDefault($e4eHl)))(taskId.split('.').pop());
    };
});
parcelRegister("ljzlb", function(module, exports) {
    $parcel$export(module.exports, "getCrossProjectBoardTrait", ()=>$9217b5bb53dd29c2$export$7f2186c37a3d095d);
    var $jcw0u;
    var $co1wz;
    var $eusud;
    var $8zOmE;
    var $8Bc5A;
    var $k9OK8;
    const $9217b5bb53dd29c2$var$CROSS_PROJECT_BOARD_TO_PLAN_UPSELL_TRAIT = 'segment_jsw_cross_project_board_trait_siteuser';
    const $9217b5bb53dd29c2$var$TAP_DELIVERY_PROXY_ENDPOINT = '/gateway/api/tap-delivery/api/v3/personalization';
    const $9217b5bb53dd29c2$var$ONE_DAY = 86400000;
    const $9217b5bb53dd29c2$export$88f2f25e1418caf1 = async (createAnalyticsEvent, siteId, userId)=>{
        try {
            const url = `${$9217b5bb53dd29c2$var$TAP_DELIVERY_PROXY_ENDPOINT}/site/${siteId}/user/${userId}`;
            const result = await (0, (0, parcelRequire("eusud")).performGetRequest)(url);
            const analyticsEvent = createAnalyticsEvent({});
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(analyticsEvent, 'getJSWCrossProjectBoardTrait personalizationApi', {
                synthetic: (0, (0, parcelRequire("co1wz")).ff)('jsw.pollinator.tenants')
            });
            return result;
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: 'quickstartApiError',
                    packageName: (0, (0, parcelRequire("8Bc5A")).PACKAGE_NAME),
                    teamName: (0, (0, parcelRequire("8Bc5A")).PACKAGE_TEAM_NAME)
                },
                attributes: {
                    error: error.message,
                    endpointSource: 'getJSWCrossProjectBoardTrait',
                    synthetic: (0, (0, parcelRequire("co1wz")).ff)('jsw.pollinator.tenants')
                },
                sendToPrivacyUnsafeSplunk: true
            });
            return {
                attributes: []
            };
        }
    };
    const $9217b5bb53dd29c2$export$7f2186c37a3d095d = async (createAnalyticsEvent, siteId, userId)=>{
        const { get: get, persist: persist } = (0, (0, parcelRequire("k9OK8")).crossProjectBoardTraitSessionStorageProvider)();
        const sessionStorage = get();
        if (sessionStorage && userId === sessionStorage.userId && siteId === sessionStorage.siteId && Date.now() - sessionStorage.date < $9217b5bb53dd29c2$var$ONE_DAY) {
            const analyticsEvent = createAnalyticsEvent({});
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(analyticsEvent, 'getJSWCrossProjectBoardTrait sessionStorage', {
                synthetic: (0, (0, parcelRequire("co1wz")).ff)('jsw.pollinator.tenants')
            });
            return sessionStorage.attribute;
        }
        const personalization = await $9217b5bb53dd29c2$export$88f2f25e1418caf1(createAnalyticsEvent, siteId, userId);
        const attribute = personalization.attributes.find((att)=>att.name === $9217b5bb53dd29c2$var$CROSS_PROJECT_BOARD_TO_PLAN_UPSELL_TRAIT);
        if (attribute !== undefined) persist({
            attribute: attribute,
            date: Date.now(),
            userId: userId,
            siteId: siteId
        });
        return attribute;
    };
});
parcelRegister("k9OK8", function(module, exports) {
    $parcel$export(module.exports, "crossProjectBoardTraitSessionStorageProvider", ()=>$ee27e8acf854e0ab$export$99f3e48748c2a8c3);
    var $eNf8a;
    const $ee27e8acf854e0ab$var$crossProjectBoardTraitSessionProvider = (0, (0, parcelRequire("eNf8a")).createSessionExpirableStorageProvider)((0, $111ca51573c84ae8$export$fac14ff24e5b1e7a));
    const $ee27e8acf854e0ab$export$99f3e48748c2a8c3 = ()=>{
        const persist = (toPersist)=>{
            $ee27e8acf854e0ab$var$crossProjectBoardTraitSessionProvider.set((0, $111ca51573c84ae8$export$fac14ff24e5b1e7a), toPersist);
        };
        const get = ()=>$ee27e8acf854e0ab$var$crossProjectBoardTraitSessionProvider.get((0, $111ca51573c84ae8$export$fac14ff24e5b1e7a));
        return {
            get: get,
            persist: persist
        };
    };
});
const $111ca51573c84ae8$export$fac14ff24e5b1e7a = 'jira-user-cross-project-board-trait';
parcelRegister("3ORcf", function(module, exports) {
    $parcel$export(module.exports, "getIsDevOpsProject", ()=>$d5d39abfab8df073$export$88ee873472aa9bb7);
    var $8Bc5A;
    const $d5d39abfab8df073$var$projectPropertyUrl = (projectKey)=>`/rest/api/2/project/${projectKey}/properties/${0, (0, parcelRequire("8Bc5A")).KEY_IS_DEVOPS_PROJECT}`;
    const $d5d39abfab8df073$export$88ee873472aa9bb7 = async (projectKey)=>{
        const url = $d5d39abfab8df073$var$projectPropertyUrl(projectKey);
        const response = await fetch(url);
        if (response.ok) {
            const result = await response.json();
            return result.value;
        }
        return false;
    };
});
parcelRegister("6ypzr", function(module, exports) {
    $parcel$export(module.exports, "getOnboardingSkipApplicationProperty", ()=>$98fe78eb4dc2e758$export$980c26b6f100081d);
    var $eusud;
    const $98fe78eb4dc2e758$export$980c26b6f100081d = ()=>(0, (0, parcelRequire("eusud")).performGetRequest)('/rest/onboarding/1.0/skip');
});
parcelRegister("4ji4w", function(module, exports) {
    $parcel$export(module.exports, "getNextNonCompleteTask", ()=>$af696889329ec63a$export$b61dc70e2ed3fae3);
    $parcel$export(module.exports, "getNextTask", ()=>$af696889329ec63a$export$404421ce8f3cbf9a);
    $parcel$export(module.exports, "setLocalStorageItem", ()=>$af696889329ec63a$export$5aa524d86254b4f);
    $parcel$export(module.exports, "getLocalStorageItem", ()=>$af696889329ec63a$export$93d719890ad66653);
    var $bSSG7;
    var $aFsRe;
    const $af696889329ec63a$export$b61dc70e2ed3fae3 = (tasks, completed, openTask)=>{
        const taskPosition = (0, (0, parcelRequire("aFsRe")).getTaskIndex)(tasks, openTask);
        const start = taskPosition === -1 ? 0 : taskPosition;
        const len = tasks.length;
        for(let i = start; i < start + len; i += 1){
            const ind = i % len;
            if (!completed.includes(tasks[ind])) return tasks[ind];
        }
        return null;
    };
    const $af696889329ec63a$export$404421ce8f3cbf9a = (tasks, openTask)=>{
        const taskPosition = (0, (0, parcelRequire("aFsRe")).getTaskIndex)(tasks, openTask);
        const ind = taskPosition === -1 ? 0 : taskPosition;
        return tasks[ind] ? tasks[ind] : null;
    };
    const $af696889329ec63a$var$store = (0, (0, parcelRequire("bSSG7")).createLocalStorageProvider)('quickstart');
    const $af696889329ec63a$export$5aa524d86254b4f = (key, value)=>{
        $af696889329ec63a$var$store.set(key, value);
    };
    const $af696889329ec63a$export$93d719890ad66653 = (key)=>$af696889329ec63a$var$store.get(key);
});
parcelRegister("aFsRe", function(module, exports) {
    $parcel$export(module.exports, "getTaskIndex", ()=>$856e0575daef172f$export$9ba3adcb1954c00);
    $parcel$export(module.exports, "convertTaskIdToActionSubjectIdForShowMe", ()=>$856e0575daef172f$export$9711f11998d5795c);
    $parcel$export(module.exports, "convertTaskIdToActionSubjectIdForLink", ()=>$856e0575daef172f$export$995248f3b43d8505);
    $parcel$export(module.exports, "convertTaskIdToActionSubjectIdForCta", ()=>$856e0575daef172f$export$293f6b2affa6ea5a);
    var $izv86;
    const $856e0575daef172f$export$9ba3adcb1954c00 = (taskList, taskId)=>{
        if (!taskList || taskId === null) return -1;
        return taskList.indexOf(taskId) + 1;
    };
    const $856e0575daef172f$export$9711f11998d5795c = (taskId)=>`${(0, (0, parcelRequire("izv86")).convertTaskIdToActionSubjectId)(taskId)}ShowMe`;
    const $856e0575daef172f$export$995248f3b43d8505 = (taskId)=>`${(0, (0, parcelRequire("izv86")).convertTaskIdToActionSubjectId)(taskId)}Link`;
    const $856e0575daef172f$export$293f6b2affa6ea5a = (taskId)=>`${(0, (0, parcelRequire("izv86")).convertTaskIdToActionSubjectId)(taskId)}Cta`;
});
parcelRegister("abQXL", function(module, exports) {
    $parcel$export(module.exports, "nextSetChecklistIsDismissed", ()=>$dc09dffee9419aae$export$f24c05f83b880547);
    var $8Bc5A;
    var $1ftPQ;
    var $3B5jO;
    var $4ji4w;
    const $dc09dffee9419aae$export$f24c05f83b880547 = (isDismissed)=>({ getState: getState, setState: setState })=>{
            const state = getState();
            const quickstartType = (0, (0, parcelRequire("3B5jO")).getQuickstartTypeSelector)(state);
            const currentIsDismissed = state.visibility[quickstartType] === (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).DISMISSED;
            if (isDismissed === currentIsDismissed) return;
            const quickstartStatus = isDismissed ? (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).DISMISSED : (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).OPEN;
            setState({
                visibility: {
                    ...state.visibility,
                    [quickstartType]: quickstartStatus
                },
                isFocusedOn: !isDismissed
            });
            (0, (0, parcelRequire("1ftPQ")).pushToBackend)({
                key: (0, (0, parcelRequire("8Bc5A")).getBackendVisibilityKey)(quickstartType),
                value: quickstartStatus
            });
            (0, (0, parcelRequire("4ji4w")).setLocalStorageItem)(`${quickstartType}.visibility`, quickstartStatus);
            if (isDismissed === false) (0, (0, parcelRequire("1ftPQ")).pushToBackend)({
                key: (0, (0, parcelRequire("8Bc5A")).KEY_OPEN),
                value: true
            });
            else (0, (0, parcelRequire("1ftPQ")).pushToBackend)({
                key: (0, (0, parcelRequire("8Bc5A")).KEY_OPEN),
                value: false
            });
            (0, (0, parcelRequire("1ftPQ")).pushToBackend)({
                key: (0, (0, parcelRequire("8Bc5A")).KEY_DISMISSED),
                value: isDismissed
            });
        };
});
parcelRegister("4VXxO", function(module, exports) {
    $parcel$export(module.exports, "nextSetChecklistIsOpen", ()=>$7815e3bf96163d46$export$4249eabbb34e2e69);
    var $8Bc5A;
    var $1ftPQ;
    var $3B5jO;
    var $4ji4w;
    const $7815e3bf96163d46$export$4249eabbb34e2e69 = (isOpen)=>({ getState: getState, setState: setState })=>{
            const state = getState();
            const quickstartType = (0, (0, parcelRequire("3B5jO")).getQuickstartTypeSelector)(state);
            const currentIsOpen = state.visibility[quickstartType] === (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).OPEN;
            const currentIsMinimised = state.visibility[quickstartType] === (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).MINIMIZED;
            if (currentIsOpen && isOpen || currentIsMinimised && !isOpen) return;
            const quickstartStatus = isOpen ? (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).OPEN : (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).MINIMIZED;
            setState({
                visibility: {
                    ...state.visibility,
                    [quickstartType]: quickstartStatus
                },
                hasNudgeActive: false,
                isFocusedOn: isOpen
            });
            (0, (0, parcelRequire("1ftPQ")).pushToBackend)({
                key: (0, (0, parcelRequire("8Bc5A")).getBackendVisibilityKey)(quickstartType),
                value: quickstartStatus
            });
            (0, (0, parcelRequire("4ji4w")).setLocalStorageItem)(`${quickstartType}.visibility`, quickstartStatus);
            (0, (0, parcelRequire("1ftPQ")).pushToBackend)({
                key: (0, (0, parcelRequire("8Bc5A")).KEY_OPEN),
                value: isOpen
            });
        };
});
parcelRegister("erLQY", function(module, exports) {
    $parcel$export(module.exports, "nextMarkAsCompletedTask", ()=>$f082da41253fd664$export$d6628d41a3bec42d);
    var $1ftPQ;
    const $f082da41253fd664$export$d6628d41a3bec42d = (taskId)=>({ getState: getState, setState: setState })=>{
            const { completedTaskList: completedTaskList } = getState();
            if (completedTaskList.includes(taskId)) return;
            (0, (0, parcelRequire("1ftPQ")).pushToBackend)({
                key: taskId,
                value: true
            });
            setState({
                completedTaskList: [
                    ...completedTaskList,
                    taskId
                ]
            });
        };
});
parcelRegister("8UXiA", function(module, exports) {
    $parcel$export(module.exports, "setNudgeStep", ()=>$2d15900fae273e3c$export$4debc323813b6997);
    const $2d15900fae273e3c$export$4debc323813b6997 = (nudge)=>({ getState: getState, setState: setState })=>{
            const { nudgeStep: nudgeStep } = getState();
            if (nudge === nudgeStep) return;
            setState({
                nudgeStep: nudge
            });
        };
    var $2d15900fae273e3c$export$2e2bcd8739ae039 = $2d15900fae273e3c$export$4debc323813b6997;
});
parcelRegister("3iEb4", function(module, exports) {
    $parcel$export(module.exports, "addMountedNudge", ()=>$d23aba2b0749d2be$export$92a89c32d0ea3943);
    $parcel$export(module.exports, "removeMountedNudge", ()=>$d23aba2b0749d2be$export$748f4cda8ab98c91);
    const $d23aba2b0749d2be$export$92a89c32d0ea3943 = (nudge)=>({ getState: getState, setState: setState })=>{
            const { mountedNudges: mountedNudges } = getState();
            if (mountedNudges.includes(nudge)) return;
            setState({
                mountedNudges: [
                    ...mountedNudges,
                    nudge
                ]
            });
        };
    const $d23aba2b0749d2be$export$748f4cda8ab98c91 = (nudge)=>({ getState: getState, setState: setState })=>{
            const { mountedNudges: mountedNudges } = getState();
            if (!mountedNudges.includes(nudge)) return;
            setState({
                mountedNudges: mountedNudges.filter((mountedNudge)=>mountedNudge !== nudge)
            });
        };
});
parcelRegister("7yDu9", function(module, exports) {
    $parcel$export(module.exports, "setShouldRenderComponent", ()=>$e7988e1b8f7782bd$export$1f9c6770ed938b6e);
    const $e7988e1b8f7782bd$export$1f9c6770ed938b6e = (shouldRenderComponent)=>({ getState: getState, setState: setState })=>{
            const { shouldRenderComponent: currentShouldRenderComponent } = getState();
            if (shouldRenderComponent === currentShouldRenderComponent) return;
            setState({
                shouldRenderComponent: shouldRenderComponent
            });
        };
    var $e7988e1b8f7782bd$export$2e2bcd8739ae039 = $e7988e1b8f7782bd$export$1f9c6770ed938b6e;
});
parcelRegister("5UBG2", function(module, exports) {
    $parcel$export(module.exports, "setProjectState", ()=>$72dc29ca0b198f0e$export$1ba498e440c0a1e4);
    const $72dc29ca0b198f0e$export$1ba498e440c0a1e4 = (projectState)=>({ setState: setState })=>{
            setState({
                projectState: projectState
            });
        };
    var $72dc29ca0b198f0e$export$2e2bcd8739ae039 = $72dc29ca0b198f0e$export$1ba498e440c0a1e4;
});
parcelRegister("lHcRB", function(module, exports) {
    $parcel$export(module.exports, "setHasNudgeActive", ()=>$4ecbca1b06d3bc5e$export$497c281e6eb0786e);
    const $4ecbca1b06d3bc5e$export$497c281e6eb0786e = (hasNudgeActive, itemName = '')=>({ getState: getState, setState: setState })=>{
            const { hasNudgeActive: currentHasNudgeActive, openTask: openTask } = getState();
            if (hasNudgeActive === currentHasNudgeActive) return;
            if (hasNudgeActive === false && itemName !== openTask) return;
            setState({
                hasNudgeActive: hasNudgeActive
            });
        };
    var $4ecbca1b06d3bc5e$export$2e2bcd8739ae039 = $4ecbca1b06d3bc5e$export$497c281e6eb0786e;
});
parcelRegister("eiyXc", function(module, exports) {
    $parcel$export(module.exports, "forceHideNudge", ()=>$7ab5d12e3e83c16f$export$6d1ed97b83a62e8e);
    const $7ab5d12e3e83c16f$export$6d1ed97b83a62e8e = ()=>({ setState: setState })=>{
            setState({
                hasNudgeActive: false
            });
        };
});
parcelRegister("jZvbN", function(module, exports) {
    $parcel$export(module.exports, "setHasEverSeenRoute", ()=>$bde38543b00c29f3$export$28e0a4314a1ce52f);
    var $8Bc5A;
    var $1ftPQ;
    const $bde38543b00c29f3$export$28e0a4314a1ce52f = (update)=>({ getState: getState, setState: setState })=>{
            const { hasEverSeenRoute: currentHasEverSeenRoute } = getState();
            const somethingHasBeenUpdated = Object.keys(update).some((key)=>update[key] !== currentHasEverSeenRoute[key]);
            if (!somethingHasBeenUpdated) return;
            const newHasEverSeenRoute = {
                ...currentHasEverSeenRoute,
                ...update
            };
            setState({
                hasEverSeenRoute: newHasEverSeenRoute
            });
            (0, (0, parcelRequire("1ftPQ")).pushToBackend)({
                key: (0, (0, parcelRequire("8Bc5A")).KEY_HAS_EVER_SEEN_ROUTE),
                value: newHasEverSeenRoute
            });
        };
    var $bde38543b00c29f3$export$2e2bcd8739ae039 = $bde38543b00c29f3$export$28e0a4314a1ce52f;
});
parcelRegister("azcAL", function(module, exports) {
    $parcel$export(module.exports, "setRoadmapIsCreateEnabled", ()=>$f19987e3027503ab$export$35ea35d1a8e07307);
    $parcel$export(module.exports, "setRoadmapIsListEmpty", ()=>$f19987e3027503ab$export$932967764e7a0b31);
    const $f19987e3027503ab$export$35ea35d1a8e07307 = (roadmapIsCreateEnabled)=>({ getState: getState, setState: setState })=>{
            const { roadmapIsCreateEnabled: currentRoadmapIsCreateEnabled } = getState();
            if (currentRoadmapIsCreateEnabled === roadmapIsCreateEnabled) return;
            setState({
                roadmapIsCreateEnabled: roadmapIsCreateEnabled
            });
        };
    const $f19987e3027503ab$export$932967764e7a0b31 = (roadmapIsListEmpty)=>({ getState: getState, setState: setState })=>{
            const { roadmapIsListEmpty: currentRoadmapIsListEmpty } = getState();
            if (currentRoadmapIsListEmpty === roadmapIsListEmpty) return;
            setState({
                roadmapIsListEmpty: roadmapIsListEmpty
            });
        };
});
parcelRegister("gbXQ5", function(module, exports) {
    $parcel$export(module.exports, "openNextTask", ()=>$460159056a360dfc$export$867dbb6d61569ae9);
    var $4ji4w;
    var $cUGWT;
    const $460159056a360dfc$export$867dbb6d61569ae9 = ()=>({ getState: getState, dispatch: dispatch })=>{
            const { openTask: openTask, tasks: tasks } = getState();
            if (!tasks) return;
            const { items: items } = tasks;
            const taskIdToExpand = (0, (0, parcelRequire("4ji4w")).getNextTask)(items, openTask);
            if (taskIdToExpand === null) return;
            dispatch((0, (0, parcelRequire("cUGWT")).nextOpenTask)(taskIdToExpand));
        };
});
parcelRegister("cUGWT", function(module, exports) {
    $parcel$export(module.exports, "nextOpenTask", ()=>$f8b3135a172025f8$export$5deb889489d2f989);
    $parcel$export(module.exports, "nextAutoOpenTask", ()=>$f8b3135a172025f8$export$cc5f3544a6cb2922);
    var $8Bc5A;
    var $1ftPQ;
    var $8UXiA;
    var $lHcRB;
    const $f8b3135a172025f8$var$openTask = (taskId)=>({ setState: setState })=>{
            setState({
                openTask: taskId
            });
            (0, (0, parcelRequire("1ftPQ")).pushToBackend)({
                key: (0, (0, parcelRequire("8Bc5A")).KEY_OPEN_TASK),
                value: taskId
            });
        };
    const $f8b3135a172025f8$export$5deb889489d2f989 = (taskId, { closeNudge: closeNudge } = {})=>({ dispatch: dispatch, getState: getState })=>{
            dispatch($f8b3135a172025f8$var$openTask(taskId));
            const { completedTaskList: completedTaskList } = getState();
            const taskIsCompleted = completedTaskList.includes(taskId);
            const taskIdExistsAndIsNotCompleted = !(taskId === '' || taskId === null) && !taskIsCompleted;
            if (closeNudge) dispatch((0, (0, parcelRequire("lHcRB")).setHasNudgeActive)(false, taskId));
            else dispatch((0, (0, parcelRequire("lHcRB")).setHasNudgeActive)(taskIdExistsAndIsNotCompleted, taskId));
            if (taskIdExistsAndIsNotCompleted && taskId === (0, (0, parcelRequire("8Bc5A")).SCRUM_ONBOARDING_TASK)) dispatch((0, (0, parcelRequire("8UXiA")).setNudgeStep)((0, $6784cbc7d62e5d7c$export$b1a6ab17737f33e)));
            else if (taskIdExistsAndIsNotCompleted && taskId === (0, (0, parcelRequire("8Bc5A")).KANBAN_ONBOARDING_TASK)) dispatch((0, (0, parcelRequire("8UXiA")).setNudgeStep)((0, $6784cbc7d62e5d7c$export$3366ddefe77d5112)));
            else if (taskIdExistsAndIsNotCompleted && taskId === (0, (0, parcelRequire("8Bc5A")).ADD_COLUMNS_TASK)) dispatch((0, (0, parcelRequire("8UXiA")).setNudgeStep)((0, $6784cbc7d62e5d7c$export$3366ddefe77d5112)));
            else dispatch((0, (0, parcelRequire("8UXiA")).setNudgeStep)(''));
        };
    const $f8b3135a172025f8$export$cc5f3544a6cb2922 = (taskId)=>({ dispatch: dispatch })=>{
            dispatch($f8b3135a172025f8$var$openTask(taskId));
            dispatch((0, (0, parcelRequire("lHcRB")).setHasNudgeActive)(false, taskId));
            dispatch((0, (0, parcelRequire("8UXiA")).setNudgeStep)(''));
        };
});
parcelRegister("iZuFy", function(module, exports) {
    $parcel$export(module.exports, "resetIsQuickstartLoaded", ()=>$b50ce46f86d3e3d7$export$bda085a823763e5a);
    var $8Bc5A;
    var $3B5jO;
    const $b50ce46f86d3e3d7$export$bda085a823763e5a = ()=>({ getState: getState, setState: setState })=>{
            const state = getState();
            const quickstartType = (0, (0, parcelRequire("3B5jO")).getQuickstartTypeSelector)(state);
            setState({
                visibility: {
                    ...state.visibility,
                    [quickstartType]: (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).NOT_LOADED
                },
                hasNudgeActive: false,
                isQuickstartLoaded: false
            });
        };
});
parcelRegister("70Fss", function(module, exports) {
    $parcel$export(module.exports, "refreshAppStateUiStatusOnly", ()=>$f0fdda8951af3bde$export$87fe8079a4ed9766);
    var $8Bc5A;
    var $6yhDy;
    var $52Vcw;
    var $6ypzr;
    var $abQXL;
    const $f0fdda8951af3bde$export$87fe8079a4ed9766 = ({ taskList: taskList })=>async ({ getState: getState, setState: setState, dispatch: dispatch })=>{
            const updatedState = {};
            const { projectState: projectState } = getState();
            if (!projectState) return;
            updatedState.isOpen = true;
            updatedState.isDismissed = false;
            updatedState.quickstartStateProjectType = projectState.projectType;
            let backendVisibilityKeys = [];
            updatedState.visibility = Object.values((0, (0, parcelRequire("8Bc5A")).QUICKSTART_TYPE)).reduce((acc, type)=>{
                acc[type] = (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).OPEN;
                return acc;
            }, {});
            backendVisibilityKeys = Object.values((0, (0, parcelRequire("8Bc5A")).QUICKSTART_TYPE)).map((type)=>(0, (0, parcelRequire("8Bc5A")).getBackendVisibilityKey)(type));
            const backendState = await (0, (0, parcelRequire("6yhDy")).pollBackend)({
                keys: [
                    (0, (0, parcelRequire("8Bc5A")).KEY_OPEN),
                    (0, (0, parcelRequire("8Bc5A")).KEY_DISMISSED),
                    (0, (0, parcelRequire("8Bc5A")).KEY_OPEN_TASK),
                    (0, (0, parcelRequire("8Bc5A")).KEY_HAS_EVER_SEEN_ROUTE),
                    (0, (0, parcelRequire("8Bc5A")).KEY_JSW_ONBOARDING),
                    ...(0, (0, parcelRequire("8Bc5A")).allTasksExcludingJSMBusinessTasks),
                    ...backendVisibilityKeys
                ]
            });
            const backendStateExists = backendState !== undefined && Object.keys(backendState).length > 0;
            if (backendStateExists) {
                updatedState.completedTaskList = Object.keys(backendState).filter((key)=>[
                        ...(0, (0, parcelRequire("8Bc5A")).allTasksExcludingJSMBusinessTasks)
                    ].includes(key) && backendState[key] === true);
                Object.values((0, (0, parcelRequire("8Bc5A")).QUICKSTART_TYPE)).forEach((type)=>{
                    if ((0, (0, parcelRequire("8Bc5A")).getBackendVisibilityKey)(type) in backendState) updatedState.visibility[type] = backendState[(0, (0, parcelRequire("8Bc5A")).getBackendVisibilityKey)(type)];
                    else if ((0, (0, parcelRequire("8Bc5A")).KEY_DISMISSED) in backendState && backendState[0, (0, parcelRequire("8Bc5A")).KEY_DISMISSED]) updatedState.visibility[type] = (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).DISMISSED;
                    else if ((0, (0, parcelRequire("8Bc5A")).KEY_OPEN) in backendState) updatedState.visibility[type] = backendState[0, (0, parcelRequire("8Bc5A")).KEY_OPEN] ? (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).OPEN : (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).MINIMIZED;
                });
                if ((0, (0, parcelRequire("8Bc5A")).KEY_DISMISSED) in backendState) updatedState.isDismissed = backendState[0, (0, parcelRequire("8Bc5A")).KEY_DISMISSED];
                if ((0, (0, parcelRequire("8Bc5A")).KEY_OPEN) in backendState) updatedState.isOpen = backendState[0, (0, parcelRequire("8Bc5A")).KEY_OPEN];
                if ((0, (0, parcelRequire("8Bc5A")).KEY_OPEN_TASK) in backendState) updatedState.openTask = backendState[0, (0, parcelRequire("8Bc5A")).KEY_OPEN_TASK];
                if ((0, (0, parcelRequire("8Bc5A")).KEY_HAS_EVER_SEEN_ROUTE) in backendState) updatedState.hasEverSeenRoute = backendState[0, (0, parcelRequire("8Bc5A")).KEY_HAS_EVER_SEEN_ROUTE];
                if ((0, (0, parcelRequire("8Bc5A")).KEY_TASK_GROUP) in backendState) {
                    updatedState.currentTaskGroupParent = backendState[0, (0, parcelRequire("8Bc5A")).KEY_TASK_GROUP].taskGroup.currentTaskGroupParent;
                    updatedState.previousTaskGroupParents = backendState[0, (0, parcelRequire("8Bc5A")).KEY_TASK_GROUP].taskGroup.previousTaskGroupParents;
                }
                if ((0, (0, parcelRequire("8Bc5A")).KEY_JSW_ONBOARDING) in backendState) {
                    if (typeof backendState[0, (0, parcelRequire("8Bc5A")).KEY_JSW_ONBOARDING] === 'object' && backendState[0, (0, parcelRequire("8Bc5A")).KEY_JSW_ONBOARDING] !== null && (0, $6784cbc7d62e5d7c$export$8a00ecf6498ee2fe) in backendState[0, (0, parcelRequire("8Bc5A")).KEY_JSW_ONBOARDING]) updatedState.selectedProjectTemplateKeyFromOnboarding = backendState[0, (0, parcelRequire("8Bc5A")).KEY_JSW_ONBOARDING][0, $6784cbc7d62e5d7c$export$8a00ecf6498ee2fe];
                }
            }
            setState(updatedState);
            if (!(backendStateExists && (0, (0, parcelRequire("8Bc5A")).KEY_OPEN) in backendState)) try {
                const skipApplicationProperty = await (0, (0, parcelRequire("6ypzr")).getOnboardingSkipApplicationProperty)();
                if (skipApplicationProperty.quickstart) dispatch((0, (0, parcelRequire("abQXL")).nextSetChecklistIsDismissed)(true));
            } catch (error) {
                (0, (0, parcelRequire("52Vcw")).fireAPIErrorAnalytics)(error, 'skipOnboarding');
            }
            setState({
                taskList: taskList
            });
            setState({
                isQuickstartLoaded: true
            });
        };
});
parcelRegister("dQPsy", function(module, exports) {
    $parcel$export(module.exports, "nextSetChecklistItemSeen", ()=>$82a74350685dcd25$export$4eac017dcde0be8f);
    var $8Bc5A;
    var $1ftPQ;
    const $82a74350685dcd25$export$4eac017dcde0be8f = (taskId)=>({ getState: getState, setState: setState })=>{
            const hasSeenTaskId = (0, (0, parcelRequire("8Bc5A")).convertToHaveSeenItTask)(taskId);
            const { hasSeenTasks: hasSeenTasks } = getState();
            if (hasSeenTasks.includes(hasSeenTaskId)) return;
            setState({
                hasSeenTasks: [
                    ...hasSeenTasks,
                    hasSeenTaskId
                ]
            });
            (0, (0, parcelRequire("1ftPQ")).pushToBackend)({
                key: (0, (0, parcelRequire("8Bc5A")).convertToHaveSeenItTask)(taskId),
                value: true
            });
        };
});
parcelRegister("boV1S", function(module, exports) {
    $parcel$export(module.exports, "nextAutoExpand", ()=>$35a410a5fb89f79b$export$3d24e97d30cfd09e);
    var $8Bc5A;
    var $984bX;
    var $4ji4w;
    var $cUGWT;
    const $35a410a5fb89f79b$export$3d24e97d30cfd09e = ()=>({ getState: getState, dispatch: dispatch })=>{
            const hasNudgeToShowAfterNavigation = (0, (0, parcelRequire("984bX")).localStorageProvider)().get({
                accountId: (0, (0, parcelRequire("8Bc5A")).ACCOUNT_ID),
                key: (0, (0, parcelRequire("8Bc5A")).KEY_HAS_NAVIGATED_FROM_LINK)
            });
            if (hasNudgeToShowAfterNavigation) return;
            const { openTask: openTask, completedTaskList: completedTaskList, tasks: tasks } = getState();
            if (!tasks) return;
            const { items: items } = tasks;
            if (items.includes(openTask) && !completedTaskList.includes(openTask)) return;
            const taskIdToExpand = (0, (0, parcelRequire("4ji4w")).getNextNonCompleteTask)(items, completedTaskList, openTask);
            if (taskIdToExpand === null) return;
            dispatch((0, (0, parcelRequire("cUGWT")).nextAutoOpenTask)(taskIdToExpand));
        };
});
parcelRegister("984bX", function(module, exports) {
    $parcel$export(module.exports, "localStorageProvider", ()=>$a6d0a6873aa6f7aa$export$aa821bfb3308a1f5);
    $parcel$export(module.exports, "setLocalStorageHasNavigatedFromLink", ()=>$a6d0a6873aa6f7aa$export$f2666fae743fa5f);
    var $eNf8a;
    var $jcw0u;
    var $8Bc5A;
    const $a6d0a6873aa6f7aa$var$DAYS_BEFORE_EXPIRY = 1;
    const $a6d0a6873aa6f7aa$var$DAY = 86400000;
    const $a6d0a6873aa6f7aa$var$getKey = ({ accountId: accountId, key: key })=>`${accountId}.${key}`;
    const $a6d0a6873aa6f7aa$var$getExpiryTime = ()=>{
        const now = Date.now();
        return now + $a6d0a6873aa6f7aa$var$DAYS_BEFORE_EXPIRY * $a6d0a6873aa6f7aa$var$DAY;
    };
    let $a6d0a6873aa6f7aa$var$localStorage;
    const $a6d0a6873aa6f7aa$export$aa821bfb3308a1f5 = ()=>{
        if ($a6d0a6873aa6f7aa$var$localStorage) return $a6d0a6873aa6f7aa$var$localStorage;
        const provider = (0, (0, parcelRequire("eNf8a")).createSessionExpirableStorageProvider)('jsw-onboarding-checklist');
        try {
            provider.removeExpiredKeys();
        } catch (e) {
            (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: 'localStorageProviderKeyRemoval',
                    packageName: 'jiraOnboardingQuickstartCore'
                },
                error: e
            });
        }
        const get = ({ accountId: accountId, key: key })=>provider.get($a6d0a6873aa6f7aa$var$getKey({
                accountId: accountId,
                key: key
            }));
        const set = ({ accountId: accountId, key: key, value: value })=>provider.set($a6d0a6873aa6f7aa$var$getKey({
                accountId: accountId,
                key: key
            }), value, $a6d0a6873aa6f7aa$var$getExpiryTime());
        const remove = ({ accountId: accountId, key: key })=>provider.remove($a6d0a6873aa6f7aa$var$getKey({
                accountId: accountId,
                key: key
            }));
        $a6d0a6873aa6f7aa$var$localStorage = {
            provider: provider,
            remove: remove,
            get: get,
            set: set
        };
        return $a6d0a6873aa6f7aa$var$localStorage;
    };
    const $a6d0a6873aa6f7aa$export$f2666fae743fa5f = (value)=>{
        $a6d0a6873aa6f7aa$export$aa821bfb3308a1f5().set({
            accountId: (0, (0, parcelRequire("8Bc5A")).ACCOUNT_ID),
            key: (0, (0, parcelRequire("8Bc5A")).KEY_HAS_NAVIGATED_FROM_LINK),
            value: value
        });
    };
});
parcelRegister("4Viq4", function(module, exports) {
    $parcel$export(module.exports, "nextShouldShowNudgeAfterNavigation", ()=>$0d74d0b8a43d2c5f$export$6f336ccb6432fcd0);
    var $8Bc5A;
    var $984bX;
    var $lHcRB;
    const $0d74d0b8a43d2c5f$export$6f336ccb6432fcd0 = (taskId)=>({ dispatch: dispatch })=>{
            const nudgeToShowAfterNavigationKeyPair = {
                accountId: (0, (0, parcelRequire("8Bc5A")).ACCOUNT_ID),
                key: (0, (0, parcelRequire("8Bc5A")).KEY_HAS_NAVIGATED_FROM_LINK)
            };
            const shouldShowNudge = (0, (0, parcelRequire("984bX")).localStorageProvider)().get(nudgeToShowAfterNavigationKeyPair) === taskId;
            if (shouldShowNudge) {
                dispatch((0, (0, parcelRequire("lHcRB")).setHasNudgeActive)(true));
                (0, (0, parcelRequire("984bX")).localStorageProvider)().remove(nudgeToShowAfterNavigationKeyPair);
            }
        };
});
parcelRegister("jIoq4", function(module, exports) {
    $parcel$export(module.exports, "nextGetTasks", ()=>$034f263b888956c9$export$15d877e1f3abf489);
    var $ikZFc;
    const $034f263b888956c9$export$15d877e1f3abf489 = (props)=>({ getState: getState, setState: setState })=>{
            const { taskList: taskList } = getState();
            const tasks = (0, (0, parcelRequire("ikZFc")).getAllTasks)({
                ...props,
                taskList: taskList
            });
            setState({
                tasks: tasks
            });
        };
});
parcelRegister("ikZFc", function(module, exports) {
    $parcel$export(module.exports, "getAllTasks", ()=>$cb6c1b1dc7b1b225$export$d1f442602a91e7cc);
    var $aIZdc;
    var $65Ltd;
    var $co1wz;
    var $4R6GH;
    var $ldyPw;
    var $8Bc5A;
    var $1ffS1;
    var $ieqoN;
    var $h3PyW;
    var $3Iaiu;
    var $aHo1U;
    var $5yUqp;
    var $5zzQl;
    var $jJca1;
    const $cb6c1b1dc7b1b225$var$getTaskListItem = ({ variants: variants, taskVariants: taskVariants, taskList: taskList, taskGroupParent: taskGroupParent })=>{
        if (variants.includes((0, (0, parcelRequire("aHo1U")).JSW).experiment.jswReverseTrial)) {
            const { title: title, items: items } = taskList[taskGroupParent === (0, (0, parcelRequire("8Bc5A")).ROOT_TASK) ? (0, (0, parcelRequire("8Bc5A")).REVERSE_TRIAL_ROOT_TASK) : taskGroupParent] ?? taskList[0, (0, parcelRequire("8Bc5A")).REVERSE_TRIAL_ROOT_TASK];
            return {
                title: title,
                items: (0, (0, parcelRequire("5zzQl")).getTasks)(variants, taskVariants, items)
            };
        }
        const { title: title, items: items } = taskList[taskGroupParent] ?? taskList[0, (0, parcelRequire("8Bc5A")).ROOT_TASK];
        return {
            title: title,
            items: (0, (0, parcelRequire("5zzQl")).getTasks)(variants, taskVariants, items)
        };
    };
    const $cb6c1b1dc7b1b225$var$createJswTasks = (projectState, taskGroupParent, routeName, shouldShowScrumOnboardingExperience, mountedNudges, canCreateProject, isEligibleToUpdateSitename, isCustomerTemplateProject, isCrossProjectBoardToPlanUpsell, canEditPlans, premiumTrialOnSignup)=>{
        const variants = new Set();
        const isTmpScrumWithSprintPermission = mountedNudges.includes((0, (0, parcelRequire("8Bc5A")).BACKLOG_CREATE_SPRINT_NUDGE_ID));
        const isEligibleForKanbanTour = mountedNudges.includes((0, $6784cbc7d62e5d7c$export$3366ddefe77d5112));
        if ((0, (0, parcelRequire("aIZdc")).isFedRamp)()) variants.add((0, (0, parcelRequire("aHo1U")).JSW).experience.fedRamp);
        if (premiumTrialOnSignup && (0, (0, parcelRequire("4R6GH")).fg)('jsw_reverse_trials_feature_gate')) variants.add((0, (0, parcelRequire("aHo1U")).JSW).experiment.jswReverseTrial);
        if (isCustomerTemplateProject) variants.add((0, (0, parcelRequire("aHo1U")).JSW).projectProperties.isCreatedFromCustomerTemplate);
        if (shouldShowScrumOnboardingExperience && isTmpScrumWithSprintPermission) variants.add((0, (0, parcelRequire("aHo1U")).JSW).experiment.scrumOnboarding);
        if (isCrossProjectBoardToPlanUpsell) variants.add((0, (0, parcelRequire("aHo1U")).JSW).trait.crossProjectBoardToPlanUpsell);
        if (canEditPlans) variants.add((0, (0, parcelRequire("aHo1U")).JSW).permissions.hasPlanEditPermissions);
        if (isEligibleForKanbanTour || routeName !== (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SOFTWARE_BOARDS)) variants.add((0, (0, parcelRequire("aHo1U")).JSW).experience.kanbanOnboarding);
        if (canCreateProject === false) variants.add((0, (0, parcelRequire("aHo1U")).JSW).permissions.notCanCreateProject);
        if (projectState?.isNextGen) variants.add((0, (0, parcelRequire("aHo1U")).JSW).projectStyle.teamManaged);
        else variants.add((0, (0, parcelRequire("aHo1U")).JSW).projectStyle.companyManaged);
        if (projectState?.isProjectAdmin) variants.add((0, (0, parcelRequire("aHo1U")).JSW).userRole.projectAdmin);
        if (projectState?.userRole === (0, (0, parcelRequire("ieqoN")).USER_ROLE).JIRA_ADMIN) {
            variants.add((0, (0, parcelRequire("aHo1U")).JSW).userRole.jiraAdmin);
            variants.add((0, (0, parcelRequire("aHo1U")).JSW).userRole.admin);
        }
        if (projectState?.userRole === (0, (0, parcelRequire("ieqoN")).USER_ROLE).SITE_ADMIN) {
            variants.add((0, (0, parcelRequire("aHo1U")).JSW).userRole.siteAdmin);
            variants.add((0, (0, parcelRequire("aHo1U")).JSW).userRole.admin);
        }
        if (projectState?.edition === (0, (0, parcelRequire("ldyPw")).FREE_EDITION)) variants.add((0, (0, parcelRequire("aHo1U")).JSW).edition.free);
        else if (projectState?.edition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION)) variants.add((0, (0, parcelRequire("aHo1U")).JSW).edition.standard);
        else if (projectState?.edition === (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION)) variants.add((0, (0, parcelRequire("aHo1U")).JSW).edition.premium);
        if ((0, (0, parcelRequire("1ffS1")).isRoadmapRoute)(routeName)) variants.add((0, (0, parcelRequire("aHo1U")).JSW).route.softwareRoadmap);
        else if (routeName === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SOFTWARE_BACKLOG)) variants.add((0, (0, parcelRequire("aHo1U")).JSW).route.softwareBacklog);
        else if (routeName === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SOFTWARE_BOARDS)) variants.add((0, (0, parcelRequire("aHo1U")).JSW).route.softwareBoard);
        else if (routeName === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SOFTWARE_LIST) || routeName === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SOFTWARE_LIST_CLASSIC)) variants.add((0, (0, parcelRequire("aHo1U")).JSW).route.softwareList);
        else if (routeName === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_ACCESS)) variants.add((0, (0, parcelRequire("aHo1U")).JSW).route.projectSettingsSoftwareAccess);
        else if (routeName === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_ISSUE)) variants.add((0, (0, parcelRequire("aHo1U")).JSW).route.issue);
        else if ((0, (0, parcelRequire("co1wz")).ff)('jsw-calendar-view-onboarding_q3bvy') && (routeName === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SOFTWARE_CALENDAR) || routeName === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC))) variants.add((0, (0, parcelRequire("aHo1U")).JSW).route.softwareCalendar);
        if (isEligibleToUpdateSitename) variants.add((0, (0, parcelRequire("aHo1U")).JSW).experiment.customizeSiteName);
        return $cb6c1b1dc7b1b225$var$getTaskListItem({
            variants: Array.from(variants.values()),
            taskVariants: (0, (0, parcelRequire("aHo1U")).JSW).taskVariants,
            taskList: (0, (0, parcelRequire("aHo1U")).JSW).taskList,
            taskGroupParent: taskGroupParent
        });
    };
    const $cb6c1b1dc7b1b225$var$createJwmTasks = (projectState, taskGroupParent, taskList = (0, (0, parcelRequire("5yUqp")).JWM).taskList)=>{
        const variants = [];
        variants.push((0, (0, parcelRequire("5yUqp")).JWM).experiments.projectTheming);
        if (projectState?.isProjectAdmin) variants.push((0, (0, parcelRequire("5yUqp")).JWM).userRole.projectAdmin);
        if (projectState?.userRole === (0, (0, parcelRequire("ieqoN")).USER_ROLE).JIRA_ADMIN) variants.push((0, (0, parcelRequire("5yUqp")).JWM).userRole.jiraAdmin);
        if (projectState?.userRole === (0, (0, parcelRequire("ieqoN")).USER_ROLE).SITE_ADMIN) variants.push((0, (0, parcelRequire("5yUqp")).JWM).userRole.siteAdmin);
        if (projectState?.isSampleProject) variants.push((0, (0, parcelRequire("5yUqp")).JWM).experiments.isSampleProject);
        else variants.push((0, (0, parcelRequire("5yUqp")).JWM).experiments.isNotSampleProject);
        if (projectState?.isTrelloCrossflow) variants.push((0, (0, parcelRequire("5yUqp")).JWM).experiments.trelloCrossflow);
        return $cb6c1b1dc7b1b225$var$getTaskListItem({
            variants: variants,
            taskVariants: (0, (0, parcelRequire("5yUqp")).JWM).taskVariants,
            taskList: taskList,
            taskGroupParent: taskGroupParent
        });
    };
    const $cb6c1b1dc7b1b225$var$createJsmTasks = (projectState, taskGroupParent)=>{
        const variants = [];
        variants.push((0, (0, parcelRequire("3Iaiu")).JSM).experiments.projectTheming);
        if (projectState?.isProjectAdmin) variants.push((0, (0, parcelRequire("3Iaiu")).JSM).userRole.projectAdmin);
        if (projectState?.userRole === (0, (0, parcelRequire("ieqoN")).USER_ROLE).JIRA_ADMIN) variants.push((0, (0, parcelRequire("3Iaiu")).JSM).userRole.jiraAdmin);
        if (projectState?.userRole === (0, (0, parcelRequire("ieqoN")).USER_ROLE).SITE_ADMIN) variants.push((0, (0, parcelRequire("3Iaiu")).JSM).userRole.siteAdmin);
        return $cb6c1b1dc7b1b225$var$getTaskListItem({
            variants: variants,
            taskVariants: (0, (0, parcelRequire("3Iaiu")).JSM).taskVariants,
            taskList: (0, (0, parcelRequire("3Iaiu")).JSM).taskList,
            taskGroupParent: taskGroupParent
        });
    };
    const $cb6c1b1dc7b1b225$var$createDevopsTasks = (taskGroupParent)=>{
        const variants = [];
        return $cb6c1b1dc7b1b225$var$getTaskListItem({
            variants: variants,
            taskVariants: (0, (0, parcelRequire("h3PyW")).DEVOPS).taskVariants,
            taskList: (0, (0, parcelRequire("h3PyW")).DEVOPS).taskList,
            taskGroupParent: taskGroupParent
        });
    };
    const $cb6c1b1dc7b1b225$export$d1f442602a91e7cc = ({ projectState: projectState, isDevOpsProject: isDevOpsProject, taskGroupParent: taskGroupParent, routeName: routeName, shouldShowScrumOnboardingExperience: shouldShowScrumOnboardingExperience, mountedNudges: mountedNudges, canCreateProject: canCreateProject, isEligibleToUpdateSitename: isEligibleToUpdateSitename, isCustomerTemplateProject: isCustomerTemplateProject, isCrossProjectBoardToPlanUpsell: isCrossProjectBoardToPlanUpsell, canEditPlans: canEditPlans, premiumTrialOnSignup: premiumTrialOnSignup, taskList: taskList })=>{
        if ((0, (0, parcelRequire("jJca1")).isJwmProject)(projectState)) return $cb6c1b1dc7b1b225$var$createJwmTasks(projectState, taskGroupParent, taskList);
        if ((0, (0, parcelRequire("jJca1")).isJsmProject)(projectState)) return $cb6c1b1dc7b1b225$var$createJsmTasks(projectState, taskGroupParent);
        if (isDevOpsProject) return $cb6c1b1dc7b1b225$var$createDevopsTasks(taskGroupParent);
        return $cb6c1b1dc7b1b225$var$createJswTasks(projectState, taskGroupParent, routeName, shouldShowScrumOnboardingExperience, mountedNudges, canCreateProject, isEligibleToUpdateSitename, isCustomerTemplateProject, isCrossProjectBoardToPlanUpsell, canEditPlans, premiumTrialOnSignup);
    };
});
parcelRegister("1ffS1", function(module, exports) {
    $parcel$export(module.exports, "isRoadmapRoute", ()=>$ee93e5dd18635f6d$export$d1fa67c1ffb68871);
    var $65Ltd;
    const $ee93e5dd18635f6d$export$d1fa67c1ffb68871 = (routeName)=>{
        if (routeName === undefined) return false;
        return routeName === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SOFTWARE_TIMELINE) || routeName === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SOFTWARE_TIMELINE_CLASSIC);
    };
});
parcelRegister("ieqoN", function(module, exports) {
    $parcel$export(module.exports, "USER_ROLE", ()=>$353950c3dd35bbc6$export$8eeb199b23203523);
    const $353950c3dd35bbc6$export$8eeb199b23203523 = {
        END_USER: 'end-user',
        SITE_ADMIN: 'site-admin',
        JIRA_ADMIN: 'jira-admin'
    };
});
parcelRegister("h3PyW", function(module, exports) {
    $parcel$export(module.exports, "DEVOPS", ()=>$866b9189a2842e55$export$bb75b6064c92010c);
    var $j6cCw;
    const $866b9189a2842e55$export$bb75b6064c92010c = {
        taskList: (0, (0, parcelRequire("j6cCw")).TASK_LIST),
        taskVariants: (0, (0, parcelRequire("j6cCw")).TASK_VARIANTS),
        experiment: (0, (0, parcelRequire("j6cCw")).Experiment)
    };
});
parcelRegister("j6cCw", function(module, exports) {
    $parcel$export(module.exports, "Experiment", ()=>$7149b1f98b73c1fd$export$4e0ab25c8a7e4009);
    $parcel$export(module.exports, "TASK_LIST", ()=>$7149b1f98b73c1fd$export$601c8c0f45aeb0a0);
    $parcel$export(module.exports, "TASK_VARIANTS", ()=>$7149b1f98b73c1fd$export$36f4d47dfe11b613);
    var $8Bc5A;
    var $1AiUr;
    const $7149b1f98b73c1fd$export$4e0ab25c8a7e4009 = {};
    const $7149b1f98b73c1fd$export$601c8c0f45aeb0a0 = Object.freeze({
        root: {
            title: (0, (0, parcelRequire("1AiUr")).messages).rootTitle,
            items: [
                (0, (0, parcelRequire("8Bc5A")).DEVOPS_WELCOME_TO_YOUR_PROJECT_TASK),
                (0, (0, parcelRequire("8Bc5A")).DEVOPS_GET_TO_KNOW_JIRA_TASK),
                (0, (0, parcelRequire("8Bc5A")).DEVOPS_NAVIGATE_YOUR_TOOLS_TASK),
                (0, (0, parcelRequire("8Bc5A")).DEVOPS_CAPTURE_INTEGRATIONS_TASK),
                (0, (0, parcelRequire("8Bc5A")).DEVOPS_CREATE_AUTOMATION_RULES_TASK),
                (0, (0, parcelRequire("8Bc5A")).DEVOPS_INVITE_TEAMMATES_TASK),
                (0, (0, parcelRequire("8Bc5A")).DEVOPS_FIND_HELP_TASK)
            ]
        }
    });
    const $7149b1f98b73c1fd$export$36f4d47dfe11b613 = {
        [(0, (0, parcelRequire("8Bc5A")).DEVOPS_WELCOME_TO_YOUR_PROJECT_TASK)]: {
            position: {
                default: 0
            },
            visibility: {
                default: true
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).DEVOPS_GET_TO_KNOW_JIRA_TASK)]: {
            position: {
                default: 100
            },
            visibility: {
                default: true
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).DEVOPS_NAVIGATE_YOUR_TOOLS_TASK)]: {
            position: {
                default: 200
            },
            visibility: {
                default: true
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).DEVOPS_CAPTURE_INTEGRATIONS_TASK)]: {
            position: {
                default: 300
            },
            visibility: {
                default: true
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).DEVOPS_CREATE_AUTOMATION_RULES_TASK)]: {
            position: {
                default: 400
            },
            visibility: {
                default: true
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).DEVOPS_INVITE_TEAMMATES_TASK)]: {
            position: {
                default: 500
            },
            visibility: {
                default: true
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).DEVOPS_FIND_HELP_TASK)]: {
            position: {
                default: 600
            },
            visibility: {
                default: true
            }
        }
    };
});
parcelRegister("1AiUr", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$6eae5132e152d792$export$defe85febe8b728c);
    var $7VHMR;
    const $6eae5132e152d792$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        rootTitle: {
            "id": "software-onboarding-quickstart.services.tasks.config.devops.root-title.no-translate",
            "defaultMessage": "Quickstart"
        }
    });
});
parcelRegister("3Iaiu", function(module, exports) {
    $parcel$export(module.exports, "JSM", ()=>$56a077523ddf70fb$export$63f289f2da861d35);
    var $58IQc;
    const $56a077523ddf70fb$export$63f289f2da861d35 = {
        taskList: (0, (0, parcelRequire("58IQc")).JSM_TASK_LIST),
        taskVariants: (0, (0, parcelRequire("58IQc")).JSM_TASK_VARIANTS),
        userRole: (0, (0, parcelRequire("58IQc")).UserRole),
        experiments: (0, (0, parcelRequire("58IQc")).Experiments)
    };
});
parcelRegister("58IQc", function(module, exports) {
    $parcel$export(module.exports, "JSM_TASK_LIST", ()=>$3c13225c81ad4b5c$export$6c6c561944d49bcb);
    $parcel$export(module.exports, "Experiments", ()=>$3c13225c81ad4b5c$export$c8d6ffd15214553a);
    $parcel$export(module.exports, "UserRole", ()=>$3c13225c81ad4b5c$export$eb3485a690c2f0b8);
    $parcel$export(module.exports, "JSM_TASK_VARIANTS", ()=>$3c13225c81ad4b5c$export$5b68aef408c932d9);
    var $8Bc5A;
    var $kTSRm;
    const $3c13225c81ad4b5c$export$6c6c561944d49bcb = Object.freeze({
        root: {
            title: (0, (0, parcelRequire("kTSRm")).messages).rootTitle,
            items: [
                (0, (0, parcelRequire("8Bc5A")).JSM_REVIEW_YOUR_CHANNELS_TASK),
                (0, (0, parcelRequire("8Bc5A")).JSM_CUSTOMIZE_YOUR_REQUEST_TYPES_TASK),
                (0, (0, parcelRequire("8Bc5A")).JSM_INVITE_YOUR_TEAM_TASK)
            ]
        }
    });
    const $3c13225c81ad4b5c$export$c8d6ffd15214553a = {
        projectTheming: 'projectTheming'
    };
    const $3c13225c81ad4b5c$export$eb3485a690c2f0b8 = {
        projectAdmin: 'projectAdmin',
        jiraAdmin: 'jiraAdmin',
        siteAdmin: 'siteAdmin'
    };
    const $3c13225c81ad4b5c$export$5b68aef408c932d9 = {
        [(0, (0, parcelRequire("8Bc5A")).JSM_REVIEW_YOUR_CHANNELS_TASK)]: {
            position: {
                default: 0
            },
            visibility: {
                default: true
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JSM_CUSTOMIZE_YOUR_REQUEST_TYPES_TASK)]: {
            position: {
                default: 1
            },
            visibility: {
                default: true
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JSM_INVITE_YOUR_TEAM_TASK)]: {
            position: {
                default: 2
            },
            visibility: {
                default: true
            }
        }
    };
});
parcelRegister("kTSRm", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$f1f6142c4170f38e$export$defe85febe8b728c);
    var $7VHMR;
    const $f1f6142c4170f38e$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        rootTitle: {
            "id": "software-onboarding-quickstart.services.tasks.config.jsm.root-title.no-translate",
            "defaultMessage": "Quickstart"
        }
    });
});
parcelRegister("aHo1U", function(module, exports) {
    $parcel$export(module.exports, "JSW", ()=>$563027753d017a60$export$4cfe6e52f2e72a54);
    var $8VfDj;
    const $563027753d017a60$export$4cfe6e52f2e72a54 = {
        taskList: (0, (0, parcelRequire("8VfDj")).TASK_LIST),
        taskVariants: (0, (0, parcelRequire("8VfDj")).TASK_VARIANTS),
        experiment: (0, (0, parcelRequire("8VfDj")).Experiment),
        experience: (0, (0, parcelRequire("8VfDj")).Experience),
        projectStyle: (0, (0, parcelRequire("8VfDj")).ProjectStyle),
        projectProperties: (0, (0, parcelRequire("8VfDj")).ProjectProperties),
        userRole: (0, (0, parcelRequire("8VfDj")).UserRole),
        edition: (0, (0, parcelRequire("8VfDj")).Edition),
        route: (0, (0, parcelRequire("8VfDj")).Route),
        permissions: (0, (0, parcelRequire("8VfDj")).Permissions),
        trait: (0, (0, parcelRequire("8VfDj")).Trait)
    };
});
parcelRegister("8VfDj", function(module, exports) {
    $parcel$export(module.exports, "Experiment", ()=>$ea3903bb15047c3b$export$4e0ab25c8a7e4009);
    $parcel$export(module.exports, "Experience", ()=>$ea3903bb15047c3b$export$c90b54e658fc4d7c);
    $parcel$export(module.exports, "ProjectStyle", ()=>$ea3903bb15047c3b$export$1f66f9f05afa05a8);
    $parcel$export(module.exports, "ProjectProperties", ()=>$ea3903bb15047c3b$export$543c19ee8dba9252);
    $parcel$export(module.exports, "UserRole", ()=>$ea3903bb15047c3b$export$eb3485a690c2f0b8);
    $parcel$export(module.exports, "Edition", ()=>$ea3903bb15047c3b$export$6f4a0f769e61929);
    $parcel$export(module.exports, "Route", ()=>$ea3903bb15047c3b$export$e7b0ac011bb776c6);
    $parcel$export(module.exports, "Permissions", ()=>$ea3903bb15047c3b$export$2d41982586bbb6be);
    $parcel$export(module.exports, "Trait", ()=>$ea3903bb15047c3b$export$2d08cfa40d69142e);
    $parcel$export(module.exports, "TASK_LIST", ()=>$ea3903bb15047c3b$export$601c8c0f45aeb0a0);
    $parcel$export(module.exports, "TASK_VARIANTS", ()=>$ea3903bb15047c3b$export$36f4d47dfe11b613);
    var $8Bc5A;
    var $ciLFO;
    const $ea3903bb15047c3b$export$4e0ab25c8a7e4009 = {
        scrumOnboarding: 'experiment.scrumOnboarding',
        customizeSiteName: 'experiment.customizeSiteName',
        jswReverseTrial: 'experiment.jswReverseTrial'
    };
    const $ea3903bb15047c3b$export$c90b54e658fc4d7c = {
        kanbanOnboarding: 'experience.kanbanOnboarding',
        fedRamp: 'experience.fedRamp'
    };
    const $ea3903bb15047c3b$export$1f66f9f05afa05a8 = {
        teamManaged: 'projectStyle.teamManaged',
        companyManaged: 'projectStyle.companyManaged'
    };
    const $ea3903bb15047c3b$export$543c19ee8dba9252 = {
        isCreatedFromCustomerTemplate: 'projectProperties.isCreatedFromCustomerTemplate'
    };
    const $ea3903bb15047c3b$export$eb3485a690c2f0b8 = {
        siteAdmin: 'userRole.siteAdmin',
        jiraAdmin: 'userRole.jiraAdmin',
        admin: 'userRole.admin',
        projectAdmin: 'userRole.projectAdmin'
    };
    const $ea3903bb15047c3b$export$6f4a0f769e61929 = {
        free: 'productEdition.free',
        standard: 'productEdition.standard',
        premium: 'productEdition.premium'
    };
    const $ea3903bb15047c3b$export$e7b0ac011bb776c6 = {
        softwareRoadmap: 'route.softwareRoadmap',
        softwareBacklog: 'route.softwareBacklog',
        softwareBoard: 'route.softwareBoard',
        softwareList: 'route.softwareList',
        projectSettingsSoftwareAccess: 'route.projectSettingsSoftwareAccess',
        issue: 'route.issue',
        softwareCalendar: 'route.softwareCalendar'
    };
    const $ea3903bb15047c3b$export$2d41982586bbb6be = {
        notCanCreateProject: 'permissions.notCanCreateProject',
        hasPlanEditPermissions: 'permissions.hasPlanEditPermissions'
    };
    const $ea3903bb15047c3b$export$2d08cfa40d69142e = {
        crossProjectBoardToPlanUpsell: 'trait.crossProjectBoardToPlanUpsell'
    };
    const $ea3903bb15047c3b$var$DEFAULT_TASK_LIST = [
        (0, (0, parcelRequire("8Bc5A")).ADD_COLUMNS_TASK),
        (0, (0, parcelRequire("8Bc5A")).CREATE_PROJECT_TASK),
        (0, (0, parcelRequire("8Bc5A")).CREATE_ISSUE_TASK),
        (0, (0, parcelRequire("8Bc5A")).CREATE_ISSUE_TASK_SIMPLIFIED),
        (0, (0, parcelRequire("8Bc5A")).TRY_ATLASSIAN_UNIVERSITY_ONBOARDING_TASK),
        (0, (0, parcelRequire("8Bc5A")).INVITE_TEAMMATES_TASK),
        (0, (0, parcelRequire("8Bc5A")).INVITE_TEAMMATES_VIDEO_TASK),
        (0, (0, parcelRequire("8Bc5A")).CAPTURE_INTEGRATIONS_TASK),
        (0, (0, parcelRequire("8Bc5A")).FIND_HELP_TASK),
        (0, (0, parcelRequire("8Bc5A")).SET_UP_BOARD_TASK),
        (0, (0, parcelRequire("8Bc5A")).GET_MOBILE_APP_TASK),
        (0, (0, parcelRequire("8Bc5A")).PREMIUM_REVERSE_TRIAL_TASK),
        (0, (0, parcelRequire("8Bc5A")).PREMIUM_EDITION_OVERVIEW_TASK),
        (0, (0, parcelRequire("8Bc5A")).PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN),
        (0, (0, parcelRequire("8Bc5A")).STANDARD_EDITION_OVERVIEW_TASK),
        (0, (0, parcelRequire("8Bc5A")).MAP_OUT_YOUR_PROJECT_GOALS_TASK),
        (0, (0, parcelRequire("8Bc5A")).IDENTIFY_SMALL_CHUNKS_OF_WORK_TASK),
        (0, (0, parcelRequire("8Bc5A")).MONITOR_MANAGE_RISK_TASK),
        (0, (0, parcelRequire("8Bc5A")).SCRUM_ONBOARDING_TASK),
        (0, (0, parcelRequire("8Bc5A")).CUSTOMIZE_SITE_NAME_TASK),
        (0, (0, parcelRequire("8Bc5A")).GET_TO_KNOW_YOUR_TEMPLATE_TASK),
        (0, (0, parcelRequire("8Bc5A")).CREATE_BUSINESS_PROJECT_FROM_JSW_QUICKSTART_TASK),
        (0, (0, parcelRequire("8Bc5A")).GET_STARTED_LIST_TASK),
        (0, (0, parcelRequire("8Bc5A")).CALENDAR_VIEW_TASK)
    ];
    const $ea3903bb15047c3b$export$601c8c0f45aeb0a0 = Object.freeze({
        root: {
            title: (0, (0, parcelRequire("ciLFO")).messages).rootTitle,
            items: $ea3903bb15047c3b$var$DEFAULT_TASK_LIST
        },
        reverseTrialRoot: {
            title: (0, (0, parcelRequire("ciLFO")).messages).reverseTrialRootTitle,
            items: $ea3903bb15047c3b$var$DEFAULT_TASK_LIST
        },
        [(0, (0, parcelRequire("8Bc5A")).PREMIUM_REVERSE_TRIAL_TASK)]: {
            title: (0, (0, parcelRequire("ciLFO")).messages).reverseTrialCardTitle,
            items: [
                (0, (0, parcelRequire("8Bc5A")).ADVANCED_ROADMAPS_TASK),
                (0, (0, parcelRequire("8Bc5A")).CHANGE_CONTROL_TASK),
                (0, (0, parcelRequire("8Bc5A")).AUTOMATIONS_TASK),
                (0, (0, parcelRequire("8Bc5A")).SITE_ADMINISTRATIONS_TASK),
                (0, (0, parcelRequire("8Bc5A")).USER_MANAGEMENT_TASK)
            ]
        },
        [(0, (0, parcelRequire("8Bc5A")).PREMIUM_EDITION_OVERVIEW_TASK)]: {
            title: (0, (0, parcelRequire("ciLFO")).messages).premiumPlanTitle,
            items: [
                (0, (0, parcelRequire("8Bc5A")).ADVANCED_ROADMAPS_TASK),
                (0, (0, parcelRequire("8Bc5A")).CHANGE_CONTROL_TASK),
                (0, (0, parcelRequire("8Bc5A")).AUTOMATIONS_TASK),
                (0, (0, parcelRequire("8Bc5A")).SITE_ADMINISTRATIONS_TASK),
                (0, (0, parcelRequire("8Bc5A")).USER_MANAGEMENT_TASK)
            ]
        },
        [(0, (0, parcelRequire("8Bc5A")).PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN)]: {
            title: (0, (0, parcelRequire("ciLFO")).messages).premiumPlanTitle,
            items: [
                (0, (0, parcelRequire("8Bc5A")).ADVANCED_ROADMAPS_TASK),
                (0, (0, parcelRequire("8Bc5A")).AUTOMATIONS_TASK)
            ]
        },
        [(0, (0, parcelRequire("8Bc5A")).STANDARD_EDITION_OVERVIEW_TASK)]: {
            title: (0, (0, parcelRequire("ciLFO")).messages).standardPlanTitle,
            items: [
                (0, (0, parcelRequire("8Bc5A")).AUTOMATIONS_TASK),
                (0, (0, parcelRequire("8Bc5A")).USER_MANAGEMENT_TASK),
                (0, (0, parcelRequire("8Bc5A")).SITE_ADMINISTRATIONS_TASK)
            ]
        }
    });
    const $ea3903bb15047c3b$var$PLAN_ONBOARDING_TASKS = {
        [(0, (0, parcelRequire("8Bc5A")).PREMIUM_REVERSE_TRIAL_TASK)]: {
            position: {
                default: 900
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$4e0ab25c8a7e4009.jswReverseTrial,
                            $ea3903bb15047c3b$export$6f4a0f769e61929.premium
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).PREMIUM_EDITION_OVERVIEW_TASK)]: {
            position: {
                default: 900
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$4e0ab25c8a7e4009.jswReverseTrial,
                            $ea3903bb15047c3b$export$6f4a0f769e61929.premium,
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.admin
                        ],
                        value: false
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$6f4a0f769e61929.premium,
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.admin
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN)]: {
            position: {
                default: 900
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$6f4a0f769e61929.premium,
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.admin
                        ],
                        value: false
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$4e0ab25c8a7e4009.jswReverseTrial,
                            $ea3903bb15047c3b$export$6f4a0f769e61929.premium
                        ],
                        value: false
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$6f4a0f769e61929.premium
                        ],
                        value: true
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$2d08cfa40d69142e.crossProjectBoardToPlanUpsell,
                            $ea3903bb15047c3b$export$2d41982586bbb6be.hasPlanEditPermissions
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).STANDARD_EDITION_OVERVIEW_TASK)]: {
            position: {
                default: 900
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$6f4a0f769e61929.standard,
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.admin
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).ADVANCED_ROADMAPS_TASK)]: {
            position: {
                default: 105
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$2d08cfa40d69142e.crossProjectBoardToPlanUpsell,
                            $ea3903bb15047c3b$export$2d41982586bbb6be.hasPlanEditPermissions
                        ],
                        value: true
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$6f4a0f769e61929.premium
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).AUTOMATIONS_TASK)]: {
            position: {
                default: 110
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$2d08cfa40d69142e.crossProjectBoardToPlanUpsell,
                            $ea3903bb15047c3b$export$2d41982586bbb6be.hasPlanEditPermissions
                        ],
                        value: true
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$6f4a0f769e61929.premium,
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.admin
                        ],
                        value: true
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$6f4a0f769e61929.standard,
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.admin
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).USER_MANAGEMENT_TASK)]: {
            position: {
                default: 115
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$6f4a0f769e61929.premium,
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.admin
                        ],
                        value: true
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$6f4a0f769e61929.standard,
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.admin
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).CHANGE_CONTROL_TASK)]: {
            position: {
                default: 120
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$6f4a0f769e61929.premium,
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.siteAdmin
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).SITE_ADMINISTRATIONS_TASK)]: {
            position: {
                default: 125
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$6f4a0f769e61929.premium,
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.siteAdmin
                        ],
                        value: true
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$6f4a0f769e61929.standard,
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.siteAdmin
                        ],
                        value: true
                    }
                ]
            }
        }
    };
    const $ea3903bb15047c3b$var$CUSTOMER_TEMPLATE_ONBOARDING_TASKS = {
        [(0, (0, parcelRequire("8Bc5A")).GET_TO_KNOW_YOUR_TEMPLATE_TASK)]: {
            position: {
                default: 0
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$543c19ee8dba9252.isCreatedFromCustomerTemplate,
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.siteAdmin
                        ],
                        value: true
                    }
                ]
            }
        }
    };
    const $ea3903bb15047c3b$export$36f4d47dfe11b613 = {
        ...$ea3903bb15047c3b$var$PLAN_ONBOARDING_TASKS,
        ...$ea3903bb15047c3b$var$CUSTOMER_TEMPLATE_ONBOARDING_TASKS,
        [(0, (0, parcelRequire("8Bc5A")).CREATE_PROJECT_TASK)]: {
            position: {
                default: 0
            },
            visibility: {
                default: true,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$2d41982586bbb6be.notCanCreateProject
                        ],
                        value: false
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$543c19ee8dba9252.isCreatedFromCustomerTemplate
                        ],
                        value: false
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).CUSTOMIZE_SITE_NAME_TASK)]: {
            position: {
                default: 50
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.siteAdmin,
                            $ea3903bb15047c3b$export$4e0ab25c8a7e4009.customizeSiteName
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).CALENDAR_VIEW_TASK)]: {
            position: {
                default: 100
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        value: true,
                        condition: [
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareCalendar
                        ]
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).SCRUM_ONBOARDING_TASK)]: {
            position: {
                default: 150
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.siteAdmin,
                            $ea3903bb15047c3b$export$4e0ab25c8a7e4009.scrumOnboarding,
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareBacklog
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MAP_OUT_YOUR_PROJECT_GOALS_TASK)]: {
            position: {
                default: 150
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareRoadmap
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).IDENTIFY_SMALL_CHUNKS_OF_WORK_TASK)]: {
            position: {
                default: 151
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareRoadmap
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MONITOR_MANAGE_RISK_TASK)]: {
            position: {
                default: 152
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareRoadmap
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).SET_UP_BOARD_TASK)]: {
            position: {
                default: 200
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$c90b54e658fc4d7c.kanbanOnboarding,
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareBoard
                        ],
                        value: false
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$c90b54e658fc4d7c.kanbanOnboarding
                        ],
                        value: false
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareList
                        ],
                        value: false
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.projectAdmin,
                            $ea3903bb15047c3b$export$1f66f9f05afa05a8.teamManaged
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).CREATE_ISSUE_TASK)]: {
            position: {
                default: 300
            },
            visibility: {
                default: true,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$c90b54e658fc4d7c.kanbanOnboarding,
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareBoard
                        ],
                        value: false
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).CREATE_ISSUE_TASK_SIMPLIFIED)]: {
            position: {
                default: 300
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$c90b54e658fc4d7c.kanbanOnboarding,
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareBoard
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).ADD_COLUMNS_TASK)]: {
            position: {
                default: 310
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$c90b54e658fc4d7c.kanbanOnboarding,
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareBoard
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).GET_STARTED_LIST_TASK)]: {
            position: {
                default: 320
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareList
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).TRY_ATLASSIAN_UNIVERSITY_ONBOARDING_TASK)]: {
            position: {
                default: 350
            },
            visibility: {
                default: true,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$c90b54e658fc4d7c.kanbanOnboarding,
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareBoard
                        ],
                        value: false
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).INVITE_TEAMMATES_TASK)]: {
            position: {
                default: 400
            },
            visibility: {
                default: true,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.projectAdmin,
                            $ea3903bb15047c3b$export$1f66f9f05afa05a8.teamManaged
                        ],
                        value: false
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.admin,
                            $ea3903bb15047c3b$export$1f66f9f05afa05a8.teamManaged
                        ],
                        value: false
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).INVITE_TEAMMATES_VIDEO_TASK)]: {
            position: {
                default: 400,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$c90b54e658fc4d7c.kanbanOnboarding,
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareBoard
                        ],
                        value: 600
                    }
                ]
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.projectAdmin,
                            $ea3903bb15047c3b$export$1f66f9f05afa05a8.teamManaged
                        ],
                        value: true
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$eb3485a690c2f0b8.admin,
                            $ea3903bb15047c3b$export$1f66f9f05afa05a8.teamManaged
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).CAPTURE_INTEGRATIONS_TASK)]: {
            position: {
                default: 500
            },
            visibility: {
                default: true,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$c90b54e658fc4d7c.fedRamp
                        ],
                        value: false
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).GET_MOBILE_APP_TASK)]: {
            position: {
                default: 600
            },
            visibility: {
                default: true,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$c90b54e658fc4d7c.fedRamp
                        ],
                        value: false
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$c90b54e658fc4d7c.kanbanOnboarding,
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareBoard
                        ],
                        value: false
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).FIND_HELP_TASK)]: {
            position: {
                default: 700
            },
            visibility: {
                default: true,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$c90b54e658fc4d7c.kanbanOnboarding,
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareBoard
                        ],
                        value: false
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).CREATE_BUSINESS_PROJECT_FROM_JSW_QUICKSTART_TASK)]: {
            position: {
                default: 800
            },
            visibility: {
                default: true,
                conditional: [
                    {
                        condition: [
                            $ea3903bb15047c3b$export$2d41982586bbb6be.notCanCreateProject
                        ],
                        value: false
                    },
                    {
                        condition: [
                            $ea3903bb15047c3b$export$c90b54e658fc4d7c.kanbanOnboarding,
                            $ea3903bb15047c3b$export$e7b0ac011bb776c6.softwareBoard
                        ],
                        value: false
                    }
                ]
            }
        }
    };
});
parcelRegister("ciLFO", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$609e253829546460$export$defe85febe8b728c);
    var $7VHMR;
    const $609e253829546460$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        rootTitle: {
            "id": "software-onboarding-quickstart.services.tasks.config.jsw.root-title.no-translate",
            "defaultMessage": "Quickstart"
        },
        premiumPlanTitle: {
            "id": "onboarding-quickstart-core.services.tasks.config.jsw.premium-plan-title",
            "defaultMessage": "Included in your Premium plan"
        },
        standardPlanTitle: {
            "id": "onboarding-quickstart-core.services.tasks.config.jsw.standard-plan-title",
            "defaultMessage": "Your Standard plan"
        },
        reverseTrialRootTitle: {
            "id": "onboarding-quickstart-core.services.tasks.config.jsw.reverse-trial-root-title",
            "defaultMessage": "Your 30-day Premium trial"
        },
        reverseTrialCardTitle: {
            "id": "onboarding-quickstart-core.services.tasks.config.jsw.reverse-trial-card-title",
            "defaultMessage": "Included in your Premium trial"
        }
    });
});
parcelRegister("5yUqp", function(module, exports) {
    $parcel$export(module.exports, "JWM", ()=>$80382801c7786de6$export$3ff26c80cb588e12);
    var $82B5j;
    var $8jC8d;
    var $5PFhq;
    const $80382801c7786de6$export$3ff26c80cb588e12 = {
        taskList: (0, (0, parcelRequire("82B5j")).TASK_LIST),
        taskVariants: (0, (0, parcelRequire("82B5j")).TASK_VARIANTS),
        userRole: (0, (0, parcelRequire("5PFhq")).UserRole),
        experiments: (0, (0, parcelRequire("8jC8d")).Experiments)
    };
});
parcelRegister("82B5j", function(module, exports) {
    $parcel$export(module.exports, "TASK_LIST", ()=>$3b1179694682c8c8$export$601c8c0f45aeb0a0);
    $parcel$export(module.exports, "TASK_VARIANTS", ()=>$3b1179694682c8c8$export$36f4d47dfe11b613);
    var $8Bc5A;
    var $8jC8d;
    var $aQMWP;
    var $5PFhq;
    const $3b1179694682c8c8$export$601c8c0f45aeb0a0 = Object.freeze({
        root: {
            title: (0, (0, parcelRequire("aQMWP")).messages).rootTitle,
            items: [
                (0, (0, parcelRequire("8Bc5A")).JWM_CREATE_PROJECT_TASK),
                (0, (0, parcelRequire("8Bc5A")).JWM_TRELLO_CROSSFLOW_TASK_IMPORT),
                (0, (0, parcelRequire("8Bc5A")).JWM_SUMMARY_AND_REPORTS_TASK),
                (0, (0, parcelRequire("8Bc5A")).JWM_TRELLO_CROSSFLOW_CALENDAR_AND_TIMELINE_TASK),
                (0, (0, parcelRequire("8Bc5A")).JWM_TRELLO_CROSSFLOW_TASK_KEY_DIFFERENCES),
                (0, (0, parcelRequire("8Bc5A")).JWM_CREATE_SAMPLE_PROJECT_TASK),
                (0, (0, parcelRequire("8Bc5A")).JWM_PROJECT_SUMMARY_TASK),
                (0, (0, parcelRequire("8Bc5A")).GET_STARTED_LIST_TASK),
                (0, (0, parcelRequire("8Bc5A")).JWM_CALENDAR_TASK),
                (0, (0, parcelRequire("8Bc5A")).JWM_TIMELINE_TASK),
                (0, (0, parcelRequire("8Bc5A")).JWM_BUILD_FORM_TASK),
                (0, (0, parcelRequire("8Bc5A")).JWM_BACKGROUND_COLOR_TASK),
                (0, (0, parcelRequire("8Bc5A")).JWM_FEEDBACK_TASK),
                (0, (0, parcelRequire("8Bc5A")).JWM_FEEDBACK_SAMPLE_PROJECT_TASK),
                (0, (0, parcelRequire("8Bc5A")).JWM_SHARE_PROJECT_TASK),
                (0, (0, parcelRequire("8Bc5A")).JWM_CREATE_YOUR_OWN_PROJECT_TASK)
            ]
        }
    });
    const $3b1179694682c8c8$export$db06b8e338ba9c65 = {
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_3_LIST)]: {
            position: {
                default: 100
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_3_CALENDAR)]: {
            position: {
                default: 200
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_3_PAGES)]: {
            position: {
                default: 300
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_4_LIST)]: {
            position: {
                default: 400
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_4_CALENDAR)]: {
            position: {
                default: 500
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_4_TIMELINE)]: {
            position: {
                default: 600
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_4_SUMMARY)]: {
            position: {
                default: 700
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_5_LIST)]: {
            position: {
                default: 800
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_5_TIMELINE)]: {
            position: {
                default: 900
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_5_PAGES)]: {
            position: {
                default: 1000
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_7_LIST)]: {
            position: {
                default: 1100
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_7_CALENDAR)]: {
            position: {
                default: 1200
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_2_BOARD)]: {
            position: {
                default: 1300
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_2_FORMS)]: {
            position: {
                default: 1400
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_2_SUMMARY)]: {
            position: {
                default: 1500
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_1_LIST)]: {
            position: {
                default: 1600
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_6_PAGES)]: {
            position: {
                default: 1700
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_0_LIST)]: {
            position: {
                default: 1800
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_0_TIMELINE)]: {
            position: {
                default: 1900
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_0_PAGES)]: {
            position: {
                default: 2000
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_DEFAULT_LIST)]: {
            position: {
                default: 2100
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_DEFAULT_BOARD)]: {
            position: {
                default: 2200
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).OPS_JTBD_DEFAULT_INVITES)]: {
            position: {
                default: 2300
            }
        }
    };
    const $3b1179694682c8c8$export$bda7075f98246874 = {
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_1_LIST)]: {
            position: {
                default: 100
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_1_CALENDAR)]: {
            position: {
                default: 200
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_1_TIMELINE)]: {
            position: {
                default: 300
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_2_CALENDAR)]: {
            position: {
                default: 400
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_2_PAGES)]: {
            position: {
                default: 500
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_3_PAGES)]: {
            position: {
                default: 600
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_3_TIMELINE)]: {
            position: {
                default: 700
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_4_LIST)]: {
            position: {
                default: 800
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_4_SUMMARY)]: {
            position: {
                default: 900
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_4_PAGES)]: {
            position: {
                default: 1000
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_5_TIMELINE)]: {
            position: {
                default: 1100
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_5_SUMMARY)]: {
            position: {
                default: 1200
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_6_FORMS)]: {
            position: {
                default: 1300
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_0_LIST)]: {
            position: {
                default: 1400
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_0_CALENDAR)]: {
            position: {
                default: 1500
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_0_TIMELINE)]: {
            position: {
                default: 1600
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_0_SUMMARY)]: {
            position: {
                default: 1700
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_DEFAULT_LIST)]: {
            position: {
                default: 1800
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_DEFAULT_BOARD)]: {
            position: {
                default: 1900
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_DEFAULT_PROJECT)]: {
            position: {
                default: 2000
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).MKT_JTBD_DEFAULT_INVITES)]: {
            position: {
                default: 2100
            }
        }
    };
    const $3b1179694682c8c8$export$36f4d47dfe11b613 = {
        ...$3b1179694682c8c8$export$db06b8e338ba9c65,
        ...$3b1179694682c8c8$export$bda7075f98246874,
        [(0, (0, parcelRequire("8Bc5A")).JWM_CREATE_PROJECT_TASK)]: {
            position: {
                default: 0
            },
            visibility: {
                default: true,
                conditional: [
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isSampleProject
                        ],
                        value: false
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JWM_TRELLO_CROSSFLOW_TASK_KEY_DIFFERENCES)]: {
            position: {
                default: 15
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isNotSampleProject,
                            (0, (0, parcelRequire("8jC8d")).Experiments).trelloCrossflow
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JWM_CREATE_SAMPLE_PROJECT_TASK)]: {
            position: {
                default: 40
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isSampleProject
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JWM_PROJECT_SUMMARY_TASK)]: {
            position: {
                default: 50
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isSampleProject
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).GET_STARTED_LIST_TASK)]: {
            position: {
                default: 100
            },
            visibility: {
                default: true,
                conditional: [
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isSampleProject
                        ],
                        value: true
                    },
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).trelloCrossflow
                        ],
                        value: false
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JWM_CALENDAR_TASK)]: {
            position: {
                default: 200
            },
            visibility: {
                default: true,
                conditional: [
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isSampleProject
                        ],
                        value: true
                    },
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).trelloCrossflow
                        ],
                        value: false
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JWM_TIMELINE_TASK)]: {
            position: {
                default: 300
            },
            visibility: {
                default: true,
                conditional: [
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isSampleProject
                        ],
                        value: true
                    },
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).trelloCrossflow
                        ],
                        value: false
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JWM_BUILD_FORM_TASK)]: {
            position: {
                default: 400
            },
            visibility: {
                default: true
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JWM_TRELLO_CROSSFLOW_CALENDAR_AND_TIMELINE_TASK)]: {
            position: {
                default: 425
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isNotSampleProject,
                            (0, (0, parcelRequire("8jC8d")).Experiments).trelloCrossflow
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JWM_SUMMARY_AND_REPORTS_TASK)]: {
            position: {
                default: 450
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isNotSampleProject,
                            (0, (0, parcelRequire("8jC8d")).Experiments).trelloCrossflow
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JWM_BACKGROUND_COLOR_TASK)]: {
            position: {
                default: 500
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).projectTheming,
                            (0, (0, parcelRequire("5PFhq")).UserRole).jiraAdmin
                        ],
                        value: true
                    },
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).projectTheming,
                            (0, (0, parcelRequire("5PFhq")).UserRole).siteAdmin
                        ],
                        value: true
                    },
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).projectTheming,
                            (0, (0, parcelRequire("5PFhq")).UserRole).projectAdmin
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JWM_SHARE_PROJECT_TASK)]: {
            position: {
                default: 600
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isSampleProject
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JWM_TRELLO_CROSSFLOW_TASK_IMPORT)]: {
            position: {
                default: 650
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isNotSampleProject,
                            (0, (0, parcelRequire("8jC8d")).Experiments).trelloCrossflow,
                            (0, (0, parcelRequire("5PFhq")).UserRole).siteAdmin
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JWM_FEEDBACK_TASK)]: {
            position: {
                default: 700
            },
            visibility: {
                default: true,
                conditional: [
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isSampleProject
                        ],
                        value: false
                    },
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).trelloCrossflow
                        ],
                        value: false
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JWM_FEEDBACK_SAMPLE_PROJECT_TASK)]: {
            position: {
                default: 700
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isSampleProject
                        ],
                        value: true
                    }
                ]
            }
        },
        [(0, (0, parcelRequire("8Bc5A")).JWM_CREATE_YOUR_OWN_PROJECT_TASK)]: {
            position: {
                default: 800
            },
            visibility: {
                default: false,
                conditional: [
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isSampleProject,
                            (0, (0, parcelRequire("5PFhq")).UserRole).jiraAdmin
                        ],
                        value: true
                    },
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isSampleProject,
                            (0, (0, parcelRequire("5PFhq")).UserRole).siteAdmin
                        ],
                        value: true
                    },
                    {
                        condition: [
                            (0, (0, parcelRequire("8jC8d")).Experiments).isSampleProject,
                            (0, (0, parcelRequire("5PFhq")).UserRole).projectAdmin
                        ],
                        value: true
                    }
                ]
            }
        }
    };
});
parcelRegister("8jC8d", function(module, exports) {
    $parcel$export(module.exports, "Experiments", ()=>$6cd173da51fb2ca8$export$c8d6ffd15214553a);
    const $6cd173da51fb2ca8$export$c8d6ffd15214553a = {
        projectTheming: 'projectTheming',
        isSampleProject: 'isSampleProject',
        isNotSampleProject: 'isNotSampleProject',
        trelloCrossflow: 'trelloCrossflow'
    };
});
parcelRegister("aQMWP", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$40755a70921f89dd$export$defe85febe8b728c);
    var $7VHMR;
    const $40755a70921f89dd$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        rootTitle: {
            "id": "software-onboarding-quickstart.services.tasks.config.jwm.root-title.no-translate",
            "defaultMessage": "Quickstart"
        }
    });
});
parcelRegister("5PFhq", function(module, exports) {
    $parcel$export(module.exports, "UserRole", ()=>$637dacd99a3a1727$export$eb3485a690c2f0b8);
    const $637dacd99a3a1727$export$eb3485a690c2f0b8 = {
        projectAdmin: 'projectAdmin',
        jiraAdmin: 'jiraAdmin',
        siteAdmin: 'siteAdmin'
    };
});
parcelRegister("5zzQl", function(module, exports) {
    $parcel$export(module.exports, "getTasks", ()=>$f17c108f7b3045ad$export$20b82b4a32771893);
    var $fNtu3;
    const $f17c108f7b3045ad$var$getTaskVisibility = (variants, visibility)=>visibility !== undefined ? (0, (0, parcelRequire("fNtu3")).getConditional)(visibility, variants) : true;
    const $f17c108f7b3045ad$var$getTaskPosition = (variants, position)=>(0, (0, parcelRequire("fNtu3")).getConditional)(position, variants);
    const $f17c108f7b3045ad$export$20b82b4a32771893 = (variants, taskVariants, taskList)=>taskList.filter((task)=>$f17c108f7b3045ad$var$getTaskVisibility(variants, taskVariants[task].visibility)).sort((taskA, taskB)=>$f17c108f7b3045ad$var$getTaskPosition(variants, taskVariants[taskA].position) - $f17c108f7b3045ad$var$getTaskPosition(variants, taskVariants[taskB].position));
});
parcelRegister("fNtu3", function(module, exports) {
    $parcel$export(module.exports, "getConditional", ()=>$8978a3b25f57d9ac$export$fe3161cde0106bba);
    const $8978a3b25f57d9ac$export$fe3161cde0106bba = ({ conditional: conditions, default: defaultValue }, variants)=>{
        if (conditions === undefined) return defaultValue;
        const variantsIncludesCondition = (c)=>variants.includes(c);
        const conditionObj = conditions.find(({ condition: condition })=>condition.every(variantsIncludesCondition));
        return conditionObj ? conditionObj.value : defaultValue;
    };
});
parcelRegister("jJca1", function(module, exports) {
    $parcel$export(module.exports, "isJwmProject", ()=>$6249819f9bc2846f$export$ea2e5a5c1a1a6748);
    $parcel$export(module.exports, "isJsmProject", ()=>$6249819f9bc2846f$export$a972ed853ed1d469);
    var $2vSU6;
    const $6249819f9bc2846f$export$ea2e5a5c1a1a6748 = (projectState)=>projectState?.projectType === (0, (0, parcelRequire("2vSU6")).CORE_PROJECT);
    const $6249819f9bc2846f$export$a972ed853ed1d469 = (projectState)=>projectState?.projectType === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT);
});
parcelRegister("75A1x", function(module, exports) {
    $parcel$export(module.exports, "syncVisibilityWithLocal", ()=>$f9427b1514e1931c$export$4aab9a98fc53b894);
    var $3B5jO;
    var $4ji4w;
    const $f9427b1514e1931c$export$4aab9a98fc53b894 = ()=>({ getState: getState, setState: setState })=>{
            const state = getState();
            const quickstartType = (0, (0, parcelRequire("3B5jO")).getQuickstartTypeSelector)(state);
            const visibilityLocal = (0, (0, parcelRequire("4ji4w")).getLocalStorageItem)(`${quickstartType}.visibility`);
            if (visibilityLocal === state.visibility[quickstartType]) return;
            setState({
                visibility: {
                    ...state.visibility,
                    [quickstartType]: visibilityLocal
                }
            });
        };
});
parcelRegister("cGE52", function(module, exports) {
    $parcel$export(module.exports, "initialState", ()=>$d175a7a443e10f6d$export$d4c72bab9d6cc13a);
    var $8Bc5A;
    const $d175a7a443e10f6d$export$d4c72bab9d6cc13a = {
        shouldRenderComponent: false,
        completedTaskList: [],
        hasSeenTasks: [],
        isDismissed: true,
        isOpen: false,
        isFocusedOn: false,
        openTask: '',
        projectState: null,
        hasNudgeActive: false,
        isQuickstartLoaded: false,
        isDevOpsProject: false,
        nudgeStep: '',
        mountedNudges: [],
        hasEverSeenRoute: {
            'jsw.onboarding.experiment.personalised.onboarding.ntkw.onboarding.it2.roadmap': false
        },
        roadmapIsCreateEnabled: false,
        roadmapIsListEmpty: true,
        previousTaskGroupParents: [],
        currentTaskGroupParent: (0, (0, parcelRequire("8Bc5A")).ROOT_TASK),
        selectedProjectTemplateKeyFromOnboarding: undefined,
        isEligibleToUpdateSitename: false,
        isCrossProjectBoardToPlanUpsell: false,
        canEditPlans: false,
        visibility: {
            [(0, (0, parcelRequire("8Bc5A")).QUICKSTART_TYPE).JSM_BUSINESS_QUICKSTART_KEY]: (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).NOT_LOADED,
            [(0, (0, parcelRequire("8Bc5A")).QUICKSTART_TYPE).OLD_GLOBAL_QUICKSTART_KEY]: (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).NOT_LOADED
        }
    };
});
parcelRegister("4i99S", function(module, exports) {
    $parcel$export(module.exports, "useOpenChecklist", ()=>$5c528ffca81c6bae$export$318fa8576ad8aa73);
    var $2NMNM;
    var $hsmeR;
    var $94Uc1;
    var $8Bc5A;
    var $cE2Qr;
    var $52Vcw;
    const $5c528ffca81c6bae$export$318fa8576ad8aa73 = ()=>{
        const [{ openTask: currentOpenTask }, { nextSetChecklistIsOpen: nextSetChecklistIsOpen, nextSetChecklistIsDismissed: nextSetChecklistIsDismissed, nextOpenTask: nextOpenTask }] = (0, (0, parcelRequire("cE2Qr")).useJswOnboarding)();
        const { openRightSidebar: openRightSidebar } = (0, (0, parcelRequire("hsmeR")).useRightSidebarController)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const [quickstartStatus] = (0, (0, parcelRequire("cE2Qr")).useQuickstartUiStatus)();
        const currentRightSidebarState = (0, (0, parcelRequire("hsmeR")).useActiveRightSidebarState)();
        const currentPanelId = currentRightSidebarState?.panelId;
        const openChecklist = ({ trigger: trigger, openTask: openTask })=>{
            if (openTask && openTask !== currentOpenTask) nextOpenTask(openTask);
            openRightSidebar((0, (0, parcelRequire("94Uc1")).PANEL_ID), (0, (0, parcelRequire("94Uc1")).PANEL_WIDTH), false, true);
            (0, (0, parcelRequire("52Vcw")).fireAnalyticsOnOpenRightHandSidebar)(createAnalyticsEvent, {
                quickstartStatus: quickstartStatus,
                currentRightSidebarPanelId: currentPanelId || '',
                openRightSidebarToTop: true,
                trigger: trigger
            });
            if (quickstartStatus === (0, (0, parcelRequire("8Bc5A")).QUICKSTART_UI_STATUS).DISMISSED) nextSetChecklistIsDismissed(false);
            else nextSetChecklistIsOpen(true);
        };
        return openChecklist;
    };
});
parcelRegister("94Uc1", function(module, exports) {
    $parcel$export(module.exports, "PANEL_ID", ()=>$5bee65240229f284$export$e1d562d81d0e502d);
    $parcel$export(module.exports, "PANEL_WIDTH", ()=>$5bee65240229f284$export$e05cfff20e9274e6);
    $parcel$export(module.exports, "PERFORMANCE_METRIC_PREFIX", ()=>$5bee65240229f284$export$495d0560d6ea05d0);
    $parcel$export(module.exports, "PERFORMANCE_MARK", ()=>$5bee65240229f284$export$5da8d06749e84d86);
    const $5bee65240229f284$export$e1d562d81d0e502d = 'jsw-nux-onboarding-checklist';
    const $5bee65240229f284$export$e05cfff20e9274e6 = 360;
    const $5bee65240229f284$export$495d0560d6ea05d0 = 'onboarding-quickstart';
    const $5bee65240229f284$export$5da8d06749e84d86 = {
        BUNDLE_LOAD_START: 'bundleLoaded',
        BUNDLE_LOAD_END: 'bundleLoadedEnd'
    };
});
parcelRegister("j5B5j", function(module, exports) {
    $parcel$export(module.exports, "ChangePlansUrlProvider", ()=>$1b260664017d263d$export$9ff771dc2e9e202f);
    var $4Sf7d;
    var $5uXOq = parcelRequire("5uXOq");
    var $2gQ83;
    var $8Uumt = parcelRequire("8Uumt");
    var $dC5iT;
    var $8CUq3;
    var $lfTZh;
    var $koeKL;
    const $1b260664017d263d$var$Query = (0, (0, parcelRequire("4Sf7d")).default);
    const $1b260664017d263d$var$ChangePlansUrlProviderInner = ({ children: children, queryReference: queryReference })=>{
        const data = (0, $8Uumt.usePreloadedQuery)($1b260664017d263d$var$Query, queryReference);
        const changeOfferingCapability = data?.tenantContexts?.[0]?.entitlementInfo?.entitlement?.experienceCapabilities?.changeOffering;
        const changeOfferingUrl = changeOfferingCapability?.experienceUrl;
        const origin = new (0, (0, parcelRequire("2gQ83")).default)({
            product: 'jira'
        });
        const changeOfferingUrlWithAtlOrigin = changeOfferingUrl ? origin.addToUrl(changeOfferingUrl) : null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children(changeOfferingUrlWithAtlOrigin));
    };
    const $1b260664017d263d$export$9ff771dc2e9e202f = ({ children: children })=>{
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const [queryReference, loadQuery] = (0, $8Uumt.useQueryLoader)($1b260664017d263d$var$Query);
        (0, $5uXOq.useEffect)(()=>{
            if (queryReference === null) loadQuery({
                cloudId: cloudId,
                productKey: 'jira-software.ondemand',
                offeringKey: 'de2887c9-8a55-41d5-b5cf-ad6a5589ebed'
            });
        }, [
            cloudId,
            loadQuery,
            queryReference
        ]);
        if (!queryReference) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "reverse-trials-popup-change-plans",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                as: "li",
                xcss: $1b260664017d263d$var$skeletonStyles
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($1b260664017d263d$var$ChangePlansUrlProviderInner, {
            queryReference: queryReference
        }, children));
    };
    const $1b260664017d263d$var$skeletonStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        height: '20px',
        marginTop: 'space.0',
        backgroundColor: 'elevation.surface.hovered'
    });
});
parcelRegister("4Sf7d", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$05014b6f280d5660$export$2e2bcd8739ae039);
    const $05014b6f280d5660$var$node = function() {
        var v0 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cloudId"
        }, v1 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "offeringKey"
        }, v2 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "productKey"
        }, v3 = [
            {
                "items": [
                    {
                        "kind": "Variable",
                        "name": "cloudIds.0",
                        "variableName": "cloudId"
                    }
                ],
                "kind": "ListValue",
                "name": "cloudIds"
            }
        ], v4 = [
            {
                "kind": "Variable",
                "name": "hamsProductKey",
                "variableName": "productKey"
            }
        ], v5 = [
            {
                "kind": "Variable",
                "name": "offeringKey",
                "variableName": "offeringKey"
            }
        ], v6 = {
            "kind": "ScalarField",
            "name": "experienceUrl"
        }, v7 = {
            "kind": "ScalarField",
            "name": "__typename"
        };
        return {
            "fragment": {
                "argumentDefinitions": [
                    v0,
                    v1,
                    v2
                ],
                "kind": "Fragment",
                "name": "changePlansUrlProviderQuery",
                "selections": [
                    {
                        "args": v3,
                        "concreteType": "TenantContext",
                        "kind": "LinkedField",
                        "name": "tenantContexts",
                        "plural": true,
                        "selections": [
                            {
                                "args": v4,
                                "kind": "LinkedField",
                                "name": "entitlementInfo",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "LinkedField",
                                        "name": "entitlement",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "LinkedField",
                                                "name": "experienceCapabilities",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "args": v5,
                                                        "kind": "LinkedField",
                                                        "name": "changeOffering",
                                                        "plural": false,
                                                        "selections": [
                                                            v6
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": [
                    v0,
                    v2,
                    v1
                ],
                "kind": "Operation",
                "name": "changePlansUrlProviderQuery",
                "selections": [
                    {
                        "args": v3,
                        "concreteType": "TenantContext",
                        "kind": "LinkedField",
                        "name": "tenantContexts",
                        "plural": true,
                        "selections": [
                            {
                                "args": v4,
                                "kind": "LinkedField",
                                "name": "entitlementInfo",
                                "plural": false,
                                "selections": [
                                    v7,
                                    {
                                        "kind": "LinkedField",
                                        "name": "entitlement",
                                        "plural": false,
                                        "selections": [
                                            v7,
                                            {
                                                "kind": "LinkedField",
                                                "name": "experienceCapabilities",
                                                "plural": false,
                                                "selections": [
                                                    v7,
                                                    {
                                                        "args": v5,
                                                        "kind": "LinkedField",
                                                        "name": "changeOffering",
                                                        "plural": false,
                                                        "selections": [
                                                            v7,
                                                            v6
                                                        ]
                                                    }
                                                ]
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "id"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "5cc154205f88b37c4f3711723d2c2d442e6c0cc8b52d51ac23970adce7e18d56",
                "metadata": {},
                "name": "changePlansUrlProviderQuery",
                "operationKind": "query",
                "text": null
            }
        };
    }();
    $05014b6f280d5660$var$node.hash = "1760b2a2f58cf86916d00a388c2bff98";
    var $05014b6f280d5660$export$2e2bcd8739ae039 = $05014b6f280d5660$var$node;
});
parcelRegister("5OXJT", function(module, exports) {
    $parcel$export(module.exports, "ListItem", ()=>$3ba212747341c36c$export$a7b06bfba60a8a78);
    var $5uXOq = parcelRequire("5uXOq");
    var $9yOBd;
    var $1FouZ;
    var $17Ngn;
    var $jhmo0;
    var $dC5iT;
    var $kgoHi;
    var $bgrar;
    var $8CUq3;
    const $3ba212747341c36c$var$ListItemContent = ({ title: title, icon: icon, hasLink: hasLink })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bgrar")).default), {
            templateColumns: "16px 1fr 16px",
            columnGap: "space.200",
            alignItems: "center"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), null, icon), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), null, title), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), null, hasLink ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("17Ngn"))))), {
            label: "",
            size: "small"
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("1FouZ"))))), {
            label: "",
            size: "small"
        })));
    const $3ba212747341c36c$export$a7b06bfba60a8a78 = ({ title: title, link: link, icon: icon, onClick: onClick })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kgoHi")).default), {
            as: "li",
            xcss: $3ba212747341c36c$var$listItemStyles,
            direction: "column"
        }, link ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jhmo0")).default), {
            href: link,
            target: "_blank",
            xcss: $3ba212747341c36c$var$linkStyles,
            onClick: onClick
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($3ba212747341c36c$var$ListItemContent, {
            title: title,
            icon: icon,
            hasLink: true
        })) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9yOBd")).default), {
            onClick: onClick,
            theme: (current, themeProps)=>({
                    buttonStyles: {
                        ...current(themeProps).buttonStyles,
                        padding: 0,
                        height: 'auto',
                        width: '100%',
                        background: 'none',
                        fontWeight: 400,
                        textAlign: 'left',
                        lineHeight: '18px'
                    },
                    spinnerStyles: current(themeProps).spinnerStyles
                })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($3ba212747341c36c$var$ListItemContent, {
            title: title,
            icon: icon
        })));
    const $3ba212747341c36c$var$listItemStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginTop: 'space.0',
        paddingBlock: 'space.100'
    });
    const $3ba212747341c36c$var$linkStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        color: 'color.text',
        fontWeight: 400,
        lineHeight: '18px',
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'underline'
        }
    });
});
parcelRegister("1VQfH", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$06a6220a6c43b429$export$2e2bcd8739ae039);
    var $7VHMR;
    var $06a6220a6c43b429$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "reverse-trial.reverse-trial-popup.title",
            "defaultMessage": "Your 30-day Premium trial"
        },
        explorePremiumFeaturesLink: {
            "id": "reverse-trial.reverse-trial-popup.explore-premium-features-link",
            "defaultMessage": "Explore Premium features"
        },
        addPaymentDetailsLink: {
            "id": "reverse-trial.reverse-trial-popup.add-payment-details-link",
            "defaultMessage": "Add payment details to stay on Premium"
        },
        endTrialAndChangePlansLink: {
            "id": "reverse-trial.reverse-trial-popup.end-trial-and-change-plans-link",
            "defaultMessage": "End trial and change plans"
        },
        afterTrial: {
            "id": "reverse-trial.reverse-trial-popup.after-trial",
            "defaultMessage": "After your trial, we\u2019ll downgrade you to Free."
        }
    });
});
parcelRegister("87Yl2", function(module, exports) {
    $parcel$export(module.exports, "PREMIUM_FEATURES_COHORT_VARIANT", ()=>$80cb9dffe755a291$export$3ca3221d1387581f);
    $parcel$export(module.exports, "PREMIUM_FEATURES_COHORT_NOT_ENROLLED", ()=>$80cb9dffe755a291$export$5ba04107d00ba4b3);
    $parcel$export(module.exports, "getJiraFeaturesDropdownSupportedEdition", ()=>$80cb9dffe755a291$export$e8c04824368b2485);
    $parcel$export(module.exports, "getDemonstratingTheValueOfJiraFeaturesCohort", ()=>$80cb9dffe755a291$export$7919f8d75a7caaef);
    $parcel$export(module.exports, "firePremiumFeaturesExperimentExposure", ()=>$80cb9dffe755a291$export$b63b014dacf001a6);
    var $6s1PB;
    var $4R6GH;
    var $8zOmE;
    var $ldyPw;
    const $80cb9dffe755a291$export$3ca3221d1387581f = 'variant';
    const $80cb9dffe755a291$export$931fa98e3493cab7 = 'control';
    const $80cb9dffe755a291$export$5ba04107d00ba4b3 = 'not-enrolled';
    const $80cb9dffe755a291$var$INELIGIBLE_REASON_GATE_NOT_PASSED = 'feature-gate-not-passed';
    const $80cb9dffe755a291$var$INELIGIBLE_REASON_NOT_ENROLLED = 'not-enrolled';
    const $80cb9dffe755a291$var$INELIGIBLE_REASON_UNSUPPORTED_PRODUCT_EDITION = 'unsupported-product-edition';
    const $80cb9dffe755a291$var$INELIGIBLE_REASON_IN_GRACE_PERIOD = 'in-grace-period';
    function $80cb9dffe755a291$export$50689110b3bc8431({ edition: edition, productKey: productKey, inGracePeriod: inGracePeriod }) {
        if (!(0, (0, parcelRequire("4R6GH")).fg)('demonstrating_value_of_jira_premium_features_gate')) return $80cb9dffe755a291$var$INELIGIBLE_REASON_GATE_NOT_PASSED;
        const isSupportedStandard = edition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION);
        const isSupportedPremium = edition === (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION) && (productKey === 'jira' || productKey === 'jira-core' || productKey === 'jira-software');
        const isSupportedEdition = isSupportedStandard || isSupportedPremium;
        if (!isSupportedEdition) return $80cb9dffe755a291$var$INELIGIBLE_REASON_UNSUPPORTED_PRODUCT_EDITION;
        if (inGracePeriod) return $80cb9dffe755a291$var$INELIGIBLE_REASON_IN_GRACE_PERIOD;
        return null;
    }
    function $80cb9dffe755a291$var$getDemonstratingTheValueofJiraFeaturesExperiment() {
        const [config] = (0, (0, parcelRequire("6s1PB")).UNSAFE_noExposureExp)('demonstrating_the_value_of_jira_premium_features');
        return config.get('cohort', $80cb9dffe755a291$export$5ba04107d00ba4b3);
    }
    const $80cb9dffe755a291$export$e8c04824368b2485 = (edition, productKey)=>{
        const isSupportedStandard = edition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION);
        const isSupportedPremium = edition === (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION) && (productKey === 'jira' || productKey === 'jira-core' || productKey === 'jira-software');
        const isSupportedEdition = isSupportedStandard || isSupportedPremium;
        if (!isSupportedEdition) throw new Error('Unsupported product edition');
        return edition;
    };
    const $80cb9dffe755a291$export$7919f8d75a7caaef = ({ shouldFire: shouldFire = true, ...args })=>{
        const reason = $80cb9dffe755a291$export$50689110b3bc8431(args);
        if (reason) return {
            cohort: $80cb9dffe755a291$export$5ba04107d00ba4b3,
            reason: reason
        };
        const cohort = shouldFire ? (0, (0, parcelRequire("6s1PB")).expVal)('demonstrating_the_value_of_jira_premium_features', 'cohort', 'not-enrolled') : $80cb9dffe755a291$var$getDemonstratingTheValueofJiraFeaturesExperiment();
        return {
            cohort: cohort,
            reason: cohort === $80cb9dffe755a291$export$5ba04107d00ba4b3 ? $80cb9dffe755a291$var$INELIGIBLE_REASON_NOT_ENROLLED : null
        };
    };
    const $80cb9dffe755a291$export$b63b014dacf001a6 = ({ productKey: productKey, edition: edition, inGracePeriod: inGracePeriod, createAnalyticsEvent: createAnalyticsEvent })=>{
        const { cohort: cohort, reason: reason } = $80cb9dffe755a291$export$7919f8d75a7caaef({
            productKey: productKey,
            edition: edition,
            inGracePeriod: inGracePeriod
        });
        if (reason && reason !== $80cb9dffe755a291$var$INELIGIBLE_REASON_GATE_NOT_PASSED && reason !== $80cb9dffe755a291$var$INELIGIBLE_REASON_NOT_ENROLLED) (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(createAnalyticsEvent({}), 'eligibility checked', {
            flagKey: 'demonstrating_the_value_of_jira_premium_features',
            ineligibilityReason: reason
        });
        if (cohort !== $80cb9dffe755a291$export$5ba04107d00ba4b3) (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(createAnalyticsEvent({}), 'feature exposed', {
            flagKey: 'demonstrating_the_value_of_jira_premium_features',
            value: cohort,
            growthInitiative: 'buy'
        });
        return {
            cohort: cohort
        };
    };
});
parcelRegister("61Rd1", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6683ec13cb4bf534$export$2e2bcd8739ae039);
    var $7VHMR;
    var $6683ec13cb4bf534$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        addPaymentDetailsPopupStandardTitle: {
            "id": "navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.billing-details-popup.add-payment-details-popup-standard-title",
            "defaultMessage": "Standard trial"
        },
        addPaymentDetailsPopupPremiumTitle: {
            "id": "navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.billing-details-popup.add-payment-details-popup-premium-title",
            "defaultMessage": "Premium trial"
        },
        addPaymentDetailsPopupStandardContent: {
            "id": "navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.billing-details-popup.add-payment-details-popup-standard-content",
            "defaultMessage": "To stay on the Standard plan after your trial, add your payment details."
        },
        addPaymentDetailsPopupPremiumContent: {
            "id": "navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.billing-details-popup.add-payment-details-popup-premium-content",
            "defaultMessage": "To stay on the Premium plan after your trial, add your payment details."
        },
        addPaymentDetailsPopupButton: {
            "id": "navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.billing-details-popup.add-payment-details-popup-button",
            "defaultMessage": "Add payment details"
        }
    });
});
parcelRegister("eb7dL", function(module, exports) {
    $parcel$export(module.exports, "productAbbreviations", ()=>$ae278b6315a6d207$export$80fc9257f50a9ad4);
    const $ae278b6315a6d207$export$80fc9257f50a9ad4 = {
        'jira-software': 'jsw',
        'jira-servicedesk': 'jsm',
        'jira-product-discovery': 'jpd',
        'jira-customer-service': 'jcs',
        'jira-core': 'jwm',
        jira: 'jira'
    };
});
parcelRegister("8qT8y", function(module, exports) {
    $parcel$export(module.exports, "Dropdown", ()=>$4ad63844d8ba4e6e$export$931cbfb6bfb85fc);
    var $5uXOq = parcelRequire("5uXOq");
    var $1I7E8;
    var $dvyoy;
    var $fElvF;
    var $7HwtN;
    var $47gW9;
    var $7XluA;
    var $4mO32;
    var $7mPT9;
    var $lpwtK;
    var $AZuXX;
    var $iXVV6;
    var $5oFIy;
    var $8zOmE;
    var $2NMNM;
    var $70Wxp;
    var $e2N0p;
    var $koeKL;
    var $87Yl2;
    const $4ad63844d8ba4e6e$var$useAutoOpenPopover = (openDialog, analyticsAttributes)=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const onPopupToggle = (0, $5uXOq.useCallback)(()=>{
            openDialog();
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'toggled',
                actionSubject: 'popup'
            }), 'editionAwarenessTrialPillPopup', {
                ...analyticsAttributes,
                flagKey: 'demonstrating_the_value_of_jira_premium_features',
                autoOpened: true,
                premiumFeaturesCohort: (0, (0, parcelRequire("87Yl2")).PREMIUM_FEATURES_COHORT_VARIANT)
            });
        }, [
            openDialog,
            createAnalyticsEvent,
            analyticsAttributes
        ]);
        const { premiumTrialOnSignup: premiumTrialOnSignup, isReady: isReady } = (0, (0, parcelRequire("70Wxp")).useFetchOnboardingReverseTrial)();
        (0, (0, parcelRequire("e2N0p")).useForceOpenPremiumPopupOnDay3)({
            shouldRun: Boolean(isReady && premiumTrialOnSignup),
            onPopupToggle: onPopupToggle
        });
    };
    const $4ad63844d8ba4e6e$export$931cbfb6bfb85fc = ({ productKey: productKey, applicationEdition: applicationEdition, children: children, status: status, analyticsAttributes: analyticsAttributes, trialEndTime: trialEndTime, isInvoiceable: isInvoiceable })=>{
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const { data: project } = (0, (0, parcelRequire("lpwtK")).useProject)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const [dialogOpen, { on: openDialog, toggle: toggleDialog, off: closeDialog }] = (0, (0, parcelRequire("AZuXX")).useBoolean)(false);
        const handleTogglePopup = (0, $5uXOq.useCallback)((_event, analyticsEvent)=>{
            const updatedEvent = analyticsEvent.update((payload)=>({
                    ...payload,
                    action: 'toggled',
                    actionSubject: 'popup'
                }));
            const show = !dialogOpen;
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(updatedEvent, 'editionAwarenessTrialPillPopup', {
                ...analyticsAttributes,
                show: show,
                flagKey: 'demonstrating_the_value_of_jira_premium_features',
                premiumFeaturesCohort: (0, (0, parcelRequire("87Yl2")).PREMIUM_FEATURES_COHORT_VARIANT)
            });
            if (show) (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'button'
            }), 'editionAwarenessTrialPill', {
                ...analyticsAttributes,
                premiumFeaturesCohort: (0, (0, parcelRequire("87Yl2")).PREMIUM_FEATURES_COHORT_VARIANT)
            });
            toggleDialog();
            (0, (0, parcelRequire("87Yl2")).firePremiumFeaturesExperimentExposure)({
                productKey: productKey,
                edition: applicationEdition,
                inGracePeriod: false,
                createAnalyticsEvent: createAnalyticsEvent
            });
        }, [
            analyticsAttributes,
            applicationEdition,
            createAnalyticsEvent,
            dialogOpen,
            productKey,
            toggleDialog
        ]);
        const entryPointParams = (0, $5uXOq.useMemo)(()=>({
                cloudId: cloudId,
                productKey: productKey,
                projectKey: project?.key
            }), [
            cloudId,
            productKey,
            project?.key
        ]);
        const { entryPointActions: entryPointActions, entryPointReferenceSubject: entryPointReferenceSubject } = (0, (0, parcelRequire("4mO32")).useEntryPoint)((0, (0, parcelRequire("7HwtN")).editionAwarenessDropdownEntryPoint), entryPointParams);
        const loadEntryPoint = entryPointActions.load;
        const onAutoOpen = (0, $5uXOq.useCallback)(()=>{
            loadEntryPoint();
            openDialog();
            (0, (0, parcelRequire("87Yl2")).firePremiumFeaturesExperimentExposure)({
                productKey: productKey,
                edition: applicationEdition,
                inGracePeriod: false,
                createAnalyticsEvent: createAnalyticsEvent
            });
        }, [
            applicationEdition,
            createAnalyticsEvent,
            loadEntryPoint,
            openDialog,
            productKey
        ]);
        $4ad63844d8ba4e6e$var$useAutoOpenPopover(onAutoOpen, analyticsAttributes);
        const triggerRef = (0, (0, parcelRequire("47gW9")).useEntryPointButtonTrigger)(entryPointActions, true);
        const onFeatureClick = (0, $5uXOq.useCallback)((e, analyticsEvent, featureId)=>{
            if (featureId === (0, $3ea4f07209ba1295$export$7d1e0ac6dfe346c1)) handleTogglePopup(e, analyticsEvent);
        }, [
            handleTogglePopup
        ]);
        const runtimeProps = (0, $5uXOq.useMemo)(()=>({
                productKey: productKey,
                applicationEdition: applicationEdition,
                onFeatureClick: onFeatureClick,
                trialEndTime: trialEndTime,
                onClose: closeDialog,
                isInvoiceable: !!isInvoiceable
            }), [
            productKey,
            applicationEdition,
            onFeatureClick,
            trialEndTime,
            closeDialog,
            isInvoiceable
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: "editionAwareness",
            sourceType: (0, $4d5e0871c06cee03$export$d1123261ca8831d2)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fElvF")).InvitePeopleModalContextProvider), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1I7E8")).default), {
            isOpen: dialogOpen,
            onClose: closeDialog,
            placement: "bottom-end",
            content: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7XluA")).JiraEntryPointContainer), {
                    entryPointReferenceSubject: entryPointReferenceSubject,
                    id: "editionAwarenessDropdown",
                    packageName: "@atlassian/jira-navigation-apps-sidebar-edition-awareness",
                    teamName: "growth-tako",
                    runtimeProps: runtimeProps,
                    fallback: null
                }),
            trigger: (triggerProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dvyoy")).EditionAwarenessButton), {
                    ...triggerProps,
                    onClick: handleTogglePopup,
                    ref: (0, (0, parcelRequire("7mPT9")).mergeRefs)(triggerProps.ref, triggerRef),
                    status: status,
                    upgradeIconType: "gem"
                }, children)
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), null));
    };
});
parcelRegister("7HwtN", function(module, exports) {
    $parcel$export(module.exports, "editionAwarenessDropdownEntryPoint", ()=>$8eed9654f212e17c$export$6174a9b4a39703f0);
    var $2A44E;
    var $eufEc;
    var $fEDBv;
    const $8eed9654f212e17c$export$6174a9b4a39703f0 = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("73HnX")), {
            moduleId: "23d579fa"
        }),
        getPreloadProps: ({ cloudId: cloudId, productKey: productKey, projectKey: projectKey })=>({
                entryPoints: {},
                extraProps: {
                    projectKey: projectKey
                },
                queries: {
                    editionAwareness: {
                        parameters: (0, (0, parcelRequire("2A44E")).default),
                        variables: {
                            cloudId: cloudId,
                            hamsProductKey: productKey,
                            projectKey: projectKey ?? '',
                            hasProjectKey: (projectKey ?? '').trim().length > 0
                        }
                    }
                }
            })
    });
});
parcelRegister("2A44E", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2040a1acef6661fe$export$2e2bcd8739ae039);
    const $2040a1acef6661fe$var$node = {
        "kind": "PreloadableConcreteRequest",
        "params": {
            "id": "7f341057c760f4aae078dd67aac57ffbf214d101f22b50313c566bd001d07f0c",
            "metadata": {},
            "name": "uiWrapperEditionAwarenessDropdownWrapperQuery",
            "operationKind": "query",
            "text": null
        }
    };
    var $2040a1acef6661fe$export$2e2bcd8739ae039 = $2040a1acef6661fe$var$node;
});
parcelRegister("73HnX", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("eNmuD"),
        $6fhBS("chVLP")
    ]).then(()=>parcelRequire('bOFuj'));
});
const $3ea4f07209ba1295$export$7d1e0ac6dfe346c1 = 'invite-people';
const $3ea4f07209ba1295$export$abfb3fcc6de06e8 = 'automations';
const $3ea4f07209ba1295$export$512ef3af712e0daf = 'user-activity-logs';
const $3ea4f07209ba1295$export$ce2cd9308980f6d = 'atlassian-intelligence';
const $3ea4f07209ba1295$export$423cb3369ffa713 = 'issue-permissions';
const $3ea4f07209ba1295$export$5e1287b9e6d3c46a = 'custom-project-roles';
const $3ea4f07209ba1295$export$82c25e7c6c8c0a93 = 'data-residency';
const $3ea4f07209ba1295$export$1466466c54b5d969 = 'audit-logs';
const $3ea4f07209ba1295$export$b6970527fbedd427 = 'custom-branding';
const $3ea4f07209ba1295$export$511697131d9ae829 = 'change-management';
const $3ea4f07209ba1295$export$7d4b5a5e9873378b = 'incident-management';
const $3ea4f07209ba1295$export$29ed47667495f403 = 'assets';
const $3ea4f07209ba1295$export$2ab1d308787c64ab = 'virtual-service-agent';
parcelRegister("AZuXX", function(module, exports) {
    $parcel$export(module.exports, "useBoolean", ()=>$d5a66367e23ac50d$export$c32275ecbd12948f);
    var $5uXOq = parcelRequire("5uXOq");
    function $d5a66367e23ac50d$export$c32275ecbd12948f(initialValue = false) {
        if (typeof initialValue !== 'boolean') throw new Error('initialValue must be `true` or `false`');
        const [value, setValue] = (0, $5uXOq.useState)(initialValue);
        const handlers = (0, $5uXOq.useMemo)(()=>({
                toggle: ()=>setValue((prevValue)=>!prevValue),
                on: ()=>setValue(true),
                off: ()=>setValue(false),
                setValue: setValue
            }), []);
        return [
            value,
            handlers
        ];
    }
});
parcelRegister("jzLyJ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f6a4f4791eb95a6a$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $lfTZh;
    var $kxZPA;
    const $f6a4f4791eb95a6a$var$LazyNav4RolloutAnalytics = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("5ujqm")).then(({ Nav4RolloutAnalytics: Nav4RolloutAnalytics })=>Nav4RolloutAnalytics), {
        moduleId: "./src/packages/navigation-apps/sidebar-nav4/rollout/analytics/src/index.tsx"
    });
    const $f6a4f4791eb95a6a$var$AsyncNav4RolloutAnalytics = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "async-nav4-rollout-analytics",
            fallback: "unmount",
            packageName: "jiraNavigationAppsSidebarNav4RolloutAnalytics",
            teamName: "jira-cosmos"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "lazy-nav4-rollout-analytics"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($f6a4f4791eb95a6a$var$LazyNav4RolloutAnalytics, null)));
    $f6a4f4791eb95a6a$var$AsyncNav4RolloutAnalytics.displayName = 'AsyncNav4RolloutAnalytics';
    var $f6a4f4791eb95a6a$export$2e2bcd8739ae039 = $f6a4f4791eb95a6a$var$AsyncNav4RolloutAnalytics;
});
parcelRegister("5ujqm", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("5pPTh").then(()=>parcelRequire('1O92x'));
});
parcelRegister("apeap", function(module, exports) {
    $parcel$export(module.exports, "useRecentActions", ()=>$c11b2121dbe8e38b$export$5c0647984dfa7d27);
    $parcel$export(module.exports, "useRecentAllSingleList", ()=>$c11b2121dbe8e38b$export$9a54f46f5b7734c);
    $parcel$export(module.exports, "useWorkedOnAllSingleList", ()=>$c11b2121dbe8e38b$export$e8278a7bcff1ec98);
    $parcel$export(module.exports, "useRecentBoards", ()=>$c11b2121dbe8e38b$export$3731bd023be4f71);
    $parcel$export(module.exports, "useRecentDashboards", ()=>$c11b2121dbe8e38b$export$81cb5beef51d43ca);
    $parcel$export(module.exports, "useRecentProjects", ()=>$c11b2121dbe8e38b$export$f6b9a13c4ec08b45);
    $parcel$export(module.exports, "useRecentFilters", ()=>$c11b2121dbe8e38b$export$8d2bfb6d3657bae2);
    $parcel$export(module.exports, "useRecentQueues", ()=>$c11b2121dbe8e38b$export$8756abb0bb90a56e);
    $parcel$export(module.exports, "RecentsContainer", ()=>$c11b2121dbe8e38b$export$5de14a1c1400859e);
    var $165Uv;
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    const $c11b2121dbe8e38b$var$emptyItemState = {
        items: null,
        isFetching: false,
        fetchError: null,
        promise: null,
        hasFetchedOnce: false,
        hasSuccessOnce: false
    };
    const $c11b2121dbe8e38b$export$d4c72bab9d6cc13a = {
        [(0, $49a076eb01652100$export$f95f1a684a17f189)]: {
            ...$c11b2121dbe8e38b$var$emptyItemState
        },
        [(0, $49a076eb01652100$export$6e58b6b0aa48a271)]: {
            ...$c11b2121dbe8e38b$var$emptyItemState
        },
        [(0, $49a076eb01652100$export$6737b9227f60f39d)]: {
            ...$c11b2121dbe8e38b$var$emptyItemState
        },
        [(0, $49a076eb01652100$export$91a9dba2d38d9a6f)]: {
            ...$c11b2121dbe8e38b$var$emptyItemState
        },
        [(0, $49a076eb01652100$export$20f1031cc9fdf29e)]: {
            ...$c11b2121dbe8e38b$var$emptyItemState
        },
        workedOn: {
            ...$c11b2121dbe8e38b$var$emptyItemState
        },
        all: {
            ...$c11b2121dbe8e38b$var$emptyItemState
        }
    };
    const $c11b2121dbe8e38b$var$transformItems = (items)=>items.reduce((acc, itemResponse)=>{
            if (itemResponse.id === 'quick-search-boards') acc[0, $49a076eb01652100$export$f95f1a684a17f189] = itemResponse.items;
            if (itemResponse.id === 'quick-search-filters') acc[0, $49a076eb01652100$export$6737b9227f60f39d] = itemResponse.items;
            if (itemResponse.id === 'quick-search-dashboards') acc[0, $49a076eb01652100$export$6e58b6b0aa48a271] = itemResponse.items;
            if (itemResponse.id === 'quick-search-projects') acc[0, $49a076eb01652100$export$91a9dba2d38d9a6f] = itemResponse.items;
            if (itemResponse.id === 'quick-search-queues') acc[0, $49a076eb01652100$export$20f1031cc9fdf29e] = itemResponse.items;
            return acc;
        }, {});
    const $c11b2121dbe8e38b$var$transformWorkedOnItems = (response)=>response.data.activities.myActivities.workedOn.nodes.map(({ object: { id: id, localResourceId: localResourceId, name: name, extension: extension, iconUrl: iconUrl, url: url, containers: containers, type: type } })=>({
                id: id,
                localResourceId: localResourceId,
                title: name,
                metadata: extension.issueKey,
                avatarUrl: iconUrl,
                url: url,
                attributes: {
                    containerName: containers.find((container)=>container.type === 'PROJECT')?.name
                },
                type: `${type.toLowerCase()}s`
            }));
    const $c11b2121dbe8e38b$var$getQueryConfig = (amount, types)=>types.reduce((acc, type)=>{
            acc[type] = amount;
            return acc;
        }, {});
    const $c11b2121dbe8e38b$var$loadRecentItems = (amount = (0, $e706d38cada11fb1$export$22ea57e66775a6b2), types)=>async ({ setState: setState, getState: getState })=>{
            const typesToFetch = types.filter((type)=>!getState()[type].isFetching);
            if (typesToFetch.length) try {
                const promise = (0, (0, parcelRequire("165Uv")).fetchRecentItems)({
                    counts: $c11b2121dbe8e38b$var$getQueryConfig(amount, typesToFetch)
                });
                const loadingState = typesToFetch.reduce((acc, type)=>{
                    acc[type] = {
                        ...getState()[type],
                        isFetching: true,
                        fetchError: null,
                        promise: promise
                    };
                    return acc;
                }, {});
                setState(loadingState);
                const itemResponse = await promise;
                const transformed = $c11b2121dbe8e38b$var$transformItems(itemResponse || []);
                const responseState = typesToFetch.reduce((acc, type)=>{
                    acc[type] = {
                        items: transformed[type],
                        isFetching: false,
                        promise: null,
                        fetchError: null,
                        hasFetchedOnce: true,
                        hasSuccessOnce: true
                    };
                    return acc;
                }, {});
                setState(responseState);
            } catch (fetchError) {
                const errorState = typesToFetch.reduce((acc, type)=>{
                    acc[type] = {
                        ...getState()[type],
                        isFetching: false,
                        promise: null,
                        fetchError: fetchError,
                        hasFetchedOnce: true
                    };
                    return acc;
                }, {});
                setState(errorState);
            }
        };
    const $c11b2121dbe8e38b$var$loadRecentItemsSingleList = (amount = (0, $e706d38cada11fb1$export$5f1b6c5e9d257e5e), types)=>async ({ setState: setState, getState: getState })=>{
            if (types.length) try {
                const promise = (0, (0, parcelRequire("165Uv")).fetchRecentItemsSingleList)({
                    types: types,
                    limit: amount
                });
                const loadingState = {
                    all: {
                        ...getState().all,
                        isFetching: true,
                        fetchError: null,
                        promise: promise
                    }
                };
                setState(loadingState);
                const itemResponse = await promise;
                const responseState = {
                    all: {
                        items: itemResponse != null ? itemResponse[0].items : [],
                        isFetching: false,
                        promise: null,
                        fetchError: null,
                        hasFetchedOnce: true,
                        hasSuccessOnce: true
                    }
                };
                setState(responseState);
            } catch (fetchError) {
                const errorState = {
                    all: {
                        ...getState().all,
                        isFetching: false,
                        promise: null,
                        fetchError: fetchError,
                        hasFetchedOnce: true
                    }
                };
                setState(errorState);
            }
        };
    const $c11b2121dbe8e38b$var$loadWorkedOnItems = (amount = (0, $e706d38cada11fb1$export$8666f31c8873bff))=>async ({ setState: setState, getState: getState }, { cloudId: cloudId })=>{
            try {
                const promise = (0, (0, parcelRequire("165Uv")).fetchWorkedOnItems)({
                    cloudId: cloudId,
                    amount: amount
                });
                const loadingState = {
                    workedOn: {
                        ...getState().workedOn,
                        isFetching: true,
                        fetchError: null,
                        promise: promise
                    }
                };
                setState(loadingState);
                const itemResponse = await promise;
                const transformedItems = $c11b2121dbe8e38b$var$transformWorkedOnItems(itemResponse);
                const responseState = {
                    workedOn: {
                        items: transformedItems,
                        isFetching: false,
                        promise: null,
                        fetchError: null,
                        hasFetchedOnce: true,
                        hasSuccessOnce: true
                    }
                };
                setState(responseState);
            } catch (fetchError) {
                const errorState = {
                    workedOn: {
                        ...getState().workedOn,
                        isFetching: false,
                        promise: null,
                        fetchError: fetchError,
                        hasFetchedOnce: true
                    }
                };
                setState(errorState);
            }
        };
    const $c11b2121dbe8e38b$export$e324594224ef24da = {
        loadRecentAll: (amount)=>async (actions2, props)=>{
                $c11b2121dbe8e38b$var$loadRecentItems(amount, [
                    props.applicationPermissions.hasSoftwareAccess ? (0, $49a076eb01652100$export$f95f1a684a17f189) : undefined,
                    (0, $49a076eb01652100$export$6e58b6b0aa48a271),
                    (0, $49a076eb01652100$export$91a9dba2d38d9a6f),
                    (0, $49a076eb01652100$export$6737b9227f60f39d),
                    props.applicationPermissions.hasServiceDeskAccess ? (0, $49a076eb01652100$export$20f1031cc9fdf29e) : undefined
                ].filter(Boolean))(actions2);
            },
        loadRecentAllSingleList: (amount)=>async (actions2, props)=>{
                $c11b2121dbe8e38b$var$loadRecentItemsSingleList(amount, [
                    props.applicationPermissions.hasSoftwareAccess ? (0, $49a076eb01652100$export$f95f1a684a17f189) : undefined,
                    (0, $49a076eb01652100$export$6e58b6b0aa48a271),
                    (0, $49a076eb01652100$export$91a9dba2d38d9a6f),
                    (0, $49a076eb01652100$export$6737b9227f60f39d),
                    (0, $49a076eb01652100$export$ebb48b08205007c5),
                    props.applicationPermissions.hasServiceDeskAccess ? (0, $49a076eb01652100$export$20f1031cc9fdf29e) : undefined
                ].filter(Boolean))(actions2);
            },
        loadWorkedOnAllSingleList: $c11b2121dbe8e38b$var$loadWorkedOnItems,
        loadRecentBoards: (amount)=>$c11b2121dbe8e38b$var$loadRecentItems(amount, [
                (0, $49a076eb01652100$export$f95f1a684a17f189)
            ]),
        loadRecentProjects: (amount)=>$c11b2121dbe8e38b$var$loadRecentItems(amount, [
                (0, $49a076eb01652100$export$91a9dba2d38d9a6f)
            ]),
        loadRecentDashboards: (amount)=>$c11b2121dbe8e38b$var$loadRecentItems(amount, [
                (0, $49a076eb01652100$export$6e58b6b0aa48a271)
            ]),
        loadRecentFilters: (amount)=>$c11b2121dbe8e38b$var$loadRecentItems(amount, [
                (0, $49a076eb01652100$export$6737b9227f60f39d)
            ]),
        loadRecentQueues: (amount)=>$c11b2121dbe8e38b$var$loadRecentItems(amount, [
                (0, $49a076eb01652100$export$20f1031cc9fdf29e)
            ])
    };
    const $c11b2121dbe8e38b$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: (0, $e706d38cada11fb1$export$eb2b39783d051b9),
        actions: $c11b2121dbe8e38b$export$e324594224ef24da,
        initialState: $c11b2121dbe8e38b$export$d4c72bab9d6cc13a
    });
    const $c11b2121dbe8e38b$export$5c0647984dfa7d27 = (0, (0, parcelRequire("3sQ5x")).createHook)($c11b2121dbe8e38b$var$store, {
        selector: null
    });
    const $c11b2121dbe8e38b$export$9a54f46f5b7734c = (0, (0, parcelRequire("3sQ5x")).createHook)($c11b2121dbe8e38b$var$store, {
        selector: (state)=>state.all
    });
    const $c11b2121dbe8e38b$export$e8278a7bcff1ec98 = (0, (0, parcelRequire("3sQ5x")).createHook)($c11b2121dbe8e38b$var$store, {
        selector: (state)=>state.workedOn
    });
    const $c11b2121dbe8e38b$export$3731bd023be4f71 = (0, (0, parcelRequire("3sQ5x")).createHook)($c11b2121dbe8e38b$var$store, {
        selector: (state)=>state[0, $49a076eb01652100$export$f95f1a684a17f189]
    });
    const $c11b2121dbe8e38b$export$81cb5beef51d43ca = (0, (0, parcelRequire("3sQ5x")).createHook)($c11b2121dbe8e38b$var$store, {
        selector: (state)=>state[0, $49a076eb01652100$export$6e58b6b0aa48a271]
    });
    const $c11b2121dbe8e38b$export$f6b9a13c4ec08b45 = (0, (0, parcelRequire("3sQ5x")).createHook)($c11b2121dbe8e38b$var$store, {
        selector: (state)=>state[0, $49a076eb01652100$export$91a9dba2d38d9a6f]
    });
    const $c11b2121dbe8e38b$export$8d2bfb6d3657bae2 = (0, (0, parcelRequire("3sQ5x")).createHook)($c11b2121dbe8e38b$var$store, {
        selector: (state)=>state[0, $49a076eb01652100$export$6737b9227f60f39d]
    });
    const $c11b2121dbe8e38b$export$8756abb0bb90a56e = (0, (0, parcelRequire("3sQ5x")).createHook)($c11b2121dbe8e38b$var$store, {
        selector: (state)=>state[0, $49a076eb01652100$export$20f1031cc9fdf29e]
    });
    const $c11b2121dbe8e38b$export$5de14a1c1400859e = (0, (0, parcelRequire("gg7kZ")).createContainer)($c11b2121dbe8e38b$var$store);
});
parcelRegister("165Uv", function(module, exports) {
    $parcel$export(module.exports, "fetchRecentItems", ()=>$892c454fe92da960$export$20d49db97b653a7b);
    $parcel$export(module.exports, "fetchRecentItemsSingleList", ()=>$892c454fe92da960$export$a00963dad78984b8);
    $parcel$export(module.exports, "fetchWorkedOnItems", ()=>$892c454fe92da960$export$f3763cb0ecc90572);
    var $ebyGx = parcelRequire("ebyGx");
    var $aDvGo;
    var $fPruT;
    const $892c454fe92da960$var$PATH_BUCKETED = '/rest/internal/2/productsearch/search';
    const $892c454fe92da960$var$PATH_SINGLE_LIST = '/rest/internal/2/productsearch/singleRecentList';
    const $892c454fe92da960$var$handleError = (error)=>{
        throw error;
    };
    const $892c454fe92da960$var$toQuery = ({ counts: counts })=>Object.keys(counts).reduce((result, itemType)=>{
            result.push(`${itemType}=${counts[itemType]}`);
            return result;
        }, []).join(',');
    const $892c454fe92da960$export$20d49db97b653a7b = async ({ counts: counts })=>{
        const url = `${$892c454fe92da960$var$PATH_BUCKETED}?${(0, $ebyGx.stringify)({
            counts: $892c454fe92da960$var$toQuery({
                counts: counts
            }),
            type: Object.keys(counts).join(',')
        })}`;
        try {
            const response = await fetch(url, (0, (0, parcelRequire("aDvGo")).default));
            const { status: status } = response;
            if (!response.ok) {
                if (status === (0, $3cd8a0ece36fbae5$export$67d19e9a991baa07)) return $892c454fe92da960$var$handleError(new (0, (0, parcelRequire("fPruT")).AuthenticationError)(`${status}`));
                return $892c454fe92da960$var$handleError(new Error(status));
            }
            if (status === (0, $3cd8a0ece36fbae5$export$3288b14b6745c572)) return null;
            return response.json();
        } catch (error) {
            return $892c454fe92da960$var$handleError(error);
        }
    };
    const $892c454fe92da960$export$a00963dad78984b8 = async ({ types: types, limit: limit })=>{
        const url = $892c454fe92da960$var$PATH_SINGLE_LIST;
        try {
            const response = await fetch(url, {
                ...(0, (0, parcelRequire("aDvGo")).default),
                method: 'POST',
                body: JSON.stringify({
                    groups: [
                        {
                            types: types,
                            limit: limit
                        }
                    ]
                })
            });
            const { status: status } = response;
            if (!response.ok) {
                if (status === (0, $3cd8a0ece36fbae5$export$67d19e9a991baa07)) return $892c454fe92da960$var$handleError(new (0, (0, parcelRequire("fPruT")).AuthenticationError)(`${status}`));
                return $892c454fe92da960$var$handleError(new Error(status));
            }
            if (status === (0, $3cd8a0ece36fbae5$export$3288b14b6745c572)) return null;
            return response.json();
        } catch (error) {
            return $892c454fe92da960$var$handleError(error);
        }
    };
    const $892c454fe92da960$export$f3763cb0ecc90572 = async ({ cloudId: cloudId, amount: amount })=>{
        try {
            const response = await fetch('/gateway/api/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    operationName: 'jira_NavigationActivity',
                    query: (0, $ec61554fda4e6d64$export$2b91517d6d5b4365),
                    variables: {
                        first: amount,
                        cloudID: cloudId
                    }
                })
            });
            const { status: status } = response;
            if (!response.ok) {
                if (status === (0, $3cd8a0ece36fbae5$export$67d19e9a991baa07)) return $892c454fe92da960$var$handleError(new (0, (0, parcelRequire("fPruT")).AuthenticationError)(`${status}`));
                return $892c454fe92da960$var$handleError(new Error(status));
            }
            return response.json();
        } catch (error) {
            return $892c454fe92da960$var$handleError(error);
        }
    };
});
const $3cd8a0ece36fbae5$export$ff39ebd4bb12b718 = 201;
const $3cd8a0ece36fbae5$export$3288b14b6745c572 = 204;
const $3cd8a0ece36fbae5$export$9de493bcd9b1d977 = 400;
const $3cd8a0ece36fbae5$export$67d19e9a991baa07 = 401;
const $3cd8a0ece36fbae5$export$a3269a3914b007e3 = 403;
const $3cd8a0ece36fbae5$export$8bca792d963eb0ef = 404;
const $3cd8a0ece36fbae5$export$4a3cb39801694eee = 407;
const $3cd8a0ece36fbae5$export$fa405bbf47d510e2 = 408;
const $3cd8a0ece36fbae5$export$c09e7ea0fc79f34f = 409;
const $3cd8a0ece36fbae5$export$5244030134e99 = 412;
const $3cd8a0ece36fbae5$export$f90ac1df0b60853d = 413;
const $3cd8a0ece36fbae5$export$a447d0a965e16776 = 422;
const $3cd8a0ece36fbae5$export$3b48aba8957000cd = 426;
const $3cd8a0ece36fbae5$export$6763168a631449a2 = 429;
const $3cd8a0ece36fbae5$export$e813cd7045fcc98d = 500;
const $3cd8a0ece36fbae5$export$2ce38d41a306034e = 502;
const $3cd8a0ece36fbae5$export$974c37534b0191b1 = 503;
const $3cd8a0ece36fbae5$export$5400e8dddac3fcf3 = 504;
const $ec61554fda4e6d64$export$2b91517d6d5b4365 = `
fragment NavigationActivityItem on ActivitiesItem {
  id
  timestamp
  object {
    id
    name
    localResourceId
    type
    url
    iconUrl
    containers {
      name
      type
    }
    extension {
      ... on ActivitiesJiraIssue {
        issueKey
      }
    }
  }
}

query jira_NavigationActivity($first: Int, $cloudID: ID!) {
  activities {
    myActivities {
      workedOn(
        first: $first,
        filters: [
          {
            type: AND,
            arguments: {
              cloudIds: [$cloudID],
              products: [JIRA, JIRA_BUSINESS, JIRA_SOFTWARE, JIRA_OPS, JIRA_SERVICE_DESK]
            }
          }
        ]
      ) {
        nodes {
          ...NavigationActivityItem
        }
      }
    }
  }
}

`;
const $e706d38cada11fb1$export$eb2b39783d051b9 = 'navigation-recents-store';
const $e706d38cada11fb1$export$22ea57e66775a6b2 = 5;
const $e706d38cada11fb1$export$8666f31c8873bff = 10;
const $e706d38cada11fb1$export$5f1b6c5e9d257e5e = 20;
parcelRegister("l0cYe", function(module, exports) {
    $parcel$export(module.exports, "NavigationSuccessTrackerForNav3", ()=>$35aca48cac64f980$export$9013eebe7301dfe1);
    var $5uXOq = parcelRequire("5uXOq");
    var $g0Gtc;
    var $3bQtK;
    var $7XYc4;
    var $8CVvd;
    var $6jY6G;
    var $Mp6qH;
    const $35aca48cac64f980$var$EmptyFragment = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
    const $35aca48cac64f980$var$NavigationSuccessTracker = ({ children: children })=>{
        const route = (0, (0, parcelRequire("6jY6G")).useCurrentRoute)();
        const ViewExperienceSuccessTrackerComponent = route.isRedirect === true ? $35aca48cac64f980$var$EmptyFragment : (0, (0, parcelRequire("g0Gtc")).ExperienceSuccessTracker);
        return (0, ($parcel$interopDefault($5uXOq))).createElement(ViewExperienceSuccessTrackerComponent, {
            location: "atlassian-navigation-main",
            parentProviders: null,
            failureEventAttributes: null
        }, children, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bQtK")).ExperienceSuccess), {
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MAIN)
        }));
    };
    const $35aca48cac64f980$export$9013eebe7301dfe1 = (0, (0, parcelRequire("7XYc4")).componentWithCondition)((0, (0, parcelRequire("8CVvd")).getWillShowNav3), $35aca48cac64f980$var$NavigationSuccessTracker, (0, $5uXOq.Fragment));
});
parcelRegister("6U0zt", function(module, exports) {
    $parcel$export(module.exports, "Addons", ()=>$10f4e1510a5de408$export$a97e6a0578b4c83);
    var $5uXOq = parcelRequire("5uXOq");
    var $2bzBV;
    var $kJH82;
    var $azzrO;
    var $kaHBi;
    var $co1wz;
    var $eKYL7;
    var $koVbs;
    var $lmyls;
    var $iXVV6;
    var $dAfqg;
    var $a4k7n;
    var $heI7y;
    var $9OXo1;
    var $Mp6qH;
    var $4uXxK;
    var $j94qH;
    var $ebYJ0;
    var $h5OKf;
    var $1bhgl;
    var $dEiSH;
    const $10f4e1510a5de408$var$AddonsView = ()=>{
        const { key: projectIdOrKey } = (0, (0, parcelRequire("dAfqg")).useProjectContext)();
        const [, { load: loadItem, setContext: setContext }] = (0, (0, parcelRequire("kJH82")).useAddon)();
        const [, { load: loadForgeItem }] = (0, (0, parcelRequire("eKYL7")).useForgeGlobalItems)();
        (0, $5uXOq.useEffect)(()=>{
            setContext({
                projectIdOrKey: projectIdOrKey
            });
        }, [
            projectIdOrKey,
            setContext
        ]);
        const [{ hasSuccessOnce: hasSuccessOnceUserSegmentation }, { loadPersonalisedAppsUserSegmentation: loadPersonalisedAppsUserSegmentation }] = (0, (0, parcelRequire("h5OKf")).usePersonalisedAppsTeamType)();
        const { activationId: activationId, cloudId: cloudId } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const { formatMessage: formatMessage, locale: locale } = (0, (0, parcelRequire("koVbs")).default)();
        const onStart = (0, (0, parcelRequire("kaHBi")).useExperienceStart)({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU),
            experienceId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).ADDONS,
            analyticsSource: 'atlassian-navigation'
        });
        const onBeforeMenuOpen = (0, $5uXOq.useCallback)(()=>{
            onStart();
            loadItem();
            loadForgeItem({
                cloudId: cloudId,
                activationId: activationId,
                locale: locale
            });
            !hasSuccessOnceUserSegmentation && loadPersonalisedAppsUserSegmentation();
            (0, (0, parcelRequire("azzrO")).NAVIGATION_TOP_DROPDOWN_MARKS).ADDONS.start();
        }, [
            onStart,
            loadItem,
            hasSuccessOnceUserSegmentation,
            loadPersonalisedAppsUserSegmentation,
            loadForgeItem,
            cloudId,
            activationId,
            locale
        ]);
        const attributes = (0, $5uXOq.useMemo)(()=>({
                isEmbeddedMarketplaceEnabledForEndUsers: (0, (0, parcelRequire("co1wz")).ff)('enduser-emcee-discover'),
                isEmbeddedMarketplaceSpaEnabled: (0, (0, parcelRequire("co1wz")).ff)('marketplace.emcee.native.spa.enable')
            }), []);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            attributes: attributes
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ebYJ0")).Popup), {
            menuId: (0, (0, parcelRequire("lmyls")).MENU_ID).ADDONS,
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).ADDONS,
            Menu: (0, (0, parcelRequire("1bhgl")).AsyncMenu),
            onBeforeMenuOpen: onBeforeMenuOpen,
            label: formatMessage((0, (0, parcelRequire("dEiSH")).default).apps)
        }, formatMessage((0, (0, parcelRequire("dEiSH")).default).apps)));
    };
    const $10f4e1510a5de408$export$a97e6a0578b4c83 = ()=>{
        const { isVisible: isVisible } = (0, (0, parcelRequire("2bzBV")).useOverflowStatus)();
        const [{ hasOpened: shouldShowNudge }] = (0, (0, parcelRequire("heI7y")).useHasOpened)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).ADDONS
        }, !false && shouldShowNudge ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("a4k7n")).NavigationAppsNavItemWrapperNudgeAsync), {
            isInsideMenu: !isVisible
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j94qH")).FullHeightTextColorWrapper), null, (0, ($parcel$interopDefault($5uXOq))).createElement($10f4e1510a5de408$var$AddonsView, null))) : (0, ($parcel$interopDefault($5uXOq))).createElement($10f4e1510a5de408$var$AddonsView, null));
    };
});
parcelRegister("kJH82", function(module, exports) {
    $parcel$export(module.exports, "useAddon", ()=>$0672771da90f2be9$export$550c6d89b9b85724);
    var $6Rmr1;
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    var $51nlc;
    const $0672771da90f2be9$var$handleFetch = ()=>async ({ setState: setState, getState: getState })=>{
            const { fetchAddons: fetchAddons, fetchAddonContext: fetchAddonContext, isFetching: isFetching } = getState();
            if (!isFetching) try {
                const promise = fetchAddons(undefined, fetchAddonContext);
                setState({
                    isFetching: true,
                    fetchError: null,
                    promise: promise
                });
                const items = await promise;
                setState({
                    items: items,
                    hasFetchedOnce: true,
                    hasSuccessOnce: true,
                    isFetching: false,
                    fetchError: null,
                    promise: null
                });
            } catch (fetchError) {
                setState({
                    ...getState(),
                    fetchError: fetchError,
                    hasFetchedOnce: true,
                    isFetching: false,
                    promise: null
                });
            }
        };
    const $0672771da90f2be9$var$actions = {
        load: ()=>async ({ dispatch: dispatch })=>{
                const promise = dispatch($0672771da90f2be9$var$handleFetch());
                await promise;
            },
        setFavourite: (item, isAddon)=>async ({ setState: setState, getState: getState })=>{
                const { items: items, addToFavorite: addToFavorite, removeFromFavorite: removeFromFavorite } = getState();
                const newItemData = {
                    ...item,
                    favourite: true
                };
                if (isAddon) {
                    setState({
                        items: [
                            newItemData,
                            ...items
                        ]
                    });
                    await addToFavorite(newItemData);
                } else {
                    setState({
                        items: items.filter((theItem)=>theItem.id !== item.id)
                    });
                    await removeFromFavorite(item);
                }
            },
        setContext: (context)=>({ setState: setState })=>{
                setState({
                    fetchAddonContext: context
                });
            }
    };
    const $0672771da90f2be9$export$f350d6bef9972cd3 = (defaults)=>{
        const initialState = {
            fetchAddons: defaults.fetchAddons,
            fetchAddonContext: {},
            addToFavorite: defaults.addToFavorite,
            removeFromFavorite: defaults.removeFromFavorite,
            hasFetchedOnce: false,
            hasSuccessOnce: false,
            items: [],
            fetchError: null,
            promise: null,
            isFetching: false
        };
        const Store = (0, (0, parcelRequire("iXqE5")).createStore)({
            name: 'addon-store',
            actions: $0672771da90f2be9$var$actions,
            initialState: initialState
        });
        const AddonContainer = (0, (0, parcelRequire("gg7kZ")).createContainer)(Store, {
            onInit: ()=>(api, props)=>{
                    api.setState({
                        fetchAddons: props.fetchAddons,
                        addToFavorite: props.addToFavorite,
                        removeFromFavorite: props.removeFromFavorite
                    });
                }
        });
        const AddonTestContainer = (0, (0, parcelRequire("gg7kZ")).createContainer)(Store, {
            onInit: ()=>(api, props)=>{
                    api.setState(props);
                }
        });
        const selector = ({ items: items, hasFetchedOnce: hasFetchedOnce, fetchError: fetchError, promise: promise, hasSuccessOnce: hasSuccessOnce, isFetching: isFetching })=>({
                items: items,
                hasFetchedOnce: hasFetchedOnce,
                hasSuccessOnce: hasSuccessOnce,
                fetchError: fetchError,
                promise: promise,
                isFetching: isFetching
            });
        const AddonSubscriber = (0, (0, parcelRequire("51nlc")).createSubscriber)(Store, {
            displayName: 'AddonSubscriber',
            selector: selector
        });
        const useAddonActions = (0, (0, parcelRequire("3sQ5x")).createHook)(Store, {
            selector: selector
        });
        const useAddon = (0, (0, parcelRequire("3sQ5x")).createHook)(Store, {
            selector: selector
        });
        return {
            AddonSubscriber: AddonSubscriber,
            useAddonActions: useAddonActions,
            useAddon: useAddon,
            AddonTestContainer: AddonTestContainer,
            AddonContainer: AddonContainer
        };
    };
    const $0672771da90f2be9$var$defaultAddToFavorite = ()=>new Promise(()=>null);
    const $0672771da90f2be9$var$defaultRemoveFromFavorite = ()=>new Promise(()=>null);
    const { useAddon: $0672771da90f2be9$export$550c6d89b9b85724 } = $0672771da90f2be9$export$f350d6bef9972cd3({
        fetchAddons: (0, (0, parcelRequire("6Rmr1")).fetchAddonItems),
        addToFavorite: $0672771da90f2be9$var$defaultAddToFavorite,
        removeFromFavorite: $0672771da90f2be9$var$defaultRemoveFromFavorite
    });
});
parcelRegister("6Rmr1", function(module, exports) {
    $parcel$export(module.exports, "fetchAddonItems", ()=>$c9dd134b6cc78152$export$fe5ef9b24322d3c1);
    var $aDvGo;
    var $jJZqs;
    var $g5X3G;
    var $fPruT;
    const $c9dd134b6cc78152$var$handleError = (error)=>{
        throw error;
    };
    const $c9dd134b6cc78152$export$fe5ef9b24322d3c1 = async (baseUrl = (0, (0, parcelRequire("g5X3G")).toBaseUrl)(''), context)=>{
        const params = $c9dd134b6cc78152$var$getContextParams(context);
        const url = `${baseUrl}/rest/navigation/3/global/addons${params ? `?${params}` : ''}`;
        try {
            const response = await fetch(url, (0, (0, parcelRequire("aDvGo")).default));
            if (!response.ok) {
                const errorCode = response.status;
                if (errorCode === 401) return $c9dd134b6cc78152$var$handleError(new (0, (0, parcelRequire("fPruT")).AuthenticationError)(`${errorCode}`));
                return $c9dd134b6cc78152$var$handleError(new (0, (0, parcelRequire("jJZqs")).default)(errorCode, response.statusText));
            }
            const addonsItems = await response.json();
            return addonsItems.map((item)=>({
                    id: item.id,
                    title: item.name,
                    avatarUrl: item.iconUrl,
                    type: (0, $49a076eb01652100$export$5e2cc09b58170ca2),
                    url: item.url,
                    className: item.styleClass
                }));
        } catch (error) {
            return $c9dd134b6cc78152$var$handleError(error);
        }
    };
    function $c9dd134b6cc78152$var$getContextParams(context) {
        const filteredParams = Object.fromEntries(Object.entries(context || {}).filter(([, value])=>!!value));
        return new URLSearchParams(filteredParams).toString();
    }
});
parcelRegister("eKYL7", function(module, exports) {
    $parcel$export(module.exports, "useForgeGlobalItems", ()=>$b1e0d26740806b04$export$fa6081acdcc112a0);
    var $4R6GH;
    var $k2DRb;
    var $e0AC8;
    var $awp3q;
    var $dvdr4;
    var $5cmSg;
    var $5vSAE;
    var $47m5i;
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    var $51nlc;
    const $b1e0d26740806b04$var$getItemHref = (extension)=>{
        const { appId: appId, envId: envId } = (0, (0, parcelRequire("e0AC8")).parseExtensionId)(extension.id);
        return `/jira/apps/${appId}/${envId}`;
    };
    const $b1e0d26740806b04$var$transformTitle = (title, environmentType, environmentKey)=>{
        if (environmentType === (0, (0, parcelRequire("k2DRb")).DEVELOPMENT_ENVIRONMENT) && environmentKey !== (0, (0, parcelRequire("k2DRb")).DEFAULT_ENVIRONMENT_KEY)) return `${title} - ${environmentKey}`;
        return title;
    };
    const $b1e0d26740806b04$var$transformResponse = (data)=>data ? data.filter((extension)=>(0, (0, parcelRequire("awp3q")).doesExtensionMeetConditions)(extension, {})).map((extension)=>({
                id: extension.id,
                title: $b1e0d26740806b04$var$transformTitle(extension.properties.title, extension.environmentType, extension.environmentKey),
                avatarUrl: extension.properties.icon,
                url: $b1e0d26740806b04$var$getItemHref(extension),
                environmentType: extension.environmentType,
                module: (0, (0, parcelRequire("k2DRb")).GLOBAL_PAGE_MODULE)
            })) : null;
    const $b1e0d26740806b04$var$handleFetch = ({ cloudId: cloudId, activationId: activationId, locale: locale })=>async ({ setState: setState, getState: getState })=>{
            const { fetchForgeModule: fetchForgeModule, isFetching: isFetching } = getState();
            if (!isFetching) try {
                if ((0, (0, parcelRequire("4R6GH")).fg)('new_graphql_endpoint_for_fetching_forge_modules')) {
                    const promise = (0, (0, parcelRequire("5vSAE")).fetchModules)({
                        cloudId: cloudId,
                        context: {},
                        includeHidden: true,
                        types: [
                            (0, (0, parcelRequire("k2DRb")).GLOBAL_PAGE_MODULE)
                        ],
                        source: (0, (0, parcelRequire("k2DRb")).SOURCE_NAVIGATION)
                    });
                    setState({
                        isFetching: true,
                        fetchError: null,
                        promise: promise
                    });
                    const data = await promise;
                    const globalPageData = data ? data[0, (0, parcelRequire("k2DRb")).GLOBAL_PAGE_MODULE] : [];
                    (0, (0, parcelRequire("dvdr4")).checkDisplayConditionsConsistency)(globalPageData, {}, 'forge-global-items-store');
                    const items = globalPageData.filter((0, (0, parcelRequire("5cmSg")).isExtensionVisible)).map((extension)=>({
                            id: extension.id,
                            title: $b1e0d26740806b04$var$transformTitle(extension.properties.title, extension.environmentType, extension.environmentKey),
                            avatarUrl: extension.properties.icon,
                            url: $b1e0d26740806b04$var$getItemHref(extension),
                            environmentType: extension.environmentType,
                            module: (0, (0, parcelRequire("k2DRb")).GLOBAL_PAGE_MODULE)
                        }));
                    setState({
                        items: items,
                        hasFetchedOnce: true,
                        hasSuccessOnce: true,
                        isFetching: false,
                        fetchError: null,
                        promise: null
                    });
                } else {
                    const promise = fetchForgeModule({
                        cloudId: cloudId,
                        moduleName: (0, (0, parcelRequire("k2DRb")).GLOBAL_PAGE_MODULE),
                        dataClassification: null,
                        shouldReturnANExtensions: false,
                        contextIds: [
                            `ari:cloud:jira:${cloudId}:workspace/${activationId}`
                        ],
                        locale: locale
                    });
                    setState({
                        isFetching: true,
                        fetchError: null,
                        promise: promise
                    });
                    const data = await promise;
                    const globalPageData = data?.extensions || data;
                    const items = $b1e0d26740806b04$var$transformResponse(globalPageData);
                    setState({
                        items: items,
                        hasFetchedOnce: true,
                        hasSuccessOnce: true,
                        isFetching: false,
                        fetchError: null,
                        promise: null
                    });
                }
            } catch (fetchError) {
                setState({
                    ...getState(),
                    fetchError: fetchError,
                    hasFetchedOnce: true,
                    isFetching: false,
                    promise: null
                });
            }
        };
    const $b1e0d26740806b04$var$actions = {
        load: ({ cloudId: cloudId, activationId: activationId = '', locale: locale })=>async ({ dispatch: dispatch })=>{
                const promise = dispatch($b1e0d26740806b04$var$handleFetch({
                    cloudId: cloudId,
                    activationId: activationId,
                    locale: locale
                }));
                await promise;
            }
    };
    const $b1e0d26740806b04$export$a87c2a3d589b7efd = (defaults)=>{
        const initialState = {
            fetchForgeModule: defaults.fetchForgeModule,
            hasFetchedOnce: false,
            hasSuccessOnce: false,
            items: [],
            promise: null,
            fetchError: null,
            isFetching: false
        };
        const Store = (0, (0, parcelRequire("iXqE5")).createStore)({
            name: 'forge-global-items-store',
            actions: $b1e0d26740806b04$var$actions,
            initialState: initialState
        });
        const ForgeGlobalItemsTestContainer = (0, (0, parcelRequire("gg7kZ")).createContainer)(Store, {
            onInit: ()=>(api, props)=>{
                    api.setState(props);
                }
        });
        const selector = ({ items: items, hasFetchedOnce: hasFetchedOnce, fetchError: fetchError, promise: promise, hasSuccessOnce: hasSuccessOnce, isFetching: isFetching })=>({
                items: items,
                hasFetchedOnce: hasFetchedOnce,
                fetchError: fetchError,
                promise: promise,
                hasSuccessOnce: hasSuccessOnce,
                isFetching: isFetching
            });
        const ForgeGlobalItemsSubscriber = (0, (0, parcelRequire("51nlc")).createSubscriber)(Store, {
            displayName: 'ForgeGlobalItemsSubscriber',
            selector: selector
        });
        const useForgeGlobalItems = (0, (0, parcelRequire("3sQ5x")).createHook)(Store, {
            selector: selector
        });
        return {
            ForgeGlobalItemsSubscriber: ForgeGlobalItemsSubscriber,
            useForgeGlobalItems: useForgeGlobalItems,
            ForgeGlobalItemsTestContainer: ForgeGlobalItemsTestContainer
        };
    };
    const { useForgeGlobalItems: $b1e0d26740806b04$export$fa6081acdcc112a0 } = $b1e0d26740806b04$export$a87c2a3d589b7efd({
        fetchForgeModule: (0, (0, parcelRequire("47m5i")).fetchForgeModule)
    });
});
parcelRegister("a4k7n", function(module, exports) {
    $parcel$export(module.exports, "NavigationAppsNavItemWrapperNudgeAsync", ()=>$86c9ba44b2aedf6e$export$c143067f544307f);
    var $5uXOq = parcelRequire("5uXOq");
    var $bBbEG;
    var $lfTZh;
    var $kxZPA;
    const $86c9ba44b2aedf6e$var$NavigationAppsNavItemLazy = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("8JMAa")).then((exportedModule)=>exportedModule.NavigationAppsNavItemWrapperNudge), {
        ssr: false,
        moduleId: "./src/packages/software/onboarding-nudges/src/ui/navigation-apps/index.tsx"
    });
    const $86c9ba44b2aedf6e$export$c143067f544307f = ({ children: children, isInsideMenu: isInsideMenu })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bBbEG")).OnboardingNudgeErrorBoundary), {
            id: (0, $6784cbc7d62e5d7c$export$dd63d0099d95ba4f),
            fallback: children,
            attributes: {
                nudgeId: (0, $6784cbc7d62e5d7c$export$dd63d0099d95ba4f)
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "navigation-apps-nav-item-lazy",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($86c9ba44b2aedf6e$var$NavigationAppsNavItemLazy, {
            nudgeId: (0, $6784cbc7d62e5d7c$export$dd63d0099d95ba4f),
            isInsideMenu: isInsideMenu
        }, children)));
});
parcelRegister("bBbEG", function(module, exports) {
    $parcel$export(module.exports, "OnboardingNudgeErrorBoundary", ()=>$383be1d115a6551b$export$3915a8814b99f0b);
    var $5uXOq = parcelRequire("5uXOq");
    var $lkem5;
    var $8Bc5A;
    const $383be1d115a6551b$export$3915a8814b99f0b = (props)=>{
        const { id: id, attributes: attributes, fallback: fallback, children: children } = props;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lkem5")).ErrorBoundaryWithChunkErrorLogging), {
            id: id,
            packageName: (0, (0, parcelRequire("8Bc5A")).PACKAGE_NAME),
            render: ()=>fallback,
            attributes: attributes
        }, children);
    };
});
parcelRegister("lkem5", function(module, exports) {
    $parcel$export(module.exports, "ErrorBoundaryWithChunkErrorLogging", ()=>$3df8b034ebbd68e5$export$9171acf2e93b7d4d);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $30rnQ;
    const $3df8b034ebbd68e5$export$9171acf2e93b7d4d = (props)=>{
        const { attributes: attributes, children: children, ...rest } = props;
        const addErrorBoundaryAnalyticsAttributes = (0, (0, parcelRequire("30rnQ")).useAddErrorBoundaryAnalyticsAttributes)(attributes);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            ...rest,
            attributes: addErrorBoundaryAnalyticsAttributes
        }, children);
    };
});
parcelRegister("30rnQ", function(module, exports) {
    $parcel$export(module.exports, "addChunkLoadErrorAnalyticsAttribute", ()=>$53c68ff9a9edd0fe$export$94ee0b5fca1e74d2);
    $parcel$export(module.exports, "useAddErrorBoundaryAnalyticsAttributes", ()=>$53c68ff9a9edd0fe$export$807be5f3f1ea996);
    var $5uXOq = parcelRequire("5uXOq");
    var $bCf8q;
    const $53c68ff9a9edd0fe$export$94ee0b5fca1e74d2 = (error)=>({
            chunkLoadError: (0, (0, parcelRequire("bCf8q")).isLoaderError)(error)
        });
    const $53c68ff9a9edd0fe$export$807be5f3f1ea996 = (attributes = {})=>(0, $5uXOq.useCallback)((error)=>({
                ...attributes,
                ...$53c68ff9a9edd0fe$export$94ee0b5fca1e74d2(error)
            }), [
            attributes
        ]);
});
parcelRegister("8JMAa", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("1whFq").then(()=>parcelRequire('3Hy6N'));
});
parcelRegister("heI7y", function(module, exports) {
    $parcel$export(module.exports, "useHasOpened", ()=>$c02ba9c4f48115f2$export$cbaabcded53b5c1f);
    var $3sQ5x;
    var $iXqE5;
    var $h97QA;
    const $c02ba9c4f48115f2$var$actions = {
        setHasOpened: (0, parcelRequire("h97QA")).setHasOpened
    };
    const $c02ba9c4f48115f2$var$initialState = {
        hasOpened: false
    };
    const $c02ba9c4f48115f2$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: $c02ba9c4f48115f2$var$initialState,
        actions: $c02ba9c4f48115f2$var$actions,
        name: 'jsw-onboarding-quickstart-has-opened'
    });
    const $c02ba9c4f48115f2$export$cbaabcded53b5c1f = (0, (0, parcelRequire("3sQ5x")).createHook)($c02ba9c4f48115f2$var$Store);
});
parcelRegister("h97QA", function(module, exports) {
    $parcel$export(module.exports, "setHasOpened", ()=>$68011af9f70af395$export$c4743c64a7c42294);
    const $68011af9f70af395$export$c4743c64a7c42294 = ()=>({ getState: getState, setState: setState })=>{
            const { hasOpened: hasOpened } = getState();
            if (hasOpened === true) return;
            setState({
                hasOpened: true
            });
        };
    var $68011af9f70af395$export$2e2bcd8739ae039 = $68011af9f70af395$export$c4743c64a7c42294;
});
parcelRegister("4uXxK", function(module, exports) {
    $parcel$export(module.exports, "TopLevelErrorBoundary", ()=>$7f8668208bdb1964$export$b70f1d8bd509b358);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $Mp6qH;
    const $7f8668208bdb1964$export$b70f1d8bd509b358 = ({ id: id, children: children })=>{
        const EVENT_DATA = (0, $5uXOq.useMemo)(()=>({
                id: id
            }), [
            id
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: `${(0, (0, parcelRequire("Mp6qH")).TOP_LEVEL_ERROR_BOUNDARY_ID)}.${id}`,
            packageName: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME),
            extraEventData: EVENT_DATA,
            fallback: "unmount"
        }, children);
    };
});
parcelRegister("j94qH", function(module, exports) {
    $parcel$export(module.exports, "FullHeightTextColorWrapper", ()=>$1e4986b0c19053de$export$19a17a164d8e4de3);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $1e4986b0c19053de$export$7ac7d6a768c2e9b2 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i1osq _1e0c1txw _4cvr1h6o _16jlkb7n _19pnkb7n _13aikb7n _1gqrf1ug",
                __cmplp.className
            ])
        });
    });
    const $1e4986b0c19053de$export$19a17a164d8e4de3 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i1osq _1e0c1txw _4cvr1h6o _syaz1fxt _16jlkb7n _19pnkb7n _13aikb7n _1gqrf1ug",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("ebYJ0", function(module, exports) {
    $parcel$export(module.exports, "Popup", ()=>$85fdbd4ad4faa6ef$export$129a32eedafa97c5);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $2bzBV;
    var $1I7E8;
    var $2Ui4E;
    var $6cNb0;
    var $gAtvZ;
    var $fVbkk;
    var $8JFi2;
    const $85fdbd4ad4faa6ef$var$OFFSET = [
        12,
        -11
    ];
    const $85fdbd4ad4faa6ef$export$129a32eedafa97c5 = ({ Menu: Menu, MenuTrigger: MenuTrigger = (0, (0, parcelRequire("fVbkk")).MenuTrigger), children: children, menuId: menuId, navigationItemId: navigationItemId, onBeforeMenuOpen: onBeforeMenuOpen = ()=>undefined, label: label })=>{
        const [isMenuOpen, { off: closeMenu }] = (0, (0, parcelRequire("2Ui4E")).useTopMenus)(menuId);
        const [isEventFromSpotlightTour] = (0, (0, parcelRequire("gAtvZ")).useFromSpotlightTour)('spotlight');
        const { isVisible: isVisible } = (0, (0, parcelRequire("2bzBV")).useOverflowStatus)();
        const Trigger = (0, $5uXOq.useCallback)((props)=>(0, ($parcel$interopDefault($5uXOq))).createElement(MenuTrigger, {
                ...props,
                onBeforeMenuOpen: onBeforeMenuOpen,
                menuId: menuId,
                navigationItemId: navigationItemId,
                "aria-haspopup": false
            }, children), [
            children,
            menuId,
            navigationItemId,
            onBeforeMenuOpen
        ]);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdGenerate)('primary-actions', menuId, 'menu-popup');
        const Content = (0, $5uXOq.useCallback)((props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("span", {
                onClick: (0, (0, parcelRequire("8JFi2")).stopPropagation),
                "data-testid": testId,
                role: "presentation"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement(Menu, {
                ...props,
                testIdPrefix: testId
            }, children)), [
            children,
            testId
        ]);
        if (false) return (0, ($parcel$interopDefault($5uXOq))).createElement(Trigger, {
            "aria-expanded": false,
            "aria-haspopup": false
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1I7E8")).default), {
            shouldRenderToParent: true,
            isOpen: isMenuOpen,
            onClose: isEventFromSpotlightTour ? (0, ($parcel$interopDefault($8Rkzz))) : closeMenu,
            content: Content,
            offset: isVisible ? undefined : $85fdbd4ad4faa6ef$var$OFFSET,
            placement: isVisible ? 'bottom-start' : 'right-start',
            trigger: Trigger,
            label: label
        });
    };
});
parcelRegister("2Ui4E", function(module, exports) {
    $parcel$export(module.exports, "useTopMenus", ()=>$77ef209ff15299e4$export$6591d6b6c4546423);
    var $5uXOq = parcelRequire("5uXOq");
    var $d8lE2;
    var $2qauD;
    const { SwitchContainer: $77ef209ff15299e4$var$SwitchContainer, useSwitch: $77ef209ff15299e4$var$useSwitch } = (0, (0, parcelRequire("2qauD")).createSwitchController)('atlassian-navigation-top-menus');
    const $77ef209ff15299e4$export$45ccf7b2695bac19 = $77ef209ff15299e4$var$SwitchContainer;
    const $77ef209ff15299e4$var$useTopMenusBase = $77ef209ff15299e4$var$useSwitch;
    const $77ef209ff15299e4$export$6591d6b6c4546423 = (props)=>{
        const switcher = $77ef209ff15299e4$var$useTopMenusBase(props);
        const [{ isOpen: isCommandPaletteOpen }] = (0, (0, parcelRequire("d8lE2")).useCommandPaletteIsOpen)();
        const [isSwitchOpen, { off: off }] = switcher;
        (0, $5uXOq.useEffect)(()=>{
            if (isSwitchOpen && isCommandPaletteOpen) off();
        }, [
            isCommandPaletteOpen,
            isSwitchOpen,
            off
        ]);
        return switcher;
    };
});
parcelRegister("d8lE2", function(module, exports) {
    $parcel$export(module.exports, "useCommandPalette", ()=>$f75514d959bfcca8$export$6b58f9610017fbb7);
    $parcel$export(module.exports, "useActionsCommandPalette", ()=>$f75514d959bfcca8$export$72aa7bc7c6a83c36);
    $parcel$export(module.exports, "useCommandPaletteAnalyticsAttributes", ()=>$f75514d959bfcca8$export$1f90c8f89f98110e);
    $parcel$export(module.exports, "useCommandPaletteIsOpen", ()=>$f75514d959bfcca8$export$cc979be9b69236df);
    $parcel$export(module.exports, "useCommandPaletteHasChildPopup", ()=>$f75514d959bfcca8$export$a18633b655403b12);
    $parcel$export(module.exports, "useCommandPaletteIsLoaded", ()=>$f75514d959bfcca8$export$af8fd8cd4b048315);
    $parcel$export(module.exports, "useCommandPaletteSessionId", ()=>$f75514d959bfcca8$export$7422170a403e909);
    $parcel$export(module.exports, "useCommandPaletteExpandedCommand", ()=>$f75514d959bfcca8$export$282aa48e4a3afada);
    $parcel$export(module.exports, "useCommandPaletteSearch", ()=>$f75514d959bfcca8$export$96819dfb22ad05d3);
    $parcel$export(module.exports, "useCommandPaletteRegistryCurrentAttribution", ()=>$f75514d959bfcca8$export$f893891deedab3c6);
    $parcel$export(module.exports, "useCommandPaletteUsePerformModeState", ()=>$f75514d959bfcca8$export$bbc05723eb144e03);
    var $5uXOq = parcelRequire("5uXOq");
    var $3sQ5x;
    var $6dDSs;
    var $iXqE5;
    var $gM5ou;
    var $2sRNe;
    const $f75514d959bfcca8$var$initialState = {
        commandPaletteSessionId: '',
        isOpen: false,
        isLoaded: false,
        search: '',
        activeIndex: 0,
        isSearchFieldFocused: false,
        commands: [],
        parentCommand: [],
        sections: [],
        isDisabled: false,
        registry: {},
        activeRegistry: {
            commandRegistry: {},
            registryIds: []
        },
        onClosePendingCommandAction: undefined,
        isLoadChildrenError: false
    };
    const $f75514d959bfcca8$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: $f75514d959bfcca8$var$initialState,
        actions: (0, parcelRequire("2sRNe")).actions,
        name: 'commandPalette'
    });
    const $f75514d959bfcca8$export$6b58f9610017fbb7 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store);
    const $f75514d959bfcca8$export$72aa7bc7c6a83c36 = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($f75514d959bfcca8$var$store);
    const $f75514d959bfcca8$export$e144a44b650635f4 = (ComponentToRender)=>(props)=>{
            const commandPalette = $f75514d959bfcca8$export$6b58f9610017fbb7();
            return (0, ($parcel$interopDefault($5uXOq))).createElement(ComponentToRender, {
                ...props,
                commandPalette: commandPalette
            });
        };
    const $f75514d959bfcca8$export$56f06313122d0aac = ({ search: search, commandPaletteSessionId: commandPaletteSessionId, expandedCommand: expandedCommand, registry: registry, activeRegistry: activeRegistry })=>({
            wordCount: (0, (0, parcelRequire("gM5ou")).getWordCount)(search),
            queryLength: search.length,
            queryHash: (0, (0, parcelRequire("gM5ou")).getQueryHash)(search),
            commandPaletteSessionId: commandPaletteSessionId,
            cpMenu: expandedCommand?.id || 'rootMenu',
            ...(0, (0, parcelRequire("gM5ou")).getExpandedCommandRegistryAnalytics)(registry, activeRegistry, expandedCommand?.id) || {},
            activeRegistryIds: activeRegistry.registryIds,
            activeRegistryIdsLength: activeRegistry.registryIds.length,
            activeRegistryCommandsLength: Object.keys(activeRegistry.commandRegistry).length,
            relevancyModel: 'v2-string-similarity'
        });
    const $f75514d959bfcca8$export$1f90c8f89f98110e = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: $f75514d959bfcca8$export$56f06313122d0aac
    });
    const $f75514d959bfcca8$export$cc979be9b69236df = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ isOpen: isOpen })=>({
                isOpen: isOpen
            })
    });
    const $f75514d959bfcca8$export$a18633b655403b12 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($f75514d959bfcca8$var$store, {
        selector: (state)=>state.hasPopupOverlay
    });
    const $f75514d959bfcca8$export$af8fd8cd4b048315 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ isLoaded: isLoaded })=>({
                isLoaded: isLoaded
            })
    });
    const $f75514d959bfcca8$export$4372697329bd9cbe = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ isDisabled: isDisabled })=>({
                isDisabled: isDisabled
            })
    });
    const $f75514d959bfcca8$export$7422170a403e909 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ commandPaletteSessionId: commandPaletteSessionId })=>({
                commandPaletteSessionId: commandPaletteSessionId
            })
    });
    const $f75514d959bfcca8$export$282aa48e4a3afada = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ expandedCommand: expandedCommand, isOpen: isOpen })=>({
                expandedCommand: expandedCommand,
                isOpen: isOpen
            })
    });
    const $f75514d959bfcca8$export$96819dfb22ad05d3 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ search: search })=>({
                search: search
            })
    });
    const $f75514d959bfcca8$export$a40e8ae3e53feab0 = (0, (0, parcelRequire("6dDSs")).createSelector)(({ registry: registry, activeRegistry: activeRegistry, expandedCommand: expandedCommand })=>({
            registry: registry,
            activeRegistry: activeRegistry,
            expandedCommand: expandedCommand
        }), ()=>undefined, ({ registry: registry, activeRegistry: activeRegistry, expandedCommand: expandedCommand })=>{
        if (!expandedCommand) return undefined;
        const registryData = (0, (0, parcelRequire("gM5ou")).getRegistryData)(registry, activeRegistry, expandedCommand.id);
        return {
            registryData: registryData,
            analyticsAttributes: registryData
        };
    });
    const $f75514d959bfcca8$export$f893891deedab3c6 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: $f75514d959bfcca8$export$a40e8ae3e53feab0
    });
    const $f75514d959bfcca8$export$bbc05723eb144e03 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ commands: commands, search: search, expandedCommand: expandedCommand, childResult: childResult, registry: registry, activeRegistry: activeRegistry })=>({
                commands: commands,
                search: search,
                expandedCommand: expandedCommand,
                childResult: childResult,
                registry: registry,
                activeRegistry: activeRegistry
            })
    });
});
parcelRegister("gM5ou", function(module, exports) {
    $parcel$export(module.exports, "getRegistryData", ()=>$cf289af0c139712c$export$6a1ed481c04a3ae6);
    $parcel$export(module.exports, "getExpandedCommandRegistryAnalytics", ()=>$cf289af0c139712c$export$eb055284806da6e5);
    $parcel$export(module.exports, "getShortcutSpecialCharacters", ()=>$cf289af0c139712c$export$e6994b845b967732);
    $parcel$export(module.exports, "removeShortcutSpecialCharacters", ()=>$cf289af0c139712c$export$cd2a5827eae5f4cf);
    $parcel$export(module.exports, "mergeSections", ()=>$cf289af0c139712c$export$389b0273703d5c48);
    $parcel$export(module.exports, "mergeChildResults", ()=>$cf289af0c139712c$export$60a91e1b94ab4fef);
    $parcel$export(module.exports, "getAnalyticsAttributesFromState", ()=>$cf289af0c139712c$export$4023cd53e5e5fcb8);
    $parcel$export(module.exports, "isSecondaryTrigger", ()=>$cf289af0c139712c$export$daa5beabdf8d9fc3);
    $parcel$export(module.exports, "hasChildren", ()=>$cf289af0c139712c$export$bd5ff50f0e53d41c);
    $parcel$export(module.exports, "getAnalyticsAttributesFromResult", ()=>$cf289af0c139712c$export$bc9cd28438b3ea4b);
    $parcel$export(module.exports, "getQueryHash", ()=>$cf289af0c139712c$export$fa1c95a5ffa92036);
    $parcel$export(module.exports, "getWordCount", ()=>$cf289af0c139712c$export$dba3e2bcdbd49da4);
    $parcel$export(module.exports, "getCommandAction", ()=>$cf289af0c139712c$export$fb6dafa07e60c21a);
    $parcel$export(module.exports, "hasAction", ()=>$cf289af0c139712c$export$e217e69099d082f5);
    $parcel$export(module.exports, "useSetTimeoutAutoClear", ()=>$cf289af0c139712c$export$c3d20e8c1e93a572);
    var $5uXOq = parcelRequire("5uXOq");
    var $bdDiz = parcelRequire("bdDiz");
    var $2f2jJ;
    var $bcTvL;
    var $jh7L9;
    const $cf289af0c139712c$export$6a1ed481c04a3ae6 = (registry, activeRegistry, commandId)=>{
        if (!commandId) return undefined;
        const activeCommandRegistryData = activeRegistry?.commandRegistry?.[commandId];
        const registryData = activeCommandRegistryData?.registryId ? registry[activeCommandRegistryData?.registryId] : undefined;
        return registryData;
    };
    const $cf289af0c139712c$export$eb055284806da6e5 = (registry, activeRegistry, commandId)=>$cf289af0c139712c$export$6a1ed481c04a3ae6(registry, activeRegistry, commandId);
    const $cf289af0c139712c$export$8af60a6897157f84 = (registry, activeRegistry, parentCommands)=>{
        const parentCommandIds = parentCommands?.map((command)=>command.id);
        while(parentCommandIds?.length){
            const nextParentCommandId = parentCommandIds?.pop();
            const registryData = $cf289af0c139712c$export$6a1ed481c04a3ae6(registry, activeRegistry, nextParentCommandId);
            if (registryData) return registryData;
        }
        return undefined;
    };
    const $cf289af0c139712c$export$e6994b845b967732 = ()=>[
            {
                char: ' ',
                replacement: (0, (0, parcelRequire("bcTvL")).SHORTCUT_ELEMENT_TYPES).THEN
            },
            {
                char: '+',
                replacement: (0, (0, parcelRequire("bcTvL")).SHORTCUT_ELEMENT_TYPES).PLUS
            },
            {
                char: '|',
                replacement: (0, (0, parcelRequire("bcTvL")).SHORTCUT_ELEMENT_TYPES).OR
            }
        ];
    const $cf289af0c139712c$export$cd2a5827eae5f4cf = (shortcut)=>{
        const specialChars = $cf289af0c139712c$export$e6994b845b967732();
        let strippedShortcut = shortcut;
        specialChars.forEach(({ char: char })=>{
            strippedShortcut = strippedShortcut.split(char).join('');
        });
        return strippedShortcut.toLowerCase();
    };
    const $cf289af0c139712c$export$389b0273703d5c48 = (sections)=>sections.reduce((acc, curr)=>{
            Object.entries(curr).forEach(([key, entry])=>{
                acc[key] = entry;
            });
            return acc;
        }, {});
    const $cf289af0c139712c$export$60a91e1b94ab4fef = (childResults)=>{
        const commands = [];
        const sections = [];
        childResults.forEach((childResult)=>{
            commands.push(...childResult.commands);
            if (childResult.sections) sections.push(childResult.sections);
        });
        return {
            commands: commands,
            sections: $cf289af0c139712c$export$389b0273703d5c48(sections)
        };
    };
    const $cf289af0c139712c$export$ae8f55d2b67cb8d6 = (text, commonWordsToBeRemoved)=>{
        const commonWords = commonWordsToBeRemoved.toLowerCase().replace(/\s{2,}/g, ' ').trim().split(' ');
        const regexString = commonWords.map((word)=>`\\b${word}\\b`).join('|');
        const regex = new RegExp(regexString, 'g');
        return text.replace(/[.,]/g, '').replace(regex, '').replace(/\s{2,}/g, ' ').trim();
    };
    const $cf289af0c139712c$export$4023cd53e5e5fcb8 = (state, command, method = 'undefined', wasSecondaryTriggered, attributes)=>{
        const analyticsAttributes = {
            commandPaletteId: command.id,
            commandPaletteSessionId: state.commandPaletteSessionId,
            searchTaken: !!state.search,
            method: method,
            cpMenu: 'rootMenu',
            action: command.id,
            actionCategory: 'none',
            wasSecondaryTriggered: wasSecondaryTriggered,
            ...$cf289af0c139712c$export$8af60a6897157f84(state.registry, state.activeRegistry, command.parentCommands) || {},
            ...$cf289af0c139712c$export$6a1ed481c04a3ae6(state.registry, state.activeRegistry, command.id) || {},
            ...$cf289af0c139712c$export$eb055284806da6e5(state.registry, state.activeRegistry, state.expandedCommand?.id) || {},
            ...attributes,
            ...!!command.analytics && command.analytics
        };
        if (state.expandedCommand?.analytics) analyticsAttributes.cpMenu = state.expandedCommand.analytics.action;
        else if (state.expandedCommand?.id) analyticsAttributes.cpMenu = state.expandedCommand.id;
        const sections = state.expandedCommand && state.childResult?.sections ? $cf289af0c139712c$export$389b0273703d5c48([
            ...state.sections,
            state.childResult?.sections
        ]) : $cf289af0c139712c$export$389b0273703d5c48(state.sections);
        const rootCommand = state.parentCommand?.[0] || state.expandedCommand || command;
        const rootSection = rootCommand.section ? sections?.[rootCommand.section] : undefined;
        analyticsAttributes.actionCategory = rootSection?.actionCategory || rootCommand?.section || rootCommand?.analytics?.actionCategory || (command?.section ? sections?.[command?.section]?.actionCategory || command?.section : undefined) || analyticsAttributes.actionCategory;
        return analyticsAttributes;
    };
    const $cf289af0c139712c$export$daa5beabdf8d9fc3 = (event)=>(0, (0, parcelRequire("2f2jJ")).isMacOs)() ? event.metaKey : event.ctrlKey;
    const $cf289af0c139712c$export$bd5ff50f0e53d41c = (command)=>{
        if (command?.wasSecondaryTriggered) {
            if (command?.secondaryAction?.type) {
                const hasSecondaryChildren = (0, (0, parcelRequire("jh7L9")).COMMAND_WITH_CHILDREN).some((commandType)=>commandType === command?.secondaryAction?.type);
                return hasSecondaryChildren;
            }
            return (0, (0, parcelRequire("jh7L9")).COMMAND_WITH_CHILDREN).some((commandType)=>commandType === command?.primaryAction?.type);
        }
        return (0, (0, parcelRequire("jh7L9")).COMMAND_WITH_CHILDREN).some((commandType)=>commandType === command?.primaryAction?.type);
    };
    const $cf289af0c139712c$export$bc9cd28438b3ea4b = (result)=>{
        if (result) return {
            globalIndex: result.index,
            sectionIndex: result.sectionIndex,
            indexWithinSection: result.indexWithinSection,
            sectionId: result.sectionId
        };
        return {};
    };
    const $cf289af0c139712c$export$fa1c95a5ffa92036 = (str)=>(0, $bdDiz.createHash)().update(str).digest('hex');
    const $cf289af0c139712c$export$dba3e2bcdbd49da4 = (str)=>str.length > 0 ? str.split(/\s+/).length : 0;
    const $cf289af0c139712c$export$fb6dafa07e60c21a = (command, wasSecondaryTriggered)=>{
        if (!command) return null;
        const { secondaryAction: secondaryAction, primaryAction: primaryAction } = command;
        if (!primaryAction) return null;
        const commandAction = wasSecondaryTriggered && secondaryAction ? secondaryAction : primaryAction;
        return {
            ...commandAction
        };
    };
    const $cf289af0c139712c$export$e217e69099d082f5 = (command)=>Boolean(command.primaryAction || command.secondaryAction);
    const $cf289af0c139712c$export$c3d20e8c1e93a572 = ()=>{
        const timeoutRef = (0, $5uXOq.useRef)();
        (0, $5uXOq.useEffect)(()=>()=>{
                if (timeoutRef.current) clearTimeout(timeoutRef.current);
            }, []);
        const setTimeoutAutoClear = (...args)=>{
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(...args);
        };
        return {
            setTimeoutAutoClear: setTimeoutAutoClear
        };
    };
});
parcelRegister("2f2jJ", function(module, exports) {
    $parcel$export(module.exports, "isMacOs", ()=>$6846128323867ebd$export$c5e71f489eb3970b);
    $parcel$export(module.exports, "getShortcutModifierKey", ()=>$6846128323867ebd$export$fe66dae91c68619b);
    const $6846128323867ebd$export$c5e71f489eb3970b = ()=>/Mac/i.test(window?.navigator?.platform);
    const $6846128323867ebd$export$fe66dae91c68619b = ()=>$6846128323867ebd$export$c5e71f489eb3970b() ? 'meta' : 'ctrl';
});
parcelRegister("bcTvL", function(module, exports) {
    $parcel$export(module.exports, "UI_EVENT_TYPE", ()=>$3996a4302dca83b1$export$a1562d08625f5d7e);
    $parcel$export(module.exports, "TRACK_EVENT_TYPE", ()=>$3996a4302dca83b1$export$947dac6c72b26f81);
    $parcel$export(module.exports, "SHORTCUT_ELEMENT_TYPES", ()=>$3996a4302dca83b1$export$1ee9d153576afd13);
    $parcel$export(module.exports, "COMMAND_PALETTE_DEBOUNCE_DEFAULT", ()=>$3996a4302dca83b1$export$894e19fc96687503);
    const $3996a4302dca83b1$export$a1562d08625f5d7e = 'UI';
    const $3996a4302dca83b1$export$947dac6c72b26f81 = 'TRACK';
    const $3996a4302dca83b1$export$f1d537d508d2945a = 'SCREEN';
    const $3996a4302dca83b1$export$12346a674644c156 = 'OPERATIONAL';
    var $3996a4302dca83b1$export$1ee9d153576afd13;
    (function(SHORTCUT_ELEMENT_TYPES) {
        SHORTCUT_ELEMENT_TYPES["THEN"] = "then";
        SHORTCUT_ELEMENT_TYPES["OR"] = "or";
        SHORTCUT_ELEMENT_TYPES["PLUS"] = "plus";
        SHORTCUT_ELEMENT_TYPES["KEY"] = "key";
    })($3996a4302dca83b1$export$1ee9d153576afd13 || ($3996a4302dca83b1$export$1ee9d153576afd13 = {}));
    const $3996a4302dca83b1$export$894e19fc96687503 = 250;
});
parcelRegister("jh7L9", function(module, exports) {
    $parcel$export(module.exports, "CommandActionType", ()=>$b1e48ebd4c8d5e4e$export$f041b1b117ab382f);
    $parcel$export(module.exports, "COMMAND_WITH_CHILDREN", ()=>$b1e48ebd4c8d5e4e$export$904b2a0e38a1bef3);
    var $b1e48ebd4c8d5e4e$export$f041b1b117ab382f;
    (function(CommandActionType) {
        CommandActionType["PERFORM"] = "perform";
        CommandActionType["COMMAND_LIST"] = "command-list";
    })($b1e48ebd4c8d5e4e$export$f041b1b117ab382f || ($b1e48ebd4c8d5e4e$export$f041b1b117ab382f = {}));
    const $b1e48ebd4c8d5e4e$export$3e448fd53031e13c = Object.values($b1e48ebd4c8d5e4e$export$f041b1b117ab382f);
    const $b1e48ebd4c8d5e4e$export$904b2a0e38a1bef3 = [
        "command-list"
    ];
});
parcelRegister("2sRNe", function(module, exports) {
    $parcel$export(module.exports, "actions", ()=>$4f4c9e92d50d4f24$export$e324594224ef24da);
    var $kGsr8 = parcelRequire("kGsr8");
    var $7Jxgt = parcelRequire("7Jxgt");
    var $e7WVN;
    var $eTXmK;
    var $2f2jJ;
    var $jcw0u;
    var $bcTvL;
    var $jh7L9;
    var $gM5ou;
    var $4DwnT;
    var $h3eZO;
    const $4f4c9e92d50d4f24$var$rootCommands = {
        parentCommand: [],
        expandedCommand: undefined,
        childCommands: undefined,
        childResult: undefined
    };
    const $4f4c9e92d50d4f24$var$open = ()=>({ setState: setState, getState: getState })=>{
            if (getState().isDisabled) return;
            setState({
                commandPaletteSessionId: (0, ($parcel$interopDefault($7Jxgt))).v4(),
                isOpen: true,
                activeIndex: 0,
                search: ''
            });
        };
    const $4f4c9e92d50d4f24$var$close = ()=>({ setState: setState })=>{
            setState({
                isOpen: false,
                commandPaletteSessionId: '',
                search: '',
                ...$4f4c9e92d50d4f24$var$rootCommands
            });
        };
    const $4f4c9e92d50d4f24$var$toggle = ()=>({ setState: setState, getState: getState })=>{
            const isOpen = !getState().isOpen;
            setState({
                isOpen: isOpen,
                activeIndex: 0,
                search: '',
                commandPaletteSessionId: isOpen ? (0, ($parcel$interopDefault($7Jxgt))).v4() : '',
                ...!isOpen ? $4f4c9e92d50d4f24$var$rootCommands : {}
            });
        };
    const $4f4c9e92d50d4f24$var$addCommands = (commands)=>({ setState: setState, getState: getState })=>{
            const currentState = getState();
            const newCommands = currentState.commands.concat(commands);
            const newState = {
                commands: newCommands
            };
            if (currentState.expandedCommand) {
                const matchedExpandedCommand = commands.filter((command)=>command.id === currentState.expandedCommand?.id);
                if (matchedExpandedCommand.length === 1) {
                    const [updatedExpandedCommand] = matchedExpandedCommand;
                    newState.expandedCommand = updatedExpandedCommand;
                }
            }
            setState(newState);
        };
    const $4f4c9e92d50d4f24$var$removeCommands = (commands)=>({ setState: setState, getState: getState })=>{
            const newCommands = getState().commands.filter((command)=>!commands.includes(command));
            setState({
                commands: newCommands
            });
        };
    const $4f4c9e92d50d4f24$var$removeCommandsById = (commandIds)=>({ setState: setState, getState: getState })=>{
            const newCommands = getState().commands.filter((command)=>!commandIds.includes(command.id));
            setState({
                commands: newCommands
            });
        };
    const $4f4c9e92d50d4f24$var$handleSearch = (search)=>({ setState: setState })=>{
            setState({
                search: search,
                activeIndex: 0
            });
        };
    const $4f4c9e92d50d4f24$var$forceSearchValue = (search)=>({ setState: setState })=>{
            setState({
                search: search,
                shouldForceSearchValue: true
            });
        };
    const $4f4c9e92d50d4f24$var$resetShouldForceSearchValue = ()=>({ setState: setState })=>{
            setState({
                shouldForceSearchValue: false
            });
        };
    const $4f4c9e92d50d4f24$var$goBackLevel = (goToRoot, analytics)=>({ setState: setState, getState: getState })=>{
            const currentState = getState();
            if (!currentState.expandedCommand) return;
            const newState = {
                search: '',
                activeIndex: 0,
                childResult: undefined,
                forceSearchFocus: true
            };
            if (currentState.parentCommand.length && !goToRoot) {
                newState.expandedCommand = currentState.parentCommand[currentState.parentCommand.length - 1];
                newState.parentCommand = currentState.parentCommand.slice(0, currentState.parentCommand.length - 1);
                if (newState.expandedCommand?.primaryAction?.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).COMMAND_LIST && newState.expandedCommand?.primaryAction?.commands) newState.childResult = {
                    commands: newState.expandedCommand?.primaryAction?.commands,
                    sections: newState.expandedCommand?.primaryAction?.sections
                };
            } else {
                newState.loadingList = false;
                newState.parentCommand = [];
                newState.expandedCommand = undefined;
                newState.childResult = undefined;
            }
            if (currentState?.onAnalytics && analytics?.createAnalyticsEvent) currentState.onAnalytics(analytics.createAnalyticsEvent({
                action: 'navigation',
                actionSubject: 'commandPalette'
            }), (0, (0, parcelRequire("bcTvL")).TRACK_EVENT_TYPE), {
                attributes: {
                    cpMenu: currentState.expandedCommand?.id || 'rootMenu',
                    searchSessionId: currentState.commandPaletteSessionId,
                    action: goToRoot ? 'backToRoot' : 'backToParent',
                    actionCategory: 'back',
                    method: analytics?.method
                }
            });
            setState(newState);
        };
    const $4f4c9e92d50d4f24$var$forceFocusSearch = (forceSearchFocus = false)=>({ setState: setState })=>{
            setState({
                forceSearchFocus: forceSearchFocus
            });
        };
    const $4f4c9e92d50d4f24$var$setActiveIndex = (index)=>({ setState: setState, getState: getState })=>{
            const { isSearchFieldFocused: isSearchFieldFocused } = getState();
            setState({
                activeIndex: index,
                ...!isSearchFieldFocused ? {
                    forceSearchFocus: true
                } : {}
            });
        };
    const $4f4c9e92d50d4f24$var$getAnalyticsAttributes = (command, method, wasSecondaryTriggered = false, attributes = {})=>({ getState: getState })=>(0, (0, parcelRequire("gM5ou")).getAnalyticsAttributesFromState)(getState(), command, method, wasSecondaryTriggered, attributes);
    const $4f4c9e92d50d4f24$var$performDialogCloseCommand = ()=>({ setState: setState, getState: getState })=>{
            setTimeout(()=>{
                const { onClosePendingCommandAction: onClosePendingCommandAction, onAnalytics: onAnalytics } = getState();
                if (onClosePendingCommandAction !== undefined) {
                    try {
                        (0, (0, parcelRequire("eTXmK")).default)('command-palette-executed');
                        onClosePendingCommandAction.perform();
                        if (onAnalytics && onClosePendingCommandAction?.createAnalyticsEvent) {
                            const performAnalyticsEvent = onClosePendingCommandAction?.createAnalyticsEvent({
                                action: 'taskSucceeded',
                                actionSubject: 'commandPalette'
                            });
                            onAnalytics(performAnalyticsEvent, (0, (0, parcelRequire("bcTvL")).TRACK_EVENT_TYPE), {
                                attributes: onClosePendingCommandAction?.analyticsArgs
                            });
                        }
                    } catch (err) {
                        const error = err instanceof Error ? err : new Error('Command palette perform error');
                        (0, (0, parcelRequire("jcw0u")).default)({
                            error: error,
                            meta: {
                                id: 'compal.performDialogCloseCommand',
                                packageName: 'jiraCommandPalette',
                                teamName: typeof onClosePendingCommandAction?.analyticsArgs?.teamName === 'string' ? onClosePendingCommandAction?.analyticsArgs.teamName : 'deliveroo'
                            },
                            attributes: onClosePendingCommandAction.analyticsArgs
                        });
                        (0, (0, parcelRequire("e7WVN")).setInteractionError)('command-palette-executed', {
                            errorMessage: error.message,
                            name: 'performDialogCloseCommandPerformError'
                        });
                    }
                    setState({
                        onClosePendingCommandAction: undefined
                    });
                }
            }, 0);
        };
    const $4f4c9e92d50d4f24$var$executeCommand = (command, analytics, wasSecondaryTriggered = false)=>({ setState: setState, getState: getState })=>{
            const currentState = getState();
            const newState = {
                ...!(0, (0, parcelRequire("4DwnT")).getActiveCommandList)(command, wasSecondaryTriggered)?.keepSearch && {
                    search: ''
                },
                activeIndex: 0
            };
            const analyticsAttributes = (0, (0, parcelRequire("gM5ou")).getAnalyticsAttributesFromState)(currentState, command, analytics?.method || 'undefined', wasSecondaryTriggered, analytics?.attributes);
            const currentCommandAction = (0, (0, parcelRequire("gM5ou")).getCommandAction)(command, wasSecondaryTriggered);
            if (!currentCommandAction) return;
            if (currentState?.onAnalytics && analytics?.createAnalyticsEvent) {
                const analyticsEvent = analytics.createAnalyticsEvent({
                    action: 'navigation',
                    actionSubject: 'commandPalette'
                });
                currentState.onAnalytics(analyticsEvent, (0, (0, parcelRequire("bcTvL")).TRACK_EVENT_TYPE), {
                    attributes: analyticsAttributes
                });
            }
            const shouldLoadChildren = (0, (0, parcelRequire("gM5ou")).hasChildren)({
                ...command,
                wasSecondaryTriggered: wasSecondaryTriggered
            });
            const shouldExecutePerform = currentCommandAction?.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).PERFORM;
            if (shouldLoadChildren) {
                (0, (0, parcelRequire("eTXmK")).default)('command-palette-load-child-command-list');
                if (currentState.expandedCommand) newState.parentCommand = [
                    ...currentState.parentCommand,
                    currentState.expandedCommand
                ];
                newState.expandedCommand = {
                    ...command,
                    wasSecondaryTriggered: wasSecondaryTriggered
                };
                newState.childResult = undefined;
                newState.forceSearchFocus = true;
                if ((0, (0, parcelRequire("h3eZO")).isSearchableChildrenAction)(currentCommandAction)) {
                    newState.childResult = {
                        commands: currentCommandAction.commands,
                        sections: currentCommandAction.sections
                    };
                    if (command.parentCommands?.length) newState.parentCommand = [
                        ...newState.parentCommand || [],
                        ...command.parentCommands
                    ];
                } else newState.childResult = undefined;
                setState(newState);
                if (currentCommandAction.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).COMMAND_LIST && currentCommandAction.onClick) currentCommandAction.onClick();
            } else if (shouldExecutePerform) {
                if (!currentCommandAction.preventModalClose) {
                    newState.commandPaletteSessionId = '';
                    newState.isOpen = false;
                    newState.expandedCommand = undefined;
                    newState.parentCommand = [];
                    newState.childResult = undefined;
                    setState(newState);
                    if (currentState.onAnalytics && analytics?.createAnalyticsEvent) {
                        const closeAnalyticsEvent = analytics.createAnalyticsEvent({
                            action: 'closed',
                            actionSubject: 'commandPalette'
                        });
                        currentState.onAnalytics(closeAnalyticsEvent, (0, (0, parcelRequire("bcTvL")).UI_EVENT_TYPE), {
                            attributes: analyticsAttributes
                        });
                    }
                }
                if (wasSecondaryTriggered && currentState.onAnalytics && analytics?.createAnalyticsEvent) {
                    const closeAnalyticsEvent = analytics.createAnalyticsEvent({
                        action: 'secondaryAction',
                        actionSubject: 'commandPalette'
                    });
                    currentState.onAnalytics(closeAnalyticsEvent, (0, (0, parcelRequire("bcTvL")).TRACK_EVENT_TYPE), {
                        attributes: {
                            ...analyticsAttributes,
                            keyPressed: (0, (0, parcelRequire("2f2jJ")).isMacOs)() ? 'cmdEnter' : 'ctrlEnter',
                            ...currentCommandAction.analyticsArgs
                        }
                    });
                }
                if (currentCommandAction.preventModalClose) try {
                    (0, (0, parcelRequire("eTXmK")).default)('command-palette-executed');
                    currentCommandAction.perform();
                    if (currentState.onAnalytics && analytics?.createAnalyticsEvent) {
                        const performAnalyticsEvent = analytics.createAnalyticsEvent({
                            action: 'taskSucceeded',
                            actionSubject: 'commandPalette'
                        });
                        currentState.onAnalytics(performAnalyticsEvent, (0, (0, parcelRequire("bcTvL")).TRACK_EVENT_TYPE), {
                            attributes: analyticsAttributes
                        });
                    }
                } catch (err) {
                    const error = err instanceof Error ? err : new Error('Command palette perform error');
                    (0, (0, parcelRequire("jcw0u")).default)({
                        error: error,
                        meta: {
                            id: 'compal.executeCommand',
                            packageName: 'jiraCommandPalette',
                            teamName: typeof analytics?.attributes?.teamName === 'string' ? analytics?.attributes.teamName : 'deliveroo'
                        },
                        attributes: analytics?.attributes
                    });
                    (0, (0, parcelRequire("e7WVN")).setInteractionError)('command-palette-executed', {
                        errorMessage: error.message,
                        name: 'executeCommandPerformError'
                    });
                }
                else setState({
                    onClosePendingCommandAction: {
                        ...currentCommandAction,
                        analyticsArgs: analyticsAttributes,
                        createAnalyticsEvent: analytics?.createAnalyticsEvent
                    }
                });
            }
        };
    const $4f4c9e92d50d4f24$var$setChildCommands = (childResult)=>({ setState: setState })=>{
            setState({
                childResult: childResult
            });
        };
    const $4f4c9e92d50d4f24$var$setLoadingList = (loadingList)=>({ setState: setState })=>{
            setState({
                loadingList: loadingList
            });
        };
    const $4f4c9e92d50d4f24$var$setFocusInput = (isSearchFieldFocused)=>({ setState: setState })=>setState({
                isSearchFieldFocused: isSearchFieldFocused
            });
    const $4f4c9e92d50d4f24$var$registerSections = (newSections)=>({ setState: setState, getState: getState })=>{
            const currentState = getState();
            const sections = currentState.sections.slice(0);
            sections.push(newSections);
            setState({
                sections: sections
            });
        };
    const $4f4c9e92d50d4f24$var$unregisterSections = (newSections)=>({ setState: setState, getState: getState })=>{
            const currentState = getState();
            const sections = currentState.sections.filter((section)=>section !== newSections);
            setState({
                sections: sections
            });
        };
    const $4f4c9e92d50d4f24$var$markAsLoaded = ()=>({ setState: setState })=>{
            setState({
                isLoaded: true
            });
        };
    const $4f4c9e92d50d4f24$var$setIsDisabled = (isDisabled)=>({ setState: setState })=>{
            setState({
                isDisabled: isDisabled,
                ...isDisabled ? {
                    isOpen: false
                } : {}
            });
        };
    const $4f4c9e92d50d4f24$var$setRegistry = (registry)=>({ setState: setState })=>{
            setState({
                registry: registry
            });
        };
    const $4f4c9e92d50d4f24$var$updateActiveRegistry = (registryId, commands)=>({ setState: setState, getState: getState })=>{
            const currentActiveRegistry = getState().activeRegistry;
            const activeRegistry = {
                commandRegistry: {
                    ...currentActiveRegistry.commandRegistry,
                    ...commands.reduce((acc, command)=>{
                        acc[command.id] = {
                            registryId: registryId
                        };
                        return acc;
                    }, {})
                },
                registryIds: (0, ($parcel$interopDefault($kGsr8)))([
                    ...currentActiveRegistry.registryIds,
                    registryId
                ])
            };
            setState({
                activeRegistry: activeRegistry
            });
        };
    const $4f4c9e92d50d4f24$var$removeFromActiveRegistry = (registryId, commands)=>({ setState: setState, getState: getState })=>{
            const currentActiveRegistry = getState().activeRegistry;
            const commandRegistry = {
                ...currentActiveRegistry.commandRegistry
            };
            commands.forEach((command)=>{
                delete commandRegistry[command.id];
            });
            setState({
                activeRegistry: {
                    commandRegistry: commandRegistry,
                    registryIds: currentActiveRegistry.registryIds.filter((id)=>id !== registryId)
                }
            });
        };
    const $4f4c9e92d50d4f24$var$setAnalytics = (onAnalytics, onErrorAnalytics)=>({ setState: setState })=>{
            setState({
                onAnalytics: onAnalytics,
                onErrorAnalytics: onErrorAnalytics
            });
        };
    const $4f4c9e92d50d4f24$var$setFallbackOverride = (fallbackOverride)=>({ setState: setState })=>{
            setState({
                fallbackOverride: fallbackOverride
            });
        };
    const $4f4c9e92d50d4f24$var$setHasPopupOverlay = (hasPopupOverlay)=>({ setState: setState })=>{
            setState({
                hasPopupOverlay: hasPopupOverlay
            });
        };
    const $4f4c9e92d50d4f24$export$e324594224ef24da = {
        open: $4f4c9e92d50d4f24$var$open,
        toggle: $4f4c9e92d50d4f24$var$toggle,
        close: $4f4c9e92d50d4f24$var$close,
        addCommands: $4f4c9e92d50d4f24$var$addCommands,
        removeCommands: $4f4c9e92d50d4f24$var$removeCommands,
        handleSearch: $4f4c9e92d50d4f24$var$handleSearch,
        forceFocusSearch: $4f4c9e92d50d4f24$var$forceFocusSearch,
        setActiveIndex: $4f4c9e92d50d4f24$var$setActiveIndex,
        executeCommand: $4f4c9e92d50d4f24$var$executeCommand,
        goBackLevel: $4f4c9e92d50d4f24$var$goBackLevel,
        setChildCommands: $4f4c9e92d50d4f24$var$setChildCommands,
        setLoadingList: $4f4c9e92d50d4f24$var$setLoadingList,
        setFocusInput: $4f4c9e92d50d4f24$var$setFocusInput,
        registerSections: $4f4c9e92d50d4f24$var$registerSections,
        unregisterSections: $4f4c9e92d50d4f24$var$unregisterSections,
        markAsLoaded: $4f4c9e92d50d4f24$var$markAsLoaded,
        getAnalyticsAttributes: $4f4c9e92d50d4f24$var$getAnalyticsAttributes,
        setIsDisabled: $4f4c9e92d50d4f24$var$setIsDisabled,
        setRegistry: $4f4c9e92d50d4f24$var$setRegistry,
        updateActiveRegistry: $4f4c9e92d50d4f24$var$updateActiveRegistry,
        removeCommandsById: $4f4c9e92d50d4f24$var$removeCommandsById,
        removeFromActiveRegistry: $4f4c9e92d50d4f24$var$removeFromActiveRegistry,
        performDialogCloseCommand: $4f4c9e92d50d4f24$var$performDialogCloseCommand,
        setAnalytics: $4f4c9e92d50d4f24$var$setAnalytics,
        setFallbackOverride: $4f4c9e92d50d4f24$var$setFallbackOverride,
        setHasPopupOverlay: $4f4c9e92d50d4f24$var$setHasPopupOverlay,
        forceSearchValue: $4f4c9e92d50d4f24$var$forceSearchValue,
        resetShouldForceSearchValue: $4f4c9e92d50d4f24$var$resetShouldForceSearchValue
    };
});
parcelRegister("4DwnT", function(module, exports) {
    $parcel$export(module.exports, "getActiveCommandList", ()=>$b4088022dc3f5c54$export$639b7f41b0b47a01);
    var $jh7L9;
    const $b4088022dc3f5c54$export$639b7f41b0b47a01 = (expandedCommand, wasSecondaryTriggered)=>{
        if (wasSecondaryTriggered && expandedCommand?.secondaryAction?.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).COMMAND_LIST) return expandedCommand.secondaryAction;
        if (!wasSecondaryTriggered && expandedCommand?.primaryAction?.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).COMMAND_LIST) return expandedCommand.primaryAction;
        return null;
    };
});
parcelRegister("h3eZO", function(module, exports) {
    $parcel$export(module.exports, "isSearchableChildrenAction", ()=>$dd5db8363c7eeda7$export$d75616d77443fe4e);
    var $jh7L9;
    const $dd5db8363c7eeda7$export$d75616d77443fe4e = (action)=>action?.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).COMMAND_LIST && action.commands !== undefined;
});
parcelRegister("2qauD", function(module, exports) {
    $parcel$export(module.exports, "createSwitchController", ()=>$a539fc33301c42ee$export$1856548326159ec8);
    var $5uXOq = parcelRequire("5uXOq");
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    const $a539fc33301c42ee$export$1856548326159ec8 = (name, activeByDefault)=>{
        const actions = {
            on: (active)=>({ setState: setState })=>setState({
                        active: active
                    }),
            off: (active)=>({ setState: setState, getState: getState })=>{
                    if (getState().active === active || active === undefined) setState({
                        active: undefined
                    });
                },
            toggle: (active)=>({ getState: getState, dispatch: dispatch })=>{
                    if (getState().active === active) dispatch(actions.off(active));
                    else dispatch(actions.on(active));
                }
        };
        const initialState = {
            active: activeByDefault
        };
        const store = (0, (0, parcelRequire("iXqE5")).createStore)({
            name: name,
            initialState: initialState,
            actions: actions
        });
        const SwitchContainer = (0, (0, parcelRequire("gg7kZ")).createContainer)(store);
        const selector = (state, key)=>state.active === key;
        const useSwitchInternal = (0, (0, parcelRequire("3sQ5x")).createHook)(store, {
            selector: selector
        });
        const useSwitch = (active)=>{
            const [isActive, { on: onSwitch, off: offSwitch, toggle: toggleSwitch }] = useSwitchInternal(active);
            const on = (0, $5uXOq.useCallback)(()=>onSwitch(active), [
                onSwitch,
                active
            ]);
            const off = (0, $5uXOq.useCallback)(()=>offSwitch(active), [
                active,
                offSwitch
            ]);
            const toggle = (0, $5uXOq.useCallback)(()=>toggleSwitch(active), [
                toggleSwitch,
                active
            ]);
            return [
                isActive,
                {
                    on: on,
                    off: off,
                    toggle: toggle
                }
            ];
        };
        return {
            SwitchContainer: SwitchContainer,
            useSwitch: useSwitch
        };
    };
});
parcelRegister("gAtvZ", function(module, exports) {
    $parcel$export(module.exports, "useFromSpotlightTour", ()=>$27f5c6431be57d88$export$dc0191efbad6291c);
    var $2qauD;
    const { useSwitch: $27f5c6431be57d88$var$useSwitch } = (0, (0, parcelRequire("2qauD")).createSwitchController)('spotlight');
    const $27f5c6431be57d88$export$dc0191efbad6291c = $27f5c6431be57d88$var$useSwitch;
});
parcelRegister("fVbkk", function(module, exports) {
    $parcel$export(module.exports, "MenuTrigger", ()=>$bb37eb68c16a7749$export$27d2ad3c5815583e);
    $parcel$export(module.exports, "ChevronRightIcon", ()=>$bb37eb68c16a7749$export$b837bd7eac09a33e);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $2bzBV;
    var $ebd3R;
    var $kJpBP;
    var $2Ui4E;
    var $6cNb0;
    var $hDzhb;
    var $kAJ4Y;
    var $iPj86;
    var $jmHsa;
    var $5MZG9;
    var $jngLf;
    var $9YfZr;
    const $bb37eb68c16a7749$export$27d2ad3c5815583e = (0, $5uXOq.forwardRef)(({ href: href, menuId: menuId, navigationItemId: navigationItemId, onBeforeMenuOpen: onBeforeMenuOpen = ()=>undefined, params: params, query: query, replace: replace, to: to, type: type, analyticsAttributes: analyticsAttributes, ...rest }, ref)=>{
        const [isMenuOpen, { off: closeMenu, toggle: toggleMenu }] = (0, (0, parcelRequire("2Ui4E")).useTopMenus)(menuId);
        const { isVisible: isVisible } = (0, (0, parcelRequire("2bzBV")).useOverflowStatus)();
        const isHighlighted = (0, (0, parcelRequire("kAJ4Y")).useMenuHighlighted)(menuId);
        const triggerAnalytics = (0, (0, parcelRequire("iPj86")).useNavigationItemAnalytics)({
            navigationItemId: navigationItemId
        });
        const onClick = (0, $5uXOq.useCallback)(()=>{
            if (!isMenuOpen) onBeforeMenuOpen();
            toggleMenu();
            triggerAnalytics(analyticsAttributes);
        }, [
            analyticsAttributes,
            isMenuOpen,
            onBeforeMenuOpen,
            toggleMenu,
            triggerAnalytics
        ]);
        (0, $5uXOq.useEffect)(()=>closeMenu, [
            closeMenu
        ]);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdGenerate)('primary-actions', menuId, 'menu-trigger');
        if (isVisible) return href != null ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jmHsa")).PrimaryButton), {
            ...rest,
            component: (0, (0, parcelRequire("hDzhb")).default),
            href: href,
            isHighlighted: isHighlighted,
            onClick: onClick,
            ref: ref,
            testId: testId,
            type: "a"
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5MZG9")).PrimaryDropdownButton), {
            ...rest,
            isHighlighted: isHighlighted,
            isSelected: isMenuOpen,
            onClick: onClick,
            ref: ref,
            testId: testId
        });
        if (to != null && __SPA__) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9YfZr")).RouteLinkItem), {
            ...rest,
            onClick: onClick,
            params: params,
            query: query,
            replace: replace,
            testId: testId,
            to: to,
            type: type
        });
        return href != null ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jngLf")).LinkItem), {
            ...rest,
            href: href,
            onClick: onClick,
            testId: testId
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            ref: ref
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kJpBP")).default), {
            ...rest,
            iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement($bb37eb68c16a7749$export$b837bd7eac09a33e, null),
            isSelected: isMenuOpen,
            onClick: onClick,
            testId: testId
        }));
    });
    $bb37eb68c16a7749$export$27d2ad3c5815583e.whyDidYouRender = {
        customName: 'MenuTrigger'
    };
    const $bb37eb68c16a7749$export$b837bd7eac09a33e = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement($bb37eb68c16a7749$var$ChevronWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("ebd3R"))))), {
            label: ""
        }));
    const $bb37eb68c16a7749$var$ChevronWrapper = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_tzy4105o _2hwxmgjw",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("kAJ4Y", function(module, exports) {
    $parcel$export(module.exports, "useMenuHighlighted", ()=>$afe91b4b16cc8923$export$d47286bd4be0870d);
    var $2Ioew;
    var $6jY6G;
    const $afe91b4b16cc8923$export$d47286bd4be0870d = (menuId)=>{
        const route = (0, (0, parcelRequire("6jY6G")).useCurrentRoute)();
        const topNavEntryPointReference = (0, (0, parcelRequire("2Ioew")).usePageLayoutEntryPoint)((0, $6d6ccf8a2b2c5b3c$export$bec6dca2e63de4ae));
        if (route.navigation) return route.navigation.menuId === menuId;
        if (topNavEntryPointReference) return topNavEntryPointReference.extraProps.menuId === menuId;
        return false;
    };
});
const $6d6ccf8a2b2c5b3c$export$948d4d345566aed3 = '__pageLayoutHorizontalNavigation';
const $6d6ccf8a2b2c5b3c$export$50ea883ba6f28ff5 = '__pageLayoutLeftSidebar';
const $6d6ccf8a2b2c5b3c$export$900ddb492c628f48 = '__pageLayoutMainContent';
const $6d6ccf8a2b2c5b3c$export$bec6dca2e63de4ae = '__pageLayoutTopNavigation';
const $6d6ccf8a2b2c5b3c$export$7bf43119a6b49ddb = '__pageLayoutRightSidebar';
parcelRegister("iPj86", function(module, exports) {
    $parcel$export(module.exports, "useNavigationItemAnalytics", ()=>$e639936782444614$export$444d136efe970fd3);
    var $5uXOq = parcelRequire("5uXOq");
    var $2bzBV;
    var $8zOmE;
    var $2NMNM;
    const $e639936782444614$export$444d136efe970fd3 = ({ navigationItemId: navigationItemId })=>{
        const { isVisible: isVisible } = (0, (0, parcelRequire("2bzBV")).useOverflowStatus)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        return (0, $5uXOq.useCallback)((attributes = {})=>{
            const analyticsEvent = createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'navigationItem'
            });
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, navigationItemId, {
                ...attributes,
                isInMore: !isVisible,
                navigationLayer: 'global'
            });
        }, [
            createAnalyticsEvent,
            isVisible,
            navigationItemId
        ]);
    };
});
parcelRegister("jmHsa", function(module, exports) {
    $parcel$export(module.exports, "PrimaryButton", ()=>$d112cde340963e02$export$ac16a6a04d9b7c84);
    var $5uXOq = parcelRequire("5uXOq");
    var $711X2;
    var $6R0Et;
    const $d112cde340963e02$export$ac16a6a04d9b7c84 = (0, $5uXOq.forwardRef)((props, ref)=>{
        if (false) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6R0Et")).SkeletonPrimaryButton), {
            text: props.children === undefined ? '' : props.children,
            isHighlighted: props.isHighlighted
        });
        const buttonProps = {
            ...props,
            ref: ref
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("711X2")).PrimaryButton), buttonProps);
    });
});
parcelRegister("5MZG9", function(module, exports) {
    $parcel$export(module.exports, "PrimaryDropdownButton", ()=>$e5d31dff0e605df2$export$bb3af89fb196520d);
    var $5uXOq = parcelRequire("5uXOq");
    var $7ownK;
    var $6R0Et;
    const $e5d31dff0e605df2$export$bb3af89fb196520d = (0, $5uXOq.forwardRef)((props, ref)=>{
        if (false) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6R0Et")).SkeletonPrimaryButton), {
            isDropdownButton: true,
            isHighlighted: props.isHighlighted,
            text: props.children === undefined ? '' : props.children
        });
        const buttonProps = {
            ...props,
            ref: ref
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ownK")).PrimaryDropdownButton), buttonProps);
    });
});
parcelRegister("jngLf", function(module, exports) {
    $parcel$export(module.exports, "LinkItem", ()=>$3ef5556c5063294d$export$532cb6865ebe8946);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $3Z96c;
    var $gRLNg;
    const $3ef5556c5063294d$export$532cb6865ebe8946 = (0, $5uXOq.forwardRef)(({ href: href, target: target, onClick: onClick, ...rest }, ref)=>{
        const navigate = (0, (0, parcelRequire("gRLNg")).useNavigate)({
            href: href,
            onClick: onClick,
            target: target
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement($3ef5556c5063294d$export$8c0773eab230d49e, {
            href: href,
            target: target,
            rel: target === '_blank' ? 'noopener noreferrer' : '',
            onClick: navigate,
            ref: ref,
            ...rest
        });
    });
    const $3ef5556c5063294d$export$8c0773eab230d49e = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("3Z96c")).default), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_9h8h1kw7 _f8pj1kw7 _30l31kw7 _1nrm18uv _1a3b18uv _9oik18uv _c2wa1kw7 _4fpr1kw7 _1bnx1kw7 _1iohnqa1 _5goinqa1 _jf4cnqa1",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("gRLNg", function(module, exports) {
    $parcel$export(module.exports, "useNavigate", ()=>$f019fb7f0ef5833c$export$9770f232ac06a008);
    var $5uXOq = parcelRequire("5uXOq");
    var $lVJAU;
    const $f019fb7f0ef5833c$export$9770f232ac06a008 = ({ href: href, target: target, onClick: onClick, useRouterActions: useRouterActions = (0, (0, parcelRequire("lVJAU")).useRouterActions), useCallback: useCallback = (0, $5uXOq.useCallback) })=>{
        const { push: push } = useRouterActions();
        return useCallback((event)=>{
            onClick && onClick(event);
            if (event.ctrlKey || event.metaKey || event.defaultPrevented || target === '_blank') return;
            event.preventDefault();
            push(href);
        }, [
            push,
            href,
            target,
            onClick
        ]);
    };
});
parcelRegister("9YfZr", function(module, exports) {
    $parcel$export(module.exports, "RouteLinkItem", ()=>$3031a27cd367c26b$export$5885e1e37551a766);
    var $5uXOq = parcelRequire("5uXOq");
    var $1aogK;
    var $hDzhb;
    const $3031a27cd367c26b$export$5885e1e37551a766 = (0, $5uXOq.forwardRef)(({ rel: rel, target: target, ...props }, ref)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1aogK")).default), {
            component: (0, (0, parcelRequire("hDzhb")).default),
            rel: rel ?? (target === '_blank' ? 'noopener noreferrer' : ''),
            target: target,
            prefetch: "hover",
            ref: ref,
            ...props
        }));
});
parcelRegister("8JFi2", function(module, exports) {
    $parcel$export(module.exports, "stopPropagation", ()=>$df06b43d1eb10455$export$51134cce184326b9);
    const $df06b43d1eb10455$export$51134cce184326b9 = (e)=>e.stopPropagation();
});
parcelRegister("h5OKf", function(module, exports) {
    $parcel$export(module.exports, "usePersonalisedAppsTeamType", ()=>$1c093da579689f30$export$952bf3a355bf4d23);
    var $3sQ5x;
    var $iXqE5;
    var $cE27s;
    const $1c093da579689f30$var$initialState = {
        isLoading: false,
        isSuccess: false,
        error: null,
        promise: null,
        teamType: undefined,
        hasSuccessOnce: false
    };
    const $1c093da579689f30$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        actions: (0, parcelRequire("cE27s")).actions,
        initialState: $1c093da579689f30$var$initialState,
        name: 'atlassian-navigation-addons-personalised-apps-team-type'
    });
    const $1c093da579689f30$export$952bf3a355bf4d23 = (0, (0, parcelRequire("3sQ5x")).createHook)($1c093da579689f30$var$store);
    const $1c093da579689f30$export$202fd798d158e553 = (0, (0, parcelRequire("3sQ5x")).createHook)($1c093da579689f30$var$store, {
        selector: null
    });
});
parcelRegister("cE27s", function(module, exports) {
    $parcel$export(module.exports, "actions", ()=>$cbb59c8ecf2badc7$export$e324594224ef24da);
    var $i5yN4;
    var $26xre;
    const $cbb59c8ecf2badc7$var$loadPersonalisedAppsUserSegmentation = ()=>async ({ setState: setState })=>{
            const promise = (0, (0, parcelRequire("i5yN4")).getUserSegmentationData)();
            setState({
                error: null,
                isLoading: true,
                isSuccess: false,
                promise: promise
            });
            try {
                const { team: team } = await promise;
                const teamType = (0, (0, parcelRequire("26xre")).mapUserTeamTypeToTeamType)(team);
                setState({
                    teamType: teamType,
                    isSuccess: true,
                    hasSuccessOnce: true
                });
            } catch (error) {
                setState({
                    error: error
                });
            } finally{
                setState({
                    isLoading: false
                });
            }
        };
    const $cbb59c8ecf2badc7$export$e324594224ef24da = {
        loadPersonalisedAppsUserSegmentation: $cbb59c8ecf2badc7$var$loadPersonalisedAppsUserSegmentation
    };
});
parcelRegister("i5yN4", function(module, exports) {
    $parcel$export(module.exports, "getUserSegmentationData", ()=>$d567b9983c1fa90f$export$f0a14a7cbbb7397e);
    var $jcw0u;
    var $eusud;
    const $d567b9983c1fa90f$var$GET_ME_URL = '/gateway/api/me';
    const $d567b9983c1fa90f$export$318c25600a19e2ee = ()=>(0, (0, parcelRequire("eusud")).performGetRequest)($d567b9983c1fa90f$var$GET_ME_URL);
    const $d567b9983c1fa90f$export$f0a14a7cbbb7397e = async ()=>{
        let response = {
            jobTitle: undefined,
            team: undefined
        };
        try {
            const { extended_profile: extendedProfile } = await $d567b9983c1fa90f$export$318c25600a19e2ee();
            const { job_title: jobTitle, team_type: team } = extendedProfile;
            response = {
                jobTitle: jobTitle,
                team: team
            };
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: 'getUserSegmentationData',
                    packageName: 'jiraAtlassianNavigation',
                    teamName: 'navigation'
                },
                error: error
            });
        }
        return response;
    };
});
parcelRegister("26xre", function(module, exports) {
    $parcel$export(module.exports, "mapUserTeamTypeToTeamType", ()=>$3464a59742bad1ac$export$38b9d6b529723f8c);
    var $2nA8w;
    const $3464a59742bad1ac$export$38b9d6b529723f8c = (team)=>{
        switch(team){
            case (0, (0, parcelRequire("2nA8w")).PERSONALISED_APPS_TEAM_TYPES).softwareDevelopment:
                return (0, (0, parcelRequire("2nA8w")).PERSONALISED_APPS_TEAM_TYPES).softwareDevelopment;
            case (0, (0, parcelRequire("2nA8w")).PERSONALISED_APPS_TEAM_TYPES).itSupport:
                return (0, (0, parcelRequire("2nA8w")).PERSONALISED_APPS_TEAM_TYPES).itSupport;
            default:
                return (0, (0, parcelRequire("2nA8w")).PERSONALISED_APPS_TEAM_TYPES).marketing;
        }
    };
});
parcelRegister("2nA8w", function(module, exports) {
    $parcel$export(module.exports, "PERSONALISED_APPS_TEAM_TYPES", ()=>$e0c89db7eea0cd0a$export$2bdcfab7823ff30);
    const $e0c89db7eea0cd0a$export$2bdcfab7823ff30 = {
        marketing: 'Business Management and Operations',
        itSupport: 'IT Support',
        softwareDevelopment: 'Software Development'
    };
});
parcelRegister("1bhgl", function(module, exports) {
    $parcel$export(module.exports, "AsyncMenu", ()=>$ed4263c510e6df56$export$81adf29ff528dc9c);
    var $5uXOq = parcelRequire("5uXOq");
    var $lfTZh;
    var $kxZPA;
    var $6Lg6A;
    var $ag3sS;
    var $9TMfX;
    var $4opAu;
    const $ed4263c510e6df56$var$Skeleton = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ag3sS")).MenuLayout), {
            isWide: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4opAu")).AppsSectionWrapper), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9TMfX")).MenuSkeletonContent), null)));
    const $ed4263c510e6df56$export$c49a6d699b74b197 = (0, (0, parcelRequire("kxZPA")).lazy)(()=>Promise.resolve((parcelRequire("4opAu"))).then((module)=>module.Menu), {
        ssr: false,
        moduleId: "./src/packages/navigation-apps/atlassian-navigation/src/ui/addons/menu/index.tsx"
    });
    const $ed4263c510e6df56$export$81adf29ff528dc9c = ({ shouldLoad: shouldLoad = true })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6Lg6A")).LazyWait), {
            until: shouldLoad
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement($ed4263c510e6df56$var$Skeleton, null),
            name: "lazy-menu"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ed4263c510e6df56$export$c49a6d699b74b197, null)));
});
parcelRegister("ag3sS", function(module, exports) {
    $parcel$export(module.exports, "MenuLayout", ()=>$a3bb5b7f7abc0b96$export$dc2a07138b27c65b);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    var $eZUJu;
    var $gwIPs;
    const $a3bb5b7f7abc0b96$export$dc2a07138b27c65b = ({ children: children, isWide: isWide = false })=>(0, ($parcel$interopDefault($5uXOq))).createElement($a3bb5b7f7abc0b96$var$Wrapper, {
            isWide: isWide
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eZUJu")).default), {
            maxHeight: $a3bb5b7f7abc0b96$var$maxHeight
        }, children));
    const $a3bb5b7f7abc0b96$var$starStyles = null;
    const $a3bb5b7f7abc0b96$var$Wrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isWide: isWide, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_1d8u6ab": (0, (0, parcelRequire("lD23V")).default)(__cmplp.isWide ? `${(0, (0, parcelRequire("gwIPs")).gridSize) * 40}px` : `${(0, (0, parcelRequire("gwIPs")).gridSize) * 29.25}px`)
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1ul911kg _p12f1c3h _107nidpf _js70kb7n _nqzgkb7n _ziy7kb7n",
                __cmplp.className
            ])
        });
    });
    const $a3bb5b7f7abc0b96$var$maxHeight = `calc(100vh - ${(0, (0, parcelRequire("gwIPs")).gridSize) * 25}px)`;
});
parcelRegister("9TMfX", function(module, exports) {
    $parcel$export(module.exports, "MenuSkeletonContent", ()=>$4a37f7ab6d486120$export$8db7a6d33c26b25f);
    var $5uXOq = parcelRequire("5uXOq");
    var $eaGfl;
    var $iwwXK;
    var $6cNb0;
    const $4a37f7ab6d486120$export$8db7a6d33c26b25f = ({ testIdPrefix: testIdPrefix })=>(0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            "data-testid": (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'skeleton')
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eaGfl")).default), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iwwXK")).default), {
            hasIcon: true,
            width: "62.5%"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iwwXK")).default), {
            hasIcon: true,
            width: "50%"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iwwXK")).default), {
            hasIcon: true,
            width: "62.5%"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iwwXK")).default), {
            hasIcon: true,
            width: "50%"
        }));
});
parcelRegister("4opAu", function(module, exports) {
    $parcel$export(module.exports, "Menu", ()=>$7aa6b084bbfecbd7$export$d9b273488cd8ce6f);
    $parcel$export(module.exports, "AppsSectionWrapper", ()=>$7aa6b084bbfecbd7$export$ff8a83a2de46823a);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $9UCCQ;
    var $aIZdc;
    var $azzrO;
    var $9oIax;
    var $39xOx;
    var $93jKl;
    var $bEm0N;
    var $jT2zB;
    var $4b1db;
    var $koVbs;
    var $lmyls;
    var $6cNb0;
    var $lfTZh;
    var $iXVV6;
    var $8zOmE;
    var $2NMNM;
    var $cNd4s;
    var $DVljb;
    var $Mp6qH;
    var $8vzcp;
    var $ag3sS;
    var $9TMfX;
    var $6WLja;
    var $h5OKf;
    var $dnhhB;
    var $6R6Mv;
    var $giMK6;
    var $9JuaB;
    var $2pyzy;
    var $jANwc;
    var $lduwc;
    const $7aa6b084bbfecbd7$var$EVENT_DATA_ADDONS = {
        id: (0, (0, parcelRequire("lmyls")).MENU_ID).ADDONS
    };
    const $7aa6b084bbfecbd7$var$EVENT_DATA_INTEGRATIONS = {
        id: 'integrations-apps-menu'
    };
    const $7aa6b084bbfecbd7$var$createIntegrationLink = (integration, isSiteAdmin, formatMessage)=>{
        const label = `${integration.label}${integration.categoryMessageKey !== undefined ? ` (${formatMessage((0, (0, parcelRequire("2pyzy")).default)[integration.categoryMessageKey])})` : ''}`;
        return integration.key === (0, (0, parcelRequire("giMK6")).SLACK) ? {
            url: (0, (0, parcelRequire("jT2zB")).getSlackConsentUrl)('apps-menu'),
            icon: integration.icon,
            actionSubjectId: integration.actionSubjectId,
            onClick: ()=>{
                (0, (0, parcelRequire("4b1db")).openSlackIntegrationPopup)('apps-menu');
            },
            label: label
        } : {
            url: integration.getUrl(`source=apps-menu&${isSiteAdmin ? 'installDialogOpen=true' : 'requestDialogOpen=true'}`),
            icon: integration.icon,
            actionSubjectId: integration.actionSubjectId,
            label: label
        };
    };
    const $7aa6b084bbfecbd7$export$d9b273488cd8ce6f = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [isFooterVisible, setIsFooterSeparatorVisible] = (0, $5uXOq.useState)(true);
        const FooterSection = isFooterVisible ? $7aa6b084bbfecbd7$export$ff8a83a2de46823a : (0, $5uXOq.Fragment);
        const onFail = (0, (0, parcelRequire("93jKl")).useExperienceFail)({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU)
        });
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const locale = (0, (0, parcelRequire("DVljb")).useLocale)();
        const isFedrampEnvironment = (0, (0, parcelRequire("aIZdc")).isFedRamp)();
        const testIdContent = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'content');
        const testIdFooter = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'footer');
        const testIdDiscover = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'discover');
        const isSiteAdmin = (0, (0, parcelRequire("cNd4s")).useIsSiteAdmin)();
        const [integrations, setIntegrations] = (0, $5uXOq.useState)([]);
        const [personalisedApps, setPersonalisedApps] = (0, $5uXOq.useState)([]);
        const [isLoadingPersonalisedApps, setIsLoadingPersonalisedApps] = (0, $5uXOq.useState)(true);
        const [{ teamType: teamType, error: teamTypeError, isLoading: isTeamTypeLoading }] = (0, (0, parcelRequire("h5OKf")).usePersonalisedAppsTeamType)();
        const [isAppsMenuFinishedRendering, setIsAppsMenuFinishedRendering] = (0, $5uXOq.useState)(false);
        const [{ isFetching: loadingIntegrations, hasFetchedOnce: hasFetchedIntegrations, fetchError: fetchIntegrationsError, integrations: appsInstalled }, { fetchIntegrations: fetchIntegrations }] = (0, (0, parcelRequire("dnhhB")).default)();
        const { isSlackIntegrationEnabled: isSlackIntegrationEnabled, isSlackIntegrationConnected: isSlackIntegrationConnected, isLoading: isLoadingSlackStatus } = (0, (0, parcelRequire("bEm0N")).useFetchSlackStatus)();
        const onDiscoverError = (0, $5uXOq.useCallback)((location, error)=>{
            onFail(location, error);
            (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(createAnalyticsEvent({}), 'appsDiscoverSection failed', 'appsMenuDiscoverSection', {
                error: 'Error loading discover section'
            });
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'loadingPersonalisedAppsExperiment failed', {
                message: error !== null && typeof error.message !== 'undefined' ? error.message : undefined,
                stack: error !== null && typeof error.stack !== 'undefined' ? error.stack : undefined
            });
        }, [
            createAnalyticsEvent,
            onFail
        ]);
        (0, $5uXOq.useEffect)(()=>{
            try {
                const teamAppList = (0, (0, parcelRequire("jANwc")).getTeamAlignedAppList)(teamType);
                setIntegrations(teamAppList);
                if (teamTypeError) setIsLoadingPersonalisedApps(false);
            } catch (error) {
                setIsLoadingPersonalisedApps(false);
                onDiscoverError('Getting a potential list of personalised apps failed.', error);
            }
        }, [
            teamType,
            isTeamTypeLoading,
            setIntegrations,
            teamTypeError,
            onDiscoverError,
            locale
        ]);
        (0, $5uXOq.useEffect)(()=>{
            try {
                if (!loadingIntegrations && hasFetchedIntegrations && !isLoadingSlackStatus) {
                    const integrationsToShow = integrations.filter(({ key: key })=>key === (0, (0, parcelRequire("giMK6")).SLACK) ? isSlackIntegrationEnabled && !isSlackIntegrationConnected : Boolean(appsInstalled[key]) === false).sort(()=>0.5 - Math.random()).slice(0, 3).map((integration)=>$7aa6b084bbfecbd7$var$createIntegrationLink(integration, isSiteAdmin, formatMessage));
                    setPersonalisedApps(integrationsToShow);
                    setIsLoadingPersonalisedApps(false);
                }
                if (fetchIntegrationsError) setIsLoadingPersonalisedApps(false);
            } catch (error) {
                setIsLoadingPersonalisedApps(false);
                onDiscoverError('Preparing personalised apps failed.', error);
            }
        }, [
            integrations,
            appsInstalled,
            isSiteAdmin,
            setPersonalisedApps,
            loadingIntegrations,
            hasFetchedIntegrations,
            fetchIntegrationsError,
            isSlackIntegrationEnabled,
            isSlackIntegrationConnected,
            isLoadingSlackStatus,
            formatMessage,
            onDiscoverError
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (integrations.length) fetchIntegrations(integrations.map((app)=>app.key));
        }, [
            integrations,
            fetchIntegrations
        ]);
        const errorFallback = (0, $5uXOq.useCallback)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8vzcp")).MenuError), {
                testIdPrefix: testIdContent
            }), [
            testIdContent
        ]);
        const errorFallbackDiscover = (0, $5uXOq.useCallback)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8vzcp")).MenuError), {
                testIdPrefix: testIdDiscover
            }), [
            testIdDiscover
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (isAppsMenuFinishedRendering) (0, (0, parcelRequire("azzrO")).NAVIGATION_TOP_DROPDOWN_MARKS).ADDONS.stop();
        }, [
            isAppsMenuFinishedRendering
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ag3sS")).MenuLayout), {
            isWide: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "personalised-apps",
            packageName: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME),
            fallback: errorFallback,
            extraEventData: $7aa6b084bbfecbd7$var$EVENT_DATA_ADDONS,
            onError: onFail,
            withExperienceTracker: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9oIax")).AuthenticationErrorBoundary), {
            render: errorFallback
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement($7aa6b084bbfecbd7$export$ff8a83a2de46823a, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9TMfX")).MenuSkeletonContent), {
                testIdPrefix: testIdContent
            })),
            name: "apps-section-wrapper"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6R6Mv")).Content), {
            testIdPrefix: testIdContent
        })))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "integrations-apps-menu",
            packageName: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME),
            extraEventData: $7aa6b084bbfecbd7$var$EVENT_DATA_INTEGRATIONS,
            render: errorFallbackDiscover,
            withExperienceTracker: false,
            onError: onDiscoverError
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement($7aa6b084bbfecbd7$export$ff8a83a2de46823a, {
                hasSeparator: true
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9TMfX")).MenuSkeletonContent), {
                testIdPrefix: testIdDiscover
            })),
            name: "discover-section"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: "appsMenuDiscoverSection",
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9JuaB")).DiscoverSection), {
            apps: personalisedApps,
            isLoadingApps: isLoadingPersonalisedApps,
            title: (0, (0, parcelRequire("2pyzy")).default).personalisedAppsTitle,
            description: (0, (0, parcelRequire("2pyzy")).default).personalisedAppsText,
            recommendationType: (0, (0, parcelRequire("giMK6")).RECOMMENDATION_TYPE).Personalised,
            setIsAppsMenuFinishedRendering: setIsAppsMenuFinishedRendering,
            isFedrampEnvironment: isFedrampEnvironment
        })))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "personalised-footer",
            packageName: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME),
            extraEventData: $7aa6b084bbfecbd7$var$EVENT_DATA_INTEGRATIONS,
            render: errorFallbackDiscover,
            withExperienceTracker: false,
            onError: onDiscoverError
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(FooterSection, {
            hasSeparator: true,
            testId: testIdFooter
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6WLja")).MenuSkeletonFooter), {
                testIdPrefix: testIdFooter
            }),
            name: "footer"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lduwc")).Footer), {
            onUpdateIsFooterVisible: setIsFooterSeparatorVisible,
            testIdPrefix: testIdFooter,
            isFedrampEnvironment: isFedrampEnvironment
        })))));
    };
    const $7aa6b084bbfecbd7$export$ff8a83a2de46823a = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("9UCCQ")).default), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1tke12am",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("9oIax", function(module, exports) {
    $parcel$export(module.exports, "AuthenticationErrorBoundary", ()=>$e7a35d76515ac063$export$124b2dc1d1aab5e6);
    var $5uXOq = parcelRequire("5uXOq");
    var $fPruT;
    class $e7a35d76515ac063$export$124b2dc1d1aab5e6 extends (0, $5uXOq.Component) {
        componentDidCatch(error, info) {
            if (error instanceof (0, (0, parcelRequire("fPruT")).AuthenticationError)) {
                this.setState({
                    caughtError: {
                        error: error,
                        info: info
                    }
                });
                return;
            }
            throw error;
        }
        render() {
            const { caughtError: caughtError } = this.state;
            const { children: children, render: renderFallback } = this.props;
            if (!caughtError) return children;
            return renderFallback();
        }
        constructor(...args){
            super(...args);
            this.state = {
                caughtError: null
            };
        }
    }
});
parcelRegister("bEm0N", function(module, exports) {
    $parcel$export(module.exports, "useFetchSlackStatus", ()=>$65230d4a6bc54b7a$export$bc4af53b82736ec2);
    var $5uXOq = parcelRequire("5uXOq");
    var $7f4tk;
    var $koeKL;
    const $65230d4a6bc54b7a$export$7ee0516d378ea459 = (cloudId)=>(0, (0, parcelRequire("7f4tk")).default)(`/gateway/api/slack/jira/enabled?cloudId=${cloudId}`, {
            method: 'GET'
        }).then((response)=>Boolean(response.enabled)).catch(()=>true);
    const $65230d4a6bc54b7a$export$71a3c7f7dc819106 = ()=>(0, (0, parcelRequire("7f4tk")).default)('/gateway/api/slack/jira/status', {
            method: 'GET'
        }).then((response)=>Boolean(response.connected)).catch(()=>false);
    const $65230d4a6bc54b7a$export$bc4af53b82736ec2 = (skip = false)=>{
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const [isSlackIntegrationEnabled, setSlackIntegrationEnabled] = (0, $5uXOq.useState)(true);
        const [isSlackIntegrationConnected, setSlackIntegrationConnected] = (0, $5uXOq.useState)(false);
        const [isLoading, setLoading] = (0, $5uXOq.useState)(true);
        (0, $5uXOq.useEffect)(()=>{
            if (skip || !cloudId) {
                setLoading(false);
                return;
            }
            setLoading(true);
            setSlackIntegrationEnabled(true);
            setSlackIntegrationConnected(false);
            Promise.all([
                $65230d4a6bc54b7a$export$7ee0516d378ea459(cloudId),
                $65230d4a6bc54b7a$export$71a3c7f7dc819106()
            ]).then(([isEnabled, isConnected])=>{
                setSlackIntegrationEnabled(isEnabled);
                setSlackIntegrationConnected(isConnected);
            }).catch(()=>{}).finally(()=>{
                setLoading(false);
            });
        }, [
            skip,
            cloudId
        ]);
        return {
            isLoading: isLoading,
            isSlackIntegrationEnabled: isSlackIntegrationEnabled,
            isSlackIntegrationConnected: isSlackIntegrationConnected
        };
    };
});
parcelRegister("jT2zB", function(module, exports) {
    $parcel$export(module.exports, "getSlackConsentUrl", ()=>$0422facbc4b1ac5b$export$cc6a5a2d00c6e3ad);
    const $0422facbc4b1ac5b$var$prodConsentUrl = 'https://atlassian-slack-integration.services.atlassian.com/api/slack/login';
    const $0422facbc4b1ac5b$var$stagingConsentUrl = 'https://atlassian-slack-integration.stg.services.atlassian.com/api/slack/login';
    const $0422facbc4b1ac5b$export$cc6a5a2d00c6e3ad = (source, w = window)=>{
        const isProd = !(w.location.hostname.endsWith('jira-dev.com') || w.location.hostname === 'localhost');
        const url = isProd ? $0422facbc4b1ac5b$var$prodConsentUrl : $0422facbc4b1ac5b$var$stagingConsentUrl;
        return `${url}?source=${source}`;
    };
});
parcelRegister("4b1db", function(module, exports) {
    $parcel$export(module.exports, "openSlackIntegrationPopup", ()=>$27081bb4a22bf553$export$a4ed879b9981f635);
    var $jT2zB;
    const $27081bb4a22bf553$var$WIDTH = 500;
    const $27081bb4a22bf553$var$HEIGHT = 900;
    const $27081bb4a22bf553$var$baseParams = {
        toolbar: 0,
        menubar: 0,
        location: 0,
        status: 0,
        scrollbars: 1,
        resizable: 0,
        chrome: 'yes'
    };
    const $27081bb4a22bf553$var$popupWindowParams = (w = window)=>{
        const width = Math.min($27081bb4a22bf553$var$WIDTH, w.screen.availWidth);
        const height = Math.min($27081bb4a22bf553$var$HEIGHT, w.screen.availHeight);
        const entities = {
            ...$27081bb4a22bf553$var$baseParams,
            width: width,
            height: height,
            left: Math.round((w.innerWidth - width) / 2 + w.screenX),
            top: Math.round((w.innerHeight - height) / 3 + w.screenY)
        };
        return Object.keys(entities).map((key)=>`${key}=${entities[key]}`).join(',');
    };
    const $27081bb4a22bf553$export$a4ed879b9981f635 = (source, w = window)=>w.open((0, (0, parcelRequire("jT2zB")).getSlackConsentUrl)(source), '_blank', $27081bb4a22bf553$var$popupWindowParams(w));
});
parcelRegister("8vzcp", function(module, exports) {
    $parcel$export(module.exports, "MenuError", ()=>$51a1ea955baf80ff$export$b4cb8426191db8b3);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $koVbs;
    var $6cNb0;
    var $jyltl;
    var $bIXOn = parcelRequire("bIXOn");
    var $itL3d;
    const $51a1ea955baf80ff$export$b4cb8426191db8b3 = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const errorText = (0, ($parcel$interopDefault($5uXOq))).createElement($51a1ea955baf80ff$var$TextWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement($51a1ea955baf80ff$var$Heading, null, formatMessage((0, (0, parcelRequire("itL3d")).default).errorHeading)), (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, formatMessage((0, (0, parcelRequire("itL3d")).default).errorBody)));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jyltl")).MenuInfo), {
            imgAltText: formatMessage((0, (0, parcelRequire("itL3d")).default).errorImg),
            imgSrc: (0, ($parcel$interopDefault($bIXOn))),
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'error'),
            text: errorText,
            useSmallImage: true
        });
    };
    const $51a1ea955baf80ff$var$TextWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1yt41e2i",
                __cmplp.className
            ])
        });
    });
    const $51a1ea955baf80ff$var$Heading = (0, $5uXOq.forwardRef)(({ as: C = "strong", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1ule _otyru2gc",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("jyltl", function(module, exports) {
    $parcel$export(module.exports, "MenuInfo", ()=>$c2ec3089b9809088$export$14caacd677140cdb);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    const $c2ec3089b9809088$export$14caacd677140cdb = ({ imgAltText: imgAltText, imgHeight: imgHeight, imgSrc: imgSrc, text: text, useSmallImage: useSmallImage = false, testId: testId })=>(0, ($parcel$interopDefault($5uXOq))).createElement($c2ec3089b9809088$export$895814aa5a1cf38c, {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($c2ec3089b9809088$export$e54065c861f556f9, {
            alt: imgAltText || '',
            imgHeight: imgHeight,
            src: imgSrc,
            useSmallImage: useSmallImage
        }), (0, ($parcel$interopDefault($5uXOq))).createElement($c2ec3089b9809088$export$7c3018f441722857, null, text));
    const $c2ec3089b9809088$export$895814aa5a1cf38c = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_19pkidpf _2hwx1wug _otyridpf _18u01wug _1bsbnklw _1e0c1txw _y3gn1h6o _2lx21bp4",
                __cmplp.className
            ])
        });
    });
    const $c2ec3089b9809088$export$e54065c861f556f9 = (0, $5uXOq.forwardRef)(({ as: C = "img", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { useSmallImage: useSmallImage, imgHeight: imgHeight, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_ve50id": (0, (0, parcelRequire("lD23V")).default)(__cmplp.imgHeight, "px")
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1ule _1yt47e8x _19pkidpf _2hwx1wug _otyridpf _18u01wug _p12f1osq",
                __cmplp.useSmallImage ? "_1bsb16xz" : "_1bsb1lit",
                __cmplp.imgHeight != null ? "_4t3i1m1g" : "_4t3i1wug",
                __cmplp.className
            ])
        });
    });
    const $c2ec3089b9809088$export$7c3018f441722857 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1ule _1yt4yv9d",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("bIXOn", function(module, exports) {
    module.exports = new URL("generic-error.bc45c66a.svg", import.meta.url).toString();
});
parcelRegister("itL3d", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9730b8dbc9b9bc1a$export$2e2bcd8739ae039);
    var $7VHMR;
    var $9730b8dbc9b9bc1a$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        errorHeading: {
            "id": "atlassian-navigation.common.menu.error.error-heading",
            "defaultMessage": "Something went wrong"
        },
        errorBody: {
            "id": "atlassian-navigation.common.menu.error.error-body",
            "defaultMessage": "Try refreshing or navigating to another page."
        },
        errorImg: {
            "id": "atlassian-navigation.common.menu.error.error-img",
            "defaultMessage": "An image showing that an error has occurred"
        }
    });
});
parcelRegister("6WLja", function(module, exports) {
    $parcel$export(module.exports, "MenuSkeletonFooter", ()=>$a62f60b9f9c94621$export$efa6bae06cf20a1);
    var $5uXOq = parcelRequire("5uXOq");
    var $iwwXK;
    var $6cNb0;
    const $a62f60b9f9c94621$export$efa6bae06cf20a1 = ({ testIdPrefix: testIdPrefix, numItems: numItems = 2 })=>(0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            "data-testid": (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'skeleton')
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iwwXK")).default), {
            width: "50%"
        }), numItems >= 2 && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iwwXK")).default), {
            width: "50%"
        }));
});
parcelRegister("dnhhB", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9e885b7340c687fa$export$2e2bcd8739ae039);
    var $dJl1T;
    var $4R6GH;
    var $eusud;
    var $3sQ5x;
    var $iXqE5;
    const $9e885b7340c687fa$var$PLUGINS_URL = '/rest/plugins/1.0/available';
    const $9e885b7340c687fa$var$PLUGINS_INSTALLED_BASE_URL = '/rest/plugins/1.0';
    const $9e885b7340c687fa$var$cachedResult = {};
    const $9e885b7340c687fa$export$761418bfd56d0c76 = (appKeys)=>{
        return appKeys.map((appKey)=>$9e885b7340c687fa$var$cachedResult[appKey] ? Promise.resolve($9e885b7340c687fa$var$cachedResult[appKey]) : (0, (0, parcelRequire("eusud")).performGetRequest)(`${$9e885b7340c687fa$var$PLUGINS_INSTALLED_BASE_URL}/${appKey}-key`).then(()=>{
                $9e885b7340c687fa$var$cachedResult[appKey] = {
                    key: appKey,
                    installed: true
                };
                return $9e885b7340c687fa$var$cachedResult[appKey];
            }).catch((error)=>{
                if (error.response && error.response.status === 404) {
                    $9e885b7340c687fa$var$cachedResult[appKey] = {
                        key: appKey,
                        installed: false
                    };
                    return $9e885b7340c687fa$var$cachedResult[appKey];
                }
                (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('experimental-apps-discover', `failed to load installed app details for ${appKey}`);
            }));
    };
    const $9e885b7340c687fa$export$22bc61add701495d = (appKeys)=>appKeys.map((appKey)=>$9e885b7340c687fa$var$cachedResult[appKey] ? Promise.resolve($9e885b7340c687fa$var$cachedResult[appKey]) : (0, (0, parcelRequire("eusud")).performGetRequest)(`${$9e885b7340c687fa$var$PLUGINS_URL}/${appKey}-key`).then((detail)=>{
                $9e885b7340c687fa$var$cachedResult[appKey] = detail;
                return $9e885b7340c687fa$var$cachedResult[appKey];
            }).catch(()=>(0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('experimental-apps-discover', `failed to load app details for ${appKey}`)));
    const $9e885b7340c687fa$var$handleFetch = (appKeys)=>{
        const fetchAppDetailsFn = (0, (0, parcelRequire("4R6GH")).fg)('enable_migrate_upm_available_to_plugins') ? $9e885b7340c687fa$export$761418bfd56d0c76 : $9e885b7340c687fa$export$22bc61add701495d;
        return async ({ setState: setState, getState: getState })=>{
            const { isFetching: isFetching } = getState();
            if (!isFetching) try {
                const integrations = (await Promise.all(fetchAppDetailsFn(appKeys))).filter(Boolean).reduce((apps, { key: key, installed: installed })=>({
                        ...apps,
                        [key]: installed
                    }), {});
                setState({
                    integrations: integrations,
                    isFetching: false,
                    fetchError: null,
                    hasFetchedOnce: true,
                    hasSuccessOnce: true
                });
            } catch (error) {
                setState({
                    isFetching: false,
                    fetchError: error,
                    hasFetchedOnce: true
                });
            }
        };
    };
    const $9e885b7340c687fa$var$actions = {
        fetchIntegrations: (appKeys)=>async ({ setState: setState, dispatch: dispatch })=>{
                const promise = dispatch($9e885b7340c687fa$var$handleFetch(appKeys));
                setState({
                    isFetching: true,
                    promise: promise
                });
            }
    };
    const $9e885b7340c687fa$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'fetch-integrations',
        initialState: {
            integrations: {},
            isFetching: false,
            fetchError: null,
            promise: null,
            hasFetchedOnce: false,
            hasSuccessOnce: false
        },
        actions: $9e885b7340c687fa$var$actions
    });
    const $9e885b7340c687fa$export$bb9a596c8be2a95f = (0, (0, parcelRequire("3sQ5x")).createHook)($9e885b7340c687fa$var$Store);
    var $9e885b7340c687fa$export$2e2bcd8739ae039 = $9e885b7340c687fa$export$bb9a596c8be2a95f;
});
parcelRegister("6R6Mv", function(module, exports) {
    $parcel$export(module.exports, "Content", ()=>$1325c659339264f4$export$7c6e2c02157bb7d2);
    var $5uXOq = parcelRequire("5uXOq");
    var $9UCCQ;
    var $kJH82;
    var $3bQtK;
    var $eKYL7;
    var $2EqxS;
    var $Mp6qH;
    var $fNYY1;
    const $1325c659339264f4$export$7c6e2c02157bb7d2 = ({ testIdPrefix: testIdPrefix })=>{
        const [{ items: items, fetchError: fetchError, promise: promise, hasSuccessOnce: hasSuccessOnce, isFetching: isFetching }] = (0, (0, parcelRequire("kJH82")).useAddon)();
        const [{ items: forgeItems }] = (0, (0, parcelRequire("eKYL7")).useForgeGlobalItems)();
        const onSuccess = (0, (0, parcelRequire("3bQtK")).useExperienceSuccess)({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU)
        });
        if (fetchError && !hasSuccessOnce) throw fetchError;
        if (isFetching) throw promise;
        (0, $5uXOq.useEffect)(()=>{
            if (hasSuccessOnce) onSuccess();
        }, [
            hasSuccessOnce,
            onSuccess
        ]);
        const forgeGlobalPageItemsCondition = ()=>forgeItems && forgeItems.length > 0;
        return (0, (0, parcelRequire("2EqxS")).getUsersApps)(items).length > 0 || forgeGlobalPageItemsCondition() ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            testId: testIdPrefix
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fNYY1")).Items), {
            testIdPrefix: testIdPrefix
        })) : null;
    };
});
parcelRegister("2EqxS", function(module, exports) {
    $parcel$export(module.exports, "useAtlassianPersonalSettingsLink", ()=>$58d844745b0a5554$export$8ac70acdad00754b);
    $parcel$export(module.exports, "useSwitchAccountLink", ()=>$58d844745b0a5554$export$dce6af3168fb6ced);
    $parcel$export(module.exports, "useProfileLink", ()=>$58d844745b0a5554$export$b8f25e615dd30d7b);
    $parcel$export(module.exports, "getBeforeEntity", ()=>$58d844745b0a5554$export$5b8580d0254a9349);
    $parcel$export(module.exports, "getBoardLink", ()=>$58d844745b0a5554$export$ff5c49b7852aca68);
    $parcel$export(module.exports, "getLinkFromProject", ()=>$58d844745b0a5554$export$dd9458ee44edd0c6);
    $parcel$export(module.exports, "getQueueLink", ()=>$58d844745b0a5554$export$b44363840d1b683e);
    $parcel$export(module.exports, "getIsClassicSoftwareProject", ()=>$58d844745b0a5554$export$286ea82aef1c76e9);
    $parcel$export(module.exports, "getUsersApps", ()=>$58d844745b0a5554$export$ad0fd737fbd4a083);
    var $ebyGx = parcelRequire("ebyGx");
    var $kTK0a;
    var $j0xEB;
    var $lsCQw;
    var $9v9ib;
    var $gAdgD;
    var $3e6VG;
    var $aIkD8;
    var $2gnjX;
    var $k7fxM;
    var $5mLQj;
    var $ee0rS;
    var $90yGq;
    var $lmyls;
    const $58d844745b0a5554$export$ab902790e443b0ec = ()=>`${(0, (0, parcelRequire("kTK0a")).getATLContextUrl)('id')}/login`;
    const $58d844745b0a5554$export$8ac70acdad00754b = (destinationAccountEmail)=>{
        const loginLink = $58d844745b0a5554$export$ab902790e443b0ec();
        const destinationUrl = `${(0, (0, parcelRequire("kTK0a")).getATLContextUrl)('id')}/manage-profile/profile-and-visibility`;
        return destinationAccountEmail ? `${loginLink}?${(0, ($parcel$interopDefault($ebyGx))).stringify({
            application: 'jira',
            continue: destinationUrl,
            login_hint: destinationAccountEmail,
            prompt: 'none'
        })}` : destinationUrl;
    };
    const $58d844745b0a5554$export$dce6af3168fb6ced = ()=>{
        const loginLink = $58d844745b0a5554$export$ab902790e443b0ec();
        const { href: href } = (0, (0, parcelRequire("j0xEB")).default)() || {};
        return `${loginLink}?${(0, ($parcel$interopDefault($ebyGx))).stringify({
            application: 'jira',
            continue: href,
            prompt: 'select_account'
        })}`;
    };
    const $58d844745b0a5554$export$b8f25e615dd30d7b = ()=>{
        const accountId = (0, (0, parcelRequire("90yGq")).useAccountId)();
        return `/jira/people/${accountId || ''}`;
    };
    const $58d844745b0a5554$export$5b8580d0254a9349 = (favoriteItems)=>favoriteItems && favoriteItems.length > 0 ? {
            id: String(favoriteItems[0].id),
            type: favoriteItems[0].type
        } : {
            id: undefined,
            type: undefined
        };
    const $58d844745b0a5554$export$ff5c49b7852aca68 = (item)=>{
        const { attributes: attributes, attributes: { parentType: parentType }, id: id } = item;
        const boardId = String(id);
        if (parentType === 'project') {
            const { nextgen: nextgen, projectKey: projectKey, projectType: projectType } = attributes;
            if (projectType === 'business') return {
                params: {
                    projectKey: projectKey
                },
                query: {},
                route: (0, (0, parcelRequire("9v9ib")).jiraBusinessProjectRedirectRoute)
            };
            if (projectType === 'service_desk') return {
                params: {
                    boardId: boardId,
                    projectKey: projectKey
                },
                query: {},
                route: (0, (0, parcelRequire("gAdgD")).jsmBoardRoute)
            };
            if (nextgen === 'true') return {
                params: {
                    boardId: boardId,
                    projectKey: projectKey
                },
                query: {},
                route: (0, (0, parcelRequire("2gnjX")).softwareBoardRoute)
            };
            return {
                params: {
                    boardId: boardId,
                    projectKey: projectKey
                },
                query: {},
                route: (0, (0, parcelRequire("k7fxM")).classicSoftwareBoardRoute)
            };
        }
        if (parentType === 'user') {
            const { userAccountId: accountId } = attributes;
            return {
                params: {
                    boardId: boardId,
                    accountId: accountId
                },
                query: {},
                route: (0, (0, parcelRequire("5mLQj")).userBoardRoute)
            };
        }
        return {
            params: undefined,
            query: undefined,
            route: undefined
        };
    };
    const $58d844745b0a5554$export$dd9458ee44edd0c6 = (item)=>{
        const { attributes: { projectType: projectType, boardId: boardId, nextgen: nextgen, projectKey: projectKey } } = item;
        if (projectType === 'business') return {
            params: {
                projectKey: projectKey
            },
            query: {},
            route: (0, (0, parcelRequire("9v9ib")).jiraBusinessProjectRedirectRoute)
        };
        if (boardId !== undefined) {
            if (nextgen === 'true') return {
                params: {
                    boardId: boardId,
                    projectKey: projectKey
                },
                query: {},
                route: (0, (0, parcelRequire("2gnjX")).softwareBoardRoute)
            };
            return {
                params: {
                    boardId: boardId,
                    projectKey: projectKey
                },
                query: {},
                route: (0, (0, parcelRequire("k7fxM")).classicSoftwareBoardRoute)
            };
        }
        if (projectType === 'service_desk') return {
            params: {
                projectKey: projectKey
            },
            query: {},
            route: (0, (0, parcelRequire("3e6VG")).projectServiceDeskRoute)
        };
        return {
            params: {
                projectKey: projectKey
            },
            query: {},
            route: (0, (0, parcelRequire("lsCQw")).browseProjectRedirectRoute)
        };
    };
    const $58d844745b0a5554$export$b44363840d1b683e = (item)=>{
        const { attributes: { projectKey: projectKey, queuesCategory: queuesCategory }, id: id } = item;
        return {
            params: {
                projectKey: projectKey,
                ...queuesCategory != null && queuesCategory !== '' ? {
                    practiceType: (0, (0, parcelRequire("ee0rS")).toShortFormItsmPractice)(queuesCategory)
                } : {},
                queueId: String(id)
            },
            route: queuesCategory != null && queuesCategory !== '' ? (0, (0, parcelRequire("aIkD8")).customPracticeQueueRoute) : (0, (0, parcelRequire("aIkD8")).customQueueRoute)
        };
    };
    const $58d844745b0a5554$export$286ea82aef1c76e9 = (attributes)=>attributes.parentType === 'user' || attributes.parentType === 'project' && attributes.projectType === 'software' && attributes.nextgen === 'false';
    const $58d844745b0a5554$export$ad0fd737fbd4a083 = (items)=>items.filter((item)=>item.id !== (0, (0, parcelRequire("lmyls")).ADDON_ID).DISCOVER);
});
parcelRegister("j0xEB", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9b1198aeec732ce1$export$2e2bcd8739ae039);
    var $9b1198aeec732ce1$export$2e2bcd8739ae039 = ()=>{
        if (typeof window !== 'undefined') return window.location;
        return undefined;
    };
});
parcelRegister("lsCQw", function(module, exports) {
    $parcel$export(module.exports, "browseProjectRedirectRoute", ()=>$8f408b6bf8b1e91d$export$f9cac37753487f39);
    var $65Ltd;
    const $8f408b6bf8b1e91d$export$f9cac37753487f39 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_BROWSE_PROJECT_REDIRECT),
        path: '/browse/:projectKey([^-/]+)',
        exact: true
    };
});
parcelRegister("9v9ib", function(module, exports) {
    $parcel$export(module.exports, "jiraBusinessProjectRedirectRoute", ()=>$21f2029296bcfd0c$export$73b60789ceb8e1eb);
    $parcel$export(module.exports, "jiraBusinessOverviewRedirectRoute", ()=>$21f2029296bcfd0c$export$5ab0e07528c31349);
    var $65Ltd;
    const $21f2029296bcfd0c$export$73b60789ceb8e1eb = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_CLASSIC_BUSINESS_REDIRECT),
        path: '/jira/core/projects/:projectKey',
        exact: true
    };
    const $21f2029296bcfd0c$export$5ab0e07528c31349 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_REDIRECT),
        path: '/jira/core/overviews/:overviewId',
        exact: true
    };
});
parcelRegister("gAdgD", function(module, exports) {
    $parcel$export(module.exports, "jsmBoardRoute", ()=>$5c5a2553f427d19f$export$a192088aab559fa1);
    var $65Ltd;
    const $5c5a2553f427d19f$export$a192088aab559fa1 = {
        path: '/jira/servicedesk/projects/:projectKey/boards/:boardId',
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_BOARDS),
        exact: true
    };
});
parcelRegister("3e6VG", function(module, exports) {
    $parcel$export(module.exports, "projectServiceDeskRoute", ()=>$b4bfd79e01394a60$export$1cfc858068dd8718);
    var $65Ltd;
    const $b4bfd79e01394a60$export$1cfc858068dd8718 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_REDIRECT),
        path: '/jira/servicedesk/projects/:projectKey',
        exact: true
    };
});
parcelRegister("aIkD8", function(module, exports) {
    $parcel$export(module.exports, "customQueueRoute", ()=>$4c49d5efc3cf0837$export$e1fac82ca0b0d637);
    $parcel$export(module.exports, "customPracticeQueueRoute", ()=>$4c49d5efc3cf0837$export$59d0bd42aa07faad);
    var $65Ltd;
    const $4c49d5efc3cf0837$export$e1fac82ca0b0d637 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_QUEUES_CUSTOM_AND_ISSUE),
        path: '/jira/servicedesk/projects/:projectKey/queues/custom/:queueId/:issueKey?',
        query: [
            'oldIssueView?'
        ],
        exact: true
    };
    const $4c49d5efc3cf0837$export$59d0bd42aa07faad = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_PRACTICE_CUSTOM_AND_ISSUE),
        path: '/jira/servicedesk/projects/:projectKey/section/:practiceType/custom/:queueId/:issueKey?',
        query: [
            'oldIssueView?'
        ],
        exact: true
    };
});
parcelRegister("2gnjX", function(module, exports) {
    $parcel$export(module.exports, "softwareBoardRoute", ()=>$3dfae82cef4e3065$export$9ae2c0a566d4e0f6);
    $parcel$export(module.exports, "softwareBoardEmbedRoute", ()=>$3dfae82cef4e3065$export$f55626b64e3fcb4f);
    var $65Ltd;
    const $3dfae82cef4e3065$export$9ae2c0a566d4e0f6 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SOFTWARE_BOARDS),
        exact: true,
        path: '/jira/software/projects/:projectKey/boards/:boardId'
    };
    const $3dfae82cef4e3065$export$f55626b64e3fcb4f = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SOFTWARE_BOARDS_EMBED),
        exact: true,
        path: '/jira/software/projects/:projectKey/boards/:boardId/embed'
    };
});
parcelRegister("k7fxM", function(module, exports) {
    $parcel$export(module.exports, "classicSoftwareBoardRoute", ()=>$b2c149f7138f811f$export$131d51ca54849e97);
    $parcel$export(module.exports, "classicSoftwareBoardEmbedRoute", ()=>$b2c149f7138f811f$export$122afcef7dfd252);
    var $65Ltd;
    const $b2c149f7138f811f$export$131d51ca54849e97 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_BOARD),
        path: '/jira/software/c/projects/:projectKey/boards/:boardId'
    };
    const $b2c149f7138f811f$export$122afcef7dfd252 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_BOARD_EMBED),
        path: '/jira/software/c/projects/:projectKey/boards/:boardId/embed'
    };
});
parcelRegister("5mLQj", function(module, exports) {
    $parcel$export(module.exports, "userBoardRoute", ()=>$94b3bd76047e314a$export$57a2a0fe739f16);
    var $65Ltd;
    const $94b3bd76047e314a$export$57a2a0fe739f16 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_USER_BOARD),
        path: '/jira/people/:accountId/boards/:boardId'
    };
});
parcelRegister("ee0rS", function(module, exports) {
    $parcel$export(module.exports, "toShortFormItsmPractice", ()=>$3a8490f36704bba6$export$db58a56259f511a2);
    var $oCMSG;
    const $3a8490f36704bba6$var$longToShortConversionMap = {
        [(0, (0, parcelRequire("oCMSG")).CHANGES)]: (0, (0, parcelRequire("oCMSG")).SHORT_FORM_CHANGES),
        [(0, (0, parcelRequire("oCMSG")).INCIDENTS)]: (0, (0, parcelRequire("oCMSG")).SHORT_FORM_INCIDENTS),
        [(0, (0, parcelRequire("oCMSG")).PROBLEMS)]: (0, (0, parcelRequire("oCMSG")).SHORT_FORM_PROBLEMS),
        [(0, (0, parcelRequire("oCMSG")).SERVICE_REQUEST)]: (0, (0, parcelRequire("oCMSG")).SHORT_FORM_SERVICE_REQUEST),
        [(0, (0, parcelRequire("oCMSG")).POST_INCIDENT_REVIEWS)]: (0, (0, parcelRequire("oCMSG")).SHORT_FORM_POST_INCIDENT_REVIEWS),
        [(0, (0, parcelRequire("oCMSG")).DEVELOPER_ESCALATION)]: (0, (0, parcelRequire("oCMSG")).SHORT_FORM_DEVELOPER_ESCALATION)
    };
    function $3a8490f36704bba6$var$isLongForm(workCategoryKey) {
        return !!workCategoryKey && workCategoryKey in $3a8490f36704bba6$var$longToShortConversionMap;
    }
    const $3a8490f36704bba6$export$db58a56259f511a2 = (workCategoryKey)=>{
        if ($3a8490f36704bba6$var$isLongForm(workCategoryKey)) return $3a8490f36704bba6$var$longToShortConversionMap[workCategoryKey];
        return null;
    };
});
parcelRegister("fNYY1", function(module, exports) {
    $parcel$export(module.exports, "Items", ()=>$46529721d69bebc5$export$b4399d239416b67f);
    var $5uXOq = parcelRequire("5uXOq");
    var $apKqz;
    var $4mNf3;
    var $kJH82;
    var $jjUR3;
    var $hrlU6;
    var $cSD7y;
    var $eKYL7;
    var $1rTGO;
    var $8kVg1;
    var $3wOYX;
    var $k2DRb;
    var $fl3Yy;
    var $k4j3t;
    var $koVbs;
    var $lmyls;
    var $6cNb0;
    var $iXVV6;
    var $5oFIy;
    var $dAfqg;
    var $d4B4Y;
    var $7ilb8;
    var $dcZVA;
    var $atKwH;
    const $46529721d69bebc5$var$ContentItem = ({ ecosystemItem: ecosystemItem, index: index, testIdPrefix: testIdPrefix })=>{
        const { addNew: addNew } = (0, (0, parcelRequire("jjUR3")).useConnectItemClickHistory)();
        const { id: id, url: url, avatarUrl: avatarUrl, title: title } = ecosystemItem.item;
        const analytics = {
            actionSubjectId: 'appsNavigationMenuItem',
            menuItemId: id,
            menuItemType: 'apps',
            position: index,
            appName: ecosystemItem.item.title,
            appType: ecosystemItem.type
        };
        const webItemRef = (0, $5uXOq.useRef)(null);
        const isDialog = ecosystemItem.type === 'connect' && ecosystemItem.item.className && (0, (0, parcelRequire("hrlU6")).isConnectDialogWebItem)(ecosystemItem.item.className);
        const onClick = (0, $5uXOq.useCallback)(()=>{
            if (ecosystemItem.type === 'connect') {
                if (isDialog) (0, (0, parcelRequire("hrlU6")).withConnectHost)((connectHost)=>{
                    if (webItemRef.current && ecosystemItem.item.className) connectHost.triggerWebItem(webItemRef.current, ecosystemItem.item.className, url);
                });
                else if (url != null) addNew(url);
            } else (0, (0, parcelRequire("8kVg1")).forgeModulesConcurrentLoadTime)(id).start({
                startTime: performance.now()
            });
        }, [
            addNew,
            ecosystemItem.item,
            ecosystemItem.type,
            id,
            isDialog,
            url
        ]);
        const icon = avatarUrl != null && avatarUrl.trim().length > 0 && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("k4j3t")).IconCustom), {
            url: avatarUrl
        }) || (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("apKqz"))))), {
            primaryColor: (0, (0, parcelRequire("dcZVA")).PRIMARY_COLOR),
            label: title
        });
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, `item-${index}`);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
            key: id
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            ref: webItemRef,
            testId: testId,
            href: isDialog ? undefined : url,
            onClick: onClick,
            analytics: analytics,
            iconBefore: icon
        }, title));
    };
    const $46529721d69bebc5$var$ContentItemsNew = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { key: projectIdOrKey } = (0, (0, parcelRequire("dAfqg")).useProjectContext)();
        const [{ items: connectItems }, { setContext: setContext }] = (0, (0, parcelRequire("kJH82")).useAddon)();
        const [{ items: forgeStoreItems }] = (0, (0, parcelRequire("eKYL7")).useForgeGlobalItems)();
        (0, $5uXOq.useEffect)(()=>{
            setContext({
                projectIdOrKey: projectIdOrKey
            });
        }, [
            projectIdOrKey,
            setContext
        ]);
        const sortedItems = (0, $5uXOq.useMemo)(()=>[
                ...(forgeStoreItems || []).map((item)=>({
                        type: 'forge',
                        item: item
                    })),
                ...connectItems.filter((item)=>item.id !== (0, (0, parcelRequire("lmyls")).ADDON_ID).DISCOVER).map((item)=>({
                        type: 'connect',
                        item: item
                    }))
            ].sort((a, b)=>a.item.title.localeCompare(b.item.title)), [
            connectItems,
            forgeStoreItems
        ]);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'items');
        const shouldTriggerForgeScreenEvent = forgeStoreItems && forgeStoreItems.length > 0;
        return (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), null, formatMessage((0, (0, parcelRequire("atKwH")).default).yourAppsText)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuList), null, sortedItems.map((ecosystemItem, idx)=>{
            const { id: id } = ecosystemItem.item;
            return (0, ($parcel$interopDefault($5uXOq))).createElement($46529721d69bebc5$var$ContentItem, {
                key: id,
                ecosystemItem: ecosystemItem,
                index: idx,
                testIdPrefix: testId
            });
        })), shouldTriggerForgeScreenEvent && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeScreenEvent), {
            attributes: {
                moduleType: (0, (0, parcelRequire("k2DRb")).GLOBAL_PAGE_MODULE)
            }
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: "appsMenuYourAppsSection",
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), null)));
    };
    const $46529721d69bebc5$var$ContentItemsOld = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { key: projectIdOrKey } = (0, (0, parcelRequire("dAfqg")).useProjectContext)();
        const [{ items: connectItems }, { setContext: setContext }] = (0, (0, parcelRequire("kJH82")).useAddon)();
        const [{ items: forgeStoreItems }] = (0, (0, parcelRequire("eKYL7")).useForgeGlobalItems)();
        const { addNew: addNew } = (0, (0, parcelRequire("jjUR3")).useConnectItemClickHistory)();
        (0, $5uXOq.useEffect)(()=>{
            setContext({
                projectIdOrKey: projectIdOrKey
            });
        }, [
            projectIdOrKey,
            setContext
        ]);
        const sortedItems = (0, $5uXOq.useMemo)(()=>[
                ...(forgeStoreItems || []).map((item)=>({
                        type: 'forge',
                        item: item
                    })),
                ...connectItems.filter((item)=>item.id !== (0, (0, parcelRequire("lmyls")).ADDON_ID).DISCOVER).map((item)=>({
                        type: 'connect',
                        item: item
                    }))
            ].sort((a, b)=>a.item.title.localeCompare(b.item.title)), [
            connectItems,
            forgeStoreItems
        ]);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'items');
        const getMenuItemProps = ({ item: item, type: type })=>type === 'connect' ? {
                onClick: ()=>item.url != null && addNew(item.url),
                className: item.className
            } : {
                iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fl3Yy")).EnvironmentLozenge), {
                    environmentType: item.environmentType
                }),
                onClick: ()=>{
                    (0, (0, parcelRequire("8kVg1")).forgeModulesConcurrentLoadTime)(item.id).start({
                        startTime: performance.now()
                    });
                }
            };
        const renderMenuItems = ()=>sortedItems.map((ecosystemItem, idx)=>{
                const { type: type, item: item } = ecosystemItem;
                return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
                    key: item.id
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
                    testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, `item-${idx}`),
                    href: item.url,
                    analytics: {
                        actionSubjectId: 'appsNavigationMenuItem',
                        menuItemId: item.id,
                        menuItemType: 'apps',
                        position: idx,
                        appName: item.title,
                        appType: type
                    },
                    iconBefore: item.avatarUrl != null && item.avatarUrl.trim().length > 0 && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("k4j3t")).IconCustom), {
                        url: item.avatarUrl
                    }) || (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("apKqz"))))), {
                        primaryColor: (0, (0, parcelRequire("dcZVA")).PRIMARY_COLOR),
                        label: item.title
                    }),
                    ...getMenuItemProps(ecosystemItem)
                }, item.title));
            });
        const shouldTriggerForgeScreenEvent = forgeStoreItems && forgeStoreItems.length > 0;
        return (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), null, formatMessage((0, (0, parcelRequire("atKwH")).default).yourAppsText)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuList), null, renderMenuItems()), shouldTriggerForgeScreenEvent && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeScreenEvent), {
            attributes: {
                moduleType: (0, (0, parcelRequire("k2DRb")).GLOBAL_PAGE_MODULE)
            }
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: "appsMenuYourAppsSection",
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), null)));
    };
    const $46529721d69bebc5$var$ContentItems = (0, (0, parcelRequire("cSD7y")).componentWithFG)('blu-4941-connect-webitem-imperative-api', $46529721d69bebc5$var$ContentItemsNew, $46529721d69bebc5$var$ContentItemsOld);
    const $46529721d69bebc5$export$b4399d239416b67f = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3wOYX")).TriggerAnalyticsWrapper), null, (0, ($parcel$interopDefault($5uXOq))).createElement($46529721d69bebc5$var$ContentItems, props));
});
parcelRegister("jjUR3", function(module, exports) {
    $parcel$export(module.exports, "useConnectItemClickHistory", ()=>$c499ec49965fe6fe$export$c251e3fda1cce4a3);
    var $5uXOq = parcelRequire("5uXOq");
    const $c499ec49965fe6fe$var$history = {
        unique: []
    };
    const $c499ec49965fe6fe$export$c251e3fda1cce4a3 = ()=>{
        const addNew = (0, $5uXOq.useCallback)((historyItem)=>{
            if (window.location.href.endsWith(historyItem) === false) $c499ec49965fe6fe$var$history.unique.push(historyItem);
        }, []);
        return (0, $5uXOq.useMemo)(()=>({
                history: $c499ec49965fe6fe$var$history,
                addNew: addNew
            }), [
            addNew
        ]);
    };
    const $c499ec49965fe6fe$export$ef04cd58bebb0345 = ()=>{
        $c499ec49965fe6fe$var$history.unique.length = 0;
    };
});
parcelRegister("hrlU6", function(module, exports) {
    $parcel$export(module.exports, "withConnectHost", ()=>$9316db67541b8a94$export$970e2411dd8601c8);
    $parcel$export(module.exports, "getConnectHost", ()=>$9316db67541b8a94$export$186d423c00af146c);
    $parcel$export(module.exports, "isConnectDialogWebItem", ()=>$9316db67541b8a94$export$920fb4b46d4f2eec);
    var $dJZWD;
    const $9316db67541b8a94$export$970e2411dd8601c8 = async (action)=>{
        const connectHost = await (0, (0, parcelRequire("dJZWD")).setupConnectJs)();
        action(connectHost);
    };
    const $9316db67541b8a94$export$186d423c00af146c = ()=>window.connectHost;
    const $9316db67541b8a94$export$920fb4b46d4f2eec = (classes)=>classes.includes('ap-dialog') || classes.includes('ap-inline-dialog');
});
parcelRegister("dJZWD", function(module, exports) {
    $parcel$export(module.exports, "setupConnectJs", ()=>$762ba9fa524ea8e6$export$891414b605e5f038);
    var $6BZYo;
    var $cQZXC;
    var $4R6GH;
    var $dH9DT;
    const $762ba9fa524ea8e6$var$installConnectJs = async ()=>{
        if ((0, (0, parcelRequire("4R6GH")).fg)('bifrost-gateway-tmp-no-wrm-via-ssr')) await (0, (0, parcelRequire("cQZXC")).waitForHeritage)();
        await (0, (0, parcelRequire("dH9DT")).waitForJQuery)();
        const loader = await (parcelRequire("gRUhY"));
        await loader.performSideEffects();
        return window.connectHost;
    };
    let $762ba9fa524ea8e6$var$installConnectJsPromise = null;
    const $762ba9fa524ea8e6$export$891414b605e5f038 = async ()=>{
        if (window.__SPA__) {
            if ($762ba9fa524ea8e6$var$installConnectJsPromise === null) $762ba9fa524ea8e6$var$installConnectJsPromise = $762ba9fa524ea8e6$var$installConnectJs();
            return $762ba9fa524ea8e6$var$installConnectJsPromise;
        }
        await (0, (0, parcelRequire("6BZYo")).loadBridge)({
            name: 'ac/create',
            wrmKeys: [
                'wrc!com.atlassian.plugins.atlassian-connect-plugin:jira-atlassian-connect-resources-v5',
                'wrc!com.atlassian.jira.jira-atlaskit-plugin:sidebar'
            ]
        });
        return window.connectHost;
    };
});
parcelRegister("6BZYo", function(module, exports) {
    $parcel$export(module.exports, "loadBridge", ()=>$ce95ee3fd66207c7$export$df8409da353bde5a);
    var $fqWWz;
    var $3GASE;
    var $ig88W;
    var $7T84i;
    var $7T84i;
    var $9B86Q;
    var $cQZXC;
    var $8JNtu;
    var $5Ik5Y;
    const $ce95ee3fd66207c7$export$df8409da353bde5a = ({ name: name, wrmKeys: wrmKeys, tentatives: tentatives = (0, (0, parcelRequire("fqWWz")).MAX_RETRIES), timeout: timeout = (0, (0, parcelRequire("fqWWz")).DEFAULT_TIMEOUT), shouldLogError: shouldLogError = (0, (0, parcelRequire("fqWWz")).SHOULD_LOG_ERROR) })=>{
        if (wrmKeys != null) return (0, (0, parcelRequire("7T84i")).loadBridgeStrict)({
            name: name,
            wrmKeys: [].concat(wrmKeys),
            shouldLogError: shouldLogError
        });
        if (tentatives <= 0) return Promise.reject(new Error('Unable to load bridge, zero tentatives never works'));
        if (tentatives <= 1) return (0, (0, parcelRequire("3GASE")).loadBridgeBail)({
            name: name,
            shouldLogError: shouldLogError
        });
        return (0, (0, parcelRequire("ig88W")).loadBridgeRace)({
            name: name,
            shouldLogError: shouldLogError,
            timeout: (tentatives - 1) * timeout
        });
    };
});
parcelRegister("dH9DT", function(module, exports) {
    $parcel$export(module.exports, "getMeta", ()=>$a78c52ec7ed5d025$export$c47913ca32a5c6f7);
    $parcel$export(module.exports, "getJQuery", ()=>$a78c52ec7ed5d025$export$7232a6dc780f9887);
    $parcel$export(module.exports, "getGlobalAP", ()=>$a78c52ec7ed5d025$export$fbc82125a6bd8012);
    $parcel$export(module.exports, "isObject", ()=>$a78c52ec7ed5d025$export$a6cdc56e425d0d0a);
    $parcel$export(module.exports, "escapeSelector", ()=>$a78c52ec7ed5d025$export$c8c0ea01c118c2eb);
    $parcel$export(module.exports, "waitForJQuery", ()=>$a78c52ec7ed5d025$export$97214de9c546981c);
    $parcel$export(module.exports, "ErrorDebugAddonKeys", ()=>$a78c52ec7ed5d025$export$d690da9bc8146677);
    var $jcw0u;
    const $a78c52ec7ed5d025$export$c47913ca32a5c6f7 = (key)=>{
        const metaTag = document.querySelector(`meta[name='ajs-${key}']`);
        return metaTag === null ? undefined : metaTag.getAttribute('content') || undefined;
    };
    const $a78c52ec7ed5d025$export$7232a6dc780f9887 = ()=>AJS.$;
    const $a78c52ec7ed5d025$export$fbc82125a6bd8012 = ()=>window._AP;
    const $a78c52ec7ed5d025$export$a6cdc56e425d0d0a = (obj)=>typeof obj === 'function' || typeof obj === 'object' && !!obj;
    const $a78c52ec7ed5d025$export$c8c0ea01c118c2eb = (s)=>{
        if (!s) throw new Error('No selector to escape');
        return s.replace(/[!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~]/g, '\\$&');
    };
    const $a78c52ec7ed5d025$var$FIVE_MINUTES = 300000;
    const $a78c52ec7ed5d025$export$97214de9c546981c = ()=>new Promise((resolve, reject)=>{
            const interval = setInterval(()=>{
                if (window.AJS?.$) {
                    clearInterval(interval);
                    resolve();
                }
            }, 10);
            setTimeout(()=>{
                clearInterval(interval);
                (0, (0, parcelRequire("jcw0u")).default)({
                    meta: {
                        id: 'connect-jquery-timeout',
                        packageName: 'jiraConnect'
                    },
                    error: new Error('Timeout when waiting for jQuery to be available on the page'),
                    skipSentry: true
                });
                reject();
            }, $a78c52ec7ed5d025$var$FIVE_MINUTES);
        });
    const $a78c52ec7ed5d025$export$7315c38dc71bd41f = 'com.atlassian.servicedesk.embedded-dev';
    const $a78c52ec7ed5d025$export$cb954c21735b28e4 = 'com.atlassian.servicedesk.embedded';
    const $a78c52ec7ed5d025$export$d690da9bc8146677 = [
        $a78c52ec7ed5d025$export$cb954c21735b28e4,
        $a78c52ec7ed5d025$export$7315c38dc71bd41f
    ];
});
parcelRegister("gRUhY", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("aVR4N").then(()=>parcelRequire('8vJyT'));
});
parcelRegister("1rTGO", function(module, exports) {
    $parcel$export(module.exports, "ForgeScreenEvent", ()=>$abbdb580491d9d09$export$92d758d83665314b);
    $parcel$export(module.exports, "ForgeOperationaFailedEvent", ()=>$abbdb580491d9d09$export$a238c3c13f29c134);
    $parcel$export(module.exports, "ForgeErrorBoundary", ()=>$abbdb580491d9d09$export$ac7e7464cefab440);
    $parcel$export(module.exports, "ForgeAnalyticsWrapper", ()=>$abbdb580491d9d09$export$ad2a5d9127152fb8);
    $parcel$export(module.exports, "withMetrics", ()=>$abbdb580491d9d09$export$14e56d7f5d3f917c);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $k2DRb;
    var $3mOWa;
    var $eOGyv;
    var $iXVV6;
    var $8zOmE;
    var $5oFIy;
    var $isuse;
    var $hBuqR;
    var $6MtPV;
    var $dd7Uh;
    var $2RhPB;
    var $9BrHy;
    var $8kVg1;
    const $abbdb580491d9d09$export$92d758d83665314b = ({ attributes: attributes })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), {
            attributes: (0, (0, parcelRequire("6MtPV")).createAttributes)(attributes)
        });
    const $abbdb580491d9d09$export$a238c3c13f29c134 = ({ eventId: eventId, attributes: attributes })=>{
        (0, (0, parcelRequire("dd7Uh")).fireOperationalFailedEvent)({
            eventId: eventId,
            attributes: attributes
        });
        return null;
    };
    const $abbdb580491d9d09$export$4c46624a93517c10 = ({ eventId: eventId, action: action, attributes: attributes })=>{
        const hasForgeAccess = (0, (0, parcelRequire("eOGyv")).useHasForgeAccess)();
        const analyticsAttributes = (0, (0, parcelRequire("6MtPV")).createAttributes)({
            ...attributes,
            hasForgeAccess: hasForgeAccess
        });
        const fireEvent = (0, $5uXOq.useCallback)((analyticsEvent)=>{
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(analyticsEvent, (0, (0, parcelRequire("9BrHy")).getEventActionName)(eventId, action), analyticsAttributes);
        }, [
            eventId,
            action,
            analyticsAttributes
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("isuse")).MountEvent), {
            onMount: fireEvent
        });
    };
    const $abbdb580491d9d09$export$f461e628d5e73fa0 = ({ children: children, eventId: eventId })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a),
            sourceName: `${(0, (0, parcelRequire("hBuqR")).PACKAGE_NAME_GASV3)}.${eventId}`
        }, children);
    const $abbdb580491d9d09$export$ac7e7464cefab440 = ({ children: children, eventId: eventId, attributes: attributes, onError: onError, errorFallback: errorFallback, resetCaughtError: resetCaughtError })=>{
        const fallback = (0, $5uXOq.useMemo)(()=>errorFallback || 'unmount', [
            errorFallback
        ]);
        const getAttributes = (0, $5uXOq.useCallback)((error)=>(0, (0, parcelRequire("6MtPV")).createAttributes)({
                ...attributes,
                error: error
            }), [
            attributes
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: eventId,
            packageName: (0, (0, parcelRequire("hBuqR")).PACKAGE_NAME_GASV3),
            teamName: "smite",
            fallback: fallback,
            attributes: getAttributes,
            onError: onError,
            resetCaughtError: resetCaughtError
        }, children);
    };
    const $abbdb580491d9d09$export$ad2a5d9127152fb8 = ({ children: children, module: module, entryPoint: entryPoint, attributes: attributes, moduleType: moduleType = (0, (0, parcelRequire("hBuqR")).MODULE_TYPE_EXTENSION), errorFallback: errorFallback = 'unmount', shouldFireInitializedEvent: shouldFireInitializedEvent = false, resetCaughtError: resetCaughtError })=>{
        const eventId = (0, (0, parcelRequire("9BrHy")).getEventId)({
            module: module,
            moduleType: moduleType,
            entryPoint: entryPoint
        });
        const analyticsAttributes = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("6MtPV")).createAttributes)(attributes), [
            attributes
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($abbdb580491d9d09$export$f461e628d5e73fa0, {
            eventId: eventId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($abbdb580491d9d09$export$ac7e7464cefab440, {
            eventId: eventId,
            attributes: analyticsAttributes,
            errorFallback: errorFallback,
            resetCaughtError: resetCaughtError
        }, shouldFireInitializedEvent && (0, ($parcel$interopDefault($5uXOq))).createElement($abbdb580491d9d09$export$4c46624a93517c10, {
            eventId: eventId,
            action: "initialized",
            attributes: analyticsAttributes
        }), children));
    };
    function $abbdb580491d9d09$var$isExtensionTimeMeasuredFromTriggerClick(extensionModule, entryPoint, attributes) {
        if ((extensionModule === (0, (0, parcelRequire("k2DRb")).CUSTOM_FIELD_MODULE) || extensionModule === (0, (0, parcelRequire("k2DRb")).CUSTOM_FIELD_TYPE_MODULE)) && (entryPoint !== 'edit' || attributes.renderContext === 'issue-create')) return false;
        if (extensionModule === (0, (0, parcelRequire("k2DRb")).ISSUE_PANEL_MODULE) && !attributes?.isNewToIssue) return false;
        return [
            (0, (0, parcelRequire("k2DRb")).GLOBAL_PAGE_MODULE),
            (0, (0, parcelRequire("k2DRb")).ISSUE_ACTION_MODULE),
            (0, (0, parcelRequire("k2DRb")).ISSUE_ACTIVITY_MODULE),
            (0, (0, parcelRequire("k2DRb")).ISSUE_GLANCE_MODULE),
            (0, (0, parcelRequire("k2DRb")).ISSUE_CONTEXT_MODULE),
            (0, (0, parcelRequire("k2DRb")).ISSUE_PANEL_MODULE),
            (0, (0, parcelRequire("k2DRb")).CUSTOM_FIELD_MODULE),
            (0, (0, parcelRequire("k2DRb")).CUSTOM_FIELD_TYPE_MODULE),
            (0, (0, parcelRequire("k2DRb")).PROJECT_PAGE_MODULE),
            (0, (0, parcelRequire("k2DRb")).ADMIN_PAGE_MODULE),
            (0, (0, parcelRequire("k2DRb")).PROJECT_SETTINGS_PAGE_MODULE)
        ].includes(extensionModule);
    }
    const $abbdb580491d9d09$export$14e56d7f5d3f917c = (Component)=>(props)=>{
            const startTime = (0, $5uXOq.useRef)(performance.now());
            const isCustomUI = (0, (0, parcelRequire("3mOWa")).isCustomUIExtension)(props.extension, props.entryPoint);
            const [isExtensionRendered, setExtensionRendered] = (0, $5uXOq.useState)(false);
            const { module: module, entryPoint: entryPoint, analyticsAttributes: analyticsAttributes, localId: localId = '', onInitialRender: onInitialRender, onLoad: onLoad, extension: extension } = props;
            const extensionId = props.extension?.id;
            const eventId = (0, (0, parcelRequire("9BrHy")).getEventId)({
                module: module,
                entryPoint: entryPoint
            });
            const browserMetricsEventId = (0, (0, parcelRequire("9BrHy")).getBrowserMetricsEventId)({
                module: module,
                entryPoint: entryPoint,
                forgeUiType: (0, (0, parcelRequire("3mOWa")).getForgeUIType)(extension, entryPoint)
            });
            const attributes = (0, $5uXOq.useMemo)(()=>({
                    ...analyticsAttributes,
                    ...(0, (0, parcelRequire("2RhPB")).getAnalyticsAttributesFromExtension)(extension, entryPoint)
                }), [
                analyticsAttributes,
                entryPoint,
                extension.id,
                isCustomUI
            ]);
            (0, $5uXOq.useEffect)(()=>{
                if (!$abbdb580491d9d09$var$isExtensionTimeMeasuredFromTriggerClick(extension.type, entryPoint ?? 'view', attributes) && extensionId) (0, (0, parcelRequire("8kVg1")).forgeModulesConcurrentLoadTime)(extensionId).start({
                    startTime: startTime.current
                });
            }, []);
            const onError = (0, $5uXOq.useCallback)(()=>{
                extensionId && (0, (0, parcelRequire("8kVg1")).forgeModulesConcurrentLoadTime)(extensionId).cancel();
            }, [
                extensionId
            ]);
            const onInitialRenderUiKitWithMetrics = (0, $5uXOq.useCallback)((payload)=>{
                onInitialRender && onInitialRender(payload);
                if (payload?.error == null) {
                    const stopTimeDeduction = payload?.appTime || 0;
                    setExtensionRendered(true);
                    extensionId && (0, (0, parcelRequire("8kVg1")).forgeModulesConcurrentLoadTime)(extensionId).stop({
                        stopTime: performance.now() - stopTimeDeduction,
                        customData: {
                            eventId: browserMetricsEventId
                        }
                    });
                } else {
                    onError();
                    (0, (0, parcelRequire("dd7Uh")).fireOperationalFailedEvent)({
                        eventId: eventId,
                        attributes: {
                            error: new Error(payload.error),
                            ...attributes
                        }
                    });
                }
            }, [
                onInitialRender,
                extensionId,
                browserMetricsEventId,
                onError,
                eventId,
                attributes
            ]);
            const onLoadCustomUiWithMetrics = (0, $5uXOq.useCallback)(()=>{
                onLoad && onLoad();
                setExtensionRendered(true);
                extensionId && (0, (0, parcelRequire("8kVg1")).forgeModulesConcurrentLoadTime)(extensionId).stop({
                    stopTime: performance.now(),
                    customData: {
                        eventId: browserMetricsEventId
                    }
                });
            }, [
                analyticsAttributes,
                extensionId,
                localId,
                onLoad
            ]);
            return (0, ($parcel$interopDefault($5uXOq))).createElement($abbdb580491d9d09$export$f461e628d5e73fa0, {
                eventId: eventId
            }, (0, ($parcel$interopDefault($5uXOq))).createElement(Component, {
                ...props,
                ...isCustomUI ? {
                    onLoad: onLoadCustomUiWithMetrics
                } : {
                    onInitialRender: onInitialRenderUiKitWithMetrics
                }
            }), isExtensionRendered && (0, ($parcel$interopDefault($5uXOq))).createElement($abbdb580491d9d09$export$92d758d83665314b, {
                attributes: attributes
            }));
        };
});
parcelRegister("3mOWa", function(module, exports) {
    $parcel$export(module.exports, "getForgeUIType", ()=>$e79bbc527e600bd1$export$aed4d8a86badbf61);
    $parcel$export(module.exports, "isCustomUIExtension", ()=>$e79bbc527e600bd1$export$edb00cfe4962b026);
    $parcel$export(module.exports, "isNativeUiExtension", ()=>$e79bbc527e600bd1$export$2ecba06e617fea0a);
    $parcel$export(module.exports, "overrideExtension", ()=>$e79bbc527e600bd1$export$42b85bc9b386c977);
    var $gccSB = parcelRequire("gccSB");
    const $e79bbc527e600bd1$export$aed4d8a86badbf61 = (extension, entryPoint = '')=>{
        const { render: render, resource: resource } = extension.properties;
        if (entryPoint && extension.properties[entryPoint]) {
            const { resource: entryPointResource, render: entryPointRender } = extension.properties[entryPoint];
            if (entryPointResource == null) return 'ui-kit';
            if (entryPointRender === 'native') return 'native-ui';
            return 'custom-ui';
        }
        if (resource == null) return 'ui-kit';
        if (render === 'native') return 'native-ui';
        return 'custom-ui';
    };
    const $e79bbc527e600bd1$export$edb00cfe4962b026 = (extension, entryPoint = '')=>$e79bbc527e600bd1$export$aed4d8a86badbf61(extension, entryPoint) === 'custom-ui';
    const $e79bbc527e600bd1$export$2ecba06e617fea0a = (extension, entryPoint = '')=>$e79bbc527e600bd1$export$aed4d8a86badbf61(extension, entryPoint) === 'native-ui';
    const $e79bbc527e600bd1$export$42b85bc9b386c977 = (extension, propsToOverride)=>{
        if (extension == null) return extension;
        if ((0, ($parcel$interopDefault($gccSB)))(propsToOverride)) return extension;
        const { resource: resource, requiresUserConsent: requiresUserConsent } = propsToOverride;
        const extensionCopy = {
            ...extension,
            properties: {
                ...extension.properties
            },
            requiresUserConsent: requiresUserConsent
        };
        if (resource != null) extensionCopy.properties.resource = resource;
        return extensionCopy;
    };
});
parcelRegister("eOGyv", function(module, exports) {
    $parcel$export(module.exports, "useHasForgeAccess", ()=>$5bef20cd08fcb4ca$export$fd7f94d3d2bac7f2);
    var $cZpsT;
    const $5bef20cd08fcb4ca$export$fd7f94d3d2bac7f2 = ()=>{
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        return !isAnonymous;
    };
});
parcelRegister("isuse", function(module, exports) {
    $parcel$export(module.exports, "MountEvent", ()=>$1df0619a43ad69d9$export$2c6963927dbc68f1);
    var $5uXOq = parcelRequire("5uXOq");
    var $cb25w;
    class $1df0619a43ad69d9$var$MountEventComponent extends (0, $5uXOq.Component) {
        componentDidMount() {
            const analyticsEvent = this.props.createAnalyticsEvent({
                action: 'mounted'
            });
            if (this.props.onMount) this.props.onMount(analyticsEvent);
        }
        render() {
            return null;
        }
    }
    const $1df0619a43ad69d9$export$2c6963927dbc68f1 = (0, (0, parcelRequire("cb25w")).default)()($1df0619a43ad69d9$var$MountEventComponent);
});
parcelRegister("dd7Uh", function(module, exports) {
    $parcel$export(module.exports, "fireOperationalFailedEvent", ()=>$7d829a81d29f9e72$export$add44f896fb31ba3);
    var $hBuqR;
    var $8uOa6;
    const $7d829a81d29f9e72$export$add44f896fb31ba3 = ({ eventId: eventId, attributes: attributes })=>{
        (0, (0, parcelRequire("8uOa6")).fireOperationalEvent)((0, (0, parcelRequire("hBuqR")).OPERATIONAL_ACTIONS).FAILED, {
            id: eventId,
            attributes: attributes
        });
    };
});
parcelRegister("2RhPB", function(module, exports) {
    $parcel$export(module.exports, "getAnalyticsAttributesFromExtension", ()=>$3fba0b567cec1fe0$export$5d3b06ab08af139e);
    var $3mOWa;
    var $e0AC8;
    const $3fba0b567cec1fe0$export$5d3b06ab08af139e = (extension, entryPoint)=>{
        const { appId: appId, envId: envId } = (0, (0, parcelRequire("e0AC8")).parseExtensionId)(extension.id);
        return {
            appId: appId,
            envId: envId,
            forgeType: (0, (0, parcelRequire("3mOWa")).getForgeUIType)(extension, entryPoint),
            envType: extension?.environmentType
        };
    };
});
parcelRegister("9BrHy", function(module, exports) {
    $parcel$export(module.exports, "getEventId", ()=>$10bc2a9e37d5a5bb$export$5639f6a927500d9);
    $parcel$export(module.exports, "getBrowserMetricsEventId", ()=>$10bc2a9e37d5a5bb$export$25830fc7d037f0f2);
    $parcel$export(module.exports, "getEventActionName", ()=>$10bc2a9e37d5a5bb$export$644ccb9a67b9a9c1);
    var $k2DRb;
    var $6yg2S;
    var $hBuqR;
    const $10bc2a9e37d5a5bb$var$capitalizeFirstLetter = (str)=>str != null && str.charAt(0).toUpperCase() + str.slice(1) || '';
    const $10bc2a9e37d5a5bb$var$prepareModuleMode = (entryPoint)=>`${$10bc2a9e37d5a5bb$var$capitalizeFirstLetter(entryPoint)}`;
    const $10bc2a9e37d5a5bb$export$3e50bc81d8ed2e3f = (moduleName)=>moduleName.replace(/^jira:/, '');
    const $10bc2a9e37d5a5bb$var$prepareModuleName = (moduleName)=>(0, (0, parcelRequire("k2DRb")).SUPPORTED_MODULES).includes(moduleName) ? $10bc2a9e37d5a5bb$export$3e50bc81d8ed2e3f(moduleName) : 'unknown';
    const $10bc2a9e37d5a5bb$export$5639f6a927500d9 = ({ module: module, moduleType: moduleType = (0, (0, parcelRequire("hBuqR")).MODULE_TYPE_EXTENSION), entryPoint: entryPoint })=>{
        const eventNamePostfix = moduleType === 'trigger' ? 'Trigger' : '';
        return (0, (0, parcelRequire("6yg2S")).toEventId)(`${$10bc2a9e37d5a5bb$var$prepareModuleName(module)}${$10bc2a9e37d5a5bb$var$prepareModuleMode(entryPoint)}${eventNamePostfix}`);
    };
    const $10bc2a9e37d5a5bb$export$25830fc7d037f0f2 = ({ module: module, entryPoint: entryPoint, forgeUiType: forgeUiType })=>(0, (0, parcelRequire("6yg2S")).toEventId)(`${$10bc2a9e37d5a5bb$var$prepareModuleName(module)}${$10bc2a9e37d5a5bb$var$prepareModuleMode(entryPoint)}-${forgeUiType}`);
    const $10bc2a9e37d5a5bb$export$644ccb9a67b9a9c1 = (eventId, action)=>`${0, (0, parcelRequire("hBuqR")).PACKAGE_NAME_GASV3}.${eventId} ${action}`;
});
parcelRegister("3wOYX", function(module, exports) {
    $parcel$export(module.exports, "ViewAnalyticsWrapper", ()=>$5b5e8901ec23bf19$export$c794eb4124961145);
    $parcel$export(module.exports, "TriggerAnalyticsWrapper", ()=>$5b5e8901ec23bf19$export$e1a79e3d472c925a);
    var $5uXOq = parcelRequire("5uXOq");
    var $k2DRb;
    var $1rTGO;
    var $hBuqR;
    const $5b5e8901ec23bf19$export$c794eb4124961145 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeAnalyticsWrapper), {
            module: (0, (0, parcelRequire("k2DRb")).GLOBAL_PAGE_MODULE),
            shouldFireInitializedEvent: true,
            ...props
        });
    const $5b5e8901ec23bf19$export$e1a79e3d472c925a = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeAnalyticsWrapper), {
            module: (0, (0, parcelRequire("k2DRb")).GLOBAL_PAGE_MODULE),
            moduleType: (0, (0, parcelRequire("hBuqR")).MODULE_TYPE_TRIGGER),
            ...props
        });
});
parcelRegister("fl3Yy", function(module, exports) {
    $parcel$export(module.exports, "EnvironmentLozenge", ()=>$730173a4987fa3e4$export$278f8e01799d0418);
    $parcel$export(module.exports, "nameWithCustomEnvironment", ()=>$730173a4987fa3e4$export$f9338c21ba7c7334);
    var $5uXOq = parcelRequire("5uXOq");
    var $lj8SI;
    var $k2DRb;
    var $ousew;
    var $8Je4V;
    const $730173a4987fa3e4$export$50589a87e4fb3dae = (environmentType)=>{
        switch(environmentType){
            case 0, (0, parcelRequire("k2DRb")).DEVELOPMENT_ENVIRONMENT:
                return (0, (0, parcelRequire("8Je4V")).messages).developmentEnvironmentLozenge;
            case 0, (0, parcelRequire("k2DRb")).STAGING_ENVIRONMENT:
                return (0, (0, parcelRequire("8Je4V")).messages).stagingEnvironmentLozenge;
            default:
                return null;
        }
    };
    const $730173a4987fa3e4$export$278f8e01799d0418 = (props)=>{
        const { environmentType: environmentType } = props;
        const labelKey = $730173a4987fa3e4$export$50589a87e4fb3dae(environmentType);
        return labelKey ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lj8SI")).default), {
            testId: "forge-ui-extension-title.ui.environment-lozenge.lozenge",
            appearance: environmentType === (0, (0, parcelRequire("k2DRb")).DEVELOPMENT_ENVIRONMENT) ? 'default' : 'moved'
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), labelKey)) : null;
    };
    const $730173a4987fa3e4$export$f9338c21ba7c7334 = (name, environmentType, environmentKey)=>{
        if (environmentType === (0, (0, parcelRequire("k2DRb")).DEVELOPMENT_ENVIRONMENT) && environmentKey && environmentKey !== (0, (0, parcelRequire("k2DRb")).DEFAULT_ENVIRONMENT_KEY)) return `${name} - ${environmentKey}`;
        return name;
    };
});
parcelRegister("8Je4V", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$838ac71f7259b5ef$export$defe85febe8b728c);
    var $7VHMR;
    const $838ac71f7259b5ef$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        developmentEnvironmentLozenge: {
            "id": "forge-ui-extension-title.environment-lozenge.development-environment-lozenge",
            "defaultMessage": "DEV"
        },
        stagingEnvironmentLozenge: {
            "id": "forge-ui-extension-title.environment-lozenge.staging-environment-lozenge",
            "defaultMessage": "STG"
        }
    });
});
parcelRegister("k4j3t", function(module, exports) {
    $parcel$export(module.exports, "IconCustom", ()=>$0744fb0e35ced980$export$5b6504ec6adf0047);
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $8CUq3;
    var $ljMUk;
    const $0744fb0e35ced980$var$colorMatrixValues = '0.08, 0, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 0,0.2, 0, 0, 0, 0, 0, 1, 0';
    const $0744fb0e35ced980$export$5b6504ec6adf0047 = ({ url: url, shouldChangeFillColor: shouldChangeFillColor = true, ariaLabelledBy: ariaLabelledBy })=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $0744fb0e35ced980$var$styles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            viewBox: "0 0 20 20"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("defs", null, (0, ($parcel$interopDefault($5uXOq))).createElement("filter", {
            id: "changeFillColor"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            type: "matrix",
            values: $0744fb0e35ced980$var$colorMatrixValues
        }))), (0, ($parcel$interopDefault($5uXOq))).createElement("image", {
            xmlnsXlink: "http://www.w3.org/1999/xlink",
            xlinkHref: url,
            width: "20",
            height: "20",
            x: "0",
            y: "0",
            filter: shouldChangeFillColor && (0, (0, parcelRequire("ljMUk")).checkForSvg)(url) ? 'url(#changeFillColor)' : '',
            "aria-labelledby": ariaLabelledBy
        })));
    };
    const $0744fb0e35ced980$var$styles = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'inline-block',
        margin: 'space.025',
        height: "var(--ds-space-250, 20px)",
        width: "var(--ds-space-250, 20px)"
    });
});
parcelRegister("ljMUk", function(module, exports) {
    $parcel$export(module.exports, "checkForSvg", ()=>$c203648fb17df2d9$export$9a369805b0fabb72);
    const $c203648fb17df2d9$export$9a369805b0fabb72 = (url)=>{
        const fileType = url.split(/#|\?/)[0].split('.').pop();
        return fileType ? fileType.trim() === 'svg' : false;
    };
});
parcelRegister("d4B4Y", function(module, exports) {
    $parcel$export(module.exports, "MenuItem", ()=>$ac79b65b740c9d18$export$2ce376c2cc3355c8);
    var $5uXOq = parcelRequire("5uXOq");
    var $kJpBP;
    var $jngLf;
    var $9YfZr;
    var $6oGXD;
    const $ac79b65b740c9d18$export$2ce376c2cc3355c8 = (0, $5uXOq.forwardRef)(({ analytics: analytics, href: href, onClick: onClick, params: params, query: query, replace: replace, target: target, to: to, type: type, ...rest }, ref)=>{
        const onClickHandler = (0, (0, parcelRequire("6oGXD")).useOnClick)({
            onClick: onClick,
            analytics: analytics
        });
        if (to != null && __SPA__) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9YfZr")).RouteLinkItem), {
            ref: ref,
            onClick: onClickHandler,
            params: params,
            query: query,
            replace: replace,
            target: target,
            to: to,
            type: type,
            ...rest
        });
        return href ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jngLf")).LinkItem), {
            ref: ref,
            href: href,
            target: target,
            onClick: onClickHandler,
            ...rest
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kJpBP")).default), {
            ref: ref,
            onClick: onClickHandler,
            ...rest
        });
    });
});
parcelRegister("6oGXD", function(module, exports) {
    $parcel$export(module.exports, "useOnClick", ()=>$a8a7ed1bfe9b49e0$export$a81c45382e7253ac);
    var $2bzBV;
    var $2Ui4E;
    var $8zOmE;
    var $2NMNM;
    const $a8a7ed1bfe9b49e0$export$a81c45382e7253ac = ({ analytics: analytics, fireUIAnalytics: fireUIAnalytics = (0, (0, parcelRequire("8zOmE")).fireUIAnalytics), onClick: onClick, useAnalyticsEvents: useAnalyticsEvents = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents), useOverflowStatus: useOverflowStatus = (0, (0, parcelRequire("2bzBV")).useOverflowStatus), useTopMenus: useTopMenus = (0, (0, parcelRequire("2Ui4E")).useTopMenus) } = {})=>{
        const [, { off: closeMenu }] = useTopMenus();
        const { isVisible: isVisible, closeOverflowMenu: closeOverflowMenu } = useOverflowStatus();
        const { createAnalyticsEvent: createAnalyticsEvent } = useAnalyticsEvents();
        const { actionSubjectId: actionSubjectId, ...analyticsRest } = analytics;
        return (event)=>{
            const analyticsEvent = createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'navigationMenuItem'
            });
            fireUIAnalytics(analyticsEvent, actionSubjectId, {
                ...analyticsRest,
                isInMore: !isVisible
            });
            onClick && onClick(event);
            if (event.ctrlKey || event.metaKey) return;
            closeMenu();
            closeOverflowMenu();
        };
    };
});
parcelRegister("7ilb8", function(module, exports) {
    $parcel$export(module.exports, "MenuList", ()=>$44f127900c12f92a$export$d4c4e98c5044dc8);
    $parcel$export(module.exports, "MenuListItem", ()=>$44f127900c12f92a$export$6c2f3257abc4825b);
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $8CUq3;
    const $44f127900c12f92a$export$d4c4e98c5044dc8 = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "ul",
            xcss: $44f127900c12f92a$var$removeDefaultListStyles
        }, children);
    const $44f127900c12f92a$export$6c2f3257abc4825b = ({ children: children, ariaLabel: ariaLabel })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "li",
            xcss: $44f127900c12f92a$var$removeDefaultListStyles,
            "aria-label": ariaLabel
        }, children);
    const $44f127900c12f92a$var$removeDefaultListStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        padding: 'space.0',
        margin: 'space.0'
    });
});
parcelRegister("dcZVA", function(module, exports) {
    $parcel$export(module.exports, "PRIMARY_COLOR", ()=>$e2d87dc8f5027df6$export$22791ddad13a5e06);
    const $e2d87dc8f5027df6$export$22791ddad13a5e06 = `var(--ds-icon, ${(0, $829f609a65d26a98$exports).N500})`;
});
parcelRegister("atKwH", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$95fc98384de2e573$export$2e2bcd8739ae039);
    var $7VHMR;
    var $95fc98384de2e573$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        yourAppsText: {
            "id": "atlassian-navigation.addons.menu.personalised-apps-experiment-enriched-content.personalised-apps-experiment-content.explore-marketplace-text",
            "defaultMessage": "Your apps"
        }
    });
});
parcelRegister("giMK6", function(module, exports) {
    $parcel$export(module.exports, "SLACK", ()=>$442bdaa98c6f9898$export$f1d97c73367642d8);
    $parcel$export(module.exports, "DEFAULT_TEAM_RECOMMENDED_APPS", ()=>$442bdaa98c6f9898$export$32b6e4b8e076046);
    $parcel$export(module.exports, "IT_SUPPORT_TEAM_RECOMMENDED_APPS", ()=>$442bdaa98c6f9898$export$9d9f02da5e5767a2);
    $parcel$export(module.exports, "SOFTWARE_DEVELOPMENT_TEAM_RECOMMENDED_APPS", ()=>$442bdaa98c6f9898$export$d80cc7a4899acce7);
    $parcel$export(module.exports, "INTEGRATIONS", ()=>$442bdaa98c6f9898$export$d49fe9e32eb9b9c3);
    $parcel$export(module.exports, "RECOMMENDATION_TYPE", ()=>$442bdaa98c6f9898$export$a94606af1cdee48d);
    var $luWVI = parcelRequire("luWVI");
    var $8xPu1 = parcelRequire("8xPu1");
    var $5ydQc = parcelRequire("5ydQc");
    var $1wuud = parcelRequire("1wuud");
    var $iL25A = parcelRequire("iL25A");
    var $bvRdf = parcelRequire("bvRdf");
    var $25S90 = parcelRequire("25S90");
    var $cGyJw = parcelRequire("cGyJw");
    var $5joay = parcelRequire("5joay");
    var $aBr31 = parcelRequire("aBr31");
    var $9XLNF = parcelRequire("9XLNF");
    var $5i38V = parcelRequire("5i38V");
    var $jxLFw = parcelRequire("jxLFw");
    var $euO4p = parcelRequire("euO4p");
    var $jt4Nh = parcelRequire("jt4Nh");
    const $442bdaa98c6f9898$export$d0c29e989f831684 = 'com.conelike.amfj';
    const $442bdaa98c6f9898$export$5e14c7e50550cd77 = 'nl.avisi.jira.plugins.relations-for-jira';
    const $442bdaa98c6f9898$export$b41c48f703d0e7eb = 'eu.softwareplant.bigpicture';
    const $442bdaa98c6f9898$export$afaf7eb5c500a159 = 'circleci.jira';
    const $442bdaa98c6f9898$export$16b14b2256d05dae = 'com.eazybi.jira.plugins.eazybi-jira';
    const $442bdaa98c6f9898$export$2d77d9815f2033f5 = 'com.kretar.jira.plugin.user-story-map';
    const $442bdaa98c6f9898$export$8326c171e7f444a5 = 'com.riadalabs.jira.plugins.insight-discovery';
    const $442bdaa98c6f9898$export$91fa8f2d7e3174cc = 'com.figma.jira-add-on';
    const $442bdaa98c6f9898$export$fa619866d542058f = 'msteams-jira';
    const $442bdaa98c6f9898$export$40d79efb385eb328 = 'com.alphaserve.powerbi-connector-jira';
    const $442bdaa98c6f9898$export$f1d97c73367642d8 = 'com.atlassian.jira.slack';
    const $442bdaa98c6f9898$export$3820a933e9f984fc = 'com.tda.timesheet.report';
    const $442bdaa98c6f9898$export$658d35071b0831ac = 'zendesk_for_jira';
    const $442bdaa98c6f9898$export$6513ebd8de81755b = 'com.thed.zephyr.je';
    const $442bdaa98c6f9898$export$b7f71f0aad4dd9c9 = 'com.xpandit.plugins.xray';
    const $442bdaa98c6f9898$export$32b6e4b8e076046 = [
        $442bdaa98c6f9898$export$f1d97c73367642d8,
        $442bdaa98c6f9898$export$fa619866d542058f,
        $442bdaa98c6f9898$export$16b14b2256d05dae,
        $442bdaa98c6f9898$export$40d79efb385eb328,
        $442bdaa98c6f9898$export$b41c48f703d0e7eb,
        $442bdaa98c6f9898$export$2d77d9815f2033f5,
        $442bdaa98c6f9898$export$5e14c7e50550cd77
    ];
    const $442bdaa98c6f9898$export$9d9f02da5e5767a2 = [
        $442bdaa98c6f9898$export$f1d97c73367642d8,
        $442bdaa98c6f9898$export$fa619866d542058f,
        $442bdaa98c6f9898$export$658d35071b0831ac,
        $442bdaa98c6f9898$export$d0c29e989f831684,
        $442bdaa98c6f9898$export$8326c171e7f444a5
    ];
    const $442bdaa98c6f9898$export$d80cc7a4899acce7 = [
        $442bdaa98c6f9898$export$f1d97c73367642d8,
        $442bdaa98c6f9898$export$fa619866d542058f,
        $442bdaa98c6f9898$export$afaf7eb5c500a159,
        $442bdaa98c6f9898$export$3820a933e9f984fc,
        $442bdaa98c6f9898$export$6513ebd8de81755b,
        $442bdaa98c6f9898$export$b7f71f0aad4dd9c9,
        $442bdaa98c6f9898$export$91fa8f2d7e3174cc
    ];
    const $442bdaa98c6f9898$export$3bc9b5daa48b1848 = (key)=>(query)=>`/jira/marketplace/discover/app/${key}${query ? `?${query}` : ''}`;
    const $442bdaa98c6f9898$export$d49fe9e32eb9b9c3 = [
        {
            key: $442bdaa98c6f9898$export$f1d97c73367642d8,
            getUrl: $442bdaa98c6f9898$export$3bc9b5daa48b1848($442bdaa98c6f9898$export$f1d97c73367642d8),
            icon: (0, ($parcel$interopDefault($9XLNF))),
            label: 'Slack',
            actionSubjectId: 'slackForAtlassianApp'
        },
        {
            key: $442bdaa98c6f9898$export$fa619866d542058f,
            getUrl: $442bdaa98c6f9898$export$3bc9b5daa48b1848($442bdaa98c6f9898$export$fa619866d542058f),
            icon: (0, ($parcel$interopDefault($5joay))),
            label: 'Microsoft Teams',
            actionSubjectId: 'microsoftTeams'
        },
        {
            key: $442bdaa98c6f9898$export$91fa8f2d7e3174cc,
            getUrl: $442bdaa98c6f9898$export$3bc9b5daa48b1848($442bdaa98c6f9898$export$91fa8f2d7e3174cc),
            icon: (0, ($parcel$interopDefault($25S90))),
            label: 'Figma',
            actionSubjectId: 'figmaApp',
            categoryMessageKey: 'categoryDesign'
        },
        {
            key: $442bdaa98c6f9898$export$afaf7eb5c500a159,
            getUrl: $442bdaa98c6f9898$export$3bc9b5daa48b1848($442bdaa98c6f9898$export$afaf7eb5c500a159),
            icon: (0, ($parcel$interopDefault($1wuud))),
            label: 'Circle CI',
            actionSubjectId: 'circleCI',
            categoryMessageKey: 'categoryCicd'
        },
        {
            key: $442bdaa98c6f9898$export$b7f71f0aad4dd9c9,
            getUrl: $442bdaa98c6f9898$export$3bc9b5daa48b1848($442bdaa98c6f9898$export$b7f71f0aad4dd9c9),
            icon: (0, ($parcel$interopDefault($jxLFw))),
            label: 'Xray',
            actionSubjectId: 'xray',
            categoryMessageKey: 'categoryTesting'
        },
        {
            key: $442bdaa98c6f9898$export$6513ebd8de81755b,
            getUrl: $442bdaa98c6f9898$export$3bc9b5daa48b1848($442bdaa98c6f9898$export$6513ebd8de81755b),
            icon: (0, ($parcel$interopDefault($jt4Nh))),
            label: 'Zephyr Squad',
            actionSubjectId: 'zephyrSquad',
            categoryMessageKey: 'categoryTesting'
        },
        {
            key: $442bdaa98c6f9898$export$3820a933e9f984fc,
            getUrl: $442bdaa98c6f9898$export$3bc9b5daa48b1848($442bdaa98c6f9898$export$3820a933e9f984fc),
            icon: (0, ($parcel$interopDefault($5i38V))),
            label: 'Timesheet tracking',
            actionSubjectId: 'timesheetTracking'
        },
        {
            key: $442bdaa98c6f9898$export$658d35071b0831ac,
            getUrl: $442bdaa98c6f9898$export$3bc9b5daa48b1848($442bdaa98c6f9898$export$658d35071b0831ac),
            icon: (0, ($parcel$interopDefault($euO4p))),
            label: 'Zendesk',
            actionSubjectId: 'zendesk',
            categoryMessageKey: 'categorySupport'
        },
        {
            key: $442bdaa98c6f9898$export$d0c29e989f831684,
            getUrl: $442bdaa98c6f9898$export$3bc9b5daa48b1848($442bdaa98c6f9898$export$d0c29e989f831684),
            icon: (0, ($parcel$interopDefault($luWVI))),
            label: 'Asset Management',
            actionSubjectId: 'assetManagement',
            categoryMessageKey: 'categoryAssetTracking'
        },
        {
            key: $442bdaa98c6f9898$export$8326c171e7f444a5,
            getUrl: $442bdaa98c6f9898$export$3bc9b5daa48b1848($442bdaa98c6f9898$export$8326c171e7f444a5),
            icon: (0, ($parcel$interopDefault($cGyJw))),
            label: 'Insight Discovery',
            actionSubjectId: 'insightDiscovery',
            categoryMessageKey: 'categoryAssetTracking'
        },
        {
            key: $442bdaa98c6f9898$export$16b14b2256d05dae,
            getUrl: $442bdaa98c6f9898$export$3bc9b5daa48b1848($442bdaa98c6f9898$export$16b14b2256d05dae),
            icon: (0, ($parcel$interopDefault($bvRdf))),
            label: 'eazyBI',
            actionSubjectId: 'eazyBI',
            categoryMessageKey: 'categoryAnalytics'
        },
        {
            key: $442bdaa98c6f9898$export$40d79efb385eb328,
            getUrl: $442bdaa98c6f9898$export$3bc9b5daa48b1848($442bdaa98c6f9898$export$40d79efb385eb328),
            icon: (0, ($parcel$interopDefault($aBr31))),
            label: 'Power BI',
            actionSubjectId: 'powerBI',
            categoryMessageKey: 'categoryAnalytics'
        },
        {
            key: $442bdaa98c6f9898$export$b41c48f703d0e7eb,
            getUrl: $442bdaa98c6f9898$export$3bc9b5daa48b1848($442bdaa98c6f9898$export$b41c48f703d0e7eb),
            icon: (0, ($parcel$interopDefault($5ydQc))),
            label: 'BigPicture',
            actionSubjectId: 'bigPicture',
            categoryMessageKey: 'categoryProjectManagement'
        },
        {
            key: $442bdaa98c6f9898$export$2d77d9815f2033f5,
            getUrl: $442bdaa98c6f9898$export$3bc9b5daa48b1848($442bdaa98c6f9898$export$2d77d9815f2033f5),
            icon: (0, ($parcel$interopDefault($iL25A))),
            label: 'Easy Agile TeamRhythm',
            actionSubjectId: 'easyAgile'
        },
        {
            key: $442bdaa98c6f9898$export$5e14c7e50550cd77,
            getUrl: $442bdaa98c6f9898$export$3bc9b5daa48b1848($442bdaa98c6f9898$export$5e14c7e50550cd77),
            icon: (0, ($parcel$interopDefault($8xPu1))),
            label: 'Atlas CRM',
            actionSubjectId: 'atlasCMR',
            categoryMessageKey: 'categoryProjectManagement'
        }
    ];
    const $442bdaa98c6f9898$export$a94606af1cdee48d = {
        Personalised: 'personalised'
    };
});
parcelRegister("luWVI", function(module, exports) {
    module.exports = new URL("assetManagement.2437db0d.png", import.meta.url).toString();
});
parcelRegister("8xPu1", function(module, exports) {
    module.exports = new URL("atlasCMR.1b2080b0.png", import.meta.url).toString();
});
parcelRegister("5ydQc", function(module, exports) {
    module.exports = new URL("bigPicture.bdf23130.png", import.meta.url).toString();
});
parcelRegister("1wuud", function(module, exports) {
    module.exports = new URL("circleCI.a4fabf69.png", import.meta.url).toString();
});
parcelRegister("iL25A", function(module, exports) {
    module.exports = new URL("easyAgile.6a075018.png", import.meta.url).toString();
});
parcelRegister("bvRdf", function(module, exports) {
    module.exports = new URL("eazyBI.224ed97d.png", import.meta.url).toString();
});
parcelRegister("25S90", function(module, exports) {
    module.exports = new URL("figma.4fcb09e3.png", import.meta.url).toString();
});
parcelRegister("cGyJw", function(module, exports) {
    module.exports = new URL("insightDiscovery.9e6dd8c2.png", import.meta.url).toString();
});
parcelRegister("5joay", function(module, exports) {
    module.exports = new URL("microsoftTeams.9706d9b4.png", import.meta.url).toString();
});
parcelRegister("aBr31", function(module, exports) {
    module.exports = new URL("powerBI.6fbcc628.png", import.meta.url).toString();
});
parcelRegister("9XLNF", function(module, exports) {
    module.exports = new URL("slack.b2145b58.png", import.meta.url).toString();
});
parcelRegister("5i38V", function(module, exports) {
    module.exports = new URL("timesheet.e35a9455.png", import.meta.url).toString();
});
parcelRegister("jxLFw", function(module, exports) {
    module.exports = new URL("xray.4a45f47c.png", import.meta.url).toString();
});
parcelRegister("euO4p", function(module, exports) {
    module.exports = new URL("zendesk.57b918fc.png", import.meta.url).toString();
});
parcelRegister("jt4Nh", function(module, exports) {
    module.exports = new URL("zephyrSquad.8de8b15e.png", import.meta.url).toString();
});
parcelRegister("9JuaB", function(module, exports) {
    $parcel$export(module.exports, "DiscoverSection", ()=>$6980bb7212c0d91b$export$9fd357037d956606);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $2bzBV;
    var $4mNf3;
    var $3Z96c;
    var $9UCCQ;
    var $kJH82;
    var $koVbs;
    var $2Ui4E;
    var $2EqxS;
    var $5oFIy;
    var $8zOmE;
    var $2NMNM;
    var $cNd4s;
    var $7ilb8;
    var $dnhhB;
    var $giMK6;
    const $6980bb7212c0d91b$var$IntegrationLink = ({ url: url, label: label, icon: icon, actionSubjectId: actionSubjectId, onClick: onClick, recommendationType: recommendationType = (0, (0, parcelRequire("giMK6")).RECOMMENDATION_TYPE).Personalised })=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const [, { off: closeMenu }] = (0, (0, parcelRequire("2Ui4E")).useTopMenus)();
        const { closeOverflowMenu: closeOverflowMenu } = (0, (0, parcelRequire("2bzBV")).useOverflowStatus)();
        const onLinkClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'navigationMenuItem clicked', actionSubjectId);
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'navigationMenuItem clicked', `${recommendationType}AppRecommendedItem`, {
                appName: actionSubjectId
            });
            if (onClick) onClick();
            closeMenu();
            closeOverflowMenu();
        }, [
            actionSubjectId,
            closeMenu,
            closeOverflowMenu,
            createAnalyticsEvent,
            onClick,
            recommendationType
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3Z96c")).default), {
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement("img", {
                src: icon,
                width: "24",
                height: "24",
                alt: `${actionSubjectId} logo`
            }),
            href: url,
            target: "_blank",
            onClick: onLinkClick
        }, label);
    };
    const $6980bb7212c0d91b$var$suspensePromise = new Promise(()=>{});
    const $6980bb7212c0d91b$export$9fd357037d956606 = ({ apps: apps = [], isLoadingApps: isLoadingApps, title: title, description: description, recommendationType: recommendationType, setIsAppsMenuFinishedRendering: setIsAppsMenuFinishedRendering, isFedrampEnvironment: isFedrampEnvironment })=>{
        const isSiteAdmin = (0, (0, parcelRequire("cNd4s")).useIsSiteAdmin)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [{ items: items }] = (0, (0, parcelRequire("kJH82")).useAddon)();
        const [{ fetchError: fetchIntegrationsError }] = (0, (0, parcelRequire("dnhhB")).default)();
        (0, $5uXOq.useEffect)(()=>{
            if (!isLoadingApps) setIsAppsMenuFinishedRendering(true);
        }, [
            isLoadingApps,
            setIsAppsMenuFinishedRendering
        ]);
        if (isFedrampEnvironment) return null;
        if (fetchIntegrationsError) throw fetchIntegrationsError;
        if (isLoadingApps) throw $6980bb7212c0d91b$var$suspensePromise;
        return apps.length ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            hasSeparator: (0, (0, parcelRequire("2EqxS")).getUsersApps)(items).length > 0
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), null, formatMessage(title)), (0, ($parcel$interopDefault($5uXOq))).createElement($6980bb7212c0d91b$var$TextWrapper, null, formatMessage(description)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuList), null, apps.map((props)=>{
            const { url: url } = props;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
                key: url,
                ariaLabel: formatMessage(title)
            }, (0, ($parcel$interopDefault($5uXOq))).createElement($6980bb7212c0d91b$var$IntegrationLink, {
                ...props,
                recommendationType: recommendationType
            }));
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), {
            attributes: {
                availableProducts: apps.map(({ actionSubjectId: actionSubjectId })=>actionSubjectId),
                recommendationType: recommendationType,
                isSiteAdmin: isSiteAdmin
            }
        })) : null;
    };
    const $6980bb7212c0d91b$var$TextWrapper = (0, $5uXOq.forwardRef)(({ as: C = "small", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_syaz1wmz _1yt467l7 _19pkidpf _2hwxidpf _otyridpf _18u0idpf _1e0c1ule _p12f1178",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("2pyzy", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$53d29d1dadad89a9$export$2e2bcd8739ae039);
    var $7VHMR;
    var $53d29d1dadad89a9$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        popularApps: {
            "id": "atlassian-navigation.addons.menu.discover-section.popular-apps",
            "defaultMessage": "Popular apps"
        },
        personalisedAppsTitle: {
            "id": "atlassian-navigation.addons.menu.personalised-apps-experiment-enriched-discover.personalised-apps-experiment-discover-section.recommended-apps-title",
            "defaultMessage": "Recommended for your team"
        },
        personalisedAppsText: {
            "id": "atlassian-navigation.addons.menu.personalised-apps-experiment-enriched-discover.personalised-apps-experiment-discover-section.recommended-apps-text",
            "defaultMessage": "Ship faster with marketplace apps that integrate your team\u2019s tools with Jira."
        },
        categoryCicd: {
            "id": "atlassian-navigation.addons.menu.discover-section.category-cicd",
            "defaultMessage": "CI/CD"
        },
        categoryDesign: {
            "id": "atlassian-navigation.addons.menu.discover-section.category-design",
            "defaultMessage": "Design"
        },
        categoryTesting: {
            "id": "atlassian-navigation.addons.menu.discover-section.category-testing",
            "defaultMessage": "Testing"
        },
        categoryAssetTracking: {
            "id": "atlassian-navigation.addons.menu.discover-section.category-asset-tracking",
            "defaultMessage": "Asset Tracking"
        },
        categoryAnalytics: {
            "id": "atlassian-navigation.addons.menu.discover-section.category-analytics",
            "defaultMessage": "Analytics"
        },
        categoryProjectManagement: {
            "id": "atlassian-navigation.addons.menu.discover-section.category-project-management",
            "defaultMessage": "Project Management"
        },
        categorySupport: {
            "id": "atlassian-navigation.addons.menu.discover-section.category-support",
            "defaultMessage": "Support"
        }
    });
});
parcelRegister("jANwc", function(module, exports) {
    $parcel$export(module.exports, "getTeamAlignedAppList", ()=>$e04e21ebbf142c5f$export$5dbb471c868520b6);
    var $2nA8w;
    var $giMK6;
    const $e04e21ebbf142c5f$var$filterRecommendedIntegrations = (applicableList)=>(0, (0, parcelRequire("giMK6")).INTEGRATIONS).filter((int)=>applicableList.includes(int.key));
    const $e04e21ebbf142c5f$export$5dbb471c868520b6 = (teamType)=>{
        switch(teamType){
            case (0, (0, parcelRequire("2nA8w")).PERSONALISED_APPS_TEAM_TYPES).softwareDevelopment:
                return $e04e21ebbf142c5f$var$filterRecommendedIntegrations((0, (0, parcelRequire("giMK6")).SOFTWARE_DEVELOPMENT_TEAM_RECOMMENDED_APPS));
            case (0, (0, parcelRequire("2nA8w")).PERSONALISED_APPS_TEAM_TYPES).itSupport:
                return $e04e21ebbf142c5f$var$filterRecommendedIntegrations((0, (0, parcelRequire("giMK6")).IT_SUPPORT_TEAM_RECOMMENDED_APPS));
            default:
                return $e04e21ebbf142c5f$var$filterRecommendedIntegrations((0, (0, parcelRequire("giMK6")).DEFAULT_TEAM_RECOMMENDED_APPS));
        }
    };
});
parcelRegister("lduwc", function(module, exports) {
    $parcel$export(module.exports, "Footer", ()=>$1489798fc3202517$export$a06f1c675e846f6f);
    var $5uXOq = parcelRequire("5uXOq");
    var $lj8SI;
    var $kJH82;
    var $co1wz;
    var $4R6GH;
    var $koVbs;
    var $lmyls;
    var $6cNb0;
    var $6awYZ;
    var $iXVV6;
    var $5oFIy;
    var $9Y7t8;
    var $4zecE;
    var $9OXo1;
    var $d4B4Y;
    var $D9lkq;
    const $1489798fc3202517$var$emceeAppRequestsParams = {
        page: 'app-requests'
    };
    const $1489798fc3202517$var$emceeAppPromotionsParams = {
        page: 'promotions'
    };
    const $1489798fc3202517$export$a06f1c675e846f6f = ({ onUpdateIsFooterVisible: onUpdateIsFooterVisible, testIdPrefix: testIdPrefix, isFedrampEnvironment: isFedrampEnvironment })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const tenantContext = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const { isAdmin: isAdmin, isSiteAdmin: isSiteAdmin } = tenantContext;
        const [{ promise: promise, hasFetchedOnce: hasFetchedOnce, items: items }] = (0, (0, parcelRequire("kJH82")).useAddon)();
        if (isFedrampEnvironment) return null;
        if (promise && !hasFetchedOnce) throw promise;
        const { url: findAppsUrl } = items.find((item)=>item.id === (0, (0, parcelRequire("lmyls")).ADDON_ID).DISCOVER) || {};
        let position = 0;
        if (!findAppsUrl && !isAdmin && !isSiteAdmin) onUpdateIsFooterVisible && onUpdateIsFooterVisible(false);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'items');
        return (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'appsNavigationMenuItem',
                isAdmin: Boolean(isSiteAdmin),
                menuItemId: 'newApps',
                menuItemType: 'action',
                position: position++
            },
            href: "/jira/marketplace/discover?source=apps-menu",
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-explore-new-apps')
        }, formatMessage((0, (0, parcelRequire("D9lkq")).default).exploreApps)), (isAdmin || isSiteAdmin) && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'appsNavigationMenuItem',
                isAdmin: isSiteAdmin,
                menuItemId: 'manageApps',
                menuItemType: 'action',
                position: position++
            },
            href: "/plugins/servlet/upm",
            iconAfter: (0, (0, parcelRequire("4R6GH")).fg)('enable-app-update-lozenge') && tenantContext?.appUpdatesAvailable && tenantContext.appUpdatesAvailable > 0 ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lj8SI")).default), {
                appearance: "new"
            }, `${tenantContext.appUpdatesAvailable > 9 ? '9+' : tenantContext.appUpdatesAvailable} ${formatMessage((0, (0, parcelRequire("D9lkq")).default).appUpdates, {
                apps: tenantContext.appUpdatesAvailable
            })}`) : null,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-manage-apps'),
            to: (0, (0, parcelRequire("9Y7t8")).manageAppsRoute)
        }, formatMessage((0, (0, parcelRequire("D9lkq")).default).manageApps)), (isAdmin || isSiteAdmin) && (0, (0, parcelRequire("4R6GH")).fg)('upm_frontend_deprecation') && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'appsNavigationMenuItem',
                isAdmin: isSiteAdmin,
                menuItemId: 'appAuditLogs',
                menuItemType: 'action',
                position: position++
            },
            href: "/plugins/servlet/upm/log",
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-app-audit-logs')
        }, formatMessage((0, (0, parcelRequire("D9lkq")).default).appAuditLogs)), (0, (0, parcelRequire("co1wz")).ff)('enduser-emcee-discover') && isSiteAdmin && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'appsNavigationMenuItem',
                isAdmin: isSiteAdmin,
                menuItemId: 'appRequests',
                menuItemType: 'action',
                position: position++
            },
            href: (0, (0, parcelRequire("6awYZ")).getAppRequestsLink)(),
            params: $1489798fc3202517$var$emceeAppRequestsParams,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-app-requests'),
            to: (0, (0, parcelRequire("co1wz")).ff)('marketplace.emcee.native.spa.enable') ? (0, (0, parcelRequire("4zecE")).appRequestRoute) : (0, (0, parcelRequire("9Y7t8")).emceeAppRoute)
        }, formatMessage((0, (0, parcelRequire("D9lkq")).default).appRequests)), (0, (0, parcelRequire("co1wz")).ff)('enable-app-promotions-emcee-apps-dropdown') && isSiteAdmin && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'appsNavigationMenuItem',
                isAdmin: isSiteAdmin,
                menuItemId: 'appPromotions',
                menuItemType: 'action',
                position: position++
            },
            href: (0, (0, parcelRequire("6awYZ")).getManagePromotionsLink)(),
            params: $1489798fc3202517$var$emceeAppPromotionsParams,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-app-promotions'),
            to: (0, (0, parcelRequire("co1wz")).ff)('marketplace.emcee.native.spa.enable') ? (0, (0, parcelRequire("4zecE")).promotionsRoute) : (0, (0, parcelRequire("9Y7t8")).emceeAppRoute)
        }, formatMessage((0, (0, parcelRequire("D9lkq")).default).appPromotions)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: "appsMenuFooterSection",
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), null)));
    };
});
parcelRegister("6awYZ", function(module, exports) {
    $parcel$export(module.exports, "getEmceeDiscoverLink", ()=>$3f6cbde73539e62b$export$f26dabf284aefb17);
    $parcel$export(module.exports, "getEmceeAppListingLink", ()=>$3f6cbde73539e62b$export$eb09695b9e9f6a3);
    $parcel$export(module.exports, "getAppRequestsLink", ()=>$3f6cbde73539e62b$export$7ab8c48f9081e976);
    $parcel$export(module.exports, "getManagePromotionsLink", ()=>$3f6cbde73539e62b$export$350ca33eed6a2742);
    var $co1wz;
    const $3f6cbde73539e62b$var$sanitiseParams = (params)=>params ? Object.entries(params).reduce((res, [key, value])=>{
            if (value) res[key] = params[key];
            return res;
        }, {}) : {};
    const $3f6cbde73539e62b$var$addQueryParamsToLink = (params, link)=>{
        const queryParams = $3f6cbde73539e62b$var$sanitiseParams(params);
        const queryString = new URLSearchParams(queryParams).toString();
        return queryString ? `${link}?${queryString}` : link;
    };
    const $3f6cbde73539e62b$var$resolveConnectDiscoverUrl = (connectUrlDiscoverVariant = 'ENDUSER_DISCOVER', jiraQueryParams)=>{
        let connectDiscoverUrl = `/plugins/servlet/ac/com.atlassian.jira.emcee/${connectUrlDiscoverVariant === 'DISCOVER' ? 'discover' : 'enduser-discover'}`;
        if (jiraQueryParams) connectDiscoverUrl = $3f6cbde73539e62b$var$addQueryParamsToLink(jiraQueryParams, connectDiscoverUrl);
        return connectDiscoverUrl;
    };
    const $3f6cbde73539e62b$export$f26dabf284aefb17 = (params, connectUrlDiscoverVariant = 'ENDUSER_DISCOVER')=>{
        const link = (0, (0, parcelRequire("co1wz")).ff)('marketplace.emcee.native.spa.enable') ? '/jira/marketplace/discover' : $3f6cbde73539e62b$var$resolveConnectDiscoverUrl(connectUrlDiscoverVariant);
        return $3f6cbde73539e62b$var$addQueryParamsToLink(params, link);
    };
    const $3f6cbde73539e62b$export$eb09695b9e9f6a3 = (appKey, params, jiraQueryParams, connectUrlDiscoverVariant = 'ENDUSER_DISCOVER')=>(0, (0, parcelRequire("co1wz")).ff)('marketplace.emcee.native.spa.enable') ? $3f6cbde73539e62b$var$addQueryParamsToLink({
            ...jiraQueryParams,
            ...params
        }, `/jira/marketplace/discover/app/${appKey}`) : $3f6cbde73539e62b$var$addQueryParamsToLink(params, `${$3f6cbde73539e62b$var$resolveConnectDiscoverUrl(connectUrlDiscoverVariant, jiraQueryParams)}#!/discover/app/${appKey}`);
    const $3f6cbde73539e62b$export$7ab8c48f9081e976 = (params)=>{
        const link = (0, (0, parcelRequire("co1wz")).ff)('marketplace.emcee.native.spa.enable') ? '/jira/marketplace/manage/app-requests' : '/plugins/servlet/ac/com.atlassian.jira.emcee/app-requests';
        return $3f6cbde73539e62b$var$addQueryParamsToLink(params, link);
    };
    const $3f6cbde73539e62b$export$350ca33eed6a2742 = (params)=>{
        const link = (0, (0, parcelRequire("co1wz")).ff)('marketplace.emcee.native.spa.enable') ? '/jira/marketplace/manage/promotions' : '/plugins/servlet/ac/com.atlassian.jira.emcee/promotions';
        return $3f6cbde73539e62b$var$addQueryParamsToLink(params, link);
    };
});
parcelRegister("9Y7t8", function(module, exports) {
    $parcel$export(module.exports, "emceeAppRoute", ()=>$18ac51784a86a796$export$5149e7851c6c4f53);
    $parcel$export(module.exports, "manageAppsRoute", ()=>$18ac51784a86a796$export$d5409be3b4016aab);
    var $65Ltd;
    const $18ac51784a86a796$export$5149e7851c6c4f53 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_MARKETPLACE_ADDONS_EMCEE),
        path: '/plugins/servlet/ac/com.atlassian.jira.emcee:environment(.?[\\w.]*)?/:page'
    };
    const $18ac51784a86a796$export$d5409be3b4016aab = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_MARKETPLACE_MANAGE_APPS),
        path: '/plugins/servlet/upm'
    };
});
parcelRegister("4zecE", function(module, exports) {
    $parcel$export(module.exports, "findAppsRoute", ()=>$75d0c5179e224132$export$e264ef643451c60a);
    $parcel$export(module.exports, "appRequestRoute", ()=>$75d0c5179e224132$export$e5429b8cad3b4991);
    $parcel$export(module.exports, "promotionsRoute", ()=>$75d0c5179e224132$export$45df65bfacbf9a2a);
    var $65Ltd;
    const $75d0c5179e224132$export$e264ef643451c60a = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_MARKETPLACE_INDEX),
        path: '/jira/marketplace'
    };
    const $75d0c5179e224132$export$e5429b8cad3b4991 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_MARKETPLACE_APP_REQUESTS),
        path: '/jira/marketplace/manage/app-requests'
    };
    const $75d0c5179e224132$export$45df65bfacbf9a2a = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_MARKETPLACE_PROMOTIONS),
        path: '/jira/marketplace/manage/promotions'
    };
});
parcelRegister("D9lkq", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9764b5e51d5e92fc$export$2e2bcd8739ae039);
    var $7VHMR;
    var $9764b5e51d5e92fc$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        findApps: {
            "id": "atlassian-navigation.addons.menu.footer.find-apps",
            "defaultMessage": "Find new apps"
        },
        manageApps: {
            "id": "atlassian-navigation.addons.menu.footer.manage-apps",
            "defaultMessage": "Manage your apps"
        },
        appAuditLogs: {
            "id": "atlassian-navigation.addons.menu.footer.app-audit-logs",
            "defaultMessage": "App audit logs"
        },
        appRequests: {
            "id": "atlassian-navigation.addons.menu.footer.app-requests",
            "defaultMessage": "View app requests"
        },
        appPromotions: {
            "id": "atlassian-navigation.addons.menu.footer.app-promotions",
            "defaultMessage": "Promotions"
        },
        exploreApps: {
            "id": "atlassian-navigation.addons.menu.personalised-apps-experiment-enriched-footer.personalised-apps-experiment-footer-section.recommended-explore-apps",
            "defaultMessage": "Explore more apps"
        },
        appUpdates: {
            "id": "atlassian-navigation.addons.menu.footer.app-updates",
            "defaultMessage": "{apps, plural, one {update} other {updates}}"
        }
    });
});
parcelRegister("dEiSH", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f4ce60ec2367163b$export$2e2bcd8739ae039);
    var $7VHMR;
    var $f4ce60ec2367163b$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        apps: {
            "id": "atlassian-navigation.addons.apps",
            "defaultMessage": "Apps"
        }
    });
});
parcelRegister("6RKvu", function(module, exports) {
    $parcel$export(module.exports, "AppSwitcher", ()=>$133f3cac4f90ffa1$export$9923361bd43fa030);
    var $5uXOq = parcelRequire("5uXOq");
    var $9jFq3;
    var $lmyls;
    var $2Ui4E;
    var $Mp6qH;
    var $4uXxK;
    var $iPj86;
    const $133f3cac4f90ffa1$var$AppSwitcher = ({ AppSwitcherIntegration: AppSwitcherIntegration = (0, (0, parcelRequire("9jFq3")).AppSwitcher), useTopMenus: useTopMenus = (0, (0, parcelRequire("2Ui4E")).useTopMenus), useNavigationItemAnalytics: useNavigationItemAnalytics = (0, (0, parcelRequire("iPj86")).useNavigationItemAnalytics) })=>{
        const [isOpen, { on: openMenu, off: closeMenu }] = useTopMenus((0, (0, parcelRequire("lmyls")).MENU_ID).APP_SWITCHER);
        const triggerAnalytics = useNavigationItemAnalytics({
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).APP_SWITCHER
        });
        const onOpen = (0, $5uXOq.useCallback)(()=>{
            openMenu();
            triggerAnalytics();
        }, [
            openMenu,
            triggerAnalytics
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement(AppSwitcherIntegration, {
            isOpen: isOpen,
            onOpen: onOpen,
            onClose: closeMenu
        });
    };
    const $133f3cac4f90ffa1$export$9923361bd43fa030 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).APP_SWITCHER
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($133f3cac4f90ffa1$var$AppSwitcher, props));
});
parcelRegister("9jFq3", function(module, exports) {
    $parcel$export(module.exports, "AppSwitcher", ()=>$20a8f1ac93bd5269$export$9923361bd43fa030);
    var $5uXOq = parcelRequire("5uXOq");
    var $ksLsv;
    var $heNNl;
    var $lfTZh;
    var $kxZPA;
    var $gMP0k;
    const $20a8f1ac93bd5269$var$LazyAppSwitcher = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("78fKF")).then((module)=>module.AppSwitcher), {
        ssr: false,
        moduleId: "./src/packages/navigation-apps/atlassian-navigation-switcher-integration/src/main.tsx"
    });
    const $20a8f1ac93bd5269$export$9923361bd43fa030 = (props)=>{
        (0, (0, parcelRequire("ksLsv")).useCrossJoinNudgeExperiment)();
        const handleMouseEnter = (0, $5uXOq.useCallback)(()=>{
            $20a8f1ac93bd5269$var$LazyAppSwitcher.preload();
        }, []);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("heNNl")).default), {
            id: "app-switcher.integration.async",
            packageName: "NavigationIntegrations"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "lazy-app-switcher",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gMP0k")).AppSwitcherLight), null)
        }, props.isOpen ? (0, ($parcel$interopDefault($5uXOq))).createElement($20a8f1ac93bd5269$var$LazyAppSwitcher, props) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gMP0k")).AppSwitcherLight), {
            onOpen: props.onOpen,
            onMouseEnter: handleMouseEnter
        })));
    };
});
parcelRegister("ksLsv", function(module, exports) {
    $parcel$export(module.exports, "useCrossJoinNudgeExperiment", ()=>$d9661dec9418aa61$export$c30730c5d28616da);
    $parcel$export(module.exports, "useCrossJoinNudgeYourWorkEligibility", ()=>$d9661dec9418aa61$export$1708bc6112f5b6aa);
    $parcel$export(module.exports, "CrossJoinNudgeYourWork", ()=>$d9661dec9418aa61$export$eb1ab086083a0ea3);
    var $5uXOq = parcelRequire("5uXOq");
    var $jcw0u;
    var $6s1PB;
    var $4R6GH;
    var $8zOmE;
    var $2NMNM;
    var $90yGq;
    var $koeKL;
    var $hqBSi;
    var $doZTA;
    var $lVJAU;
    var $1e3dw;
    var $5cSdG;
    var $dxdji;
    var $kB1IK;
    var $62RUt;
    var $iVil7;
    var $gdX4y;
    var $e1J1K;
    var $8mTEW;
    var $25NDO;
    var $iu3ma;
    var $fVgtE;
    var $eCsnX;
    var $4kGsO;
    var $fmenl;
    var $khNyR;
    const $d9661dec9418aa61$var$MAX_NUMBER_OF_COLLABORATORS = 5;
    const $d9661dec9418aa61$var$MAX_FEATURE_VISIT_COUNT = 3;
    const $d9661dec9418aa61$export$c30730c5d28616da = ()=>{
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const fireExposure = (0, (0, parcelRequire("5cSdG")).useFeatureExposed)();
        const isRequestCapabilitiesEligible = (0, (0, parcelRequire("e1J1K")).useConfluenceRequestCapabilitiesCheck)(cloudId);
        const isSite7DaysOlder = (0, (0, parcelRequire("8mTEW")).useSiteEligibilityCheck)(cloudId);
        const getCollaborators = (0, (0, parcelRequire("dxdji")).useUsersFromCollaborationGraph)(cloudId);
        const showRequestAccessPromptFlag = (0, (0, parcelRequire("eCsnX")).useRequestAccessPromptFlag)();
        const showSuccessFlag = (0, (0, parcelRequire("4kGsO")).useRequestAccessSuccessFlag)();
        const showErrorFlag = (0, (0, parcelRequire("fVgtE")).useRequestAccessErrorFlag)();
        const requestAccess = (0, (0, parcelRequire("gdX4y")).useConfluenceRequestAccess)();
        const { getNudgeState: getNudgeStateV1, setNudgeDismissed: setNudgeDismissedV1 } = (0, (0, parcelRequire("kB1IK")).useNudgeStateV1)();
        const { getNudgeState: getNudgeStateV2a, setNudgeDismissed: setNudgeDismissedV2a } = (0, (0, parcelRequire("kB1IK")).useNudgeStateV2a)();
        const { push: push } = (0, (0, parcelRequire("lVJAU")).useRouterActions)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const userId = (0, (0, parcelRequire("90yGq")).useAccountId)() || '';
        const licensedProducts = (0, (0, parcelRequire("hqBSi")).useLicensedProducts)();
        const [{ route: route }] = (0, (0, parcelRequire("doZTA")).useRouter)();
        (0, $5uXOq.useEffect)(()=>{
            const runExperiment = async ()=>{
                if (!(0, (0, parcelRequire("4R6GH")).fg)('cross_join_nudges_gate')) return;
                try {
                    if (route.name === 'your-work') {
                        fireExposure('YOUR_WORK_ROUTE');
                        return;
                    }
                    if (!licensedProducts.confluence) {
                        fireExposure('NO_CONFLUENCE_LICENSE');
                        return;
                    }
                    if ((0, (0, parcelRequire("1e3dw")).isFeatureVisited)()) return;
                    if (await (0, (0, parcelRequire("iVil7")).hasConfluenceAccess)(cloudId)) {
                        fireExposure('ALREADY_HAVE_ACCESS');
                        return;
                    }
                    if (!await isRequestCapabilitiesEligible()) {
                        fireExposure('NOT_ELIGIBLE_FOR_REQUEST_ACCESS');
                        return;
                    }
                    if (!await isSite7DaysOlder()) {
                        fireExposure('NEW_SITE_LESS_THAN_7_DAYS_OLD');
                        return;
                    }
                    const onSubmit = async ()=>{
                        try {
                            const accessStatus = await requestAccess();
                            if (accessStatus === 'ACCESS_GRANTED' || accessStatus === 'ACCESS_EXISTS' || accessStatus === 'APPROVED_REQUEST_EXISTS') push('/wiki');
                            else if (accessStatus === 'PENDING_REQUEST_CREATED' || accessStatus === 'PENDING_REQUEST_EXISTS') showSuccessFlag();
                            else showErrorFlag();
                        } catch  {
                            showErrorFlag();
                        }
                    };
                    const collaborators = await getCollaborators();
                    const confluenceUsers = await (0, (0, parcelRequire("62RUt")).getConfluenceUsers)(collaborators, cloudId);
                    if (confluenceUsers.length === 0) {
                        fireExposure('COLLABORATORS_HAVE_NO_ACCESS');
                        return;
                    }
                    const users = confluenceUsers.slice(0, $d9661dec9418aa61$var$MAX_NUMBER_OF_COLLABORATORS);
                    fireExposure('ELIGIBLE');
                    if (await getNudgeStateV2a() === 'DISMISSED') return;
                    const featureVisitedCount = await (0, (0, parcelRequire("1e3dw")).getFeatureVisitedCount)(userId);
                    if (featureVisitedCount >= $d9661dec9418aa61$var$MAX_FEATURE_VISIT_COUNT) return;
                    showRequestAccessPromptFlag({
                        isV2a: true,
                        users: users,
                        onSubmit: onSubmit,
                        onOptout: setNudgeDismissedV2a,
                        snoozeFor30Days: (0, (0, parcelRequire("1e3dw")).snoozeFeatureFor30Days),
                        featureVisitedCount: featureVisitedCount
                    });
                    (0, (0, parcelRequire("1e3dw")).increaseFeatureVisitedCount)(userId, featureVisitedCount + 1);
                    (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'slo.crossJoinNudgesConfluence succeeded');
                } catch (error) {
                    (0, (0, parcelRequire("jcw0u")).default)({
                        meta: {
                            id: 'useCrossJoinNudgesExperiment',
                            packageName: 'jiraCrossJoinNudges'
                        },
                        attributes: {
                            cloudId: cloudId
                        },
                        error: error,
                        sendToPrivacyUnsafeSplunk: true
                    });
                }
            };
            runExperiment();
        }, [
            cloudId,
            createAnalyticsEvent,
            fireExposure,
            getCollaborators,
            getNudgeStateV1,
            getNudgeStateV2a,
            isRequestCapabilitiesEligible,
            isSite7DaysOlder,
            push,
            requestAccess,
            route.name,
            setNudgeDismissedV1,
            setNudgeDismissedV2a,
            showErrorFlag,
            showRequestAccessPromptFlag,
            showSuccessFlag,
            userId,
            licensedProducts.confluence
        ]);
    };
    const $d9661dec9418aa61$export$1708bc6112f5b6aa = ()=>{
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const trackIneligibility = (0, (0, parcelRequire("5cSdG")).useIneligibilityAnalytics)();
        const isSite7DaysOlder = (0, (0, parcelRequire("8mTEW")).useSiteEligibilityCheck)(cloudId);
        const { isVisited: isVisited } = (0, (0, parcelRequire("1e3dw")).useSnooze)('cross.join.nudges.your.work');
        const licensedProducts = (0, (0, parcelRequire("hqBSi")).useLicensedProducts)();
        const { getNudgeState: getNudgeState } = (0, (0, parcelRequire("kB1IK")).useNudgeStateYourWork)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const [isEligible, setIsEligible] = (0, $5uXOq.useState)(false);
        (0, $5uXOq.useEffect)(()=>{
            const checkEligibility = async ()=>{
                try {
                    if (!(0, (0, parcelRequire("4R6GH")).fg)('team_expansion_cross_join_nudge_jira_your_work')) return;
                    if (!licensedProducts.confluence) {
                        trackIneligibility('NO_CONFLUENCE_LICENSE');
                        return;
                    }
                    if (isVisited()) return;
                    if (await getNudgeState() === 'DISMISSED') return;
                    (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'confluenceAccessCheck started', {
                        touchpoint: 'jiraYourWork'
                    });
                    const hasAccessToConfluence = await (0, (0, parcelRequire("iVil7")).fetchCachedConfluenceAccessCheck)(createAnalyticsEvent, cloudId, 'jiraYourWork');
                    if (hasAccessToConfluence === null) return;
                    if (hasAccessToConfluence) {
                        trackIneligibility('ALREADY_HAVE_ACCESS');
                        return;
                    }
                    (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'capabilitiesCheck started', {
                        touchpoint: 'jiraYourWork'
                    });
                    const isRaCapable = await (0, (0, parcelRequire("e1J1K")).fetchCachedIsConfluenceRACapable)(createAnalyticsEvent, cloudId, 'jiraYourWork');
                    if (isRaCapable === null) return;
                    if (!isRaCapable) {
                        trackIneligibility('NOT_ELIGIBLE_FOR_REQUEST_ACCESS');
                        return;
                    }
                    (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'siteAgeCheck started', {
                        touchpoint: 'jiraYourWork'
                    });
                    if (!await isSite7DaysOlder()) {
                        trackIneligibility('NEW_SITE_LESS_THAN_7_DAYS_OLD');
                        return;
                    }
                    (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'promotionalCard eligibleCheckSucceeded', {
                        touchpoint: 'jiraYourWork'
                    });
                    setIsEligible(true);
                } catch (error) {
                    (0, (0, parcelRequire("jcw0u")).default)({
                        meta: {
                            id: 'useCrossJoinNudgeYourWorkEligibility',
                            packageName: 'jiraCrossJoinNudges'
                        },
                        attributes: {
                            cloudId: cloudId
                        },
                        error: error,
                        sendToPrivacyUnsafeSplunk: true
                    });
                }
            };
            checkEligibility();
        }, [
            cloudId,
            getNudgeState,
            isSite7DaysOlder,
            isVisited,
            licensedProducts.confluence,
            trackIneligibility,
            createAnalyticsEvent
        ]);
        return isEligible;
    };
    const $d9661dec9418aa61$var$CrossJoinNudgeYourWorkWithChecks = ()=>{
        const showSuccessFlag = (0, (0, parcelRequire("4kGsO")).useRequestAccessSuccessFlag)();
        const showErrorFlag = (0, (0, parcelRequire("fVgtE")).useRequestAccessErrorFlag)();
        const requestAccess = (0, (0, parcelRequire("gdX4y")).useConfluenceRequestAccess)('crossJoinNudgesYourWork');
        const { setNudgeDismissed: setNudgeDismissed } = (0, (0, parcelRequire("kB1IK")).useNudgeStateYourWork)();
        const key = 'cross.join.nudges.your.work';
        const { snoozeFor7Days: snoozeFor7Days, snoozeFor30Days: snoozeFor30Days, increaseVisitedCount: increaseVisitedCount } = (0, (0, parcelRequire("1e3dw")).useSnooze)(key);
        const { push: push } = (0, (0, parcelRequire("lVJAU")).useRouterActions)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const [isDismissed, setIsDismissed] = (0, $5uXOq.useState)(false);
        const [isEnrolled] = (0, $5uXOq.useState)((0, (0, parcelRequire("6s1PB")).expValEquals)('cross_join_nudge_jira_your_work', 'cohort', 'variation'));
        const { collaborators: collaborators, isLoading: isCollaboratorsLoading } = (0, (0, parcelRequire("25NDO")).useCollaborators)(isEnrolled);
        const { visitedCount: visitedCount, isLoading: isVisitedCountLoading } = (0, (0, parcelRequire("iu3ma")).useVisitedCount)(isEnrolled, key);
        const onDismiss = (0, $5uXOq.useCallback)(()=>{
            snoozeFor7Days();
            increaseVisitedCount(visitedCount + 1);
            setIsDismissed(true);
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'promotionalCard dismissed', 'crossJoinNudges');
        }, [
            createAnalyticsEvent,
            visitedCount,
            increaseVisitedCount,
            snoozeFor7Days
        ]);
        const onSubmit = (0, $5uXOq.useCallback)(async ()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'promotionalCard submitted', 'crossJoinNudges');
            try {
                const accessStatus = await requestAccess();
                (0, (0, parcelRequire("e1J1K")).resetRACapableCache)();
                if (accessStatus === 'ACCESS_GRANTED' || accessStatus === 'ACCESS_EXISTS' || accessStatus === 'APPROVED_REQUEST_EXISTS') push('/wiki');
                else if (accessStatus === 'PENDING_REQUEST_CREATED' || accessStatus === 'PENDING_REQUEST_EXISTS') {
                    showSuccessFlag();
                    setIsDismissed(true);
                } else {
                    (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'promotionalCard submitError', {
                        accessStatus: accessStatus
                    });
                    showErrorFlag();
                }
            } catch (err) {
                (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'promotionalCard submitError', {
                    err: err
                });
                showErrorFlag();
            }
        }, [
            createAnalyticsEvent,
            push,
            requestAccess,
            showErrorFlag,
            showSuccessFlag
        ]);
        const onSnooze = (0, $5uXOq.useCallback)(()=>{
            snoozeFor30Days();
            increaseVisitedCount(visitedCount + 1);
            setIsDismissed(true);
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'promotionalCard snoozed', 'crossJoinNudges');
        }, [
            createAnalyticsEvent,
            visitedCount,
            increaseVisitedCount,
            snoozeFor30Days
        ]);
        const onOptOut = (0, $5uXOq.useCallback)(()=>{
            setNudgeDismissed();
            setIsDismissed(true);
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'promotionalCard optedOut', 'crossJoinNudges');
        }, [
            createAnalyticsEvent,
            setNudgeDismissed
        ]);
        let secondaryAction;
        switch(visitedCount){
            case 0:
                secondaryAction = 'none';
                break;
            case 1:
                secondaryAction = 'snooze';
                break;
            default:
                secondaryAction = 'opt-out';
        }
        const onRender = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'promotionalCard shown', 'crossJoinNudges', {
                secondaryAction: secondaryAction,
                collaborators: collaborators.length
            });
        }, [
            collaborators.length,
            createAnalyticsEvent,
            secondaryAction
        ]);
        const shouldShow = isEnrolled && !isCollaboratorsLoading && !isVisitedCountLoading && !isDismissed;
        return shouldShow ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("khNyR")).CrossJoinNudgeYourWorkCard), {
            collaborators: collaborators,
            onRender: onRender,
            onDismiss: secondaryAction === 'none' ? onDismiss : onSnooze,
            onSubmit: onSubmit,
            onSnooze: secondaryAction === 'snooze' ? onSnooze : undefined,
            onOptOut: secondaryAction === 'opt-out' ? onOptOut : undefined
        }) : null;
    };
    const $d9661dec9418aa61$export$eb1ab086083a0ea3 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fmenl")).ErrorBoundary), null, (0, ($parcel$interopDefault($5uXOq))).createElement($d9661dec9418aa61$var$CrossJoinNudgeYourWorkWithChecks, null));
});
parcelRegister("1e3dw", function(module, exports) {
    $parcel$export(module.exports, "isFeatureVisited", ()=>$6d6d9cc23b530639$export$262c003deb93a450);
    $parcel$export(module.exports, "snoozeFeatureFor30Days", ()=>$6d6d9cc23b530639$export$788659dbff920e5f);
    $parcel$export(module.exports, "getFeatureVisitedCount", ()=>$6d6d9cc23b530639$export$64c6861c3bbd89a2);
    $parcel$export(module.exports, "increaseFeatureVisitedCount", ()=>$6d6d9cc23b530639$export$41554dcbb74e65ef);
    $parcel$export(module.exports, "useSnooze", ()=>$6d6d9cc23b530639$export$26ba987533188b38);
    var $5uXOq = parcelRequire("5uXOq");
    var $bSSG7;
    var $jcw0u;
    var $90yGq;
    var $2lgIf;
    const $6d6d9cc23b530639$var$CROSS_JOIN_NUDGES_CONFLUENCE_VISITED_KEY = 'cross.join.nudges.confluence.visited';
    const $6d6d9cc23b530639$var$VISITED_COUNT_KEY = 'CROSS_JOIN_NUDGE_CONFLUENCE_VISITED_COUNT';
    const $6d6d9cc23b530639$var$SNOOZE_DAYS_AFTER_FEATURE_IGNORED = 7;
    const $6d6d9cc23b530639$var$SNOOZE_DAYS_AFTER_NOT_INTERESTED_CLICKED = 30;
    const $6d6d9cc23b530639$var$ONE_DAY_IN_MILLISECONDS = 86400000;
    const $6d6d9cc23b530639$var$crossJoinNudgeVisitedCache = (0, (0, parcelRequire("bSSG7")).createLocalStorageProvider)('cross-join-nudge-visited');
    const $6d6d9cc23b530639$export$262c003deb93a450 = ()=>{
        try {
            if ($6d6d9cc23b530639$var$getFeatureVisited()) return true;
            $6d6d9cc23b530639$export$788659dbff920e5f();
            if (!$6d6d9cc23b530639$var$getFeatureVisited()) {
                (0, (0, parcelRequire("jcw0u")).default)({
                    meta: {
                        id: 'featureVisitedLocalStorageNotAvailabe',
                        packageName: 'jiraCrossJoinNudges'
                    }
                });
                return true;
            }
            return false;
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: 'useFeatureVisited',
                    packageName: 'jiraCrossJoinNudges'
                },
                error: error,
                skipSentry: true,
                sendToPrivacyUnsafeSplunk: true
            });
            return true;
        }
    };
    const $6d6d9cc23b530639$var$setFeatureVisited = (numOfDaysSnoozed, key = $6d6d9cc23b530639$var$CROSS_JOIN_NUDGES_CONFLUENCE_VISITED_KEY)=>{
        const SNOOZE_UNTIL_TIMESTAMP = new Date().getTime() + $6d6d9cc23b530639$var$ONE_DAY_IN_MILLISECONDS * numOfDaysSnoozed;
        $6d6d9cc23b530639$var$crossJoinNudgeVisitedCache.set(key, SNOOZE_UNTIL_TIMESTAMP);
    };
    const $6d6d9cc23b530639$export$7a91674e4b755c4b = (key = $6d6d9cc23b530639$var$CROSS_JOIN_NUDGES_CONFLUENCE_VISITED_KEY)=>{
        $6d6d9cc23b530639$var$setFeatureVisited($6d6d9cc23b530639$var$SNOOZE_DAYS_AFTER_FEATURE_IGNORED, key);
    };
    const $6d6d9cc23b530639$export$788659dbff920e5f = (key = $6d6d9cc23b530639$var$CROSS_JOIN_NUDGES_CONFLUENCE_VISITED_KEY)=>{
        $6d6d9cc23b530639$var$setFeatureVisited($6d6d9cc23b530639$var$SNOOZE_DAYS_AFTER_NOT_INTERESTED_CLICKED, key);
    };
    const $6d6d9cc23b530639$var$getFeatureVisited = (key = $6d6d9cc23b530639$var$CROSS_JOIN_NUDGES_CONFLUENCE_VISITED_KEY)=>{
        const snoozeUntilTimestampString = $6d6d9cc23b530639$var$crossJoinNudgeVisitedCache.get(key);
        if (snoozeUntilTimestampString !== null) {
            const SNOOZE_UNTIL_TIMESTAMP = parseInt(snoozeUntilTimestampString, 10);
            const TIMESTAMP = new Date().getTime();
            if (!Number.isNaN(SNOOZE_UNTIL_TIMESTAMP) && TIMESTAMP < SNOOZE_UNTIL_TIMESTAMP) return true;
        }
        return false;
    };
    const $6d6d9cc23b530639$export$64c6861c3bbd89a2 = async (accountId, propertyKey = $6d6d9cc23b530639$var$VISITED_COUNT_KEY)=>{
        if (!accountId) return 0;
        const visitedCount = await (0, (0, parcelRequire("2lgIf")).getUserProperty)({
            accountId: accountId,
            propertyKey: propertyKey,
            defaultValue: 0
        });
        return !Number.isNaN(visitedCount.value) ? visitedCount.value : 0;
    };
    const $6d6d9cc23b530639$export$41554dcbb74e65ef = async (accountId, incrementedCount, propertyKey = $6d6d9cc23b530639$var$VISITED_COUNT_KEY)=>{
        await (0, (0, parcelRequire("2lgIf")).setUserProperty)({
            accountId: accountId,
            propertyKey: propertyKey,
            propertyValue: incrementedCount
        });
    };
    const $6d6d9cc23b530639$export$26ba987533188b38 = (key)=>{
        const accountId = (0, (0, parcelRequire("90yGq")).useAccountId)() || '';
        const countKey = `${key}.count`;
        return (0, $5uXOq.useMemo)(()=>({
                snoozeFor7Days: ()=>$6d6d9cc23b530639$export$7a91674e4b755c4b(key),
                snoozeFor30Days: ()=>$6d6d9cc23b530639$export$788659dbff920e5f(key),
                getVisitedCount: ()=>$6d6d9cc23b530639$export$64c6861c3bbd89a2(accountId, countKey),
                increaseVisitedCount: (incrementedCount)=>$6d6d9cc23b530639$export$41554dcbb74e65ef(accountId, incrementedCount, countKey),
                isVisited: ()=>$6d6d9cc23b530639$var$getFeatureVisited(key)
            }), [
            accountId,
            countKey,
            key
        ]);
    };
});
parcelRegister("2lgIf", function(module, exports) {
    $parcel$export(module.exports, "setUserProperty", ()=>$a2f0508e2b69d572$export$ba821ce5e177c7ef);
    $parcel$export(module.exports, "getUserProperty", ()=>$a2f0508e2b69d572$export$b3126db2ef6e34ed);
    var $jcw0u;
    var $eusud;
    const $a2f0508e2b69d572$var$getUserUrl = (accountId, propertyKey)=>`/rest/api/3/user/properties/${propertyKey}?accountId=${accountId}`;
    const $a2f0508e2b69d572$export$ba821ce5e177c7ef = async ({ accountId: accountId, propertyKey: propertyKey, propertyValue: propertyValue })=>{
        const url = $a2f0508e2b69d572$var$getUserUrl(accountId, propertyKey);
        try {
            await (0, (0, parcelRequire("eusud")).performPutRequest)(url, {
                body: JSON.stringify(propertyValue)
            });
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: `SET_${propertyKey}`,
                    packageName: 'jiraCrossJoinNudges'
                },
                error: error
            });
        }
    };
    const $a2f0508e2b69d572$export$b3126db2ef6e34ed = async ({ accountId: accountId, propertyKey: propertyKey, defaultValue: defaultValue })=>{
        const url = $a2f0508e2b69d572$var$getUserUrl(accountId, propertyKey);
        try {
            const response = await (0, (0, parcelRequire("eusud")).performGetRequest)(url);
            return response;
        } catch (error) {
            return {
                key: propertyKey,
                value: defaultValue
            };
        }
    };
});
parcelRegister("5cSdG", function(module, exports) {
    $parcel$export(module.exports, "useFeatureExposed", ()=>$e90289e9d20092ca$export$700e85d1c81b218b);
    $parcel$export(module.exports, "useIneligibilityAnalytics", ()=>$e90289e9d20092ca$export$8bdc1ff53bd5e153);
    var $5uXOq = parcelRequire("5uXOq");
    var $jcw0u;
    var $8zOmE;
    var $2NMNM;
    const $e90289e9d20092ca$export$700e85d1c81b218b = ()=>{
        const flagKey = 'cross_join_nudges_confluence_v2a';
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const cohort = 'variation';
        return (0, $5uXOq.useCallback)((ineligibilityReason)=>{
            try {
                if (ineligibilityReason === 'ELIGIBLE') (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(createAnalyticsEvent({}), 'feature exposed', {
                    flagKey: flagKey,
                    cohort: cohort,
                    ineligibilityReason: ineligibilityReason
                });
                else (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(createAnalyticsEvent({}), 'eligibility checked', {
                    flagKey: flagKey,
                    flagCohort: cohort,
                    ineligibilityReason: ineligibilityReason
                });
            } catch (error) {
                (0, (0, parcelRequire("jcw0u")).default)({
                    meta: {
                        id: 'useFeatureExposed',
                        packageName: 'jiraCrossJoinNudges'
                    },
                    error: error,
                    sendToPrivacyUnsafeSplunk: true
                });
            }
        }, [
            cohort,
            createAnalyticsEvent,
            flagKey
        ]);
    };
    const $e90289e9d20092ca$export$8bdc1ff53bd5e153 = ()=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        return (0, $5uXOq.useCallback)((ineligibilityReason)=>{
            (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(createAnalyticsEvent({}), 'eligibility checked', {
                flagKey: 'cross_join_nudge_jira_your_work',
                ineligibilityReason: ineligibilityReason
            });
        }, [
            createAnalyticsEvent
        ]);
    };
});
parcelRegister("dxdji", function(module, exports) {
    $parcel$export(module.exports, "useUsersFromCollaborationGraph", ()=>$05df166a7944f9a9$export$b05786e1a14be004);
    var $5uXOq = parcelRequire("5uXOq");
    var $jcw0u;
    var $eusud;
    var $9OXo1;
    const $05df166a7944f9a9$var$COLLABORATION_GRAPH_MAX_RESULTS = 50;
    const $05df166a7944f9a9$export$b05786e1a14be004 = (cloudId)=>{
        const { atlassianAccountId: atlassianAccountId } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        return (0, $5uXOq.useCallback)(async ()=>{
            try {
                const payload = {
                    context: {
                        contextType: 'crossJoinNudges',
                        principalId: atlassianAccountId,
                        siteId: cloudId
                    },
                    modelRequestParams: {
                        experience: 'CgUserNearbyUser'
                    },
                    expanded: true,
                    maxNumberOfResults: $05df166a7944f9a9$var$COLLABORATION_GRAPH_MAX_RESULTS,
                    userId: atlassianAccountId
                };
                const options = {
                    body: JSON.stringify(payload)
                };
                return (0, (0, parcelRequire("eusud")).performPostRequest)('/gateway/api/collaboration/v1/collaborationgraph/user/user', options).then((entities)=>entities.collaborationGraphEntities.filter((en)=>en.userProfile !== undefined).map((en)=>({
                            id: en.userProfile.account_id,
                            name: en.userProfile.name,
                            avatar: en.userProfile.picture
                        })));
            } catch (error) {
                (0, (0, parcelRequire("jcw0u")).default)({
                    meta: {
                        id: 'useUsersFromCollaborationGraph',
                        packageName: 'jiraCrossJoinNudges'
                    },
                    error: error,
                    sendToPrivacyUnsafeSplunk: true
                });
                throw new Error('Get collaborators error');
            }
        }, [
            atlassianAccountId,
            cloudId
        ]);
    };
});
parcelRegister("kB1IK", function(module, exports) {
    $parcel$export(module.exports, "useNudgeStateV1", ()=>$b95457efaa0607a0$export$c50db6713418f20c);
    $parcel$export(module.exports, "useNudgeStateV2a", ()=>$b95457efaa0607a0$export$1721ab60f4854021);
    $parcel$export(module.exports, "useNudgeStateYourWork", ()=>$b95457efaa0607a0$export$766742495b6411cb);
    var $5uXOq = parcelRequire("5uXOq");
    var $90yGq;
    var $2lgIf;
    const $b95457efaa0607a0$var$KEY_V1 = 'CROSS_JOIN_NUDGES_CONFLUENCE';
    const $b95457efaa0607a0$var$KEY_V2A = 'CROSS_JOIN_NUDGES_CONFLUENCE_V2A';
    const $b95457efaa0607a0$var$KEY_YOUR_WORK = 'CROSS_JOIN_NUDGES_YOUR_WORK';
    const $b95457efaa0607a0$var$getNudgeState = async (key, accountId)=>{
        if (!accountId) return 'NONE';
        const nudgeState = await (0, (0, parcelRequire("2lgIf")).getUserProperty)({
            accountId: accountId,
            propertyKey: key,
            defaultValue: 'NONE'
        });
        return nudgeState.value;
    };
    const $b95457efaa0607a0$var$setNudgeDismissState = async (key, accountId)=>{
        if (!accountId) return;
        await (0, (0, parcelRequire("2lgIf")).setUserProperty)({
            accountId: accountId,
            propertyKey: key,
            propertyValue: 'DISMISSED'
        });
    };
    const $b95457efaa0607a0$var$useNudgeState = (key)=>{
        const accountId = (0, (0, parcelRequire("90yGq")).useAccountId)();
        return (0, $5uXOq.useMemo)(()=>({
                getNudgeState: ()=>$b95457efaa0607a0$var$getNudgeState(key, accountId),
                setNudgeDismissed: ()=>$b95457efaa0607a0$var$setNudgeDismissState(key, accountId)
            }), [
            accountId,
            key
        ]);
    };
    const $b95457efaa0607a0$export$c50db6713418f20c = ()=>$b95457efaa0607a0$var$useNudgeState($b95457efaa0607a0$var$KEY_V1);
    const $b95457efaa0607a0$export$1721ab60f4854021 = ()=>$b95457efaa0607a0$var$useNudgeState($b95457efaa0607a0$var$KEY_V2A);
    const $b95457efaa0607a0$export$766742495b6411cb = ()=>$b95457efaa0607a0$var$useNudgeState($b95457efaa0607a0$var$KEY_YOUR_WORK);
});
parcelRegister("62RUt", function(module, exports) {
    $parcel$export(module.exports, "getConfluenceUsers", ()=>$1080fa8edae179da$export$71bcfe5bb11155f7);
    var $jcw0u;
    var $7f4tk;
    const $1080fa8edae179da$var$URL = '/gateway/api/growth/cross-join-nudges-confluence/get-permitted-confluence-users';
    const $1080fa8edae179da$export$71bcfe5bb11155f7 = async (collaborators, cloudId)=>{
        try {
            const accountIds = collaborators.map((c)=>c.id);
            const confluenceUsers = await (0, (0, parcelRequire("7f4tk")).default)($1080fa8edae179da$var$URL, {
                method: 'POST',
                body: JSON.stringify({
                    cloudId: cloudId,
                    accountIds: accountIds
                })
            });
            return collaborators.filter((c)=>confluenceUsers.includes(c.id));
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: 'getConfluenceUsers',
                    packageName: 'jiraCrossJoinNudges'
                },
                attributes: {
                    cloudId: cloudId,
                    accountIds: JSON.stringify(collaborators.map((c)=>c.id)),
                    errorResponse: $1080fa8edae179da$var$getErrorResponse(error)
                },
                error: error,
                sendToPrivacyUnsafeSplunk: true
            });
            throw new Error('Get Confluence users error');
        }
    };
    const $1080fa8edae179da$var$getErrorResponse = (error)=>{
        try {
            return JSON.stringify(error);
        } catch (e) {
            return undefined;
        }
    };
});
parcelRegister("iVil7", function(module, exports) {
    $parcel$export(module.exports, "hasConfluenceAccess", ()=>$543bba9bf251c6d1$export$cf2bb135927e1e46);
    $parcel$export(module.exports, "fetchCachedConfluenceAccessCheck", ()=>$543bba9bf251c6d1$export$f05fbd979235f3fc);
    var $bSSG7;
    var $jcw0u;
    var $7f4tk;
    var $8zOmE;
    const $543bba9bf251c6d1$var$URL = '/gateway/api/permissions/permitted';
    const $543bba9bf251c6d1$export$cf2bb135927e1e46 = async (cloudId)=>{
        try {
            return $543bba9bf251c6d1$var$fetchConfluenceAccess(cloudId);
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: 'hasConfluenceAccess',
                    packageName: 'jiraCrossJoinNudges'
                },
                error: error,
                sendToPrivacyUnsafeSplunk: true
            });
            throw new Error('Confluence permission check error');
        }
    };
    const $543bba9bf251c6d1$var$fetchConfluenceAccess = async (cloudId)=>{
        const data = {
            permissionId: 'write',
            resourceId: `ari:cloud:confluence::site/${cloudId}`
        };
        const response = await (0, (0, parcelRequire("7f4tk")).default)($543bba9bf251c6d1$var$URL, {
            method: 'POST',
            body: JSON.stringify(data)
        });
        return response.permitted;
    };
    const $543bba9bf251c6d1$var$CONFLUENCE_PERMISSION_CACHE_KEY = 'cross.join.confluence.cache.key';
    const $543bba9bf251c6d1$var$CONFLUENCE_PERMISSION_CACHE_KEY_EXPIRY_MS = 604800000;
    const $543bba9bf251c6d1$var$crossJoinApiCache = (0, (0, parcelRequire("bSSG7")).createLocalExpirableStorageProvider)('cross-join-nudge-conf-perms-api-cache');
    const $543bba9bf251c6d1$export$f05fbd979235f3fc = async (createAnalyticsEvent, cloudId, touchpoint)=>{
        let confluencePermissionValue = $543bba9bf251c6d1$var$crossJoinApiCache.get($543bba9bf251c6d1$var$CONFLUENCE_PERMISSION_CACHE_KEY);
        if (confluencePermissionValue === undefined) {
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'confluenceAccessCheck cacheMiss', {
                touchpoint: touchpoint
            });
            try {
                confluencePermissionValue = await $543bba9bf251c6d1$var$fetchConfluenceAccess(cloudId);
            } catch (err) {
                (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'confluenceAccessCheck fetchError', {
                    touchpoint: touchpoint,
                    err: err,
                    sendToPrivacyUnsafeSplunk: true,
                    skipSentry: true
                });
                return null;
            }
            try {
                $543bba9bf251c6d1$var$crossJoinApiCache.set($543bba9bf251c6d1$var$CONFLUENCE_PERMISSION_CACHE_KEY, confluencePermissionValue, Date.now() + $543bba9bf251c6d1$var$CONFLUENCE_PERMISSION_CACHE_KEY_EXPIRY_MS);
            } catch (err) {
                (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'confluenceAccessCheck setError', {
                    touchpoint: touchpoint,
                    err: err,
                    skipSentry: true
                });
                return confluencePermissionValue;
            }
            return confluencePermissionValue;
        }
        (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'confluenceAccessCheck cacheHit', {
            touchpoint: touchpoint
        });
        return confluencePermissionValue;
    };
});
parcelRegister("gdX4y", function(module, exports) {
    $parcel$export(module.exports, "useConfluenceRequestAccess", ()=>$5c5a22de52b9c079$export$7a3505014099f607);
    var $5uXOq = parcelRequire("5uXOq");
    var $jcw0u;
    var $7f4tk;
    var $8zOmE;
    var $2NMNM;
    var $90yGq;
    var $koeKL;
    const $5c5a22de52b9c079$var$URL = '/gateway/api/invitations/v1/access-requests/request';
    const $5c5a22de52b9c079$var$product = 'confluence';
    const $5c5a22de52b9c079$export$7a3505014099f607 = (integration = 'crossJoinNudgesConfluence')=>{
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const userId = (0, (0, parcelRequire("90yGq")).useAccountId)() || '';
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const resource = `ari:cloud:${$5c5a22de52b9c079$var$product}::site/${cloudId}`;
        return (0, $5uXOq.useCallback)(async ()=>{
            try {
                return (0, (0, parcelRequire("7f4tk")).default)($5c5a22de52b9c079$var$URL, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        resource: resource
                    })
                }).then((response)=>{
                    const { results: results } = response;
                    const result = results[resource];
                    const analyticsAttributes = {
                        userId: userId,
                        products: [
                            $5c5a22de52b9c079$var$product
                        ],
                        inviteResult: [
                            {
                                resourceAri: resource,
                                status: result
                            }
                        ],
                        integration: integration
                    };
                    (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(createAnalyticsEvent({}), 'productAccess requested', userId, analyticsAttributes);
                    return result;
                });
            } catch (error) {
                (0, (0, parcelRequire("jcw0u")).default)({
                    meta: {
                        id: 'useConfluenceRequestAccess',
                        packageName: 'jiraCrossJoinNudges'
                    },
                    error: error,
                    sendToPrivacyUnsafeSplunk: true
                });
                throw new Error('Confluence request access error');
            }
        }, [
            createAnalyticsEvent,
            integration,
            resource,
            userId
        ]);
    };
});
parcelRegister("e1J1K", function(module, exports) {
    $parcel$export(module.exports, "useConfluenceRequestCapabilitiesCheck", ()=>$b1d9429bb117b963$export$46d687ec5563683e);
    $parcel$export(module.exports, "fetchCachedIsConfluenceRACapable", ()=>$b1d9429bb117b963$export$f5767c68446c14cb);
    $parcel$export(module.exports, "resetRACapableCache", ()=>$b1d9429bb117b963$export$5191fc85eb825b4b);
    var $5uXOq = parcelRequire("5uXOq");
    var $bSSG7;
    var $jcw0u;
    var $7f4tk;
    var $8zOmE;
    const $b1d9429bb117b963$export$46d687ec5563683e = (cloudId)=>{
        const resource = `ari:cloud:confluence::site/${cloudId}`;
        return (0, $5uXOq.useCallback)(async ()=>{
            try {
                return $b1d9429bb117b963$var$fetchIsConfluenceRACapable(resource);
            } catch (error) {
                (0, (0, parcelRequire("jcw0u")).default)({
                    meta: {
                        id: 'useConfluenceRequestCapabilitiesCheck',
                        packageName: 'jiraCrossJoinNudges'
                    },
                    error: error,
                    sendToPrivacyUnsafeSplunk: true
                });
                throw new Error('Get request capabilities error');
            }
        }, [
            resource
        ]);
    };
    const $b1d9429bb117b963$var$fetchIsConfluenceRACapable = async (resource)=>{
        const URL = $b1d9429bb117b963$var$getConfluenceRequestCapabilitiesUrl(resource);
        const response = await (0, (0, parcelRequire("7f4tk")).default)(URL, {
            credentials: 'include'
        });
        const requestCapabilities = response.results[resource];
        return requestCapabilities === 'DIRECT_ACCESS' || requestCapabilities === 'REQUEST_ACCESS';
    };
    const $b1d9429bb117b963$var$getConfluenceRequestCapabilitiesUrl = (resource)=>{
        const params = new URLSearchParams({
            resource: resource
        });
        return `/gateway/api/invitations/v1/access-requests/capabilities?${params.toString()}`;
    };
    const $b1d9429bb117b963$var$CONFLUENCE_RA_CAPABILITIES_CACHE_KEY = 'cross.join.confluence-capabilities.cache.key';
    const $b1d9429bb117b963$var$CONFLUENCE_RA_CAPABILITIES_CACHE_KEY_EXPIRY_MS = 86400000;
    const $b1d9429bb117b963$var$crossJoinApiCache = (0, (0, parcelRequire("bSSG7")).createLocalExpirableStorageProvider)('cross-join-nudge-conf-capabilities-api-cache');
    const $b1d9429bb117b963$export$f5767c68446c14cb = async (createAnalyticsEvent, cloudId, touchpoint)=>{
        const resource = `ari:cloud:confluence::site/${cloudId}`;
        let isRACapable = $b1d9429bb117b963$var$crossJoinApiCache.get($b1d9429bb117b963$var$CONFLUENCE_RA_CAPABILITIES_CACHE_KEY);
        if (isRACapable === undefined) {
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'capabilitiesCheck cacheMiss', {
                touchpoint: touchpoint
            });
            try {
                isRACapable = await $b1d9429bb117b963$var$fetchIsConfluenceRACapable(resource);
            } catch (err) {
                (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'capabilitiesCheck fetchError', {
                    touchpoint: touchpoint,
                    err: err,
                    sendToPrivacyUnsafeSplunk: true,
                    skipSentry: true
                });
                return null;
            }
            $b1d9429bb117b963$export$a901680cf5ecfb6c(createAnalyticsEvent, isRACapable, touchpoint);
            return isRACapable;
        }
        (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'capabilitiesCheck cacheHit', {
            touchpoint: touchpoint
        });
        return isRACapable;
    };
    const $b1d9429bb117b963$export$a901680cf5ecfb6c = (createAnalyticsEvent, value, touchpoint)=>{
        try {
            $b1d9429bb117b963$var$crossJoinApiCache.set($b1d9429bb117b963$var$CONFLUENCE_RA_CAPABILITIES_CACHE_KEY, value, Date.now() + $b1d9429bb117b963$var$CONFLUENCE_RA_CAPABILITIES_CACHE_KEY_EXPIRY_MS);
        } catch (err) {
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'capabilitiesCheck setError', {
                touchpoint: touchpoint,
                err: err,
                skipSentry: true
            });
        }
    };
    const $b1d9429bb117b963$export$5191fc85eb825b4b = ()=>{
        $b1d9429bb117b963$var$crossJoinApiCache.remove($b1d9429bb117b963$var$CONFLUENCE_RA_CAPABILITIES_CACHE_KEY);
    };
});
parcelRegister("8mTEW", function(module, exports) {
    $parcel$export(module.exports, "useSiteEligibilityCheck", ()=>$f2be65df141d6690$export$6d7dfd5017341421);
    var $5uXOq = parcelRequire("5uXOq");
    var $jcw0u;
    var $jX0pr;
    const $f2be65df141d6690$export$79555f8f9058b0b6 = 'confluence.ondemand.activationDate';
    const $f2be65df141d6690$export$6d7dfd5017341421 = (cloudId)=>(0, $5uXOq.useCallback)(async ()=>{
            try {
                const siteTraits = await (0, (0, parcelRequire("jX0pr")).memoizedFetchSitePersonalization)({
                    cloudId: cloudId
                });
                return $f2be65df141d6690$var$isSite7DaysOlder(siteTraits);
            } catch (error) {
                (0, (0, parcelRequire("jcw0u")).default)({
                    meta: {
                        id: 'useSiteEligibilityCheck',
                        packageName: 'jiraCrossJoinNudges'
                    },
                    attributes: {
                        cloudId: cloudId,
                        errorResponse: JSON.stringify(error)
                    },
                    error: error,
                    sendToPrivacyUnsafeSplunk: true
                });
                throw new Error('Site eligibility check error');
            }
        }, [
            cloudId
        ]);
    const $f2be65df141d6690$var$isSite7DaysOlder = (traits)=>{
        const confluenceActivation = traits.find((trait)=>trait.name === $f2be65df141d6690$export$79555f8f9058b0b6);
        if (confluenceActivation?.value) {
            const oneWeekLaterActivation = new Date(confluenceActivation?.value);
            oneWeekLaterActivation.setDate(oneWeekLaterActivation.getDate() + 7);
            const today = new Date();
            return today >= oneWeekLaterActivation;
        }
        return false;
    };
});
parcelRegister("jX0pr", function(module, exports) {
    $parcel$export(module.exports, "memoizedFetchSitePersonalization", ()=>$7b32fa3ce5d65e32$export$671fb03be30194c3);
    $parcel$export(module.exports, "useSitePersonalization", ()=>$7b32fa3ce5d65e32$export$58982988039b926f);
    var $8QpSo;
    var $ld2hZ;
    var $7f4tk;
    const $7b32fa3ce5d65e32$var$getPersonalizationSiteTraitsUrl = (cloudId)=>`/gateway/api/tap-delivery/api/v3/personalization/site/${cloudId}`;
    const $7b32fa3ce5d65e32$export$ddf4964d2a406b6a = async ({ cloudId: cloudId })=>{
        if (!cloudId) throw new Error('empty cloudId');
        const { attributes: attributes } = await (0, (0, parcelRequire("7f4tk")).default)($7b32fa3ce5d65e32$var$getPersonalizationSiteTraitsUrl(cloudId), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-atlassian-mau-ignore': 'true'
            }
        });
        return attributes;
    };
    const $7b32fa3ce5d65e32$export$671fb03be30194c3 = (0, (0, parcelRequire("8QpSo")).default)($7b32fa3ce5d65e32$export$ddf4964d2a406b6a, ([{ cloudId: newCloudId }], [{ cloudId: lastCloudId }])=>newCloudId === lastCloudId);
    const $7b32fa3ce5d65e32$export$58982988039b926f = ()=>(0, (0, parcelRequire("ld2hZ")).useService)($7b32fa3ce5d65e32$export$671fb03be30194c3);
});
parcelRegister("25NDO", function(module, exports) {
    $parcel$export(module.exports, "useCollaborators", ()=>$e74b148a91bec82c$export$1ccc4f4de0326402);
    var $5uXOq = parcelRequire("5uXOq");
    var $jcw0u;
    var $koeKL;
    var $dxdji;
    var $62RUt;
    const $e74b148a91bec82c$export$1ccc4f4de0326402 = (isEnrolled)=>{
        const [collaborators, setCollaborators] = (0, $5uXOq.useState)([]);
        const [isLoading, setIsLoading] = (0, $5uXOq.useState)(true);
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const getCollaborators = (0, (0, parcelRequire("dxdji")).useUsersFromCollaborationGraph)(cloudId);
        (0, $5uXOq.useEffect)(()=>{
            const fetch = async ()=>{
                try {
                    const users = await getCollaborators();
                    if (!!users && users.length !== 0) {
                        const confluenceUsers = await (0, (0, parcelRequire("62RUt")).getConfluenceUsers)(users, cloudId);
                        setCollaborators(confluenceUsers);
                    }
                } catch (error) {
                    (0, (0, parcelRequire("jcw0u")).default)({
                        meta: {
                            id: 'useCollaborators',
                            packageName: 'jiraCrossJoinNudges'
                        },
                        error: error,
                        sendToPrivacyUnsafeSplunk: true
                    });
                } finally{
                    setIsLoading(false);
                }
            };
            if (isEnrolled) fetch();
        }, [
            cloudId,
            getCollaborators,
            isEnrolled
        ]);
        return (0, $5uXOq.useMemo)(()=>({
                collaborators: collaborators,
                isLoading: isLoading
            }), [
            collaborators,
            isLoading
        ]);
    };
});
parcelRegister("iu3ma", function(module, exports) {
    $parcel$export(module.exports, "useVisitedCount", ()=>$4e26577f827b3885$export$fb7c0ffa45d7cd29);
    var $5uXOq = parcelRequire("5uXOq");
    var $1e3dw;
    const $4e26577f827b3885$export$fb7c0ffa45d7cd29 = (isEnrolled, key)=>{
        const [visitedCount, setVisitedCount] = (0, $5uXOq.useState)(0);
        const [isLoading, setIsLoading] = (0, $5uXOq.useState)(true);
        const { getVisitedCount: getVisitedCount } = (0, (0, parcelRequire("1e3dw")).useSnooze)(key);
        (0, $5uXOq.useEffect)(()=>{
            const fetch = async ()=>{
                const count = await getVisitedCount();
                setVisitedCount(count);
                setIsLoading(false);
            };
            if (isEnrolled) fetch();
        }, [
            getVisitedCount,
            isEnrolled
        ]);
        return (0, $5uXOq.useMemo)(()=>({
                visitedCount: visitedCount,
                isLoading: isLoading
            }), [
            visitedCount,
            isLoading
        ]);
    };
});
parcelRegister("fVgtE", function(module, exports) {
    $parcel$export(module.exports, "useRequestAccessErrorFlag", ()=>$c964d6607e689cdd$export$d8e2d94eec4efe85);
    var $5uXOq = parcelRequire("5uXOq");
    var $fHfPB;
    var $3KRpp;
    var $pa9q9;
    var $koVbs;
    var $dcbMS;
    var $jhRnv;
    const $c964d6607e689cdd$export$d8e2d94eec4efe85 = ()=>{
        const { showFlag: showFlag, dismissFlag: dismissFlag } = (0, (0, parcelRequire("pa9q9")).useFlagService)();
        return (0, $5uXOq.useCallback)(()=>{
            const id = `error-${(0, (0, parcelRequire("dcbMS")).generateId)()}`;
            const dismiss = ()=>dismissFlag((0, (0, parcelRequire("fHfPB")).dismissFlag)(id));
            showFlag({
                id: id,
                render: (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement($c964d6607e689cdd$export$e1d0d64874873c8b, {
                        ...props,
                        id: id,
                        onDismiss: dismiss
                    })
            });
        }, [
            dismissFlag,
            showFlag
        ]);
    };
    const $c964d6607e689cdd$var$JIRA_SUPPORT_LINK = 'https://support.atlassian.com/contact/';
    const $c964d6607e689cdd$export$e1d0d64874873c8b = ({ onDismiss: onDismiss, ...props })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3KRpp")).ErrorFlag), {
            ...props,
            onDismissed: onDismiss,
            title: formatMessage((0, (0, parcelRequire("jhRnv")).messages).title),
            description: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement("p", null, formatMessage((0, (0, parcelRequire("jhRnv")).messages).description), (0, ($parcel$interopDefault($5uXOq))).createElement("a", {
                href: $c964d6607e689cdd$var$JIRA_SUPPORT_LINK
            }, formatMessage((0, (0, parcelRequire("jhRnv")).messages).action)), "."))
        });
    };
});
parcelRegister("3KRpp", function(module, exports) {
    $parcel$export(module.exports, "CommonFlag", ()=>$ef767762d5402ccf$export$13d73238a031436b);
    $parcel$export(module.exports, "ErrorFlag", ()=>$ef767762d5402ccf$export$518ba8e0e60f145f);
    $parcel$export(module.exports, "ErrorAutoDismissFlag", ()=>$ef767762d5402ccf$export$3f75672bb90a4431);
    $parcel$export(module.exports, "InfoFlag", ()=>$ef767762d5402ccf$export$6a55b52e74c1e48e);
    $parcel$export(module.exports, "SuccessAutoDismissFlag", ()=>$ef767762d5402ccf$export$74a3c0748aa31355);
    $parcel$export(module.exports, "SuccessFlag", ()=>$ef767762d5402ccf$export$93bd4963a1d00fd5);
    $parcel$export(module.exports, "WarningFlag", ()=>$ef767762d5402ccf$export$23695d8281998f27);
    $parcel$export(module.exports, "FlagGroup", ()=>$ef767762d5402ccf$export$a2c3479950310d7d);
    var $5uXOq = parcelRequire("5uXOq");
    var $1eg3p;
    var $kxZPA;
    const $ef767762d5402ccf$var$ForPaintJiraCommonFlag = (0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>(parcelRequire("lKKug")).then(({ JiraCommonFlag: JiraCommonFlag })=>JiraCommonFlag), {
        ssr: false,
        moduleId: "./src/packages/platform/ui/flags/src/common/ui/components/common-flag-v2/index.tsx"
    });
    const $ef767762d5402ccf$var$AfterPaintJiraCommonFlag = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("lKKug")).then(({ JiraCommonFlag: JiraCommonFlag })=>JiraCommonFlag), {
        ssr: false,
        moduleId: "./src/packages/platform/ui/flags/src/common/ui/components/common-flag-v2/index.tsx"
    });
    const $ef767762d5402ccf$var$CommonFlagImpl = (props)=>__SPA__ ? (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$AfterPaintJiraCommonFlag, props) : (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$ForPaintJiraCommonFlag, props);
    const $ef767762d5402ccf$var$ForPaintFlagGroup = (0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>(parcelRequire("fhw5b")), {
        ssr: false,
        moduleId: "./src/packages/platform/ui/flags/src/common/ui/components/group-flag-v2/index.tsx"
    });
    const $ef767762d5402ccf$var$AfterPaintFlagGroup = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("fhw5b")), {
        ssr: false,
        moduleId: "./src/packages/platform/ui/flags/src/common/ui/components/group-flag-v2/index.tsx"
    });
    const $ef767762d5402ccf$var$FlagGroupImpl = (props)=>__SPA__ ? (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$AfterPaintFlagGroup, props) : (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$ForPaintFlagGroup, props);
    const $ef767762d5402ccf$export$13d73238a031436b = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$CommonFlagImpl, props));
    const $ef767762d5402ccf$export$518ba8e0e60f145f = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$CommonFlagImpl, {
            flagType: "error",
            ...props
        }));
    const $ef767762d5402ccf$export$3f75672bb90a4431 = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$CommonFlagImpl, {
            flagType: "error",
            isAutoDismiss: true,
            ...props
        }));
    const $ef767762d5402ccf$export$6a55b52e74c1e48e = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$CommonFlagImpl, {
            flagType: "info",
            ...props
        }));
    const $ef767762d5402ccf$export$74a3c0748aa31355 = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$CommonFlagImpl, {
            flagType: "success",
            isAutoDismiss: true,
            ...props
        }));
    const $ef767762d5402ccf$export$93bd4963a1d00fd5 = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$CommonFlagImpl, {
            flagType: "success",
            ...props
        }));
    const $ef767762d5402ccf$export$23695d8281998f27 = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$CommonFlagImpl, {
            flagType: "warning",
            ...props
        }));
    const $ef767762d5402ccf$export$a2c3479950310d7d = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$FlagGroupImpl, props));
});
parcelRegister("lKKug", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("hPzOn"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("4yB9l")
    ]).then(()=>parcelRequire('8ZHDV'));
});
parcelRegister("fhw5b", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("Fkef4").then(()=>parcelRequire('5pz6a'));
});
parcelRegister("dcbMS", function(module, exports) {
    $parcel$export(module.exports, "generateId", ()=>$390ca3140b8da28e$export$567fc7097e064344);
    let $390ca3140b8da28e$var$id = 0;
    const $390ca3140b8da28e$export$567fc7097e064344 = ()=>{
        $390ca3140b8da28e$var$id += 1;
        return String($390ca3140b8da28e$var$id);
    };
});
parcelRegister("jhRnv", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$1a71831a465b380b$export$defe85febe8b728c);
    var $7VHMR;
    const $1a71831a465b380b$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "cross-join-nudges.request-access-error-flag.title",
            "defaultMessage": "We couldn't add you to Confluence"
        },
        description: {
            "id": "cross-join-nudges.request-access-error-flag.description",
            "defaultMessage": "Try again in a few minutes. If you still aren't able to join, "
        },
        action: {
            "id": "cross-join-nudges.request-access-error-flag.action",
            "defaultMessage": "contact support"
        }
    });
});
parcelRegister("eCsnX", function(module, exports) {
    $parcel$export(module.exports, "useRequestAccessPromptFlag", ()=>$3517fb24263f5c55$export$233a6a0aca8c9091);
    var $5uXOq = parcelRequire("5uXOq");
    var $jcw0u;
    var $fHfPB;
    var $pa9q9;
    var $8zOmE;
    var $2NMNM;
    var $90yGq;
    var $dcbMS;
    var $dCRY8;
    var $x9FGz;
    const $3517fb24263f5c55$export$233a6a0aca8c9091 = ()=>{
        const { showFlag: showFlag, dismissFlag: dismissFlag } = (0, (0, parcelRequire("pa9q9")).useFlagService)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const userId = (0, (0, parcelRequire("90yGq")).useAccountId)() || '';
        const FLAG_MESSAGE_TYPE = 'marketing';
        return (0, $5uXOq.useCallback)(({ users: users, onOptout: onOptout, onSubmit: onSubmit, snoozeFor30Days: snoozeFor30Days, isV2a: isV2a = false, sessionId: sessionId = '', featureVisitedCount: featureVisitedCount = 0 })=>{
            try {
                const id = `request-access-${(0, (0, parcelRequire("dcbMS")).generateId)()}`;
                const dismissTheFlag = ()=>dismissFlag((0, (0, parcelRequire("fHfPB")).dismissFlag)(id));
                const eventAttributes = {
                    collaborators: users.length,
                    userId: userId,
                    isV2a: isV2a,
                    featureVisitedCount: featureVisitedCount,
                    sessionId: sessionId
                };
                const submit = async ()=>{
                    (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'button clicked', 'crossJoinNudgesConfluence', eventAttributes);
                    dismissTheFlag();
                    await onSubmit();
                };
                const dismiss = ()=>{
                    (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'flag dismissed', 'crossJoinNudgesConfluence', eventAttributes);
                    dismissTheFlag();
                };
                const dismissAndOptout = ()=>{
                    dismiss();
                    (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'flag optedOut', 'crossJoinNudgesConfluence', eventAttributes);
                    onOptout();
                };
                const dismissAndSnooze = ()=>{
                    dismissTheFlag();
                    (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'flag snoozed', 'crossJoinNudgesConfluence', eventAttributes);
                    snoozeFor30Days();
                };
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'nudge viewed', 'crossJoinNudgesConfluence', eventAttributes);
                showFlag({
                    id: id,
                    render: (props)=>isV2a ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dCRY8")).ChoreographedRequestAccessPromptFlag), {
                            ...props,
                            id: id,
                            users: users,
                            onDismiss: dismiss,
                            onSnoozeAndDismiss: dismissAndSnooze,
                            onOptoutAndDismiss: dismissAndOptout,
                            onSubmit: submit,
                            featureVisitedCount: featureVisitedCount,
                            messageType: FLAG_MESSAGE_TYPE,
                            messageId: "growth.cross-join-nudges.request-access-prompt-flag.v2"
                        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("x9FGz")).ChoreographedRequestAccessPromptFlag), {
                            ...props,
                            id: id,
                            users: users,
                            onDismiss: dismissAndOptout,
                            onSubmit: submit,
                            messageType: FLAG_MESSAGE_TYPE,
                            messageId: "growth.cross-join-nudges.request-access-prompt-flag"
                        })
                });
            } catch (error) {
                (0, (0, parcelRequire("jcw0u")).default)({
                    meta: {
                        id: 'useRequestAccessPromptFlag',
                        packageName: 'jiraCrossJoinNudges'
                    },
                    error: error,
                    sendToPrivacyUnsafeSplunk: true
                });
                throw new Error('Show request access prompt flag error');
            }
        }, [
            createAnalyticsEvent,
            dismissFlag,
            showFlag,
            userId
        ]);
    };
});
parcelRegister("dCRY8", function(module, exports) {
    $parcel$export(module.exports, "ChoreographedRequestAccessPromptFlag", ()=>$cbbfed92083dbd3b$export$939bd64e3beccdb5);
    var $5uXOq = parcelRequire("5uXOq");
    var $10xFf;
    var $cSWNW;
    var $546sS;
    const $cbbfed92083dbd3b$export$ef75ccc59458e1ea = (0, (0, parcelRequire("cSWNW")).withChoreographedRender)((0, (0, parcelRequire("546sS")).RequestAccessPromptFlag));
    const $cbbfed92083dbd3b$export$939bd64e3beccdb5 = (props)=>{
        if ((0, (0, parcelRequire("10xFf")).shouldBeChoreographed)(props)) {
            const { messageType: messageType, ...rest } = props;
            return (0, ($parcel$interopDefault($5uXOq))).createElement($cbbfed92083dbd3b$export$ef75ccc59458e1ea, rest);
        }
        const { messageType: messageType, messageId: messageId, onMessageDisposition: onMessageDisposition, ...rest } = props;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("546sS")).RequestAccessPromptFlag), rest);
    };
});
parcelRegister("10xFf", function(module, exports) {
    $parcel$export(module.exports, "shouldBeChoreographed", ()=>$36201c75b4df20a7$export$a8ba9111c7eec9ac);
    $parcel$export(module.exports, "environmentToEnvType", ()=>$36201c75b4df20a7$export$e2cf19200849be9e);
    var $iZhen;
    var $t6tX9;
    function $36201c75b4df20a7$export$a8ba9111c7eec9ac(props) {
        return !!props?.messageType && (0, (0, parcelRequire("t6tX9")).IntegratedChoreographerMessageTypesEnum).includes(props.messageType);
    }
    function $36201c75b4df20a7$export$e2cf19200849be9e(environment) {
        switch(environment){
            case 'dev':
                return (0, (0, parcelRequire("iZhen")).envType).DEV;
            case 'staging':
                return (0, (0, parcelRequire("iZhen")).envType).STAGING;
            case 'prod':
                return (0, (0, parcelRequire("iZhen")).envType).PROD;
            default:
                return (0, (0, parcelRequire("iZhen")).envType).LOCAL;
        }
    }
});
parcelRegister("t6tX9", function(module, exports) {
    $parcel$export(module.exports, "IntegratedChoreographerMessageTypesEnum", ()=>$b5642a17fa90b00d$export$dbe9e38c9d6f26d0);
    const $b5642a17fa90b00d$export$94e85cf3348f045a = {
        ENGAGEMENT: 'engagement',
        MARKETING: 'marketing',
        TRANSACTIONAL: 'transactional'
    };
    const $b5642a17fa90b00d$export$dbe9e38c9d6f26d0 = [
        $b5642a17fa90b00d$export$94e85cf3348f045a.ENGAGEMENT,
        $b5642a17fa90b00d$export$94e85cf3348f045a.MARKETING
    ];
});
parcelRegister("cSWNW", function(module, exports) {
    $parcel$export(module.exports, "withChoreographedRender", ()=>$620c1a9f3fec1a68$export$c1160fdac17030e);
    var $5uXOq = parcelRequire("5uXOq");
    var $fG5AJ;
    function $620c1a9f3fec1a68$export$c1160fdac17030e(Component, options) {
        function ChoreographedComponent({ messageId: messageId, onMessageDisposition: onMessageDisposition, ...props }) {
            const shouldRender = (0, (0, parcelRequire("fG5AJ")).useChoreographedRender)({
                messageId: messageId,
                onMessageDisposition: onMessageDisposition
            });
            if (shouldRender) return (0, ($parcel$interopDefault($5uXOq))).createElement(Component, props);
            if (options && options.toggleableProp) {
                const toggleableComponentProps = {
                    ...props,
                    [options.toggleableProp]: false
                };
                return (0, ($parcel$interopDefault($5uXOq))).createElement(Component, toggleableComponentProps);
            }
            return null;
        }
        ChoreographedComponent.displayName = `Choreographed${Component.displayName ?? 'Component'}`;
        return ChoreographedComponent;
    }
});
parcelRegister("fG5AJ", function(module, exports) {
    $parcel$export(module.exports, "getPendoPlugin", ()=>$596f0fde3f3a2d5a$export$3d3a86b2d636214b);
    $parcel$export(module.exports, "useChoreographer", ()=>$596f0fde3f3a2d5a$export$9f0e2488ffd90f11);
    $parcel$export(module.exports, "ChoreographedStatusContext", ()=>$596f0fde3f3a2d5a$export$3ff96dbefca6b00d);
    $parcel$export(module.exports, "useChoreographedStatusContext", ()=>$596f0fde3f3a2d5a$export$2eb1efa814fe4a);
    $parcel$export(module.exports, "useChoreographedRender", ()=>$596f0fde3f3a2d5a$export$2cfd68294be82811);
    var $5uXOq = parcelRequire("5uXOq");
    var $4zICj;
    var $8r6xd;
    var $TTRxY;
    var $j8DrX;
    var $7qPn9;
    const $596f0fde3f3a2d5a$var$environment = (0, (0, parcelRequire("7qPn9")).toEnvironment)((0, (0, parcelRequire("j8DrX")).default)('ajs-environment')) ?? 'prod';
    const $596f0fde3f3a2d5a$var$createChoreographerFactory = ()=>{
        if (false) return {
            useChoreographer: (..._)=>({
                    startMessage: ()=>Promise.resolve(''),
                    stopMessage: ()=>{}
                })
        };
        return (0, (0, parcelRequire("4zICj")).choreographerFactory)((0, (0, parcelRequire("TTRxY")).ProductIds).JIRA, $596f0fde3f3a2d5a$var$environment);
    };
    let $596f0fde3f3a2d5a$var$pendoPlugin;
    function $596f0fde3f3a2d5a$export$3d3a86b2d636214b(env) {
        if (!$596f0fde3f3a2d5a$var$pendoPlugin) $596f0fde3f3a2d5a$var$pendoPlugin = new (0, (0, parcelRequire("8r6xd")).PendoChoreographerPlugin)(env, {
            sourceProductId: (0, (0, parcelRequire("TTRxY")).ProductIds).JIRA
        });
        return $596f0fde3f3a2d5a$var$pendoPlugin;
    }
    const { useChoreographer: $596f0fde3f3a2d5a$export$9f0e2488ffd90f11 } = $596f0fde3f3a2d5a$var$createChoreographerFactory();
    const $596f0fde3f3a2d5a$export$3ff96dbefca6b00d = (0, $5uXOq.createContext)({
        isAlreadyChoreographedAtAncestor: false
    });
    const $596f0fde3f3a2d5a$export$2eb1efa814fe4a = ()=>(0, $5uXOq.useContext)($596f0fde3f3a2d5a$export$3ff96dbefca6b00d);
    const $596f0fde3f3a2d5a$export$2cfd68294be82811 = ({ messageId: messageId, onMessageDisposition: onMessageDisposition })=>{
        const { isAlreadyChoreographedAtAncestor: isAlreadyChoreographedAtAncestor } = $596f0fde3f3a2d5a$export$2eb1efa814fe4a();
        const [shouldRender, setShouldRender] = (0, $5uXOq.useState)(false);
        const { startMessage: startMessage, stopMessage: stopMessage } = $596f0fde3f3a2d5a$export$9f0e2488ffd90f11(messageId, {
            start: ()=>setShouldRender(true)
        });
        (0, $5uXOq.useEffect)(()=>{
            if (!isAlreadyChoreographedAtAncestor) {
                const startMessageAsync = async ()=>{
                    const disposition = await startMessage();
                    onMessageDisposition?.(disposition);
                };
                startMessageAsync();
                return ()=>{
                    stopMessage();
                };
            }
        }, [
            onMessageDisposition,
            startMessage,
            stopMessage,
            isAlreadyChoreographedAtAncestor
        ]);
        return shouldRender || isAlreadyChoreographedAtAncestor;
    };
});
parcelRegister("546sS", function(module, exports) {
    $parcel$export(module.exports, "RequestAccessPromptFlag", ()=>$bedbdb3cad7f80fc$export$9b8813c3601640da);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $f5TWP;
    var $luBm6;
    var $3KRpp;
    var $koVbs;
    var $9Rp90;
    var $rrn66;
    var $2zDkN;
    const $bedbdb3cad7f80fc$export$9b8813c3601640da = ({ users: users, onSubmit: onSubmit, onDismiss: onDismiss, onOptoutAndDismiss: onOptoutAndDismiss, onSnoozeAndDismiss: onSnoozeAndDismiss, featureVisitedCount: featureVisitedCount, ...props })=>{
        const handleOnSubmit = (0, $5uXOq.useCallback)(async ()=>{
            await onSubmit();
        }, [
            onSubmit
        ]);
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const customFlagFooterProps = (0, $5uXOq.useMemo)(()=>({
                actions: [
                    {
                        content: '',
                        onClick: (0, (0, parcelRequire("2zDkN")).noop)
                    }
                ],
                linkComponent: ()=>{
                    let secondETA = null;
                    if (featureVisitedCount === 1) secondETA = (0, ($parcel$interopDefault($5uXOq))).createElement($bedbdb3cad7f80fc$var$LinkButton, {
                        onClick: onSnoozeAndDismiss
                    }, formatMessage((0, (0, parcelRequire("rrn66")).messages).remindMeLaterButton));
                    else if (featureVisitedCount > 1) secondETA = (0, ($parcel$interopDefault($5uXOq))).createElement($bedbdb3cad7f80fc$var$LinkButton, {
                        onClick: onOptoutAndDismiss
                    }, formatMessage((0, (0, parcelRequire("rrn66")).messages).notInterestedButton));
                    return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
                        alignInline: "start",
                        grow: "fill",
                        alignBlock: "center"
                    }, (0, ($parcel$interopDefault($5uXOq))).createElement($bedbdb3cad7f80fc$var$LinkButton, {
                        onClick: handleOnSubmit
                    }, formatMessage((0, (0, parcelRequire("rrn66")).messages).joinButton)), !!secondETA && (0, ($parcel$interopDefault($5uXOq))).createElement($bedbdb3cad7f80fc$var$LinkButtonSeparator, null), secondETA);
                }
            }), [
            featureVisitedCount,
            handleOnSubmit,
            formatMessage,
            onOptoutAndDismiss,
            onSnoozeAndDismiss
        ]);
        const customIcon = (0, $5uXOq.useMemo)(()=>{
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9Rp90")).FlagCompatibleAvatarGroup), {
                alignment: "vertical",
                data: users.map((user)=>({
                        name: user.name,
                        src: user.avatar,
                        appearance: 'circle',
                        size: 'medium'
                    })),
                boundariesElement: "scrollParent"
            });
        }, [
            users
        ]);
        const infoFlagTitle = formatMessage((0, (0, parcelRequire("rrn66")).messages).urgencyTitle, {
            viewCount: featureVisitedCount
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3KRpp")).InfoFlag), {
            ...props,
            ...customFlagFooterProps,
            onDismissed: featureVisitedCount === 0 ? onDismiss : onSnoozeAndDismiss,
            title: infoFlagTitle,
            description: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("luBm6")).default), {
                space: "space.100"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement($bedbdb3cad7f80fc$var$MainContentContainer, null, formatMessage((0, (0, parcelRequire("rrn66")).messages).description))),
            icon: customIcon
        });
    };
    const $bedbdb3cad7f80fc$var$MainContentContainer = (0, $5uXOq.forwardRef)(({ as: C = "p", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0ccj1k _1yyj11wp _sudp1e54 _1reoewfl _18m9ewfl _19pkidpf _2hwxidpf _otyridpf _18u0idpf _otyru2gc",
                __cmplp.className
            ])
        });
    });
    const $bedbdb3cad7f80fc$var$LinkButton = (0, $5uXOq.forwardRef)(({ as: C = "button", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_p12f1w81 _c71loyo4 _1reo15vq _18m915vq _syaz14q2 _bfhk1kw7 _11c81oud _189eidpf _k48p1wq8 _80omtlke _ca0qidpf _u5f3idpf _n3tdidpf _19bvidpf _9oik18uv _1bnx8stv _jf4cnqa1",
                __cmplp.className
            ])
        });
    });
    const $bedbdb3cad7f80fc$var$LinkButtonSeparator = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1kt95qml _fntnutpp _eid3utpp",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("9Rp90", function(module, exports) {
    $parcel$export(module.exports, "FlagCompatibleAvatarGroup", ()=>$28afe71c94c1415f$export$29beada2d5f93289);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $jnhNB;
    var $2zDkN;
    const $28afe71c94c1415f$var$MAX_AVATARS_FEATURED_HORIZONTAL = 4;
    const $28afe71c94c1415f$var$MAX_AVATARS_FEATURED_VERTICAL = 2;
    const $28afe71c94c1415f$var$MAX_OVERFLOW_USER_COUNT_DISPLAYED = 99;
    const $28afe71c94c1415f$var$OVERFLOW_USER_MARKER = '$$$overflow-user-marker';
    const $28afe71c94c1415f$export$29beada2d5f93289 = ({ alignment: alignment = 'horizontal', data: data, ...props })=>{
        const maxAvatarCount = alignment === 'horizontal' ? $28afe71c94c1415f$var$MAX_AVATARS_FEATURED_HORIZONTAL : $28afe71c94c1415f$var$MAX_AVATARS_FEATURED_VERTICAL;
        const tooltipPosition = alignment === 'horizontal' ? 'top' : 'right';
        const { data: customData, overrides: customOverrides } = (0, $5uXOq.useMemo)(()=>$28afe71c94c1415f$export$bc55c1ff86d63c4d({
                data: data,
                maxAvatarCount: maxAvatarCount
            }), [
            data,
            maxAvatarCount
        ]);
        const avatarGroup = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jnhNB")).default), {
            maxCount: maxAvatarCount,
            data: customData,
            overrides: customOverrides,
            boundariesElement: "scrollParent",
            tooltipPosition: tooltipPosition,
            onMoreClick: (0, (0, parcelRequire("2zDkN")).noop),
            ...props
        });
        if (alignment === 'vertical') return (0, ($parcel$interopDefault($5uXOq))).createElement($28afe71c94c1415f$var$VerticalAvatarGroupContainer, null, avatarGroup);
        return avatarGroup;
    };
    const $28afe71c94c1415f$export$bc55c1ff86d63c4d = ({ data: users, maxAvatarCount: maxAvatarCount = $28afe71c94c1415f$var$MAX_AVATARS_FEATURED_HORIZONTAL })=>{
        const overflows = users.length > maxAvatarCount;
        const lastFeaturedUser = maxAvatarCount - 1;
        const featuredUsers = users.slice(0, lastFeaturedUser);
        const overflowUsers = users.slice(lastFeaturedUser);
        const overflowMarkerDummyUser = $28afe71c94c1415f$export$1b8d8867a2d1ffbd(overflowUsers);
        const processedUsers = overflows ? [
            ...featuredUsers,
            overflowMarkerDummyUser
        ] : users;
        const overrides = {
            Avatar: {
                render: (AvatarComponent, avatarProps)=>{
                    if (avatarProps.name === overflowMarkerDummyUser.name && avatarProps.key === overflowMarkerDummyUser.key) return $28afe71c94c1415f$var$renderAvatarWithOverflowCount(AvatarComponent, avatarProps, overflowUsers.length);
                    return (0, ($parcel$interopDefault($5uXOq))).createElement(AvatarComponent, avatarProps);
                }
            }
        };
        return {
            overrides: overrides,
            data: processedUsers
        };
    };
    const $28afe71c94c1415f$export$1b8d8867a2d1ffbd = (overflowUsers)=>({
            name: overflowUsers.map((u)=>u.name).join(', '),
            key: $28afe71c94c1415f$var$OVERFLOW_USER_MARKER
        });
    const $28afe71c94c1415f$var$renderAvatarWithOverflowCount = (AvatarComponent, avatarProps, overflowCount)=>(0, ($parcel$interopDefault($5uXOq))).createElement(AvatarComponent, {
            name: avatarProps.name,
            appearance: avatarProps.appearance,
            size: avatarProps.size,
            borderColor: avatarProps.borderColor,
            stackIndex: 0
        }, (avatarChildrenProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement($28afe71c94c1415f$var$AvatarOverflowCountContainer, avatarChildrenProps, `+${Math.min(overflowCount, $28afe71c94c1415f$var$MAX_OVERFLOW_USER_COUNT_DISPLAYED)}`));
    const $28afe71c94c1415f$var$AvatarOverflowCountContainer = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_bfhkj6p2 _syaz1ktl _y3gn1s23 _k48p124r",
                __cmplp.className
            ])
        });
    });
    const $28afe71c94c1415f$var$VerticalAvatarGroupContainer = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_ogvv1bp4 _3mc9idpf _1hlaidpf _1qu0x0bf",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("2zDkN", function(module, exports) {
    $parcel$export(module.exports, "noop", ()=>$8dec5faa18776ff2$export$8793edee2d425525);
    const $8dec5faa18776ff2$export$8793edee2d425525 = ()=>{};
});
parcelRegister("rrn66", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$7f6fd96fbb5dfa11$export$defe85febe8b728c);
    var $7VHMR;
    const $7f6fd96fbb5dfa11$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.title",
            "defaultMessage": "Your team is now using Confluence"
        },
        titleNudgeCandidate01: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.title-nudge-candidate-01",
            "defaultMessage": "Join your teammates in Confluence"
        },
        titleNudgeCandidate02: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.title-nudge-candidate-02",
            "defaultMessage": "Collaborate together in Confluence"
        },
        titleNudgeCandidate03: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.title-nudge-candidate-03",
            "defaultMessage": "Your team is working in Confluence"
        },
        titleNudgeCandidate04: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.title-nudge-candidate-04",
            "defaultMessage": "Unify your projects in Confluence"
        },
        titleNudgeCandidate05: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.title-nudge-candidate-05",
            "defaultMessage": "Bring projects together in Confluence"
        },
        titleNudgeCandidate06: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.title-nudge-candidate-06",
            "defaultMessage": "Create a single source of truth"
        },
        titleNudgeCandidate07: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.title-nudge-candidate-07",
            "defaultMessage": "Create a single source of truth"
        },
        description: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.description",
            "defaultMessage": "Organize team knowledge, capture ideas, and share requirements for your Jira projects."
        },
        descriptionNudgeCandidate01: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.description-nudge-candidate-01",
            "defaultMessage": "Create a single source of truth for project decisions and software documentation."
        },
        descriptionNudgeCandidate02: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.description-nudge-candidate-02",
            "defaultMessage": "Capture, consolidate, and share team knowledge, in one centralized place."
        },
        descriptionNudgeCandidate03: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.description-nudge-candidate-03",
            "defaultMessage": "Create project pages to build a shared understanding for better decision-making."
        },
        descriptionNudgeCandidate04: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.description-nudge-candidate-04",
            "defaultMessage": "Organize project information and sync it to Jira tasks to track and hit your goals faster."
        },
        descriptionNudgeCandidate05: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.description-nudge-candidate-05",
            "defaultMessage": "Capture information in project pages and sync to Jira tasks to track your milestones."
        },
        descriptionNudgeCandidate06: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.description-nudge-candidate-06",
            "defaultMessage": "Consolidate information in one place and sync to Jira tasks for automatic updates."
        },
        descriptionNudgeCandidate07: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.description-nudge-candidate-07",
            "defaultMessage": "Join your team in Confluence to consolidate project information in one place."
        },
        joinButton: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.join-button",
            "defaultMessage": "Join your team"
        },
        joinButtonNudgeCandidate01: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.join-button-nudge-candidate-01",
            "defaultMessage": "Join your team"
        },
        joinButtonNudgeCandidate02: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.join-button-nudge-candidate-02",
            "defaultMessage": "Join your team"
        },
        joinButtonNudgeCandidate03: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.join-button-nudge-candidate-03",
            "defaultMessage": "Join your team"
        },
        joinButtonNudgeCandidate04: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.join-button-nudge-candidate-04",
            "defaultMessage": "Join Confluence"
        },
        joinButtonNudgeCandidate05: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.join-button-nudge-candidate-05",
            "defaultMessage": "Join Confluence"
        },
        joinButtonNudgeCandidate06: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.join-button-nudge-candidate-06",
            "defaultMessage": "Join Confluence"
        },
        joinButtonNudgeCandidate07: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.join-button-nudge-candidate-07",
            "defaultMessage": "Join Confluence"
        },
        remindMeLaterButton: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.remind-me-later-button",
            "defaultMessage": "Remind me later"
        },
        notInterestedButton: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.not-interested-button",
            "defaultMessage": "Not interested"
        },
        urgencyTitle: {
            "id": "cross-join-nudges.common.request-access-prompt-flag.urgency-title",
            "defaultMessage": "{viewCount, select, 0 {Your team is now using Confluence} 1 {Your team is working in Confluence} other {Your team is waiting for you in Confluence}}"
        }
    });
});
parcelRegister("x9FGz", function(module, exports) {
    $parcel$export(module.exports, "ChoreographedRequestAccessPromptFlag", ()=>$811504fd4ff77b3a$export$939bd64e3beccdb5);
    var $5uXOq = parcelRequire("5uXOq");
    var $10xFf;
    var $cSWNW;
    var $8Wm3b;
    const $811504fd4ff77b3a$export$ef75ccc59458e1ea = (0, (0, parcelRequire("cSWNW")).withChoreographedRender)((0, (0, parcelRequire("8Wm3b")).RequestAccessPromptFlag));
    const $811504fd4ff77b3a$export$939bd64e3beccdb5 = (props)=>{
        if ((0, (0, parcelRequire("10xFf")).shouldBeChoreographed)(props)) {
            const { messageType: messageType, ...rest } = props;
            return (0, ($parcel$interopDefault($5uXOq))).createElement($811504fd4ff77b3a$export$ef75ccc59458e1ea, rest);
        }
        const { messageType: messageType, messageId: messageId, onMessageDisposition: onMessageDisposition, ...rest } = props;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8Wm3b")).RequestAccessPromptFlag), rest);
    };
});
parcelRegister("8Wm3b", function(module, exports) {
    $parcel$export(module.exports, "RequestAccessPromptFlag", ()=>$6be4c8f121a608b8$export$9b8813c3601640da);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $2hXfb;
    var $f5TWP;
    var $luBm6;
    var $3KRpp;
    var $koVbs;
    var $9Rp90;
    var $rrn66;
    var $2zDkN;
    const $6be4c8f121a608b8$export$9b8813c3601640da = ({ users: users, onSubmit: onSubmit, onDismiss: onDismiss, ...props })=>{
        const handleOnSubmit = (0, $5uXOq.useCallback)(async ()=>{
            await onSubmit();
        }, [
            onSubmit
        ]);
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const customFlagFooterProps = (0, $5uXOq.useMemo)(()=>({
                actions: [
                    {
                        content: '',
                        onClick: (0, (0, parcelRequire("2zDkN")).noop)
                    }
                ],
                linkComponent: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
                        alignInline: "end",
                        grow: "fill",
                        spread: "space-between",
                        alignBlock: "center"
                    }, (0, ($parcel$interopDefault($5uXOq))).createElement($6be4c8f121a608b8$var$LinkButton, {
                        onClick: handleOnSubmit
                    }, formatMessage((0, (0, parcelRequire("rrn66")).messages).joinButton)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9Rp90")).FlagCompatibleAvatarGroup), {
                        alignment: "horizontal",
                        data: users.map((user)=>({
                                name: user.name,
                                src: user.avatar,
                                appearance: 'circle',
                                size: 'medium'
                            })),
                        boundariesElement: "scrollParent"
                    }))
            }), [
            handleOnSubmit,
            formatMessage,
            users
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3KRpp")).InfoFlag), {
            ...props,
            ...customFlagFooterProps,
            onDismissed: onDismiss,
            title: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, formatMessage((0, (0, parcelRequire("rrn66")).messages).title, {
                relevantUser: 'Sam Smith'
            })),
            description: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("luBm6")).default), {
                space: "space.100"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement($6be4c8f121a608b8$var$MainContentContainer, null, formatMessage((0, (0, parcelRequire("rrn66")).messages).description))),
            icon: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("2hXfb"))))), {
                primaryColor: `var(--ds-icon-information, ${(0, $829f609a65d26a98$export$e62e8919cca44969)})`,
                label: "Info"
            })
        });
    };
    const $6be4c8f121a608b8$var$MainContentContainer = (0, $5uXOq.forwardRef)(({ as: C = "p", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0ccj1k _1yyj11wp _sudp1e54 _1reoewfl _18m9ewfl _19pkidpf _2hwxidpf _otyridpf _18u0idpf _otyru2gc",
                __cmplp.className
            ])
        });
    });
    const $6be4c8f121a608b8$var$LinkButton = (0, $5uXOq.forwardRef)(({ as: C = "button", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_syaz14q2 _bfhk1kw7 _11c81oud _ca0qidpf _u5f3idpf _n3tdidpf _19bvidpf _189eidpf _k48p1wq8 _80omtlke _9oik18uv _1bnx8stv _jf4cnqa1",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("4kGsO", function(module, exports) {
    $parcel$export(module.exports, "useRequestAccessSuccessFlag", ()=>$4a287e4fddaeb389$export$184b02cbba392fc0);
    var $5uXOq = parcelRequire("5uXOq");
    var $fHfPB;
    var $3KRpp;
    var $pa9q9;
    var $koVbs;
    var $dcbMS;
    var $dJlGU;
    const $4a287e4fddaeb389$export$184b02cbba392fc0 = ()=>{
        const { showFlag: showFlag, dismissFlag: dismissFlag } = (0, (0, parcelRequire("pa9q9")).useFlagService)();
        return (0, $5uXOq.useCallback)(()=>{
            const id = `success-${(0, (0, parcelRequire("dcbMS")).generateId)()}`;
            const dismiss = ()=>dismissFlag((0, (0, parcelRequire("fHfPB")).dismissFlag)(id));
            showFlag({
                id: id,
                render: (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement($4a287e4fddaeb389$export$567128fc1024c9cf, {
                        ...props,
                        id: id,
                        onDismiss: dismiss
                    })
            });
        }, [
            dismissFlag,
            showFlag
        ]);
    };
    const $4a287e4fddaeb389$export$567128fc1024c9cf = ({ onDismiss: onDismiss, ...props })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3KRpp")).SuccessFlag), {
            ...props,
            onDismissed: onDismiss,
            title: formatMessage((0, (0, parcelRequire("dJlGU")).messages).title),
            description: (0, ($parcel$interopDefault($5uXOq))).createElement("p", null, formatMessage((0, (0, parcelRequire("dJlGU")).messages).description))
        });
    };
});
parcelRegister("dJlGU", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$49198865101fe328$export$defe85febe8b728c);
    var $7VHMR;
    const $49198865101fe328$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "cross-join-nudges.request-access-success-flag.title",
            "defaultMessage": "A request was sent to your admins"
        },
        description: {
            "id": "cross-join-nudges.request-access-success-flag.description",
            "defaultMessage": "You'll receive an email once they've reviewed it."
        }
    });
});
parcelRegister("fmenl", function(module, exports) {
    $parcel$export(module.exports, "ErrorBoundary", ()=>$14caa3dc0d5e6e39$export$e926676385687eaf);
    var $5uXOq = parcelRequire("5uXOq");
    var $heNNl;
    var $jcw0u;
    const $14caa3dc0d5e6e39$export$e926676385687eaf = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("heNNl")).default), {
            onError: (_, error)=>{
                (0, (0, parcelRequire("jcw0u")).default)({
                    meta: {
                        id: 'cross-join-nudges-your-work-error-boundary'
                    },
                    error: error
                });
            }
        }, children);
});
parcelRegister("khNyR", function(module, exports) {
    $parcel$export(module.exports, "CrossJoinNudgeYourWorkCard", ()=>$910a9b371bd7ff78$export$7ac60ccf67d54f65);
    var $5uXOq = parcelRequire("5uXOq");
    var $jnhNB;
    var $eCeGD;
    var $dC5iT;
    var $f5TWP;
    var $8CUq3;
    var $5aHb0;
    var $fTfAU = parcelRequire("fTfAU");
    var $eci4O = parcelRequire("eci4O");
    var $g8hgP = parcelRequire("g8hgP");
    var $8Ahxt = parcelRequire("8Ahxt");
    var $juXaV = parcelRequire("juXaV");
    var $ousew;
    var $koVbs;
    var $kQvK1;
    const $910a9b371bd7ff78$var$Actions = ({ onSubmit: onSubmit, onSnooze: onSnooze, onOptOut: onOptOut, collaborators: collaborators })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
            space: "space.050",
            alignBlock: "center"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eCeGD")).default), {
            onClick: onSubmit
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), {
            ...(0, (0, parcelRequire("kQvK1")).messages).joinYourTeam,
            values: {
                collaborators: collaborators.length,
                collaborator: collaborators[0]?.name.split(' ')[0]
            }
        })), onSnooze && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eCeGD")).default), {
            appearance: "subtle",
            onClick: onSnooze
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), (0, (0, parcelRequire("kQvK1")).messages).remindMeLater)), onOptOut && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eCeGD")).default), {
            appearance: "subtle",
            onClick: onOptOut
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), (0, (0, parcelRequire("kQvK1")).messages).notInterested)));
    const $910a9b371bd7ff78$var$Avatars = ({ collaborators: collaborators })=>{
        const data = [
            (0, ($parcel$interopDefault($8Ahxt))),
            (0, ($parcel$interopDefault($fTfAU))),
            (0, ($parcel$interopDefault($eci4O))),
            (0, ($parcel$interopDefault($g8hgP)))
        ].map((src)=>({
                name: '',
                src: src
            }));
        for (const [i, user] of collaborators.entries())data[i] = {
            name: user.name,
            src: user.avatar
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $910a9b371bd7ff78$var$AvatarWrapper
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jnhNB")).default), {
            appearance: "stack",
            data: data,
            maxCount: 4,
            size: "large",
            borderColor: "white",
            testId: "cross-join-nudges.ui.your-work-widget.ui"
        }));
    };
    const $910a9b371bd7ff78$export$7ac60ccf67d54f65 = (0, $5uXOq.memo)(({ onRender: onRender, collaborators: collaborators, onDismiss: onDismiss, ...actionsProps })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        onRender();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $910a9b371bd7ff78$var$Wrapper
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($910a9b371bd7ff78$var$Avatars, {
            collaborators: collaborators
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5aHb0")).PromotionalCard), {
            header: formatMessage((0, (0, parcelRequire("kQvK1")).messages).header, {
                collaborators: collaborators.length,
                collaborator: collaborators[0]?.name
            }),
            imageUrl: (0, ($parcel$interopDefault($juXaV))),
            imageAltText: formatMessage((0, (0, parcelRequire("kQvK1")).messages).imageAltText),
            description: formatMessage((0, (0, parcelRequire("kQvK1")).messages).description),
            primaryAction: (0, ($parcel$interopDefault($5uXOq))).createElement($910a9b371bd7ff78$var$Actions, {
                ...actionsProps,
                collaborators: collaborators
            }),
            reason: formatMessage((0, (0, parcelRequire("kQvK1")).messages).tooltip),
            analyticsSource: "cross-join-nudges-your-work",
            onDismiss: onDismiss
        }));
    });
    const $910a9b371bd7ff78$var$AvatarWrapper = (0, (0, parcelRequire("8CUq3")).xcss)({
        position: 'absolute',
        zIndex: 'card',
        left: '144px',
        top: '35px'
    });
    const $910a9b371bd7ff78$var$Wrapper = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'inline-block',
        position: 'relative'
    });
});
parcelRegister("5aHb0", function(module, exports) {
    $parcel$export(module.exports, "PromotionalCard", ()=>$e71e2c86ce67b28e$export$418393e556a23457);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    var $2rqOp;
    var $1dJ6S;
    var $lLLkM;
    var $kkuz9;
    var $3bDjY;
    var $koVbs;
    var $iXVV6;
    var $5oFIy;
    var $8zOmE;
    var $bVZ71;
    const $e71e2c86ce67b28e$export$418393e556a23457 = ({ imageUrl: imageUrl, imageAltText: imageAltText, header: header, description: description, primaryAction: primaryAction, reason: reason, analyticsSource: analyticsSource, isDismissLoading: isDismissLoading, onDismiss: onDismiss })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceType: (0, $4d5e0871c06cee03$export$cae2783c9279926),
            sourceName: analyticsSource
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($e71e2c86ce67b28e$var$Wrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement($e71e2c86ce67b28e$var$ButtonWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2rqOp")).default), {
            appearance: "subtle",
            spacing: "none",
            isLoading: isDismissLoading,
            "aria-label": formatMessage((0, (0, parcelRequire("bVZ71")).default).dismiss),
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("lLLkM"))))), {
                size: "medium",
                label: "",
                primaryColor: `var(--ds-icon-warning-inverse, ${(0, $829f609a65d26a98$exports).N800})`
            }),
            onClick: (e, analyticsEvent)=>{
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, `${analyticsSource}Dismiss`);
                onDismiss();
            }
        })), imageUrl ? (0, ($parcel$interopDefault($5uXOq))).createElement($e71e2c86ce67b28e$var$ImageContainer, {
            imageUrl: imageUrl
        }, imageAltText ? (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            role: "img",
            "aria-label": imageAltText
        }) : null) : null, (0, ($parcel$interopDefault($5uXOq))).createElement($e71e2c86ce67b28e$var$Content, null, (0, ($parcel$interopDefault($5uXOq))).createElement($e71e2c86ce67b28e$var$Header, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1dJ6S")).default), {
            size: "medium",
            as: "h2"
        }, header)), (0, ($parcel$interopDefault($5uXOq))).createElement($e71e2c86ce67b28e$var$Description, null, description), primaryAction, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), null)), (0, ($parcel$interopDefault($5uXOq))).createElement($e71e2c86ce67b28e$var$Footer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: reason,
            onShow: (analyticsEvent)=>(0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'promotionalCardInfo')
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($e71e2c86ce67b28e$var$FooterContent, {
            tabIndex: 0
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("kkuz9"))))), {
            size: "small",
            label: ""
        }), formatMessage((0, (0, parcelRequire("bVZ71")).default).footerContent))))));
    };
    const $e71e2c86ce67b28e$var$Wrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_bfhkvuon _16qspmv4 _2rko1l7b _1bsb1gwv _kqswh2mm",
                __cmplp.className
            ])
        });
    });
    const $e71e2c86ce67b28e$var$Header = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_c71lckbl _1reo15vq _18m915vq",
                __cmplp.className
            ])
        });
    });
    const $e71e2c86ce67b28e$var$ImageContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { imageUrl: imageUrl, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_ntxwly": (0, (0, parcelRequire("lD23V")).default)(`url(${__cmplp.imageUrl})`)
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1lrw1dfr _4t3ibv7e _1bsb1osq _1itklex0",
                __cmplp.className
            ])
        });
    });
    const $e71e2c86ce67b28e$var$Content = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1reo15vq _18m915vq _1yt4pxbi",
                __cmplp.className
            ])
        });
    });
    const $e71e2c86ce67b28e$var$Description = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_19pkutpp _otyrutpp _syaz1fxt _c71l1wqb _1reo15vq _18m915vq",
                __cmplp.className
            ])
        });
    });
    const $e71e2c86ce67b28e$var$Footer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1bah1h6o _1yt418iy _x3doh6cr _syaz1wmz",
                __cmplp.className
            ])
        });
    });
    const $e71e2c86ce67b28e$var$FooterContent = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _zulp1b66 _1wyb1skh",
                __cmplp.className
            ])
        });
    });
    const $e71e2c86ce67b28e$var$ButtonWrapper = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswstnw _154iv47k _1xi21ejb",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("bVZ71", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a789b7df29ddb8f2$export$2e2bcd8739ae039);
    var $7VHMR;
    var $a789b7df29ddb8f2$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        dismiss: {
            "id": "home-common-ui.promotional-card.dismiss",
            "defaultMessage": "Close"
        },
        footerContent: {
            "id": "home-common-ui.promotional-card.footer-content",
            "defaultMessage": "Why am I seeing this?"
        }
    });
});
parcelRegister("fTfAU", function(module, exports) {
    module.exports = new URL("Angie.ce60f296.svg", import.meta.url).toString();
});
parcelRegister("eci4O", function(module, exports) {
    module.exports = new URL("Ed.d7b4103d.svg", import.meta.url).toString();
});
parcelRegister("g8hgP", function(module, exports) {
    module.exports = new URL("More.e627945d.svg", import.meta.url).toString();
});
parcelRegister("8Ahxt", function(module, exports) {
    module.exports = new URL("Norah.1b131c52.svg", import.meta.url).toString();
});
parcelRegister("juXaV", function(module, exports) {
    module.exports = new URL("generic-background.3268bb58.svg", import.meta.url).toString();
});
parcelRegister("kQvK1", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$f46761a434cbf7ed$export$defe85febe8b728c);
    var $7VHMR;
    const $f46761a434cbf7ed$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        header: {
            "id": "cross-join-nudges.your-work.header",
            "defaultMessage": "{collaborators, plural, =0 {Your team is using Confluence} one {{collaborator} is using Confluence} other {{collaborator} and others are using Confluence}}"
        },
        description: {
            "id": "cross-join-nudges.your-work.description",
            "defaultMessage": "Organize team knowledge, capture ideas, and share requirements for your Jira projects."
        },
        joinYourTeam: {
            "id": "cross-join-nudges.your-work.join-your-team",
            "defaultMessage": "{collaborators, plural, one {Join {collaborator}} other {Join your team}}"
        },
        remindMeLater: {
            "id": "cross-join-nudges.your-work.remind-me-later",
            "defaultMessage": "Remind me later"
        },
        notInterested: {
            "id": "cross-join-nudges.your-work.not-interested",
            "defaultMessage": "Not interested"
        },
        tooltip: {
            "id": "cross-join-nudges.your-work.tooltip",
            "defaultMessage": "You're seeing this suggestion because you may want to use Confluence to work with the same people you work with in Jira. Your Jira teammates already use Confluence."
        },
        imageAltText: {
            "id": "cross-join-nudges.your-work.image-alt-text",
            "defaultMessage": "Your team collaborating on a document in Confluence"
        }
    });
});
parcelRegister("gMP0k", function(module, exports) {
    $parcel$export(module.exports, "AppSwitcherLight", ()=>$eedf22ad32c5f711$export$3787c612f09492c8);
    var $5uXOq = parcelRequire("5uXOq");
    var $vNVcd;
    var $3qye2;
    var $4R6GH;
    var $3HwlS;
    var $fL0AO;
    var $3j5Ay;
    const $eedf22ad32c5f711$export$3787c612f09492c8 = ({ onOpen: onOpen, onMouseEnter: onMouseEnter })=>{
        if (false) {
            if ((0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) {
                if ((0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_2')) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fL0AO")).AppSwitcher), {
                    label: (0, (0, parcelRequire("3j5Ay")).SwitchToTooltipText)
                });
                return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3qye2")).Nav4SkeletonSwitcherButton), {
                    label: ""
                });
            }
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3qye2")).SkeletonSwitcherButton), {
                label: ""
            });
        }
        if ((0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) {
            if ((0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_eap_drop_2')) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fL0AO")).AppSwitcher), {
                onClick: onOpen,
                onMouseEnter: onMouseEnter,
                label: (0, (0, parcelRequire("3j5Ay")).SwitchToTooltipText)
            });
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("vNVcd")).AppSwitcherNav4), {
                onClick: onOpen,
                onMouseEnter: onMouseEnter
            });
        }
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("vNVcd")).AppSwitcher), {
            onClick: onOpen,
            onMouseEnter: onMouseEnter
        });
    };
});
parcelRegister("78fKF", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("aD9jw")
    ]).then(()=>parcelRequire('djIOy'));
});
parcelRegister("jUhOq", function(module, exports) {
    $parcel$export(module.exports, "ConversationAssistant", ()=>$a6baf802cdb95631$export$9777b121d71c4de1);
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $5i4cN;
    var $h4rOi;
    var $4QAPb;
    var $hlVrS;
    var $7XYc4;
    var $4R6GH;
    var $hsmeR;
    var $2Ui4E;
    var $3HwlS;
    var $8zOmE;
    var $2NMNM;
    var $kkNtb;
    var $koeKL;
    var $Mp6qH;
    var $4uXxK;
    var $ideHW;
    const $a6baf802cdb95631$var$AssistantButton = (0, (0, parcelRequire("7XYc4")).componentWithCondition)(()=>(0, (0, parcelRequire("3HwlS")).getWillShowNav4)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_eap_drop_2'), (0, (0, parcelRequire("hlVrS")).ConversationAssistantButtonNav4), (0, (0, parcelRequire("hlVrS")).ConversationAssistantButton));
    const $a6baf802cdb95631$var$ConversationAssistantButton = ()=>{
        const [_topMenusValue, { off: closeAllMenus }] = (0, (0, parcelRequire("2Ui4E")).useTopMenus)();
        const { openRightSidebar: openRightSidebar, closeCurrentRightSidebar: closeCurrentRightSidebar } = (0, (0, parcelRequire("hsmeR")).useRightSidebarController)();
        const { panelId: panelId, isCollapsed: isCollapsed } = (0, (0, parcelRequire("hsmeR")).useActiveRightSidebarState)() || {};
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const isActive = panelId === (0, $1cb976721581e2e6$export$dffb4ed44e20ea9a) && !isCollapsed;
        const onButtonClickOld = (0, $5uXOq.useCallback)(()=>{
            if (isActive) {
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                    action: 'clicked',
                    actionSubject: 'navigationItem'
                }), 'aiMate', {
                    opened: isCollapsed
                });
                closeCurrentRightSidebar();
            } else openRightSidebar((0, $1cb976721581e2e6$export$dffb4ed44e20ea9a), (0, $1cb976721581e2e6$export$64aee74ba59709e5), false, true);
            closeAllMenus();
        }, [
            closeAllMenus,
            closeCurrentRightSidebar,
            createAnalyticsEvent,
            isActive,
            isCollapsed,
            openRightSidebar
        ]);
        const onButtonClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'navigationItem'
            }), 'aiMate', {
                opened: !isActive
            });
            isActive ? closeCurrentRightSidebar() : openRightSidebar((0, $1cb976721581e2e6$export$dffb4ed44e20ea9a), (0, $1cb976721581e2e6$export$64aee74ba59709e5), false, true);
            closeAllMenus();
        }, [
            isActive,
            closeAllMenus,
            closeCurrentRightSidebar,
            openRightSidebar,
            createAnalyticsEvent
        ]);
        const buttonRender = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "span",
            paddingInlineStart: (0, (0, parcelRequire("3HwlS")).getWillShowNav4)() ? undefined : 'space.050',
            testId: "atlassian-navigation.ui.conversation-assistant.app-navigation-ai-mate",
            role: (0, (0, parcelRequire("3HwlS")).getWillShowNav4)() ? 'listitem' : undefined
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($a6baf802cdb95631$var$AssistantButton, {
            onClick: (0, (0, parcelRequire("3HwlS")).getWillShowNav4)() ? onButtonClick : onButtonClickOld,
            isActive: isActive,
            siteId: cloudId,
            productKey: "jira"
        }));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, buttonRender, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4QAPb")).ChatContextSubscriber), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5i4cN")).ChatOpenerSubscriber), {
            onTriggerOpeningChat: ()=>{
                if (!isActive) openRightSidebar((0, $1cb976721581e2e6$export$dffb4ed44e20ea9a), (0, $1cb976721581e2e6$export$64aee74ba59709e5), false, true);
            }
        }));
    };
    const $a6baf802cdb95631$export$9777b121d71c4de1 = ()=>{
        const { data: data } = (0, (0, parcelRequire("kkNtb")).useRovoEntitlementResource)();
        const shouldShowConversationAssistantButton = data?.isRovoEnabled ?? false;
        const buttonRender = shouldShowConversationAssistantButton ? (0, ($parcel$interopDefault($5uXOq))).createElement($a6baf802cdb95631$var$ConversationAssistantButton, null) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ideHW")).RovoDemandTest), null);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).CONVERSATION_ASSISTANT
        }, buttonRender, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("h4rOi")).RovoEntitlementSetter), {
            isRovoEnabled: shouldShowConversationAssistantButton
        }));
    };
});
const $1cb976721581e2e6$export$dffb4ed44e20ea9a = 'ai-mate-container';
const $1cb976721581e2e6$export$64aee74ba59709e5 = 439;
const $1cb976721581e2e6$export$1c07393c5bb1bc27 = 439;
const $1cb976721581e2e6$export$aa3478deee7adbeb = 350;
const $1cb976721581e2e6$export$21e515a86cd1733b = 'Chat';
parcelRegister("ideHW", function(module, exports) {
    $parcel$export(module.exports, "RovoDemandTest", ()=>$458b20de6608c1cd$export$6b60f48811782803);
    var $5uXOq = parcelRequire("5uXOq");
    var $cSD7y;
    var $6NNA3;
    var $5QEhh;
    var $Mp6qH;
    var $4uXxK;
    const $458b20de6608c1cd$var$RovoDemandTestButton = ()=>{
        const isEnrolled = (0, (0, parcelRequire("6NNA3")).useIsEnrolledInExperience)();
        const buttonRender = isEnrolled ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5QEhh")).default), null) : null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, buttonRender);
    };
    const $458b20de6608c1cd$var$RovoDemandTestButtonWithErrorBoundary = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).ROVO_DEMAND_TESTS
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($458b20de6608c1cd$var$RovoDemandTestButton, null));
    const $458b20de6608c1cd$export$6b60f48811782803 = (0, (0, parcelRequire("cSD7y")).componentWithFG)('jsw_rovo_demand_tests_gate', $458b20de6608c1cd$var$RovoDemandTestButtonWithErrorBoundary, ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null));
});
parcelRegister("6NNA3", function(module, exports) {
    $parcel$export(module.exports, "useIsEnrolledInExperience", ()=>$83c9cc042cf76acf$export$32dd30a4116853f7);
    var $5uXOq = parcelRequire("5uXOq");
    var $6s1PB;
    var $8zOmE;
    var $2NMNM;
    var $5oBMG;
    const $83c9cc042cf76acf$export$32dd30a4116853f7 = ()=>{
        const productEntitlementDetails = (0, (0, parcelRequire("5oBMG")).useProductEntitlementDetails)();
        const [expConfig, fireExperimentExposure] = (0, (0, parcelRequire("6s1PB")).UNSAFE_noExposureExp)('jsw_rovo_demand_tests_page');
        const cohort = expConfig.get('cohort', 'not-enrolled');
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const isInTrial = (0, $5uXOq.useMemo)(()=>{
            const supportedProducts = [
                'jira-software',
                'jira-product-discovery',
                'jira-servicedesk'
            ];
            let trialing = false;
            if (productEntitlementDetails) Object.keys(productEntitlementDetails).forEach((product)=>{
                if (supportedProducts.includes(product) && productEntitlementDetails[product].trialEndTime) trialing = true;
            });
            return trialing;
        }, [
            productEntitlementDetails
        ]);
        const isEnrolled = (0, $5uXOq.useMemo)(()=>{
            if (cohort === 'not-enrolled' || isInTrial) return false;
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'jiraRovoDemandTesting exposed', {
                cohort: cohort
            });
            fireExperimentExposure();
            return cohort === 'test';
        }, [
            cohort,
            isInTrial,
            createAnalyticsEvent,
            fireExperimentExposure
        ]);
        if (isEnrolled) return true;
        return false;
    };
});
parcelRegister("5QEhh", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3c2f9e5997133cbe$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $711X2;
    var $hqZzc;
    var $dC5iT;
    var $ousew;
    var $koVbs;
    var $3HwlS;
    var $iXVV6;
    var $5oFIy;
    var $8zOmE;
    var $1f8Sx;
    const $3c2f9e5997133cbe$var$defaultButtonStyles = {
        paddingInline: '8px'
    };
    const $3c2f9e5997133cbe$var$inactiveButtonStyles = {
        background: "var(--ds-background-neutral, #091E420F)"
    };
    function $3c2f9e5997133cbe$export$2e2bcd8739ae039() {
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const isActive = window.location.href.endsWith('software/rovo/discover');
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: "jiraRovoDemandTesting topNavButton",
            sourceType: (0, $4d5e0871c06cee03$export$cae2783c9279926)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "span",
            paddingInlineStart: (0, (0, parcelRequire("3HwlS")).getWillShowNav4)() ? undefined : 'space.050',
            role: (0, (0, parcelRequire("3HwlS")).getWillShowNav4)() ? 'listitem' : undefined
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("711X2")).PrimaryButton), {
            href: "/jira/software/rovo/discover",
            style: {
                ...$3c2f9e5997133cbe$var$defaultButtonStyles,
                ...!isActive && $3c2f9e5997133cbe$var$inactiveButtonStyles
            },
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hqZzc")).RovoIcon), {
                size: "xsmall",
                label: ""
            }),
            tooltip: formatMessage((0, (0, parcelRequire("1f8Sx")).default).discoverRovoButtonTooltip),
            isSelected: isActive,
            onClick: (_, analyticsEvent)=>{
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'rovoDemandTestButton');
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), (0, (0, parcelRequire("1f8Sx")).default).rovoButtonCopy))));
    }
});
parcelRegister("1f8Sx", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$923d3bf735ccc9b6$export$2e2bcd8739ae039);
    var $7VHMR;
    var $923d3bf735ccc9b6$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        rovoButtonCopy: {
            "id": "jira-rovo-demand-testing.ui.top-nav-button.rovo-button-copy",
            "defaultMessage": "Rovo"
        },
        discoverRovoButtonTooltip: {
            "id": "jira-rovo-demand-testing.ui.top-nav-button.rovo-discover-button-tooltip",
            "defaultMessage": "Discover Rovo"
        }
    });
});
parcelRegister("96WTB", function(module, exports) {
    $parcel$export(module.exports, "Create", ()=>$c8d9a30d843c92d0$export$8ade6fcbf3a7de5d);
    var $5uXOq = parcelRequire("5uXOq");
    var $dJl1T;
    var $7kxNZ;
    var $jp0tf;
    var $co1wz;
    var $koVbs;
    var $9YT0J;
    var $c0dQj;
    var $6cNb0;
    var $hULKg;
    var $5rzqz;
    var $f5nIZ;
    var $kxEY7;
    var $cZpsT;
    var $2YP9W;
    var $Mp6qH;
    var $3S5gG;
    var $4uXxK;
    var $iPj86;
    var $ktmuI;
    var $8fSk0;
    var $twalS;
    const $c8d9a30d843c92d0$var$noop = ()=>undefined;
    const $c8d9a30d843c92d0$var$Create = ({ AkCreate: AkCreate = (0, (0, parcelRequire("3S5gG")).Create), NudgeTooltip: NudgeTooltip = (0, (0, parcelRequire("kxEY7")).NavigationCreateIssueButtonWrapperNudgeAsync), onCreateClick: onCreateClick = (0, (0, parcelRequire("jp0tf")).default), useNavigationItemAnalytics: useNavigationItemAnalytics = (0, (0, parcelRequire("iPj86")).useNavigationItemAnalytics), useIsInitialLoadLegacyIssueCreate: useIsInitialLoadLegacyIssueCreate = (0, (0, parcelRequire("7kxNZ")).useIsInitialLoadLegacyIssueCreate) })=>{
        const testId = (0, (0, parcelRequire("6cNb0")).testIdGenerate)('create');
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [, { openIssueCreateModal: openIssueCreateModal }] = (0, (0, parcelRequire("5rzqz")).useTriggerIssueCreateModal)();
        (0, (0, parcelRequire("9YT0J")).useInitializePersistentIssueCreateModalView)();
        const triggerAnalytics = useNavigationItemAnalytics({
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).CREATE
        });
        const [{ isInitialLoad: isInitialLoad }, actions] = useIsInitialLoadLegacyIssueCreate();
        let currentIssueCreateVisibility = null;
        const gicOptInStateOverridden = (0, $5uXOq.useRef)(false);
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const isMobileExperienceEnabled = (0, (0, parcelRequire("c0dQj")).isMobileAndInMVPExperiment)();
        if ((0, (0, parcelRequire("co1wz")).ff)('project.config.pce.modern.global-issue-create')) {
            const { data: data, update: update } = (0, (0, parcelRequire("2YP9W")).useResource)((0, (0, parcelRequire("f5nIZ")).gicOptInStatusResources));
            if (isAnonymous && !gicOptInStateOverridden.current) {
                update(()=>({
                        gicOptInStatus: {
                            isEnabled: true
                        }
                    }));
                gicOptInStateOverridden.current = true;
            }
            currentIssueCreateVisibility = data && data?.gicOptInStatus;
        }
        const onClick = (0, $5uXOq.useCallback)((e)=>{
            if ((0, (0, parcelRequire("co1wz")).ff)('project.config.pce.modern.global-issue-create')) {
                if (currentIssueCreateVisibility !== null) {
                    if (currentIssueCreateVisibility.isEnabled) {
                        (0, (0, parcelRequire("ktmuI")).GlobalIssueCreateFromTopNav).abort({
                            force: true
                        });
                        (0, (0, parcelRequire("ktmuI")).GlobalIssueCreateFromTopNav).start();
                        (0, (0, parcelRequire("ktmuI")).GlobalIssueCreateFromTopNav).addMetadata({
                            inputDelay: Math.round(performance.now() - e.timeStamp)
                        });
                        const nextState = {
                            disableCreationSuccessFlag: (0, (0, parcelRequire("twalS")).shouldDisableGICFlagCreation)(),
                            triggerPointKey: (0, (0, parcelRequire("hULKg")).GENERIC_GLOBAL_CREATE_BUTTON),
                            ...isMobileExperienceEnabled ? {
                                displayMinimizableButtons: false,
                                displayCreateAnotherIssueToggle: false,
                                triggerPointKey: (0, (0, parcelRequire("hULKg")).MOBILE_GLOBAL_CREATE_BUTTON)
                            } : {}
                        };
                        openIssueCreateModal(nextState);
                    } else {
                        onCreateClick({
                            isInitialLoad: isInitialLoad,
                            triggerSource: (0, $de7681b8672bb382$export$d983e94cf86f65d)
                        });
                        if (isInitialLoad) actions.clearInitialLoaded();
                    }
                } else (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('global-issue-create', 'visibility data is still getting downloaded');
            } else {
                onCreateClick({
                    isInitialLoad: isInitialLoad,
                    triggerSource: (0, $de7681b8672bb382$export$d983e94cf86f65d)
                });
                if (isInitialLoad) actions.clearInitialLoaded();
            }
            triggerAnalytics();
        }, [
            actions,
            currentIssueCreateVisibility,
            isInitialLoad,
            isMobileExperienceEnabled,
            onCreateClick,
            openIssueCreateModal,
            triggerAnalytics
        ]);
        if (!false) return (0, ($parcel$interopDefault($5uXOq))).createElement(NudgeTooltip, null, (0, ($parcel$interopDefault($5uXOq))).createElement(AkCreate, {
            onClick: onClick,
            text: formatMessage((0, (0, parcelRequire("8fSk0")).default).create),
            testId: testId,
            label: formatMessage((0, (0, parcelRequire("8fSk0")).default).createButtonAriaLabel)
        }));
        return (0, ($parcel$interopDefault($5uXOq))).createElement(AkCreate, {
            onClick: $c8d9a30d843c92d0$var$noop,
            text: formatMessage((0, (0, parcelRequire("8fSk0")).default).create),
            testId: testId,
            label: formatMessage((0, (0, parcelRequire("8fSk0")).default).createButtonAriaLabel)
        });
    };
    const $c8d9a30d843c92d0$export$8ade6fcbf3a7de5d = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).CREATE
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($c8d9a30d843c92d0$var$Create, props));
});
parcelRegister("7kxNZ", function(module, exports) {
    $parcel$export(module.exports, "useIsInitialLoadLegacyIssueCreate", ()=>$c1839ac195702930$export$8a73e89ffc4b9342);
    var $3sQ5x;
    var $iXqE5;
    const $c1839ac195702930$var$initialState = {
        isInitialLoad: true
    };
    const $c1839ac195702930$export$e324594224ef24da = {
        clearInitialLoaded: ()=>({ setState: setState, getState: getState })=>{
                if (getState().isInitialLoad) setState({
                    isInitialLoad: false
                });
            }
    };
    const $c1839ac195702930$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'is-initial-load-create-issue-legacy',
        initialState: $c1839ac195702930$var$initialState,
        actions: $c1839ac195702930$export$e324594224ef24da
    });
    const $c1839ac195702930$export$8a73e89ffc4b9342 = (0, (0, parcelRequire("3sQ5x")).createHook)($c1839ac195702930$var$store);
});
parcelRegister("jp0tf", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$16da541e59a26adc$export$2e2bcd8739ae039);
    var $6BZYo;
    var $co1wz;
    var $71RGi;
    var $iKCxj;
    var $16da541e59a26adc$export$2e2bcd8739ae039 = ({ timeout: timeout, tentatives: tentatives, isInitialLoad: isInitialLoad, triggerSource: triggerSource, showModern: showModern } = {})=>{
        (0, (0, parcelRequire("iKCxj")).sendStartMarkForLegacyIssueCreate)({
            isInitialLoad: isInitialLoad,
            triggerSource: triggerSource
        });
        (0, (0, parcelRequire("71RGi")).initForgeBridge)();
        return (0, (0, parcelRequire("6BZYo")).loadBridge)({
            name: 'quick-edit/init/create-dialogs',
            wrmKeys: [
                'wrc!com.atlassian.jira.jira-quick-edit-plugin:quick-create-issue-bootstrap',
                'wrc!com.atlassian.jira.jira-atlaskit-plugin:overrides-general',
                'wrc!com.atlassian.jira.jira-atlaskit-plugin:overrides-dialogs'
            ],
            timeout: timeout,
            tentatives: tentatives
        }).then((api)=>{
            if ((0, (0, parcelRequire("co1wz")).ff)('project.config.pce.modern.global-issue-create.trigger')) {
                if (showModern === true) api.hideLoadingIndicator(false);
                else api.showCreateDialog({
                    showModern: showModern
                });
            } else api.showCreateDialog({});
            (0, (0, parcelRequire("iKCxj")).sendEndMarkForLegacyIssueCreate)({
                isInitialLoad: isInitialLoad
            });
        });
    };
});
parcelRegister("71RGi", function(module, exports) {
    $parcel$export(module.exports, "initForgeBridge", ()=>$56030f95fced5c24$export$8e110dda7ad69e5a);
    var $6BZYo;
    var $4Pf62;
    var $dJl1T;
    var $4R6GH;
    var $k2DRb;
    var $5vSAE;
    var $iVaKa;
    var $jpE7i;
    const $56030f95fced5c24$var$loadModulesV1 = async (dataClassification = null)=>{
        const { cloudId: cloudId, activationId: activationId, locale: locale } = (0, (0, parcelRequire("4Pf62")).getTenantContext_DEPRECATED_DO_NOT_USE)();
        const modulesToFetch = [
            (0, (0, parcelRequire("k2DRb")).CUSTOM_FIELD_MODULE),
            (0, (0, parcelRequire("k2DRb")).CUSTOM_FIELD_TYPE_MODULE),
            (0, (0, parcelRequire("k2DRb")).WORKFLOW_VALIDATOR_MODULE),
            (0, (0, parcelRequire("k2DRb")).WORKFLOW_CONDITION_MODULE),
            (0, (0, parcelRequire("k2DRb")).WORKFLOW_POST_FUNCTION_MODULE)
        ];
        const contextIds = [
            `ari:cloud:jira:${cloudId}:workspace/${activationId}`
        ];
        const { extensions: extensions } = await (0, (0, parcelRequire("iVaKa")).default)(cloudId, modulesToFetch, dataClassification, undefined, (0, (0, parcelRequire("k2DRb")).SOURCE_LEGACY), contextIds, locale);
        const customFields = extensions.jiraCustomField;
        const customFieldTypes = extensions.jiraCustomFieldType;
        const workflowValidators = extensions.jiraWorkflowValidator;
        const workflowConditions = extensions.jiraWorkflowCondition || [];
        const workflowPostFunctions = extensions.jiraWorkflowPostFunction || [];
        return {
            customFields: customFields,
            customFieldTypes: customFieldTypes,
            workflowValidators: workflowValidators,
            workflowConditions: workflowConditions,
            workflowPostFunctions: workflowPostFunctions
        };
    };
    const $56030f95fced5c24$var$loadModulesV2 = async ()=>{
        const { cloudId: cloudId } = (0, (0, parcelRequire("4Pf62")).getTenantContext_DEPRECATED_DO_NOT_USE)();
        const extensions = await (0, (0, parcelRequire("5vSAE")).fetchModules)({
            cloudId: cloudId,
            context: {},
            includeHidden: false,
            types: [
                (0, (0, parcelRequire("k2DRb")).WORKFLOW_VALIDATOR_MODULE),
                (0, (0, parcelRequire("k2DRb")).WORKFLOW_CONDITION_MODULE),
                (0, (0, parcelRequire("k2DRb")).WORKFLOW_POST_FUNCTION_MODULE)
            ],
            source: (0, (0, parcelRequire("k2DRb")).SOURCE_LEGACY)
        });
        return {
            workflowValidators: extensions[0, (0, parcelRequire("k2DRb")).WORKFLOW_VALIDATOR_MODULE],
            workflowConditions: extensions[0, (0, parcelRequire("k2DRb")).WORKFLOW_CONDITION_MODULE],
            workflowPostFunctions: extensions[0, (0, parcelRequire("k2DRb")).WORKFLOW_POST_FUNCTION_MODULE],
            customFields: [],
            customFieldTypes: []
        };
    };
    const $56030f95fced5c24$export$8e110dda7ad69e5a = async ()=>{
        const components = {
            customField: (0, (0, parcelRequire("4R6GH")).fg)('new_graphql_endpoint_for_fetching_forge_modules') ? undefined : (0, (0, parcelRequire("jpE7i")).customField),
            workflowValidator: (0, parcelRequire("jpE7i")).workflowValidator,
            workflowCondition: (0, parcelRequire("jpE7i")).workflowCondition,
            workflowPostFunction: (0, parcelRequire("jpE7i")).workflowPostFunction
        };
        const loadModules = (0, (0, parcelRequire("4R6GH")).fg)('new_graphql_endpoint_for_fetching_forge_modules') ? $56030f95fced5c24$var$loadModulesV2 : $56030f95fced5c24$var$loadModulesV1;
        const [modules, bridge] = await Promise.all([
            loadModules(),
            (0, (0, parcelRequire("6BZYo")).loadBridge)({
                name: 'jira/forge/bridge',
                wrmKeys: 'wrc!com.atlassian.jira.jira-atlaskit-plugin:forge-bridge'
            }).catch((e)=>{
                (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('forge.legacy.bridge', 'Cannot load the bridge', e);
                return e;
            })
        ]);
        const API = {
            modules: modules,
            components: components,
            loadModules: loadModules
        };
        bridge(API);
    };
});
parcelRegister("jpE7i", function(module, exports) {
    $parcel$export(module.exports, "customField", ()=>$6232fc21ce9dabb7$export$82c8c2cee582a640);
    $parcel$export(module.exports, "workflowCondition", ()=>$6232fc21ce9dabb7$export$8fe15afd9a3cb1e7);
    $parcel$export(module.exports, "workflowPostFunction", ()=>$6232fc21ce9dabb7$export$f7a58b73e10db093);
    $parcel$export(module.exports, "workflowValidator", ()=>$6232fc21ce9dabb7$export$75e1428e5ba72a);
    var $5uXOq = parcelRequire("5uXOq");
    var $7nsd3 = parcelRequire("7nsd3");
    var $gG5I6;
    var $7kaQH;
    var $ldFMs;
    var $45Dl2;
    var $i6C3Q;
    const $6232fc21ce9dabb7$export$82c8c2cee582a640 = {
        renderView: (props)=>{
            const container = document.createElement('div');
            (0, $7nsd3.render)((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kaQH")).CustomField), props), container);
            return container;
        },
        renderEditView: (props)=>{
            const container = document.createElement('div');
            (0, $7nsd3.render)((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kaQH")).CustomFieldEdit), props), container);
            return container;
        }
    };
    const $6232fc21ce9dabb7$export$8fe15afd9a3cb1e7 = {
        renderView: (props)=>{
            const container = document.createElement('div');
            (0, $7nsd3.render)((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gG5I6")).JiraContextContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ldFMs")).WorkflowConditionView), props)), container);
            return container;
        },
        renderCreate: (props)=>{
            const container = document.createElement('div');
            (0, $7nsd3.render)((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gG5I6")).JiraContextContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ldFMs")).WorkflowConditionCreate), props)), container);
            return container;
        },
        renderEdit: (props)=>{
            const container = document.createElement('div');
            (0, $7nsd3.render)((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gG5I6")).JiraContextContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ldFMs")).WorkflowConditionEdit), props)), container);
            return container;
        }
    };
    const $6232fc21ce9dabb7$export$f7a58b73e10db093 = {
        renderView: (props)=>{
            const container = document.createElement('div');
            (0, $7nsd3.render)((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gG5I6")).JiraContextContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("45Dl2")).WorkflowPostFunctionView), props)), container);
            return container;
        },
        renderCreate: (props)=>{
            const container = document.createElement('div');
            (0, $7nsd3.render)((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gG5I6")).JiraContextContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("45Dl2")).WorkflowPostFunctionCreate), props)), container);
            return container;
        },
        renderEdit: (props)=>{
            const container = document.createElement('div');
            (0, $7nsd3.render)((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gG5I6")).JiraContextContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("45Dl2")).WorkflowPostFunctionEdit), props)), container);
            return container;
        }
    };
    const $6232fc21ce9dabb7$export$75e1428e5ba72a = {
        renderView: (props)=>{
            const container = document.createElement('div');
            (0, $7nsd3.render)((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gG5I6")).JiraContextContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("i6C3Q")).WorkflowValidatorView), props)), container);
            return container;
        },
        renderCreate: (props)=>{
            const container = document.createElement('div');
            (0, $7nsd3.render)((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gG5I6")).JiraContextContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("i6C3Q")).WorkflowValidatorCreate), props)), container);
            return container;
        },
        renderEdit: (props)=>{
            const container = document.createElement('div');
            (0, $7nsd3.render)((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gG5I6")).JiraContextContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("i6C3Q")).WorkflowValidatorEdit), props)), container);
            return container;
        }
    };
});
parcelRegister("7kaQH", function(module, exports) {
    $parcel$export(module.exports, "CustomField", ()=>$58ab3610b649b9db$export$9639a5943929d469);
    $parcel$export(module.exports, "CustomFieldEdit", ()=>$58ab3610b649b9db$export$5de2ecfed36387cf);
    var $5uXOq = parcelRequire("5uXOq");
    var $2RhPB;
    var $7Aezm;
    var $i3iZI;
    var $34psX;
    const $58ab3610b649b9db$export$9639a5943929d469 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7Aezm")).ViewAnalyticsWrapper), {
            attributes: {
                ...props.analyticsAttributes,
                ...(0, (0, parcelRequire("2RhPB")).getAnalyticsAttributesFromExtension)(props.extension)
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("34psX")).AsyncCustomField), props));
    const $58ab3610b649b9db$var$CustomFieldEditView = (props)=>{
        const [isOpen, setIsOpen] = (0, $5uXOq.useState)(true);
        const { onCancel: onCancel, onSave: onSave } = props;
        const onCancelCallback = (0, $5uXOq.useCallback)(()=>{
            setIsOpen(false);
            onCancel && onCancel();
        }, [
            onCancel
        ]);
        const onSaveCallback = (0, $5uXOq.useCallback)((e)=>{
            setIsOpen(false);
            return onSave(e);
        }, [
            onSave
        ]);
        return isOpen ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("i3iZI")).AsyncCustomFieldEdit), {
            ...props,
            onCancel: onCancelCallback,
            onSave: onSaveCallback
        }) : null;
    };
    const $58ab3610b649b9db$export$5de2ecfed36387cf = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7Aezm")).EditAnalyticsWrapper), {
            attributes: {
                ...props.analyticsAttributes,
                ...(0, (0, parcelRequire("2RhPB")).getAnalyticsAttributesFromExtension)(props.extension)
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($58ab3610b649b9db$var$CustomFieldEditView, props));
});
parcelRegister("7Aezm", function(module, exports) {
    $parcel$export(module.exports, "ViewAnalyticsWrapper", ()=>$f6c5a5a43a090916$export$c794eb4124961145);
    $parcel$export(module.exports, "EditAnalyticsWrapper", ()=>$f6c5a5a43a090916$export$947ae3996b2bc000);
    var $5uXOq = parcelRequire("5uXOq");
    var $k2DRb;
    var $1rTGO;
    const $f6c5a5a43a090916$export$c794eb4124961145 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeAnalyticsWrapper), {
            module: (0, (0, parcelRequire("k2DRb")).CUSTOM_FIELD_MODULE),
            shouldFireInitializedEvent: true,
            ...props
        });
    const $f6c5a5a43a090916$export$947ae3996b2bc000 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeAnalyticsWrapper), {
            module: (0, (0, parcelRequire("k2DRb")).CUSTOM_FIELD_MODULE),
            entryPoint: (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT),
            ...props
        });
});
parcelRegister("i3iZI", function(module, exports) {
    $parcel$export(module.exports, "AsyncCustomFieldEdit", ()=>$9e4c2fe5e670db4c$export$61f4a77d813bf4b4);
    var $5uXOq = parcelRequire("5uXOq");
    var $pa9q9;
    var $1rTGO;
    var $8kVg1;
    var $hBuqR;
    var $k2DRb;
    var $afKG3;
    var $3mOWa;
    var $lfTZh;
    var $kxZPA;
    var $69dub;
    var $7IjIj;
    const $9e4c2fe5e670db4c$var$getModule = ()=>(0, (0, parcelRequire("8kVg1")).measureInitialPageLoadAsyncResourcesTiming)((0, (0, parcelRequire("hBuqR")).PERFORMANCE_KEYS).MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_EDIT_BUNDLE_START, (0, (0, parcelRequire("hBuqR")).PERFORMANCE_KEYS).MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_EDIT_BUNDLE_END, ()=>(parcelRequire("lf16s")));
    const $9e4c2fe5e670db4c$var$getForgeExtensionModule = ()=>(0, (0, parcelRequire("8kVg1")).measureInitialPageLoadAsyncResourcesTiming)((0, (0, parcelRequire("hBuqR")).PERFORMANCE_KEYS).MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_EDIT_BUNDLE_START, (0, (0, parcelRequire("hBuqR")).PERFORMANCE_KEYS).MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_EDIT_BUNDLE_END, ()=>(parcelRequire("7kdGo")));
    const $9e4c2fe5e670db4c$export$55429035358dd625 = (0, (0, parcelRequire("1rTGO")).withMetrics)((0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>$9e4c2fe5e670db4c$var$getModule().then((module)=>module.CustomFieldEdit), {
        ssr: false
    }));
    const $9e4c2fe5e670db4c$export$6eef71e05c3af990 = (0, (0, parcelRequire("1rTGO")).withMetrics)((0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>$9e4c2fe5e670db4c$var$getForgeExtensionModule().then((module)=>module.CustomFieldEdit), {
        ssr: false
    }));
    const $9e4c2fe5e670db4c$export$5ffe94eb10d5f1f4 = ({ onSave: onSave, onCancel: onCancel, onConfirm: onConfirm, onError: onError, onRender: onRender, ...restProps })=>{
        const { extension: extension, extensionData: extensionData, extensionPayload: extensionPayload } = restProps;
        const { showFlag: showFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        const extensionDataValue = (0, $5uXOq.useMemo)(()=>{
            const preparedExtensionData = extensionData?.issue && extensionData?.project ? extensionData : {
                fieldType: extensionData.fieldType,
                fieldId: extensionData.fieldId,
                fieldName: extensionData.fieldName,
                renderContext: extensionData.renderContext
            };
            return {
                ...preparedExtensionData,
                type: extension.type,
                entryPoint: (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT)
            };
        }, [
            extension.type,
            extensionData
        ]);
        const extraProps = (0, $5uXOq.useMemo)(()=>({
                module: (0, (0, parcelRequire("k2DRb")).CUSTOM_FIELD_MODULE),
                entryPoint: (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT),
                extensionData: extensionDataValue,
                extensionPayload: extensionPayload,
                contextToken: (0, (0, parcelRequire("k2DRb")).CONTEXT_TOKEN_TYPE_JIRA)
            }), [
            extensionDataValue,
            extensionPayload
        ]);
        const submit = (0, $5uXOq.useCallback)(({ data: fieldValue })=>(0, (0, parcelRequire("7IjIj")).onCustomFieldSubmit)(fieldValue, onSave, onConfirm, showFlag), [
            showFlag
        ]);
        const close = onCancel;
        const bridge = (0, $5uXOq.useMemo)(()=>({
                submit: submit,
                close: close
            }), [
            submit,
            close
        ]);
        if ((0, (0, parcelRequire("3mOWa")).isNativeUiExtension)(extension, (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT)) || (0, (0, parcelRequire("3mOWa")).isCustomUIExtension)(extension, (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT))) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("69dub")).StopSubmitPropagation), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "custom-field-edit",
            fallback: null
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($9e4c2fe5e670db4c$export$6eef71e05c3af990, {
            ...restProps,
            ...extraProps,
            onError: onError,
            onRender: onRender,
            onCancel: onCancel,
            submit: submit,
            bridge: bridge
        })));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("69dub")).StopSubmitPropagation), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "custom-field-edit",
            fallback: null
        }, (0, (0, parcelRequire("3mOWa")).isCustomUIExtension)(extension, (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT)) ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("afKG3")).LazyModalIframe), {
            ...restProps,
            ...extraProps,
            bridge: bridge,
            onClose: onCancel,
            onLoad: onRender
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement($9e4c2fe5e670db4c$export$55429035358dd625, {
            ...restProps,
            ...extraProps,
            onError: onError,
            onRender: onRender,
            onCancel: onCancel,
            submit: submit,
            bridge: bridge
        })));
    };
    const $9e4c2fe5e670db4c$export$61f4a77d813bf4b4 = $9e4c2fe5e670db4c$export$5ffe94eb10d5f1f4;
});
parcelRegister("afKG3", function(module, exports) {
    $parcel$export(module.exports, "LazyIframe", ()=>$8deafd95f39f73a9$export$808646051d5a3908);
    $parcel$export(module.exports, "LazyModalIframe", ()=>$8deafd95f39f73a9$export$f35ac420e260eb7);
    var $1rTGO;
    var $kxZPA;
    const $8deafd95f39f73a9$var$getModule = ()=>(parcelRequire("i2O8t"));
    const $8deafd95f39f73a9$export$808646051d5a3908 = (0, (0, parcelRequire("1rTGO")).withMetrics)((0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>$8deafd95f39f73a9$var$getModule().then((module)=>module.IframeRenderer), {
        ssr: false
    }));
    const $8deafd95f39f73a9$export$f35ac420e260eb7 = (0, (0, parcelRequire("1rTGO")).withMetrics)((0, (0, parcelRequire("kxZPA")).lazy)(()=>$8deafd95f39f73a9$var$getModule().then((module)=>module.ModalIframeRenderer), {}));
});
parcelRegister("i2O8t", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("1SRXI")
    ]).then(()=>parcelRequire('96A2p'));
});
parcelRegister("69dub", function(module, exports) {
    $parcel$export(module.exports, "StopSubmitPropagation", ()=>$1d6031a605ad4b71$export$238f56c0891e9254);
    var $5uXOq = parcelRequire("5uXOq");
    const $1d6031a605ad4b71$export$e6dfd72ab29f8598 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            "data-testid": "forge-ui-async.common.ui.submit-stop-propogation-wrapper.forge-stop-propagation-wrapper",
            onSubmit: props.onSubmit
        }, props.children);
    const $1d6031a605ad4b71$export$238f56c0891e9254 = ({ children: children })=>{
        const handleSubmit = (e)=>{
            e.stopPropagation();
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement($1d6031a605ad4b71$export$e6dfd72ab29f8598, {
            onSubmit: handleSubmit
        }, children);
    };
});
parcelRegister("7IjIj", function(module, exports) {
    $parcel$export(module.exports, "onCustomFieldSubmit", ()=>$7482280c9b50d561$export$fc8c2ed652969e74);
    var $jJZqs;
    var $dGSK4;
    const $7482280c9b50d561$export$fc8c2ed652969e74 = (fieldValue, onSave, onConfirm, showFlag)=>onSave(fieldValue).then((response)=>{
            if (response == null) return;
            if ('error' in response) {
                if (response.error) throw response.error;
            } else onConfirm(response.data);
        }).catch((error)=>{
            showFlag((0, (0, parcelRequire("dGSK4")).default)(error));
            if ((0, (0, parcelRequire("jJZqs")).isValidationError)(error)) throw error;
            throw Error(`Unable to save custom field, error: ${error.message}`);
        });
});
parcelRegister("dGSK4", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b60f776846cd27f9$export$2e2bcd8739ae039);
    var $jJZqs;
    var $eRDnE;
    var $ctOgQ;
    var $b60f776846cd27f9$export$2e2bcd8739ae039 = (error)=>(0, (0, parcelRequire("jJZqs")).isValidationError)(error) ? (0, (0, parcelRequire("ctOgQ")).default)(error.errors) : (0, (0, parcelRequire("eRDnE")).default)();
});
parcelRegister("eRDnE", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$41e1a5ac6c28ff9a$export$2e2bcd8739ae039);
    var $7VHMR;
    const $41e1a5ac6c28ff9a$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "forge-ui.common.flags.custom-field.edit-failed.generic-error.title",
            "defaultMessage": "We were unable to edit this custom field. Please try again."
        }
    });
    var $41e1a5ac6c28ff9a$export$2e2bcd8739ae039 = ()=>({
            type: 'error',
            title: $41e1a5ac6c28ff9a$var$messages.title,
            isAutoDismiss: false
        });
});
parcelRegister("ctOgQ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$59eef974107568f4$export$2e2bcd8739ae039);
    var $7VHMR;
    const $59eef974107568f4$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "forge-ui.common.flags.custom-field.edit-failed.validation-error.title",
            "defaultMessage": "Validation error"
        }
    });
    var $59eef974107568f4$export$2e2bcd8739ae039 = (errors)=>({
            type: 'error',
            title: $59eef974107568f4$var$messages.title,
            description: errors.map(({ error: error })=>error).join(', '),
            isAutoDismiss: false
        });
});
parcelRegister("lf16s", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("kWrFZ"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("eNmuD"),
        $6fhBS("hPzOn"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("2AjeU")
    ]).then(()=>parcelRequire('lDRdH'));
});
parcelRegister("7kdGo", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("kWrFZ"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("eNmuD"),
        $6fhBS("hPzOn"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("2QVAD")
    ]).then(()=>parcelRequire('456zR'));
});
parcelRegister("34psX", function(module, exports) {
    $parcel$export(module.exports, "AsyncCustomField", ()=>$531f395fd2fb5a90$export$3d94b128d17ce776);
    var $5uXOq = parcelRequire("5uXOq");
    var $1rTGO;
    var $8kVg1;
    var $hBuqR;
    var $k2DRb;
    var $3mOWa;
    var $lfTZh;
    var $kxZPA;
    var $bLCKr;
    const $531f395fd2fb5a90$var$getModule = ()=>(0, (0, parcelRequire("8kVg1")).measureInitialPageLoadAsyncResourcesTiming)((0, (0, parcelRequire("hBuqR")).PERFORMANCE_KEYS).MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_BUNDLE_START, (0, (0, parcelRequire("hBuqR")).PERFORMANCE_KEYS).MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_BUNDLE_END, ()=>(parcelRequire("h2FhT")));
    const $531f395fd2fb5a90$var$getForgeExtensionModule = ()=>(0, (0, parcelRequire("8kVg1")).measureInitialPageLoadAsyncResourcesTiming)((0, (0, parcelRequire("hBuqR")).PERFORMANCE_KEYS).MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_BUNDLE_START, (0, (0, parcelRequire("hBuqR")).PERFORMANCE_KEYS).MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_BUNDLE_END, ()=>(parcelRequire("hf94s")));
    const $531f395fd2fb5a90$var$skeletonConfig = [
        {
            width: 100
        }
    ];
    const $531f395fd2fb5a90$export$2a6d819caf09da30 = (0, (0, parcelRequire("1rTGO")).withMetrics)((0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>$531f395fd2fb5a90$var$getModule().then((module)=>module.CustomField), {
        ssr: false
    }));
    const $531f395fd2fb5a90$export$a64ace77e0f13482 = (0, (0, parcelRequire("1rTGO")).withMetrics)((0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>$531f395fd2fb5a90$var$getForgeExtensionModule().then((module)=>module.CustomField), {
        ssr: false
    }));
    const $531f395fd2fb5a90$export$ffc72ef953e96226 = (props)=>{
        const { extension: extension, extensionData: extensionData, extensionPayload: extensionPayload } = props;
        const loadingComponent = (0, $5uXOq.useMemo)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bLCKr")).Skeleton), {
                skeletonConfig: $531f395fd2fb5a90$var$skeletonConfig
            }), []);
        const extensionDataValue = (0, $5uXOq.useMemo)(()=>{
            const preparedExtensionData = extensionData?.issue && extensionData?.project ? extensionData : {
                fieldType: extensionData.fieldType,
                fieldId: extensionData.fieldId,
                fieldName: extensionData.fieldName,
                renderContext: extensionData.renderContext
            };
            return {
                ...preparedExtensionData,
                type: extension.type
            };
        }, [
            extension.type,
            extensionData
        ]);
        const extraProps = (0, $5uXOq.useMemo)(()=>({
                loadingComponent: loadingComponent,
                module: (0, (0, parcelRequire("k2DRb")).CUSTOM_FIELD_MODULE),
                extensionData: extensionDataValue,
                extensionPayload: extensionPayload,
                contextToken: (0, (0, parcelRequire("k2DRb")).CONTEXT_TOKEN_TYPE_JIRA)
            }), [
            extensionDataValue,
            extensionPayload,
            loadingComponent
        ]);
        if ((0, (0, parcelRequire("3mOWa")).isNativeUiExtension)(extension) || (0, (0, parcelRequire("3mOWa")).isCustomUIExtension)(extension)) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "custom-field-renderer",
            fallback: loadingComponent
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($531f395fd2fb5a90$export$a64ace77e0f13482, {
            ...props,
            ...extraProps
        }));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "custom-field-renderer",
            fallback: loadingComponent
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($531f395fd2fb5a90$export$2a6d819caf09da30, {
            ...props,
            ...extraProps
        }));
    };
    const $531f395fd2fb5a90$export$3d94b128d17ce776 = $531f395fd2fb5a90$export$ffc72ef953e96226;
});
parcelRegister("bLCKr", function(module, exports) {
    $parcel$export(module.exports, "Skeleton", ()=>$e1da1ba1fdde315e$export$8f31e4c4a37b8e9c);
    $parcel$export(module.exports, "SkeletonLayout", ()=>$e1da1ba1fdde315e$export$af3ba02ef74c9bdf);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    var $dC5iT;
    var $8CUq3;
    const $e1da1ba1fdde315e$export$69f3c7f0b2b87aa2 = [];
    const $e1da1ba1fdde315e$export$8f31e4c4a37b8e9c = ({ skeletonConfig: skeletonConfig = $e1da1ba1fdde315e$export$69f3c7f0b2b87aa2 })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, skeletonConfig.map(({ width: width }, index)=>(0, ($parcel$interopDefault($5uXOq))).createElement($e1da1ba1fdde315e$var$ForgeSkeleton, {
                "data-testId": `forgeskeleton-${index}`,
                key: index,
                width: width
            })));
    const $e1da1ba1fdde315e$export$af3ba02ef74c9bdf = ({ children: children, layout: layout })=>{
        if (!layout) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            testId: "forge-ui-async.common.ui.skeleton.box",
            xcss: $e1da1ba1fdde315e$var$layoutStyles
        }, children);
    };
    const $e1da1ba1fdde315e$var$shimmer = null;
    const $e1da1ba1fdde315e$var$ForgeSkeleton = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: {
                ...__cmpls,
                "--_1y04s53": (0, (0, parcelRequire("lD23V")).default)(`${__cmplp.width}%`)
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1bsb1mcm _4t3ipxbi _19pkpxbi _5sag9cwz _1o51q7pw _tip812c5 _j7hq1nls _1pglp3kn _bfhkqrzy _1itk1ipn _12vemgnk",
                __cmplp.className
            ])
        });
    });
    const $e1da1ba1fdde315e$var$layoutStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        paddingTop: 'space.200',
        paddingLeft: 'space.300',
        paddingRight: 'space.300'
    });
});
parcelRegister("h2FhT", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("kWrFZ"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("eNmuD"),
        $6fhBS("hPzOn"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("dZwJE")
    ]).then(()=>parcelRequire('4q3Ym'));
});
parcelRegister("hf94s", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("kWrFZ"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("eNmuD"),
        $6fhBS("hPzOn"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("70dg8")
    ]).then(()=>parcelRequire('680EM'));
});
parcelRegister("ldFMs", function(module, exports) {
    $parcel$export(module.exports, "WorkflowConditionCreate", ()=>$7c07a6c7f01f91ab$export$7c5d91d150cf4af1);
    $parcel$export(module.exports, "WorkflowConditionEdit", ()=>$7c07a6c7f01f91ab$export$be9207c31b4d0498);
    $parcel$export(module.exports, "WorkflowConditionView", ()=>$7c07a6c7f01f91ab$export$2dda4bec0d23d56e);
    var $5uXOq = parcelRequire("5uXOq");
    var $2RhPB;
    var $5pfjA;
    var $hfrz9;
    const $7c07a6c7f01f91ab$export$7c5d91d150cf4af1 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5pfjA")).CreateAnalyticsWrapper), {
            attributes: {
                ...props.analyticsAttributes,
                ...(0, (0, parcelRequire("2RhPB")).getAnalyticsAttributesFromExtension)(props.extension)
            },
            shouldFireInitializedEvent: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hfrz9")).AsyncWorkflowConditionCreateEdit), {
            ...props,
            entryPoint: "create"
        }));
    const $7c07a6c7f01f91ab$export$be9207c31b4d0498 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5pfjA")).EditAnalyticsWrapper), {
            attributes: {
                ...props.analyticsAttributes,
                ...(0, (0, parcelRequire("2RhPB")).getAnalyticsAttributesFromExtension)(props.extension)
            },
            shouldFireInitializedEvent: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hfrz9")).AsyncWorkflowConditionCreateEdit), {
            ...props,
            entryPoint: "edit"
        }));
    const $7c07a6c7f01f91ab$export$2dda4bec0d23d56e = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5pfjA")).ViewAnalyticsWrapper), {
            attributes: {
                ...props.analyticsAttributes,
                ...(0, (0, parcelRequire("2RhPB")).getAnalyticsAttributesFromExtension)(props.extension)
            },
            shouldFireInitializedEvent: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hfrz9")).AsyncWorkflowConditionView), {
            ...props,
            entryPoint: "view"
        }));
});
parcelRegister("5pfjA", function(module, exports) {
    $parcel$export(module.exports, "ViewAnalyticsWrapper", ()=>$51aaf0505517c4bc$export$c794eb4124961145);
    $parcel$export(module.exports, "EditAnalyticsWrapper", ()=>$51aaf0505517c4bc$export$947ae3996b2bc000);
    $parcel$export(module.exports, "CreateAnalyticsWrapper", ()=>$51aaf0505517c4bc$export$92d71027daa95c83);
    var $5uXOq = parcelRequire("5uXOq");
    var $k2DRb;
    var $1rTGO;
    const $51aaf0505517c4bc$export$c794eb4124961145 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeAnalyticsWrapper), {
            module: (0, (0, parcelRequire("k2DRb")).WORKFLOW_CONDITION_MODULE),
            entryPoint: (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_VIEW),
            ...props
        });
    const $51aaf0505517c4bc$export$947ae3996b2bc000 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeAnalyticsWrapper), {
            module: (0, (0, parcelRequire("k2DRb")).WORKFLOW_CONDITION_MODULE),
            entryPoint: (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT),
            ...props
        });
    const $51aaf0505517c4bc$export$92d71027daa95c83 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeAnalyticsWrapper), {
            module: (0, (0, parcelRequire("k2DRb")).WORKFLOW_CONDITION_MODULE),
            entryPoint: (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_CREATE),
            ...props
        });
});
parcelRegister("hfrz9", function(module, exports) {
    $parcel$export(module.exports, "AsyncWorkflowConditionCreateEdit", ()=>$0da674e5868c18a6$export$6006938a750a95cf);
    $parcel$export(module.exports, "AsyncWorkflowConditionView", ()=>$0da674e5868c18a6$export$f52818748d92f596);
    var $5uXOq = parcelRequire("5uXOq");
    var $k2DRb;
    var $8Lmqs;
    const $0da674e5868c18a6$export$f05650047f36f873 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8Lmqs")).LazyWorkflowCreateEdit), {
            ...props,
            module: (0, (0, parcelRequire("k2DRb")).WORKFLOW_CONDITION_MODULE)
        });
    const $0da674e5868c18a6$export$d15747c9ea39b63 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8Lmqs")).LazyWorkflowView), {
            ...props,
            module: (0, (0, parcelRequire("k2DRb")).WORKFLOW_CONDITION_MODULE)
        });
    const $0da674e5868c18a6$export$6006938a750a95cf = $0da674e5868c18a6$export$f05650047f36f873;
    const $0da674e5868c18a6$export$f52818748d92f596 = $0da674e5868c18a6$export$d15747c9ea39b63;
});
parcelRegister("8Lmqs", function(module, exports) {
    $parcel$export(module.exports, "LazyWorkflowView", ()=>$636e48b937a0dfe6$export$6d3477ca54d81ef7);
    $parcel$export(module.exports, "LazyWorkflowCreateEdit", ()=>$636e48b937a0dfe6$export$b714d2a79a4b7082);
    var $5uXOq = parcelRequire("5uXOq");
    var $1rTGO;
    var $3mOWa;
    var $lfTZh;
    var $kxZPA;
    var $bLCKr;
    const $636e48b937a0dfe6$var$skeletonConfig = [
        {
            width: 100
        },
        {
            width: 75
        }
    ];
    const $636e48b937a0dfe6$var$getModule = ()=>(parcelRequire("ecIvk"));
    const $636e48b937a0dfe6$export$7ef2c8aa7b731b0c = (0, (0, parcelRequire("1rTGO")).withMetrics)((0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>$636e48b937a0dfe6$var$getModule().then((module)=>module.ForgeUIExtension), {
        ssr: false
    }));
    const $636e48b937a0dfe6$export$6d3477ca54d81ef7 = (props)=>{
        const { entryPoint: entryPoint, extension: extension, extensionData: extensionData, module: module, ...restProps } = props;
        const loadingComponent = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bLCKr")).Skeleton), {
            skeletonConfig: $636e48b937a0dfe6$var$skeletonConfig
        });
        const extraProps = {
            entryPoint: entryPoint,
            extension: extension,
            loadingComponent: loadingComponent,
            module: module,
            extensionData: {
                ...extensionData,
                type: module,
                entryPoint: entryPoint
            }
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "workflow-view",
            fallback: loadingComponent
        }, (0, (0, parcelRequire("3mOWa")).isCustomUIExtension)(extension, entryPoint) && (0, ($parcel$interopDefault($5uXOq))).createElement($636e48b937a0dfe6$export$7ef2c8aa7b731b0c, {
            ...restProps,
            ...extraProps
        }));
    };
    const $636e48b937a0dfe6$export$b714d2a79a4b7082 = (props)=>{
        const { entryPoint: entryPoint, extension: extension, extensionData: extensionData, module: module, onSetConfigurationCallback: onSetConfigurationCallback, ...restProps } = props;
        const loadingComponent = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bLCKr")).Skeleton), {
            skeletonConfig: $636e48b937a0dfe6$var$skeletonConfig
        });
        const extraProps = {
            entryPoint: entryPoint,
            extension: extension,
            loadingComponent: loadingComponent,
            module: module,
            extensionData: {
                ...extensionData,
                type: module,
                entryPoint: entryPoint
            }
        };
        const setWorkflowRuleConfigurationCallback = (0, $5uXOq.useCallback)((param)=>{
            if (!onSetConfigurationCallback) return;
            const { onConfigure: onConfigure } = param?.data || {};
            const callbackType = typeof onConfigure;
            if (callbackType !== 'function') throw new Error('Invalid "onConfigure" callback - function expected');
            onSetConfigurationCallback(async ()=>{
                const config = await onConfigure();
                return config;
            });
        }, [
            onSetConfigurationCallback
        ]);
        const customBridgeMethods = (0, $5uXOq.useMemo)(()=>({
                setWorkflowRuleConfigurationCallback: setWorkflowRuleConfigurationCallback
            }), [
            setWorkflowRuleConfigurationCallback
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "workflow-create-edit",
            fallback: loadingComponent
        }, (0, (0, parcelRequire("3mOWa")).isCustomUIExtension)(extension, entryPoint) && (0, ($parcel$interopDefault($5uXOq))).createElement($636e48b937a0dfe6$export$7ef2c8aa7b731b0c, {
            ...restProps,
            ...extraProps,
            customBridgeMethods: customBridgeMethods
        }));
    };
});
parcelRegister("ecIvk", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("kWrFZ"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("eNmuD"),
        $6fhBS("hPzOn"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("19rY6")
    ]).then(()=>parcelRequire('3twM3'));
});
parcelRegister("45Dl2", function(module, exports) {
    $parcel$export(module.exports, "WorkflowPostFunctionCreate", ()=>$cac277ec5c99e31c$export$9c721b17de78c59f);
    $parcel$export(module.exports, "WorkflowPostFunctionEdit", ()=>$cac277ec5c99e31c$export$45d016d27d63575f);
    $parcel$export(module.exports, "WorkflowPostFunctionView", ()=>$cac277ec5c99e31c$export$d9daa48ae954d3b8);
    var $5uXOq = parcelRequire("5uXOq");
    var $2RhPB;
    var $4ywzD;
    var $lvbs1;
    const $cac277ec5c99e31c$export$9c721b17de78c59f = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4ywzD")).CreateAnalyticsWrapper), {
            attributes: {
                ...props.analyticsAttributes,
                ...(0, (0, parcelRequire("2RhPB")).getAnalyticsAttributesFromExtension)(props.extension)
            },
            shouldFireInitializedEvent: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lvbs1")).AsyncWorkflowPostFunctionCreateEdit), {
            ...props,
            entryPoint: "create"
        }));
    const $cac277ec5c99e31c$export$45d016d27d63575f = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4ywzD")).EditAnalyticsWrapper), {
            attributes: {
                ...props.analyticsAttributes,
                ...(0, (0, parcelRequire("2RhPB")).getAnalyticsAttributesFromExtension)(props.extension)
            },
            shouldFireInitializedEvent: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lvbs1")).AsyncWorkflowPostFunctionCreateEdit), {
            ...props,
            entryPoint: "edit"
        }));
    const $cac277ec5c99e31c$export$d9daa48ae954d3b8 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4ywzD")).ViewAnalyticsWrapper), {
            attributes: {
                ...props.analyticsAttributes,
                ...(0, (0, parcelRequire("2RhPB")).getAnalyticsAttributesFromExtension)(props.extension)
            },
            shouldFireInitializedEvent: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lvbs1")).AsyncWorkflowPostFunctionView), {
            ...props,
            entryPoint: "view"
        }));
});
parcelRegister("4ywzD", function(module, exports) {
    $parcel$export(module.exports, "ViewAnalyticsWrapper", ()=>$10121893bdf026ba$export$c794eb4124961145);
    $parcel$export(module.exports, "EditAnalyticsWrapper", ()=>$10121893bdf026ba$export$947ae3996b2bc000);
    $parcel$export(module.exports, "CreateAnalyticsWrapper", ()=>$10121893bdf026ba$export$92d71027daa95c83);
    var $5uXOq = parcelRequire("5uXOq");
    var $k2DRb;
    var $1rTGO;
    const $10121893bdf026ba$export$c794eb4124961145 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeAnalyticsWrapper), {
            module: (0, (0, parcelRequire("k2DRb")).WORKFLOW_POST_FUNCTION_MODULE),
            entryPoint: (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_VIEW),
            ...props
        });
    const $10121893bdf026ba$export$947ae3996b2bc000 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeAnalyticsWrapper), {
            module: (0, (0, parcelRequire("k2DRb")).WORKFLOW_POST_FUNCTION_MODULE),
            entryPoint: (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT),
            ...props
        });
    const $10121893bdf026ba$export$92d71027daa95c83 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeAnalyticsWrapper), {
            module: (0, (0, parcelRequire("k2DRb")).WORKFLOW_POST_FUNCTION_MODULE),
            entryPoint: (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_CREATE),
            ...props
        });
});
parcelRegister("lvbs1", function(module, exports) {
    $parcel$export(module.exports, "AsyncWorkflowPostFunctionCreateEdit", ()=>$7bd477c6d4e7b433$export$cfa61e5559ea0dce);
    $parcel$export(module.exports, "AsyncWorkflowPostFunctionView", ()=>$7bd477c6d4e7b433$export$87ba64d38cfeb16a);
    var $5uXOq = parcelRequire("5uXOq");
    var $k2DRb;
    var $8Lmqs;
    const $7bd477c6d4e7b433$export$86604dfb04526e1a = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8Lmqs")).LazyWorkflowCreateEdit), {
            ...props,
            module: (0, (0, parcelRequire("k2DRb")).WORKFLOW_POST_FUNCTION_MODULE)
        });
    const $7bd477c6d4e7b433$export$db5b75fe29c72654 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8Lmqs")).LazyWorkflowView), {
            ...props,
            module: (0, (0, parcelRequire("k2DRb")).WORKFLOW_POST_FUNCTION_MODULE)
        });
    const $7bd477c6d4e7b433$export$cfa61e5559ea0dce = $7bd477c6d4e7b433$export$86604dfb04526e1a;
    const $7bd477c6d4e7b433$export$87ba64d38cfeb16a = $7bd477c6d4e7b433$export$db5b75fe29c72654;
});
parcelRegister("i6C3Q", function(module, exports) {
    $parcel$export(module.exports, "WorkflowValidatorCreate", ()=>$4f7cda6f50bc7e48$export$b131ee6ae33375f7);
    $parcel$export(module.exports, "WorkflowValidatorEdit", ()=>$4f7cda6f50bc7e48$export$295e28146293a786);
    $parcel$export(module.exports, "WorkflowValidatorView", ()=>$4f7cda6f50bc7e48$export$8a0a1dfbf1819d74);
    var $5uXOq = parcelRequire("5uXOq");
    var $2RhPB;
    var $j5IMH;
    var $gPjRV;
    const $4f7cda6f50bc7e48$export$b131ee6ae33375f7 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j5IMH")).CreateAnalyticsWrapper), {
            attributes: {
                ...props.analyticsAttributes,
                ...(0, (0, parcelRequire("2RhPB")).getAnalyticsAttributesFromExtension)(props.extension)
            },
            shouldFireInitializedEvent: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gPjRV")).AsyncWorkflowValidatorCreateEdit), {
            ...props,
            entryPoint: "create"
        }));
    const $4f7cda6f50bc7e48$export$295e28146293a786 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j5IMH")).EditAnalyticsWrapper), {
            attributes: {
                ...props.analyticsAttributes,
                ...(0, (0, parcelRequire("2RhPB")).getAnalyticsAttributesFromExtension)(props.extension)
            },
            shouldFireInitializedEvent: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gPjRV")).AsyncWorkflowValidatorCreateEdit), {
            ...props,
            entryPoint: "edit"
        }));
    const $4f7cda6f50bc7e48$export$8a0a1dfbf1819d74 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j5IMH")).ViewAnalyticsWrapper), {
            attributes: {
                ...props.analyticsAttributes,
                ...(0, (0, parcelRequire("2RhPB")).getAnalyticsAttributesFromExtension)(props.extension)
            },
            shouldFireInitializedEvent: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gPjRV")).AsyncWorkflowValidatorView), {
            ...props,
            entryPoint: "view"
        }));
});
parcelRegister("j5IMH", function(module, exports) {
    $parcel$export(module.exports, "ViewAnalyticsWrapper", ()=>$d6fabfc8ce024dcd$export$c794eb4124961145);
    $parcel$export(module.exports, "EditAnalyticsWrapper", ()=>$d6fabfc8ce024dcd$export$947ae3996b2bc000);
    $parcel$export(module.exports, "CreateAnalyticsWrapper", ()=>$d6fabfc8ce024dcd$export$92d71027daa95c83);
    var $5uXOq = parcelRequire("5uXOq");
    var $k2DRb;
    var $1rTGO;
    const $d6fabfc8ce024dcd$export$c794eb4124961145 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeAnalyticsWrapper), {
            module: (0, (0, parcelRequire("k2DRb")).WORKFLOW_VALIDATOR_MODULE),
            entryPoint: (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_VIEW),
            ...props
        });
    const $d6fabfc8ce024dcd$export$947ae3996b2bc000 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeAnalyticsWrapper), {
            module: (0, (0, parcelRequire("k2DRb")).WORKFLOW_VALIDATOR_MODULE),
            entryPoint: (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT),
            ...props
        });
    const $d6fabfc8ce024dcd$export$92d71027daa95c83 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeAnalyticsWrapper), {
            module: (0, (0, parcelRequire("k2DRb")).WORKFLOW_VALIDATOR_MODULE),
            entryPoint: (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_CREATE),
            ...props
        });
});
parcelRegister("gPjRV", function(module, exports) {
    $parcel$export(module.exports, "AsyncWorkflowValidatorCreateEdit", ()=>$11384f1439a1e41c$export$54de7ff67b273896);
    $parcel$export(module.exports, "AsyncWorkflowValidatorView", ()=>$11384f1439a1e41c$export$9b5553aeb9e69415);
    var $5uXOq = parcelRequire("5uXOq");
    var $k2DRb;
    var $8Lmqs;
    const $11384f1439a1e41c$export$b86c3ac240c092a8 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8Lmqs")).LazyWorkflowCreateEdit), {
            ...props,
            module: (0, (0, parcelRequire("k2DRb")).WORKFLOW_VALIDATOR_MODULE)
        });
    const $11384f1439a1e41c$export$7547b0d39a9ad099 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8Lmqs")).LazyWorkflowView), {
            ...props,
            module: (0, (0, parcelRequire("k2DRb")).WORKFLOW_VALIDATOR_MODULE)
        });
    const $11384f1439a1e41c$export$54de7ff67b273896 = $11384f1439a1e41c$export$b86c3ac240c092a8;
    const $11384f1439a1e41c$export$9b5553aeb9e69415 = $11384f1439a1e41c$export$7547b0d39a9ad099;
});
parcelRegister("iKCxj", function(module, exports) {
    $parcel$export(module.exports, "sendStartMarkForLegacyIssueCreate", ()=>$d3fc7ef6ccbf5390$export$7dbcbf47a4e0e00f);
    $parcel$export(module.exports, "sendEndMarkForLegacyIssueCreate", ()=>$d3fc7ef6ccbf5390$export$b5c5d408bb7fd104);
    var $dvqhI;
    var $ccNll;
    const $d3fc7ef6ccbf5390$export$7dbcbf47a4e0e00f = ({ isInitialLoad: isInitialLoad, triggerSource: triggerSource })=>{
        if (typeof isInitialLoad === 'boolean' && triggerSource !== undefined) {
            if (isInitialLoad) (0, (0, parcelRequire("ccNll")).legacyIssueCreateInitialRenderInteraction).start();
            else (0, (0, parcelRequire("ccNll")).legacyIssueCreateTransitionInteraction).start();
            (0, (0, parcelRequire("ccNll")).legacyIssueCreateCombinedInteraction).start();
        }
    };
    const $d3fc7ef6ccbf5390$export$b5c5d408bb7fd104 = async ({ isInitialLoad: isInitialLoad, triggerSource: triggerSource })=>{
        const Events = await (0, (0, parcelRequire("dvqhI")).apiReady)();
        const onCreateIssueDialogRendered = ()=>{
            if (typeof isInitialLoad === 'boolean') {
                if (isInitialLoad) (0, (0, parcelRequire("ccNll")).legacyIssueCreateInitialRenderInteraction).stop({
                    customData: {
                        triggerSource: triggerSource,
                        isGICPrefetchEnabled: true
                    }
                });
                else (0, (0, parcelRequire("ccNll")).legacyIssueCreateTransitionInteraction).stop({
                    customData: {
                        triggerSource: triggerSource,
                        isGICPrefetchEnabled: true
                    }
                });
                (0, (0, parcelRequire("ccNll")).legacyIssueCreateCombinedInteraction).stop({
                    customData: {
                        triggerSource: triggerSource,
                        isGICPrefetchEnabled: true
                    }
                });
            }
            Events.unbind('createIssueDialogRendered', onCreateIssueDialogRendered);
        };
        Events.bind('createIssueDialogRendered', onCreateIssueDialogRendered);
    };
});
parcelRegister("dvqhI", function(module, exports) {
    $parcel$export(module.exports, "apiReady", ()=>$548c078b9ef136c5$export$330fb1a04c974aad);
    var $6BZYo;
    let $548c078b9ef136c5$var$currentBridge;
    const $548c078b9ef136c5$export$330fb1a04c974aad = async ()=>{
        if ($548c078b9ef136c5$var$currentBridge) return $548c078b9ef136c5$var$currentBridge;
        const Events = await (0, (0, parcelRequire("6BZYo")).loadBridge)({
            name: 'jira/util/events',
            wrmKeys: 'wrc!jira.webresources:jira-events'
        });
        $548c078b9ef136c5$var$currentBridge = Events;
        return Events;
    };
});
parcelRegister("ccNll", function(module, exports) {
    $parcel$export(module.exports, "legacyIssueCreateInitialRenderInteraction", ()=>$d1196bb7209b7b8b$export$2bc24132dfd54487);
    $parcel$export(module.exports, "legacyIssueCreateTransitionInteraction", ()=>$d1196bb7209b7b8b$export$ba0054dee3105c90);
    $parcel$export(module.exports, "legacyIssueCreateCombinedInteraction", ()=>$d1196bb7209b7b8b$export$52353a8848646ccd);
    var $dGrdR;
    const $d1196bb7209b7b8b$export$2bc24132dfd54487 = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'legacy-issue-create.initial-render'
    });
    const $d1196bb7209b7b8b$export$ba0054dee3105c90 = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'legacy-issue-create.transition'
    });
    const $d1196bb7209b7b8b$export$52353a8848646ccd = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'legacy-issue-create.combined'
    });
});
const $de7681b8672bb382$export$d983e94cf86f65d = 'global-create-button';
const $de7681b8672bb382$export$7e22286cafe320a7 = 'keyboard-shortcut';
parcelRegister("9YT0J", function(module, exports) {
    $parcel$export(module.exports, "useInitializePersistentIssueCreateModalView", ()=>$d36676d86816c70e$export$9086117e14a55509);
    var $5uXOq = parcelRequire("5uXOq");
    var $2vSU6;
    var $c0dQj;
    var $5rzqz;
    var $dAfqg;
    var $cn7wb;
    const $d36676d86816c70e$export$9086117e14a55509 = ()=>{
        const [{ shouldShowMiniModalSpotlight: shouldShowMiniModalSpotlight }, { setShouldPushMiniModalDiscoverability: setShouldPushMiniModalDiscoverability, setPersistentModalView: setPersistentModalView }] = (0, (0, parcelRequire("5rzqz")).useTriggerIssueCreateModal)();
        const { data: projectContext } = (0, (0, parcelRequire("dAfqg")).useProjectContext)();
        const hasUserSeenMiniModal = (0, $5uXOq.useRef)(false);
        const isJSM = projectContext?.projectType === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT);
        const { data: resource } = (0, (0, parcelRequire("cn7wb")).usePersistentIssueCreateModalViewResource)();
        const isMobileExperienceEnabled = (0, (0, parcelRequire("c0dQj")).isMobileAndInMVPExperiment)();
        (0, $5uXOq.useEffect)(()=>{
            setPersistentModalView(resource?.view || 'modal');
        }, []);
        (0, $5uXOq.useEffect)(()=>{
            if (resource?.isDiscoPushComplete || hasUserSeenMiniModal.current) setShouldPushMiniModalDiscoverability(false);
            else setShouldPushMiniModalDiscoverability(!isJSM && !isMobileExperienceEnabled);
        }, [
            resource?.isDiscoPushComplete,
            setShouldPushMiniModalDiscoverability,
            isJSM,
            isMobileExperienceEnabled
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (shouldShowMiniModalSpotlight && !hasUserSeenMiniModal.current) hasUserSeenMiniModal.current = true;
        }, [
            shouldShowMiniModalSpotlight
        ]);
        return null;
    };
});
parcelRegister("5rzqz", function(module, exports) {
    $parcel$export(module.exports, "useTriggerIssueCreateModal", ()=>$0a31d64d52b4b5e9$export$71e0eb7b8a91a156);
    $parcel$export(module.exports, "useOpenIssueCreateModal", ()=>$0a31d64d52b4b5e9$export$47cf258b1046080b);
    $parcel$export(module.exports, "withNewGICProvider", ()=>$0a31d64d52b4b5e9$export$558a195c5bfe3787);
    var $5uXOq = parcelRequire("5uXOq");
    var $7Jxgt = parcelRequire("7Jxgt");
    var $co1wz;
    var $4R6GH;
    var $1Nspv;
    var $bGrWH;
    var $8zOmE;
    var $2NMNM;
    var $f5nIZ;
    var $2YP9W;
    var $3sQ5x;
    var $iXqE5;
    var $51nlc;
    var $hULKg;
    var $cKWWX;
    var $6k7U1;
    const $0a31d64d52b4b5e9$export$d4c72bab9d6cc13a = {
        inProgressIssueID: undefined,
        isModalOpen: false,
        isSubsequentLoad: false,
        isSubTaskCreationOpen: false,
        isCreateLinkedIssue: false,
        wasStateRestored: false,
        payload: null,
        id: 'create-issue-dialog',
        title: null,
        sectionMessage: null,
        submitErrorMessage: undefined,
        globalEventNamespaces: [
            (0, (0, parcelRequire("bGrWH")).GLOBAL_EVENT_NAMESPACE)
        ],
        fieldsToAddInPublishedData: [],
        alertsToBeLinked: undefined,
        onIssueCreate: null,
        onClose: null,
        displayOnlyRequiredFields: false,
        disableCreationSuccessFlag: false,
        displayCreateAnotherIssueToggle: true,
        displaySitePicker: false,
        disableProjectDropdown: false,
        disableIssueTypeDropdown: false,
        triggerPointKey: (0, (0, parcelRequire("hULKg")).TRIGGER_POINT_KEY_NOT_DEFINED),
        context: undefined,
        minimizableModalView: 'modal',
        persistentModalView: undefined,
        shouldPushMiniModalDiscoverability: undefined,
        shouldShowMiniModalSpotlight: undefined,
        wasModalRetriggered: false,
        openIssueCreateModalNextState: {},
        shouldInitFetchConfig: false,
        interactedWithModalChangerButtons: false,
        isModalLoadInContext: false,
        disableMinimizableModal: false,
        displayMinimizableButtons: true,
        displayHeaderActionContainer: true,
        overrideUseRequestTypeFieldsToggleState: undefined,
        containerStyle: {},
        isMiniDesignEnabled: false,
        isEnrolledAndTargetedForGicMultipleLayouts: false,
        sessionId: undefined,
        FieldWrapperComponent: undefined,
        ExternalControls: undefined,
        isCustomBoardWithIcc: false,
        jqlSwimlaneId: undefined,
        swimlaneMode: undefined,
        prefilledCustomFields: {},
        ecosystemCallback: undefined,
        product: undefined,
        actionSource: undefined
    };
    const $0a31d64d52b4b5e9$export$e324594224ef24da = {
        restoreState: (stateToRestore)=>({ setState: setState, getState: getState })=>{
                const { isSubsequentLoad: isSubsequentLoad, isModalOpen: isModalOpen } = getState();
                if (!isModalOpen && stateToRestore.isModalOpen) (0, (0, parcelRequire("6k7U1")).startGlobalIssueCreateMetrics)(isSubsequentLoad);
                setState(stateToRestore);
            },
        openIssueCreateModal: (nextState)=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                if (currentState.isModalOpen) setState({
                    wasModalRetriggered: true,
                    openIssueCreateModalNextState: nextState
                });
                else {
                    (0, (0, parcelRequire("6k7U1")).startGlobalIssueCreateMetrics)(currentState.isSubsequentLoad);
                    const isDiscoverabilityPushEnabled = !currentState.isEnrolledAndTargetedForGicMultipleLayouts;
                    const isDiscoPushTriggerPoint = isDiscoverabilityPushEnabled && (0, (0, parcelRequire("6k7U1")).isPartOfDiscoverabilityTriggers)(nextState?.triggerPointKey);
                    const nextModalView = isDiscoverabilityPushEnabled ? (0, (0, parcelRequire("6k7U1")).getNextModalView)(isDiscoPushTriggerPoint, currentState.persistentModalView, $0a31d64d52b4b5e9$export$d4c72bab9d6cc13a.minimizableModalView) : 'modal';
                    setState({
                        ...$0a31d64d52b4b5e9$export$d4c72bab9d6cc13a,
                        ...isDiscoverabilityPushEnabled && {
                            minimizableModalView: nextModalView,
                            persistentModalView: currentState.persistentModalView,
                            ...(0, (0, parcelRequire("6k7U1")).discoverabilityPushModalViewOverride)(currentState.shouldPushMiniModalDiscoverability, isDiscoPushTriggerPoint, nextModalView)
                        },
                        inProgressIssueID: (0, ($parcel$interopDefault($7Jxgt))).v4(),
                        isSubsequentLoad: currentState.isSubsequentLoad,
                        isModalOpen: true,
                        wasModalRetriggered: currentState.isModalOpen,
                        ...nextState
                    });
                }
            },
        closeIssueCreateModal: (hasIssueCreated)=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                const isDiscoverabilityPushEnabled = !currentState.isEnrolledAndTargetedForGicMultipleLayouts;
                const isValidDiscoPushTriggerPoint = isDiscoverabilityPushEnabled && (0, (0, parcelRequire("6k7U1")).isPartOfDiscoverabilityTriggers)(currentState.triggerPointKey);
                const shouldUpdatePersistentModalView = isDiscoverabilityPushEnabled && hasIssueCreated && isValidDiscoPushTriggerPoint;
                const shouldPersistDiscoverabilityState = isDiscoverabilityPushEnabled && currentState.shouldPushMiniModalDiscoverability && !isValidDiscoPushTriggerPoint;
                setState({
                    ...$0a31d64d52b4b5e9$export$d4c72bab9d6cc13a,
                    callbackPayload: undefined,
                    ...shouldPersistDiscoverabilityState && {
                        shouldPushMiniModalDiscoverability: true
                    },
                    ...isDiscoverabilityPushEnabled && {
                        persistentModalView: currentState.persistentModalView
                    },
                    ...shouldUpdatePersistentModalView && {
                        persistentModalView: currentState.minimizableModalView === 'mini' ? 'mini' : 'modal'
                    },
                    isModalOpen: false,
                    isSubsequentLoad: true
                });
                (0, (0, parcelRequire("1Nspv")).CreateIssueUFOExperience).abort({
                    metadata: {
                        wasStateRestored: getState().wasStateRestored
                    }
                });
            },
        clearSectionMessage: ()=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                setState({
                    ...currentState,
                    sectionMessage: null
                });
            },
        setDisplayOnlyRequiredFields: (nextState)=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                setState({
                    ...currentState,
                    displayOnlyRequiredFields: nextState
                });
            },
        setMinimizableModalView: (minimizableModalView)=>({ setState: setState })=>{
                setState({
                    minimizableModalView: minimizableModalView,
                    interactedWithModalChangerButtons: true
                });
            },
        resetWasModalRetriggered: (applyNextState = false)=>({ setState: setState, getState: getState })=>{
                if (applyNextState) {
                    const currentState = getState();
                    (0, (0, parcelRequire("6k7U1")).startGlobalIssueCreateMetrics)(currentState.isSubsequentLoad);
                    setState({
                        ...$0a31d64d52b4b5e9$export$d4c72bab9d6cc13a,
                        ...!currentState.isEnrolledAndTargetedForGicMultipleLayouts && {
                            persistentModalView: currentState.persistentModalView
                        },
                        inProgressIssueID: currentState.inProgressIssueID,
                        isSubsequentLoad: currentState.isSubsequentLoad,
                        isModalOpen: true,
                        wasModalRetriggered: false,
                        minimizableModalView: 'modal',
                        openIssueCreateModalNextState: {},
                        shouldInitFetchConfig: true,
                        ...currentState.openIssueCreateModalNextState
                    });
                } else setState({
                    minimizableModalView: 'modal',
                    wasModalRetriggered: false,
                    openIssueCreateModalNextState: {}
                });
            },
        resetShouldInitFetchConfig: ()=>({ setState: setState })=>{
                setState({
                    shouldInitFetchConfig: false
                });
            },
        setCallbackPayload: (callbackPayload)=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                setState({
                    callbackPayload: {
                        id: currentState.callbackPayload?.id ?? '',
                        data: callbackPayload
                    }
                });
            },
        setShouldPushMiniModalDiscoverability: (shouldPushMiniModalDiscoverability)=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                if (currentState.isEnrolledAndTargetedForGicMultipleLayouts) return;
                setState({
                    shouldPushMiniModalDiscoverability: shouldPushMiniModalDiscoverability
                });
            },
        dismissModalSpotlight: ()=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                if (currentState.isEnrolledAndTargetedForGicMultipleLayouts) return;
                setState({
                    shouldShowMiniModalSpotlight: false
                });
            },
        setPersistentModalView: (persistentModalView)=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                if (currentState.isEnrolledAndTargetedForGicMultipleLayouts) return;
                setState({
                    persistentModalView: persistentModalView
                });
            },
        removeAlertFromAlertsToBeLinked: (alertId)=>({ setState: setState, getState: getState })=>{
                if (!(0, (0, parcelRequire("co1wz")).ff)('linked-alerts-in-gic_onvr6')) return;
                const currentState = getState();
                const alertsToBeLinked = currentState.alertsToBeLinked?.filter((entry)=>entry !== alertId);
                setState({
                    alertsToBeLinked: alertsToBeLinked
                });
            },
        setSessionId: (sessionId)=>({ setState: setState })=>{
                setState({
                    sessionId: sessionId
                });
            }
    };
    const $0a31d64d52b4b5e9$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'issue-create.issue-create-form.use-trigger-issue-create-modal',
        initialState: $0a31d64d52b4b5e9$export$d4c72bab9d6cc13a,
        actions: $0a31d64d52b4b5e9$export$e324594224ef24da
    });
    const $0a31d64d52b4b5e9$var$useTriggerIssueCreateModalState = (0, (0, parcelRequire("3sQ5x")).createStateHook)($0a31d64d52b4b5e9$var$store);
    const $0a31d64d52b4b5e9$var$useTriggerIssueCreateModalActions = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($0a31d64d52b4b5e9$var$store);
    const $0a31d64d52b4b5e9$export$71e0eb7b8a91a156 = ()=>{
        const createState = $0a31d64d52b4b5e9$var$useTriggerIssueCreateModalState();
        const createActions = $0a31d64d52b4b5e9$var$useTriggerIssueCreateModalActions();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { openIssueCreateModal: openIssueCreateModal, ...otherActions } = createActions;
        const openIssueCreateModalWithAnalytics = (0, $5uXOq.useCallback)((nextState)=>{
            const analyticsEvent = createAnalyticsEvent({});
            const attributes = {
                gicViewState: nextState?.minimizableModalView,
                isSubtask: nextState?.isSubTaskCreationOpen,
                triggerPointKey: nextState?.triggerPointKey,
                ...(0, (0, parcelRequire("4R6GH")).fg)('new-attributes-for-issue-create-anywhere') ? {
                    ...nextState?.product ? {
                        product: nextState.product
                    } : {},
                    ...nextState?.actionSource ? {
                        actionSource: nextState.actionSource
                    } : {}
                } : {}
            };
            (0, (0, parcelRequire("8zOmE")).fireTrackAnalyticsDeferred)(analyticsEvent, 'issueCreateModal triggered', attributes);
            openIssueCreateModal(nextState);
        }, [
            createAnalyticsEvent,
            openIssueCreateModal
        ]);
        return [
            createState,
            {
                ...otherActions,
                openIssueCreateModal: openIssueCreateModalWithAnalytics
            }
        ];
    };
    const $0a31d64d52b4b5e9$export$47cf258b1046080b = ()=>{
        const { openIssueCreateModal: openIssueCreateModal } = $0a31d64d52b4b5e9$var$useTriggerIssueCreateModalActions();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const [, { setSitePickerState: setSitePickerState }] = (0, (0, parcelRequire("cKWWX")).useSitePickerContext)();
        return (0, $5uXOq.useCallback)((nextState)=>{
            const analyticsEvent = createAnalyticsEvent({});
            const attributes = {
                gicViewState: nextState?.minimizableModalView,
                isSubtask: nextState?.isSubTaskCreationOpen,
                triggerPointKey: nextState?.triggerPointKey,
                ...(0, (0, parcelRequire("4R6GH")).fg)('new-attributes-for-issue-create-anywhere') ? {
                    ...nextState?.product ? {
                        product: nextState.product
                    } : {},
                    ...nextState?.actionSource ? {
                        actionSource: nextState.actionSource
                    } : {}
                } : {}
            };
            (0, (0, parcelRequire("8zOmE")).fireTrackAnalyticsDeferred)(analyticsEvent, 'issueCreateModal triggered', attributes);
            if (nextState?.payload?.overrideCloudId && (0, (0, parcelRequire("4R6GH")).fg)('jira_ai_issue_create_site_user_preference')) setSitePickerState({
                selectedCloudId: nextState?.payload?.overrideCloudId
            });
            openIssueCreateModal(nextState);
        }, [
            createAnalyticsEvent,
            openIssueCreateModal,
            setSitePickerState
        ]);
    };
    const $0a31d64d52b4b5e9$export$4c674fe824b8f278 = (0, (0, parcelRequire("51nlc")).createSubscriber)($0a31d64d52b4b5e9$var$store);
    function $0a31d64d52b4b5e9$export$558a195c5bfe3787(WrappedComponent) {
        return (props)=>{
            const [, { openIssueCreateModal: openIssueCreateModal, closeIssueCreateModal: closeIssueCreateModal }] = $0a31d64d52b4b5e9$export$71e0eb7b8a91a156();
            const { data: data } = (0, (0, parcelRequire("2YP9W")).useResource)((0, (0, parcelRequire("f5nIZ")).gicOptInStatusResources));
            const currentIssueCreateVisibility = data && data?.gicOptInStatus;
            return (0, ($parcel$interopDefault($5uXOq))).createElement(WrappedComponent, {
                openIssueCreateModal: openIssueCreateModal,
                closeIssueCreateModal: closeIssueCreateModal,
                currentIssueCreateVisibility: currentIssueCreateVisibility?.isEnabled || false,
                ...props
            });
        };
    }
});
parcelRegister("1Nspv", function(module, exports) {
    $parcel$export(module.exports, "CreateIssueUFOExperience", ()=>$ff7fb599b8d98642$export$cd75c112ac11a83b);
    $parcel$export(module.exports, "StatusFieldLoad", ()=>$ff7fb599b8d98642$export$e890e797a25e655c);
    $parcel$export(module.exports, "GlobalIssueCreateModalSubmitUFOExperience", ()=>$ff7fb599b8d98642$export$70208657c8d471a7);
    $parcel$export(module.exports, "EcosystemAPIPrefillingUFOExperience", ()=>$ff7fb599b8d98642$export$b2d8f8edec75f571);
    var $atdLB;
    var $duUGa;
    const $ff7fb599b8d98642$export$cd75c112ac11a83b = new (0, (0, parcelRequire("duUGa")).UFOExperience)('global-issue-create.create-issue', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Experience,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).Custom,
        category: 'global-issue-create.create-issue'
    });
    const $ff7fb599b8d98642$export$e890e797a25e655c = new (0, (0, parcelRequire("duUGa")).UFOExperience)('global-issue-create.create-issue.status-field', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Load,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).PageSegmentLoad
    });
    const $ff7fb599b8d98642$export$70208657c8d471a7 = new (0, (0, parcelRequire("duUGa")).UFOExperience)('modern-global-issue-create.modal.issue-submit', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Experience,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).Custom,
        category: 'modern-global-issue-create.modal.issue-submit'
    });
    const $ff7fb599b8d98642$export$b2d8f8edec75f571 = new (0, (0, parcelRequire("duUGa")).UFOExperience)('ecosystem-api-new-gic-prefilling', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Experience,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).Custom,
        category: 'ecosystem-api-new-gic-prefilling'
    });
});
parcelRegister("bGrWH", function(module, exports) {
    $parcel$export(module.exports, "REQUIRED", ()=>$4aabf2a0e09eb856$export$b1c05b34d317c074);
    $parcel$export(module.exports, "FORM_DROPZONE_CONTAINER_ID", ()=>$4aabf2a0e09eb856$export$1a020bd73f33e5a);
    $parcel$export(module.exports, "MINIMISED_DROPZONE_CONTAINER_ID", ()=>$4aabf2a0e09eb856$export$9eadc594a5383580);
    $parcel$export(module.exports, "LOG_WORK_TIME_SPENT_ERROR_KEY", ()=>$4aabf2a0e09eb856$export$a1a7caa1e4a73c9f);
    $parcel$export(module.exports, "LOG_WORK_TIME_REMAINING_ERROR_KEY", ()=>$4aabf2a0e09eb856$export$cf70f9220e6a2f50);
    $parcel$export(module.exports, "LOG_WORK_DATE_ERROR_KEY", ()=>$4aabf2a0e09eb856$export$aac4661200ba4104);
    $parcel$export(module.exports, "LOG_WORK_DESCRIPTION_ERROR_KEY", ()=>$4aabf2a0e09eb856$export$a23c32d5cf54cba0);
    $parcel$export(module.exports, "COMMON_LOG_WORK_ERROR_KEYS", ()=>$4aabf2a0e09eb856$export$74ab3898ae683660);
    $parcel$export(module.exports, "MULTI_INPUT_FIELDS_ERROR_KEYS", ()=>$4aabf2a0e09eb856$export$983715bb9b0f9b2c);
    $parcel$export(module.exports, "DEFAULT_SCROLL_OPTIONS", ()=>$4aabf2a0e09eb856$export$40f6793dd75f4e52);
    $parcel$export(module.exports, "UNSUPPORTED_FIELD_TYPES_IN_GIC", ()=>$4aabf2a0e09eb856$export$3bf5bce483cc2c30);
    $parcel$export(module.exports, "ISSUE_CREATE_TYPE", ()=>$4aabf2a0e09eb856$export$30cc1fd33e7c7258);
    $parcel$export(module.exports, "GLOBAL_EVENT_NAMESPACE", ()=>$4aabf2a0e09eb856$export$8170252f0ea43511);
    const $4aabf2a0e09eb856$export$17bcab948893a9ba = 'worklog';
    const $4aabf2a0e09eb856$export$b1c05b34d317c074 = 'REQUIRED';
    const $4aabf2a0e09eb856$export$f10b32dfda12e96e = 'unknown';
    const $4aabf2a0e09eb856$export$e5e4b8c32e68dd0b = 'networkError';
    const $4aabf2a0e09eb856$export$2d06efb8146cd043 = 'graphQLError';
    const $4aabf2a0e09eb856$export$1a020bd73f33e5a = 'issue-create-modal-dropzone-container';
    const $4aabf2a0e09eb856$export$9eadc594a5383580 = 'minimised-issue-create-dropzone-container';
    const $4aabf2a0e09eb856$export$a1a7caa1e4a73c9f = 'worklog_timeLogged';
    const $4aabf2a0e09eb856$export$cf70f9220e6a2f50 = 'worklog_newEstimate';
    const $4aabf2a0e09eb856$export$aac4661200ba4104 = 'worklog_startDate';
    const $4aabf2a0e09eb856$export$a23c32d5cf54cba0 = 'worklog_description';
    const $4aabf2a0e09eb856$export$74ab3898ae683660 = [
        'worklog_adjustmentAmount',
        'worklog_adjustEstimate',
        'worklog_activate'
    ];
    const $4aabf2a0e09eb856$export$63fd567c1d0aca19 = [
        ...$4aabf2a0e09eb856$export$74ab3898ae683660,
        $4aabf2a0e09eb856$export$a1a7caa1e4a73c9f,
        $4aabf2a0e09eb856$export$cf70f9220e6a2f50,
        $4aabf2a0e09eb856$export$aac4661200ba4104,
        $4aabf2a0e09eb856$export$a23c32d5cf54cba0
    ];
    const $4aabf2a0e09eb856$export$983715bb9b0f9b2c = {
        [$4aabf2a0e09eb856$export$17bcab948893a9ba]: $4aabf2a0e09eb856$export$63fd567c1d0aca19
    };
    const $4aabf2a0e09eb856$export$40f6793dd75f4e52 = {
        behavior: 'auto',
        scrollMode: 'if-needed',
        block: 'start'
    };
    const $4aabf2a0e09eb856$export$3bf5bce483cc2c30 = [
        'com.atlassian.servicedesk:sd-request-participants'
    ];
    const $4aabf2a0e09eb856$export$30cc1fd33e7c7258 = {
        linkedIssue: 'LINKED_ISSUE_CREATE',
        standard: 'STANDARD'
    };
    const $4aabf2a0e09eb856$export$8170252f0ea43511 = 'QuickCreateIssue';
});
parcelRegister("hULKg", function(module, exports) {
    $parcel$export(module.exports, "GENERIC_FORGE_USE_PRODUCT_MODALS", ()=>$62659eb193cd5c9c$export$a05673a22505d5ee);
    $parcel$export(module.exports, "GENERIC_GLOBAL_CREATE_BUTTON", ()=>$62659eb193cd5c9c$export$c78d329777576aa0);
    $parcel$export(module.exports, "MOBILE_GLOBAL_CREATE_BUTTON", ()=>$62659eb193cd5c9c$export$992ab052fd10f8e8);
    $parcel$export(module.exports, "GENERIC_KEYBOARD_SHORTCUT", ()=>$62659eb193cd5c9c$export$affdc3af7a9a4677);
    $parcel$export(module.exports, "JSW_ISSUE_VIEW_CREATE_ISSUE_IN_EPIC", ()=>$62659eb193cd5c9c$export$7e9cfd1f59e60910);
    $parcel$export(module.exports, "JSW_ISSUE_VIEW_CREATE_SUBTASK", ()=>$62659eb193cd5c9c$export$708499dcd016e5e9);
    $parcel$export(module.exports, "JSW_ISSUE_VIEW_AI_BREAKDOWN_CHILD_ISSUE", ()=>$62659eb193cd5c9c$export$e1c8789144c4c810);
    $parcel$export(module.exports, "JSW_ISSUE_VIEW_AI_BREAKDOWN_SUBTASK", ()=>$62659eb193cd5c9c$export$c5afabb7a17e802);
    $parcel$export(module.exports, "JSW_RELEASES_CREATE_ISSUE", ()=>$62659eb193cd5c9c$export$2086a63173b79b56);
    $parcel$export(module.exports, "JSW_ROADMAP_CREATE_ISSUE", ()=>$62659eb193cd5c9c$export$fe1e5e4597147709);
    $parcel$export(module.exports, "JSW_SECURITY_CREATE_ISSUE", ()=>$62659eb193cd5c9c$export$9bc326812a93707);
    $parcel$export(module.exports, "JSM_CALENDAR_CREATE_CHANGE_REQUEST", ()=>$62659eb193cd5c9c$export$f352934e06f44599);
    $parcel$export(module.exports, "JSM_CREATE_INCIDENT_FROM_ALERT_DETAIL", ()=>$62659eb193cd5c9c$export$6ae07c77acc64360);
    $parcel$export(module.exports, "JSM_CREATE_INCIDENT_FROM_ALERT_LIST", ()=>$62659eb193cd5c9c$export$b41d0684e4b6ab1);
    $parcel$export(module.exports, "JSM_EMPTY_QUEUE_CREATE_REQUEST", ()=>$62659eb193cd5c9c$export$be9b2b1329ff7ec9);
    $parcel$export(module.exports, "JSW_CREATE_ISSUE_DIALOG", ()=>$62659eb193cd5c9c$export$aa348159181703a0);
    $parcel$export(module.exports, "ISSUE_VIEW_CREATE_LINKED_ISSUE", ()=>$62659eb193cd5c9c$export$f90eec3841adf619);
    $parcel$export(module.exports, "COMMAND_PALETTE_CREATE_LINKED_ISSUE", ()=>$62659eb193cd5c9c$export$718822a309bffada);
    $parcel$export(module.exports, "SERVICE_DESK_LINKED_ISSUE_TRIGEER", ()=>$62659eb193cd5c9c$export$2753dafac81e805c);
    $parcel$export(module.exports, "JSW_INCIDENTS_CREATE_JSM_PIR_TRIGGER", ()=>$62659eb193cd5c9c$export$7c4d5cc5711d9294);
    $parcel$export(module.exports, "JPO_DIALOGS_CREATE_ISSUE_TRIGGER", ()=>$62659eb193cd5c9c$export$998dfc72404b9e14);
    $parcel$export(module.exports, "JSW_ADAPTED_GLOBAL_ISSUE_CREATE", ()=>$62659eb193cd5c9c$export$fc180a4b5785d2f4);
    $parcel$export(module.exports, "JSW_CREATE_EPIC_ISSUE", ()=>$62659eb193cd5c9c$export$946ec0b21cb84fd0);
    $parcel$export(module.exports, "CREATE_ISSUE_ACTIVE_SPRINT_EMPTY_VIEW", ()=>$62659eb193cd5c9c$export$5b97e2844e620e54);
    $parcel$export(module.exports, "CREATE_SUBTASK_NIN_TRIGGER", ()=>$62659eb193cd5c9c$export$b99842c2cf46f6be);
    $parcel$export(module.exports, "TRIGGER_POINT_KEY_NOT_DEFINED", ()=>$62659eb193cd5c9c$export$8d987f6e80b74000);
    $parcel$export(module.exports, "ISSUE_PARENT_SWITCHER_FIELD_CREATE_ISSUE", ()=>$62659eb193cd5c9c$export$8de9fa43731c4b5b);
    $parcel$export(module.exports, "POLARIS_CREATE_DELIVERY_ISSUE", ()=>$62659eb193cd5c9c$export$87249ccbfd10fb78);
    $parcel$export(module.exports, "HOME_PAGE_DEFAULT_OPEN_GIC", ()=>$62659eb193cd5c9c$export$c0eeeb31d264de76);
    $parcel$export(module.exports, "ISSUE_CREATE_EMBEDDABLE_TRIGGER", ()=>$62659eb193cd5c9c$export$f546e18e46478c84);
    $parcel$export(module.exports, "DEVELOPER_ESCALATIONS_ISSUE_CREATE_TRIGGER", ()=>$62659eb193cd5c9c$export$1784e634ba79f1f9);
    $parcel$export(module.exports, "JWM_INLINE_CREATE", ()=>$62659eb193cd5c9c$export$145eb8935a34e85e);
    $parcel$export(module.exports, "POLARIS_GLOBAL_SUBMIT_IDEA_SWITCH_PROJECTS_TRIGGER", ()=>$62659eb193cd5c9c$export$32648b574a536c8b);
    $parcel$export(module.exports, "ALLOWED_EXTERNAL_CONTROLS", ()=>$62659eb193cd5c9c$export$c0e2ab74dfaa67cc);
    const $62659eb193cd5c9c$export$a05673a22505d5ee = 'forge-open-issue-create';
    const $62659eb193cd5c9c$export$c78d329777576aa0 = 'global-create-button';
    const $62659eb193cd5c9c$export$992ab052fd10f8e8 = 'mobile-global-create-button';
    const $62659eb193cd5c9c$export$affdc3af7a9a4677 = 'keyboard-shortcut';
    const $62659eb193cd5c9c$export$7e9cfd1f59e60910 = 'jsw-issue-view-create-issue-in-epic';
    const $62659eb193cd5c9c$export$708499dcd016e5e9 = 'jsw-issue-view-create-subtask';
    const $62659eb193cd5c9c$export$e1c8789144c4c810 = 'jsw-issue-view-ai-breakdown-child-issue';
    const $62659eb193cd5c9c$export$c5afabb7a17e802 = 'jsw-issue-view-ai-breakdown-subtask';
    const $62659eb193cd5c9c$export$2086a63173b79b56 = 'jsw-releases-create-issue';
    const $62659eb193cd5c9c$export$fe1e5e4597147709 = 'jsw-roadmap-create-issue';
    const $62659eb193cd5c9c$export$9bc326812a93707 = 'jsw-security-create-issue';
    const $62659eb193cd5c9c$export$f352934e06f44599 = 'jsm-calendar-create-change-request';
    const $62659eb193cd5c9c$export$6ae07c77acc64360 = 'jsm-create-incident-from-alert-detail';
    const $62659eb193cd5c9c$export$b41d0684e4b6ab1 = 'jsm-create-incident-from-alert-list';
    const $62659eb193cd5c9c$export$be9b2b1329ff7ec9 = 'jsm-empty-queue-create-request';
    const $62659eb193cd5c9c$export$235b856f54db8916 = 'jwm-board-create-issue';
    const $62659eb193cd5c9c$export$ebbb81a0a3406bcb = 'jwm-calendar-create-issue';
    const $62659eb193cd5c9c$export$8e135b62470e5f9a = 'jwm-list-create-issue';
    const $62659eb193cd5c9c$export$21ea58e7361c33ba = 'jwm-list-create-subtask';
    const $62659eb193cd5c9c$export$d2cf44b8c67cca33 = 'jwm-timeline-create-issue';
    const $62659eb193cd5c9c$export$894285d6d13375fd = 'jwm-timeline-create-subtask';
    const $62659eb193cd5c9c$export$aa348159181703a0 = 'software-create-issue-dialog';
    const $62659eb193cd5c9c$export$f90eec3841adf619 = 'create-linked-issue-issue-view';
    const $62659eb193cd5c9c$export$718822a309bffada = 'create-linked-issue-command-palette';
    const $62659eb193cd5c9c$export$2753dafac81e805c = 'service-desk-linked-issue-trigger';
    const $62659eb193cd5c9c$export$7c4d5cc5711d9294 = 'jsw-incidents-create-jsm-pir-trigger';
    const $62659eb193cd5c9c$export$6eb7318fea2f8ce0 = 'jsw-incidents-create-issue-trigger';
    const $62659eb193cd5c9c$export$998dfc72404b9e14 = 'jpo-dialogs-jira-create-issue';
    const $62659eb193cd5c9c$export$fc180a4b5785d2f4 = 'jsw-adapted-global-issue-create-trigger';
    const $62659eb193cd5c9c$export$946ec0b21cb84fd0 = 'jsw-create-epic-issue';
    const $62659eb193cd5c9c$export$dba86c91ec4fdbf0 = 'create-issue-backlog-trigger';
    const $62659eb193cd5c9c$export$b5cdfd7f3bb79a7 = 'create-epic-issue-backlog-trigger';
    const $62659eb193cd5c9c$export$a8199da3cfab0e8 = 'create-issue-in-epic-backlog-trigger';
    const $62659eb193cd5c9c$export$5b97e2844e620e54 = 'create-issue-active-sprint-empty-view-trigger';
    const $62659eb193cd5c9c$export$569f5ff8afa08896 = 'create-linked-issue-issue-navigator-trigger';
    const $62659eb193cd5c9c$export$bafd52474e9ff4e = 'jwm-summary-create-first-issue-trigger';
    const $62659eb193cd5c9c$export$87f86192eaf0fdf = 'create-subtask-issue-navigator-trigger';
    const $62659eb193cd5c9c$export$b99842c2cf46f6be = 'create-subtask-new-issue-navigator-trigger';
    const $62659eb193cd5c9c$export$1e467d0a3f53a410 = 'jwm-board-embed-issue-create';
    const $62659eb193cd5c9c$export$8d987f6e80b74000 = 'trigger-point-key-not-defined';
    const $62659eb193cd5c9c$export$867e9a641a3c1457 = 'jwm-list-embed-issue-create';
    const $62659eb193cd5c9c$export$8de9fa43731c4b5b = 'issue-parent-switcher-field-create-issue';
    const $62659eb193cd5c9c$export$87249ccbfd10fb78 = 'polaris-create-delivery-issue';
    const $62659eb193cd5c9c$export$c0eeeb31d264de76 = 'home-page-default-open-gic';
    const $62659eb193cd5c9c$export$f546e18e46478c84 = 'issue-create-embed-trigger';
    const $62659eb193cd5c9c$export$1784e634ba79f1f9 = 'developer-escalations-issue-create-trigger';
    const $62659eb193cd5c9c$export$145eb8935a34e85e = 'jwm-inline-create';
    const $62659eb193cd5c9c$export$32648b574a536c8b = 'polaris-global-submit-idea-switch-project-trigger';
    const $62659eb193cd5c9c$export$818f24c6c32e18a = 'jira-calendar-create-issue';
    const $62659eb193cd5c9c$export$f352408886aa304b = 'connect-app-ecosystem';
    const $62659eb193cd5c9c$export$c0e2ab74dfaa67cc = {
        FIELD_IDS: [
            'description'
        ],
        TRIGGER_POINTS: [
            'service-desk-gen-ai-pir'
        ]
    };
});
parcelRegister("cKWWX", function(module, exports) {
    $parcel$export(module.exports, "useSitePickerContext", ()=>$c8055d212e869a3c$export$f8b5aae013e4cf77);
    var $3sQ5x;
    var $iXqE5;
    const $c8055d212e869a3c$export$d4c72bab9d6cc13a = {
        selectedCloudId: '',
        siteDomain: ''
    };
    const $c8055d212e869a3c$export$e324594224ef24da = {
        setSitePickerState: ({ selectedCloudId: selectedCloudId, siteDomain: siteDomain })=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                setState({
                    ...currentState,
                    selectedCloudId: selectedCloudId || '',
                    siteDomain: siteDomain || ''
                });
            }
    };
    const $c8055d212e869a3c$export$6f57813fe9f31bf9 = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'issue.fields.site-picker',
        initialState: $c8055d212e869a3c$export$d4c72bab9d6cc13a,
        actions: $c8055d212e869a3c$export$e324594224ef24da
    });
    const $c8055d212e869a3c$export$f8b5aae013e4cf77 = (0, (0, parcelRequire("3sQ5x")).createHook)($c8055d212e869a3c$export$6f57813fe9f31bf9);
});
parcelRegister("6k7U1", function(module, exports) {
    $parcel$export(module.exports, "startGlobalIssueCreateMetrics", ()=>$8a2e4df39a48d5af$export$550b19803bc962ec);
    $parcel$export(module.exports, "isPartOfDiscoverabilityTriggers", ()=>$8a2e4df39a48d5af$export$f6bc17a4d9120156);
    $parcel$export(module.exports, "discoverabilityPushModalViewOverride", ()=>$8a2e4df39a48d5af$export$8d246e5e04f0b424);
    $parcel$export(module.exports, "getNextModalView", ()=>$8a2e4df39a48d5af$export$720579602bb055a2);
    var $eTXmK;
    var $1Nspv;
    var $gEaAu;
    var $huY3x;
    const $8a2e4df39a48d5af$export$550b19803bc962ec = (isSubsequentLoad)=>{
        (0, (0, parcelRequire("1Nspv")).CreateIssueUFOExperience).start();
        const globalIssueCreateModalLoadMetric = isSubsequentLoad ? (0, (0, parcelRequire("gEaAu")).globalIssueCreateModalLoadSubsequent) : (0, (0, parcelRequire("gEaAu")).globalIssueCreateModalLoad);
        if (isSubsequentLoad) (0, (0, parcelRequire("eTXmK")).default)('global-issue.create-modal-subsequent');
        else (0, (0, parcelRequire("eTXmK")).default)('global-issue.create-modal');
        globalIssueCreateModalLoadMetric.start();
        (0, (0, parcelRequire("huY3x")).startGlobalIssueCreateTTIWithAdjustments)();
    };
    const $8a2e4df39a48d5af$export$f6bc17a4d9120156 = (triggerPoint)=>{
        if (!triggerPoint) return false;
        const acceptedTriggerPoints = [
            'global-create-button',
            'keyboard-shortcut'
        ];
        return acceptedTriggerPoints.includes(triggerPoint);
    };
    const $8a2e4df39a48d5af$export$8d246e5e04f0b424 = (shouldPushDiscoverability, isTriggerAccepted, defaultView = 'modal')=>{
        if (!shouldPushDiscoverability) return undefined;
        return {
            minimizableModalView: isTriggerAccepted ? 'mini' : defaultView,
            shouldPushMiniModalDiscoverability: true,
            shouldShowMiniModalSpotlight: isTriggerAccepted
        };
    };
    const $8a2e4df39a48d5af$export$720579602bb055a2 = (isTriggerAccepted, persistentView, defaultView = 'modal')=>{
        if (isTriggerAccepted) return persistentView || defaultView;
        return defaultView;
    };
});
parcelRegister("gEaAu", function(module, exports) {
    $parcel$export(module.exports, "globalIssueCreateModalLoad", ()=>$ee246606338fc99e$export$5a01a7fa84b239c8);
    $parcel$export(module.exports, "globalIssueCreateModalLoadSubsequent", ()=>$ee246606338fc99e$export$c50ad3c31b6fcfc6);
    $parcel$export(module.exports, "successFlagDisplay", ()=>$ee246606338fc99e$export$4b2382574525bb0f);
    $parcel$export(module.exports, "successFlagAddToSprintAction", ()=>$ee246606338fc99e$export$7839bdaa2f6a2385);
    $parcel$export(module.exports, "successFlagClearFiltersAction", ()=>$ee246606338fc99e$export$c1592e4d8d98963e);
    var $dGrdR;
    const $ee246606338fc99e$export$5a01a7fa84b239c8 = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'global-issue-create-modal'
    });
    const $ee246606338fc99e$export$c50ad3c31b6fcfc6 = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'global-issue-create-modal-subsequent'
    });
    const $ee246606338fc99e$export$4b2382574525bb0f = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'global-issue-create-dynamic-success-flag-display'
    });
    const $ee246606338fc99e$export$7839bdaa2f6a2385 = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'global-issue-create-dynamic-success-flag-add-to-sprint-action'
    });
    const $ee246606338fc99e$export$c1592e4d8d98963e = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'global-issue-create-dynamic-success-flag-clear-filters-action'
    });
});
parcelRegister("huY3x", function(module, exports) {
    $parcel$export(module.exports, "globalIssueCreateTTIWithAdjustments", ()=>$06c7e44c59602f0c$export$9f3d6f17e1da7874);
    $parcel$export(module.exports, "startGlobalIssueCreateTTIWithAdjustments", ()=>$06c7e44c59602f0c$export$457c9fb71ad5b3a9);
    $parcel$export(module.exports, "markGicTti", ()=>$06c7e44c59602f0c$export$ef664dac649a716c);
    $parcel$export(module.exports, "stopTTI", ()=>$06c7e44c59602f0c$export$30ca4c461fab486c);
    $parcel$export(module.exports, "cancelTTI", ()=>$06c7e44c59602f0c$export$27f2445288b528c);
    $parcel$export(module.exports, "markIframeLoad", ()=>$06c7e44c59602f0c$export$7a2709fc1e33401a);
    $parcel$export(module.exports, "markFieldsRegistered", ()=>$06c7e44c59602f0c$export$cf3893335c93e33b);
    $parcel$export(module.exports, "markOnInitResolved", ()=>$06c7e44c59602f0c$export$d86f4868694f2aa7);
    var $dGrdR;
    var $a1HyB;
    const $06c7e44c59602f0c$export$9f3d6f17e1da7874 = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'global-issue-create-tti-with-adjustments',
        timings: [
            {
                key: 'gic-tti-delta',
                startMark: 'gic-tti'
            },
            {
                key: 'script-execution',
                startMark: 'iframe-load'
            },
            {
                key: 'loading-indicators-visibility',
                startMark: 'fields-registered'
            },
            {
                key: 'time-until-oninit-resolved',
                endMark: 'oninit-resolved'
            },
            {
                key: 'gic-all-fields-tti',
                endMark: 'gic-all-fields-loaded'
            }
        ],
        featureFlags: (0, (0, parcelRequire("a1HyB")).TTIFFs)
    });
    const $06c7e44c59602f0c$export$457c9fb71ad5b3a9 = ()=>{
        $06c7e44c59602f0c$export$9f3d6f17e1da7874.start();
    };
    const $06c7e44c59602f0c$export$ef664dac649a716c = ()=>$06c7e44c59602f0c$export$9f3d6f17e1da7874.mark('gic-tti');
    const $06c7e44c59602f0c$export$30ca4c461fab486c = (customData)=>{
        $06c7e44c59602f0c$export$9f3d6f17e1da7874.stop({
            customData: customData
        });
    };
    const $06c7e44c59602f0c$export$27f2445288b528c = ()=>{
        $06c7e44c59602f0c$export$9f3d6f17e1da7874.cancel();
    };
    const $06c7e44c59602f0c$export$7a2709fc1e33401a = ()=>{
        $06c7e44c59602f0c$export$9f3d6f17e1da7874.mark('iframe-load');
    };
    const $06c7e44c59602f0c$export$cf3893335c93e33b = ()=>{
        $06c7e44c59602f0c$export$9f3d6f17e1da7874.mark('fields-registered');
    };
    const $06c7e44c59602f0c$export$d86f4868694f2aa7 = ()=>{
        $06c7e44c59602f0c$export$9f3d6f17e1da7874.mark('oninit-resolved');
    };
});
parcelRegister("a1HyB", function(module, exports) {
    $parcel$export(module.exports, "TTIFFs", ()=>$aec371b77b607203$export$c396ba1504d97f3f);
    const $aec371b77b607203$export$c396ba1504d97f3f = [];
});
parcelRegister("cn7wb", function(module, exports) {
    $parcel$export(module.exports, "usePersistentIssueCreateModalViewResource", ()=>$c55164aa1e8f5a83$export$20dbed3226e737bc);
    var $5uXOq = parcelRequire("5uXOq");
    var $jcw0u;
    var $aHuqL;
    var $2YP9W;
    var $3X1Xr;
    const $c55164aa1e8f5a83$export$20dbed3226e737bc = ()=>{
        const { data: data, update: update } = (0, (0, parcelRequire("2YP9W")).useResource)((0, (0, parcelRequire("3X1Xr")).persistentIssueCreateModalViewResource));
        const updateView = (0, $5uXOq.useCallback)(async (view)=>{
            try {
                await (0, (0, parcelRequire("aHuqL")).userPreferenceAPI).set((0, $fa76c7a777da2375$export$15187a597cef02df), view);
                update((state)=>({
                        ...state,
                        view: view
                    }));
            } catch (error) {
                (0, (0, parcelRequire("jcw0u")).default)({
                    error: error,
                    meta: {
                        id: 'persistentIssueCreateModalViewState',
                        packageName: 'jiraRouterResourcesPersistentIssueCreateModalView',
                        teamName: 'gryffindor'
                    },
                    sendToPrivacyUnsafeSplunk: true
                });
            }
        }, [
            update
        ]);
        const updateIsDiscoPushComplete = (0, $5uXOq.useCallback)(async (isDiscoPushComplete = true)=>{
            try {
                await (0, (0, parcelRequire("aHuqL")).userPreferenceAPI).set((0, $fa76c7a777da2375$export$8977f91a75d8d265), isDiscoPushComplete);
                update((state)=>({
                        ...state,
                        isDiscoPushComplete: isDiscoPushComplete
                    }));
            } catch (error) {
                (0, (0, parcelRequire("jcw0u")).default)({
                    error: error,
                    meta: {
                        id: 'miniModalDiscoverabilityPushState',
                        packageName: 'jiraRouterResourcesPersistentIssueCreateModalView',
                        teamName: 'gryffindor'
                    },
                    sendToPrivacyUnsafeSplunk: true
                });
            }
        }, [
            update
        ]);
        return {
            data: data,
            updateView: updateView,
            updateIsDiscoPushComplete: updateIsDiscoPushComplete
        };
    };
});
const $fa76c7a777da2375$export$15187a597cef02df = 'jira.user.gic.persistent.modal.view.state';
const $fa76c7a777da2375$export$8977f91a75d8d265 = 'jira.user.gic.mini.modal.discoverability';
const $fa76c7a777da2375$export$f658cfd591eb583e = 'RESOURCE_PERSISTENT_ISSUE_CREATE_MODAL_VIEW';
parcelRegister("kxEY7", function(module, exports) {
    $parcel$export(module.exports, "NavigationCreateIssueButtonWrapperNudgeAsync", ()=>$6b9c90b8c1e1a472$export$fc0840474cb1fce9);
    var $5uXOq = parcelRequire("5uXOq");
    var $bBbEG;
    var $lfTZh;
    var $kxZPA;
    var $dsvCp;
    const $6b9c90b8c1e1a472$var$NavigationCreateIssueButtonLazy = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("k2WCw")).then((exportedModule)=>exportedModule.NavigationCreateIssueButtonWrapperNudge), {
        ssr: false,
        moduleId: "./src/packages/software/onboarding-nudges/src/ui/navigation-create-issue/index.tsx"
    });
    const $6b9c90b8c1e1a472$export$fc0840474cb1fce9 = ({ children: children })=>{
        const shouldShowNudge = (0, (0, parcelRequire("dsvCp")).useCanShowQuickstartNudge)();
        if (!shouldShowNudge) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bBbEG")).OnboardingNudgeErrorBoundary), {
            id: (0, $6784cbc7d62e5d7c$export$aba87e019f5ccb8d),
            fallback: children,
            attributes: {
                nudgeId: (0, $6784cbc7d62e5d7c$export$aba87e019f5ccb8d)
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "software-onboarding-navigation-create-issue-nudge",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($6b9c90b8c1e1a472$var$NavigationCreateIssueButtonLazy, {
            nudgeId: (0, $6784cbc7d62e5d7c$export$aba87e019f5ccb8d)
        }, children)));
    };
});
parcelRegister("dsvCp", function(module, exports) {
    $parcel$export(module.exports, "useCanShowQuickstartNudge", ()=>$6331d14932d8e8e2$export$b687eb4905cdc15a);
    var $heI7y;
    const $6331d14932d8e8e2$export$b687eb4905cdc15a = ()=>{
        const [{ hasOpened: hasOpened }] = (0, (0, parcelRequire("heI7y")).useHasOpened)();
        return hasOpened;
    };
});
parcelRegister("k2WCw", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("aQQdr").then(()=>parcelRequire('9NAtJ'));
});
parcelRegister("3S5gG", function(module, exports) {
    $parcel$export(module.exports, "Create", ()=>$321e94af7b02d9cb$export$8ade6fcbf3a7de5d);
    var $5uXOq = parcelRequire("5uXOq");
    var $g6jiF;
    var $83yN3;
    var $eCeGD;
    var $dZbEw;
    var $dC5iT;
    var $cSD7y;
    var $4R6GH;
    var $kvHKs;
    var $2gP8z;
    var $3HwlS;
    var $2tvF5;
    const $321e94af7b02d9cb$var$TEST_ID_NAV_4 = 'atlassian-navigation--create-button';
    const $321e94af7b02d9cb$export$8ade6fcbf3a7de5d = (props)=>{
        if (false) {
            if ((0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) {
                if ((0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_2')) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2tvF5")).CreateButton), {
                    testId: $321e94af7b02d9cb$var$TEST_ID_NAV_4
                }, props.text);
                return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eCeGD")).default), {
                    appearance: "primary",
                    iconBefore: (0, ($parcel$interopDefault((0, parcelRequire("dZbEw"))))),
                    testId: props.testId
                }, props.text);
            }
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("83yN3")).SkeletonCreateButton), {
                text: props.text,
                testId: props.testId
            });
        }
        if ((0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) {
            const { onClick: onClick, text: text } = props;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2gP8z")).SpotlightTarget), {
                name: (0, (0, parcelRequire("kvHKs")).GLOBAL_NAV_CREATE_BUTTON_SPOTLIGHT_ID)
            }, (0, ($parcel$interopDefault($5uXOq))).createElement($321e94af7b02d9cb$var$ButtonWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2tvF5")).CreateButton), {
                onClick: onClick,
                testId: $321e94af7b02d9cb$var$TEST_ID_NAV_4
            }, text)));
        }
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("g6jiF")).Create), props);
    };
    const $321e94af7b02d9cb$var$ButtonWrapper = (0, (0, parcelRequire("cSD7y")).componentWithFG)('jira_nav4_eap_drop_2', (0, (0, parcelRequire("dC5iT")).default), (0, $5uXOq.Fragment));
});
parcelRegister("2gP8z", function(module, exports) {
    $parcel$export(module.exports, "SpotlightTarget", ()=>$bac5b90628bba66f$export$e29577842f211e22);
    var $5uXOq = parcelRequire("5uXOq");
    var $5Fk4Z;
    var $axUP7;
    const $bac5b90628bba66f$var$SpotlightTargetWrapper = ({ children: children, ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Fk4Z")).default), props, (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-test-id": `ak-spotlight-target-${props.name}`
        }, children));
    const $bac5b90628bba66f$export$e29577842f211e22 = (0, (0, parcelRequire("axUP7")).withNestingOptimisation)($bac5b90628bba66f$var$SpotlightTargetWrapper);
});
parcelRegister("axUP7", function(module, exports) {
    $parcel$export(module.exports, "withNestingOptimisation", ()=>$c861ce2b697701b9$export$ee1cb2a8280d8710);
    var $5uXOq = parcelRequire("5uXOq");
    var $j9VTE;
    var $9sGI6;
    const $c861ce2b697701b9$export$ee1cb2a8280d8710 = (Component)=>{
        const WithNestingOptimisation = (0, $5uXOq.forwardRef)((props, ref)=>{
            const children = typeof props === 'object' && props != null ? props.children : null;
            return (0, (0, parcelRequire("j9VTE")).useShouldNestedElementRender)().shouldRender && !false ? (0, ($parcel$interopDefault($5uXOq))).createElement(Component, {
                ...props,
                ref: ref
            }) : children;
        });
        WithNestingOptimisation.displayName = `withNestingOptimisation(${(0, (0, parcelRequire("9sGI6")).getDisplayName)(Component)})`;
        return WithNestingOptimisation;
    };
});
parcelRegister("ktmuI", function(module, exports) {
    $parcel$export(module.exports, "GlobalIssueCreateFromTopNav", ()=>$903a8c36bcdb4b23$export$177dc7755a3f80f2);
    var $atdLB;
    var $duUGa;
    var $gjd6t;
    const $903a8c36bcdb4b23$export$177dc7755a3f80f2 = new (0, (0, parcelRequire("duUGa")).UFOExperience)('global-issue-create-from-top-nav', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Operation,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).Custom,
        until: (0, (0, parcelRequire("gjd6t")).untilAll)([
            {
                category: 'global-issue-create.create-issue'
            }
        ])
    });
    const $903a8c36bcdb4b23$export$2b83859c1548ffb5 = new (0, (0, parcelRequire("duUGa")).UFOExperience)('projects-menu-dropdown.recommendation-selector', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Experience,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).InlineResult
    });
    const $903a8c36bcdb4b23$export$a784c5bbb21ea554 = new (0, (0, parcelRequire("duUGa")).UFOExperience)('projects-menu-dropdown.load-jpd-recommendation', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Experience,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).InlineResult
    });
    const $903a8c36bcdb4b23$export$901fa2452d4f9051 = new (0, (0, parcelRequire("duUGa")).UFOExperience)('projects-menu-dropdown.load-jwm-sample-project-recommendation', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Experience,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).InlineResult
    });
});
parcelRegister("8fSk0", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$d702f6d77050354f$export$2e2bcd8739ae039);
    var $7VHMR;
    var $d702f6d77050354f$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        create: {
            "id": "atlassian-navigation.create.create",
            "defaultMessage": "Create"
        },
        createButtonAriaLabel: {
            "id": "atlassian-navigation.create.create-button-aria-label",
            "defaultMessage": "Create issue"
        }
    });
});
parcelRegister("twalS", function(module, exports) {
    $parcel$export(module.exports, "shouldDisableGICFlagCreation", ()=>$ea9b0b430c82f5e1$export$fc4d210bb6a1528a);
    var $Mp6qH;
    const $ea9b0b430c82f5e1$export$fc4d210bb6a1528a = ()=>(0, (0, parcelRequire("Mp6qH")).JSW_TMP_BACKLOG_BOARD_REGEXP).test(window.location.pathname);
});
parcelRegister("3bzLW", function(module, exports) {
    $parcel$export(module.exports, "Dashboards", ()=>$f1da5b4afde35356$export$dd47e695f88fad71);
    var $5uXOq = parcelRequire("5uXOq");
    var $kaHBi;
    var $bcn6L;
    var $koVbs;
    var $lmyls;
    var $apeap;
    var $cZpsT;
    var $Mp6qH;
    var $4uXxK;
    var $fVbkk;
    var $4XiFK;
    var $ebYJ0;
    var $7HJPM;
    var $lQgHF;
    const $f1da5b4afde35356$var$DashboardsAnonymous = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVbkk")).MenuTrigger), {
            href: "/jira/dashboards",
            menuId: (0, (0, parcelRequire("lmyls")).MENU_ID).DASHBOARDS,
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).DASHBOARDS
        }, formatMessage((0, (0, parcelRequire("lQgHF")).default).dashboards));
    };
    const $f1da5b4afde35356$var$DashboardsLoggedIn = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [{ hasFetchedOnce: hasFetchedOnceFavorites }, { loadFavoriteDashboards: loadFavorite }] = (0, (0, parcelRequire("bcn6L")).useFavoriteDashboards)();
        const [, { loadRecentDashboards: loadRecent }] = (0, (0, parcelRequire("apeap")).useRecentDashboards)();
        const onStart = (0, (0, parcelRequire("kaHBi")).useExperienceStart)({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU),
            experienceId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).DASHBOARDS,
            analyticsSource: 'atlassian-navigation'
        });
        const onBeforeMenuOpen = (0, $5uXOq.useCallback)(()=>{
            onStart();
            loadRecent((0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_RECENT));
            if (!hasFetchedOnceFavorites) loadFavorite((0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV));
        }, [
            hasFetchedOnceFavorites,
            loadFavorite,
            loadRecent,
            onStart
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4XiFK")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ebYJ0")).Popup), {
            menuId: (0, (0, parcelRequire("lmyls")).MENU_ID).DASHBOARDS,
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).DASHBOARDS,
            Menu: (0, (0, parcelRequire("7HJPM")).Menu),
            onBeforeMenuOpen: onBeforeMenuOpen,
            label: formatMessage((0, (0, parcelRequire("lQgHF")).default).dashboards)
        }, formatMessage((0, (0, parcelRequire("lQgHF")).default).dashboards)));
    };
    const $f1da5b4afde35356$var$Dashboards = ()=>{
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        return isAnonymous ? (0, ($parcel$interopDefault($5uXOq))).createElement($f1da5b4afde35356$var$DashboardsAnonymous, null) : (0, ($parcel$interopDefault($5uXOq))).createElement($f1da5b4afde35356$var$DashboardsLoggedIn, null);
    };
    const $f1da5b4afde35356$export$dd47e695f88fad71 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).DASHBOARDS
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($f1da5b4afde35356$var$Dashboards, null));
});
parcelRegister("4XiFK", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f53eb447f22f4276$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $8CUq3;
    const $f53eb447f22f4276$var$PopupBoxWrapper = ({ children: children })=>{
        if (!false) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $f53eb447f22f4276$var$popupBoxWrapperStyles
        }, children);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
    };
    const $f53eb447f22f4276$var$popupBoxWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        color: 'color.text',
        height: '100%'
    });
    var $f53eb447f22f4276$export$2e2bcd8739ae039 = $f53eb447f22f4276$var$PopupBoxWrapper;
});
parcelRegister("7HJPM", function(module, exports) {
    $parcel$export(module.exports, "Menu", ()=>$9f76f48ca86ed8d3$export$d9b273488cd8ce6f);
    var $5uXOq = parcelRequire("5uXOq");
    var $9UCCQ;
    var $9oIax;
    var $39xOx;
    var $93jKl;
    var $bcn6L;
    var $lmyls;
    var $6cNb0;
    var $lfTZh;
    var $Mp6qH;
    var $8vzcp;
    var $ag3sS;
    var $9TMfX;
    var $6WLja;
    var $e8DNP;
    var $6gxIx;
    var $7qz2O;
    var $8nbY6;
    var $dKlaW;
    const $9f76f48ca86ed8d3$var$EVENT_DATA = {
        id: (0, (0, parcelRequire("lmyls")).MENU_ID).DASHBOARDS
    };
    const $9f76f48ca86ed8d3$export$d9b273488cd8ce6f = ({ testIdPrefix: testIdPrefix })=>{
        const [, { loadFavoriteDashboards: loadFavorite }] = (0, (0, parcelRequire("bcn6L")).useFavoriteDashboards)();
        const onFail = (0, (0, parcelRequire("93jKl")).useExperienceFail)({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU)
        });
        (0, $5uXOq.useEffect)(()=>()=>{
                loadFavorite((0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV));
            }, [
            loadFavorite
        ]);
        const testIdContent = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'content');
        const testIdFooter = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'footer');
        const errorFallback = (0, $5uXOq.useCallback)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8vzcp")).MenuError), {
                testIdPrefix: testIdContent
            }), [
            testIdContent
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ag3sS")).MenuLayout), {
            isWide: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            isScrollable: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).DASHBOARDS,
            packageName: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME),
            fallback: errorFallback,
            withExperienceTracker: true,
            extraEventData: $9f76f48ca86ed8d3$var$EVENT_DATA,
            onError: onFail
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9oIax")).AuthenticationErrorBoundary), {
            render: errorFallback
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("e8DNP")).Empty), {
            testIdPrefix: testIdContent
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "navigation-apps-dashboards-menu",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9TMfX")).MenuSkeletonContent), {
                testIdPrefix: testIdContent
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8nbY6")).LoadStatus), {
            shouldThrowError: true
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6gxIx")).FavoriteContent), {
            testIdPrefix: testIdContent
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dKlaW")).RecentContent), {
            testIdPrefix: testIdContent
        })))))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            hasSeparator: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "navigation-apps-dashboards-footer",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6WLja")).MenuSkeletonFooter), {
                testIdPrefix: testIdFooter
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8nbY6")).LoadStatus), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7qz2O")).Footer), {
            testIdPrefix: testIdFooter
        }))));
    };
});
parcelRegister("e8DNP", function(module, exports) {
    $parcel$export(module.exports, "Empty", ()=>$281eb0020f130a6b$export$1f74963c34e8bfec);
    var $5uXOq = parcelRequire("5uXOq");
    var $cgL5K;
    var $3bQtK;
    var $bcn6L;
    var $koVbs;
    var $6cNb0;
    var $apeap;
    var $cZpsT;
    var $Mp6qH;
    var $jyltl;
    var $dMJS0;
    var $go6GE = parcelRequire("go6GE");
    var $4MIFv;
    const $281eb0020f130a6b$var$learnMoreHref = 'https://confluence.atlassian.com/jiracorecloud/configuring-dashboards-765593585.html';
    const $281eb0020f130a6b$export$1f74963c34e8bfec = ({ children: children, testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const onSuccess = (0, (0, parcelRequire("3bQtK")).useExperienceSuccess)({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU)
        });
        const text = (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            as: "p"
        }, formatMessage((0, (0, parcelRequire("4MIFv")).default).emptyText))), (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, (0, ($parcel$interopDefault($5uXOq))).createElement("a", {
            href: $281eb0020f130a6b$var$learnMoreHref,
            target: "_blank",
            rel: "noopener noreferrer"
        }, formatMessage((0, (0, parcelRequire("4MIFv")).default).learnMore))));
        const [{ hasSuccessOnce: hasSuccessOnceFavorite, isFetching: isFetchingFavorite, fetchError: fetchErrorFavorite }] = (0, (0, parcelRequire("bcn6L")).useFavoriteDashboards)();
        const [{ hasSuccessOnce: hasSuccessOnceRecent, isFetching: isFetchingRecent, fetchError: fetchErrorRecent }] = (0, (0, parcelRequire("apeap")).useRecentDashboards)();
        const { favoriteItems: favoriteItems, recentItems: recentItems } = (0, (0, parcelRequire("dMJS0")).useDashboardNavItems)();
        const notEmpty = favoriteItems.length || recentItems.length || isFetchingFavorite && !hasSuccessOnceFavorite || isFetchingRecent && !hasSuccessOnceRecent || fetchErrorFavorite || fetchErrorRecent;
        const shouldShowEmptyForAnonymous = isAnonymous;
        (0, $5uXOq.useEffect)(()=>{
            const isSuccess = hasSuccessOnceFavorite && hasSuccessOnceRecent;
            if (isSuccess) onSuccess();
        }, [
            hasSuccessOnceFavorite,
            hasSuccessOnceRecent,
            onSuccess
        ]);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'empty');
        return notEmpty && !shouldShowEmptyForAnonymous ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jyltl")).MenuInfo), {
            imgSrc: (0, ($parcel$interopDefault($go6GE))),
            testId: testId,
            text: text
        });
    };
});
parcelRegister("dMJS0", function(module, exports) {
    $parcel$export(module.exports, "useBoardNavItems", ()=>$104764fbcbb91fe4$export$cad67ea3bdbadd5e);
    $parcel$export(module.exports, "useDashboardNavItems", ()=>$104764fbcbb91fe4$export$eb662c364f65f2a5);
    $parcel$export(module.exports, "useFilterNavItems", ()=>$104764fbcbb91fe4$export$b00ea32725560f5d);
    $parcel$export(module.exports, "usePlanNavItems", ()=>$104764fbcbb91fe4$export$8448c28cc43804d4);
    $parcel$export(module.exports, "useProjectNavItems", ()=>$104764fbcbb91fe4$export$4a9469e6b2e4297c);
    $parcel$export(module.exports, "useRecentNavItems", ()=>$104764fbcbb91fe4$export$5cfe5f7fc9ab5417);
    $parcel$export(module.exports, "useQueueNavItems", ()=>$104764fbcbb91fe4$export$7723949d2540d672);
    var $5uXOq = parcelRequire("5uXOq");
    var $9XE6b = parcelRequire("9XE6b");
    var $bcn6L;
    var $4041n;
    var $88fdg;
    var $apeap;
    const $104764fbcbb91fe4$var$getFavouriteAndRecentItems = (recentItems, favoriteItems, changedItems)=>{
        const mapChangedItems = (item)=>{
            const changedItem = (0, ($parcel$interopDefault($9XE6b)))(changedItems, item.id);
            return changedItem ? {
                ...item,
                favourite: changedItem.value
            } : item;
        };
        const updatedFavorites = favoriteItems ? favoriteItems.map(mapChangedItems) : [];
        const updatedRecents = recentItems ? recentItems.map(mapChangedItems) : [];
        const favoritesIds = updatedFavorites.map((item)=>item.id);
        const filteredRecents = updatedRecents.filter((item)=>!favoritesIds.includes(Number(item.id)));
        return {
            favoriteItems: updatedFavorites,
            recentItems: filteredRecents
        };
    };
    const $104764fbcbb91fe4$var$getFilteredViewedItems = (viewedItems, workedOnItems)=>viewedItems?.filter((viewedItem)=>!workedOnItems?.some((workedOnItem)=>parseInt(workedOnItem.localResourceId, 10) === viewedItem.id && workedOnItem.type === viewedItem.type)) || [];
    const $104764fbcbb91fe4$export$cad67ea3bdbadd5e = ()=>{
        const { changeFavorite: changeFavorite, items: { boards: changedItems } } = (0, $5uXOq.useContext)((0, (0, parcelRequire("4041n")).FavoriteChangeContext));
        const [recentData] = (0, (0, parcelRequire("apeap")).useRecentBoards)();
        const [favoriteData] = (0, (0, parcelRequire("bcn6L")).useFavoriteBoards)();
        const { favoriteItems: favoriteItems, recentItems: recentItems } = (0, $5uXOq.useMemo)(()=>$104764fbcbb91fe4$var$getFavouriteAndRecentItems(recentData.items, favoriteData.items, changedItems), [
            recentData.items,
            favoriteData.items,
            changedItems
        ]);
        return {
            changeFavorite: changeFavorite,
            changedItems: changedItems,
            favoriteItems: favoriteItems,
            recentItems: recentItems
        };
    };
    const $104764fbcbb91fe4$export$eb662c364f65f2a5 = ()=>{
        const { changeFavorite: changeFavorite, items: { dashboards: changedItems } } = (0, $5uXOq.useContext)((0, (0, parcelRequire("4041n")).FavoriteChangeContext));
        const [recentData] = (0, (0, parcelRequire("apeap")).useRecentDashboards)();
        const [favoriteData] = (0, (0, parcelRequire("bcn6L")).useFavoriteDashboards)();
        const { favoriteItems: favoriteItems, recentItems: recentItems } = (0, $5uXOq.useMemo)(()=>$104764fbcbb91fe4$var$getFavouriteAndRecentItems(recentData.items, favoriteData.items, changedItems), [
            recentData.items,
            favoriteData.items,
            changedItems
        ]);
        return {
            changeFavorite: changeFavorite,
            changedItems: changedItems,
            favoriteItems: favoriteItems,
            recentItems: recentItems
        };
    };
    const $104764fbcbb91fe4$export$b00ea32725560f5d = ()=>{
        const { changeFavorite: changeFavorite, items: { filters: changedItems } } = (0, $5uXOq.useContext)((0, (0, parcelRequire("4041n")).FavoriteChangeContext));
        const [recentData] = (0, (0, parcelRequire("apeap")).useRecentFilters)();
        const [favoriteData] = (0, (0, parcelRequire("bcn6L")).useFavoriteFilters)();
        const { favoriteItems: favoriteItems, recentItems: recentItems } = (0, $5uXOq.useMemo)(()=>$104764fbcbb91fe4$var$getFavouriteAndRecentItems(recentData.items, favoriteData.items, changedItems), [
            recentData.items,
            favoriteData.items,
            changedItems
        ]);
        return {
            changeFavorite: changeFavorite,
            changedItems: changedItems,
            favoriteItems: favoriteItems,
            recentItems: recentItems
        };
    };
    const $104764fbcbb91fe4$export$8448c28cc43804d4 = ()=>{
        const { changeFavorite: changeFavorite, items: { plans: changedItems } } = (0, $5uXOq.useContext)((0, (0, parcelRequire("4041n")).FavoriteChangeContext));
        const [{ items: items }] = (0, (0, parcelRequire("88fdg")).usePlansItems)();
        const [favoriteData] = (0, (0, parcelRequire("bcn6L")).useFavoritePlans)();
        const { favoriteItems: favoriteItems, recentItems: recentItems } = (0, $5uXOq.useMemo)(()=>{
            const recentPlans = items?.recent ? items.recent.map((plan)=>({
                    ...plan,
                    id: plan.id.toString().split('|')[0]
                })) : [];
            return $104764fbcbb91fe4$var$getFavouriteAndRecentItems(recentPlans, favoriteData.items, changedItems);
        }, [
            items,
            favoriteData.items,
            changedItems
        ]);
        return {
            changeFavorite: changeFavorite,
            changedItems: changedItems,
            favoriteItems: favoriteItems,
            recentItems: recentItems
        };
    };
    const $104764fbcbb91fe4$export$4a9469e6b2e4297c = ()=>{
        const { changeFavorite: changeFavorite, items: { projects: changedItems } } = (0, $5uXOq.useContext)((0, (0, parcelRequire("4041n")).FavoriteChangeContext));
        const [recentData] = (0, (0, parcelRequire("apeap")).useRecentProjects)();
        const [favoriteData] = (0, (0, parcelRequire("bcn6L")).useFavoriteProjects)();
        const { favoriteItems: favoriteItems, recentItems: recentItems } = (0, $5uXOq.useMemo)(()=>$104764fbcbb91fe4$var$getFavouriteAndRecentItems(recentData.items, favoriteData.items, changedItems), [
            recentData.items,
            favoriteData.items,
            changedItems
        ]);
        return {
            changeFavorite: changeFavorite,
            changedItems: changedItems,
            favoriteItems: favoriteItems,
            recentItems: recentItems
        };
    };
    const $104764fbcbb91fe4$export$5cfe5f7fc9ab5417 = ()=>{
        const [viewedData] = (0, (0, parcelRequire("apeap")).useRecentAllSingleList)();
        const [workedOnData] = (0, (0, parcelRequire("apeap")).useWorkedOnAllSingleList)();
        const viewedItems = (0, $5uXOq.useMemo)(()=>$104764fbcbb91fe4$var$getFilteredViewedItems(viewedData.items, workedOnData.items), [
            viewedData.items,
            workedOnData.items
        ]);
        return {
            workedOnItems: workedOnData.items || [],
            viewedItems: viewedItems
        };
    };
    const $104764fbcbb91fe4$export$7723949d2540d672 = ()=>{
        const { changeFavorite: changeFavorite, items: { queues: changedItems } } = (0, $5uXOq.useContext)((0, (0, parcelRequire("4041n")).FavoriteChangeContext));
        const [recentData] = (0, (0, parcelRequire("apeap")).useRecentQueues)();
        const [favoriteData] = (0, (0, parcelRequire("bcn6L")).useFavoriteQueues)();
        const { favoriteItems: favoriteItems, recentItems: recentItems } = (0, $5uXOq.useMemo)(()=>$104764fbcbb91fe4$var$getFavouriteAndRecentItems(recentData.items, favoriteData.items, changedItems), [
            recentData.items,
            favoriteData.items,
            changedItems
        ]);
        return {
            changeFavorite: changeFavorite,
            changedItems: changedItems,
            favoriteItems: favoriteItems,
            recentItems: recentItems
        };
    };
});
parcelRegister("88fdg", function(module, exports) {
    $parcel$export(module.exports, "usePlansItems", ()=>$fbc1adaeeb4c0d23$export$9386de9ff002702a);
    var $jjts0;
    var $g5X3G;
    var $3sQ5x;
    var $iXqE5;
    const $fbc1adaeeb4c0d23$var$handleFetch = ()=>async ({ setState: setState, getState: getState })=>{
            const { fetchPlansItems: fetchPlansItems, baseUrl: baseUrl, isFetching: isFetching } = getState();
            if (!isFetching) try {
                const promise = fetchPlansItems(baseUrl);
                setState({
                    isFetching: true,
                    fetchError: null,
                    promise: promise
                });
                const items = await promise;
                setState({
                    items: items,
                    hasFetchedOnce: true,
                    hasSuccessOnce: true,
                    isFetching: false,
                    fetchError: null,
                    promise: null
                });
            } catch (fetchError) {
                setState({
                    ...getState(),
                    fetchError: fetchError,
                    hasFetchedOnce: true,
                    isFetching: false,
                    promise: null
                });
            }
        };
    const $fbc1adaeeb4c0d23$var$actions = {
        load: ()=>async ({ dispatch: dispatch })=>{
                const promise = dispatch($fbc1adaeeb4c0d23$var$handleFetch());
                await promise;
            }
    };
    const $fbc1adaeeb4c0d23$export$b0dd7d935d0978a4 = ({ storeName: storeName = 'plans-item-store', fetchPlansItems: fetchPlansItems })=>{
        const initialState = {
            baseUrl: (0, (0, parcelRequire("g5X3G")).toBaseUrl)(''),
            fetchPlansItems: fetchPlansItems,
            hasFetchedOnce: false,
            hasSuccessOnce: false,
            items: null,
            fetchError: null,
            promise: null,
            isFetching: false
        };
        const Store = (0, (0, parcelRequire("iXqE5")).createStore)({
            name: storeName,
            actions: $fbc1adaeeb4c0d23$var$actions,
            initialState: initialState
        });
        const selector = ({ items: items, hasFetchedOnce: hasFetchedOnce, fetchError: fetchError, promise: promise, hasSuccessOnce: hasSuccessOnce, isFetching: isFetching })=>({
                items: items,
                hasFetchedOnce: hasFetchedOnce,
                hasSuccessOnce: hasSuccessOnce,
                fetchError: fetchError,
                promise: promise,
                isFetching: isFetching
            });
        const usePlansItems = (0, (0, parcelRequire("3sQ5x")).createHook)(Store, {
            selector: selector
        });
        return {
            usePlansItems: usePlansItems
        };
    };
    const { usePlansItems: $fbc1adaeeb4c0d23$export$9386de9ff002702a } = $fbc1adaeeb4c0d23$export$b0dd7d935d0978a4({
        fetchPlansItems: (0, (0, parcelRequire("jjts0")).fetchPlansItems)
    });
});
parcelRegister("jjts0", function(module, exports) {
    $parcel$export(module.exports, "fetchPlansItems", ()=>$9279033d6121eb6e$export$ae5884604a8ac77b);
    var $aDvGo;
    var $g5X3G;
    const $9279033d6121eb6e$var$handleError = (error)=>{
        throw error;
    };
    const $9279033d6121eb6e$var$transformResult = ({ isVisible: isVisible, staticLinks: staticLinks, recentPlans: recentPlans = [] })=>{
        const recentItems = recentPlans.map(({ planId: planId, scenarioId: scenarioId, title: title, url: url })=>({
                id: `${planId}|${scenarioId}`,
                title: title || '',
                url: url,
                type: (0, $49a076eb01652100$export$eebccb886b064713)
            }));
        return {
            isVisible: isVisible,
            recent: recentItems,
            links: staticLinks
        };
    };
    const $9279033d6121eb6e$export$ae5884604a8ac77b = async (baseUrl = (0, (0, parcelRequire("g5X3G")).toBaseUrl)(''))=>{
        const url = `${baseUrl}/rest/jpo/1.0/navigation`;
        try {
            const response = await fetch(url, (0, (0, parcelRequire("aDvGo")).default));
            if (response.ok) {
                const result = await response.json();
                return $9279033d6121eb6e$var$transformResult(result);
            }
            return $9279033d6121eb6e$var$handleError(new Error(response.statusText));
        } catch (error) {
            return $9279033d6121eb6e$var$handleError(error);
        }
    };
});
parcelRegister("go6GE", function(module, exports) {
    module.exports = new URL("dashboards-empty.ed2e5362.svg", import.meta.url).toString();
});
parcelRegister("4MIFv", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$69bb4571f94cb527$export$2e2bcd8739ae039);
    var $7VHMR;
    var $69bb4571f94cb527$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        emptyText: {
            "id": "atlassian-navigation.dashboards.menu.empty.empty-text",
            "defaultMessage": "Create a dashboard to track the status of your projects."
        },
        learnMore: {
            "id": "atlassian-navigation.dashboards.menu.empty.learn-more",
            "defaultMessage": "More about dashboards"
        },
        emptyImg: {
            "id": "atlassian-navigation.dashboards.menu.empty.empty-img",
            "defaultMessage": "An image showing that the apps dropdown menu is empty"
        }
    });
});
parcelRegister("6gxIx", function(module, exports) {
    $parcel$export(module.exports, "FavoriteContent", ()=>$96d17f8a5c6dc94c$export$297af018011b145a);
    var $5uXOq = parcelRequire("5uXOq");
    var $hSLU7;
    var $4mNf3;
    var $eXQym;
    var $koVbs;
    var $6cNb0;
    var $94QFg;
    var $Mp6qH;
    var $fhtpB;
    var $d4B4Y;
    var $56fXm;
    var $dMJS0;
    var $fBffT;
    const $96d17f8a5c6dc94c$export$297af018011b145a = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { favoriteItems: favoriteItems, changeFavorite: onChangeFavorite, changedItems: changedItems } = (0, (0, parcelRequire("dMJS0")).useDashboardNavItems)();
        const itemsToShow = (0, $5uXOq.useMemo)(()=>favoriteItems.slice(0, (0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV)), [
            favoriteItems
        ]);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'items-starred');
        const dashboardsUrl = '/jira/dashboards?page=1&sortKey=favourite&sortOrder=DESC';
        return favoriteItems.length > 0 ? (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), {
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'heading')
        }, formatMessage((0, (0, parcelRequire("fBffT")).default).starred)), itemsToShow.map((item, idx)=>{
            const { url: url, id: id, favourite: isFavourite, metadata: metadata, title: title } = item;
            const contextItem = changedItems[id.toString()];
            const isPending = contextItem && contextItem.pending === true ? contextItem.pending : false;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
                analytics: {
                    actionSubjectId: 'dashboardsNavigationMenuItem',
                    menuItemId: id,
                    menuItemType: 'starred',
                    position: idx,
                    starred: Boolean(isFavourite)
                },
                description: metadata,
                href: url,
                iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("hSLU7"))))), {
                    label: "dashboard"
                }),
                iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fhtpB")).FavoriteButton), {
                    id: String(id),
                    isFavorite: Boolean(isFavourite),
                    favoriteItemName: title,
                    isPending: isPending,
                    menuItemType: "starred",
                    onChangeFavorite: onChangeFavorite,
                    type: (0, (0, parcelRequire("eXQym")).DASHBOARDS_ITEM_TYPE)
                }),
                key: id,
                testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, `item-${idx}`),
                to: (0, (0, parcelRequire("94QFg")).dashboardViewRoute),
                params: {
                    dashboardId: String(id)
                }
            }, title);
        }), favoriteItems.length >= (0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV) && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("56fXm")).MenuItemViewAll), {
            analytics: {
                actionSubjectId: 'dashboardsNavigationMenuItem',
                menuItemId: 'viewAll',
                position: (0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV)
            },
            href: dashboardsUrl,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-view-all'),
            to: dashboardsUrl
        })) : null;
    };
});
parcelRegister("94QFg", function(module, exports) {
    $parcel$export(module.exports, "dashboardRoute", ()=>$0f4773b5d6aa4f26$export$3bf0ecb39349b6ef);
    $parcel$export(module.exports, "selectedDashboardRoute", ()=>$0f4773b5d6aa4f26$export$b32070ee9554c3fb);
    $parcel$export(module.exports, "dashboardEditRoute", ()=>$0f4773b5d6aa4f26$export$b6e00d2cd7369fbd);
    $parcel$export(module.exports, "dashboardWallboardRoute", ()=>$0f4773b5d6aa4f26$export$eaa33c953ab9ca63);
    $parcel$export(module.exports, "dashboardViewRoute", ()=>$0f4773b5d6aa4f26$export$24f1b2ff97b9d3e6);
    $parcel$export(module.exports, "dashboardViewEmbedRoute", ()=>$0f4773b5d6aa4f26$export$258b5e3f294617d2);
    $parcel$export(module.exports, "systemDashboardEditRoute", ()=>$0f4773b5d6aa4f26$export$7367fb465296c7dd);
    var $65Ltd;
    const $0f4773b5d6aa4f26$export$3bf0ecb39349b6ef = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_DASHBOARD),
        path: '/secure/Dashboard.jspa'
    };
    const $0f4773b5d6aa4f26$export$b32070ee9554c3fb = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_DASHBOARD_SELECTED),
        path: '/secure/Dashboard.jspa',
        query: [
            'selectPageId'
        ]
    };
    const $0f4773b5d6aa4f26$export$b6e00d2cd7369fbd = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_DASHBOARD_EDIT),
        path: '/jira/dashboards/:dashboardId(\\d+)/edit',
        query: [
            'maximized?'
        ],
        exact: true
    };
    const $0f4773b5d6aa4f26$export$eaa33c953ab9ca63 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_DASHBOARD_WALLBOARD),
        path: '/jira/dashboards/wallboard',
        query: [
            'dashboardId',
            'cyclePeriod?',
            'transitionFx?',
            'random?'
        ],
        exact: true
    };
    const $0f4773b5d6aa4f26$export$24f1b2ff97b9d3e6 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_DASHBOARD_VIEW),
        path: '/jira/dashboards/:dashboardId(\\d+|last-visited)',
        query: [
            'maximized?'
        ],
        exact: true
    };
    const $0f4773b5d6aa4f26$export$258b5e3f294617d2 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_DASHBOARD_VIEW_EMBED),
        path: '/jira/dashboards/:dashboardId(\\d+)/embed',
        query: [],
        exact: true
    };
    const $0f4773b5d6aa4f26$export$7367fb465296c7dd = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SYSTEM_DASHBOARD_EDIT),
        path: '/secure/admin/EditDefaultDashboard(!default)?.jspa',
        query: [
            'maximized?'
        ]
    };
});
parcelRegister("fhtpB", function(module, exports) {
    $parcel$export(module.exports, "FavoriteButton", ()=>$fd1b3cb9e133f54f$export$8d5dab2ad1e19ea0);
    var $5uXOq = parcelRequire("5uXOq");
    var $2bzBV;
    var $hcObS;
    var $iXVV6;
    var $8zOmE;
    var $2NMNM;
    const $fd1b3cb9e133f54f$export$8d5dab2ad1e19ea0 = ({ beforeEntityId: beforeEntityId, beforeEntityType: beforeEntityType, id: id, isFavorite: isFavorite, favoriteItemName: favoriteItemName, isPending: isPending, menuItemType: menuItemType, onChangeFavorite: onChangeFavorite, type: type })=>{
        const { isVisible: isVisible } = (0, (0, parcelRequire("2bzBV")).useOverflowStatus)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const contextualAnalyticsDataAttributes = (0, $5uXOq.useMemo)(()=>({
                isInMore: !isVisible,
                menuType: type,
                menuItemType: menuItemType
            }), [
            isVisible,
            menuItemType,
            type
        ]);
        const onClick = (0, $5uXOq.useCallback)(()=>{
            const beforeEntity = beforeEntityId != null && beforeEntityType != null ? {
                beforeEntity: {
                    id: beforeEntityId,
                    type: beforeEntityType
                }
            } : undefined;
            onChangeFavorite({
                id: id,
                value: !isFavorite,
                type: type,
                ...beforeEntity
            });
            const actionSubject = type.slice(0, type.length - 1);
            const action = isFavorite ? 'unstarred' : 'starred';
            const analyticsEvent = createAnalyticsEvent({});
            (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(analyticsEvent, `${actionSubject} ${action}`, id);
        }, [
            beforeEntityId,
            beforeEntityType,
            createAnalyticsEvent,
            id,
            isFavorite,
            onChangeFavorite,
            type
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            attributes: contextualAnalyticsDataAttributes
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hcObS")).default), {
            isSmall: true,
            isShownInList: true,
            isFavorite: isFavorite,
            favoriteItemName: favoriteItemName,
            pending: isPending,
            onClick: onClick
        }));
    };
});
parcelRegister("hcObS", function(module, exports) {
    $parcel$export(module.exports, "FavoriteButtonWrapper", ()=>$0a68425ce3fc2fa8$export$bb1478fee6cd5f0c);
    $parcel$export(module.exports, "default", ()=>$0a68425ce3fc2fa8$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $basmW = parcelRequire("basmW");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $fVnbM;
    var $dm1qi;
    var $dAf5R;
    var $5oTeF;
    var $bH1hf;
    var $7FtaJ;
    var $1Cvrw;
    var $fNobW;
    var $3bDjY;
    var $iQv12;
    var $7XYc4;
    var $az4zD;
    var $dCm1o;
    var $6fQ6A;
    const $0a68425ce3fc2fa8$var$SKELETON_TEST_ID = 'favouriting.favorite-button-stateless.skeleton';
    const $0a68425ce3fc2fa8$var$ICON_WRAPPER_TEST_ID_PREFIX = 'favouriting.favorite-button-stateless.icon-wrapper';
    class $0a68425ce3fc2fa8$export$f8ad2acf0ec4860f extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                isFavorite: undefined,
                favoriteItemName: undefined,
                isShownInList: false,
                isSmall: false,
                hideTooltip: false,
                onClick: (0, ($parcel$interopDefault($8Rkzz))),
                tooltipPosition: 'bottom',
                skipAnimation: false
            };
        }
        componentDidUpdate(prevProps) {
            if (!this.props.skipAnimation && (!prevProps.pending && this.props.pending || !prevProps.isFavorite && this.props.isFavorite)) {
                this.onAnimationStart();
                this.onSettingAnimationEnd();
            }
        }
        onAnimationStart() {
            this.setState({
                isAnimating: !this.state.isAnimating
            });
        }
        onSettingAnimationEnd() {
            setTimeout(()=>{
                this.setState({
                    isAnimating: false
                });
            }, $0a68425ce3fc2fa8$var$animationTime * 2);
        }
        render() {
            const { isFavorite: isFavorite, favoriteItemName: favoriteItemName, isShownInList: isShownInList, isSmall: isSmall, hideTooltip: hideTooltip, tooltipPosition: tooltipPosition, pending: pending, intl: { formatMessage: formatMessage } } = this.props;
            const { isAnimating: isAnimating } = this.state;
            if (isFavorite === undefined) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fNobW")).default), {
                name: "favourite-button"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$export$db65185b5889c83, {
                "data-testid": $0a68425ce3fc2fa8$var$SKELETON_TEST_ID,
                isSmall: isSmall
            }));
            const iconWrapperTestId = isShownInList ? `${$0a68425ce3fc2fa8$var$ICON_WRAPPER_TEST_ID_PREFIX}-list` : $0a68425ce3fc2fa8$var$ICON_WRAPPER_TEST_ID_PREFIX;
            const buttonContentOld = (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$var$FavIconWrapper, {
                isFavorite: isFavorite,
                isAnimating: isAnimating,
                "data-testid": iconWrapperTestId,
                isSmall: isSmall
            }, isFavorite ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("dm1qi"))))), {
                LEGACY_fallbackIcon: (0, ($parcel$interopDefault((0, parcelRequire("bH1hf"))))),
                LEGACY_size: isSmall ? 'small' : undefined,
                color: "currentColor",
                label: formatMessage((0, (0, parcelRequire("6fQ6A")).default).removeFromStarred)
            }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("dAf5R"))))), {
                LEGACY_fallbackIcon: (0, ($parcel$interopDefault((0, parcelRequire("5oTeF"))))),
                LEGACY_size: isSmall ? 'small' : undefined,
                color: "currentColor",
                label: formatMessage((0, (0, parcelRequire("6fQ6A")).default).addToStarred)
            }));
            const renderIcon = ()=>{
                const label = isFavorite ? formatMessage((0, (0, parcelRequire("6fQ6A")).default).removeFromStarred) : formatMessage((0, (0, parcelRequire("6fQ6A")).default).addToStarred);
                let StarIconComponent;
                if (isSmall) StarIconComponent = isFavorite ? (0, ($parcel$interopDefault((0, parcelRequire("7FtaJ"))))) : (0, ($parcel$interopDefault((0, parcelRequire("1Cvrw")))));
                else StarIconComponent = isFavorite ? (0, ($parcel$interopDefault((0, parcelRequire("dm1qi"))))) : (0, ($parcel$interopDefault((0, parcelRequire("dAf5R")))));
                return (0, ($parcel$interopDefault($5uXOq))).createElement(StarIconComponent, {
                    color: "currentColor",
                    label: label
                });
            };
            const buttonContent = (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$var$FavIconWrapper, {
                isFavorite: isFavorite,
                isAnimating: isAnimating,
                "data-testid": iconWrapperTestId,
                isSmall: isSmall
            }, renderIcon());
            const tooltipContent = isFavorite ? formatMessage((0, (0, parcelRequire("6fQ6A")).default).removeFromStarred) : formatMessage((0, (0, parcelRequire("6fQ6A")).default).addToStarred);
            const ariaLabel = favoriteItemName ? formatMessage((0, (0, parcelRequire("6fQ6A")).default).toggleStarredWithItemName, {
                itemName: favoriteItemName
            }) : formatMessage((0, (0, parcelRequire("6fQ6A")).default).toggleStarred);
            return (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$export$bb1478fee6cd5f0c, {
                role: "presentation",
                onClick: this.onBtnClickWrapper,
                onKeyPress: this.onBtnClickWrapper,
                "data-is-favorite": isFavorite
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
                content: !hideTooltip && tooltipContent,
                hideTooltipOnClick: true,
                position: tooltipPosition
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                appearance: isShownInList ? 'subtle-link' : 'subtle',
                spacing: "none",
                "aria-label": ariaLabel,
                "aria-pressed": isFavorite,
                onClick: !pending ? this.onClick : (0, ($parcel$interopDefault($8Rkzz)))
            }, (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() ? buttonContent : buttonContentOld)));
        }
        constructor(...args){
            super(...args);
            this.state = {
                isAnimating: false
            };
            this.onBtnClickWrapper = (e)=>{
                e.stopPropagation();
                e.preventDefault();
            };
            this.onClick = (_, analyticsEvent)=>{
                this.props.onClick(analyticsEvent);
            };
        }
    }
    var $0a68425ce3fc2fa8$export$2e2bcd8739ae039 = (0, ($parcel$interopDefault($basmW)))((0, (0, parcelRequire("iQv12")).default)({
        onClick: ()=>({
                name: 'star'
            })
    }), (0, (0, parcelRequire("az4zD")).injectIntlV2))($0a68425ce3fc2fa8$export$f8ad2acf0ec4860f);
    const $0a68425ce3fc2fa8$var$animationTime = 500;
    const $0a68425ce3fc2fa8$export$bb1478fee6cd5f0c = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1o8l",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseOld = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isSmall: isSmall, isAnimating: isAnimating, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o _1bah1h6o",
                __cmplp.isSmall ? "_1bsb1tcg" : "_1bsbzwfg",
                __cmplp.isSmall ? "_4t3i1tcg" : "_4t3izwfg",
                __cmplp.isAnimating && "_11vn1ssb _1px1fmmq _48hmq7pw _1uvf1e03",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseNew = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isSmall: isSmall, isAnimating: isAnimating, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o _1bah1h6o",
                __cmplp.isSmall ? "_1bsb1tcg" : "_1bsbzwfg",
                __cmplp.isSmall ? "_4t3i1tcg" : "_4t3izwfg",
                __cmplp.isAnimating && "_1px1fmmq _48hmq7pw _1uvf1e03",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$var$FavoriteIconWrapperBase = (0, (0, parcelRequire("7XYc4")).componentWithCondition)((0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled), $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseNew, $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseOld);
    const $0a68425ce3fc2fa8$var$FavIconWrapper = (0, $5uXOq.forwardRef)(({ as: C = $0a68425ce3fc2fa8$var$FavoriteIconWrapperBase, style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "",
                __cmplp.isFavorite ? "_syaz2kyj" : "_syaz2u25",
                __cmplp.isFavorite ? "_30l31ebw" : "_30l3w9a8",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$export$db65185b5889c83 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isSmall: isSmall, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw",
                __cmplp.isSmall ? "_1bsb1tcg" : "_1bsbzwfg",
                __cmplp.isSmall ? "_4t3i1tcg" : "_4t3izwfg",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("iQv12", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$945500a9598c3ac2$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $gvRPW;
    const $945500a9598c3ac2$var$fireWithUIAnalytics = (ConnectedProps)=>{
        const providedKeys = Object.keys(ConnectedProps).sort();
        const cachedMappedProps = {};
        const cachedConnectedProps = {};
        const getStateFromMappedProps = (mapped)=>{
            providedKeys.forEach((key, index)=>{
                const prop = mapped[index];
                if (key in cachedMappedProps && cachedMappedProps[key] === prop) return;
                cachedMappedProps[key] = prop;
                cachedConnectedProps[key] = (...attrs)=>{
                    const analyticsEvent = attrs[attrs.length - 1].clone();
                    const connectedProp = ConnectedProps[key];
                    if (typeof ConnectedProps[key] === 'string') analyticsEvent.update({
                        name: connectedProp,
                        analyticsType: (0, (0, parcelRequire("gvRPW")).UI_EVENT_TYPE)
                    }).fire();
                    else if (typeof connectedProp === 'function') {
                        const update = connectedProp(...attrs);
                        analyticsEvent.update({
                            ...update,
                            analyticsType: (0, (0, parcelRequire("gvRPW")).UI_EVENT_TYPE)
                        }).fire();
                    }
                    if (typeof prop === 'function') prop(...attrs);
                };
            });
            return cachedConnectedProps;
        };
        return (WrappedComponent)=>class WithAnalytics extends (0, $5uXOq.Component) {
                static{
                    this.displayName = `WithAnalytics(${WrappedComponent.displayName || WrappedComponent.name || 'UnnamedComponent'}`;
                }
                render() {
                    const newMappedProps = getStateFromMappedProps(providedKeys.map((key)=>this.props[key]));
                    return (0, ($parcel$interopDefault($5uXOq))).createElement(WrappedComponent, {
                        ...this.props,
                        ...newMappedProps
                    });
                }
            };
    };
    var $945500a9598c3ac2$export$2e2bcd8739ae039 = $945500a9598c3ac2$var$fireWithUIAnalytics;
});
parcelRegister("6fQ6A", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$10bc2ec5d9a72db1$export$2e2bcd8739ae039);
    var $7VHMR;
    var $10bc2ec5d9a72db1$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        addToStarred: {
            "id": "platform.ui.favouriting.favourite-button-stateless.add-to-starred",
            "defaultMessage": "Add to Starred"
        },
        removeFromStarred: {
            "id": "platform.ui.favouriting.favourite-button-stateless.remove-from-starred",
            "defaultMessage": "Remove from Starred"
        },
        toggleStarred: {
            "id": "platform.ui.favouriting.favourite-button-stateless.toggle-starred",
            "defaultMessage": "Star"
        },
        toggleStarredWithItemName: {
            "id": "platform.ui.favouriting.favourite-button-stateless.toggle-starred-with-item-name",
            "defaultMessage": "Star {itemName}"
        }
    });
});
parcelRegister("56fXm", function(module, exports) {
    $parcel$export(module.exports, "MenuItemViewAll", ()=>$fec3d6e24cccbb91$export$2d5ce40137c4f96d);
    var $5uXOq = parcelRequire("5uXOq");
    var $1FouZ;
    var $koVbs;
    var $d4B4Y;
    var $awxdD;
    const $fec3d6e24cccbb91$var$viewAllCssFn = ()=>({
            '&, &:hover, &:focus, & span': {
                color: `var(--ds-link, ${(0, $829f609a65d26a98$exports).B400})`,
                textDecoration: 'none'
            },
            '&:active': {
                color: `var(--ds-link-pressed, ${(0, $829f609a65d26a98$exports).B400})`,
                textDecoration: 'none'
            }
        });
    const $fec3d6e24cccbb91$export$2d5ce40137c4f96d = (props)=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            ...props,
            target: "_blank",
            cssFn: $fec3d6e24cccbb91$var$viewAllCssFn,
            iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("1FouZ"))))), {
                label: formatMessage((0, (0, parcelRequire("awxdD")).default).viewAllStarred)
            })
        }, formatMessage((0, (0, parcelRequire("awxdD")).default).viewAllStarred));
    };
});
parcelRegister("awxdD", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f6318f13bf447304$export$2e2bcd8739ae039);
    var $7VHMR;
    var $f6318f13bf447304$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        viewAllStarred: {
            "id": "atlassian-navigation.common.menu.view-all.view-all-starred",
            "defaultMessage": "View all starred"
        }
    });
});
parcelRegister("fBffT", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$4e9605aa9ba39b44$export$2e2bcd8739ae039);
    var $7VHMR;
    var $4e9605aa9ba39b44$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        starred: {
            "id": "atlassian-navigation.dashboards.menu.favorite-content.starred",
            "defaultMessage": "Starred"
        }
    });
});
parcelRegister("7qz2O", function(module, exports) {
    $parcel$export(module.exports, "Footer", ()=>$9ea4139d651cf8bc$export$a06f1c675e846f6f);
    var $5uXOq = parcelRequire("5uXOq");
    var $koVbs;
    var $6cNb0;
    var $3zTeb;
    var $cZpsT;
    var $d4B4Y;
    var $a3jaK;
    var $cuZpl;
    const $9ea4139d651cf8bc$export$a06f1c675e846f6f = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'items');
        const dashboardsUrl = '/jira/dashboards';
        return (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'dashboardsNavigationMenuItem',
                menuItemId: 'viewAll',
                menuItemType: 'action',
                position: 0
            },
            href: dashboardsUrl,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-view-all'),
            to: (0, (0, parcelRequire("3zTeb")).dashboardsDirectoryRoute)
        }, formatMessage((0, (0, parcelRequire("cuZpl")).default).viewAll)), !isAnonymous && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("a3jaK")).CreateButton), {
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-create')
        }));
    };
});
parcelRegister("3zTeb", function(module, exports) {
    $parcel$export(module.exports, "dashboardsDirectoryRoute", ()=>$ce3faeb59dd926fb$export$41bba408475cab16);
    $parcel$export(module.exports, "dashboardsDirectoryRedirectRoute", ()=>$ce3faeb59dd926fb$export$b96f6fb3c35a6fe5);
    var $65Ltd;
    const $ce3faeb59dd926fb$export$41bba408475cab16 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_DASHBOARDS_DIRECTORY),
        path: '/jira/dashboards'
    };
    const $ce3faeb59dd926fb$export$b96f6fb3c35a6fe5 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_DASHBOARDS_DIRECTORY_SECURE),
        path: '/secure/ConfigurePortalPages(!default.jspa|.jspa)'
    };
});
parcelRegister("a3jaK", function(module, exports) {
    $parcel$export(module.exports, "CreateButton", ()=>$2b806b55754512d0$export$6d3c345ce7c38fd9);
    var $5uXOq = parcelRequire("5uXOq");
    var $koVbs;
    var $e9QkH;
    var $Mp6qH;
    var $d4B4Y;
    var $eqacc;
    const $2b806b55754512d0$export$6d3c345ce7c38fd9 = ({ testId: testId })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [, { on: modalOpen }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("Mp6qH")).MODAL_ID).DASHBOARD_CREATE);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'dashboardsNavigationMenuItem',
                menuItemId: 'create',
                menuItemType: 'action',
                position: 1
            },
            onClick: modalOpen,
            testId: testId
        }, formatMessage((0, (0, parcelRequire("eqacc")).default).createButton));
    };
});
parcelRegister("e9QkH", function(module, exports) {
    $parcel$export(module.exports, "useSwitchModals", ()=>$bbe85969245206f0$export$5ed4b99cbad4ebd6);
    var $2qauD;
    const { useSwitch: $bbe85969245206f0$var$useSwitch } = (0, (0, parcelRequire("2qauD")).createSwitchController)('jira-switch-modals');
    const $bbe85969245206f0$export$5ed4b99cbad4ebd6 = $bbe85969245206f0$var$useSwitch;
});
parcelRegister("eqacc", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$956db87aa6fc3521$export$2e2bcd8739ae039);
    var $7VHMR;
    var $956db87aa6fc3521$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        createButton: {
            "id": "atlassian-navigation.dashboards.menu.footer.create-button.create-button",
            "defaultMessage": "Create dashboard"
        },
        notAllowed: {
            "id": "atlassian-navigation.dashboards.menu.footer.create-button.not-allowed",
            "defaultMessage": "You must be logged in to create dashboards"
        }
    });
});
parcelRegister("cuZpl", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$55607ccb6f8aac9f$export$2e2bcd8739ae039);
    var $7VHMR;
    var $55607ccb6f8aac9f$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        viewAll: {
            "id": "atlassian-navigation.dashboards.menu.footer.view-all",
            "defaultMessage": "View all dashboards"
        }
    });
});
parcelRegister("8nbY6", function(module, exports) {
    $parcel$export(module.exports, "LoadStatus", ()=>$e364b42a3e2b8e03$export$c7df4a3f1b89de01);
    var $5uXOq = parcelRequire("5uXOq");
    var $bcn6L;
    var $apeap;
    var $dHtVS;
    const $e364b42a3e2b8e03$export$c7df4a3f1b89de01 = ({ shouldThrowError: shouldThrowError = false })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dHtVS")).LoadStatus), {
            shouldThrowError: shouldThrowError,
            useProvider: (0, (0, parcelRequire("bcn6L")).useFavoriteDashboards)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dHtVS")).LoadStatus), {
            shouldThrowError: shouldThrowError,
            useProvider: (0, (0, parcelRequire("apeap")).useRecentDashboards)
        }));
});
parcelRegister("dHtVS", function(module, exports) {
    $parcel$export(module.exports, "LoadStatus", ()=>$97c251fd319eb785$export$c7df4a3f1b89de01);
    const $97c251fd319eb785$export$c7df4a3f1b89de01 = ({ useProvider: useProvider, shouldThrowError: shouldThrowError = false })=>{
        const [{ fetchError: fetchError, isFetching: isFetching = false, promise: promise, hasSuccessOnce: hasSuccessOnce = false } = {}] = useProvider();
        if (fetchError != null && shouldThrowError && !hasSuccessOnce) throw fetchError;
        if (isFetching && !hasSuccessOnce) throw promise;
        return null;
    };
});
parcelRegister("dKlaW", function(module, exports) {
    $parcel$export(module.exports, "RecentContent", ()=>$e3bdcf72a295ac60$export$a2873dc4e96d8e90);
    var $5uXOq = parcelRequire("5uXOq");
    var $hSLU7;
    var $4mNf3;
    var $eXQym;
    var $koVbs;
    var $2EqxS;
    var $6cNb0;
    var $94QFg;
    var $Mp6qH;
    var $fhtpB;
    var $d4B4Y;
    var $dMJS0;
    var $pwPQz;
    const $e3bdcf72a295ac60$export$a2873dc4e96d8e90 = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { recentItems: recentItems, favoriteItems: favoriteItems, changeFavorite: onChangeFavorite, changedItems: changedItems } = (0, (0, parcelRequire("dMJS0")).useDashboardNavItems)();
        const itemsToShow = (0, $5uXOq.useMemo)(()=>recentItems.slice(0, (0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_RECENT)), [
            recentItems
        ]);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'items-recent');
        return recentItems.length > 0 ? (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), {
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'heading')
        }, formatMessage((0, (0, parcelRequire("pwPQz")).default).recent)), itemsToShow.map((item, idx)=>{
            const { url: url, id: id, favourite: isFavourite, metadata: metadata, title: title } = item;
            const contextItem = changedItems[item.id.toString()];
            const isPending = contextItem && contextItem.pending === true ? contextItem.pending : false;
            const { id: beforeEntityId, type: beforeEntityType } = (0, (0, parcelRequire("2EqxS")).getBeforeEntity)(favoriteItems);
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
                analytics: {
                    actionSubjectId: 'dashboardsNavigationMenuItem',
                    menuItemId: id,
                    menuItemType: 'recent',
                    position: idx,
                    starred: Boolean(isFavourite)
                },
                description: metadata,
                href: url,
                iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("hSLU7"))))), {
                    label: ""
                }),
                iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fhtpB")).FavoriteButton), {
                    beforeEntityId: beforeEntityId,
                    beforeEntityType: beforeEntityType,
                    id: String(id),
                    isFavorite: Boolean(isFavourite),
                    favoriteItemName: title,
                    isPending: isPending,
                    menuItemType: "recent",
                    onChangeFavorite: onChangeFavorite,
                    type: (0, (0, parcelRequire("eXQym")).DASHBOARDS_ITEM_TYPE)
                }),
                key: id,
                testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, `item-${idx}`),
                to: (0, (0, parcelRequire("94QFg")).dashboardViewRoute),
                params: {
                    dashboardId: String(id)
                }
            }, title);
        })) : null;
    };
});
parcelRegister("pwPQz", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$037213aaba1faf3d$export$2e2bcd8739ae039);
    var $7VHMR;
    var $037213aaba1faf3d$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        recent: {
            "id": "atlassian-navigation.dashboards.menu.recent-content.recent",
            "defaultMessage": "Recent"
        },
        viewAll: {
            "id": "atlassian-navigation.dashboards.menu.recent-content.view-all",
            "defaultMessage": "View all recent"
        }
    });
});
parcelRegister("lQgHF", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$4360ba769f596109$export$2e2bcd8739ae039);
    var $7VHMR;
    var $4360ba769f596109$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        dashboards: {
            "id": "atlassian-navigation.dashboards.dashboards",
            "defaultMessage": "Dashboards"
        }
    });
});
parcelRegister("jSplz", function(module, exports) {
    $parcel$export(module.exports, "Filters", ()=>$05595fb4c275b9c8$export$92a576f896a9bf72);
    var $5uXOq = parcelRequire("5uXOq");
    var $kaHBi;
    var $bcn6L;
    var $koVbs;
    var $lmyls;
    var $apeap;
    var $cZpsT;
    var $Mp6qH;
    var $4uXxK;
    var $fVbkk;
    var $4XiFK;
    var $ebYJ0;
    var $kawVZ;
    var $7ikvo;
    const $05595fb4c275b9c8$var$FiltersAnonymous = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVbkk")).MenuTrigger), {
            href: "/jira/filters?searchName=&Search=Search&filterView=search",
            menuId: (0, (0, parcelRequire("lmyls")).MENU_ID).FILTERS,
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).FILTERS
        }, formatMessage((0, (0, parcelRequire("7ikvo")).default).filters));
    };
    const $05595fb4c275b9c8$var$FiltersLoggedIn = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [{ hasFetchedOnce: hasFetchedOnceFavorites }, { loadFavoriteFilters: loadFavorite }] = (0, (0, parcelRequire("bcn6L")).useFavoriteFilters)();
        const [, { loadRecentFilters: loadRecent }] = (0, (0, parcelRequire("apeap")).useRecentFilters)();
        const onStart = (0, (0, parcelRequire("kaHBi")).useExperienceStart)({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU),
            experienceId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).FILTERS,
            analyticsSource: 'atlassian-navigation'
        });
        const onBeforeMenuOpen = (0, $5uXOq.useCallback)(()=>{
            onStart();
            loadRecent((0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_RECENT));
            if (!hasFetchedOnceFavorites) loadFavorite((0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV));
        }, [
            hasFetchedOnceFavorites,
            loadFavorite,
            loadRecent,
            onStart
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4XiFK")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ebYJ0")).Popup), {
            menuId: (0, (0, parcelRequire("lmyls")).MENU_ID).FILTERS,
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).FILTERS,
            Menu: (0, (0, parcelRequire("kawVZ")).Menu),
            onBeforeMenuOpen: onBeforeMenuOpen,
            label: formatMessage((0, (0, parcelRequire("7ikvo")).default).filters)
        }, formatMessage((0, (0, parcelRequire("7ikvo")).default).filters)));
    };
    const $05595fb4c275b9c8$var$FiltersWithAuth = ()=>{
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        return isAnonymous ? (0, ($parcel$interopDefault($5uXOq))).createElement($05595fb4c275b9c8$var$FiltersAnonymous, null) : (0, ($parcel$interopDefault($5uXOq))).createElement($05595fb4c275b9c8$var$FiltersLoggedIn, null);
    };
    const $05595fb4c275b9c8$export$92a576f896a9bf72 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).FILTERS
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($05595fb4c275b9c8$var$FiltersWithAuth, null));
});
parcelRegister("kawVZ", function(module, exports) {
    $parcel$export(module.exports, "Menu", ()=>$37b6403ac89429a0$export$d9b273488cd8ce6f);
    var $5uXOq = parcelRequire("5uXOq");
    var $9UCCQ;
    var $9oIax;
    var $39xOx;
    var $93jKl;
    var $bcn6L;
    var $lmyls;
    var $6cNb0;
    var $lfTZh;
    var $Mp6qH;
    var $8vzcp;
    var $ag3sS;
    var $9TMfX;
    var $6WLja;
    var $aC6ao;
    var $bBBg3;
    var $7xt41;
    var $1Q2KE;
    var $lQ0sa;
    const $37b6403ac89429a0$var$EVENT_DATA = {
        id: (0, (0, parcelRequire("lmyls")).MENU_ID).FILTERS
    };
    const $37b6403ac89429a0$export$d9b273488cd8ce6f = ({ AuthenticationErrorBoundary: AuthenticationErrorBoundary = (0, (0, parcelRequire("9oIax")).AuthenticationErrorBoundary), Empty: Empty = (0, (0, parcelRequire("aC6ao")).Empty), Error: Error = (0, (0, parcelRequire("8vzcp")).MenuError), FavoriteContent: FavoriteContent = (0, (0, parcelRequire("bBBg3")).FavoriteContent), Footer: Footer = (0, (0, parcelRequire("7xt41")).Footer), JSErrorBoundary: JSErrorBoundary = (0, (0, parcelRequire("39xOx")).JSErrorBoundary), Layout: Layout = (0, (0, parcelRequire("ag3sS")).MenuLayout), LoadStatus: LoadStatus = (0, (0, parcelRequire("1Q2KE")).LoadStatus), RecentContent: RecentContent = (0, (0, parcelRequire("lQ0sa")).RecentContent), Section: Section = (0, (0, parcelRequire("9UCCQ")).default), SkeletonContent: SkeletonContent = (0, (0, parcelRequire("9TMfX")).MenuSkeletonContent), SkeletonFooter: SkeletonFooter = (0, (0, parcelRequire("6WLja")).MenuSkeletonFooter), testIdPrefix: testIdPrefix, useExperienceFail: useExperienceFail = (0, (0, parcelRequire("93jKl")).useExperienceFail), useFavorite: useFavorite = (0, (0, parcelRequire("bcn6L")).useFavoriteFilters) })=>{
        const [, { loadFavoriteFilters: loadFavorite }] = useFavorite();
        const onFail = useExperienceFail({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU)
        });
        (0, $5uXOq.useEffect)(()=>()=>{
                loadFavorite((0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV));
            }, [
            loadFavorite
        ]);
        const testIdContent = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'content');
        const testIdFooter = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'footer');
        const errorFallback = (0, $5uXOq.useCallback)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement(Error, {
                testIdPrefix: testIdContent
            }), [
            testIdContent
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement(Layout, {
            isWide: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(Section, {
            isScrollable: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(JSErrorBoundary, {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).FILTERS,
            packageName: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME),
            fallback: errorFallback,
            withExperienceTracker: true,
            extraEventData: $37b6403ac89429a0$var$EVENT_DATA,
            onError: onFail
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(AuthenticationErrorBoundary, {
            render: errorFallback
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(Empty, {
            testIdPrefix: testIdContent
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "atlassian-navigation-filters-menu",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement(SkeletonContent, {
                testIdPrefix: testIdContent
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(LoadStatus, {
            shouldThrowError: true
        }), (0, ($parcel$interopDefault($5uXOq))).createElement(FavoriteContent, {
            testIdPrefix: testIdContent
        }), (0, ($parcel$interopDefault($5uXOq))).createElement(RecentContent, {
            testIdPrefix: testIdContent
        })))))), (0, ($parcel$interopDefault($5uXOq))).createElement(Section, {
            hasSeparator: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "atlassian-navigation-filters-footer",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement(SkeletonFooter, {
                testIdPrefix: testIdFooter
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(LoadStatus, null), (0, ($parcel$interopDefault($5uXOq))).createElement(Footer, {
            testIdPrefix: testIdFooter
        }))));
    };
});
parcelRegister("aC6ao", function(module, exports) {
    $parcel$export(module.exports, "Empty", ()=>$c74c3409b5a3434a$export$1f74963c34e8bfec);
    var $5uXOq = parcelRequire("5uXOq");
    var $cgL5K;
    var $3bQtK;
    var $bcn6L;
    var $aWse0;
    var $6cNb0;
    var $apeap;
    var $cZpsT;
    var $Mp6qH;
    var $jyltl;
    var $dMJS0;
    var $jbyOm = parcelRequire("jbyOm");
    var $dODGv;
    const $c74c3409b5a3434a$var$learnMoreHref = 'https://confluence.atlassian.com/jirasoftwarecloud/searching-for-issues-764478280.html#Searchingforissues-SearchallofJira';
    const $c74c3409b5a3434a$export$1f74963c34e8bfec = ({ MenuInfo: MenuInfo = (0, (0, parcelRequire("jyltl")).MenuInfo), children: children, testIdPrefix: testIdPrefix, useExperienceSuccess: useExperienceSuccess = (0, (0, parcelRequire("3bQtK")).useExperienceSuccess), useFavorite: useFavorite = (0, (0, parcelRequire("bcn6L")).useFavoriteFilters), useIntl: useIntl = (0, (0, parcelRequire("aWse0")).useIntlV2), useIsAnonymous: useIsAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous), useItems: useItems = (0, (0, parcelRequire("dMJS0")).useFilterNavItems), useRecent: useRecent = (0, (0, parcelRequire("apeap")).useRecentFilters) })=>{
        const { formatMessage: formatMessage } = useIntl();
        const isAnonymous = useIsAnonymous();
        const onSuccess = useExperienceSuccess({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU)
        });
        const text = (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            as: "p"
        }, formatMessage((0, (0, parcelRequire("dODGv")).default).emptyText))), (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, (0, ($parcel$interopDefault($5uXOq))).createElement("a", {
            href: $c74c3409b5a3434a$var$learnMoreHref,
            target: "_blank",
            rel: "noopener noreferrer"
        }, formatMessage((0, (0, parcelRequire("dODGv")).default).learnMore))));
        const [{ isFetching: isFetchingFavorite, hasSuccessOnce: hasSuccessOnceFavorite, fetchError: fetchErrorFavorite }] = useFavorite();
        const [{ isFetching: isFetchingRecent, hasSuccessOnce: hasSuccessOnceRecent, fetchError: fetchErrorRecent }] = useRecent();
        const { favoriteItems: favoriteItems, recentItems: recentItems } = useItems();
        const notEmpty = favoriteItems.length || recentItems.length || isFetchingFavorite && !hasSuccessOnceFavorite || isFetchingRecent && !hasSuccessOnceRecent || fetchErrorFavorite || fetchErrorRecent;
        const shouldShowEmptyForAnonymous = isAnonymous;
        (0, $5uXOq.useEffect)(()=>{
            const isSuccess = hasSuccessOnceFavorite && hasSuccessOnceRecent;
            if (isSuccess) onSuccess();
        }, [
            hasSuccessOnceFavorite,
            hasSuccessOnceRecent,
            onSuccess
        ]);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'empty');
        return notEmpty && !shouldShowEmptyForAnonymous ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children) : (0, ($parcel$interopDefault($5uXOq))).createElement(MenuInfo, {
            imgSrc: (0, ($parcel$interopDefault($jbyOm))),
            testId: testId,
            text: text
        });
    };
});
parcelRegister("jbyOm", function(module, exports) {
    module.exports = new URL("filter-empty.4c92f05b.svg", import.meta.url).toString();
});
parcelRegister("dODGv", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$56a795574a4eb398$export$2e2bcd8739ae039);
    var $7VHMR;
    var $56a795574a4eb398$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        emptyText: {
            "id": "atlassian-navigation.filters.menu.empty.empty-text",
            "defaultMessage": "Search for issues across all projects and save them as filters."
        },
        learnMore: {
            "id": "atlassian-navigation.filters.menu.empty.learn-more",
            "defaultMessage": "Learn more"
        },
        emptyImg: {
            "id": "atlassian-navigation.filters.menu.empty.empty-img",
            "defaultMessage": "An image showing that the apps dropdown menu is empty"
        }
    });
});
parcelRegister("bBBg3", function(module, exports) {
    $parcel$export(module.exports, "FavoriteContent", ()=>$e72dd94e33471bbd$export$297af018011b145a);
    var $5uXOq = parcelRequire("5uXOq");
    var $bTqXB;
    var $4mNf3;
    var $eXQym;
    var $koVbs;
    var $4zlUA;
    var $6cNb0;
    var $71Gk7;
    var $Mp6qH;
    var $fhtpB;
    var $d4B4Y;
    var $7ilb8;
    var $56fXm;
    var $dMJS0;
    var $31jbO;
    const $e72dd94e33471bbd$export$297af018011b145a = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { favoriteItems: favoriteItems, changeFavorite: onChangeFavorite, changedItems: changedItems } = (0, (0, parcelRequire("dMJS0")).useFilterNavItems)();
        const itemsToShow = (0, $5uXOq.useMemo)(()=>favoriteItems.slice(0, (0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV)), [
            favoriteItems
        ]);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'items-starred');
        const filtersUrl = '/jira/filters?page=1&sortKey=favourite&sortOrder=DESC';
        return favoriteItems.length > 0 ? (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), {
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'heading')
        }, formatMessage((0, (0, parcelRequire("31jbO")).default).starred)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuList), null, itemsToShow.map((item, idx)=>{
            const { url: url, id: id, favourite: isFavourite, title: title } = item;
            const contextItem = changedItems[item.id.toString()];
            const isPending = contextItem && contextItem.pending === true ? contextItem.pending : false;
            const filterParams = {
                issueKey: ''
            };
            const filterQuery = {
                filter: String(id)
            };
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
                key: id,
                ariaLabel: formatMessage((0, (0, parcelRequire("31jbO")).default).starred)
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
                analytics: {
                    actionSubjectId: 'filtersNavigationMenuItem',
                    menuItemId: id,
                    menuItemType: 'starred',
                    position: idx,
                    starred: Boolean(isFavourite)
                },
                href: url,
                iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("bTqXB"))))), {
                    label: ""
                }),
                iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fhtpB")).FavoriteButton), {
                    id: String(id),
                    isFavorite: Boolean(isFavourite),
                    favoriteItemName: title,
                    isPending: isPending,
                    menuItemType: "starred",
                    onChangeFavorite: onChangeFavorite,
                    type: (0, (0, parcelRequire("eXQym")).FILTERS_ITEM_TYPE)
                }),
                params: filterParams,
                query: filterQuery,
                testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, `item-${idx}`),
                to: (0, (0, parcelRequire("71Gk7")).globalIssueNavigatorRoute),
                onClick: (0, (0, parcelRequire("4zlUA")).handleGinSPAClick)
            }, title));
        })), favoriteItems.length >= (0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV) && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("56fXm")).MenuItemViewAll), {
            analytics: {
                actionSubjectId: 'filtersNavigationMenuItem',
                menuItemId: 'viewAll',
                position: (0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV)
            },
            href: filtersUrl,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-view-all'),
            to: filtersUrl
        })) : null;
    };
});
parcelRegister("4zlUA", function(module, exports) {
    $parcel$export(module.exports, "handleGinSPAClick", ()=>$7b30a009e09f47e3$export$f74673b086629362);
    const $7b30a009e09f47e3$export$f74673b086629362 = (e)=>{
        const href = typeof e.currentTarget.href === 'string' ? e.currentTarget.href : undefined;
        const openInNewTab = e.metaKey || e.altKey || e.ctrlKey || e.shiftKey || e.button != null && e.button === 1;
        if (href == null || href === '' || e.defaultPrevented || openInNewTab) return;
        if (window.JIRA?.Issues?.Application) {
            e.preventDefault();
            window.JIRA.Issues.Application.execute('navigation:navigateToUrl', href, {
                forceRefresh: true,
                checkPermission: true,
                replace: false,
                reset: true,
                routerEvent: true
            });
        }
    };
});
parcelRegister("71Gk7", function(module, exports) {
    $parcel$export(module.exports, "globalIssueNavigatorRoute", ()=>$7087c0d72ce043a7$export$f4c8e8bc55cd2008);
    $parcel$export(module.exports, "globalIssueNavigatorBrowseRoute", ()=>$7087c0d72ce043a7$export$c1b37e392499fccf);
    $parcel$export(module.exports, "globalIssueNavigatorRedirectRoute", ()=>$7087c0d72ce043a7$export$b2cd2888fd62d62e);
    var $65Ltd;
    const $7087c0d72ce043a7$export$f4c8e8bc55cd2008 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_LEGACY_GIN),
        path: '/issues/:issueKey(.*)?',
        apdexIgnoreParams: [
            'issueKey'
        ]
    };
    const $7087c0d72ce043a7$export$c1b37e392499fccf = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_LEGACY_GIN_ISSUE),
        path: '/browse/:issueKey?',
        query: [
            'jql|filter'
        ]
    };
    const $7087c0d72ce043a7$export$b2cd2888fd62d62e = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_LEGACY_GIN_QUICKSEARCH),
        path: '/secure/QuickSearch.jspa',
        query: [
            'searchString'
        ]
    };
});
parcelRegister("31jbO", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$1350230aaed398a9$export$2e2bcd8739ae039);
    var $7VHMR;
    var $1350230aaed398a9$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        starred: {
            "id": "atlassian-navigation.filters.menu.favorite-content.starred",
            "defaultMessage": "Starred"
        }
    });
});
parcelRegister("7xt41", function(module, exports) {
    $parcel$export(module.exports, "Footer", ()=>$8201760f444f9d16$export$a06f1c675e846f6f);
    var $5uXOq = parcelRequire("5uXOq");
    var $koVbs;
    var $6cNb0;
    var $13DBR;
    var $d4B4Y;
    var $aXYEG;
    const $8201760f444f9d16$export$a06f1c675e846f6f = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'items');
        return (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'filtersNavigationMenuItem',
                menuItemId: 'viewAll',
                menuItemType: 'action',
                position: 0
            },
            href: "/jira/filters?searchName=&Search=Search&filterView=search",
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-view-all'),
            to: (0, (0, parcelRequire("13DBR")).filtersDirectoryRoute)
        }, formatMessage((0, (0, parcelRequire("aXYEG")).default).viewAll)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'filtersNavigationMenuItem',
                menuItemId: 'create',
                menuItemType: 'action',
                position: 1
            },
            href: `/issues/?jql=${encodeURIComponent('created >= -30d order by created DESC')}`,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-create')
        }, formatMessage((0, (0, parcelRequire("aXYEG")).default).viewAllIssues)));
    };
});
parcelRegister("13DBR", function(module, exports) {
    $parcel$export(module.exports, "filtersDirectoryRoute", ()=>$4b1900cd8840fb6b$export$6350f52c8b40042d);
    $parcel$export(module.exports, "filtersDirectoryRedirectRoute", ()=>$4b1900cd8840fb6b$export$f4f86e1ddc30a625);
    $parcel$export(module.exports, "secureFiltersDirectoryRedirectRoute", ()=>$4b1900cd8840fb6b$export$50796f2aecd63181);
    var $65Ltd;
    const $4b1900cd8840fb6b$export$6350f52c8b40042d = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_FILTERS_DIRECTORY),
        path: '/jira/filters'
    };
    const $4b1900cd8840fb6b$export$f4f86e1ddc30a625 = {
        name: `${(0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_FILTERS_DIRECTORY)}-redirect`,
        path: '/ManageFilters.jspa'
    };
    const $4b1900cd8840fb6b$export$50796f2aecd63181 = {
        name: `${(0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_FILTERS_DIRECTORY_SECURE)}-redirect`,
        path: '/secure/ManageFilters.jspa'
    };
});
parcelRegister("aXYEG", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$04b207ae58b19dda$export$2e2bcd8739ae039);
    var $7VHMR;
    var $04b207ae58b19dda$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        viewAllIssues: {
            "id": "atlassian-navigation.filters.menu.footer.view-all-issues",
            "defaultMessage": "View all issues"
        },
        viewAll: {
            "id": "atlassian-navigation.filters.menu.footer.view-all",
            "defaultMessage": "View all filters"
        }
    });
});
parcelRegister("1Q2KE", function(module, exports) {
    $parcel$export(module.exports, "LoadStatus", ()=>$5044ac4fa9b31340$export$c7df4a3f1b89de01);
    var $5uXOq = parcelRequire("5uXOq");
    var $bcn6L;
    var $apeap;
    var $dHtVS;
    const $5044ac4fa9b31340$export$c7df4a3f1b89de01 = ({ LoadStatusCommon: LoadStatusCommon = (0, (0, parcelRequire("dHtVS")).LoadStatus), shouldThrowError: shouldThrowError = false, useFavorite: useFavorite = (0, (0, parcelRequire("bcn6L")).useFavoriteFilters), useRecent: useRecent = (0, (0, parcelRequire("apeap")).useRecentFilters) })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement(LoadStatusCommon, {
            shouldThrowError: shouldThrowError,
            useProvider: useFavorite
        }), (0, ($parcel$interopDefault($5uXOq))).createElement(LoadStatusCommon, {
            shouldThrowError: shouldThrowError,
            useProvider: useRecent
        }));
});
parcelRegister("lQ0sa", function(module, exports) {
    $parcel$export(module.exports, "RecentContent", ()=>$2e9bf8b639eae23f$export$a2873dc4e96d8e90);
    var $5uXOq = parcelRequire("5uXOq");
    var $bTqXB;
    var $4mNf3;
    var $eXQym;
    var $koVbs;
    var $4zlUA;
    var $2EqxS;
    var $6cNb0;
    var $71Gk7;
    var $Mp6qH;
    var $fhtpB;
    var $d4B4Y;
    var $7ilb8;
    var $dMJS0;
    var $k3GIG;
    const $2e9bf8b639eae23f$export$a2873dc4e96d8e90 = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { recentItems: recentItems, favoriteItems: favoriteItems, changeFavorite: onChangeFavorite, changedItems: changedItems } = (0, (0, parcelRequire("dMJS0")).useFilterNavItems)();
        const itemsToShow = (0, $5uXOq.useMemo)(()=>recentItems.slice(0, (0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_RECENT)), [
            recentItems
        ]);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'items-recent');
        return recentItems.length > 0 ? (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), {
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'heading')
        }, formatMessage((0, (0, parcelRequire("k3GIG")).default).recent)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuList), null, itemsToShow.map((item, idx)=>{
            const { url: url, id: id, favourite: isFavourite, title: title } = item;
            const contextItem = changedItems[item.id.toString()];
            const isPending = contextItem && contextItem.pending === true ? contextItem.pending : false;
            const { id: beforeEntityId, type: beforeEntityType } = (0, (0, parcelRequire("2EqxS")).getBeforeEntity)(favoriteItems);
            const filterParams = {
                issueKey: ''
            };
            const filterQuery = {
                filter: String(id)
            };
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
                key: id,
                ariaLabel: formatMessage((0, (0, parcelRequire("k3GIG")).default).recent)
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
                analytics: {
                    actionSubjectId: 'filtersNavigationMenuItem',
                    menuItemId: id,
                    menuItemType: 'recent',
                    position: idx,
                    starred: Boolean(isFavourite)
                },
                href: url,
                iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("bTqXB"))))), {
                    label: ""
                }),
                iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fhtpB")).FavoriteButton), {
                    beforeEntityId: beforeEntityId,
                    beforeEntityType: beforeEntityType,
                    id: String(id),
                    isFavorite: Boolean(isFavourite),
                    favoriteItemName: title,
                    isPending: isPending,
                    menuItemType: "recent",
                    onChangeFavorite: onChangeFavorite,
                    type: (0, (0, parcelRequire("eXQym")).FILTERS_ITEM_TYPE)
                }),
                params: filterParams,
                query: filterQuery,
                testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, `item-${idx}`),
                to: (0, (0, parcelRequire("71Gk7")).globalIssueNavigatorRoute),
                onClick: (0, (0, parcelRequire("4zlUA")).handleGinSPAClick)
            }, title));
        }))) : null;
    };
});
parcelRegister("k3GIG", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$e96da9bc3d6ed1b2$export$2e2bcd8739ae039);
    var $7VHMR;
    var $e96da9bc3d6ed1b2$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        recent: {
            "id": "atlassian-navigation.filters.menu.recent-content.recent",
            "defaultMessage": "Recent"
        }
    });
});
parcelRegister("7ikvo", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$49764f41cba0da7b$export$2e2bcd8739ae039);
    var $7VHMR;
    var $49764f41cba0da7b$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        filters: {
            "id": "atlassian-navigation.filters.filters",
            "defaultMessage": "Filters"
        }
    });
});
parcelRegister("crC7i", function(module, exports) {
    $parcel$export(module.exports, "Help", ()=>$2e020bcbc529c0ab$export$1be7516c0280bee8);
    var $5uXOq = parcelRequire("5uXOq");
    var $jZ4fr;
    var $bHDKL;
    var $5Fk4Z;
    var $VSDnB;
    var $1I7E8;
    var $g0Gtc;
    var $2i4sA;
    var $7XYc4;
    var $4R6GH;
    var $6BNJk;
    var $kspz4;
    var $koVbs;
    var $hsmeR;
    var $lmyls;
    var $2Ui4E;
    var $6cNb0;
    var $g8I5C;
    var $3HwlS;
    var $8Bc5A;
    var $cE2Qr;
    var $1XJ20;
    var $4DPZY;
    var $ajOhG;
    var $heI7y;
    var $cZpsT;
    var $Mp6qH;
    var $4uXxK;
    var $1bshq;
    var $8dSZI;
    var $iPj86;
    var $5ofLo;
    var $gXTsP;
    var $kPpft;
    const $2e020bcbc529c0ab$var$Badge = (props)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5ofLo")).Badge), props);
    };
    const $2e020bcbc529c0ab$var$SpotlightTargetComponent = (0, (0, parcelRequire("7XYc4")).componentWithCondition)(()=>(0, (0, parcelRequire("3HwlS")).getWillShowNav4)() && (0, (0, parcelRequire("4R6GH")).fg)('blu-5788-spotlight-help-button'), $2e020bcbc529c0ab$var$JWMFeedbackSpotlightWrapper, $2e020bcbc529c0ab$var$SpotlightTargetComponentOriginal);
    function $2e020bcbc529c0ab$var$SpotlightTargetComponentOriginal({ children: children }) {
        return false ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement($2e020bcbc529c0ab$var$SpotlightTarget, {
            name: (0, (0, parcelRequire("Mp6qH")).HELP_SPOTLIGHT_KEY)
        }, children), (0, (0, parcelRequire("4R6GH")).fg)('add_engagement_spotlight_to_navbar_button') && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jZ4fr")).EngagementSpotlight), {
            name: (0, (0, parcelRequire("Mp6qH")).HELP_SPOTLIGHT_KEY)
        }));
    }
    function $2e020bcbc529c0ab$var$SpotlightTarget({ children: children, ...props }) {
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Fk4Z")).default), props, (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            "data-test-id": `ak-spotlight-target-${props.name}`
        }, children));
    }
    function $2e020bcbc529c0ab$var$JWMFeedbackSpotlightWrapper({ children: children }) {
        const renderNudgeWrapper = (0, $5uXOq.useCallback)((kids)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ajOhG")).FeedbackNudgeAsync), null, kids), []);
        const [{ hasOpened: shouldShowNudge }] = (0, (0, parcelRequire("heI7y")).useHasOpened)();
        const [isUnloaded] = (0, (0, parcelRequire("cE2Qr")).useIsDismissed)();
        const [openTask] = (0, (0, parcelRequire("cE2Qr")).useOpenTask)();
        const [, { setHasNudgeActive: setHasNudgeActive }] = (0, (0, parcelRequire("cE2Qr")).useQuickstartActions)();
        (0, $5uXOq.useEffect)(()=>{
            if (!isUnloaded && openTask === (0, (0, parcelRequire("8Bc5A")).JWM_FEEDBACK_TASK) && shouldShowNudge) setHasNudgeActive(true);
        }, [
            isUnloaded,
            openTask,
            shouldShowNudge,
            setHasNudgeActive
        ]);
        return !false && shouldShowNudge ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("g8I5C")).QuickstartNudge), {
            renderWrapper: renderNudgeWrapper
        }, children) : (0, ($parcel$interopDefault($5uXOq))).createElement($2e020bcbc529c0ab$var$SpotlightTargetComponentOriginal, null, children);
    }
    function $2e020bcbc529c0ab$var$Help() {
        const [{ data: projectData }] = (0, (0, parcelRequire("4DPZY")).useContainerContext)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [isMenuOpen, { toggle: toggleMenu, off: closeMenu, on: openMenu }] = (0, (0, parcelRequire("2Ui4E")).useTopMenus)((0, (0, parcelRequire("lmyls")).MENU_ID).HELP);
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const [topMenusValue, { off: closeAllMenus }] = (0, (0, parcelRequire("2Ui4E")).useTopMenus)();
        const [, { setNavigationData: setNavigationData }] = (0, (0, parcelRequire("kspz4")).useHelpPanelActions)();
        const { releaseNotesNotifications: releaseNotesNotifications, setReleaseNotesNotifications: setReleaseNotesNotifications } = (0, (0, parcelRequire("8dSZI")).useHelpButtonState)();
        const isIPH = true;
        const helpButtonRef = (0, $5uXOq.useRef)(null);
        let isProjectSimplified = false;
        if (projectData && 'project' in projectData && projectData.project) isProjectSimplified = projectData.project.isSimplified;
        const attributes = isIPH ? {
            helpPanelVersion: (0, (0, parcelRequire("6BNJk")).HELP_PANEL_VERSION),
            helpPanelType: 'inProductHelp',
            nextGenProject: isProjectSimplified
        } : {
            helpPanelType: 'dropdown',
            nextGenProject: isProjectSimplified
        };
        const triggerAnalytics = (0, (0, parcelRequire("iPj86")).useNavigationItemAnalytics)({
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).HELP
        });
        const { openRightSidebar: openRightSidebar, closeCurrentRightSidebar: closeCurrentRightSidebar } = (0, (0, parcelRequire("hsmeR")).useRightSidebarController)();
        const currentRightSidebarState = (0, (0, parcelRequire("hsmeR")).useActiveRightSidebarState)();
        const isHelpPanelOpenedPreviously = (0, (0, parcelRequire("1XJ20")).usePrevious)(currentRightSidebarState);
        const testIdPrefix = (0, (0, parcelRequire("6cNb0")).testIdGenerate)('secondary-actions', 'help');
        const content = (0, $5uXOq.useCallback)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2i4sA")).ViewExperienceTrackingProvider), {
                application: null,
                analyticsSource: "atlassian-navigation",
                experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU),
                experienceId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).HELP,
                edition: null
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("g0Gtc")).ExperienceSuccessTracker), {
                location: "atlassian-navigation--secondary-actions--help--menu-popup",
                parentProviders: null,
                failureEventAttributes: null
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gXTsP")).Menu), {
                testIdPrefix: testIdPrefix
            }))), [
            testIdPrefix
        ]);
        const onClick = (0, $5uXOq.useCallback)(()=>{
            if (isIPH) {
                if (currentRightSidebarState && currentRightSidebarState.panelId === (0, (0, parcelRequire("6BNJk")).HELP_PANEL_ID) && !currentRightSidebarState.isCollapsed) closeCurrentRightSidebar();
                else openRightSidebar((0, (0, parcelRequire("6BNJk")).HELP_PANEL_ID), (0, (0, parcelRequire("6BNJk")).HELP_PANEL_WIDTH), false, true);
                closeAllMenus();
                setNavigationData({
                    articleId: {
                        id: '',
                        type: (0, (0, parcelRequire("bHDKL")).ARTICLE_TYPE).HELP_ARTICLE
                    },
                    history: []
                });
            } else toggleMenu();
            const isPanelOpen = currentRightSidebarState?.panelId === (0, (0, parcelRequire("6BNJk")).HELP_PANEL_ID) && !currentRightSidebarState?.isCollapsed;
            triggerAnalytics({
                ...attributes,
                ...(0, (0, parcelRequire("VSDnB")).getBooleanFF)('platform.navigation-help-opened-closed-indicator_b9xbt') ? {
                    isOpened: !isPanelOpen
                } : {}
            });
        }, [
            isIPH,
            triggerAnalytics,
            attributes,
            currentRightSidebarState,
            closeAllMenus,
            setNavigationData,
            closeCurrentRightSidebar,
            openRightSidebar,
            toggleMenu
        ]);
        const badge = (0, $5uXOq.useCallback)(()=>{
            if (isAnonymous) return null;
            return (0, ($parcel$interopDefault($5uXOq))).createElement($2e020bcbc529c0ab$var$Badge, {
                isNotificationCleared: releaseNotesNotifications === 0
            });
        }, [
            isAnonymous,
            releaseNotesNotifications
        ]);
        const TriggerComponent = (0, $5uXOq.useCallback)((triggerProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement("div", {
                "data-testid": (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'menu-trigger')
            }, (0, ($parcel$interopDefault($5uXOq))).createElement($2e020bcbc529c0ab$var$SpotlightTargetComponent, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1bshq")).Help), {
                ...triggerProps,
                isSelected: isMenuOpen,
                badge: badge,
                onClick: onClick,
                tooltip: formatMessage((0, (0, parcelRequire("kPpft")).default).tooltip),
                label: formatMessage((0, (0, parcelRequire("kPpft")).default).label),
                ref: helpButtonRef
            }))), [
            testIdPrefix,
            isMenuOpen,
            badge,
            onClick,
            formatMessage
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (!currentRightSidebarState?.isCollapsed && releaseNotesNotifications !== 0) setReleaseNotesNotifications(0);
        }, [
            releaseNotesNotifications,
            setReleaseNotesNotifications,
            currentRightSidebarState
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (isIPH) {
                if (currentRightSidebarState?.panelId === (0, (0, parcelRequire("6BNJk")).HELP_PANEL_ID) && !currentRightSidebarState?.isCollapsed) openMenu();
                else {
                    closeMenu();
                    if (isHelpPanelOpenedPreviously?.panelId === (0, (0, parcelRequire("6BNJk")).HELP_PANEL_ID) && !currentRightSidebarState) requestAnimationFrame(()=>helpButtonRef.current?.focus());
                }
            }
        }, [
            closeMenu,
            currentRightSidebarState,
            isHelpPanelOpenedPreviously,
            isIPH,
            openMenu,
            topMenusValue
        ]);
        if (false || isIPH) return (0, ($parcel$interopDefault($5uXOq))).createElement(TriggerComponent, {
            "aria-expanded": false,
            "aria-haspopup": !isIPH
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1I7E8")).default), {
            content: content,
            placement: "bottom-end",
            isOpen: isMenuOpen,
            onClose: closeMenu,
            trigger: TriggerComponent
        });
    }
    const $2e020bcbc529c0ab$export$1be7516c0280bee8 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).HELP
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($2e020bcbc529c0ab$var$Help, null));
});
parcelRegister("g8I5C", function(module, exports) {
    $parcel$export(module.exports, "QuickstartNudge", ()=>$0174d522ba9dfb06$export$bd61ee610c74db6c);
    var $5uXOq = parcelRequire("5uXOq");
    var $6OJ73;
    var $axUP7;
    const $0174d522ba9dfb06$export$bd61ee610c74db6c = (0, (0, parcelRequire("axUP7")).withNestingOptimisation)(({ renderWrapper: renderWrapper, children: children })=>{
        const isFullyExpanded = (0, (0, parcelRequire("6OJ73")).useIsFullyExpanded)();
        return isFullyExpanded ? renderWrapper(children) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
    });
});
parcelRegister("6OJ73", function(module, exports) {
    $parcel$export(module.exports, "useIsFullyExpanded", ()=>$c20291d4f7970c77$export$7212b6b01271f5d1);
    var $5uXOq = parcelRequire("5uXOq");
    var $gPQRI;
    const $c20291d4f7970c77$var$TIMEOUT = 500;
    const $c20291d4f7970c77$export$7212b6b01271f5d1 = ()=>{
        const [ready, setReady] = (0, $5uXOq.useState)(false);
        const { isCollapsed: isCollapsed } = (0, (0, parcelRequire("gPQRI")).useLeftSidebarState)();
        (0, $5uXOq.useLayoutEffect)(()=>{
            const timeoutId = setTimeout(()=>{
                setReady(!isCollapsed);
            }, $c20291d4f7970c77$var$TIMEOUT);
            return ()=>clearTimeout(timeoutId);
        }, [
            isCollapsed
        ]);
        return ready && !isCollapsed;
    };
});
parcelRegister("ajOhG", function(module, exports) {
    $parcel$export(module.exports, "FeedbackNudgeAsync", ()=>$9b3ebbbcc2dff472$export$be3f2958452e7be9);
    var $5uXOq = parcelRequire("5uXOq");
    var $bBbEG;
    var $lfTZh;
    var $kxZPA;
    const $9b3ebbbcc2dff472$var$FeedbackNudgeLazy = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("7u4Dj")).then((exportedModule)=>exportedModule.FeedbackNudge), {
        ssr: false,
        moduleId: "./src/packages/software/onboarding-nudges/src/ui/jwm-feedback/index.tsx"
    });
    const $9b3ebbbcc2dff472$export$be3f2958452e7be9 = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bBbEG")).OnboardingNudgeErrorBoundary), {
            id: (0, $dbbf68c32d8c4308$export$e9e47a7ad42e71f5),
            fallback: children,
            attributes: {
                nudgeId: (0, $dbbf68c32d8c4308$export$e9e47a7ad42e71f5)
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "feedback-onboarding-nudge",
            fallback: children
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($9b3ebbbcc2dff472$var$FeedbackNudgeLazy, null, children)));
    $9b3ebbbcc2dff472$export$be3f2958452e7be9.displayName = 'FeedbackNudgeAsync';
});
const $dbbf68c32d8c4308$export$64d75060ee6ba425 = 'backlogCreateIssueNudge';
const $dbbf68c32d8c4308$export$e0197e09cccd4e50 = 'backlogCreateSprintNudge';
const $dbbf68c32d8c4308$export$6770a2081a63fc15 = 'backlogStartSprintNudge';
const $dbbf68c32d8c4308$export$63b61f654ce3da3d = 'backlogCompleteSprintNudge';
const $dbbf68c32d8c4308$export$1c7a80b00ad9619 = 'backlogInsightsNudge';
const $dbbf68c32d8c4308$export$b8b9d90f9e5bd72b = 'jiraSoftwareOnboardingNudges';
const $dbbf68c32d8c4308$export$65fb0c4e534f03fc = 'asyncScrumTourNudge';
const $dbbf68c32d8c4308$export$f2fe211acb4aa81a = 'asyncKanbanTourNudge';
const $dbbf68c32d8c4308$export$fb9c6e63a7490968 = 'asyncJWMCalendarTourNudge';
const $dbbf68c32d8c4308$export$df53a339af97f102 = 'asyncJWMCalendarAndTimelineTourNudge';
const $dbbf68c32d8c4308$export$573d4bf1da6d2bc6 = 'asyncJWMBoardTourNudge';
const $dbbf68c32d8c4308$export$501e36925fa10eef = 'asyncJWMListTourNudge';
const $dbbf68c32d8c4308$export$2f06a16c987cc1a2 = 'asyncJWMTimelineTourNudge';
const $dbbf68c32d8c4308$export$226bdcb034c75951 = 'asyncJWMFormTourNudge';
const $dbbf68c32d8c4308$export$b72fce9f4cf11b5c = 'asyncJWMTrelloKeyDifferencesTourNudge';
const $dbbf68c32d8c4308$export$306510c7b1760652 = 'asyncJWMSummaryAndReportsTabNudge';
const $dbbf68c32d8c4308$export$cf1943561be3a3c2 = 'asyncJWMSummaryViewNudge';
const $dbbf68c32d8c4308$export$f8bf435a6066832c = 'button clicked';
const $dbbf68c32d8c4308$export$3bc551fe07069f89 = 'asyncJWMInviteTeamNudge';
const $dbbf68c32d8c4308$export$d99767a37baddfc = 'sampleProjectDataNudge';
const $dbbf68c32d8c4308$export$4db327e1949c2108 = 'boardInProgressColumnNudge';
const $dbbf68c32d8c4308$export$a402f2c11da96cd7 = 'boardCreateIssueNudge';
const $dbbf68c32d8c4308$export$1e174cd1e0212f3a = 'navigateCalendarNudge';
const $dbbf68c32d8c4308$export$6ffa29e74c0304c4 = 'tailorBoardColumnsNudge';
const $dbbf68c32d8c4308$export$a1398a6ef9526001 = 'inlineEditListNudge';
const $dbbf68c32d8c4308$export$5908fa8acd2c0739 = 'filterListNudge';
const $dbbf68c32d8c4308$export$50192b0fdc9bdacf = 'moreDropdownListNudge';
const $dbbf68c32d8c4308$export$e34e81ed4f255f6e = 'timelineAddDateNudge';
const $dbbf68c32d8c4308$export$aac6e5779d47ef37 = 'timelineAddChildItemNudge';
const $dbbf68c32d8c4308$export$69aca8ebee0d34c8 = 'trelloCrossflowAddItemsCalendarNudge';
const $dbbf68c32d8c4308$export$dd4b359b5a58cdc0 = 'trelloCrossflowTimelineTabNudge';
const $dbbf68c32d8c4308$export$1e0e647cc08bb4d8 = 'trelloKeyDifferencesIssueNudge';
const $dbbf68c32d8c4308$export$762576896c225bd6 = 'trelloKeyDifferencesSummaryNudge';
const $dbbf68c32d8c4308$export$5a96f813312dc7fa = 'trelloKeyDifferencesBoardNudge';
const $dbbf68c32d8c4308$export$ae8eeb04e0b1e9af = 'availableFieldsFormNudge';
const $dbbf68c32d8c4308$export$a54d77300f1dddea = 'changeIssueTypeFormNudge';
const $dbbf68c32d8c4308$export$6afdc7e5f6d2e915 = 'previewSaveFormNudge';
const $dbbf68c32d8c4308$export$eb0b199de90a7ec2 = 'backgroundColorNudge';
const $dbbf68c32d8c4308$export$e9e47a7ad42e71f5 = 'feedbackNudge';
const $dbbf68c32d8c4308$export$791f56b3d78403cc = 'calendarReleasesNudge';
const $dbbf68c32d8c4308$export$a1be34032ee24f78 = 'shareProjectNudge';
const $dbbf68c32d8c4308$export$29fe6611cc093756 = 'summaryTabNudge';
const $dbbf68c32d8c4308$export$f107c06c0830f09d = 'reportsTabNudge';
const $dbbf68c32d8c4308$export$9749a972a54ad7ba = 'boardContainerNudge';
parcelRegister("7u4Dj", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("eSNL6").then(()=>parcelRequire('5YJ1A'));
});
parcelRegister("1bshq", function(module, exports) {
    $parcel$export(module.exports, "Help", ()=>$a05c7042ce796542$export$1be7516c0280bee8);
    var $5uXOq = parcelRequire("5uXOq");
    var $4YvKq;
    var $9kXvc;
    var $3HwlS;
    var $ey2zZ;
    const $a05c7042ce796542$export$1be7516c0280bee8 = (0, $5uXOq.forwardRef)((props, ref)=>{
        if (false) {
            if ((0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9kXvc")).Nav4SkeletonHelpButton), {
                label: props.tooltip
            });
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9kXvc")).SkeletonHelpButton), {
                label: props.tooltip
            });
        }
        if ((0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) {
            const { badge: badge, onClick: onClick, isSelected: isSelected, label: label } = props;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ey2zZ")).Help), {
                badge: badge,
                label: label,
                onClick: onClick,
                isSelected: isSelected,
                ref: ref
            });
        }
        const buttonProps = {
            ...props,
            ref: ref
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4YvKq")).Help), buttonProps);
    });
});
parcelRegister("8dSZI", function(module, exports) {
    $parcel$export(module.exports, "useHelpButtonState", ()=>$dbb456bea3760752$export$e3d3de46b23d1ede);
    var $5uXOq = parcelRequire("5uXOq");
    var $90yGq;
    var $a6zkS;
    const $dbb456bea3760752$export$53f45136e435c2fd = 'help_button_ui_state';
    const $dbb456bea3760752$export$e3d3de46b23d1ede = ()=>{
        const [releaseNotesNotifications, setReleaseNotesNotifications] = (0, $5uXOq.useState)(null);
        const [userReleaseNotesNotifications, setUserReleaseNotesNotifications] = (0, $5uXOq.useState)(null);
        const accountId = (0, (0, parcelRequire("90yGq")).useAccountId)();
        const updateData = (0, $5uXOq.useCallback)(()=>{
            if (userReleaseNotesNotifications !== null) {
                const newReleaseNotesNotificationsValue = userReleaseNotesNotifications !== undefined ? userReleaseNotesNotifications : 100;
                if (releaseNotesNotifications !== null) {
                    if (userReleaseNotesNotifications !== releaseNotesNotifications) {
                        const remoteData = {
                            helpPanelMenu: {
                                releaseNotesNotifications: releaseNotesNotifications
                            }
                        };
                        (0, (0, parcelRequire("a6zkS")).updateHelpButtonState)(accountId, remoteData);
                    }
                } else setReleaseNotesNotifications(newReleaseNotesNotificationsValue);
            }
        }, [
            accountId,
            releaseNotesNotifications,
            userReleaseNotesNotifications
        ]);
        const restoreReleaseNotesNotifications = (0, $5uXOq.useCallback)(async ()=>{
            if (accountId) {
                const userPropertiesHelpButtonValue = await (0, (0, parcelRequire("a6zkS")).getHelpButtonState)(accountId);
                setUserReleaseNotesNotifications(userPropertiesHelpButtonValue?.releaseNotesNotifications);
            } else setUserReleaseNotesNotifications(0);
        }, [
            accountId
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (userReleaseNotesNotifications === null) restoreReleaseNotesNotifications();
            else updateData();
        }, [
            userReleaseNotesNotifications,
            restoreReleaseNotesNotifications,
            updateData
        ]);
        return {
            releaseNotesNotifications: releaseNotesNotifications,
            setReleaseNotesNotifications: setReleaseNotesNotifications
        };
    };
});
parcelRegister("a6zkS", function(module, exports) {
    $parcel$export(module.exports, "updateHelpButtonState", ()=>$67d0104a4e9a215c$export$c70764abdc0ffd52);
    $parcel$export(module.exports, "getHelpButtonState", ()=>$67d0104a4e9a215c$export$9716b2ae6c8887b0);
    var $89SaW;
    var $jcw0u;
    const $67d0104a4e9a215c$export$c70764abdc0ffd52 = async (accountId, data)=>{
        if (accountId !== null) try {
            await (0, (0, parcelRequire("89SaW")).setUserProperties)(accountId, (0, $2c95e3030594e0a4$export$53f45136e435c2fd), JSON.stringify(data));
        } catch (e) {
            (0, (0, parcelRequire("jcw0u")).default)({
                error: e,
                meta: {
                    id: 'updateServerHelpButtonState',
                    packageName: 'jiraAtlassianNavigation',
                    teamName: 'navigation'
                }
            });
        }
    };
    const $67d0104a4e9a215c$export$9716b2ae6c8887b0 = async (accountId)=>{
        try {
            const { helpPanelMenu: userPropertiesHelpButtonValue } = await (0, (0, parcelRequire("89SaW")).getUserProperty)(accountId, (0, $2c95e3030594e0a4$export$53f45136e435c2fd));
            return userPropertiesHelpButtonValue;
        } catch (e) {
            (0, (0, parcelRequire("jcw0u")).default)({
                error: e,
                meta: {
                    id: 'getHelpButtonState',
                    packageName: 'jiraAtlassianNavigation',
                    teamName: 'navigation'
                }
            });
        }
        return null;
    };
});
const $2c95e3030594e0a4$export$53f45136e435c2fd = 'help_button_ui_state';
parcelRegister("5ofLo", function(module, exports) {
    $parcel$export(module.exports, "Badge", ()=>$04b8d99d5802ffbb$export$37acb3580601e69a);
    var $5uXOq = parcelRequire("5uXOq");
    var $1pgoX = parcelRequire("1pgoX");
    var $kspz4;
    var $admgR;
    class $04b8d99d5802ffbb$var$MockNotificationLogClient extends (0, $1pgoX.NotificationLogClient) {
        constructor(countValue){
            super('', '');
            this.count = 0;
            this.count = countValue;
        }
        countUnseenNotifications() {
            return Promise.resolve({
                count: this.count
            });
        }
    }
    const $04b8d99d5802ffbb$var$emptyClient = new $04b8d99d5802ffbb$var$MockNotificationLogClient(0);
    const $04b8d99d5802ffbb$export$32bd6ac078326cec = async (isNotificationCleared)=>{
        const [{ releaseNotesNotifications: releaseNotesNotifications }] = (0, (0, parcelRequire("kspz4")).useHelpPanel)();
        const notificationLogProvider = (0, $5uXOq.useMemo)(()=>new $04b8d99d5802ffbb$var$MockNotificationLogClient(releaseNotesNotifications), [
            releaseNotesNotifications
        ]);
        const result = await Promise.resolve(isNotificationCleared || false || releaseNotesNotifications === 0 ? $04b8d99d5802ffbb$var$emptyClient : notificationLogProvider);
        return result;
    };
    const $04b8d99d5802ffbb$export$37acb3580601e69a = ({ isNotificationCleared: isNotificationCleared })=>{
        const [notifications, setNotifications] = (0, $5uXOq.useState)(0);
        const notificationLogProvider = $04b8d99d5802ffbb$export$32bd6ac078326cec(isNotificationCleared);
        (0, $5uXOq.useEffect)(()=>{
            const initNotifications = async ()=>{
                const result = await notificationLogProvider;
                setNotifications(result.count);
            };
            initNotifications();
        }, [
            notificationLogProvider
        ]);
        return notifications > 0 ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("admgR")).LazyNotifications), {
            max: 3,
            appearance: "primary"
        }, notifications) : null;
    };
});
parcelRegister("admgR", function(module, exports) {
    $parcel$export(module.exports, "LazyNotifications", ()=>$c9ac2a12d590e98a$export$eebecbd0322becdd);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $lfTZh;
    var $kxZPA;
    var $Mp6qH;
    const $c9ac2a12d590e98a$var$Notifications = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("6dLNF")), {
        ssr: false,
        moduleId: "../platform/packages/design-system/badge/src/index.tsx"
    });
    const $c9ac2a12d590e98a$export$eebecbd0322becdd = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "help.atlaskit-badge",
            packageName: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME),
            teamName: "navigation"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "lazy-notification-badge",
            fallback: null
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($c9ac2a12d590e98a$var$Notifications, props)));
});
parcelRegister("6dLNF", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("bA0K4").then(()=>parcelRequire('a5cvw'));
});
parcelRegister("gXTsP", function(module, exports) {
    $parcel$export(module.exports, "Menu", ()=>$68fac951608638d6$export$d9b273488cd8ce6f);
    var $5uXOq = parcelRequire("5uXOq");
    var $4mNf3;
    var $9UCCQ;
    var $7iULw;
    var $dseDh;
    var $koVbs;
    var $6cNb0;
    var $6jY6G;
    var $4DPZY;
    var $e9QkH;
    var $itmJb;
    var $cZpsT;
    var $cNd4s;
    var $kJP8l;
    var $Mp6qH;
    var $d4B4Y;
    var $ag3sS;
    var $dwJWB;
    const $68fac951608638d6$var$PLATFORM_FEEDBACK_GROUP_ID = 'jira-platform-general';
    const $68fac951608638d6$var$unknownAttributeValue = 'unknown';
    const $68fac951608638d6$var$docsUrlMap = {
        software: 'https://confluence.atlassian.com/jirasoftwarecloud/',
        serviceDesk: 'https://confluence.atlassian.com/servicedeskcloud/',
        core: 'https://confluence.atlassian.com/jiracorecloud/',
        jira: 'https://confluence.atlassian.com/alldoc/',
        'product-discovery': 'https://confluence.atlassian.com/alldoc/'
    };
    const $68fac951608638d6$var$GET_JIRA_MOBILE_APP_PAGE = 'https://www.atlassian.com/software/jira/mobile-app?source=jira-help';
    const $68fac951608638d6$export$d9b273488cd8ce6f = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const isAdmin = (0, (0, parcelRequire("itmJb")).useIsAdmin)();
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const isSiteAdmin = (0, (0, parcelRequire("cNd4s")).useIsSiteAdmin)();
        const productName = (0, (0, parcelRequire("kJP8l")).useProductName)();
        const [, { on: openKeyboardShortcuts }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("Mp6qH")).MODAL_ID).KEYBOARD_SHORTCUTS);
        let position = 0;
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'menu-popup');
        const route = (0, (0, parcelRequire("6jY6G")).useCurrentRoute)();
        const [{ data: projectData }] = (0, (0, parcelRequire("4DPZY")).useContainerContext)();
        const routeGroup = route.group ?? $68fac951608638d6$var$unknownAttributeValue;
        const routeName = route.name ?? $68fac951608638d6$var$unknownAttributeValue;
        let projectType = $68fac951608638d6$var$unknownAttributeValue;
        if (projectData && projectData.project) projectType = projectData.project.type;
        return (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ag3sS")).MenuLayout), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), {
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'help', 'heading')
        }, formatMessage((0, (0, parcelRequire("dwJWB")).default).headingHelp)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'helpNavigationMenuItem',
                menuItemId: 'atlassian-documentation',
                menuItemType: 'help',
                position: position++
            },
            href: $68fac951608638d6$var$docsUrlMap[productName],
            target: "_blank",
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'help', 'item-atlassian-documentation')
        }, formatMessage((0, (0, parcelRequire("dwJWB")).default).atlassianDocumentation)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'helpNavigationMenuItem',
                menuItemId: 'atlassian-community',
                menuItemType: 'help',
                position: position++
            },
            href: "https://community.atlassian.com",
            target: "_blank",
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'help', 'item-atlassian-community')
        }, formatMessage((0, (0, parcelRequire("dwJWB")).default).atlassianCommunity)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'helpNavigationMenuItem',
                menuItemId: 'whats-new',
                menuItemType: 'help',
                position: position++
            },
            href: "https://confluence.atlassian.com/pages/viewrecentblogposts.action?key=Cloud",
            target: "_blank",
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'help', 'item-whats-new')
        }, formatMessage((0, (0, parcelRequire("dwJWB")).default).whatsNew)), isAdmin || isSiteAdmin ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'helpNavigationMenuItem',
                menuItemId: 'raise-a-bug',
                menuItemType: 'help',
                position: position++
            },
            href: "https://support.atlassian.com/contact/",
            target: "_blank",
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'help', 'item-raise-a-bug')
        }, formatMessage((0, (0, parcelRequire("dwJWB")).default).raiseABug)) : null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dseDh")).FeedbackActionsSubscriber), null, (__, { openFeedbackCollector: openFeedbackCollector })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
                analytics: {
                    actionSubjectId: 'helpNavigationMenuItem',
                    menuItemId: 'feedback',
                    menuItemType: 'help',
                    position: position++
                },
                onClick: ()=>{
                    openFeedbackCollector({
                        entrypointId: (0, (0, parcelRequire("7iULw")).ENTRYPOINT_ID_JIRA_PLATFORM),
                        feedbackGroupId: $68fac951608638d6$var$PLATFORM_FEEDBACK_GROUP_ID,
                        additionalFields: [
                            {
                                id: 'route-name',
                                value: routeName
                            },
                            {
                                id: 'route-group',
                                value: routeGroup
                            },
                            {
                                id: 'project-type',
                                value: projectType
                            }
                        ]
                    });
                },
                testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'help', 'item-feedback')
            }, formatMessage((0, (0, parcelRequire("dwJWB")).default).giveFeedback))), !isAnonymous && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'helpNavigationMenuItem',
                menuItemId: 'get-mobile',
                menuItemType: 'help',
                position: position++
            },
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'help', 'item-get-mobile'),
            href: $68fac951608638d6$var$GET_JIRA_MOBILE_APP_PAGE,
            target: "_blank"
        }, formatMessage((0, (0, parcelRequire("dwJWB")).default).getMobile)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'helpNavigationMenuItem',
                menuItemId: 'keyboard-shortcuts',
                menuItemType: 'help',
                position: position++
            },
            onClick: openKeyboardShortcuts,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'help', 'item-keyboard-shortcuts')
        }, formatMessage((0, (0, parcelRequire("dwJWB")).default).keyboardShortcuts)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), {
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'legal', 'heading')
        }, formatMessage((0, (0, parcelRequire("dwJWB")).default).headingLegal)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'helpNavigationMenuItem',
                menuItemId: 'terms-of-use',
                menuItemType: 'legal',
                position: 0
            },
            href: "https://www.atlassian.com/legal/customer-agreement",
            target: "_blank",
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'legal', 'item-terms-of-use')
        }, formatMessage((0, (0, parcelRequire("dwJWB")).default).termsOfUse)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'helpNavigationMenuItem',
                menuItemId: 'privacy-policy',
                menuItemType: 'legal',
                position: 1
            },
            href: "https://www.atlassian.com/legal/privacy-policy",
            target: "_blank",
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'legal', 'item-privacy-policy')
        }, formatMessage((0, (0, parcelRequire("dwJWB")).default).privacyPolicy)))));
    };
});
parcelRegister("kJP8l", function(module, exports) {
    $parcel$export(module.exports, "useProductName", ()=>$fbc3e5a1ff3e1b43$export$dde400a84de238be);
    var $daoB1;
    var $kLseK;
    const $fbc3e5a1ff3e1b43$export$dde400a84de238be = ()=>{
        const appPermissions = (0, (0, parcelRequire("daoB1")).useApplicationPermissions)();
        return (0, (0, parcelRequire("kLseK")).getProductName)(appPermissions);
    };
});
parcelRegister("kLseK", function(module, exports) {
    $parcel$export(module.exports, "getProductName", ()=>$255564fdcf8ca770$export$aa3f36fe5dd1f237);
    const $255564fdcf8ca770$export$aa3f36fe5dd1f237 = (permissions)=>{
        const { hasSoftwareAccess: hasSoftwareAccess, hasServiceDeskAccess: hasServiceDeskAccess, hasCoreAccess: hasCoreAccess, hasProductDiscoveryAccess: hasProductDiscoveryAccess } = permissions;
        return ([
            {
                condition: hasSoftwareAccess && !hasServiceDeskAccess && !hasProductDiscoveryAccess,
                value: 'software'
            },
            {
                condition: hasServiceDeskAccess && !hasSoftwareAccess && !hasProductDiscoveryAccess,
                value: 'serviceDesk'
            },
            {
                condition: hasCoreAccess && !hasSoftwareAccess && !hasServiceDeskAccess && !hasProductDiscoveryAccess,
                value: 'core'
            },
            {
                condition: hasProductDiscoveryAccess && !hasSoftwareAccess && !hasServiceDeskAccess,
                value: 'product-discovery'
            }
        ].find((item)=>item.condition) || {
            value: 'jira'
        }).value;
    };
});
parcelRegister("dwJWB", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2ab93048aa1a9657$export$2e2bcd8739ae039);
    var $7VHMR;
    var $2ab93048aa1a9657$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        headingHelp: {
            "id": "atlassian-navigation.help.menu.heading-help",
            "defaultMessage": "Help"
        },
        headingLegal: {
            "id": "atlassian-navigation.help.menu.heading-legal",
            "defaultMessage": "Legal"
        },
        atlassianCommunity: {
            "id": "atlassian-navigation.help.menu.atlassian-community",
            "defaultMessage": "Atlassian Community"
        },
        atlassianDocumentation: {
            "id": "atlassian-navigation.help.menu.atlassian-documentation",
            "defaultMessage": "Atlassian Documentation"
        },
        whatsNew: {
            "id": "atlassian-navigation.help.menu.whats-new",
            "defaultMessage": "What's New"
        },
        privacyPolicy: {
            "id": "atlassian-navigation.help.menu.privacy-policy",
            "defaultMessage": "Privacy policy"
        },
        termsOfUse: {
            "id": "atlassian-navigation.help.menu.terms-of-use",
            "defaultMessage": "Terms of use"
        },
        getMobile: {
            "id": "atlassian-navigation.help.menu.get-mobile",
            "defaultMessage": "Get Jira Mobile"
        },
        aboutJira: {
            "id": "atlassian-navigation.help.menu.about-jira",
            "defaultMessage": "About Jira"
        },
        keyboardShortcuts: {
            "id": "atlassian-navigation.help.menu.keyboard-shortcuts",
            "defaultMessage": "Keyboard shortcuts"
        },
        giveFeedback: {
            "id": "atlassian-navigation.help.menu.give-feedback",
            "defaultMessage": "Give feedback"
        },
        raiseABug: {
            "id": "atlassian-navigation.help.menu.raise-a-bug",
            "defaultMessage": "Raise a bug"
        }
    });
});
parcelRegister("kPpft", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$e66fc02bdc2bad41$export$2e2bcd8739ae039);
    var $7VHMR;
    var $e66fc02bdc2bad41$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        tooltip: {
            "id": "atlassian-navigation.help.tooltip",
            "defaultMessage": "Help"
        },
        label: {
            "id": "atlassian-navigation.help.label",
            "defaultMessage": "Help"
        }
    });
});
parcelRegister("MBTxm", function(module, exports) {
    $parcel$export(module.exports, "InitialDataLoader", ()=>$0c63bc4f7fc4fb59$export$867ec025b81eadf3);
    var $5uXOq = parcelRequire("5uXOq");
    var $kJH82;
    var $2N6bN;
    var $bcn6L;
    var $eKYL7;
    var $d40Fj;
    var $apeap;
    var $lsa9q;
    var $90yGq;
    var $fY39E;
    var $koeKL;
    var $DVljb;
    const $0c63bc4f7fc4fb59$export$867ec025b81eadf3 = ()=>{
        const [, { loadRecentAll: loadRecentAll }] = (0, (0, parcelRequire("apeap")).useRecentActions)();
        const [, { loadFavoriteAll: loadFavoriteAll }] = (0, (0, parcelRequire("bcn6L")).useFavoriteActions)();
        const [, { fetchCreateProjectPermission: fetchCreateProjectPermission }] = (0, (0, parcelRequire("d40Fj")).useCreateProjectPermissionActions)();
        const [, { load: loadForgeItem }] = (0, (0, parcelRequire("eKYL7")).useForgeGlobalItems)();
        const [, { load: loadAddons }] = (0, (0, parcelRequire("kJH82")).useAddon)();
        const [, { fetchTraits: fetchTraits }] = (0, (0, parcelRequire("2N6bN")).usePersonalizationProvider)();
        const hasFetchedAll = (0, $5uXOq.useRef)(false);
        const [{ hasLoaded: hasLoaded }] = (0, (0, parcelRequire("lsa9q")).default)();
        const [hasTimedOut, setHasTimedOut] = (0, $5uXOq.useState)(false);
        const accountId = (0, (0, parcelRequire("90yGq")).useAccountId)();
        const activationId = (0, (0, parcelRequire("fY39E")).useActivationId)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const locale = (0, (0, parcelRequire("DVljb")).useLocale)();
        (0, $5uXOq.useEffect)(()=>{
            if ((hasLoaded || hasTimedOut) && !hasFetchedAll.current) {
                loadRecentAll();
                loadFavoriteAll((0, $7a1562bd0ca086b0$export$ea341e6cc435ed40));
                loadForgeItem({
                    cloudId: cloudId,
                    activationId: activationId,
                    locale: locale
                });
                fetchCreateProjectPermission();
                loadAddons();
                hasFetchedAll.current = true;
            }
        }, [
            hasLoaded,
            hasTimedOut,
            hasFetchedAll,
            loadRecentAll,
            loadFavoriteAll,
            fetchCreateProjectPermission,
            loadForgeItem,
            loadAddons,
            accountId,
            fetchTraits,
            cloudId,
            activationId,
            locale
        ]);
        (0, $5uXOq.useEffect)(()=>{
            let t;
            if (!hasLoaded) t = setTimeout(()=>setHasTimedOut(true), 10000);
            return ()=>clearTimeout(t);
        }, [
            hasLoaded,
            setHasTimedOut
        ]);
        return null;
    };
});
parcelRegister("2N6bN", function(module, exports) {
    $parcel$export(module.exports, "usePersonalizationProvider", ()=>$5d7e4157825488be$export$816e978a144775ee);
    var $3sQ5x;
    var $iXqE5;
    var $f9blZ;
    const $5d7e4157825488be$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'create-project-permissions',
        initialState: {
            traits: [],
            siteTraits: [],
            isFetching: false,
            fetchError: null,
            promise: null,
            hasFetchedOnce: false,
            hasSuccessOnce: false
        },
        actions: (0, parcelRequire("f9blZ")).actions
    });
    const $5d7e4157825488be$export$816e978a144775ee = (0, (0, parcelRequire("3sQ5x")).createHook)($5d7e4157825488be$var$store);
});
parcelRegister("f9blZ", function(module, exports) {
    $parcel$export(module.exports, "actions", ()=>$3a9df0465aab2f9e$export$e324594224ef24da);
    var $jcw0u;
    var $eusud;
    const $3a9df0465aab2f9e$var$performGetRequestForTraits = async (url)=>{
        try {
            const response = await (0, (0, parcelRequire("eusud")).performGetRequest)(url);
            if (response?.attributes) return {
                data: response.attributes,
                error: null
            };
            throw Error('response is not defined or null');
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: 'jwmSampleProjectService',
                    packageName: (0, $fb261d53ea98c20e$export$b8b9d90f9e5bd72b),
                    teamName: 'jira-werewolves'
                },
                error: error,
                sendToPrivacyUnsafeSplunk: true
            });
            return {
                data: [],
                error: error
            };
        }
    };
    const $3a9df0465aab2f9e$export$820a550ad3fb8364 = (cloudId, atlassianAccountId)=>async ({ setState: setState, getState: getState })=>{
            const { isFetching: isFetching } = getState();
            if (!isFetching) {
                setState({
                    isFetching: true,
                    fetchError: null
                });
                const siteTraitUrl = `/gateway/api/tap-delivery/api/v3/personalization/site/${cloudId}`;
                const siteUserTraitUrl = `${siteTraitUrl}/user/${atlassianAccountId}`;
                const [{ data: listofSiteUserTraits, error: errorOnSiteUser }, { data: listofSiteTraits, error: errorOnSite }] = await Promise.all([
                    $3a9df0465aab2f9e$var$performGetRequestForTraits(siteUserTraitUrl),
                    $3a9df0465aab2f9e$var$performGetRequestForTraits(siteTraitUrl)
                ]);
                const errors = errorOnSiteUser || errorOnSite;
                setState({
                    traits: listofSiteUserTraits,
                    siteTraits: listofSiteTraits,
                    isFetching: false,
                    fetchError: errors,
                    hasFetchedOnce: true,
                    ...errors ? {} : {
                        hasSuccessOnce: true
                    }
                });
            }
        };
    const $3a9df0465aab2f9e$export$e324594224ef24da = {
        fetchTraits: (cloudId, atlassianAccountId)=>async ({ setState: setState, dispatch: dispatch })=>{
                const promise = dispatch($3a9df0465aab2f9e$export$820a550ad3fb8364(cloudId, atlassianAccountId));
                setState({
                    isFetching: true,
                    promise: promise
                });
            }
    };
});
const $fb261d53ea98c20e$export$b8b9d90f9e5bd72b = 'jira-business.sample-project';
const $fb261d53ea98c20e$export$ba541fd402eb5f6f = 'jwm-sample-project-experiment';
const $fb261d53ea98c20e$export$f7109619a5e9e0d6 = 'jwmSampleProjectExperiment';
const $fb261d53ea98c20e$export$529244c5c5612bf2 = 'jwmSampleProjectCreateFailure';
const $fb261d53ea98c20e$export$a1a2e9d60f36ed80 = 'jira_software_ui_moon_experiment_create_jwm_sample_experiment_project_dismissed_first';
const $fb261d53ea98c20e$export$fc53f723f5163588 = 'jira_software_ui_moon_experiment_create_jwm_sample_experiment_project_created_first';
const $fb261d53ea98c20e$export$ce71307e913afd12 = 'jira-core_business_project_created_first';
const $fb261d53ea98c20e$export$a92ddfff80995acf = 'jira_screen_viewed_jwmsampleprojectexperimentdropdown_first';
const $fb261d53ea98c20e$export$9365f74cb008dc7c = 'brain_jsw_to_jwm_implicit_site_boolean';
const $fb261d53ea98c20e$export$142f18c04fa33074 = 'export-issues-xflow-modal';
const $fb261d53ea98c20e$export$2667e34461b211df = 'invite-business-teams-modal';
const $fb261d53ea98c20e$export$63fbd2796586b8eb = 'projects-menu-existing-users';
const $fb261d53ea98c20e$export$d25895a47354c866 = 'projects-menu-new-users';
const $fb261d53ea98c20e$export$b807b84bb648264b = 'onboarding-checklist';
const $fb261d53ea98c20e$export$bf3fe75eb774ca83 = 'projects-jwm-empty-list';
const $fb261d53ea98c20e$export$df59c204c3152280 = 'issue-links-on-jsw-board-cards';
parcelRegister("9i62K", function(module, exports) {
    $parcel$export(module.exports, "useShowAssets", ()=>$ecfb0cbe48af33be$export$6d1797fe1bb33b7a);
    $parcel$export(module.exports, "Insight", ()=>$ecfb0cbe48af33be$export$f9e7e087e1968b4a);
    var $5uXOq = parcelRequire("5uXOq");
    var $bliJ7;
    var $39xOx;
    var $lmyls;
    var $8zOmE;
    var $2NMNM;
    var $ktP1V;
    var $ahEft;
    var $90yGq;
    var $9OXo1;
    var $4uXxK;
    var $4XiFK;
    var $hFLLE;
    const $ecfb0cbe48af33be$export$6d1797fe1bb33b7a = ()=>{
        const tenantContext = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const { appPermissions: appPermissions } = tenantContext;
        const isJSMUser = appPermissions.hasServiceDeskAccess;
        if ((0, (0, parcelRequire("ktP1V")).isCMDBEnabledInEnvironment)()) {
            if (isJSMUser) return true;
        }
        return false;
    };
    const $ecfb0cbe48af33be$var$Insight = ()=>{
        const tenantContext = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const { appPermissions: appPermissions } = tenantContext;
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const isPremiumUser = (0, (0, parcelRequire("ahEft")).isLicensedPremiumUser)(tenantContext);
        const isJSMUser = appPermissions.hasServiceDeskAccess;
        const [hasDismissed] = (0, (0, parcelRequire("bliJ7")).useUserHasDismissed)();
        const showUpsell = hasDismissed === false && isJSMUser;
        (0, $5uXOq.useEffect)(()=>{
            if (!isPremiumUser && showUpsell) (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(createAnalyticsEvent({}), 'cmdbUpsellPageButton viewed', {
                serviceDeskEdition: tenantContext.appEditions?.serviceDesk,
                isSiteAdmin: tenantContext.isSiteAdmin
            });
        }, [
            createAnalyticsEvent,
            isPremiumUser,
            showUpsell,
            tenantContext.appEditions?.serviceDesk,
            tenantContext.isSiteAdmin
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "InsightNavMenu",
            packageName: "jiraAtlassianNavigation",
            fallback: "unmount"
        }, !isPremiumUser && showUpsell || isPremiumUser ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hFLLE")).InsightMenuItem), null) : null);
    };
    const $ecfb0cbe48af33be$export$f9e7e087e1968b4a = ()=>{
        const atlassianAccountId = (0, (0, parcelRequire("90yGq")).useAccountId)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).INSIGHT
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bliJ7")).CmdbUpsellPageContainer), {
            isGlobal: true,
            atlassianAccountId: atlassianAccountId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4XiFK")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement($ecfb0cbe48af33be$var$Insight, null))));
    };
});
parcelRegister("bliJ7", function(module, exports) {
    $parcel$export(module.exports, "CmdbUpsellPageContainer", ()=>$215e0f2cc3aecb56$export$185a1503ee62245e);
    $parcel$export(module.exports, "useUserHasDismissed", ()=>$215e0f2cc3aecb56$export$168f6ca47f2dd0d7);
    $parcel$export(module.exports, "useCmdbUpsellPageStoreActions", ()=>$215e0f2cc3aecb56$export$7bdf1f3cf5d7163b);
    var $3sQ5x;
    var $gg7kZ;
    var $iXqE5;
    var $51nlc;
    var $8aeF9;
    var $9jmKp;
    const $215e0f2cc3aecb56$export$185a1503ee62245e = (0, (0, parcelRequire("gg7kZ")).createContainer)();
    const $215e0f2cc3aecb56$var$initialState = {
        userHasDismissed: undefined
    };
    const $215e0f2cc3aecb56$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: $215e0f2cc3aecb56$var$initialState,
        actions: (0, parcelRequire("8aeF9")),
        containedBy: $215e0f2cc3aecb56$export$185a1503ee62245e,
        handlers: {
            onInit: (0, parcelRequire("9jmKp")).fetchInitialState
        }
    });
    const $215e0f2cc3aecb56$export$168f6ca47f2dd0d7 = (0, (0, parcelRequire("3sQ5x")).createHook)($215e0f2cc3aecb56$var$store, {
        selector: (state)=>state.userHasDismissed
    });
    const $215e0f2cc3aecb56$export$81f35d374558f538 = (0, (0, parcelRequire("3sQ5x")).createHook)($215e0f2cc3aecb56$var$store);
    const $215e0f2cc3aecb56$export$7bdf1f3cf5d7163b = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($215e0f2cc3aecb56$var$store);
    const $215e0f2cc3aecb56$export$d766ac3cb3a067c0 = (0, (0, parcelRequire("51nlc")).createSubscriber)($215e0f2cc3aecb56$var$store);
});
parcelRegister("8aeF9", function(module, exports) {
    $parcel$export(module.exports, "fetchInitialState", ()=>(parcelRequire("9jmKp")).fetchInitialState);
    $parcel$export(module.exports, "toggleShowPage", ()=>(parcelRequire("hVpHy")).toggleShowPage);
    var $9jmKp;
    var $hVpHy;
});
parcelRegister("9jmKp", function(module, exports) {
    $parcel$export(module.exports, "fetchInitialState", ()=>$bf172d9d821cf507$export$84ded0ac7058ab7a);
    var $eeyUP;
    const $bf172d9d821cf507$export$84ded0ac7058ab7a = ()=>async ({ setState: setState, getState: getState }, { atlassianAccountId: atlassianAccountId })=>{
            if (getState().userHasDismissed === undefined) {
                const userHasDismissed = await (0, (0, parcelRequire("eeyUP")).fetchHasDismissedProperty)(atlassianAccountId);
                setState({
                    userHasDismissed: userHasDismissed
                });
            }
        };
});
parcelRegister("eeyUP", function(module, exports) {
    $parcel$export(module.exports, "fetchHasDismissedProperty", ()=>$2f0ac8757ae7c53f$export$872c472220985d8b);
    var $89SaW;
    var $jcw0u;
    const $2f0ac8757ae7c53f$export$872c472220985d8b = async (atlassianAccountId)=>{
        const meta = {
            id: 'fetchUpsellDismissedProperty',
            packageName: 'jiraCmdbUpsellPage'
        };
        if (atlassianAccountId === null) {
            const error = new Error('atlassianAccountId is null');
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: meta
            });
            return false;
        }
        try {
            await (0, (0, parcelRequire("89SaW")).getUserProperty)(atlassianAccountId, (0, $8f514cf95b75cc66$export$38c3dc12cf3da533));
            return true;
        } catch (error) {
            if (error instanceof Error && (0, (0, parcelRequire("jcw0u")).isNetworkError)(error)) return false;
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error instanceof Error ? error : undefined,
                meta: meta
            });
            return false;
        }
    };
});
const $8f514cf95b75cc66$export$38c3dc12cf3da533 = 'jsm-cmdb-upsell-page-dismissed';
parcelRegister("hVpHy", function(module, exports) {
    $parcel$export(module.exports, "toggleShowPage", ()=>$983267917c332983$export$76132886e83e1a9a);
    var $b7zXG;
    var $eWMDa;
    const $983267917c332983$export$76132886e83e1a9a = ()=>async ({ getState: getState, dispatch: dispatch })=>{
            const { userHasDismissed: userHasDismissed } = getState();
            if (userHasDismissed) dispatch((0, (0, parcelRequire("eWMDa")).enablePage)());
            else if (userHasDismissed === false) dispatch((0, (0, parcelRequire("b7zXG")).dismissPage)());
        };
});
parcelRegister("b7zXG", function(module, exports) {
    $parcel$export(module.exports, "dismissPage", ()=>$54ab437faf58b2ea$export$b6e8cf6e649eeb96);
    var $89SaW;
    var $jcw0u;
    const $54ab437faf58b2ea$export$b6e8cf6e649eeb96 = ()=>async ({ setState: setState }, { atlassianAccountId: atlassianAccountId })=>{
            setState({
                userHasDismissed: true
            });
            try {
                atlassianAccountId && await (0, (0, parcelRequire("89SaW")).setUserProperties)(atlassianAccountId, (0, $8f514cf95b75cc66$export$38c3dc12cf3da533), '1');
            } catch (error) {
                (0, (0, parcelRequire("jcw0u")).default)({
                    error: error instanceof Error ? error : undefined,
                    meta: {
                        id: 'fetchUpsellDismissedProperty',
                        packageName: 'jiraCmdbUpsellPage'
                    }
                });
            }
        };
});
parcelRegister("eWMDa", function(module, exports) {
    $parcel$export(module.exports, "enablePage", ()=>$72e0594a39999a08$export$d904731aa3799771);
    var $jcw0u;
    var $5aUqV;
    const $72e0594a39999a08$export$d904731aa3799771 = ()=>async ({ setState: setState }, { atlassianAccountId: atlassianAccountId })=>{
            setState({
                userHasDismissed: false
            });
            try {
                atlassianAccountId && await (0, (0, parcelRequire("5aUqV")).deleteUserProperties)('', atlassianAccountId, (0, $8f514cf95b75cc66$export$38c3dc12cf3da533));
            } catch (error) {
                (0, (0, parcelRequire("jcw0u")).default)({
                    error: error instanceof Error ? error : undefined,
                    meta: {
                        id: 'fetchUpsellEnabledProperty',
                        packageName: 'jiraCmdbUpsellPage'
                    }
                });
            }
        };
});
parcelRegister("5aUqV", function(module, exports) {
    $parcel$export(module.exports, "deleteUserProperties", ()=>$9af5c0a0f8e96e9b$export$3de6197fc2f24c03);
    var $gHS8W;
    var $89SaW;
    const $9af5c0a0f8e96e9b$export$3de6197fc2f24c03 = async (baseUrl, accountId, propertyKey)=>fetch((0, (0, parcelRequire("89SaW")).getUserPropertiesUrl)(accountId, propertyKey), {
            ...(0, (0, parcelRequire("gHS8W")).default),
            method: 'DELETE'
        });
});
parcelRegister("hFLLE", function(module, exports) {
    $parcel$export(module.exports, "InsightMenuItem", ()=>$944d4f2112c8b786$export$eb258e2cec2937f9);
    var $5uXOq = parcelRequire("5uXOq");
    var $koVbs;
    var $lmyls;
    var $blEB6;
    var $9OXo1;
    var $Mp6qH;
    var $fVbkk;
    var $7rU7r;
    const $944d4f2112c8b786$export$eb258e2cec2937f9 = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { isSiteAdmin: isSiteAdmin, appEditions: appEditions } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVbkk")).MenuTrigger), {
            href: (0, (0, parcelRequire("blEB6")).getLandingPageUrl)(),
            menuId: (0, (0, parcelRequire("lmyls")).MENU_ID).INSIGHT,
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).INSIGHT,
            analyticsAttributes: {
                isSiteAdmin: isSiteAdmin,
                serviceDeskEdition: appEditions?.serviceDesk
            }
        }, formatMessage((0, (0, parcelRequire("7rU7r")).default).titleCmdbAssets));
    };
});
parcelRegister("blEB6", function(module, exports) {
    $parcel$export(module.exports, "isInsightRoute", ()=>$587a2dd53fdae2e8$export$296d80f3ce129968);
    $parcel$export(module.exports, "getCreateServiceUrl", ()=>$587a2dd53fdae2e8$export$1f9b8b558a361aaf);
    $parcel$export(module.exports, "getRootPath", ()=>$587a2dd53fdae2e8$export$5413f45d031c7806);
    $parcel$export(module.exports, "getLandingPageUrl", ()=>$587a2dd53fdae2e8$export$9366da5a373815e5);
    $parcel$export(module.exports, "getGlobalConfigUrl", ()=>$587a2dd53fdae2e8$export$20fd4a0aec335970);
    $parcel$export(module.exports, "getDataManagerUrl", ()=>$587a2dd53fdae2e8$export$c9b27b0d2e19d52f);
    $parcel$export(module.exports, "getDataManagerAdaptersUrl", ()=>$587a2dd53fdae2e8$export$cd6b35373ba077a);
    $parcel$export(module.exports, "getAssetsReportsUrl", ()=>$587a2dd53fdae2e8$export$c1909404b7ea317f);
    $parcel$export(module.exports, "createObjectSchemasUrl", ()=>$587a2dd53fdae2e8$export$c48ea1282c74411e);
    $parcel$export(module.exports, "createObjectSchemaUrl", ()=>$587a2dd53fdae2e8$export$3a0332e980fe56cf);
    $parcel$export(module.exports, "createObjectTypeUrl", ()=>$587a2dd53fdae2e8$export$cfefb90cfb86b528);
    $parcel$export(module.exports, "createObjectSchemaConfigUrl", ()=>$587a2dd53fdae2e8$export$f02479149ce3cac7);
    $parcel$export(module.exports, "createObjectDetailUrl", ()=>$587a2dd53fdae2e8$export$51e4229f2c7d3866);
    $parcel$export(module.exports, "createObjectTypeSettingsUrl", ()=>$587a2dd53fdae2e8$export$4b4b7d2c3df4fa58);
    $parcel$export(module.exports, "createObjectTypeListWithObjectSelectedUrl", ()=>$587a2dd53fdae2e8$export$c1790fa8f0d8e89a);
    $parcel$export(module.exports, "getFeatureUsagePageUrl", ()=>$587a2dd53fdae2e8$export$5ba89eed0cf60033);
    $parcel$export(module.exports, "getFeatureUsageVirtualAgentPageUrl", ()=>$587a2dd53fdae2e8$export$415502b49e27270d);
    var $9PhlU;
    const $587a2dd53fdae2e8$export$ee637ecb2dfa4f6a = '/jira/servicedesk/service-modal';
    const $587a2dd53fdae2e8$export$c90f45511b7ccc85 = '/jira/servicedesk/assets';
    const $587a2dd53fdae2e8$export$6d7574dbda22cf7 = '/jira/servicedesk/insight';
    const $587a2dd53fdae2e8$export$296d80f3ce129968 = (pathname)=>pathname.includes($587a2dd53fdae2e8$export$6d7574dbda22cf7);
    const $587a2dd53fdae2e8$export$1f9b8b558a361aaf = ()=>$587a2dd53fdae2e8$export$ee637ecb2dfa4f6a;
    const $587a2dd53fdae2e8$export$5413f45d031c7806 = ()=>$587a2dd53fdae2e8$export$c90f45511b7ccc85;
    const $587a2dd53fdae2e8$export$9366da5a373815e5 = ()=>$587a2dd53fdae2e8$export$5413f45d031c7806();
    const $587a2dd53fdae2e8$export$20fd4a0aec335970 = ()=>`${$587a2dd53fdae2e8$export$5413f45d031c7806()}/configure/global`;
    const $587a2dd53fdae2e8$export$c9b27b0d2e19d52f = ()=>`${$587a2dd53fdae2e8$export$5413f45d031c7806()}/data-manager/`;
    const $587a2dd53fdae2e8$export$cd6b35373ba077a = ()=>`${$587a2dd53fdae2e8$export$5413f45d031c7806()}/data-manager/adapters/`;
    const $587a2dd53fdae2e8$export$c1909404b7ea317f = ()=>`${$587a2dd53fdae2e8$export$5413f45d031c7806()}/reports`;
    const $587a2dd53fdae2e8$export$c48ea1282c74411e = ()=>`${$587a2dd53fdae2e8$export$5413f45d031c7806()}/object-schemas`;
    const $587a2dd53fdae2e8$export$3a0332e980fe56cf = (objectSchemaId)=>`${$587a2dd53fdae2e8$export$5413f45d031c7806()}/object-schema/${objectSchemaId}`;
    const $587a2dd53fdae2e8$export$cfefb90cfb86b528 = (objectSchemaId, objectTypeId)=>`${$587a2dd53fdae2e8$export$3a0332e980fe56cf(objectSchemaId)}?${(0, (0, parcelRequire("9PhlU")).SCHEMA_PAGE_QUERY_PARAMS).objectTypeId}=${objectTypeId}`;
    const $587a2dd53fdae2e8$export$f02479149ce3cac7 = (objectSchemaId)=>`${$587a2dd53fdae2e8$export$5413f45d031c7806()}/configure/object-schema/${objectSchemaId}`;
    const $587a2dd53fdae2e8$export$51e4229f2c7d3866 = (objectId)=>`${$587a2dd53fdae2e8$export$c90f45511b7ccc85}/object/${objectId}`;
    const $587a2dd53fdae2e8$export$4b4b7d2c3df4fa58 = (objectTypeId)=>`${$587a2dd53fdae2e8$export$5413f45d031c7806()}/configure/object-type/${objectTypeId}`;
    const $587a2dd53fdae2e8$export$c1790fa8f0d8e89a = (objectSchemaId, objectTypeId, objectId)=>`${$587a2dd53fdae2e8$export$cfefb90cfb86b528(objectSchemaId, objectTypeId)}&view=list&objectId=${objectId}`;
    const $587a2dd53fdae2e8$export$5ba89eed0cf60033 = ({ showChangeLimitModal: showChangeLimitModal } = {})=>`/jira/settings/products/servicedesk/feature-usage?feature=assets${showChangeLimitModal ? '&changeLimit=true' : ''}`;
    const $587a2dd53fdae2e8$export$415502b49e27270d = ({ showChangeLimitModal: showChangeLimitModal } = {})=>`/jira/settings/products/servicedesk/feature-usage?feature=virtual-agent${showChangeLimitModal ? '&changeLimit=true' : ''}`;
});
parcelRegister("9PhlU", function(module, exports) {
    $parcel$export(module.exports, "SCHEMA_PAGE_QUERY_PARAMS", ()=>$dee65df3d835c7b2$export$a73deb42718b5c4);
    $parcel$export(module.exports, "TAB_QUERY_PARAM_REFERENCE_TYPES", ()=>$dee65df3d835c7b2$export$f7855185bab37329);
    $parcel$export(module.exports, "TAB_QUERY_PARAM_STATUSES", ()=>$dee65df3d835c7b2$export$3739ebbc7c6e87e8);
    $parcel$export(module.exports, "TAB_QUERY_PARAM_ICONS", ()=>$dee65df3d835c7b2$export$2c22dc6c729ae0bd);
    $parcel$export(module.exports, "TAB_QUERY_PARAM_DATA_MANAGER", ()=>$dee65df3d835c7b2$export$f37b3a97acf56eaf);
    $parcel$export(module.exports, "TAB_QUERY_PARAM_GENERAL", ()=>$dee65df3d835c7b2$export$12ebb1d5017e1d6d);
    $parcel$export(module.exports, "TAB_QUERY_PARAM_ROLES", ()=>$dee65df3d835c7b2$export$a77734d9ea30fe44);
    $parcel$export(module.exports, "TAB_QUERY_PARAM_IMPORT", ()=>$dee65df3d835c7b2$export$be37b820df30c947);
    $parcel$export(module.exports, "TAB_QUERY_PARAM_INHERITANCE", ()=>$dee65df3d835c7b2$export$670faa8ae93e0903);
    $parcel$export(module.exports, "useUpdateQueryParams", ()=>$dee65df3d835c7b2$export$e66acc5f4583ce1c);
    $parcel$export(module.exports, "useObjectTypeIdQueryParam", ()=>$dee65df3d835c7b2$export$f5a33d51dd6de08);
    $parcel$export(module.exports, "useObjectIdQueryParam", ()=>$dee65df3d835c7b2$export$a6e3187c87554a1e);
    $parcel$export(module.exports, "useSchemaViewModeQueryParam", ()=>$dee65df3d835c7b2$export$6abda0ad26022eff);
    $parcel$export(module.exports, "useObjectViewModeQueryParam", ()=>$dee65df3d835c7b2$export$1927b50f7d074492);
    var $5uXOq = parcelRequire("5uXOq");
    var $ebyGx = parcelRequire("ebyGx");
    var $5Ny9E;
    var $ieDtd;
    var $doZTA;
    var $lVJAU;
    const $dee65df3d835c7b2$export$a73deb42718b5c4 = {
        schemaMode: 'mode',
        objectViewMode: 'view',
        objectTypeId: 'typeId',
        fallbackObjectTypeId: 'objecttypeid',
        objectId: 'objectId'
    };
    const $dee65df3d835c7b2$export$f7855185bab37329 = 'reference-types';
    const $dee65df3d835c7b2$export$3739ebbc7c6e87e8 = 'statuses';
    const $dee65df3d835c7b2$export$2c22dc6c729ae0bd = 'icons';
    const $dee65df3d835c7b2$export$f37b3a97acf56eaf = 'data-manager';
    const $dee65df3d835c7b2$export$12ebb1d5017e1d6d = 'general';
    const $dee65df3d835c7b2$export$a77734d9ea30fe44 = 'roles';
    const $dee65df3d835c7b2$export$be37b820df30c947 = 'import';
    const $dee65df3d835c7b2$export$670faa8ae93e0903 = 'inheritance';
    const $dee65df3d835c7b2$export$e66acc5f4583ce1c = ()=>{
        const { push: push } = (0, (0, parcelRequire("lVJAU")).useRouterActions)();
        const [{ location: location }] = (0, (0, parcelRequire("doZTA")).useRouter)();
        const updateQueryParams = (0, $5uXOq.useCallback)((queryParams = {})=>{
            const { pathname: pathname, search: search } = location;
            const updatedQueryParams = {};
            Object.keys(queryParams).forEach((key)=>{
                updatedQueryParams[key] = queryParams[key] ?? undefined;
            });
            return `${pathname}?${(0, ($parcel$interopDefault($ebyGx))).stringify({
                ...(0, ($parcel$interopDefault($ebyGx))).parse(search),
                ...updatedQueryParams
            })}`;
        }, [
            location
        ]);
        const pushRouterAction = (0, $5uXOq.useCallback)((path)=>{
            push(path);
        }, [
            push
        ]);
        return [
            {
                updateQueryParams: updateQueryParams,
                pushRouterAction: pushRouterAction
            }
        ];
    };
    const $dee65df3d835c7b2$export$f5a33d51dd6de08 = ()=>{
        const [objectTypeId, setObjectTypeId] = (0, (0, parcelRequire("ieDtd")).useQueryParam)($dee65df3d835c7b2$export$a73deb42718b5c4.objectTypeId);
        const [fallbackObjectTypeId, setFallbackObjectTypeId] = (0, (0, parcelRequire("ieDtd")).useQueryParam)($dee65df3d835c7b2$export$a73deb42718b5c4.fallbackObjectTypeId);
        (0, $5uXOq.useEffect)(()=>{
            if (fallbackObjectTypeId !== undefined) {
                setObjectTypeId((0, (0, parcelRequire("5Ny9E")).toCmdbObjectTypeId)(fallbackObjectTypeId), 'replace');
                setFallbackObjectTypeId(undefined, 'replace');
            }
        }, [
            fallbackObjectTypeId,
            setObjectTypeId,
            setFallbackObjectTypeId
        ]);
        const nullableMain = objectTypeId !== undefined ? (0, (0, parcelRequire("5Ny9E")).toCmdbObjectTypeId)(objectTypeId) : null;
        const nullableFallback = fallbackObjectTypeId !== undefined ? (0, (0, parcelRequire("5Ny9E")).toCmdbObjectTypeId)(fallbackObjectTypeId) : null;
        const setter = (newObjectTypeId, updateType)=>{
            setObjectTypeId(newObjectTypeId !== null ? newObjectTypeId : undefined, updateType);
        };
        return [
            nullableMain !== null ? nullableMain : nullableFallback,
            setter
        ];
    };
    const $dee65df3d835c7b2$export$a6e3187c87554a1e = ()=>{
        const [objectId, setObjectId] = (0, (0, parcelRequire("ieDtd")).useQueryParam)($dee65df3d835c7b2$export$a73deb42718b5c4.objectId);
        const nullableObjectId = objectId !== undefined ? (0, (0, parcelRequire("5Ny9E")).toCmdbObjectId)(objectId) : null;
        const setter = (newObjectId, updateType)=>{
            setObjectId(newObjectId !== null ? newObjectId : undefined, updateType);
        };
        return [
            nullableObjectId,
            setter
        ];
    };
    const $dee65df3d835c7b2$export$6abda0ad26022eff = ()=>{
        const [rawParamValue, setSchemaMode] = (0, (0, parcelRequire("ieDtd")).useQueryParam)($dee65df3d835c7b2$export$a73deb42718b5c4.schemaMode);
        const schemaMode = rawParamValue === 'attribute' ? 'attribute' : 'object';
        return [
            schemaMode,
            setSchemaMode
        ];
    };
    const $dee65df3d835c7b2$export$1927b50f7d074492 = ()=>{
        const [rawParamValue, setObjectViewMode] = (0, (0, parcelRequire("ieDtd")).useQueryParam)($dee65df3d835c7b2$export$a73deb42718b5c4.objectViewMode);
        const objectViewMode = rawParamValue === 'list' ? 'list' : 'details';
        const setter = (newValue, updateMode)=>{
            setObjectViewMode(newValue === 'list' ? 'list' : undefined, updateMode);
        };
        return [
            objectViewMode,
            setter
        ];
    };
});
parcelRegister("7rU7r", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$421905193c27f8ab$export$2e2bcd8739ae039);
    var $7VHMR;
    var $421905193c27f8ab$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        titleCmdbAssets: {
            "id": "atlassian-navigation.insight.title-cmdb-assets",
            "defaultMessage": "Assets"
        }
    });
});
parcelRegister("aq0Kn", function(module, exports) {
    $parcel$export(module.exports, "Integrations", ()=>$ac7ef7200c8e5178$export$99d02219d259eb94);
    var $5uXOq = parcelRequire("5uXOq");
    var $etdID;
    var $em9pP;
    var $4eMk5;
    var $1clga;
    const $ac7ef7200c8e5178$export$99d02219d259eb94 = ()=>{
        if (!false) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4eMk5")).KeyboardShortcuts), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("em9pP")).IssueViewModal), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1clga")).Projects), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("etdID")).Dashboards), null));
        return null;
    };
});
parcelRegister("etdID", function(module, exports) {
    $parcel$export(module.exports, "Dashboards", ()=>$f86b9cc82ae6c09b$export$dd47e695f88fad71);
    var $5uXOq = parcelRequire("5uXOq");
    var $65Ltd;
    var $jgE05;
    var $6jY6G;
    var $e9QkH;
    var $cZpsT;
    var $9VXkC;
    var $lVJAU;
    var $Mp6qH;
    var $gIycu;
    const $f86b9cc82ae6c09b$export$dd47e695f88fad71 = ()=>{
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const [isOpen, { off: modalClose }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("Mp6qH")).MODAL_ID).DASHBOARD_CREATE);
        const { path: path } = (0, (0, parcelRequire("6jY6G")).useRouteByName)((0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_DASHBOARD_EDIT)) ?? {};
        const { push: push } = (0, (0, parcelRequire("lVJAU")).useRouterActions)();
        const onSuccess = (0, $5uXOq.useCallback)((_, { id: id })=>{
            path && push((0, (0, parcelRequire("9VXkC")).generatePath)(path, {
                dashboardId: id
            }));
            modalClose();
        }, [
            modalClose,
            path,
            push
        ]);
        if (isAnonymous) return null;
        return isOpen ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gIycu")).Absolute), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jgE05")).default), {
            onSuccess: onSuccess,
            onCancel: modalClose
        })) : null;
    };
});
parcelRegister("jgE05", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$927aeb284ed7a228$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $lfTZh;
    var $kxZPA;
    const $927aeb284ed7a228$var$AsyncCreateDashboardModal = (0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>(parcelRequire("iDIjr")), {
        moduleId: "./src/packages/global-pages/directories/dashboards-directory-v2/create-dashboard-modal/src/index.tsx"
    });
    const $927aeb284ed7a228$var$AsyncCreateDashboardModalContainer = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "create-dashboard-modal",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($927aeb284ed7a228$var$AsyncCreateDashboardModal, props));
    var $927aeb284ed7a228$export$2e2bcd8739ae039 = $927aeb284ed7a228$var$AsyncCreateDashboardModalContainer;
});
parcelRegister("iDIjr", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("hPzOn"),
        $6fhBS("8bdSJ")
    ]).then(()=>parcelRequire('eTZZm'));
});
parcelRegister("gIycu", function(module, exports) {
    $parcel$export(module.exports, "Absolute", ()=>$09a1291fd6ca71a7$export$fa7a3a6ce2191e98);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $09a1291fd6ca71a7$export$fa7a3a6ce2191e98 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswstnw",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("em9pP", function(module, exports) {
    $parcel$export(module.exports, "IssueViewModal", ()=>$7393951fada84893$export$e6a284da53c503a6);
    var $5uXOq = parcelRequire("5uXOq");
    var $gjEtj;
    var $ecB7Y;
    var $lfTZh;
    var $g5X3G;
    var $kxZPA;
    const $7393951fada84893$export$b61e79ae300e872f = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("apmLb")), {
        moduleId: "./src/packages/issue/issue-view/src/views/issue-details/modal-issue-app.tsx"
    });
    const $7393951fada84893$export$e6a284da53c503a6 = ()=>{
        const [{ isModalOpen: isModalOpen, issueKey: issueKey, onClose: onClose, packageName: packageName, analyticsSource: analyticsSource }] = (0, (0, parcelRequire("ecB7Y")).useTriggerIssueViewModal)();
        return isModalOpen ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gjEtj")).ModalBoundary), {
            packageName: packageName,
            id: "issue-modal"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "modal-issue-app",
            fallback: null
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($7393951fada84893$export$b61e79ae300e872f, {
            analyticsSource: analyticsSource,
            issueKey: (0, (0, parcelRequire("g5X3G")).toIssueKey)(issueKey),
            onClose: onClose
        }))) : null;
    };
});
parcelRegister("gjEtj", function(module, exports) {
    $parcel$export(module.exports, "ModalBoundary", ()=>$c322f875acea1654$export$c3f500e812fb1bb3);
    var $5uXOq = parcelRequire("5uXOq");
    var $gAeCk;
    var $iqr2y;
    var $heNNl;
    var $lfTZh;
    const $c322f875acea1654$export$c3f500e812fb1bb3 = ({ children: children, id: id, metricKey: metricKey, packageName: packageName, onError: onError })=>{
        const ErrorState = (0, $5uXOq.useCallback)((args)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iqr2y")).ErrorFlagRenderer), {
                id: id,
                error: args.error
            }), [
            id
        ]);
        const fallback = typeof metricKey === 'string' && metricKey.length > 0 ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gAeCk")).default), {
            metricKey: metricKey
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("heNNl")).default), {
            id: id,
            packageName: packageName,
            render: ErrorState,
            onError: onError
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: id,
            fallback: fallback
        }, children));
    };
});
parcelRegister("ecB7Y", function(module, exports) {
    $parcel$export(module.exports, "useTriggerIssueViewModal", ()=>$164e67a198897250$export$fb8fdfbeb70f910c);
    var $8Rkzz = parcelRequire("8Rkzz");
    var $3sQ5x;
    var $iXqE5;
    const $164e67a198897250$export$d4c72bab9d6cc13a = {
        isModalOpen: false,
        onClose: (0, ($parcel$interopDefault($8Rkzz))),
        issueKey: '',
        packageName: '',
        analyticsSource: ''
    };
    const $164e67a198897250$export$e324594224ef24da = {
        openIssueViewModal: (nextState)=>({ setState: setState })=>{
                setState({
                    ...$164e67a198897250$export$d4c72bab9d6cc13a,
                    ...nextState,
                    isModalOpen: true
                });
            },
        closeIssueViewModal: ()=>({ setState: setState })=>{
                setState({
                    ...$164e67a198897250$export$d4c72bab9d6cc13a,
                    isModalOpen: false
                });
            }
    };
    const $164e67a198897250$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'issue-view.controllers.open-issue-view-modal',
        initialState: $164e67a198897250$export$d4c72bab9d6cc13a,
        actions: $164e67a198897250$export$e324594224ef24da
    });
    const $164e67a198897250$export$fb8fdfbeb70f910c = (0, (0, parcelRequire("3sQ5x")).createHook)($164e67a198897250$var$store);
});
parcelRegister("apmLb", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("hPzOn"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("8SBrF")
    ]).then(()=>parcelRequire('2555l'));
});
parcelRegister("4eMk5", function(module, exports) {
    $parcel$export(module.exports, "KeyboardShortcuts", ()=>$6dfb894a2dc118b1$export$5176144edf2bef2b);
    var $5uXOq = parcelRequire("5uXOq");
    var $dJl1T;
    var $7kxNZ;
    var $jp0tf;
    var $39xOx;
    var $co1wz;
    var $6BNJk;
    var $3tKCZ;
    var $hsmeR;
    var $lmyls;
    var $2Ui4E;
    var $hULKg;
    var $5rzqz;
    var $lfTZh;
    var $f5nIZ;
    var $2YP9W;
    var $Mp6qH;
    const $6dfb894a2dc118b1$export$5176144edf2bef2b = ()=>{
        const [, { on: on }] = (0, (0, parcelRequire("2Ui4E")).useTopMenus)((0, (0, parcelRequire("lmyls")).MENU_ID).SEARCH);
        const [, { off: closeAllMenus }] = (0, (0, parcelRequire("2Ui4E")).useTopMenus)();
        const { openRightSidebar: openRightSidebar, closeCurrentRightSidebar: closeCurrentRightSidebar } = (0, (0, parcelRequire("hsmeR")).useRightSidebarController)();
        const rightSidebarState = (0, (0, parcelRequire("hsmeR")).useActiveRightSidebarState)();
        const [{ isInitialLoad: isInitialLoad }, actions] = (0, (0, parcelRequire("7kxNZ")).useIsInitialLoadLegacyIssueCreate)();
        const [, { openIssueCreateModal: openIssueCreateModal }] = (0, (0, parcelRequire("5rzqz")).useTriggerIssueCreateModal)();
        const isInitialLoadRef = (0, $5uXOq.useRef)(false);
        isInitialLoadRef.current = isInitialLoad;
        const openSearch = (0, $5uXOq.useCallback)(()=>requestAnimationFrame(()=>{
                on();
            }), [
            on
        ]);
        let currentIssueCreateVisibility = null;
        if ((0, (0, parcelRequire("co1wz")).ff)('project.config.pce.modern.global-issue-create')) {
            const { data: data } = (0, (0, parcelRequire("2YP9W")).useResource)((0, (0, parcelRequire("f5nIZ")).gicOptInStatusResources));
            currentIssueCreateVisibility = data && data?.gicOptInStatus;
        }
        const openIssueCreate = (0, $5uXOq.useCallback)(()=>{
            if ((0, (0, parcelRequire("co1wz")).ff)('project.config.pce.modern.global-issue-create')) {
                if (currentIssueCreateVisibility !== null) {
                    if (currentIssueCreateVisibility.isEnabled) openIssueCreateModal({
                        triggerPointKey: (0, (0, parcelRequire("hULKg")).GENERIC_KEYBOARD_SHORTCUT)
                    });
                    else {
                        (0, (0, parcelRequire("jp0tf")).default)({
                            isInitialLoad: isInitialLoadRef.current,
                            triggerSource: (0, $de7681b8672bb382$export$7e22286cafe320a7)
                        });
                        if (isInitialLoadRef.current) actions.clearInitialLoaded();
                    }
                } else (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('global-issue-create', 'visibility data is still getting downloaded');
            } else {
                (0, (0, parcelRequire("jp0tf")).default)({
                    isInitialLoad: isInitialLoadRef.current,
                    triggerSource: (0, $de7681b8672bb382$export$7e22286cafe320a7)
                });
                if (isInitialLoadRef.current) actions.clearInitialLoaded();
            }
        }, [
            actions,
            currentIssueCreateVisibility,
            openIssueCreateModal
        ]);
        const toggleHelpPanel = (0, $5uXOq.useCallback)(()=>{
            closeAllMenus();
            if (!rightSidebarState?.isCollapsed && rightSidebarState?.panelId === (0, (0, parcelRequire("6BNJk")).HELP_PANEL_ID)) closeCurrentRightSidebar();
            else openRightSidebar((0, (0, parcelRequire("6BNJk")).HELP_PANEL_ID), (0, (0, parcelRequire("6BNJk")).HELP_PANEL_WIDTH));
        }, [
            closeAllMenus,
            closeCurrentRightSidebar,
            openRightSidebar,
            rightSidebarState?.isCollapsed,
            rightSidebarState?.panelId
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "integrations.keyboard-shortcuts",
            packageName: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME),
            fallback: "unmount"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "lazy-shortcuts",
            fallback: null
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3tKCZ")).KeyboardShortcuts), {
            showIssueCreate: openIssueCreate,
            showSearch: openSearch,
            toggleHelpPanel: toggleHelpPanel,
            shouldUseHelpShortcut: true
        })));
    };
});
parcelRegister("3tKCZ", function(module, exports) {
    $parcel$export(module.exports, "KeyboardShortcuts", ()=>$fcab74eed8b53dd6$export$5176144edf2bef2b);
    var $kxZPA;
    const $fcab74eed8b53dd6$export$5176144edf2bef2b = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("jgFim")), {
        ssr: false,
        moduleId: "./src/packages/platform/ui/keyboard-shortcuts/src/view/index.tsx"
    });
});
parcelRegister("jgFim", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("6OtWH")
    ]).then(()=>parcelRequire('d6vlx'));
});
parcelRegister("1clga", function(module, exports) {
    $parcel$export(module.exports, "Projects", ()=>$25503fad4ea8c4b7$export$ca6fbb51b35e375c);
    var $5uXOq = parcelRequire("5uXOq");
    var $d40Fj;
    var $bKCrm;
    var $e9QkH;
    var $Mp6qH;
    const $25503fad4ea8c4b7$export$90cfe4f15bb50f58 = ({ open: open, close: close, setOnCloseCallback: setOnCloseCallback })=>{
        const [{ canCreateClassicProject: canCreateClassicProject }] = (0, (0, parcelRequire("d40Fj")).useCreateProjectPermission)();
        (0, $5uXOq.useEffect)(()=>{
            open && open({
                source: 'navigationNext',
                isNextGen: !canCreateClassicProject
            });
            setOnCloseCallback && setOnCloseCallback(close);
        }, [
            open
        ]);
        return null;
    };
    const $25503fad4ea8c4b7$export$ca6fbb51b35e375c = ()=>{
        const [isProjectCreateDrawerOpen, { off: closeProjectCreateDrawer }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("Mp6qH")).MODAL_ID).PROJECT_CREATE);
        if (!isProjectCreateDrawerOpen) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bKCrm")).ProjectCreateDrawerConsumer), null, ({ methods: { open: open, setOnCloseCallback: setOnCloseCallback } })=>(0, ($parcel$interopDefault($5uXOq))).createElement($25503fad4ea8c4b7$export$90cfe4f15bb50f58, {
                open: open,
                close: closeProjectCreateDrawer,
                setOnCloseCallback: setOnCloseCallback
            }));
    };
});
parcelRegister("jyW1v", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8abfadba6a4f23da$export$2e2bcd8739ae039);
    var $7VHMR;
    var $8abfadba6a4f23da$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        topNav: {
            "id": "atlassian-navigation.top-nav",
            "defaultMessage": "Primary"
        },
        more: {
            "id": "atlassian-navigation.more",
            "defaultMessage": "More"
        }
    });
});
parcelRegister("inFGE", function(module, exports) {
    $parcel$export(module.exports, "Notifications", ()=>$611a60e3341e79e1$export$36ba2f559d49a8f6);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $1I7E8;
    var $dwE2v;
    var $39xOx;
    var $kaHBi;
    var $6s1PB;
    var $4R6GH;
    var $aWse0;
    var $lmyls;
    var $2Ui4E;
    var $6cNb0;
    var $lfTZh;
    var $koeKL;
    var $cZpsT;
    var $9OXo1;
    var $04PxM;
    var $kxZPA;
    var $Mp6qH;
    var $4uXxK;
    var $keCgG;
    var $iPj86;
    var $5vuhU;
    var $b7Ryd;
    const $611a60e3341e79e1$var$BadgeDI = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("1eLMu")).then(({ Badge: Badge })=>Badge), {
        ssr: false,
        moduleId: "./src/packages/navigation-apps/atlassian-navigation/src/ui/notifications/badge/index.tsx"
    });
    const $611a60e3341e79e1$var$MenuDI = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("cgIES")).then(({ Menu: Menu })=>Menu), {
        ssr: false,
        moduleId: "./src/packages/navigation-apps/atlassian-navigation/src/ui/notifications/menu/index.tsx"
    });
    const $611a60e3341e79e1$var$Notifications = ({ Badge: Badge = $611a60e3341e79e1$var$BadgeDI, ErrorBoundary: ErrorBoundary = (0, (0, parcelRequire("39xOx")).JSErrorBoundary), Icon: Icon = (0, (0, parcelRequire("keCgG")).Notification), Menu: Menu = $611a60e3341e79e1$var$MenuDI, NotificationContentFrame: NotificationContentFrame = $611a60e3341e79e1$var$NotificationContentFrameDI, Popup: Popup = (0, (0, parcelRequire("1I7E8")).default), useExperienceStart: useExperienceStart = (0, (0, parcelRequire("kaHBi")).useExperienceStart), useIntl: useIntl = (0, (0, parcelRequire("aWse0")).useIntlV2), useIsAnonymous: useIsAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous), useNavigationItemAnalytics: useNavigationItemAnalytics = (0, (0, parcelRequire("iPj86")).useNavigationItemAnalytics), useTopMenus: useTopMenus = (0, (0, parcelRequire("2Ui4E")).useTopMenus) })=>{
        const testIdPrefix = (0, (0, parcelRequire("6cNb0")).testIdGenerate)('secondary-actions', 'notifications');
        const { formatMessage: formatMessage } = useIntl();
        const isAnonymous = useIsAnonymous();
        const [isNotificationCleared, setIsNotificationCleared] = (0, $5uXOq.useState)(false);
        const [notificationCount, setNotificationCount] = (0, $5uXOq.useState)(0);
        const handleCountUpdate = (0, $5uXOq.useCallback)((newCount)=>{
            setNotificationCount(newCount);
        }, []);
        const [isMenuOpen, { toggle: toggleMenu, off: closeMenu, on: openMenu }] = useTopMenus((0, (0, parcelRequire("lmyls")).MENU_ID).NOTIFICATIONS);
        const triggerAnalytics = useNavigationItemAnalytics({
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).NOTIFICATIONS
        });
        const onStart = useExperienceStart({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU),
            experienceId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).NOTIFICATIONS,
            analyticsSource: 'atlassian-navigation'
        });
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const { atlassianAccountId: atlassianAccountId } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const { triggerNotificationBellPartyNudgeExperiment: triggerNotificationBellPartyNudgeExperiment } = (0, (0, parcelRequire("04PxM")).useThirdPartyNudgeExperiment)(cloudId, atlassianAccountId);
        const onClick = (0, $5uXOq.useCallback)(()=>{
            if (isMenuOpen) performance.clearMarks('notification.list.render.start');
            else performance.mark('notification.list.render.start');
            onStart();
            toggleMenu();
            triggerAnalytics();
            setIsNotificationCleared(true);
            if (!(0, (0, parcelRequire("6s1PB")).expValEquals)('third_party_app_notifications_nudge', 'cohort', 'variation')) triggerNotificationBellPartyNudgeExperiment();
        }, [
            isMenuOpen,
            onStart,
            toggleMenu,
            triggerAnalytics,
            triggerNotificationBellPartyNudgeExperiment
        ]);
        const handleNotificationIconHover = ()=>{
            $611a60e3341e79e1$var$MenuDI.preload();
        };
        (0, (0, parcelRequire("5vuhU")).useRegisterNotificationsInCommandPalette)(onClick);
        const content = (0, $5uXOq.useCallback)(({ update: update })=>(0, ($parcel$interopDefault($5uXOq))).createElement(NotificationContentFrame, null, (0, ($parcel$interopDefault($5uXOq))).createElement(ErrorBoundary, {
                id: "notifications.list.async",
                packageName: "AtlassianNavigation",
                teamName: "navigation"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
                name: "notification-menu-native",
                fallback: null
            }, (0, ($parcel$interopDefault($5uXOq))).createElement(Menu, {
                scheduleUpdate: update,
                testIdPrefix: testIdPrefix,
                showThirdPartyNotificationNudge: (0, (0, parcelRequire("6s1PB")).expValEquals)('third_party_app_notifications_nudge', 'cohort', 'variation')
            })))), [
            testIdPrefix
        ]);
        const badge = (0, $5uXOq.useCallback)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement(ErrorBoundary, {
                id: "notifications.badge.async",
                packageName: "AtlassianNavigation",
                teamName: "navigation",
                fallback: "unmount"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
                name: "badge",
                fallback: null
            }, (0, ($parcel$interopDefault($5uXOq))).createElement(Badge, {
                isNotificationCleared: isNotificationCleared,
                setIsNotificationCleared: setIsNotificationCleared,
                isNotificationDrawerOpen: isMenuOpen,
                ...(0, (0, parcelRequire("4R6GH")).fg)('header_content_not_accessible_for_screen_reader') && {
                    onNotificationCountChange: handleCountUpdate
                }
            }))), [
            isNotificationCleared,
            isMenuOpen
        ]);
        const badgeEmpty = (0, $5uXOq.useCallback)(()=>null, []);
        const notificationsLabel = notificationCount < (0, $3c41b45407aae96c$export$14c62ba6f49b806f) ? formatMessage((0, (0, parcelRequire("b7Ryd")).default).label, {
            count: notificationCount
        }) : formatMessage((0, (0, parcelRequire("b7Ryd")).default).overNineNotifications);
        const trigger = (0, $5uXOq.useCallback)((triggerProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement("div", {
                "data-testid": (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'menu-trigger')
            }, (0, ($parcel$interopDefault($5uXOq))).createElement(Icon, {
                ...triggerProps,
                badge: badge,
                isSelected: isMenuOpen,
                onClick: onClick,
                onMouseEnter: handleNotificationIconHover,
                tooltip: formatMessage((0, (0, parcelRequire("b7Ryd")).default).tooltip),
                label: (0, (0, parcelRequire("4R6GH")).fg)('header_content_not_accessible_for_screen_reader') ? notificationsLabel : formatMessage((0, (0, parcelRequire("b7Ryd")).default).oldLabel),
                onViewRequests: openMenu
            })), [
            badge,
            isMenuOpen,
            onClick,
            formatMessage,
            testIdPrefix,
            notificationCount
        ]);
        if (isAnonymous) return null;
        if (false) return (0, ($parcel$interopDefault($5uXOq))).createElement(Icon, {
            badge: badgeEmpty,
            tooltip: formatMessage((0, (0, parcelRequire("b7Ryd")).default).tooltip)
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement(Popup, {
            content: content,
            isOpen: isMenuOpen,
            onClose: closeMenu,
            placement: "bottom-end",
            trigger: trigger
        });
    };
    const $611a60e3341e79e1$export$36ba2f559d49a8f6 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).NOTIFICATIONS
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dwE2v")).UsingNotificationsToImproveAdminApprovalProvider), null, (0, ($parcel$interopDefault($5uXOq))).createElement($611a60e3341e79e1$var$Notifications, props)));
    const $611a60e3341e79e1$var$NotificationContentFrameDI = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1bsb1w2c _4t3i54ef _1e0c1txw",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("dwE2v", function(module, exports) {
    $parcel$export(module.exports, "CoordinationStopProvider", ()=>$108b0001dbc2c488$export$83d026e3dd5706d);
    $parcel$export(module.exports, "UsingNotificationsToImproveAdminApprovalProvider", ()=>$108b0001dbc2c488$export$a5554f3421878be1);
    $parcel$export(module.exports, "useUsingNotificationsToImproveAdminApprovalContext", ()=>$108b0001dbc2c488$export$3e450d633150d6ca);
    var $5uXOq = parcelRequire("5uXOq");
    var $bSSG7;
    var $9OXo1;
    const $108b0001dbc2c488$var$TouchpointContext = (0, $5uXOq.createContext)({
        hasSpotlightBeenShown: true,
        markSpotlightAsShown: ()=>{}
    });
    const $108b0001dbc2c488$var$localStorage = (0, (0, parcelRequire("bSSG7")).createLocalStorageProvider)((0, $95839ee75937f648$export$67a034c701fa7ff6));
    const $108b0001dbc2c488$export$83d026e3dd5706d = ({ stop: stop, children: children })=>children(stop);
    const $108b0001dbc2c488$export$a5554f3421878be1 = ({ children: children })=>{
        const { cloudId: cloudId, atlassianAccountId: atlassianAccountId } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const [hasSpotlightBeenShown, setHasSpotlightBeenShown] = (0, $5uXOq.useState)(true);
        const spotlightLocalStorageKey = `${(0, $95839ee75937f648$export$7a86d77850136bf4)}_${cloudId}_${atlassianAccountId}`;
        (0, $5uXOq.useEffect)(()=>{
            setHasSpotlightBeenShown($108b0001dbc2c488$var$localStorage.get(spotlightLocalStorageKey) || false);
        }, [
            spotlightLocalStorageKey
        ]);
        const markSpotlightAsShown = (0, $5uXOq.useCallback)(()=>{
            $108b0001dbc2c488$var$localStorage.set(spotlightLocalStorageKey, true);
            setHasSpotlightBeenShown(true);
        }, [
            setHasSpotlightBeenShown,
            spotlightLocalStorageKey
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($108b0001dbc2c488$var$TouchpointContext.Provider, {
            value: {
                hasSpotlightBeenShown: hasSpotlightBeenShown,
                markSpotlightAsShown: markSpotlightAsShown
            }
        }, children);
    };
    const $108b0001dbc2c488$export$3e450d633150d6ca = ()=>(0, $5uXOq.useContext)($108b0001dbc2c488$var$TouchpointContext);
});
const $95839ee75937f648$export$67a034c701fa7ff6 = 'uniapa';
const $95839ee75937f648$export$7a86d77850136bf4 = 'has-spotlight-been-shown';
const $95839ee75937f648$export$d2ea4f931a71a509 = 'jira-admin-product-request-spotlight';
parcelRegister("04PxM", function(module, exports) {
    $parcel$export(module.exports, "useThirdPartyNudgeExperiment", ()=>$fb5873227cc1f4ae$export$7eb4430a3f41a22a);
    var $5uXOq = parcelRequire("5uXOq");
    var $6s1PB;
    var $2FkXZ;
    var $8IM3A;
    var $fULuv;
    var $1XkD3;
    var $8bzhD;
    var $kGiWK;
    const $fb5873227cc1f4ae$export$7eb4430a3f41a22a = (cloudId, atlassianAccountId)=>{
        const { showThirdPartyNudgeFlag: showThirdPartyNudgeFlag } = (0, (0, parcelRequire("kGiWK")).useThirdPartyNudgeFlag)({
            cloudId: cloudId
        });
        const { fetchAllTraits: fetchAllTraits } = (0, (0, parcelRequire("fULuv")).usePersonalization)();
        const triggerIssueCommentThirdPartyNudgeExperiment = (0, $5uXOq.useCallback)(async ()=>{
            const traits = await fetchAllTraits(cloudId, atlassianAccountId);
            if (!traits) return;
            const isEligibleForMsteams = (0, (0, parcelRequire("8bzhD")).isTenantActiveOnMsteams)(traits) && !(0, (0, parcelRequire("8bzhD")).isUserActiveOnMsteams)(traits);
            const isEligibleForSlack = (0, (0, parcelRequire("8bzhD")).isTenantActiveOnSlack)(traits) && !(0, (0, parcelRequire("8bzhD")).isUserActiveOnSlack)(traits);
            const hasSeenOtherThirdPartyNudges = (0, (0, parcelRequire("8bzhD")).hasSeenIssuesViewNudge)(traits) || (0, (0, parcelRequire("8bzhD")).hasSeenNotificationBellNudge)(traits) || (0, (0, parcelRequire("8bzhD")).hasSeenIssueWatchNudge)(traits) || (0, (0, parcelRequire("8bzhD")).hasSeenNotificationsNudge)(traits);
            const hasAlreadySeen = traits[0, (0, parcelRequire("2FkXZ")).DISMISSED_TRAIT_NAME] || (0, (0, parcelRequire("8IM3A")).getHasSeenNudge)();
            if (hasAlreadySeen || !isEligibleForMsteams && !isEligibleForSlack || hasSeenOtherThirdPartyNudges) return;
            const [expConfig, fireFeatureExposure] = (0, (0, parcelRequire("6s1PB")).UNSAFE_noExposureExp)('third_party_app_nudge_on_after_jira_comment');
            fireFeatureExposure();
            if (expConfig.get('cohort', 'not-enrolled') !== 'variation') return;
            const experimentType = 'JSW_COMMENT';
            if (isEligibleForSlack && isEligibleForMsteams) showThirdPartyNudgeFlag({
                variant: 'both',
                experimentType: experimentType
            });
            else if (isEligibleForSlack) showThirdPartyNudgeFlag({
                variant: 'slack',
                experimentType: experimentType
            });
            else showThirdPartyNudgeFlag({
                variant: 'msteams',
                experimentType: experimentType
            });
        }, [
            fetchAllTraits,
            cloudId,
            atlassianAccountId,
            showThirdPartyNudgeFlag
        ]);
        const triggerNotificationBellPartyNudgeExperiment = (0, $5uXOq.useCallback)(async ()=>{
            const traits = await fetchAllTraits(cloudId, atlassianAccountId, (0, (0, parcelRequire("2FkXZ")).ExperimentType).NOTIFICATION_BELL);
            if (!traits) return;
            const isSlackOrTeamsActiveUser = (0, (0, parcelRequire("8bzhD")).isUserActiveOnMsteams)(traits) || (0, (0, parcelRequire("8bzhD")).isUserActiveOnSlack)(traits);
            const isNewJiraActiveUser = (0, (0, parcelRequire("8bzhD")).isNewJiraUser)(traits);
            if (isSlackOrTeamsActiveUser || isNewJiraActiveUser) return;
            const hasSeenOtherThirdPartyNudges = (0, (0, parcelRequire("8bzhD")).hasSeenIssuesViewNudge)(traits) || (0, (0, parcelRequire("8bzhD")).hasSeenIssueCommentNudge)(traits) || (0, (0, parcelRequire("8bzhD")).hasSeenIssueWatchNudge)(traits);
            const hasAlreadySeen = traits[0, (0, parcelRequire("2FkXZ")).NOTIFICATION_BELL_TRAIT_NAME] || (0, (0, parcelRequire("1XkD3")).getNotificationNudgeState)();
            if (hasAlreadySeen || hasSeenOtherThirdPartyNudges || !(0, (0, parcelRequire("6s1PB")).expValEquals)('third_party_app_notification_bell_nudge', 'cohort', 'variation')) return;
            const isEligibleForMsteams = (0, (0, parcelRequire("8bzhD")).isTenantActiveOnMsteams)(traits);
            const isEligibleForSlack = (0, (0, parcelRequire("8bzhD")).isTenantActiveOnSlack)(traits);
            const experimentType = 'NOTIFICATION_BELL';
            if (isEligibleForMsteams) showThirdPartyNudgeFlag({
                variant: 'msteams',
                experimentType: experimentType
            });
            else if (isEligibleForSlack) showThirdPartyNudgeFlag({
                variant: 'slack',
                experimentType: experimentType
            });
            else showThirdPartyNudgeFlag({
                variant: 'both',
                experimentType: experimentType
            });
        }, [
            fetchAllTraits,
            cloudId,
            atlassianAccountId,
            showThirdPartyNudgeFlag
        ]);
        const shouldThirdPartySpotlightAppear = (0, $5uXOq.useCallback)(async ()=>{
            const traits = await fetchAllTraits(cloudId, atlassianAccountId, (0, (0, parcelRequire("2FkXZ")).ExperimentType).ISSUES_VIEW);
            if (!traits) return false;
            const isTenantActive = (0, (0, parcelRequire("8bzhD")).isTenantActiveOnMsteams)(traits);
            const hasSeenOtherThirdPartyNudges = (0, (0, parcelRequire("8bzhD")).hasSeenIssueCommentNudge)(traits) || (0, (0, parcelRequire("8bzhD")).hasSeenNotificationBellNudge)(traits) || (0, (0, parcelRequire("8bzhD")).hasSeenIssueWatchNudge)(traits) || (0, (0, parcelRequire("8bzhD")).hasSeenNotificationsNudge)(traits);
            const hasAlreadySeen = traits[0, (0, parcelRequire("2FkXZ")).MSTEAMS_ISSUES_VIEW_TRAIT_NAME] || (0, (0, parcelRequire("1XkD3")).getIssuesViewNudgeState)();
            const isEligibleUser = isTenantActive && !hasSeenOtherThirdPartyNudges && !hasAlreadySeen;
            return isEligibleUser ? (0, (0, parcelRequire("6s1PB")).expValEquals)('third_party_app_nudge_on_issues_view', 'cohort', 'variation') : false;
        }, [
            fetchAllTraits,
            cloudId,
            atlassianAccountId
        ]);
        return {
            triggerIssueCommentThirdPartyNudgeExperiment: triggerIssueCommentThirdPartyNudgeExperiment,
            triggerNotificationBellPartyNudgeExperiment: triggerNotificationBellPartyNudgeExperiment,
            shouldThirdPartySpotlightAppear: shouldThirdPartySpotlightAppear
        };
    };
});
parcelRegister("2FkXZ", function(module, exports) {
    $parcel$export(module.exports, "TEAMS_APP_ID", ()=>$4c37e046fbba6248$export$b476a840a5a09155);
    $parcel$export(module.exports, "DAYS_BEFORE_INACTIVE_TENANT", ()=>$4c37e046fbba6248$export$6036aa9230bfb3f5);
    $parcel$export(module.exports, "DAYS_BEFORE_INACTIVE_USER", ()=>$4c37e046fbba6248$export$f40e5c9c7b60d068);
    $parcel$export(module.exports, "DAYS_SINCE_LAST_NUDGE", ()=>$4c37e046fbba6248$export$dcb82abacceefba4);
    $parcel$export(module.exports, "DAYS_SINCE_FIRST_LOGIN", ()=>$4c37e046fbba6248$export$59c38436864bd283);
    $parcel$export(module.exports, "DAYS_SINCE_SNOOZE", ()=>$4c37e046fbba6248$export$e1ac23c8732476f9);
    $parcel$export(module.exports, "ExperimentType", ()=>$4c37e046fbba6248$export$8c84b4abfb7bd268);
    $parcel$export(module.exports, "TeamsDeepLinkSource", ()=>$4c37e046fbba6248$export$484de43fbd2571f8);
    $parcel$export(module.exports, "PERSONALIZATION_URL", ()=>$4c37e046fbba6248$export$28fb02d117a2ff8e);
    $parcel$export(module.exports, "MSTEAMS_TENANT_TRAIT_NAME", ()=>$4c37e046fbba6248$export$397d375b795e7497);
    $parcel$export(module.exports, "SLACK_TENANT_TRAIT_NAME", ()=>$4c37e046fbba6248$export$50e919bd161b2fec);
    $parcel$export(module.exports, "MSTEAMS_USER_TRAIT_NAME", ()=>$4c37e046fbba6248$export$37885255b1d60364);
    $parcel$export(module.exports, "SLACK_USER_TRAIT_NAME", ()=>$4c37e046fbba6248$export$c7104391aaa79daf);
    $parcel$export(module.exports, "DISMISSED_TRAIT_NAME", ()=>$4c37e046fbba6248$export$e74cbf9e9a309f73);
    $parcel$export(module.exports, "MSTEAMS_ISSUES_VIEW_TRAIT_NAME", ()=>$4c37e046fbba6248$export$b71e0b7cb09921c2);
    $parcel$export(module.exports, "NOTIFICATION_BELL_TRAIT_NAME", ()=>$4c37e046fbba6248$export$502bf89fef175ff2);
    $parcel$export(module.exports, "JIRA_FIRST_ACTIVE_TRAIT", ()=>$4c37e046fbba6248$export$1fd159178045a2ee);
    $parcel$export(module.exports, "ISSUE_WATCH_NUDGE_TRAIT_NAME", ()=>$4c37e046fbba6248$export$df79c140709c5b63);
    $parcel$export(module.exports, "DISMISS_NOTIFICATION_NUDGE_TRAIT_NAME", ()=>$4c37e046fbba6248$export$754c4ccd15771179);
    $parcel$export(module.exports, "NOTIFICATION_NUDGE_SEEN_TRAIT_NAME", ()=>$4c37e046fbba6248$export$8a776f794abbea4d);
    $parcel$export(module.exports, "SNOOZE_NOTIFICATION_NUDGE_TRAIT_NAME", ()=>$4c37e046fbba6248$export$bc9288faa3badc99);
    $parcel$export(module.exports, "SNOOZE_ISSUE_WATCH_NUDGE_TRAIT_NAME", ()=>$4c37e046fbba6248$export$c496539d04bc9293);
    $parcel$export(module.exports, "DISMISS_ISSUE_WATCH_NUDGE_TRAIT_NAME", ()=>$4c37e046fbba6248$export$d502ecd2ba366a55);
    $parcel$export(module.exports, "DATA_PRIVACY_URL", ()=>$4c37e046fbba6248$export$74c724bfd2fb2795);
    const $4c37e046fbba6248$export$b476a840a5a09155 = 'aa183fd9-7104-46c4-af9f-9ee9b81d717e';
    const $4c37e046fbba6248$export$6036aa9230bfb3f5 = 180;
    const $4c37e046fbba6248$export$f40e5c9c7b60d068 = 150;
    const $4c37e046fbba6248$export$dcb82abacceefba4 = 90;
    const $4c37e046fbba6248$export$59c38436864bd283 = 7;
    const $4c37e046fbba6248$export$e1ac23c8732476f9 = 7;
    const $4c37e046fbba6248$export$8c84b4abfb7bd268 = {
        JSW_COMMENT: 'thirdPartyNudgeAfterComment',
        ISSUES_VIEW: 'thirdPartyNudgeIssueView',
        NOTIFICATION_BELL: 'thirdPartyNotificationBellNudge',
        ISSUES_WATCH: 'thirdPartyNudgeIssueWatch',
        ISSUES_WATCH_ACTIONABLE: 'thirdPartyActionableNudgeIssueWatch',
        NOTIFICATIONS_SECTION: 'thirdPartyNotificationsSectionNudge'
    };
    const $4c37e046fbba6248$export$484de43fbd2571f8 = {
        JSW_COMMENT: 'third-party-nudge-jsw-comment',
        ISSUE_WATCH: 'third-party-nudge-issue-watch',
        ISSUES_VIEW: 'third-party-nudge-issue-view',
        NOTIFICATION_BELL: 'third-party-nudge-notification-bell'
    };
    const $4c37e046fbba6248$export$28fb02d117a2ff8e = '/gateway/api/tap-delivery/api/v3/personalization';
    const $4c37e046fbba6248$export$397d375b795e7497 = 'jira_msteams_v2_track_days_since_tenant_last_active';
    const $4c37e046fbba6248$export$50e919bd161b2fec = 'external_product_integrations_track_jira_slack_days_since_tenant_last_active';
    const $4c37e046fbba6248$export$37885255b1d60364 = 'jira_msteams_v2_track_days_since_user_last_active';
    const $4c37e046fbba6248$export$c7104391aaa79daf = 'external_product_integrations_track_jira_slack_days_since_user_last_active';
    const $4c37e046fbba6248$export$e74cbf9e9a309f73 = 'jira_screen_viewed_thirdpartynudgescreen_first_time';
    const $4c37e046fbba6248$export$b71e0b7cb09921c2 = 'jira_screen_viewed_issuesnavigatorthirdpartynudgescreen_last_time';
    const $4c37e046fbba6248$export$502bf89fef175ff2 = 'jira_screen_viewed_notificationbellthirdpartynudgescreen_last_time';
    const $4c37e046fbba6248$export$1fd159178045a2ee = 'jira_first_active';
    const $4c37e046fbba6248$export$df79c140709c5b63 = 'jira_screen_viewed_issuewatchthirdpartynudgescreen_last_time';
    const $4c37e046fbba6248$export$754c4ccd15771179 = 'jira_ui_button_clicked_notificationsthirdpartynudgedismiss_first_time';
    const $4c37e046fbba6248$export$8a776f794abbea4d = 'jira_screen_viewed_notificationsectionthirdpartynudgescreen_last_time';
    const $4c37e046fbba6248$export$bc9288faa3badc99 = 'jira_ui_button_clicked_notificationsthirdpartynudgesnooze_last_time';
    const $4c37e046fbba6248$export$c496539d04bc9293 = 'jira_ui_button_clicked_thirdpartysnooze_last_time';
    const $4c37e046fbba6248$export$d502ecd2ba366a55 = 'jira_ui_button_clicked_thirdpartydismiss_last_time';
    const $4c37e046fbba6248$export$74c724bfd2fb2795 = 'https://www.atlassian.com/legal/privacy-policy';
});
parcelRegister("8IM3A", function(module, exports) {
    $parcel$export(module.exports, "setHasSeenNudge", ()=>$9eef40d58ffaaa15$export$797f6da9750e4260);
    $parcel$export(module.exports, "getHasSeenNudge", ()=>$9eef40d58ffaaa15$export$c034158c5a3998dd);
    var $bSSG7;
    const $9eef40d58ffaaa15$var$store = (0, (0, parcelRequire("bSSG7")).createLocalStorageProvider)('third-party-nudge');
    const $9eef40d58ffaaa15$var$HAS_SEEN_NUDGE_KEY = 'has-seen-nudge';
    const $9eef40d58ffaaa15$export$797f6da9750e4260 = ()=>{
        $9eef40d58ffaaa15$var$store.set($9eef40d58ffaaa15$var$HAS_SEEN_NUDGE_KEY, true);
    };
    const $9eef40d58ffaaa15$export$c034158c5a3998dd = ()=>$9eef40d58ffaaa15$var$store.get($9eef40d58ffaaa15$var$HAS_SEEN_NUDGE_KEY) || false;
});
parcelRegister("fULuv", function(module, exports) {
    $parcel$export(module.exports, "usePersonalization", ()=>$dac42877afa4d4b8$export$9543f8d9a80ddfb0);
    var $5uXOq = parcelRequire("5uXOq");
    var $8zOmE;
    var $2NMNM;
    var $2FkXZ;
    const $dac42877afa4d4b8$var$getTraitValue = (traitName, traits)=>{
        if (!traits || !traits.attributes || traits.attributes.length === 0) return;
        const matches = traits.attributes.filter((attribute)=>attribute.name === traitName);
        if (matches.length === 0) return;
        return matches[0];
    };
    const $dac42877afa4d4b8$export$9543f8d9a80ddfb0 = ()=>{
        const [isInitialized, setIsInitialized] = (0, $5uXOq.useState)(false);
        const [isLoading, setIsLoading] = (0, $5uXOq.useState)(false);
        const [traits, setTraits] = (0, $5uXOq.useState)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const fireFetchEvent = (0, $5uXOq.useCallback)((action, scope, experimentType, detail)=>{
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({
                type: 'sendOperationalEvent'
            }), `fetchTraits ${action}`, {
                experiment: experimentType,
                scope: scope,
                detail: detail
            });
        }, [
            createAnalyticsEvent
        ]);
        const fetchSiteUserTraits = (0, $5uXOq.useCallback)(async (cloudId, atlassianAccountId, experimentType = (0, (0, parcelRequire("2FkXZ")).ExperimentType).JSW_COMMENT)=>{
            try {
                const result = await fetch(`${(0, (0, parcelRequire("2FkXZ")).PERSONALIZATION_URL)}/site/${cloudId}/user/${atlassianAccountId}`);
                if (result.status !== 200) {
                    fireFetchEvent('failed', 'SITE_USER', result.statusText);
                    return;
                }
                const json = await result.json();
                const dismissedTrait = $dac42877afa4d4b8$var$getTraitValue((0, (0, parcelRequire("2FkXZ")).DISMISSED_TRAIT_NAME), json);
                const msteamsIssuesViewTrait = $dac42877afa4d4b8$var$getTraitValue((0, (0, parcelRequire("2FkXZ")).MSTEAMS_ISSUES_VIEW_TRAIT_NAME), json);
                const notificationBellTrait = $dac42877afa4d4b8$var$getTraitValue((0, (0, parcelRequire("2FkXZ")).NOTIFICATION_BELL_TRAIT_NAME), json);
                const jiraFirstActiveTrait = $dac42877afa4d4b8$var$getTraitValue((0, (0, parcelRequire("2FkXZ")).JIRA_FIRST_ACTIVE_TRAIT), json);
                const issueWatchNudgeTrait = $dac42877afa4d4b8$var$getTraitValue((0, (0, parcelRequire("2FkXZ")).ISSUE_WATCH_NUDGE_TRAIT_NAME), json);
                const dismissedNotificationNudgeTrait = $dac42877afa4d4b8$var$getTraitValue((0, (0, parcelRequire("2FkXZ")).DISMISS_NOTIFICATION_NUDGE_TRAIT_NAME), json);
                const notificationsNudgeSeenTrait = $dac42877afa4d4b8$var$getTraitValue((0, (0, parcelRequire("2FkXZ")).NOTIFICATION_NUDGE_SEEN_TRAIT_NAME), json);
                const snoozeNotificationNudgeTrait = $dac42877afa4d4b8$var$getTraitValue((0, (0, parcelRequire("2FkXZ")).SNOOZE_NOTIFICATION_NUDGE_TRAIT_NAME), json);
                const dismissIssueWatchNudgeTrait = $dac42877afa4d4b8$var$getTraitValue((0, (0, parcelRequire("2FkXZ")).DISMISS_ISSUE_WATCH_NUDGE_TRAIT_NAME), json);
                const snoozeIssueWatchNudgeTrait = $dac42877afa4d4b8$var$getTraitValue((0, (0, parcelRequire("2FkXZ")).SNOOZE_ISSUE_WATCH_NUDGE_TRAIT_NAME), json);
                fireFetchEvent('succeeded', 'SITE_USER', experimentType);
                return {
                    ...dismissedTrait ? {
                        [(0, (0, parcelRequire("2FkXZ")).DISMISSED_TRAIT_NAME)]: dismissedTrait.value
                    } : {},
                    ...msteamsIssuesViewTrait ? {
                        [(0, (0, parcelRequire("2FkXZ")).MSTEAMS_ISSUES_VIEW_TRAIT_NAME)]: msteamsIssuesViewTrait.value
                    } : {},
                    ...notificationBellTrait ? {
                        [(0, (0, parcelRequire("2FkXZ")).NOTIFICATION_BELL_TRAIT_NAME)]: notificationBellTrait.value
                    } : {},
                    ...jiraFirstActiveTrait ? {
                        [(0, (0, parcelRequire("2FkXZ")).JIRA_FIRST_ACTIVE_TRAIT)]: jiraFirstActiveTrait.value
                    } : {},
                    ...issueWatchNudgeTrait ? {
                        [(0, (0, parcelRequire("2FkXZ")).ISSUE_WATCH_NUDGE_TRAIT_NAME)]: issueWatchNudgeTrait.value
                    } : {},
                    ...dismissedNotificationNudgeTrait ? {
                        [(0, (0, parcelRequire("2FkXZ")).DISMISS_NOTIFICATION_NUDGE_TRAIT_NAME)]: dismissedNotificationNudgeTrait.value
                    } : {},
                    ...notificationsNudgeSeenTrait ? {
                        [(0, (0, parcelRequire("2FkXZ")).NOTIFICATION_NUDGE_SEEN_TRAIT_NAME)]: notificationsNudgeSeenTrait.value
                    } : {},
                    ...snoozeNotificationNudgeTrait ? {
                        [(0, (0, parcelRequire("2FkXZ")).SNOOZE_NOTIFICATION_NUDGE_TRAIT_NAME)]: snoozeNotificationNudgeTrait.value
                    } : {},
                    ...dismissIssueWatchNudgeTrait ? {
                        [(0, (0, parcelRequire("2FkXZ")).DISMISS_ISSUE_WATCH_NUDGE_TRAIT_NAME)]: dismissIssueWatchNudgeTrait.value
                    } : {},
                    ...snoozeIssueWatchNudgeTrait ? {
                        [(0, (0, parcelRequire("2FkXZ")).SNOOZE_ISSUE_WATCH_NUDGE_TRAIT_NAME)]: snoozeIssueWatchNudgeTrait.value
                    } : {}
                };
            } catch (error) {
                fireFetchEvent('failed', 'SITE_USER', error.message);
            }
        }, [
            fireFetchEvent
        ]);
        const fetchSiteTraits = (0, $5uXOq.useCallback)(async (cloudId, experimentType = (0, (0, parcelRequire("2FkXZ")).ExperimentType).JSW_COMMENT)=>{
            try {
                const result = await fetch(`${(0, (0, parcelRequire("2FkXZ")).PERSONALIZATION_URL)}/site/${cloudId}`);
                if (result.status !== 200) {
                    fireFetchEvent('failed', 'SITE', result.statusText);
                    return;
                }
                const json = await result.json();
                const msteamsTrait = $dac42877afa4d4b8$var$getTraitValue((0, (0, parcelRequire("2FkXZ")).MSTEAMS_TENANT_TRAIT_NAME), json);
                const slackTrait = $dac42877afa4d4b8$var$getTraitValue((0, (0, parcelRequire("2FkXZ")).SLACK_TENANT_TRAIT_NAME), json);
                fireFetchEvent('succeeded', 'SITE', experimentType);
                return {
                    ...msteamsTrait ? {
                        [(0, (0, parcelRequire("2FkXZ")).MSTEAMS_TENANT_TRAIT_NAME)]: msteamsTrait.value
                    } : {},
                    ...slackTrait ? {
                        [(0, (0, parcelRequire("2FkXZ")).SLACK_TENANT_TRAIT_NAME)]: slackTrait.value
                    } : {}
                };
            } catch (error) {
                if (error instanceof Error) fireFetchEvent('failed', 'SITE', error.message);
            }
        }, [
            fireFetchEvent
        ]);
        const fetchUserTraits = (0, $5uXOq.useCallback)(async (atlassianAccountId, experimentType = (0, (0, parcelRequire("2FkXZ")).ExperimentType).JSW_COMMENT)=>{
            try {
                const result = await fetch(`${(0, (0, parcelRequire("2FkXZ")).PERSONALIZATION_URL)}/user/${atlassianAccountId}`);
                if (result.status !== 200) {
                    fireFetchEvent('failed', 'USER', result.statusText);
                    return;
                }
                const json = await result.json();
                const msteamsTrait = $dac42877afa4d4b8$var$getTraitValue((0, (0, parcelRequire("2FkXZ")).MSTEAMS_USER_TRAIT_NAME), json);
                const slackTrait = $dac42877afa4d4b8$var$getTraitValue((0, (0, parcelRequire("2FkXZ")).SLACK_USER_TRAIT_NAME), json);
                fireFetchEvent('succeeded', 'USER', experimentType);
                return {
                    ...msteamsTrait ? {
                        [(0, (0, parcelRequire("2FkXZ")).MSTEAMS_USER_TRAIT_NAME)]: msteamsTrait.value
                    } : {},
                    ...slackTrait ? {
                        [(0, (0, parcelRequire("2FkXZ")).SLACK_USER_TRAIT_NAME)]: slackTrait.value
                    } : {}
                };
            } catch (error) {
                fireFetchEvent('failed', 'USER', error.message);
            }
        }, [
            fireFetchEvent
        ]);
        const getTraitPromises = (0, $5uXOq.useCallback)((isInitial, experimentType, cloudId, atlassianAccountId)=>{
            if (isInitial) return [
                fetchSiteUserTraits(cloudId, atlassianAccountId)
            ];
            const siteAndSiteUserPromises = [
                fetchSiteUserTraits(cloudId, atlassianAccountId),
                fetchSiteTraits(cloudId)
            ];
            switch(experimentType){
                case (0, (0, parcelRequire("2FkXZ")).ExperimentType).ISSUES_VIEW:
                    return siteAndSiteUserPromises;
                case (0, (0, parcelRequire("2FkXZ")).ExperimentType).JSW_COMMENT:
                case (0, (0, parcelRequire("2FkXZ")).ExperimentType).NOTIFICATION_BELL:
                case (0, (0, parcelRequire("2FkXZ")).ExperimentType).NOTIFICATIONS_SECTION:
                default:
                    return [
                        ...siteAndSiteUserPromises,
                        fetchUserTraits(atlassianAccountId)
                    ];
            }
        }, [
            fetchSiteUserTraits,
            fetchSiteTraits,
            fetchUserTraits
        ]);
        const fetchAllTraits = (0, $5uXOq.useCallback)(async (cloudId, atlassianAccountId, experimentType = (0, (0, parcelRequire("2FkXZ")).ExperimentType).JSW_COMMENT)=>{
            if (!cloudId || !atlassianAccountId) {
                fireFetchEvent('failed', 'MISSING_PARAMS', experimentType);
                return;
            }
            setIsLoading(true);
            const promises = getTraitPromises(isInitialized, experimentType, cloudId, atlassianAccountId);
            const results = await Promise.allSettled(promises);
            if (results.some((result)=>result.status === 'rejected')) {
                setIsLoading(false);
                return;
            }
            const result = results.reduce((acc, current)=>{
                if (current.status === 'fulfilled') return Object.assign(current.value || {}, acc);
                return acc;
            }, {});
            setTraits((prevState = {})=>Object.assign(prevState, result));
            setIsLoading(false);
            setIsInitialized(true);
            return Object.assign(traits || {}, result);
        }, [
            fireFetchEvent,
            isInitialized,
            traits,
            getTraitPromises
        ]);
        const value = (0, $5uXOq.useMemo)(()=>({
                isInitialized: isInitialized,
                isLoading: isLoading,
                traits: traits,
                fetchAllTraits: fetchAllTraits
            }), [
            isInitialized,
            isLoading,
            traits,
            fetchAllTraits
        ]);
        return value;
    };
});
parcelRegister("1XkD3", function(module, exports) {
    $parcel$export(module.exports, "setIssuesViewNudgeState", ()=>$8a252357dc829c92$export$4dd07317d9b5915f);
    $parcel$export(module.exports, "setIssueWatchNudgeState", ()=>$8a252357dc829c92$export$4161efedb3ca161f);
    $parcel$export(module.exports, "setNotificationBellNudgeState", ()=>$8a252357dc829c92$export$aef9ea27a6e27c31);
    $parcel$export(module.exports, "setNotificationSectionNudgeState", ()=>$8a252357dc829c92$export$57dbaa6dfc748e05);
    $parcel$export(module.exports, "getIssuesViewNudgeState", ()=>$8a252357dc829c92$export$b8723210651350d);
    $parcel$export(module.exports, "getNotificationNudgeState", ()=>$8a252357dc829c92$export$d18d4819029516b1);
    $parcel$export(module.exports, "getIssueWatchNudgeState", ()=>$8a252357dc829c92$export$6d279fc1eb9dc70);
    $parcel$export(module.exports, "getNotificationSectionNudgeState", ()=>$8a252357dc829c92$export$144ea8a31358efd9);
    var $eNf8a;
    const $8a252357dc829c92$var$store = (0, (0, parcelRequire("eNf8a")).createSessionStorageProvider)('third-party-nudge');
    const $8a252357dc829c92$var$ISSUES_VIEW_NUDGE_KEY = 'issues-view-nudge-date';
    const $8a252357dc829c92$var$NOTIFICATION_BELL_NUDGE_KEY = 'notification-bell-nudge-state';
    const $8a252357dc829c92$var$NOTIFICATION_SECTION_NUDGE_KEY = 'notification-section-nudge-state';
    const $8a252357dc829c92$var$ISSUE_WATCH_NUDGE_KEY = 'issues-watch-nudge-state';
    const $8a252357dc829c92$export$4dd07317d9b5915f = ()=>{
        $8a252357dc829c92$var$store.set($8a252357dc829c92$var$ISSUES_VIEW_NUDGE_KEY, true);
    };
    const $8a252357dc829c92$export$4161efedb3ca161f = ()=>{
        $8a252357dc829c92$var$store.set($8a252357dc829c92$var$ISSUE_WATCH_NUDGE_KEY, true);
    };
    const $8a252357dc829c92$export$aef9ea27a6e27c31 = ()=>{
        $8a252357dc829c92$var$store.set($8a252357dc829c92$var$NOTIFICATION_BELL_NUDGE_KEY, true);
    };
    const $8a252357dc829c92$export$57dbaa6dfc748e05 = ()=>{
        $8a252357dc829c92$var$store.set($8a252357dc829c92$var$NOTIFICATION_SECTION_NUDGE_KEY, true);
    };
    const $8a252357dc829c92$export$b8723210651350d = ()=>$8a252357dc829c92$var$store.get($8a252357dc829c92$var$ISSUES_VIEW_NUDGE_KEY) || false;
    const $8a252357dc829c92$export$d18d4819029516b1 = ()=>$8a252357dc829c92$var$store.get($8a252357dc829c92$var$NOTIFICATION_BELL_NUDGE_KEY) || false;
    const $8a252357dc829c92$export$6d279fc1eb9dc70 = ()=>$8a252357dc829c92$var$store.get($8a252357dc829c92$var$ISSUE_WATCH_NUDGE_KEY) || false;
    const $8a252357dc829c92$export$144ea8a31358efd9 = ()=>$8a252357dc829c92$var$store.get($8a252357dc829c92$var$NOTIFICATION_SECTION_NUDGE_KEY) || false;
});
parcelRegister("8bzhD", function(module, exports) {
    $parcel$export(module.exports, "generateFlagId", ()=>$0568080d6af99dd3$export$1df20ac41cb2fa5d);
    $parcel$export(module.exports, "openTeamsDeepLink", ()=>$0568080d6af99dd3$export$e2c30e91aca3a244);
    $parcel$export(module.exports, "isTenantActiveOnSlack", ()=>$0568080d6af99dd3$export$4fb47bd72bea8fe1);
    $parcel$export(module.exports, "isUserActiveOnSlack", ()=>$0568080d6af99dd3$export$2799d7aad9ba8075);
    $parcel$export(module.exports, "isTenantActiveOnMsteams", ()=>$0568080d6af99dd3$export$9fac56e1e8bde50);
    $parcel$export(module.exports, "isUserActiveOnMsteams", ()=>$0568080d6af99dd3$export$7b9993195fbe5513);
    $parcel$export(module.exports, "isNewJiraUser", ()=>$0568080d6af99dd3$export$7e6bdbcd458053e6);
    $parcel$export(module.exports, "hasSeenIssueCommentNudge", ()=>$0568080d6af99dd3$export$99f6be9c2e86203);
    $parcel$export(module.exports, "hasSeenNotificationsNudge", ()=>$0568080d6af99dd3$export$3e53111ce9af6aaa);
    $parcel$export(module.exports, "hasSeenIssuesViewNudge", ()=>$0568080d6af99dd3$export$37dfb59b6b607265);
    $parcel$export(module.exports, "hasSeenNotificationBellNudge", ()=>$0568080d6af99dd3$export$c83d8ad295a13c63);
    $parcel$export(module.exports, "hasSeenIssueWatchNudge", ()=>$0568080d6af99dd3$export$be43d47447236517);
    $parcel$export(module.exports, "isDismissedIssueWatchNudge", ()=>$0568080d6af99dd3$export$fd0bc68795c0177f);
    $parcel$export(module.exports, "isSnoozedIssueWatchNudge", ()=>$0568080d6af99dd3$export$c1bc357fe2099648);
    $parcel$export(module.exports, "isDismissedNotificationNudge", ()=>$0568080d6af99dd3$export$c65a6a099e4e7540);
    $parcel$export(module.exports, "isSnoozedNotificationNudge", ()=>$0568080d6af99dd3$export$db96ffd460de2f3c);
    var $4mBKK;
    var $2FkXZ;
    let $0568080d6af99dd3$var$flagId = 0;
    const $0568080d6af99dd3$export$1df20ac41cb2fa5d = ()=>String($0568080d6af99dd3$var$flagId++);
    const $0568080d6af99dd3$export$1c49788f82ee9446 = (source)=>`https://teams.microsoft.com/l/app/${0, (0, parcelRequire("2FkXZ")).TEAMS_APP_ID}?source=${source}`;
    const $0568080d6af99dd3$export$e2c30e91aca3a244 = (source = (0, (0, parcelRequire("2FkXZ")).TeamsDeepLinkSource).JSW_COMMENT)=>{
        window.open($0568080d6af99dd3$export$1c49788f82ee9446(source), '_blank', 'noopener, noreferrer');
    };
    const $0568080d6af99dd3$export$4fb47bd72bea8fe1 = (traits)=>traits[0, (0, parcelRequire("2FkXZ")).SLACK_TENANT_TRAIT_NAME] !== undefined && traits[0, (0, parcelRequire("2FkXZ")).SLACK_TENANT_TRAIT_NAME] <= (0, (0, parcelRequire("2FkXZ")).DAYS_BEFORE_INACTIVE_TENANT);
    const $0568080d6af99dd3$export$2799d7aad9ba8075 = (traits)=>traits[0, (0, parcelRequire("2FkXZ")).SLACK_USER_TRAIT_NAME] !== undefined && traits[0, (0, parcelRequire("2FkXZ")).SLACK_USER_TRAIT_NAME] <= (0, (0, parcelRequire("2FkXZ")).DAYS_BEFORE_INACTIVE_USER);
    const $0568080d6af99dd3$export$9fac56e1e8bde50 = (traits)=>traits[0, (0, parcelRequire("2FkXZ")).MSTEAMS_TENANT_TRAIT_NAME] !== undefined && traits[0, (0, parcelRequire("2FkXZ")).MSTEAMS_TENANT_TRAIT_NAME] <= (0, (0, parcelRequire("2FkXZ")).DAYS_BEFORE_INACTIVE_TENANT);
    const $0568080d6af99dd3$export$7b9993195fbe5513 = (traits)=>traits[0, (0, parcelRequire("2FkXZ")).MSTEAMS_USER_TRAIT_NAME] !== undefined && traits[0, (0, parcelRequire("2FkXZ")).MSTEAMS_USER_TRAIT_NAME] <= (0, (0, parcelRequire("2FkXZ")).DAYS_BEFORE_INACTIVE_USER);
    const $0568080d6af99dd3$export$7e6bdbcd458053e6 = (traits)=>{
        const firstJiraActiveTraitValue = traits[0, (0, parcelRequire("2FkXZ")).JIRA_FIRST_ACTIVE_TRAIT];
        return firstJiraActiveTraitValue !== undefined && (0, (0, parcelRequire("4mBKK")).default)(new Date(), new Date(firstJiraActiveTraitValue)) < (0, (0, parcelRequire("2FkXZ")).DAYS_SINCE_FIRST_LOGIN);
    };
    const $0568080d6af99dd3$export$99f6be9c2e86203 = (traits)=>{
        const dismissedTraitValue = traits[0, (0, parcelRequire("2FkXZ")).DISMISSED_TRAIT_NAME];
        return dismissedTraitValue !== undefined && (0, (0, parcelRequire("4mBKK")).default)(new Date(), new Date(dismissedTraitValue)) < (0, (0, parcelRequire("2FkXZ")).DAYS_SINCE_LAST_NUDGE);
    };
    const $0568080d6af99dd3$export$3e53111ce9af6aaa = (traits)=>{
        const notificationsNudgeSeenTraitValue = traits[0, (0, parcelRequire("2FkXZ")).NOTIFICATION_NUDGE_SEEN_TRAIT_NAME];
        return notificationsNudgeSeenTraitValue !== undefined && (0, (0, parcelRequire("4mBKK")).default)(new Date(), new Date(notificationsNudgeSeenTraitValue)) < (0, (0, parcelRequire("2FkXZ")).DAYS_SINCE_LAST_NUDGE);
    };
    const $0568080d6af99dd3$export$37dfb59b6b607265 = (traits)=>{
        const issuesViewTraitValue = traits[0, (0, parcelRequire("2FkXZ")).MSTEAMS_ISSUES_VIEW_TRAIT_NAME];
        return issuesViewTraitValue !== undefined && (0, (0, parcelRequire("4mBKK")).default)(new Date(), new Date(issuesViewTraitValue)) < (0, (0, parcelRequire("2FkXZ")).DAYS_SINCE_LAST_NUDGE);
    };
    const $0568080d6af99dd3$export$c83d8ad295a13c63 = (traits)=>{
        const notificationBellTraitValue = traits[0, (0, parcelRequire("2FkXZ")).NOTIFICATION_BELL_TRAIT_NAME];
        return notificationBellTraitValue !== undefined && (0, (0, parcelRequire("4mBKK")).default)(new Date(), new Date(notificationBellTraitValue)) < (0, (0, parcelRequire("2FkXZ")).DAYS_SINCE_LAST_NUDGE);
    };
    const $0568080d6af99dd3$export$be43d47447236517 = (traits)=>{
        const issuesWatchNudgeTraitValue = traits[0, (0, parcelRequire("2FkXZ")).ISSUE_WATCH_NUDGE_TRAIT_NAME];
        return issuesWatchNudgeTraitValue !== undefined && (0, (0, parcelRequire("4mBKK")).default)(new Date(), new Date(issuesWatchNudgeTraitValue)) < (0, (0, parcelRequire("2FkXZ")).DAYS_SINCE_LAST_NUDGE);
    };
    const $0568080d6af99dd3$export$fd0bc68795c0177f = (traits)=>{
        const isDismissed = traits[0, (0, parcelRequire("2FkXZ")).DISMISS_ISSUE_WATCH_NUDGE_TRAIT_NAME];
        return isDismissed !== undefined;
    };
    const $0568080d6af99dd3$export$c1bc357fe2099648 = (traits)=>{
        const snoozedTraitValue = traits[0, (0, parcelRequire("2FkXZ")).SNOOZE_ISSUE_WATCH_NUDGE_TRAIT_NAME];
        return snoozedTraitValue !== undefined && (0, (0, parcelRequire("4mBKK")).default)(new Date(), new Date(snoozedTraitValue)) < (0, (0, parcelRequire("2FkXZ")).DAYS_SINCE_SNOOZE);
    };
    const $0568080d6af99dd3$export$c65a6a099e4e7540 = (traits)=>{
        const isDismissed = traits[0, (0, parcelRequire("2FkXZ")).DISMISS_NOTIFICATION_NUDGE_TRAIT_NAME];
        return isDismissed !== undefined;
    };
    const $0568080d6af99dd3$export$db96ffd460de2f3c = (traits)=>{
        const snoozedTraitValue = traits[0, (0, parcelRequire("2FkXZ")).SNOOZE_NOTIFICATION_NUDGE_TRAIT_NAME];
        return snoozedTraitValue !== undefined && (0, (0, parcelRequire("4mBKK")).default)(new Date(), new Date(snoozedTraitValue)) < (0, (0, parcelRequire("2FkXZ")).DAYS_SINCE_SNOOZE);
    };
});
parcelRegister("kGiWK", function(module, exports) {
    $parcel$export(module.exports, "useThirdPartyNudgeFlag", ()=>$0599705c1c0930e5$export$4fda1972e27def44);
    var $5uXOq = parcelRequire("5uXOq");
    var $fHfPB;
    var $pa9q9;
    var $8bzhD;
    var $76HiL;
    const $0599705c1c0930e5$export$4fda1972e27def44 = ({ cloudId: cloudId })=>{
        const { showFlag: showFlag, dismissFlag: dismissFlag } = (0, (0, parcelRequire("pa9q9")).useFlagService)();
        const showThirdPartyNudgeFlag = (0, $5uXOq.useCallback)(({ variant: variant, experimentType: experimentType })=>{
            const flagId = `third-party-nudge-flag-${(0, (0, parcelRequire("8bzhD")).generateFlagId)()}`;
            const doDismissFlag = ()=>{
                dismissFlag((0, (0, parcelRequire("fHfPB")).dismissFlag)(flagId));
            };
            const flagProps = {
                id: flagId,
                cloudId: cloudId,
                variant: variant,
                onDismiss: doDismissFlag
            };
            showFlag({
                id: flagId,
                render: (props)=>experimentType === 'NOTIFICATION_BELL' ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("76HiL")).ThirdPartyNotificationBellFlag), {
                        ...props,
                        ...flagProps
                    }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("76HiL")).ThirdPartyIssueCommentFlag), {
                        ...props,
                        ...flagProps
                    })
            });
        }, [
            cloudId,
            dismissFlag,
            showFlag
        ]);
        return {
            showThirdPartyNudgeFlag: showThirdPartyNudgeFlag
        };
    };
});
parcelRegister("76HiL", function(module, exports) {
    $parcel$export(module.exports, "ThirdPartyIssueCommentFlag", ()=>$7bdab1d1ae44ba8b$export$c4e74f7f67cd16b2);
    $parcel$export(module.exports, "ThirdPartyNotificationBellFlag", ()=>$7bdab1d1ae44ba8b$export$e1f14322b5e8104c);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $fVnbM;
    var $dvXKO;
    var $koVbs;
    var $iXVV6;
    var $5oFIy;
    var $8zOmE;
    var $2NMNM;
    var $2FkXZ;
    var $8IM3A;
    var $1XkD3;
    var $8bzhD;
    var $2fA0L;
    var $r5a8x;
    var $5Qiir;
    var $7x8SI;
    var $kA0vR;
    var $bIM5T;
    var $7Y58S;
    var $eKIMg;
    var $cOlLa;
    const $7bdab1d1ae44ba8b$export$c4e74f7f67cd16b2 = ({ cloudId: cloudId, variant: variant, onDismiss: onDismiss })=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        (0, $5uXOq.useEffect)(()=>{
            (0, (0, parcelRequire("8IM3A")).setHasSeenNudge)();
        }, []);
        const slackService = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("dvXKO")).slackServiceFactory)(cloudId, 'jira', createAnalyticsEvent), [
            cloudId,
            createAnalyticsEvent
        ]);
        const handleConnectSlackButtonClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'button clicked', 'thirdPartyConnect', {
                thirdPartyApp: 'slack',
                trigger: 'after-issue-comment'
            });
            slackService.getConsent(()=>{});
        }, [
            createAnalyticsEvent,
            slackService
        ]);
        const handleConnectTeamsButtonClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'button clicked', 'thirdPartyConnect', {
                thirdPartyApp: 'msteams',
                trigger: 'after-issue-comment'
            });
            (0, (0, parcelRequire("8bzhD")).openTeamsDeepLink)();
        }, [
            createAnalyticsEvent
        ]);
        const handleDismissButtonClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'button clicked', 'thirdPartyNudgeJswComment', {
                suggestedThirdPartyApps: variant === 'both' ? [
                    'slack',
                    'msteams'
                ] : [
                    variant
                ],
                trigger: 'after-issue-comment'
            });
            onDismiss();
        }, [
            createAnalyticsEvent,
            onDismiss,
            variant
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: "thirdPartyNudge",
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a),
            attributes: {
                suggestedThirdPartyApps: variant === 'both' ? [
                    'slack',
                    'msteams'
                ] : [
                    variant
                ],
                trigger: 'after-issue-comment'
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Qiir")).NudgeFlag), {
            onDismiss: handleDismissButtonClick
        }, variant === 'slack' && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eKIMg")).SlackSplash), null), variant === 'msteams' && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cOlLa")).TeamsSplash), null), variant === 'both' && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7x8SI")).BothSplash), null), (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$FlagBodyWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$FlagBodyContentWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$FlagHeader, null, formatMessage((0, (0, parcelRequire("r5a8x")).messages).title)), (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$FlagDescription, null, variant === 'slack' && formatMessage((0, (0, parcelRequire("r5a8x")).messages).descriptionSlack), variant === 'msteams' && formatMessage((0, (0, parcelRequire("r5a8x")).messages).descriptionTeams), variant === 'both' && formatMessage((0, (0, parcelRequire("r5a8x")).messages).descriptionBoth))), (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$CtaWrapper, null, variant === 'slack' && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            appearance: "primary",
            testId: "third-party-nudge.ui.third-party-nudge-flag.connect-slack-button",
            onClick: handleConnectSlackButtonClick
        }, formatMessage((0, (0, parcelRequire("r5a8x")).messages).connectSlackButton)), variant === 'msteams' && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            appearance: "primary",
            testId: "third-party-nudge.ui.third-party-nudge-flag.find-out-more-button",
            onClick: handleConnectTeamsButtonClick
        }, formatMessage((0, (0, parcelRequire("r5a8x")).messages).findOutMoreButton)), variant === 'both' && (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$ConnectIntegrationsWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$ConnectIntegrationsHeader, null, formatMessage((0, (0, parcelRequire("r5a8x")).messages).connectHeader)), (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$IntegrationButtonsWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fA0L")).SlackConnectButton), {
            onClick: handleConnectSlackButtonClick
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fA0L")).MicrosoftTeamsConnectButton), {
            onClick: handleConnectTeamsButtonClick
        })))))));
    };
    const $7bdab1d1ae44ba8b$export$e1f14322b5e8104c = ({ cloudId: cloudId, variant: variant, onDismiss: onDismiss })=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        (0, $5uXOq.useEffect)(()=>{
            (0, (0, parcelRequire("1XkD3")).setNotificationBellNudgeState)();
        }, []);
        const slackService = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("dvXKO")).slackServiceFactory)(cloudId, 'jira', createAnalyticsEvent), [
            cloudId,
            createAnalyticsEvent
        ]);
        const handleConnectSlackButtonClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'button clicked', 'notificationBellThirdPartyConnect', {
                thirdPartyApp: 'slack',
                trigger: 'notification-bell'
            });
            slackService.getConsent(()=>{});
        }, [
            createAnalyticsEvent,
            slackService
        ]);
        const handleConnectTeamsButtonClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'button clicked', 'notificationBellThirdPartyConnect', {
                thirdPartyApp: 'msteams',
                trigger: 'notification-bell'
            });
            (0, (0, parcelRequire("8bzhD")).openTeamsDeepLink)((0, (0, parcelRequire("2FkXZ")).TeamsDeepLinkSource).NOTIFICATION_BELL);
        }, [
            createAnalyticsEvent
        ]);
        const handleDismissButtonClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'button clicked', 'notificationBellThirdPartyDismiss', {
                suggestedThirdPartyApps: variant === 'both' ? [
                    'slack',
                    'msteams'
                ] : [
                    variant
                ],
                trigger: 'notification-bell'
            });
            onDismiss();
        }, [
            createAnalyticsEvent,
            onDismiss,
            variant
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: "notificationBellThirdPartyNudge",
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a),
            attributes: {
                suggestedThirdPartyApps: variant === 'both' ? [
                    'slack',
                    'msteams'
                ] : [
                    variant
                ],
                trigger: 'notification-bell'
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Qiir")).NudgeFlag), {
            onDismiss: handleDismissButtonClick
        }, variant === 'slack' && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bIM5T")).NotificationBellSlackSplash), null), variant === 'msteams' && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7Y58S")).NotificationBellTeamsSplash), null), variant === 'both' && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kA0vR")).NotificationBellBothSplash), null), (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$FlagBodyWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$FlagBodyContentWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$FlagHeader, null, variant === 'slack' && formatMessage((0, (0, parcelRequire("r5a8x")).messages).notificationBellSlackTitle), variant === 'msteams' && formatMessage((0, (0, parcelRequire("r5a8x")).messages).notificationBellTeamsTitle), variant === 'both' && formatMessage((0, (0, parcelRequire("r5a8x")).messages).notificationBellBothTitle)), (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$FlagDescription, null, formatMessage((0, (0, parcelRequire("r5a8x")).messages).notificationBellDescription))), (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$CtaWrapper, null, variant === 'slack' && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            appearance: "primary",
            testId: "third-party-nudge.ui.third-party-nudge-flag.slack-connect-button",
            onClick: handleConnectSlackButtonClick
        }, formatMessage((0, (0, parcelRequire("r5a8x")).messages).notificationBellConnectSlackButton)), variant === 'msteams' && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            appearance: "primary",
            testId: "third-party-nudge.ui.third-party-nudge-flag.teams-connect-button",
            onClick: handleConnectTeamsButtonClick
        }, formatMessage((0, (0, parcelRequire("r5a8x")).messages).notificationBellConnectTeamsButton)), variant === 'both' && (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$ConnectIntegrationsWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$ConnectIntegrationsHeader, null, formatMessage((0, (0, parcelRequire("r5a8x")).messages).notificationBellConnectHeader)), (0, ($parcel$interopDefault($5uXOq))).createElement($7bdab1d1ae44ba8b$var$IntegrationButtonsWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fA0L")).SlackConnectButton), {
            onClick: handleConnectSlackButtonClick
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fA0L")).MicrosoftTeamsConnectButton), {
            onClick: handleConnectTeamsButtonClick
        })))))));
    };
    const $7bdab1d1ae44ba8b$var$ConnectIntegrationsWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _2lx21bp4 _zulpu2gc",
                __cmplp.className
            ])
        });
    });
    const $7bdab1d1ae44ba8b$var$FlagBodyContentWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _2lx21bp4 _zulpu2gc",
                __cmplp.className
            ])
        });
    });
    const $7bdab1d1ae44ba8b$var$FlagHeader = (0, $5uXOq.forwardRef)(({ as: C = "h4", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_11c81bk4",
                __cmplp.className
            ])
        });
    });
    const $7bdab1d1ae44ba8b$var$FlagDescription = (0, $5uXOq.forwardRef)(({ as: C = "p", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_11c81oud _19pkidpf _2hwxidpf _otyridpf _18u0idpf",
                __cmplp.className
            ])
        });
    });
    const $7bdab1d1ae44ba8b$var$ConnectIntegrationsHeader = (0, $5uXOq.forwardRef)(({ as: C = "p", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_11c816g6 _syaz1wmz",
                __cmplp.className
            ])
        });
    });
    const $7bdab1d1ae44ba8b$var$IntegrationButtonsWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _2lx2vrvc _1bah1h6o _4cvr1h6o _zulpu2gc",
                __cmplp.className
            ])
        });
    });
    const $7bdab1d1ae44ba8b$var$FlagBodyWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1yt41ejb _1e0c1txw _2lx21bp4 _zulp1ejb",
                __cmplp.className
            ])
        });
    });
    const $7bdab1d1ae44ba8b$var$CtaWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1ule",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("2fA0L", function(module, exports) {
    $parcel$export(module.exports, "MicrosoftTeamsConnectButton", ()=>$050dbb939afefac2$export$42e6beee2a04510d);
    $parcel$export(module.exports, "SlackConnectButton", ()=>$050dbb939afefac2$export$f0b644c6caf8e113);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $fVnbM;
    var $5qRwM;
    var $dC5iT;
    var $8CUq3;
    var $co1wz;
    var $8tu14;
    var $l8wn4;
    var $5ekzg = parcelRequire("5ekzg");
    var $hsoSl = parcelRequire("hsoSl");
    const $050dbb939afefac2$export$42e6beee2a04510d = ({ onClick: onClick })=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement($050dbb939afefac2$var$IntegrationButton, {
            testId: "third-party-nudge.ui.third-party-nudge-flag.connect-button.both-find-out-more-button",
            onClick: onClick
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($050dbb939afefac2$var$IntegrationButtonContent, null, (0, (0, parcelRequire("co1wz")).ff)('groot_jfe_assets_consolidation') ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $050dbb939afefac2$var$teamsLogoWrapperStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8tu14")).default), {
            secondaryColor: "currentColor",
            label: "Teams icon"
        })) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5qRwM")).default), {
            src: (0, ($parcel$interopDefault($hsoSl))),
            alt: "Teams icon",
            width: 16
        }), (0, ($parcel$interopDefault($5uXOq))).createElement($050dbb939afefac2$var$IntegrationName, null, "Microsoft Teams")));
    };
    const $050dbb939afefac2$export$f0b644c6caf8e113 = ({ onClick: onClick })=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement($050dbb939afefac2$var$IntegrationButton, {
            testId: "third-party-nudge.ui.third-party-nudge-flag.connect-button.both-connect-slack-button",
            onClick: onClick
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($050dbb939afefac2$var$IntegrationButtonContent, null, (0, (0, parcelRequire("co1wz")).ff)('groot_jfe_assets_consolidation') ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $050dbb939afefac2$var$SlackLogoWrapperStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8wn4")).default), {
            label: "Slack icon"
        })) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5qRwM")).default), {
            src: (0, ($parcel$interopDefault($5ekzg))),
            alt: "Slack icon",
            width: 16
        }), (0, ($parcel$interopDefault($5uXOq))).createElement($050dbb939afefac2$var$IntegrationName, null, "Slack")));
    };
    const $050dbb939afefac2$var$IntegrationName = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_k48pni7l _1wybutpp",
                __cmplp.className
            ])
        });
    });
    const $050dbb939afefac2$var$IntegrationButtonContent = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _2lx2vrvc _1bah1h6o _4cvr1h6o _zulpu2gc",
                __cmplp.className
            ])
        });
    });
    const $050dbb939afefac2$var$IntegrationButton = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("fVnbM")).default), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_16jlkb7n _1o9zkb7n _i0dlf1ug",
                __cmplp.className
            ])
        });
    });
    const $050dbb939afefac2$var$teamsLogoWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        width: '16px',
        height: '20px',
        display: 'flex',
        marginLeft: 'space.negative.050',
        marginRight: 'space.050'
    });
    const $050dbb939afefac2$var$SlackLogoWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        width: '20px',
        height: '20px',
        display: 'flex'
    });
});
parcelRegister("8tu14", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3bfd5dd788eed1ef$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $5LWLA;
    const $3bfd5dd788eed1ef$var$MSTeamsLogo = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            height: "24",
            width: "24",
            viewBox: "-334 -518 2897 3110",
            "data-testid": props['data-testid']
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M1554.637 777.5h575.713c54.391 0 98.483 44.092 98.483 98.483v524.398c0 199.901-162.051 361.952-361.952 361.952h-1.711c-199.901.028-361.975-162-362.004-361.901V828.971c.001-28.427 23.045-51.471 51.471-51.471z",
            fill: "#5059C9"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("circle", {
            r: "233.25",
            cy: "440.583",
            cx: "1943.75",
            fill: "#5059C9"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("circle", {
            r: "336.917",
            cy: "336.917",
            cx: "1218.083",
            fill: "#7B83EB"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M1667.323 777.5H717.01c-53.743 1.33-96.257 45.931-95.01 99.676v598.105c-7.505 322.519 247.657 590.16 570.167 598.053 322.51-7.893 577.671-275.534 570.167-598.053V877.176c1.245-53.745-41.268-98.346-95.011-99.676z",
            fill: "#7B83EB"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M1244 777.5v838.145c-.258 38.435-23.549 72.964-59.09 87.598a91.856 91.856 0 01-35.765 7.257H667.613c-6.738-17.105-12.958-34.21-18.142-51.833a631.287 631.287 0 01-27.472-183.49V877.02c-1.246-53.659 41.198-98.19 94.855-99.52z",
            opacity: ".1"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M1192.167 777.5v889.978a91.802 91.802 0 01-7.257 35.765c-14.634 35.541-49.163 58.833-87.598 59.09H691.975c-8.812-17.105-17.105-34.21-24.362-51.833-7.257-17.623-12.958-34.21-18.142-51.833a631.282 631.282 0 01-27.472-183.49V877.02c-1.246-53.659 41.198-98.19 94.855-99.52z",
            opacity: ".2"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M1192.167 777.5v786.312c-.395 52.223-42.632 94.46-94.855 94.855h-447.84A631.282 631.282 0 01622 1475.177V877.02c-1.246-53.659 41.198-98.19 94.855-99.52z",
            opacity: ".2"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M1140.333 777.5v786.312c-.395 52.223-42.632 94.46-94.855 94.855H649.472A631.282 631.282 0 01622 1475.177V877.02c-1.246-53.659 41.198-98.19 94.855-99.52z",
            opacity: ".2"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M1244 509.522v163.275c-8.812.518-17.105 1.037-25.917 1.037-8.812 0-17.105-.518-25.917-1.037a284.472 284.472 0 01-51.833-8.293c-104.963-24.857-191.679-98.469-233.25-198.003a288.02 288.02 0 01-16.587-51.833h258.648c52.305.198 94.657 42.549 94.856 94.854z",
            opacity: ".1"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M1192.167 561.355v111.442a284.472 284.472 0 01-51.833-8.293c-104.963-24.857-191.679-98.469-233.25-198.003h190.228c52.304.198 94.656 42.55 94.855 94.854z",
            opacity: ".2"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M1192.167 561.355v111.442a284.472 284.472 0 01-51.833-8.293c-104.963-24.857-191.679-98.469-233.25-198.003h190.228c52.304.198 94.656 42.55 94.855 94.854z",
            opacity: ".2"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M1140.333 561.355v103.148c-104.963-24.857-191.679-98.469-233.25-198.003h138.395c52.305.199 94.656 42.551 94.855 94.855z",
            opacity: ".2"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("linearGradient", {
            gradientTransform: "matrix(1 0 0 -1 0 2075.333)",
            y2: "394.261",
            x2: "942.234",
            y1: "1683.073",
            x1: "198.099",
            gradientUnits: "userSpaceOnUse",
            id: "a"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0",
            "stop-color": "#5a62c3"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: ".5",
            "stop-color": "#4d55bd"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "1",
            "stop-color": "#3940ab"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M95.01 466.5h950.312c52.473 0 95.01 42.538 95.01 95.01v950.312c0 52.473-42.538 95.01-95.01 95.01H95.01c-52.473 0-95.01-42.538-95.01-95.01V561.51c0-52.472 42.538-95.01 95.01-95.01z",
            fill: "#444cb5"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M820.211 828.193h-189.97v517.297h-121.03V828.193H320.123V727.844h500.088z",
            fill: "#FFF"
        }));
    const $3bfd5dd788eed1ef$var$MSTeamsLogomark = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5LWLA")).default), {
            ...props,
            glyph: $3bfd5dd788eed1ef$var$MSTeamsLogo
        });
    var $3bfd5dd788eed1ef$export$2e2bcd8739ae039 = $3bfd5dd788eed1ef$var$MSTeamsLogomark;
});
parcelRegister("l8wn4", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$fed9722bda5af49c$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $5LWLA;
    const $fed9722bda5af49c$var$SlackLogo = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            height: "24",
            width: "24",
            viewBox: "0 0 24 24",
            version: "1.1",
            xmlns: "http://www.w3.org/2000/svg",
            "data-testid": props['data-testid']
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            id: "Slack-Mark-Copy",
            transform: "translate(3.000000, 3.000000)"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            id: "Group"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            transform: "translate(0.000000, 9.420114)",
            fill: "#E01E5A"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M3.84042615,1.92021308 C3.84042615,2.95305496 2.99669617,3.79678495 1.96385428,3.79678495 C0.931012401,3.79678495 0.0872824126,2.95305496 0.0872824126,1.92021308 C0.0872824126,0.887371195 0.931012401,0.0436412063 1.96385428,0.0436412063 L3.84042615,0.0436412063 L3.84042615,1.92021308 Z",
            id: "Path"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M4.78598562,1.92021308 C4.78598562,0.887371195 5.62971561,0.0436412063 6.6625575,0.0436412063 C7.69539938,0.0436412063 8.53912937,0.887371195 8.53912937,1.92021308 L8.53912937,6.61891629 C8.53912937,7.65175817 7.69539938,8.49548816 6.6625575,8.49548816 C5.62971561,8.49548816 4.78598562,7.65175817 4.78598562,6.61891629 L4.78598562,1.92021308 Z",
            id: "Path"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            fill: "#36C5F0"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M6.6625575,3.84042615 C5.62971561,3.84042615 4.78598562,2.99669617 4.78598562,1.96385428 C4.78598562,0.931012401 5.62971561,0.0872824126 6.6625575,0.0872824126 C7.69539938,0.0872824126 8.53912937,0.931012401 8.53912937,1.96385428 L8.53912937,3.84042615 L6.6625575,3.84042615 Z",
            id: "Path"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M6.6625575,4.78598562 C7.69539938,4.78598562 8.53912937,5.62971561 8.53912937,6.6625575 C8.53912937,7.69539938 7.69539938,8.53912937 6.6625575,8.53912937 L1.96385428,8.53912937 C0.931012401,8.53912937 0.0872824126,7.69539938 0.0872824126,6.6625575 C0.0872824126,5.62971561 0.931012401,4.78598562 1.96385428,4.78598562 L6.6625575,4.78598562 Z",
            id: "Path"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            transform: "translate(9.420114, 0.000000)",
            fill: "#2EB67D"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M4.74234442,6.6625575 C4.74234442,5.62971561 5.58607441,4.78598562 6.61891629,4.78598562 C7.65175817,4.78598562 8.49548816,5.62971561 8.49548816,6.6625575 C8.49548816,7.69539938 7.65175817,8.53912937 6.61891629,8.53912937 L4.74234442,8.53912937 L4.74234442,6.6625575 Z",
            id: "Path"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M3.79678495,6.6625575 C3.79678495,7.69539938 2.95305496,8.53912937 1.92021308,8.53912937 C0.887371195,8.53912937 0.0436412063,7.69539938 0.0436412063,6.6625575 L0.0436412063,1.96385428 C0.0436412063,0.931012401 0.887371195,0.0872824126 1.92021308,0.0872824126 C2.95305496,0.0872824126 3.79678495,0.931012401 3.79678495,1.96385428 L3.79678495,6.6625575 Z",
            id: "Path"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            transform: "translate(9.420114, 9.420114)",
            fill: "#ECB22E"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M1.92021308,4.74234442 C2.95305496,4.74234442 3.79678495,5.58607441 3.79678495,6.61891629 C3.79678495,7.65175817 2.95305496,8.49548816 1.92021308,8.49548816 C0.887371195,8.49548816 0.0436412063,7.65175817 0.0436412063,6.61891629 L0.0436412063,4.74234442 L1.92021308,4.74234442 Z",
            id: "Path"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M1.92021308,3.79678495 C0.887371195,3.79678495 0.0436412063,2.95305496 0.0436412063,1.92021308 C0.0436412063,0.887371195 0.887371195,0.0436412063 1.92021308,0.0436412063 L6.61891629,0.0436412063 C7.65175817,0.0436412063 8.49548816,0.887371195 8.49548816,1.92021308 C8.49548816,2.95305496 7.65175817,3.79678495 6.61891629,3.79678495 L1.92021308,3.79678495 Z",
            id: "Path"
        }))))));
    const $fed9722bda5af49c$var$SlackLogomark = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5LWLA")).default), {
            ...props,
            glyph: $fed9722bda5af49c$var$SlackLogo
        });
    var $fed9722bda5af49c$export$2e2bcd8739ae039 = $fed9722bda5af49c$var$SlackLogomark;
});
parcelRegister("5ekzg", function(module, exports) {
    module.exports = new URL("slack.26cbe450.svg", import.meta.url).toString();
});
parcelRegister("hsoSl", function(module, exports) {
    module.exports = new URL("teams.b0e5e774.svg", import.meta.url).toString();
});
parcelRegister("r5a8x", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$fc3a29b96cc0483d$export$defe85febe8b728c);
    var $7VHMR;
    const $fc3a29b96cc0483d$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "third-party-nudge.third-party-nudge-flag.title",
            "defaultMessage": "Stay updated on your work"
        },
        descriptionSlack: {
            "id": "third-party-nudge.third-party-nudge-flag.description-slack",
            "defaultMessage": "Get real-time updates in Slack where your team can quickly see and respond to project needs."
        },
        descriptionTeams: {
            "id": "third-party-nudge.third-party-nudge-flag.description-teams",
            "defaultMessage": "Get real-time updates in Microsoft Teams where your team can quickly see and respond to project needs."
        },
        descriptionBoth: {
            "id": "third-party-nudge.third-party-nudge-flag.description-both",
            "defaultMessage": "Get real-time updates in your chat tool where your team can quickly see and respond to project needs."
        },
        findOutMoreButton: {
            "id": "third-party-nudge.third-party-nudge-flag.find-out-more-button",
            "defaultMessage": "Find out more"
        },
        connectSlackButton: {
            "id": "third-party-nudge.third-party-nudge-flag.connect-slack-button",
            "defaultMessage": "Connect Slack"
        },
        connectHeader: {
            "id": "third-party-nudge.third-party-nudge-flag.connect-header",
            "defaultMessage": "Connect"
        },
        notificationBellTeamsTitle: {
            "id": "third-party-nudge.third-party-nudge-flag.notification-bell-teams-title",
            "defaultMessage": "Get personal notifications in Teams"
        },
        notificationBellSlackTitle: {
            "id": "third-party-nudge.third-party-nudge-flag.notification-bell-slack-title",
            "defaultMessage": "Get personal notifications in Slack"
        },
        notificationBellBothTitle: {
            "id": "third-party-nudge.third-party-nudge-flag.notification-bell-both-title",
            "defaultMessage": "Get instant personal notifications"
        },
        notificationBellDescription: {
            "id": "third-party-nudge.third-party-nudge-flag.notification-bell-description",
            "defaultMessage": "Stay on top of your work and respond to project needs in real time with custom, personal notifications."
        },
        notificationBellConnectTeamsButton: {
            "id": "third-party-nudge.third-party-nudge-flag.notification-bell-connect-teams-button",
            "defaultMessage": "Connect Microsoft Teams"
        },
        notificationBellConnectSlackButton: {
            "id": "third-party-nudge.third-party-nudge-flag.notification-bell-connect-slack-button",
            "defaultMessage": "Connect Slack"
        },
        notificationBellConnectHeader: {
            "id": "third-party-nudge.third-party-nudge-flag.notification-bell-connect-header",
            "defaultMessage": "Connect now"
        }
    });
});
parcelRegister("5Qiir", function(module, exports) {
    $parcel$export(module.exports, "NudgeFlag", ()=>$e3c559a578764a51$export$967cf9dd5e24152);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $84THj;
    const $e3c559a578764a51$export$967cf9dd5e24152 = ({ onDismiss: onDismiss, children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement($e3c559a578764a51$var$FlagWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement($e3c559a578764a51$var$DismissButton, {
            "data-testid": "third-party-nudge.ui.third-party-nudge-flag.nudge-flag.dismiss-button",
            onClick: onDismiss
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("84THj"))))), {
            label: "Dismiss icon",
            color: "var(--ds-text-inverse, #FFFFFF)",
            LEGACY_size: "small",
            LEGACY_primaryColor: "var(--ds-text-inverse, white)"
        })), children);
    const $e3c559a578764a51$var$FlagWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1bsb1rpp _16qs1lil _2rko1y44 _kqswh2mm _bfhk1j9a",
                __cmplp.className
            ])
        });
    });
    const $e3c559a578764a51$var$DismissButton = (0, $5uXOq.forwardRef)(({ as: C = "button", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswstnw _154iu2gc _1xi2u2gc _1e0c1txw _1bah1h6o _4cvr1h6o _1bsb1ejb _4t3i1ejb _11q7glyw _syaz1kw7 _19itglyw _1yt4ze3t _11c81kw7 _80omtlke _12ji1r31 _1qu21kw7 _12y31o36",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("7x8SI", function(module, exports) {
    $parcel$export(module.exports, "BothSplash", ()=>$5fa018b3ef85c754$export$149b071b87f1d588);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $ousew;
    var $koVbs;
    var $1CrUW;
    const $5fa018b3ef85c754$export$149b071b87f1d588 = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement($5fa018b3ef85c754$var$BothSplashWrapper, {
            "data-testid": "third-party-nudge.ui.third-party-nudge-flag.splashes.both-splash.both-splash-wrapper"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            width: "412",
            height: "128",
            viewBox: "0 0 412 128",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M0 4C0 1.79086 1.79086 0 4 0H408C410.209 0 412 1.79086 412 4V128H0V4Z",
            fill: "url(#paint0_linear_914_2570)"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            filter: "url(#filter0_dd_914_2570)"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "47",
            y: "18.0001",
            width: "42",
            height: "42",
            rx: "4",
            fill: "white"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            clipPath: "url(#clip0_914_2570)"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M68.42 34.6669C70.582 34.6669 72.3347 32.9692 72.3347 30.8749C72.3347 28.7807 70.582 27.083 68.42 27.083C66.258 27.083 64.5054 28.7807 64.5054 30.8749C64.5054 32.9692 66.258 34.6669 68.42 34.6669Z",
            fill: "#7B83EB"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.1",
            d: "M68.1405 31.6876H64.595L64.6126 31.7632L64.6143 31.7697L64.6327 31.8396C64.8903 32.7859 65.5154 33.5992 66.3774 34.1097C67.2394 34.6203 68.2717 34.7885 69.2587 34.5793V32.7707C69.2578 32.4837 69.1397 32.2087 68.9302 32.0058C68.7207 31.8029 68.4368 31.6885 68.1405 31.6876Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M67.3021 32.5001H64.8831C65.2005 33.1485 65.7015 33.6963 66.3276 34.0799C66.9537 34.4635 67.6794 34.6671 68.4202 34.6671V33.5832C68.4194 33.2962 68.3013 33.0212 68.0918 32.8183C67.8823 32.6154 67.5984 32.501 67.3021 32.5001Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M77.2273 34.9376C78.8487 34.9376 80.1631 33.6644 80.1631 32.0939C80.1631 30.5233 78.8487 29.2501 77.2273 29.2501C75.6059 29.2501 74.2915 30.5233 74.2915 32.0939C74.2915 33.6644 75.6059 34.9376 77.2273 34.9376Z",
            fill: "#5059C9"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M79.7439 35.7501H73.1501C73.0077 35.7501 72.8712 35.8049 72.7705 35.9024C72.6698 35.9999 72.6133 36.1322 72.6133 36.2701V42.8595C72.5951 43.8132 72.9157 44.7439 73.5215 45.4959C74.1273 46.2479 74.9814 46.7755 75.9408 46.9902C76.5518 47.1144 77.1838 47.1051 77.7906 46.9628C78.3975 46.8206 78.9639 46.5491 79.4487 46.168C79.9336 45.7869 80.3246 45.3059 80.5933 44.76C80.862 44.2141 81.0016 43.617 81.002 43.0122V36.9689C81.002 36.6456 80.8695 36.3356 80.6335 36.1071C80.3976 35.8785 80.0775 35.7501 79.7439 35.7501Z",
            fill: "#5059C9"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M75.1306 36.9689V44.6876C75.1315 46.1181 74.6448 47.5088 73.7463 48.6439C72.8477 49.7791 71.5874 50.5952 70.1611 50.9656C68.7347 51.336 67.222 51.24 65.8577 50.6925C64.4934 50.145 63.3539 49.1766 62.6159 47.9376C62.4544 47.6778 62.3142 47.4061 62.1965 47.1251C62.0867 46.8602 61.9942 46.5888 61.9197 46.3126C61.7814 45.7813 61.7109 45.2356 61.71 44.6876V36.9689C61.7097 36.8088 61.7421 36.6502 61.8053 36.5022C61.8684 36.3543 61.9611 36.2198 62.078 36.1066C62.1949 35.9934 62.3337 35.9036 62.4864 35.8425C62.6392 35.7813 62.8029 35.7499 62.9682 35.7501H73.8725C74.0377 35.7499 74.2015 35.7813 74.3542 35.8425C74.507 35.9036 74.6457 35.9934 74.7626 36.1066C74.8795 36.2198 74.9722 36.3543 75.0353 36.5022C75.0985 36.6502 75.1309 36.8088 75.1306 36.9689Z",
            fill: "#7B83EB"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M67.3021 32.5001H64.8831C65.2005 33.1485 65.7015 33.6963 66.3276 34.0799C66.9537 34.4635 67.6794 34.6671 68.4202 34.6671V33.5832C68.4194 33.2962 68.3013 33.0212 68.0918 32.8183C67.8823 32.6154 67.5984 32.501 67.3021 32.5001Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.1",
            d: "M69.2591 35.7501V46.0445C69.2591 46.2894 69.1726 46.527 69.0139 46.7177C68.8552 46.9083 68.6339 47.0407 68.3867 47.0926C68.3078 47.1154 68.2258 47.1263 68.1435 47.1251H62.1965C62.0867 46.8602 61.9942 46.5888 61.9197 46.3126C61.7814 45.7813 61.7109 45.2356 61.71 44.6876V36.9689C61.7097 36.8088 61.7421 36.6502 61.8053 36.5022C61.8684 36.3543 61.9611 36.2198 62.078 36.1066C62.1949 35.9934 62.3337 35.9036 62.4864 35.8425C62.6392 35.7813 62.8029 35.7499 62.9682 35.7501H69.2591Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M68.4203 35.7501V46.857C68.4215 46.9367 68.4102 47.0162 68.3867 47.0926C68.3331 47.332 68.1965 47.5463 67.9997 47.7001C67.8028 47.8538 67.5576 47.9376 67.3047 47.9376H62.6159C62.4544 47.6778 62.3142 47.4061 62.1965 47.1251C62.0867 46.8602 61.9942 46.5888 61.9197 46.3126C61.7814 45.7813 61.7109 45.2356 61.71 44.6876V36.9689C61.7097 36.8088 61.7421 36.6502 61.8053 36.5022C61.8684 36.3543 61.9611 36.2198 62.078 36.1066C62.1949 35.9934 62.3337 35.9036 62.4864 35.8425C62.6392 35.7813 62.8029 35.7499 62.9682 35.7501H68.4203Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M68.4203 35.7501V45.232C68.419 45.5182 68.301 45.7923 68.0921 45.9947C67.8832 46.1971 67.6002 46.3113 67.3047 46.3126H61.9197C61.7814 45.7813 61.7109 45.2356 61.71 44.6876V36.9689C61.7097 36.8088 61.7421 36.6502 61.8053 36.5022C61.8684 36.3543 61.9611 36.2198 62.078 36.1066C62.1949 35.9934 62.3337 35.9036 62.4864 35.8425C62.6392 35.7813 62.8029 35.7499 62.9682 35.7501H68.4203Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M62.9682 35.7501C62.8029 35.7499 62.6392 35.7813 62.4864 35.8425C62.3337 35.9036 62.1949 35.9934 62.078 36.1066C61.9611 36.2198 61.8684 36.3543 61.8053 36.5022C61.7421 36.6502 61.7097 36.8088 61.71 36.9689V44.6876C61.7109 45.2356 61.7814 45.7813 61.9197 46.3126H66.4659C66.7614 46.3113 67.0444 46.1971 67.2533 45.9947C67.4622 45.7923 67.5802 45.5182 67.5815 45.232V35.7501H62.9682Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M55.2788 32.5001H66.4632C66.7598 32.5001 67.0442 32.6142 67.2538 32.8173C67.4635 33.0205 67.5813 33.2959 67.5813 33.5832V44.4171C67.5813 44.7043 67.4635 44.9798 67.2538 45.1829C67.0442 45.386 66.7598 45.5001 66.4632 45.5001H55.2788C54.9822 45.5001 54.6978 45.386 54.4881 45.1829C54.2784 44.9798 54.1606 44.7043 54.1606 44.4171V33.5832C54.1606 33.2959 54.2784 33.0205 54.4881 32.8173C54.6978 32.6142 54.9822 32.5001 55.2788 32.5001Z",
            fill: "#4B53BC"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M64.2093 36.542H61.7013V43.0583H60.0556V36.542H57.5325V34.9414H64.2093V36.542Z",
            fill: "white"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "47",
            y: "68.0001",
            width: "42",
            height: "42",
            rx: "3.18898",
            fill: "white"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M60.4623 92.4304C60.4623 93.9336 59.2343 95.1616 57.731 95.1616C56.2278 95.1616 54.9998 93.9336 54.9998 92.4304C54.9998 90.9271 56.2278 89.6991 57.731 89.6991H60.4623V92.4304Z",
            fill: "#E01E5A"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M61.8376 92.4305C61.8376 90.9272 63.0657 89.6992 64.5689 89.6992C66.0722 89.6992 67.3002 90.9272 67.3002 92.4305V99.2692C67.3002 100.772 66.0722 102 64.5689 102C63.0657 102 61.8376 100.772 61.8376 99.2692V92.4305Z",
            fill: "#E01E5A"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M64.5689 81.4625C63.0657 81.4625 61.8376 80.2345 61.8376 78.7313C61.8376 77.228 63.0657 76 64.5689 76C66.0722 76 67.3002 77.228 67.3002 78.7313V81.4625H64.5689Z",
            fill: "#36C5F0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M64.5697 82.8386C66.073 82.8386 67.301 84.0666 67.301 85.5699C67.301 87.0731 66.073 88.3011 64.5697 88.3011H57.731C56.2278 88.3011 54.9998 87.0731 54.9998 85.5699C54.9998 84.0666 56.2278 82.8386 57.731 82.8386H64.5697Z",
            fill: "#36C5F0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M75.5364 85.5699C75.5364 84.0666 76.7644 82.8386 78.2676 82.8386C79.7709 82.8386 80.9989 84.0666 80.9989 85.5699C80.9989 87.0731 79.7709 88.3011 78.2676 88.3011H75.5364V85.5699Z",
            fill: "#2EB67D"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M74.161 85.57C74.161 87.0732 72.933 88.3013 71.4297 88.3013C69.9265 88.3013 68.6985 87.0732 68.6985 85.57V78.7313C68.6985 77.228 69.9265 76 71.4297 76C72.933 76 74.161 77.228 74.161 78.7313V85.57Z",
            fill: "#2EB67D"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M71.4295 96.538C72.9328 96.538 74.1608 97.766 74.1608 99.2692C74.1608 100.772 72.9328 102 71.4295 102C69.9262 102 68.6982 100.772 68.6982 99.2692V96.538H71.4295Z",
            fill: "#ECB22E"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M71.4297 95.1616C69.9265 95.1616 68.6985 93.9336 68.6985 92.4304C68.6985 90.9271 69.9265 89.6991 71.4297 89.6991H78.2685C79.7717 89.6991 80.9997 90.9271 80.9997 92.4304C80.9997 93.9336 79.7717 95.1616 78.2685 95.1616H71.4297Z",
            fill: "#ECB22E"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M101 28.0001C101 25.791 102.791 24.0001 105 24.0001H361C363.209 24.0001 365 25.791 365 28.0001V100C365 102.209 363.209 104 361 104H105C102.791 104 101 102.209 101 100V28.0001Z",
            fill: "white",
            fillOpacity: "0.98"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#091E42",
            xmlSpace: "preserve",
            fontSize: "9",
            fontWeight: "600",
            letterSpacing: "0em"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "113",
            y: "45.1993"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), {
            ...(0, (0, parcelRequire("1CrUW")).messages).slackExampleTitle,
            values: {
                tag: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
                        fill: "#0055CC",
                        xmlSpace: "preserve",
                        fontSize: "9",
                        fontWeight: "600",
                        letterSpacing: "0em"
                    }, chunks)
            }
        }))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "113",
            y: "54.0001",
            width: "210",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "113",
            y: "66.0001",
            width: "91",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "207",
            y: "66.0001",
            width: "91",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "113.3",
            y: "80.3001",
            width: "61.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#B3B9C4",
            strokeWidth: "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#626F86",
            xmlSpace: "preserve",
            fontSize: "9",
            fontWeight: "500",
            letterSpacing: "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "123.26",
            y: "91.1993"
        }, formatMessage((0, (0, parcelRequire("1CrUW")).messages).integrationExampleCommentButton))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "181.893",
            y: "80.3001",
            width: "60.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#B3B9C4",
            strokeWidth: "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#626F86",
            xmlSpace: "preserve",
            fontSize: "9",
            fontWeight: "500",
            letterSpacing: "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "191.414",
            y: "91.1993"
        }, formatMessage((0, (0, parcelRequire("1CrUW")).messages).integrationExampleEditIssueButton)))), (0, ($parcel$interopDefault($5uXOq))).createElement("defs", null, (0, ($parcel$interopDefault($5uXOq))).createElement("filter", {
            id: "filter0_dd_914_2570",
            x: "35",
            y: "6",
            width: "342",
            height: "116",
            filterUnits: "userSpaceOnUse",
            colorInterpolationFilters: "sRGB"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("feFlood", {
            floodOpacity: "0",
            result: "BackgroundImageFix"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            in: "SourceAlpha",
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
            result: "hardAlpha"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feOffset", null), (0, ($parcel$interopDefault($5uXOq))).createElement("feGaussianBlur", {
            stdDeviation: "6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            type: "matrix",
            values: "0 0 0 0 0.0117647 0 0 0 0 0.0156863 0 0 0 0 0.0156863 0 0 0 0.56 0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in2: "BackgroundImageFix",
            result: "effect1_dropShadow_914_2570"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            in: "SourceAlpha",
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
            result: "hardAlpha"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feOffset", null), (0, ($parcel$interopDefault($5uXOq))).createElement("feGaussianBlur", {
            stdDeviation: "0.5"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            type: "matrix",
            values: "0 0 0 0 0.0117647 0 0 0 0 0.0156863 0 0 0 0 0.0156863 0 0 0 0.5 0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in2: "effect1_dropShadow_914_2570",
            result: "effect2_dropShadow_914_2570"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in: "SourceGraphic",
            in2: "effect2_dropShadow_914_2570",
            result: "shape"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("linearGradient", {
            id: "paint0_linear_914_2570",
            x1: "408.5",
            y1: "3.50001",
            x2: "0.106745",
            y2: "128.349",
            gradientUnits: "userSpaceOnUse"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.0974484",
            stopColor: "#042C86"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.920861",
            stopColor: "#0744A2"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("clipPath", {
            id: "clip0_914_2570"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            width: "27.3",
            height: "26",
            fill: "white",
            transform: "translate(54 26.0001)"
        })))));
    };
    const $5fa018b3ef85c754$var$BothSplashWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i1qr7",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("1CrUW", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$00115d534eea6438$export$defe85febe8b728c);
    var $7VHMR;
    const $00115d534eea6438$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        teamsExampleTitle: {
            "id": "third-party-nudge.third-party-nudge-flag.splashes.teams-example-title",
            "defaultMessage": "Mike commented on an issue"
        },
        slackExampleIntegrationName: {
            "id": "third-party-nudge.third-party-nudge-flag.splashes.slack-example-integration-name",
            "defaultMessage": "Jira Cloud"
        },
        slackExampleTitle: {
            "id": "third-party-nudge.third-party-nudge-flag.splashes.slack-example-title",
            "defaultMessage": "<tag>@mike</tag> assigned an issue to you"
        },
        bothExampleTitle: {
            "id": "third-party-nudge.third-party-nudge-flag.splashes.both-example-title",
            "defaultMessage": "<tag>@mike</tag> mentioned you in a comment"
        },
        integrationExampleCommentButton: {
            "id": "third-party-nudge.third-party-nudge-flag.splashes.integration-example-comment-button",
            "defaultMessage": "Comment"
        },
        integrationExampleEditIssueButton: {
            "id": "third-party-nudge.third-party-nudge-flag.splashes.integration-example-edit-issue-button",
            "defaultMessage": "Edit issue"
        },
        integrationExampleOpenInJiraButton: {
            "id": "third-party-nudge.third-party-nudge-flag.splashes.integration-example-open-in-jira-button",
            "defaultMessage": "Open in Jira"
        }
    });
});
parcelRegister("kA0vR", function(module, exports) {
    $parcel$export(module.exports, "NotificationBellBothSplash", ()=>$5bcddde960622926$export$647bdb139f429977);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $ousew;
    var $koVbs;
    var $kqOSb;
    const $5bcddde960622926$export$647bdb139f429977 = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            "data-testid": "third-party-nudge.ui.third-party-nudge-flag.splashes.notification-bell-splashes.both-splash",
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i1qr7"
            ])
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            width: "412",
            height: "128",
            viewBox: "0 0 412 128",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M0 4C0 1.79086 1.79086 0 4 0H408C410.209 0 412 1.79086 412 4V128H0V4Z",
            fill: "url(#paint0_linear_2726_7335)"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            filter: "url(#filter0_dd_2726_7335)"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "47",
            y: "18.0001",
            width: "42",
            height: "42",
            rx: "4",
            fill: "white"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            "clip-path": "url(#clip0_2726_7335)"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M68.42 34.6669C70.582 34.6669 72.3346 32.9692 72.3346 30.875C72.3346 28.7807 70.582 27.083 68.42 27.083C66.258 27.083 64.5053 28.7807 64.5053 30.875C64.5053 32.9692 66.258 34.6669 68.42 34.6669Z",
            fill: "#7B83EB"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.1",
            d: "M68.1404 31.6876H64.5948L64.6125 31.7632L64.6141 31.7697L64.6326 31.8396C64.8902 32.7859 65.5153 33.5992 66.3773 34.1098C67.2393 34.6203 68.2715 34.7885 69.2585 34.5793V32.7707C69.2577 32.4837 69.1396 32.2087 68.9301 32.0058C68.7206 31.8029 68.4367 31.6885 68.1404 31.6876Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M67.3021 32.5001H64.883C65.2005 33.1485 65.7014 33.6963 66.3275 34.0799C66.9537 34.4635 67.6793 34.6671 68.4202 34.6671V33.5832C68.4193 33.2962 68.3012 33.0212 68.0917 32.8183C67.8822 32.6154 67.5983 32.501 67.3021 32.5001Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M77.2273 34.9376C78.8487 34.9376 80.1631 33.6644 80.1631 32.0939C80.1631 30.5233 78.8487 29.2501 77.2273 29.2501C75.6059 29.2501 74.2915 30.5233 74.2915 32.0939C74.2915 33.6644 75.6059 34.9376 77.2273 34.9376Z",
            fill: "#5059C9"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M79.7439 35.7501H73.1501C73.0077 35.7501 72.8712 35.8049 72.7705 35.9024C72.6698 36 72.6133 36.1322 72.6133 36.2701V42.8595C72.5951 43.8132 72.9157 44.7439 73.5215 45.4959C74.1273 46.2479 74.9814 46.7755 75.9408 46.9903C76.5518 47.1145 77.1838 47.1051 77.7906 46.9629C78.3975 46.8206 78.9639 46.5491 79.4487 46.168C79.9336 45.787 80.3246 45.306 80.5933 44.76C80.862 44.2141 81.0016 43.617 81.002 43.0123V36.9689C81.002 36.6457 80.8695 36.3357 80.6335 36.1071C80.3976 35.8785 80.0775 35.7501 79.7439 35.7501Z",
            fill: "#5059C9"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M75.1305 36.9689V44.6876C75.1313 46.1181 74.6447 47.5088 73.7461 48.6439C72.8476 49.7791 71.5873 50.5952 70.1609 50.9656C68.7346 51.336 67.2219 51.24 65.8576 50.6925C64.4933 50.145 63.3537 49.1766 62.6157 47.9376C62.4543 47.6778 62.3141 47.4061 62.1963 47.1251C62.0866 46.8602 61.9941 46.5888 61.9195 46.3126C61.7813 45.7813 61.7108 45.2356 61.7098 44.6876V36.9689C61.7096 36.8088 61.742 36.6502 61.8052 36.5022C61.8683 36.3543 61.961 36.2198 62.0779 36.1066C62.1947 35.9934 62.3335 35.9036 62.4863 35.8425C62.639 35.7813 62.8027 35.7499 62.968 35.7501H73.8723C74.0376 35.7499 74.2013 35.7813 74.3541 35.8425C74.5068 35.9036 74.6456 35.9934 74.7625 36.1066C74.8794 36.2198 74.972 36.3543 75.0352 36.5022C75.0983 36.6502 75.1307 36.8088 75.1305 36.9689Z",
            fill: "#7B83EB"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M67.3021 32.5001H64.883C65.2005 33.1485 65.7014 33.6963 66.3275 34.0799C66.9537 34.4635 67.6793 34.6671 68.4202 34.6671V33.5832C68.4193 33.2962 68.3012 33.0212 68.0917 32.8183C67.8822 32.6154 67.5983 32.501 67.3021 32.5001Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.1",
            d: "M69.259 35.7501V46.0445C69.259 46.2894 69.1724 46.527 69.0137 46.7177C68.855 46.9084 68.6337 47.0407 68.3866 47.0926C68.3077 47.1154 68.2257 47.1263 68.1434 47.1251H62.1963C62.0866 46.8602 61.9941 46.5888 61.9195 46.3126C61.7813 45.7813 61.7108 45.2356 61.7098 44.6876V36.9689C61.7096 36.8088 61.742 36.6502 61.8052 36.5022C61.8683 36.3543 61.961 36.2198 62.0779 36.1066C62.1947 35.9934 62.3335 35.9036 62.4863 35.8425C62.639 35.7813 62.8027 35.7499 62.968 35.7501H69.259Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M68.4202 35.7501V46.857C68.4214 46.9367 68.4101 47.0162 68.3866 47.0926C68.333 47.332 68.1964 47.5464 67.9995 47.7001C67.8027 47.8538 67.5574 47.9376 67.3046 47.9376H62.6157C62.4543 47.6778 62.3141 47.4061 62.1963 47.1251C62.0866 46.8602 61.9941 46.5888 61.9195 46.3126C61.7813 45.7813 61.7108 45.2356 61.7098 44.6876V36.9689C61.7096 36.8088 61.742 36.6502 61.8052 36.5022C61.8683 36.3543 61.961 36.2198 62.0779 36.1066C62.1947 35.9934 62.3335 35.9036 62.4863 35.8425C62.639 35.7813 62.8027 35.7499 62.968 35.7501H68.4202Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M68.4202 35.7501V45.232C68.4189 45.5182 68.3009 45.7923 68.092 45.9947C67.883 46.1971 67.6001 46.3114 67.3046 46.3126H61.9195C61.7813 45.7813 61.7108 45.2356 61.7098 44.6876V36.9689C61.7096 36.8088 61.742 36.6502 61.8052 36.5022C61.8683 36.3543 61.961 36.2198 62.0779 36.1066C62.1947 35.9934 62.3335 35.9036 62.4863 35.8425C62.639 35.7813 62.8027 35.7499 62.968 35.7501H68.4202Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M62.968 35.7501C62.8027 35.7499 62.639 35.7813 62.4863 35.8425C62.3335 35.9036 62.1947 35.9934 62.0779 36.1066C61.961 36.2198 61.8683 36.3543 61.8052 36.5022C61.742 36.6502 61.7096 36.8088 61.7098 36.9689V44.6876C61.7108 45.2356 61.7813 45.7813 61.9195 46.3126H66.4658C66.7613 46.3114 67.0442 46.1971 67.2532 45.9947C67.4621 45.7923 67.5801 45.5182 67.5814 45.232V35.7501H62.968Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M55.2787 32.5001H66.4632C66.7597 32.5001 67.0441 32.6142 67.2538 32.8174C67.4635 33.0205 67.5813 33.296 67.5813 33.5832V44.4171C67.5813 44.7043 67.4635 44.9798 67.2538 45.1829C67.0441 45.386 66.7597 45.5001 66.4632 45.5001H55.2787C54.9822 45.5001 54.6978 45.386 54.4881 45.1829C54.2784 44.9798 54.1606 44.7043 54.1606 44.4171V33.5832C54.1606 33.296 54.2784 33.0205 54.4881 32.8174C54.6978 32.6142 54.9822 32.5001 55.2787 32.5001Z",
            fill: "#4B53BC"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M64.2092 36.5421H61.7012V43.0583H60.0555V36.5421H57.5324V34.9414H64.2092V36.5421Z",
            fill: "white"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "47",
            y: "68.0001",
            width: "42",
            height: "42",
            rx: "3.18898",
            fill: "white"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M60.4623 92.4303C60.4623 93.9336 59.2343 95.1616 57.7311 95.1616C56.2278 95.1616 54.9998 93.9336 54.9998 92.4303C54.9998 90.9271 56.2278 89.6991 57.7311 89.6991H60.4623V92.4303Z",
            fill: "#E01E5A"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M61.8377 92.4304C61.8377 90.9271 63.0657 89.6991 64.569 89.6991C66.0722 89.6991 67.3002 90.9271 67.3002 92.4304V99.2691C67.3002 100.772 66.0722 102 64.569 102C63.0657 102 61.8377 100.772 61.8377 99.2691V92.4304Z",
            fill: "#E01E5A"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M64.569 81.4625C63.0658 81.4625 61.8378 80.2345 61.8378 78.7312C61.8378 77.228 63.0658 76 64.569 76C66.0723 76 67.3003 77.228 67.3003 78.7312V81.4625H64.569Z",
            fill: "#36C5F0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M64.5698 82.8386C66.0731 82.8386 67.3011 84.0666 67.3011 85.5699C67.3011 87.0731 66.0731 88.3011 64.5698 88.3011H57.7311C56.2278 88.3011 54.9998 87.0731 54.9998 85.5699C54.9998 84.0666 56.2278 82.8386 57.7311 82.8386H64.5698Z",
            fill: "#36C5F0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M75.5365 85.5698C75.5365 84.0666 76.7645 82.8386 78.2678 82.8386C79.771 82.8386 80.999 84.0666 80.999 85.5698C80.999 87.0731 79.771 88.3011 78.2678 88.3011H75.5365V85.5698Z",
            fill: "#2EB67D"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M74.1611 85.57C74.1611 87.0732 72.9331 88.3012 71.4299 88.3012C69.9266 88.3012 68.6986 87.0732 68.6986 85.57V78.7312C68.6986 77.228 69.9266 76 71.4299 76C72.9331 76 74.1611 77.228 74.1611 78.7312V85.57Z",
            fill: "#2EB67D"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M71.4297 96.538C72.933 96.538 74.161 97.766 74.161 99.2692C74.161 100.772 72.933 102 71.4297 102C69.9265 102 68.6985 100.772 68.6985 99.2692V96.538H71.4297Z",
            fill: "#ECB22E"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M71.4299 95.1616C69.9266 95.1616 68.6986 93.9336 68.6986 92.4303C68.6986 90.9271 69.9266 89.6991 71.4299 89.6991H78.2686C79.7719 89.6991 80.9999 90.9271 80.9999 92.4303C80.9999 93.9336 79.7719 95.1616 78.2686 95.1616H71.4299Z",
            fill: "#ECB22E"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M101 28.0001C101 25.791 102.791 24.0001 105 24.0001H361C363.209 24.0001 365 25.791 365 28.0001V100C365 102.209 363.209 104 361 104H105C102.791 104 101 102.209 101 100V28.0001Z",
            fill: "white",
            "fill-opacity": "0.98"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#091E42",
            xmlSpace: "preserve",
            fontSize: "9",
            fontWeight: "600",
            letterSpacing: "0em"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "113",
            y: "45.1993"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), {
            ...(0, (0, parcelRequire("kqOSb")).messages).bothNudgeTitle,
            values: {
                tag: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
                        fill: "#0055CC",
                        xmlSpace: "preserve",
                        fontSize: "9",
                        fontWeight: "600",
                        letterSpacing: "0em"
                    }, chunks)
            }
        }))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "113",
            y: "54.0001",
            width: "210",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "113",
            y: "66.0001",
            width: "91",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "207",
            y: "66.0001",
            width: "91",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "113.3",
            y: "80.3001",
            width: "61.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#B3B9C4",
            "stroke-width": "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#626F86",
            xmlSpace: "preserve",
            "white-space": "pre",
            "font-size": "9",
            "letter-spacing": "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "123.26",
            y: "91.1994"
        }, formatMessage((0, (0, parcelRequire("kqOSb")).messages).nudgeCommentButton))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "181.892",
            y: "80.3001",
            width: "60.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#B3B9C4",
            "stroke-width": "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#626F86",
            xmlSpace: "preserve",
            "white-space": "pre",
            "font-size": "9",
            "letter-spacing": "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "191.414",
            y: "91.1994"
        }, formatMessage((0, (0, parcelRequire("kqOSb")).messages).nudgeEditIssueButton))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "249.485",
            y: "80.3001",
            width: "70.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#B3B9C4",
            "stroke-width": "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#626F86",
            xmlSpace: "preserve",
            "white-space": "pre",
            "font-size": "9",
            "letter-spacing": "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "259.007",
            y: "91.1994"
        }, formatMessage((0, (0, parcelRequire("kqOSb")).messages).nudgeOpenInJiraButton)))), (0, ($parcel$interopDefault($5uXOq))).createElement("defs", null, (0, ($parcel$interopDefault($5uXOq))).createElement("filter", {
            id: "filter0_dd_2726_7335",
            x: "39",
            y: "10",
            width: "334",
            height: "108",
            filterUnits: "userSpaceOnUse",
            "color-interpolation-filters": "sRGB"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("feFlood", {
            "flood-opacity": "0",
            result: "BackgroundImageFix"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            in: "SourceAlpha",
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
            result: "hardAlpha"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feOffset", null), (0, ($parcel$interopDefault($5uXOq))).createElement("feGaussianBlur", {
            stdDeviation: "4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            type: "matrix",
            values: "0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.16 0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in2: "BackgroundImageFix",
            result: "effect1_dropShadow_2726_7335"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            in: "SourceAlpha",
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
            result: "hardAlpha"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feOffset", null), (0, ($parcel$interopDefault($5uXOq))).createElement("feGaussianBlur", {
            stdDeviation: "0.5"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            type: "matrix",
            values: "0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.12 0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in2: "effect1_dropShadow_2726_7335",
            result: "effect2_dropShadow_2726_7335"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in: "SourceGraphic",
            in2: "effect2_dropShadow_2726_7335",
            result: "shape"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("linearGradient", {
            id: "paint0_linear_2726_7335",
            x1: "408.5",
            y1: "3.50001",
            x2: "0.106745",
            y2: "128.349",
            gradientUnits: "userSpaceOnUse"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.0974484",
            "stop-color": "#B4E3FF"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.920861",
            "stop-color": "#F6F7FF"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("clipPath", {
            id: "clip0_2726_7335"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            width: "27.3",
            height: "26",
            fill: "white",
            transform: "translate(54 26.0001)"
        })))));
    };
    const $5bcddde960622926$var$BothSplashStyles = null;
});
parcelRegister("kqOSb", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$71503304df7eb883$export$defe85febe8b728c);
    var $7VHMR;
    const $71503304df7eb883$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        slackIntegrationName: {
            "id": "third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.slack-integration-name",
            "defaultMessage": "Jira Cloud"
        },
        teamsNudgeTitle: {
            "id": "third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.teams-nudge-title",
            "defaultMessage": "Mike commented on an issue"
        },
        slackNudgeTitle: {
            "id": "third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.slack-nudge-title",
            "defaultMessage": "<tag>@mike</tag> assigned an issue to you"
        },
        bothNudgeTitle: {
            "id": "third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.both-nudge-title",
            "defaultMessage": "<tag>@mike</tag> mentioned you in a comment"
        },
        nudgeCommentButton: {
            "id": "third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.nudge-comment-button",
            "defaultMessage": "Comment"
        },
        nudgeEditIssueButton: {
            "id": "third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.nudge-edit-issue-button",
            "defaultMessage": "Edit issue"
        },
        nudgeOpenInJiraButton: {
            "id": "third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.nudge-open-in-jira-button",
            "defaultMessage": "Open in Jira"
        }
    });
});
parcelRegister("bIM5T", function(module, exports) {
    $parcel$export(module.exports, "NotificationBellSlackSplash", ()=>$62b6bc7502f8263f$export$b815853e36ed5a84);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $ousew;
    var $koVbs;
    var $kqOSb;
    const $62b6bc7502f8263f$export$b815853e36ed5a84 = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            "data-testid": "third-party-nudge.ui.third-party-nudge-flag.splashes.notification-bell-splashes.slack-splash",
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i1qr7"
            ])
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            width: "412",
            height: "128",
            viewBox: "0 0 412 128",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M0 4C0 1.79086 1.79086 0 4 0H408C410.209 0 412 1.79086 412 4V128H0V4Z",
            fill: "url(#paint0_linear_2726_7334)"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            filter: "url(#filter0_dd_2726_7334)"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M99 22.47C99 21.1059 100.106 20 101.47 20H364.53C365.894 20 367 21.1059 367 22.47V105C367 107.209 365.209 109 363 109H103C100.791 109 99 107.209 99 105V22.47Z",
            fill: "white",
            "fill-opacity": "0.98"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            width: "24",
            height: "24",
            transform: "translate(111 28)",
            fill: "white",
            "fill-opacity": "0.01"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M131.846 30.25H122.543C122.543 32.4958 124.425 34.3238 126.737 34.3238H128.458V35.9168C128.458 38.1626 130.34 39.9906 132.652 39.9906V31.0334C132.652 30.5895 132.303 30.25 131.846 30.25Z",
            fill: "#2684FF"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M127.248 34.7416H117.945C117.945 36.9874 119.827 38.8154 122.14 38.8154H123.86V40.4345C123.86 42.6803 125.742 44.5083 128.055 44.5083V35.525C128.055 35.1072 127.705 34.7416 127.248 34.7416Z",
            fill: "url(#paint1_linear_2726_7334)"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M122.651 39.2594H113.348C113.348 41.5052 115.23 43.3332 117.542 43.3332H119.263V44.9262C119.263 47.172 121.145 49 123.457 49V40.0428C123.457 39.5989 123.081 39.2594 122.651 39.2594Z",
            fill: "url(#paint2_linear_2726_7334)"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#091E42",
            xmlSpace: "preserve",
            "white-space": "pre",
            "font-size": "8",
            "font-weight": "bold",
            "letter-spacing": "0em"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "138",
            y: "35.8438"
        }, formatMessage((0, (0, parcelRequire("kqOSb")).messages).slackIntegrationName))), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#091E42",
            xmlSpace: "preserve",
            fontSize: "9",
            fontWeight: "600",
            letterSpacing: "0em"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "138",
            y: "51.87"
        }, "\uD83D\uDD14")), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#091E42",
            xmlSpace: "preserve",
            fontSize: "9",
            fontWeight: "600",
            letterSpacing: "0em"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "150",
            y: "50.6992"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), {
            ...(0, (0, parcelRequire("kqOSb")).messages).slackNudgeTitle,
            values: {
                tag: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
                        fill: "#0055CC",
                        xmlSpace: "preserve",
                        fontSize: "9",
                        fontWeight: "600",
                        letterSpacing: "0em"
                    }, chunks)
            }
        }))), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M142 75C142 76.1046 141.105 77 140 77C138.895 77 138 76.1046 138 75L138 60C138 58.8954 138.895 58 140 58C141.105 58 142 58.8954 142 60L142 75Z",
            fill: "#2F78FC"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "150",
            y: "58.4999",
            width: "181",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "150",
            y: "70.5002",
            width: "72",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "226",
            y: "70.5002",
            width: "72",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "137.3",
            y: "85.3",
            width: "61.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#B3B9C4",
            "stroke-width": "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#626F86",
            xmlSpace: "preserve",
            "white-space": "pre",
            "font-size": "9",
            "letter-spacing": "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "147.26",
            y: "96.1992"
        }, formatMessage((0, (0, parcelRequire("kqOSb")).messages).nudgeCommentButton))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "205.892",
            y: "85.3",
            width: "60.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#B3B9C4",
            "stroke-width": "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#626F86",
            xmlSpace: "preserve",
            "white-space": "pre",
            "font-size": "9",
            "letter-spacing": "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "215.414",
            y: "96.1992"
        }, formatMessage((0, (0, parcelRequire("kqOSb")).messages).nudgeEditIssueButton))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "273.485",
            y: "85.3",
            width: "70.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#B3B9C4",
            "stroke-width": "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#626F86",
            xmlSpace: "preserve",
            "white-space": "pre",
            "font-size": "9",
            "letter-spacing": "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "282.992",
            y: "96.1992"
        }, formatMessage((0, (0, parcelRequire("kqOSb")).messages).nudgeOpenInJiraButton))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "45",
            y: "20",
            width: "42",
            height: "42",
            rx: "4",
            fill: "white"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M58.4623 44.4302C58.4623 45.9334 57.2343 47.1614 55.7311 47.1614C54.2278 47.1614 52.9998 45.9334 52.9998 44.4302C52.9998 42.9269 54.2278 41.6989 55.7311 41.6989H58.4623V44.4302Z",
            fill: "#E01E5A"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M59.8377 44.4303C59.8377 42.927 61.0657 41.699 62.569 41.699C64.0722 41.699 65.3002 42.927 65.3002 44.4303V51.269C65.3002 52.7722 64.0722 54.0002 62.569 54.0002C61.0657 54.0002 59.8377 52.7722 59.8377 51.269V44.4303Z",
            fill: "#E01E5A"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M62.569 33.4624C61.0658 33.4624 59.8378 32.2344 59.8378 30.7311C59.8378 29.2279 61.0658 27.9999 62.569 27.9999C64.0723 27.9999 65.3003 29.2279 65.3003 30.7311V33.4624H62.569Z",
            fill: "#36C5F0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M62.5698 34.8385C64.0731 34.8385 65.3011 36.0665 65.3011 37.5697C65.3011 39.073 64.0731 40.301 62.5698 40.301H55.7311C54.2278 40.301 52.9998 39.073 52.9998 37.5697C52.9998 36.0665 54.2278 34.8385 55.7311 34.8385H62.5698Z",
            fill: "#36C5F0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M73.5365 37.5697C73.5365 36.0664 74.7645 34.8384 76.2678 34.8384C77.771 34.8384 78.999 36.0664 78.999 37.5697C78.999 39.0729 77.771 40.3009 76.2678 40.3009H73.5365V37.5697Z",
            fill: "#2EB67D"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M72.1611 37.5698C72.1611 39.0731 70.9331 40.3011 69.4299 40.3011C67.9266 40.3011 66.6986 39.0731 66.6986 37.5698V30.7311C66.6986 29.2279 67.9266 27.9999 69.4299 27.9999C70.9331 27.9999 72.1611 29.2279 72.1611 30.7311V37.5698Z",
            fill: "#2EB67D"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M69.4297 48.5378C70.933 48.5378 72.161 49.7658 72.161 51.2691C72.161 52.7723 70.933 54.0003 69.4297 54.0003C67.9265 54.0003 66.6985 52.7723 66.6985 51.2691V48.5378H69.4297Z",
            fill: "#ECB22E"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M69.4299 47.1614C67.9266 47.1614 66.6986 45.9334 66.6986 44.4302C66.6986 42.9269 67.9266 41.6989 69.4299 41.6989H76.2686C77.7719 41.6989 78.9999 42.9269 78.9999 44.4302C78.9999 45.9334 77.7719 47.1614 76.2686 47.1614H69.4299Z",
            fill: "#ECB22E"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("defs", null, (0, ($parcel$interopDefault($5uXOq))).createElement("filter", {
            id: "filter0_dd_2726_7334",
            x: "37",
            y: "12",
            width: "338",
            height: "105",
            filterUnits: "userSpaceOnUse",
            "color-interpolation-filters": "sRGB"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("feFlood", {
            "flood-opacity": "0",
            result: "BackgroundImageFix"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            in: "SourceAlpha",
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
            result: "hardAlpha"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feOffset", null), (0, ($parcel$interopDefault($5uXOq))).createElement("feGaussianBlur", {
            stdDeviation: "4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            type: "matrix",
            values: "0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.16 0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in2: "BackgroundImageFix",
            result: "effect1_dropShadow_2726_7334"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            in: "SourceAlpha",
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
            result: "hardAlpha"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feOffset", null), (0, ($parcel$interopDefault($5uXOq))).createElement("feGaussianBlur", {
            stdDeviation: "0.5"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            type: "matrix",
            values: "0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.12 0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in2: "effect1_dropShadow_2726_7334",
            result: "effect2_dropShadow_2726_7334"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in: "SourceGraphic",
            in2: "effect2_dropShadow_2726_7334",
            result: "shape"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("linearGradient", {
            id: "paint0_linear_2726_7334",
            x1: "408.5",
            y1: "3.50001",
            x2: "0.106745",
            y2: "128.349",
            gradientUnits: "userSpaceOnUse"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.0974484",
            "stop-color": "#B4E3FF"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.920861",
            "stop-color": "#F6F7FF"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("linearGradient", {
            id: "paint1_linear_2726_7334",
            x1: "127.857",
            y1: "34.7642",
            x2: "124.02",
            y2: "38.8376",
            gradientUnits: "userSpaceOnUse"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.176",
            "stop-color": "#0052CC"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "1",
            "stop-color": "#2684FF"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("linearGradient", {
            id: "paint2_linear_2726_7334",
            x1: "123.518",
            y1: "39.2894",
            x2: "119.074",
            y2: "43.7406",
            gradientUnits: "userSpaceOnUse"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.176",
            "stop-color": "#0052CC"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "1",
            "stop-color": "#2684FF"
        })))));
    };
    const $62b6bc7502f8263f$var$SlackSplashStyles = null;
});
parcelRegister("7Y58S", function(module, exports) {
    $parcel$export(module.exports, "NotificationBellTeamsSplash", ()=>$fa0d6e3d56d6b893$export$a3eacc2159f7bab8);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $koVbs;
    var $kqOSb;
    const $fa0d6e3d56d6b893$export$a3eacc2159f7bab8 = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            "data-testid": "third-party-nudge.ui.third-party-nudge-flag.splashes.notification-bell-splashes.teams-splash",
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i1qr7"
            ])
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            width: "412",
            height: "128",
            viewBox: "0 0 412 128",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M0 4C0 1.79086 1.79086 0 4 0H408C410.209 0 412 1.79086 412 4V128H0V4Z",
            fill: "url(#paint0_linear_2726_7333)"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            filter: "url(#filter0_dd_2726_7333)"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            opacity: "0.98"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M112 26.4701C112 25.106 113.106 24.0001 114.47 24.0001H357.53C358.894 24.0001 360 25.106 360 26.4701V100C360 102.209 358.209 104 356 104H116C113.791 104 112 102.209 112 100V26.4701Z",
            fill: "white"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#091E42",
            xmlSpace: "preserve",
            "white-space": "pre",
            "font-size": "9",
            "letter-spacing": "0em"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "124",
            y: "45.1994"
        }, formatMessage((0, (0, parcelRequire("kqOSb")).messages).teamsNudgeTitle))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "124",
            y: "54",
            width: "210",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "124",
            y: "66",
            width: "91",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "218",
            y: "66",
            width: "91",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "124.3",
            y: "80.3001",
            width: "61.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#DFD8FD",
            "stroke-width": "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#5E4DB2",
            xmlSpace: "preserve",
            "white-space": "pre",
            "font-size": "9",
            "letter-spacing": "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "134.26",
            y: "91.1994"
        }, formatMessage((0, (0, parcelRequire("kqOSb")).messages).nudgeCommentButton))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "192.892",
            y: "80.3001",
            width: "60.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#DFD8FD",
            "stroke-width": "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#5E4DB2",
            xmlSpace: "preserve",
            "white-space": "pre",
            "font-size": "9",
            "letter-spacing": "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "202.414",
            y: "91.1994"
        }, formatMessage((0, (0, parcelRequire("kqOSb")).messages).nudgeEditIssueButton))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "260.485",
            y: "80.3001",
            width: "70.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#DFD8FD",
            "stroke-width": "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#5E4DB2",
            xmlSpace: "preserve",
            "white-space": "pre",
            "font-size": "9",
            "letter-spacing": "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "269.992",
            y: "91.1994"
        }, formatMessage((0, (0, parcelRequire("kqOSb")).messages).nudgeOpenInJiraButton)))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "58",
            y: "28",
            width: "42",
            height: "42",
            rx: "4",
            fill: "white"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            "clip-path": "url(#clip0_2726_7333)"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M79.42 44.6668C81.582 44.6668 83.3346 42.9691 83.3346 40.8749C83.3346 38.7806 81.582 37.0829 79.42 37.0829C77.258 37.0829 75.5053 38.7806 75.5053 40.8749C75.5053 42.9691 77.258 44.6668 79.42 44.6668Z",
            fill: "#7B83EB"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.1",
            d: "M79.1404 41.6875H75.5948L75.6125 41.7631L75.6141 41.7696L75.6326 41.8395C75.8902 42.7858 76.5153 43.5991 77.3773 44.1096C78.2393 44.6202 79.2715 44.7884 80.2585 44.5792V42.7706C80.2577 42.4836 80.1396 42.2086 79.9301 42.0057C79.7206 41.8028 79.4367 41.6884 79.1404 41.6875Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M78.3021 42.5H75.883C76.2005 43.1484 76.7014 43.6962 77.3275 44.0798C77.9537 44.4634 78.6793 44.667 79.4202 44.667V43.5831C79.4193 43.2961 79.3012 43.0211 79.0917 42.8182C78.8822 42.6153 78.5983 42.5009 78.3021 42.5Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M88.2273 44.9375C89.8487 44.9375 91.1631 43.6643 91.1631 42.0938C91.1631 40.5232 89.8487 39.25 88.2273 39.25C86.6059 39.25 85.2915 40.5232 85.2915 42.0938C85.2915 43.6643 86.6059 44.9375 88.2273 44.9375Z",
            fill: "#5059C9"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M90.7439 45.75H84.1501C84.0077 45.75 83.8712 45.8048 83.7705 45.9023C83.6698 45.9998 83.6133 46.1321 83.6133 46.27V52.8594C83.5951 53.8131 83.9157 54.7438 84.5215 55.4958C85.1273 56.2478 85.9814 56.7754 86.9408 56.9901C87.5518 57.1143 88.1838 57.105 88.7906 56.9627C89.3975 56.8205 89.9639 56.549 90.4487 56.1679C90.9336 55.7868 91.3246 55.3058 91.5933 54.7599C91.862 54.214 92.0016 53.6169 92.002 53.0121V46.9688C92.002 46.6455 91.8695 46.3355 91.6335 46.107C91.3976 45.8784 91.0775 45.75 90.7439 45.75Z",
            fill: "#5059C9"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M86.1305 46.9688V54.6875C86.1313 56.118 85.6447 57.5087 84.7461 58.6438C83.8476 59.779 82.5873 60.5951 81.1609 60.9655C79.7346 61.3359 78.2219 61.2399 76.8576 60.6924C75.4933 60.1449 74.3537 59.1765 73.6157 57.9375C73.4543 57.6777 73.3141 57.406 73.1963 57.125C73.0866 56.8601 72.9941 56.5887 72.9195 56.3125C72.7813 55.7812 72.7108 55.2355 72.7098 54.6875V46.9688C72.7096 46.8087 72.742 46.6501 72.8052 46.5021C72.8683 46.3542 72.961 46.2197 73.0779 46.1065C73.1947 45.9933 73.3335 45.9035 73.4863 45.8424C73.639 45.7812 73.8027 45.7498 73.968 45.75H84.8723C85.0376 45.7498 85.2013 45.7812 85.3541 45.8424C85.5068 45.9035 85.6456 45.9933 85.7625 46.1065C85.8794 46.2197 85.972 46.3542 86.0352 46.5021C86.0983 46.6501 86.1307 46.8087 86.1305 46.9688Z",
            fill: "#7B83EB"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M78.3021 42.5H75.883C76.2005 43.1484 76.7014 43.6962 77.3275 44.0798C77.9537 44.4634 78.6793 44.667 79.4202 44.667V43.5831C79.4193 43.2961 79.3012 43.0211 79.0917 42.8182C78.8822 42.6153 78.5983 42.5009 78.3021 42.5Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.1",
            d: "M80.259 45.75V56.0444C80.259 56.2893 80.1724 56.5269 80.0137 56.7176C79.855 56.9082 79.6337 57.0406 79.3866 57.0925C79.3077 57.1153 79.2257 57.1262 79.1434 57.125H73.1963C73.0866 56.8601 72.9941 56.5887 72.9195 56.3125C72.7813 55.7812 72.7108 55.2355 72.7098 54.6875V46.9688C72.7096 46.8087 72.742 46.6501 72.8052 46.5021C72.8683 46.3542 72.961 46.2197 73.0779 46.1065C73.1947 45.9933 73.3335 45.9035 73.4863 45.8424C73.639 45.7812 73.8027 45.7498 73.968 45.75H80.259Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M79.4202 45.75V56.8569C79.4214 56.9366 79.4101 57.0161 79.3866 57.0925C79.333 57.3319 79.1964 57.5462 78.9995 57.7C78.8027 57.8537 78.5574 57.9375 78.3046 57.9375H73.6157C73.4543 57.6777 73.3141 57.406 73.1963 57.125C73.0866 56.8601 72.9941 56.5887 72.9195 56.3125C72.7813 55.7812 72.7108 55.2355 72.7098 54.6875V46.9688C72.7096 46.8087 72.742 46.6501 72.8052 46.5021C72.8683 46.3542 72.961 46.2197 73.0779 46.1065C73.1947 45.9933 73.3335 45.9035 73.4863 45.8424C73.639 45.7812 73.8027 45.7498 73.968 45.75H79.4202Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M79.4202 45.75V55.2319C79.4189 55.5181 79.3009 55.7922 79.092 55.9946C78.883 56.197 78.6001 56.3112 78.3046 56.3125H72.9195C72.7813 55.7812 72.7108 55.2355 72.7098 54.6875V46.9688C72.7096 46.8087 72.742 46.6501 72.8052 46.5021C72.8683 46.3542 72.961 46.2197 73.0779 46.1065C73.1947 45.9933 73.3335 45.9035 73.4863 45.8424C73.639 45.7812 73.8027 45.7498 73.968 45.75H79.4202Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M73.968 45.75C73.8027 45.7498 73.639 45.7812 73.4863 45.8424C73.3335 45.9035 73.1947 45.9933 73.0779 46.1065C72.961 46.2197 72.8683 46.3542 72.8052 46.5021C72.742 46.6501 72.7096 46.8087 72.7098 46.9688V54.6875C72.7108 55.2355 72.7813 55.7812 72.9195 56.3125H77.4658C77.7613 56.3112 78.0442 56.197 78.2532 55.9946C78.4621 55.7922 78.5801 55.5181 78.5814 55.2319V45.75H73.968Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M66.2787 42.5H77.4632C77.7597 42.5 78.0441 42.6141 78.2538 42.8172C78.4635 43.0204 78.5813 43.2958 78.5813 43.5831V54.417C78.5813 54.7042 78.4635 54.9797 78.2538 55.1828C78.0441 55.3859 77.7597 55.5 77.4632 55.5H66.2787C65.9822 55.5 65.6978 55.3859 65.4881 55.1828C65.2784 54.9797 65.1606 54.7042 65.1606 54.417V43.5831C65.1606 43.2958 65.2784 43.0204 65.4881 42.8172C65.6978 42.6141 65.9822 42.5 66.2787 42.5Z",
            fill: "#4B53BC"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M75.2092 46.5419H72.7012V53.0582H71.0555V46.5419H68.5324V44.9413H75.2092V46.5419Z",
            fill: "white"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M357.527 24C358.893 24 360 25.1073 360 26.4731L360 28L112 28L112 26.4731C112 25.1073 113.107 24 114.473 24L357.527 24Z",
            fill: "#505AC6"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("defs", null, (0, ($parcel$interopDefault($5uXOq))).createElement("filter", {
            id: "filter0_dd_2726_7333",
            x: "50",
            y: "16",
            width: "318",
            height: "96.0001",
            filterUnits: "userSpaceOnUse",
            "color-interpolation-filters": "sRGB"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("feFlood", {
            "flood-opacity": "0",
            result: "BackgroundImageFix"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            in: "SourceAlpha",
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
            result: "hardAlpha"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feOffset", null), (0, ($parcel$interopDefault($5uXOq))).createElement("feGaussianBlur", {
            stdDeviation: "4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            type: "matrix",
            values: "0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.16 0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in2: "BackgroundImageFix",
            result: "effect1_dropShadow_2726_7333"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            in: "SourceAlpha",
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
            result: "hardAlpha"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feOffset", null), (0, ($parcel$interopDefault($5uXOq))).createElement("feGaussianBlur", {
            stdDeviation: "0.5"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            type: "matrix",
            values: "0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.12 0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in2: "effect1_dropShadow_2726_7333",
            result: "effect2_dropShadow_2726_7333"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in: "SourceGraphic",
            in2: "effect2_dropShadow_2726_7333",
            result: "shape"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("linearGradient", {
            id: "paint0_linear_2726_7333",
            x1: "408.5",
            y1: "3.50001",
            x2: "0.106745",
            y2: "128.349",
            gradientUnits: "userSpaceOnUse"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.0974484",
            "stop-color": "#C6E8FF"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.920861",
            "stop-color": "#F6F7FF"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("clipPath", {
            id: "clip0_2726_7333"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "65",
            y: "36",
            width: "27.3",
            height: "26",
            fill: "white"
        })))));
    };
    const $fa0d6e3d56d6b893$var$TeamsSplashStyles = null;
});
parcelRegister("eKIMg", function(module, exports) {
    $parcel$export(module.exports, "SlackSplash", ()=>$d26ae462bd104bd2$export$82a1eb5d90727e6c);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $ousew;
    var $koVbs;
    var $1CrUW;
    const $d26ae462bd104bd2$export$82a1eb5d90727e6c = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement($d26ae462bd104bd2$var$SlackSplashWrapper, {
            "data-testid": "third-party-nudge.ui.third-party-nudge-flag.splashes.slack-splash.slack-splash-wrapper"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            width: "412",
            height: "128",
            viewBox: "0 0 412 128",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M0 4C0 1.79086 1.79086 0 4 0H408C410.209 0 412 1.79086 412 4V128H0V4Z",
            fill: "url(#paint0_linear_914_2569)"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            filter: "url(#filter0_dd_914_2569)"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M99 22.47C99 21.1059 100.106 20 101.47 20H364.53C365.894 20 367 21.1059 367 22.47V105C367 107.209 365.209 109 363 109H103C100.791 109 99 107.209 99 105V22.47Z",
            fill: "white",
            fillOpacity: "0.98"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            width: "24",
            height: "24",
            transform: "translate(111 28)",
            fill: "white",
            fillOpacity: "0.01"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M131.846 30.25H122.543C122.543 32.4958 124.425 34.3238 126.737 34.3238H128.458V35.9168C128.458 38.1626 130.34 39.9906 132.652 39.9906V31.0334C132.652 30.5895 132.303 30.25 131.846 30.25Z",
            fill: "#2684FF"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M127.248 34.7416H117.945C117.945 36.9875 119.827 38.8155 122.14 38.8155H123.86V40.4345C123.86 42.6804 125.742 44.5084 128.055 44.5084V35.5251C128.055 35.1072 127.705 34.7416 127.248 34.7416Z",
            fill: "url(#paint1_linear_914_2569)"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M122.65 39.2594H113.348C113.348 41.5052 115.23 43.3332 117.542 43.3332H119.263V44.9262C119.263 47.172 121.145 49 123.457 49V40.0428C123.457 39.5989 123.081 39.2594 122.65 39.2594Z",
            fill: "url(#paint2_linear_914_2569)"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#091E42",
            xmlSpace: "preserve",
            fontSize: "8",
            fontWeight: "bold",
            letterSpacing: "0em"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "138",
            y: "35.8438"
        }, formatMessage((0, (0, parcelRequire("1CrUW")).messages).slackExampleIntegrationName))), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#091E42",
            xmlSpace: "preserve",
            fontSize: "9",
            fontWeight: "600",
            letterSpacing: "0em"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "138",
            y: "51.87"
        }, "\uD83D\uDD14")), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#091E42",
            xmlSpace: "preserve",
            fontSize: "9",
            fontWeight: "600",
            letterSpacing: "0em"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "150",
            y: "50.6992"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), {
            ...(0, (0, parcelRequire("1CrUW")).messages).slackExampleTitle,
            values: {
                tag: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
                        fill: "#0055CC",
                        xmlSpace: "preserve",
                        fontSize: "9",
                        fontWeight: "600",
                        letterSpacing: "0em"
                    }, chunks)
            }
        }))), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M142 75C142 76.1046 141.105 77 140 77C138.895 77 138 76.1046 138 75L138 60C138 58.8954 138.895 58 140 58C141.105 58 142 58.8954 142 60L142 75Z",
            fill: "#2F78FC"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "150",
            y: "58.4999",
            width: "181",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "150",
            y: "70.5002",
            width: "72",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "226",
            y: "70.5002",
            width: "72",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "137.3",
            y: "85.3",
            width: "61.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#B3B9C4",
            strokeWidth: "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#626F86",
            xmlSpace: "preserve",
            fontSize: "9",
            fontWeight: "500",
            letterSpacing: "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "147.26",
            y: "96.1992"
        }, formatMessage((0, (0, parcelRequire("1CrUW")).messages).integrationExampleCommentButton))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "205.893",
            y: "85.3",
            width: "60.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#B3B9C4",
            strokeWidth: "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#626F86",
            xmlSpace: "preserve",
            fontSize: "9",
            fontWeight: "500",
            letterSpacing: "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "215.414",
            y: "96.1992"
        }, formatMessage((0, (0, parcelRequire("1CrUW")).messages).integrationExampleEditIssueButton))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "273.485",
            y: "85.3",
            width: "70.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#B3B9C4",
            strokeWidth: "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#626F86",
            xmlSpace: "preserve",
            fontSize: "9",
            fontWeight: "500",
            letterSpacing: "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "282.992",
            y: "96.1992"
        }, formatMessage((0, (0, parcelRequire("1CrUW")).messages).integrationExampleOpenInJiraButton))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "45",
            y: "20",
            width: "42",
            height: "42",
            rx: "4",
            fill: "white"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M58.4623 44.4302C58.4623 45.9334 57.2343 47.1614 55.731 47.1614C54.2278 47.1614 52.9998 45.9334 52.9998 44.4302C52.9998 42.9269 54.2278 41.6989 55.731 41.6989H58.4623V44.4302Z",
            fill: "#E01E5A"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M59.8376 44.4302C59.8376 42.927 61.0657 41.699 62.5689 41.699C64.0722 41.699 65.3002 42.927 65.3002 44.4302V51.269C65.3002 52.7722 64.0722 54.0002 62.5689 54.0002C61.0657 54.0002 59.8376 52.7722 59.8376 51.269V44.4302Z",
            fill: "#E01E5A"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M62.5689 33.4624C61.0657 33.4624 59.8376 32.2344 59.8376 30.7311C59.8376 29.2279 61.0657 27.9999 62.5689 27.9999C64.0722 27.9999 65.3002 29.2279 65.3002 30.7311V33.4624H62.5689Z",
            fill: "#36C5F0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M62.5697 34.8384C64.073 34.8384 65.301 36.0664 65.301 37.5697C65.301 39.0729 64.073 40.301 62.5697 40.301H55.731C54.2278 40.301 52.9998 39.0729 52.9998 37.5697C52.9998 36.0664 54.2278 34.8384 55.731 34.8384H62.5697Z",
            fill: "#36C5F0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M73.5364 37.5697C73.5364 36.0664 74.7644 34.8384 76.2676 34.8384C77.7709 34.8384 78.9989 36.0664 78.9989 37.5697C78.9989 39.0729 77.7709 40.301 76.2676 40.301H73.5364V37.5697Z",
            fill: "#2EB67D"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M72.161 37.5699C72.161 39.0731 70.933 40.3011 69.4297 40.3011C67.9265 40.3011 66.6985 39.0731 66.6985 37.5699V30.7311C66.6985 29.2279 67.9265 27.9999 69.4297 27.9999C70.933 27.9999 72.161 29.2279 72.161 30.7311V37.5699Z",
            fill: "#2EB67D"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M69.4295 48.5378C70.9328 48.5378 72.1608 49.7658 72.1608 51.2691C72.1608 52.7724 70.9328 54.0004 69.4295 54.0004C67.9262 54.0004 66.6982 52.7724 66.6982 51.2691V48.5378H69.4295Z",
            fill: "#ECB22E"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M69.4297 47.1614C67.9265 47.1614 66.6985 45.9334 66.6985 44.4302C66.6985 42.9269 67.9265 41.6989 69.4297 41.6989H76.2685C77.7717 41.6989 78.9997 42.9269 78.9997 44.4302C78.9997 45.9334 77.7717 47.1614 76.2685 47.1614H69.4297Z",
            fill: "#ECB22E"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("defs", null, (0, ($parcel$interopDefault($5uXOq))).createElement("filter", {
            id: "filter0_dd_914_2569",
            x: "33",
            y: "8",
            width: "346",
            height: "113",
            filterUnits: "userSpaceOnUse",
            colorInterpolationFilters: "sRGB"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("feFlood", {
            floodOpacity: "0",
            result: "BackgroundImageFix"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            in: "SourceAlpha",
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
            result: "hardAlpha"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feOffset", null), (0, ($parcel$interopDefault($5uXOq))).createElement("feGaussianBlur", {
            stdDeviation: "6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            type: "matrix",
            values: "0 0 0 0 0.0117647 0 0 0 0 0.0156863 0 0 0 0 0.0156863 0 0 0 0.56 0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in2: "BackgroundImageFix",
            result: "effect1_dropShadow_914_2569"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            in: "SourceAlpha",
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
            result: "hardAlpha"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feOffset", null), (0, ($parcel$interopDefault($5uXOq))).createElement("feGaussianBlur", {
            stdDeviation: "0.5"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            type: "matrix",
            values: "0 0 0 0 0.0117647 0 0 0 0 0.0156863 0 0 0 0 0.0156863 0 0 0 0.5 0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in2: "effect1_dropShadow_914_2569",
            result: "effect2_dropShadow_914_2569"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in: "SourceGraphic",
            in2: "effect2_dropShadow_914_2569",
            result: "shape"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("linearGradient", {
            id: "paint0_linear_914_2569",
            x1: "408.5",
            y1: "3.50001",
            x2: "0.106745",
            y2: "128.349",
            gradientUnits: "userSpaceOnUse"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.0974484",
            stopColor: "#042C86"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.920861",
            stopColor: "#0744A2"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("linearGradient", {
            id: "paint1_linear_914_2569",
            x1: "127.857",
            y1: "34.7642",
            x2: "124.02",
            y2: "38.8376",
            gradientUnits: "userSpaceOnUse"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.176",
            stopColor: "#0052CC"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "1",
            stopColor: "#2684FF"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("linearGradient", {
            id: "paint2_linear_914_2569",
            x1: "123.518",
            y1: "39.2894",
            x2: "119.074",
            y2: "43.7406",
            gradientUnits: "userSpaceOnUse"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.176",
            stopColor: "#0052CC"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "1",
            stopColor: "#2684FF"
        })))));
    };
    const $d26ae462bd104bd2$var$SlackSplashWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i1qr7",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("cOlLa", function(module, exports) {
    $parcel$export(module.exports, "TeamsSplash", ()=>$a72ff0b0cc56a99a$export$ff18071b37ad7926);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $koVbs;
    var $1CrUW;
    const $a72ff0b0cc56a99a$export$ff18071b37ad7926 = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement($a72ff0b0cc56a99a$var$TeamsSplashWrapper, {
            "data-testid": "third-party-nudge.ui.third-party-nudge-flag.splashes.teams-splash.teams-splash-wrapper"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            width: "412",
            height: "128",
            viewBox: "0 0 412 128",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M0 4C0 1.79086 1.79086 0 4 0H408C410.209 0 412 1.79086 412 4V128H0V4Z",
            fill: "url(#paint0_linear_914_2408)"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            filter: "url(#filter0_dd_914_2408)"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            opacity: "0.98"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M112 26.4701C112 25.106 113.106 24.0001 114.47 24.0001H357.53C358.894 24.0001 360 25.106 360 26.4701V100C360 102.209 358.209 104 356 104H116C113.791 104 112 102.209 112 100V26.4701Z",
            fill: "white"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#091E42",
            xmlSpace: "preserve",
            fontSize: "9",
            fontWeight: "600",
            letterSpacing: "0em"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "124",
            y: "45.1993"
        }, formatMessage((0, (0, parcelRequire("1CrUW")).messages).teamsExampleTitle))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "124",
            y: "54",
            width: "210",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "124",
            y: "66",
            width: "91",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "218",
            y: "66",
            width: "91",
            height: "6",
            rx: "3",
            fill: "#DCDFE4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "124.3",
            y: "80.3001",
            width: "61.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#DFD8FD",
            strokeWidth: "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#5E4DB2",
            xmlSpace: "preserve",
            fontSize: "9",
            fontWeight: "500",
            letterSpacing: "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "134.26",
            y: "91.1993"
        }, formatMessage((0, (0, parcelRequire("1CrUW")).messages).integrationExampleCommentButton))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "192.893",
            y: "80.3001",
            width: "60.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#DFD8FD",
            strokeWidth: "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#5E4DB2",
            xmlSpace: "preserve",
            fontSize: "9",
            fontWeight: "500",
            letterSpacing: "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "202.414",
            y: "91.1993"
        }, formatMessage((0, (0, parcelRequire("1CrUW")).messages).integrationExampleEditIssueButton))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "260.485",
            y: "80.3001",
            width: "70.9924",
            height: "15.4",
            rx: "1.48113",
            stroke: "#DFD8FD",
            strokeWidth: "0.6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("text", {
            fill: "#5E4DB2",
            xmlSpace: "preserve",
            fontSize: "9",
            fontWeight: "500",
            letterSpacing: "0px"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("tspan", {
            x: "269.992",
            y: "91.1993"
        }, formatMessage((0, (0, parcelRequire("1CrUW")).messages).integrationExampleOpenInJiraButton)))), (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "58",
            y: "28",
            width: "42",
            height: "42",
            rx: "4",
            fill: "white"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            clipPath: "url(#clip0_914_2408)"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M79.42 44.6668C81.582 44.6668 83.3347 42.9691 83.3347 40.8748C83.3347 38.7806 81.582 37.0829 79.42 37.0829C77.258 37.0829 75.5054 38.7806 75.5054 40.8748C75.5054 42.9691 77.258 44.6668 79.42 44.6668Z",
            fill: "#7B83EB"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.1",
            d: "M79.1405 41.6875H75.595L75.6126 41.7631L75.6143 41.7696L75.6327 41.8394C75.8903 42.7858 76.5154 43.5991 77.3774 44.1096C78.2394 44.6201 79.2717 44.7884 80.2587 44.5792V42.7706C80.2578 42.4836 80.1397 42.2086 79.9302 42.0057C79.7207 41.8027 79.4368 41.6884 79.1405 41.6875Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M78.3021 42.5H75.8831C76.2005 43.1484 76.7015 43.6962 77.3276 44.0798C77.9537 44.4634 78.6794 44.667 79.4202 44.6669V43.5831C79.4194 43.2961 79.3013 43.0211 79.0918 42.8182C78.8823 42.6152 78.5984 42.5009 78.3021 42.5Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M88.2273 44.9375C89.8487 44.9375 91.1631 43.6643 91.1631 42.0938C91.1631 40.5232 89.8487 39.25 88.2273 39.25C86.6059 39.25 85.2915 40.5232 85.2915 42.0938C85.2915 43.6643 86.6059 44.9375 88.2273 44.9375Z",
            fill: "#5059C9"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M90.7439 45.75H84.1501C84.0077 45.75 83.8712 45.8048 83.7705 45.9023C83.6698 45.9998 83.6133 46.1321 83.6133 46.27V52.8594C83.5951 53.813 83.9157 54.7438 84.5215 55.4958C85.1273 56.2478 85.9814 56.7753 86.9408 56.9901C87.5518 57.1143 88.1838 57.105 88.7906 56.9627C89.3975 56.8205 89.9639 56.5489 90.4487 56.1679C90.9336 55.7868 91.3246 55.3058 91.5933 54.7599C91.862 54.214 92.0016 53.6169 92.002 53.0121V46.9688C92.002 46.6455 91.8695 46.3355 91.6335 46.107C91.3976 45.8784 91.0775 45.75 90.7439 45.75Z",
            fill: "#5059C9"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M86.1306 46.9688V54.6875C86.1315 56.1179 85.6448 57.5087 84.7463 58.6438C83.8477 59.779 82.5874 60.5951 81.1611 60.9655C79.7347 61.3359 78.222 61.2399 76.8577 60.6924C75.4934 60.1449 74.3539 59.1765 73.6159 57.9375C73.4544 57.6776 73.3142 57.406 73.1965 57.125C73.0867 56.8601 72.9942 56.5887 72.9197 56.3125C72.7814 55.7812 72.7109 55.2354 72.71 54.6875V46.9688C72.7097 46.8086 72.7421 46.6501 72.8053 46.5021C72.8684 46.3541 72.9611 46.2197 73.078 46.1065C73.1949 45.9933 73.3337 45.9035 73.4864 45.8423C73.6392 45.7812 73.8029 45.7498 73.9682 45.75H84.8725C85.0377 45.7498 85.2015 45.7812 85.3542 45.8423C85.507 45.9035 85.6457 45.9933 85.7626 46.1065C85.8795 46.2197 85.9722 46.3541 86.0353 46.5021C86.0985 46.6501 86.1309 46.8086 86.1306 46.9688Z",
            fill: "#7B83EB"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M78.3021 42.5H75.8831C76.2005 43.1484 76.7015 43.6962 77.3276 44.0798C77.9537 44.4634 78.6794 44.667 79.4202 44.6669V43.5831C79.4194 43.2961 79.3013 43.0211 79.0918 42.8182C78.8823 42.6152 78.5984 42.5009 78.3021 42.5Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.1",
            d: "M80.2591 45.75V56.0444C80.2591 56.2893 80.1726 56.5269 80.0139 56.7175C79.8552 56.9082 79.6339 57.0405 79.3867 57.0925C79.3078 57.1152 79.2258 57.1262 79.1435 57.125H73.1965C73.0867 56.8601 72.9942 56.5887 72.9197 56.3125C72.7814 55.7812 72.7109 55.2354 72.71 54.6875V46.9688C72.7097 46.8086 72.7421 46.6501 72.8053 46.5021C72.8684 46.3541 72.9611 46.2197 73.078 46.1065C73.1949 45.9933 73.3337 45.9035 73.4864 45.8423C73.6392 45.7812 73.8029 45.7498 73.9682 45.75H80.2591Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M79.4203 45.75V56.8569C79.4215 56.9366 79.4102 57.0161 79.3867 57.0925C79.3331 57.3319 79.1965 57.5462 78.9997 57.7C78.8028 57.8537 78.5576 57.9375 78.3047 57.9375H73.6159C73.4544 57.6776 73.3142 57.406 73.1965 57.125C73.0867 56.8601 72.9942 56.5887 72.9197 56.3125C72.7814 55.7812 72.7109 55.2354 72.71 54.6875V46.9688C72.7097 46.8086 72.7421 46.6501 72.8053 46.5021C72.8684 46.3541 72.9611 46.2197 73.078 46.1065C73.1949 45.9933 73.3337 45.9035 73.4864 45.8423C73.6392 45.7812 73.8029 45.7498 73.9682 45.75H79.4203Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M79.4203 45.75V55.2319C79.419 55.5181 79.301 55.7922 79.0921 55.9946C78.8832 56.197 78.6002 56.3112 78.3047 56.3125H72.9197C72.7814 55.7812 72.7109 55.2354 72.71 54.6875V46.9688C72.7097 46.8086 72.7421 46.6501 72.8053 46.5021C72.8684 46.3541 72.9611 46.2197 73.078 46.1065C73.1949 45.9933 73.3337 45.9035 73.4864 45.8423C73.6392 45.7812 73.8029 45.7498 73.9682 45.75H79.4203Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            opacity: "0.2",
            d: "M73.9682 45.75C73.8029 45.7498 73.6392 45.7812 73.4864 45.8423C73.3337 45.9035 73.1949 45.9933 73.078 46.1065C72.9611 46.2197 72.8684 46.3541 72.8053 46.5021C72.7421 46.6501 72.7097 46.8086 72.71 46.9688V54.6875C72.7109 55.2354 72.7814 55.7812 72.9197 56.3125H77.4659C77.7614 56.3112 78.0444 56.197 78.2533 55.9946C78.4622 55.7922 78.5802 55.5181 78.5815 55.2319V45.75H73.9682Z",
            fill: "black"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M66.2788 42.5H77.4632C77.7598 42.5 78.0442 42.6141 78.2538 42.8172C78.4635 43.0203 78.5813 43.2958 78.5813 43.5831V54.4169C78.5813 54.7042 78.4635 54.9797 78.2538 55.1828C78.0442 55.3859 77.7598 55.5 77.4632 55.5H66.2788C65.9822 55.5 65.6978 55.3859 65.4881 55.1828C65.2784 54.9797 65.1606 54.7042 65.1606 54.4169V43.5831C65.1606 43.2958 65.2784 43.0203 65.4881 42.8172C65.6978 42.6141 65.9822 42.5 66.2788 42.5Z",
            fill: "#4B53BC"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M75.2093 46.5419H72.7013V53.0582H71.0556V46.5419H68.5325V44.9413H75.2093V46.5419Z",
            fill: "white"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M357.527 24C358.893 24 360 25.1073 360 26.4731L360 28L112 28L112 26.4731C112 25.1072 113.107 24 114.473 24L357.527 24Z",
            fill: "#505AC6"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("defs", null, (0, ($parcel$interopDefault($5uXOq))).createElement("filter", {
            id: "filter0_dd_914_2408",
            x: "46",
            y: "12",
            width: "326",
            height: "104",
            filterUnits: "userSpaceOnUse",
            colorInterpolationFilters: "sRGB"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("feFlood", {
            floodOpacity: "0",
            result: "BackgroundImageFix"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            in: "SourceAlpha",
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
            result: "hardAlpha"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feOffset", null), (0, ($parcel$interopDefault($5uXOq))).createElement("feGaussianBlur", {
            stdDeviation: "6"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            type: "matrix",
            values: "0 0 0 0 0.0117647 0 0 0 0 0.0156863 0 0 0 0 0.0156863 0 0 0 0.56 0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in2: "BackgroundImageFix",
            result: "effect1_dropShadow_914_2408"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            in: "SourceAlpha",
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
            result: "hardAlpha"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feOffset", null), (0, ($parcel$interopDefault($5uXOq))).createElement("feGaussianBlur", {
            stdDeviation: "0.5"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feColorMatrix", {
            type: "matrix",
            values: "0 0 0 0 0.0117647 0 0 0 0 0.0156863 0 0 0 0 0.0156863 0 0 0 0.5 0"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in2: "effect1_dropShadow_914_2408",
            result: "effect2_dropShadow_914_2408"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("feBlend", {
            mode: "normal",
            in: "SourceGraphic",
            in2: "effect2_dropShadow_914_2408",
            result: "shape"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("linearGradient", {
            id: "paint0_linear_914_2408",
            x1: "408.5",
            y1: "3.50001",
            x2: "0.106745",
            y2: "128.349",
            gradientUnits: "userSpaceOnUse"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.0974484",
            stopColor: "#042C86"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("stop", {
            offset: "0.920861",
            stopColor: "#0744A2"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("clipPath", {
            id: "clip0_914_2408"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("rect", {
            x: "65",
            y: "36",
            width: "27.3",
            height: "26",
            fill: "white"
        })))));
    };
    const $a72ff0b0cc56a99a$var$TeamsSplashWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i1qr7",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("keCgG", function(module, exports) {
    $parcel$export(module.exports, "Notification", ()=>$5d149cd838a80129$export$14834abbfce3b8cd);
    var $5uXOq = parcelRequire("5uXOq");
    var $cqOtB;
    var $9Ugep;
    var $imZsD;
    var $5Fk4Z;
    var $1QiBP;
    var $dwE2v;
    var $lglOU;
    var $eGLgs;
    var $3HwlS;
    var $8zOmE;
    var $2NMNM;
    var $9OXo1;
    var $2j6pM;
    const $5d149cd838a80129$var$renderNotifications = (props, ref)=>{
        const { onViewRequests: onViewRequests, ...notificationProps } = props;
        if ((0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) {
            const { badge: badge, onClick: onClick, isSelected: isSelected, label: label } = props;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2j6pM")).Notifications), {
                badge: badge,
                label: label,
                onClick: onClick,
                isSelected: isSelected,
                ref: ref
            });
        }
        const buttonProps = {
            ...notificationProps,
            ref: ref
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cqOtB")).Notifications), buttonProps);
    };
    const $5d149cd838a80129$export$14834abbfce3b8cd = (0, $5uXOq.forwardRef)((props, ref)=>{
        const { markSpotlightAsShown: markSpotlightAsShown, hasSpotlightBeenShown: hasSpotlightBeenShown } = (0, (0, parcelRequire("dwE2v")).useUsingNotificationsToImproveAdminApprovalContext)();
        const [isSpotlightActive, setIsSpotlightActive] = (0, $5uXOq.useState)(true);
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { isSiteAdmin: isSiteAdmin } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        if (false) {
            if ((0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9Ugep")).Nav4SkeletonNotificationButton), {
                label: props.tooltip
            });
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9Ugep")).SkeletonNotificationButton), {
                label: props.tooltip
            });
        }
        const { onViewRequests: onViewRequests, ...notificationProps } = props;
        const handleSendAnalyticsEventForClickRequestSpotlightButton = (buttonType)=>{
            const analyticsEvent = createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'button'
            });
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'requestSpotlightButton', {
                buttonType: buttonType,
                isSiteAdmin: isSiteAdmin
            });
        };
        const shouldShowRequestsSpotlight = isSiteAdmin && !hasSpotlightBeenShown;
        if (!shouldShowRequestsSpotlight) return $5d149cd838a80129$var$renderNotifications(notificationProps, ref);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("imZsD")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Fk4Z")).default), {
            name: "admin-request-spotlight"
        }, $5d149cd838a80129$var$renderNotifications(notificationProps, ref)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1QiBP")).default), null, isSpotlightActive && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eGLgs")).default), {
            messageId: (0, $95839ee75937f648$export$d2ea4f931a71a509),
            messageType: "engagement"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dwE2v")).CoordinationStopProvider), null, (stop)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lglOU")).UsingNotificationsToImproveAdminApprovalSpotlight), {
                onViewRequests: ()=>{
                    setIsSpotlightActive(false);
                    markSpotlightAsShown();
                    onViewRequests && onViewRequests();
                    handleSendAnalyticsEventForClickRequestSpotlightButton('viewRequests');
                    stop && stop();
                },
                onClose: ()=>{
                    setIsSpotlightActive(false);
                    markSpotlightAsShown();
                    handleSendAnalyticsEventForClickRequestSpotlightButton('close');
                    stop && stop();
                }
            })))));
    });
});
parcelRegister("lglOU", function(module, exports) {
    $parcel$export(module.exports, "UsingNotificationsToImproveAdminApprovalSpotlight", ()=>$76067028c6c9ade1$export$aa9f9fc1c08b114f);
    var $5uXOq = parcelRequire("5uXOq");
    var $koVbs;
    var $iXVV6;
    var $5oFIy;
    var $ehdJY;
    var $9OXo1;
    var $a2BKa;
    const $76067028c6c9ade1$export$aa9f9fc1c08b114f = ({ onViewRequests: onViewRequests, onClose: onClose })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { isSiteAdmin: isSiteAdmin } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceType: (0, $4d5e0871c06cee03$export$cae2783c9279926),
            sourceName: "requestSpotlight",
            attributes: {
                isSiteAdmin: isSiteAdmin
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ehdJY")).JiraSpotlight), {
            actions: [
                {
                    onClick: onViewRequests,
                    text: formatMessage((0, (0, parcelRequire("a2BKa")).default).uniapaSpotlightViewRequestsButtonText)
                },
                {
                    onClick: onClose,
                    text: formatMessage((0, (0, parcelRequire("a2BKa")).default).uniapaSpotlightCloseButtonText),
                    appearance: 'subtle'
                }
            ],
            target: "admin-request-spotlight",
            key: "admin-request-spotlight",
            targetRadius: 100,
            dialogPlacement: "bottom right",
            dialogWidth: 275,
            targetBgColor: `var(--ds-icon-inverse, ${(0, $829f609a65d26a98$export$29a4f05edc054374)})`,
            messageId: "cross-flow-using-notifications-to-improve-admin-approval.ui.jira-spotlight.admin-request-spotlight",
            messageType: "engagement"
        }, formatMessage((0, (0, parcelRequire("a2BKa")).default).uniapaSpotlightNotificationText))));
    };
});
parcelRegister("ehdJY", function(module, exports) {
    $parcel$export(module.exports, "JiraSpotlight", ()=>$61a8dc1ff1116911$export$e209c6be9ab8c4f4);
    var $5uXOq = parcelRequire("5uXOq");
    var $7gs1W;
    var $10xFf;
    var $5NHqU;
    const $61a8dc1ff1116911$export$e209c6be9ab8c4f4 = (props)=>{
        if ((0, (0, parcelRequire("10xFf")).shouldBeChoreographed)(props)) {
            const { messageType: messageType, ...rest } = props;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5NHqU")).ChoreographerSpotlight), rest);
        }
        const { messageType: messageType, messageId: messageId, onMessageDisposition: onMessageDisposition, ...rest } = props;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7gs1W")).default), rest);
    };
});
parcelRegister("5NHqU", function(module, exports) {
    $parcel$export(module.exports, "ChoreographerSpotlight", ()=>$b7d2a49fe3b12822$export$e01943d134357d00);
    var $7gs1W;
    var $cSWNW;
    const $b7d2a49fe3b12822$export$e01943d134357d00 = (0, (0, parcelRequire("cSWNW")).withChoreographedRender)((0, (0, parcelRequire("7gs1W")).default));
});
parcelRegister("a2BKa", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$801cdcf6d45d9579$export$2e2bcd8739ae039);
    var $7VHMR;
    var $801cdcf6d45d9579$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        uniapaSpotlightNotificationText: {
            "id": "cross-flow-using-notifications-to-improve-admin-approval.uniapa-spotlight-notification-text",
            "defaultMessage": "When someone in your team requests a new product you'll get a notification here now."
        },
        uniapaSpotlightCloseButtonText: {
            "id": "cross-flow-using-notifications-to-improve-admin-approval.uniapa-spotlight-close-button-text",
            "defaultMessage": "Close"
        },
        uniapaSpotlightViewRequestsButtonText: {
            "id": "cross-flow-using-notifications-to-improve-admin-approval.uniapa-spotlight-view-requests-button-text",
            "defaultMessage": "View requests"
        }
    });
});
parcelRegister("eGLgs", function(module, exports) {
    $parcel$export(module.exports, "CoordinationClient", ()=>$754c27d37ccd1133$export$580a2294c917898c);
    $parcel$export(module.exports, "default", ()=>$754c27d37ccd1133$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $iZhen;
    var $jcYbC;
    var $8Mq6c;
    var $TTRxY;
    var $ioD1B;
    var $fG5AJ;
    var $10xFf;
    var $4Pf62;
    var $4R6GH;
    var $j8DrX;
    var $14XJw;
    var $7qPn9;
    const $754c27d37ccd1133$var$isSyntheticTenant = ()=>(0, (0, parcelRequire("j8DrX")).default)('ajs-is-synthetic') === 'true';
    class $754c27d37ccd1133$export$580a2294c917898c extends (0, (0, parcelRequire("8Mq6c")).default) {
        constructor(cloudId, stargateUrl, shouldAllowOnSynthetic = false, orgId = '', messageType = 'transactional', onMessageDisposition, isAlreadyChoreographedAtAncestor = false){
            const environment = (0, (0, parcelRequire("7qPn9")).toEnvironment)((0, (0, parcelRequire("j8DrX")).default)('ajs-environment'));
            const getEnvironmentType = (env)=>{
                if (env === 'prod') return (0, (0, parcelRequire("iZhen")).envType).PROD;
                if (env === 'staging') return (0, (0, parcelRequire("iZhen")).envType).STAGING;
                return (0, (0, parcelRequire("iZhen")).envType).DEV;
            };
            const enableChoreographer = (0, (0, parcelRequire("10xFf")).shouldBeChoreographed)({
                messageType: messageType
            }) && !isAlreadyChoreographedAtAncestor;
            const coordinationOptions = {
                enableChoreographer: enableChoreographer,
                envType: getEnvironmentType(environment),
                additionalData: {
                    sourceProductId: (0, (0, parcelRequire("TTRxY")).ProductIds).JIRA
                }
            };
            super({
                cloudId: cloudId,
                stargateUrl: stargateUrl,
                orgId: orgId,
                isFedRAMPEnvironment: false,
                coordinationOptions: coordinationOptions
            });
            this.shouldUseMockClient = $754c27d37ccd1133$var$isSyntheticTenant() && !shouldAllowOnSynthetic;
            this.enableChoreographer = enableChoreographer;
            this.onMessageDisposition = onMessageDisposition;
            this.isDebugModeEnabled = (0, (0, parcelRequire("4R6GH")).fg)('ep-external-message-debug-mode');
        }
        async start(messageId) {
            if (this.isDebugModeEnabled) {
                const serverResponse = await super.start(messageId);
                if (this.enableChoreographer) this.onMessageDisposition?.(serverResponse ? (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).STARTED : (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).BLOCKED);
                const shouldShowMessage = confirm(`\u{1F41E} \u{1D5D8}\u{1D5E3} \u{1D5D8}\u{1D605}\u{1D601}\u{1D5F2}\u{1D5FF}\u{1D5FB}\u{1D5EE}\u{1D5F9} \u{1D5E0}\u{1D5F2}\u{1D600}\u{1D600}\u{1D5EE}\u{1D5F4}\u{1D5F2} \u{1D5D7}\u{1D5F2}\u{1D5EF}\u{1D602}\u{1D5F4}\u{1D5F4}\u{1D5F2}\u{1D5FF}

Message ID: ${messageId}
Start call server response: ${serverResponse}

Do you want to display the message?`);
                if (!shouldShowMessage) {
                    const didStop = await super.stop(messageId);
                    if (this.enableChoreographer) this.onMessageDisposition?.(didStop ? (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).STOPPED : (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).BLOCKED);
                }
                return shouldShowMessage;
            }
            return this.shouldUseMockClient ? false : super.start(messageId).then((didStart)=>{
                if (this.enableChoreographer) this.onMessageDisposition?.(didStart ? (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).STARTED : (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).BLOCKED);
                return didStart;
            });
        }
        async stop(messageId) {
            return this.shouldUseMockClient ? true : super.stop(messageId).then((didStop)=>{
                if (this.enableChoreographer) this.onMessageDisposition?.(didStop ? (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).STOPPED : (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).BLOCKED);
                return didStop;
            });
        }
    }
    function $754c27d37ccd1133$export$2e2bcd8739ae039(props) {
        const { cloudId: cloudId } = (0, (0, parcelRequire("4Pf62")).getTenantContext_DEPRECATED_DO_NOT_USE)();
        const stargateUrl = '/gateway/api';
        const { data: orgId } = (0, (0, parcelRequire("14XJw")).useOrgId)();
        const { isAlreadyChoreographedAtAncestor: isAlreadyChoreographedAtAncestor } = (0, (0, parcelRequire("fG5AJ")).useChoreographedStatusContext)();
        const client = new $754c27d37ccd1133$export$580a2294c917898c(cloudId, stargateUrl, props.shouldAllowOnSynthetic, orgId, props.messageType, props.onMessageDisposition, isAlreadyChoreographedAtAncestor);
        const { fallback: fallback, messageId: messageId } = props;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ioD1B")).ChoreographerContextProvider), {
            isChoreographed: client.enableChoreographer
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jcYbC")).default), {
            client: client,
            fallback: fallback,
            messageId: messageId
        }, props.children));
    }
});
parcelRegister("ioD1B", function(module, exports) {
    $parcel$export(module.exports, "ChoreographerContextProvider", ()=>$c141e5ba3428702a$export$c3226151caaf2fbf);
    var $5uXOq = parcelRequire("5uXOq");
    var $fG5AJ;
    const $c141e5ba3428702a$var$choreographedStatusContextValue = {
        isAlreadyChoreographedAtAncestor: true
    };
    const $c141e5ba3428702a$export$c3226151caaf2fbf = ({ isChoreographed: isChoreographed, children: children })=>{
        const { isAlreadyChoreographedAtAncestor: isAlreadyChoreographedAtAncestor } = (0, (0, parcelRequire("fG5AJ")).useChoreographedStatusContext)();
        const shouldUseChoreographerContextProvider = !isAlreadyChoreographedAtAncestor && isChoreographed;
        return shouldUseChoreographerContextProvider ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fG5AJ")).ChoreographedStatusContext).Provider, {
            value: $c141e5ba3428702a$var$choreographedStatusContextValue
        }, children) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
    };
});
parcelRegister("5vuhU", function(module, exports) {
    $parcel$export(module.exports, "useRegisterNotificationsInCommandPalette", ()=>$179b48f3297e7dbc$export$1859b18f10ade54c);
    var $5uXOq = parcelRequire("5uXOq");
    var $6Njc0;
    var $5p1kJ;
    var $5rVQI;
    var $eG9rp;
    var $1Kt4o;
    var $5zCdk;
    var $jh7L9;
    var $koVbs;
    var $2ju1i;
    const $179b48f3297e7dbc$export$1859b18f10ade54c = (onClick)=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { getKeywords: getKeywords } = (0, (0, parcelRequire("eG9rp")).useCommandKeywords)();
        const commands = [
            {
                id: (0, $309f32bbf2afff80$export$e0226a83abddbd07),
                name: formatMessage((0, (0, parcelRequire("2ju1i")).messages).checkNotifications),
                primaryAction: {
                    type: (0, (0, parcelRequire("jh7L9")).CommandActionType).PERFORM,
                    perform: onClick
                },
                keywords: getKeywords('checkNotificationsSynonyms'),
                section: (0, (0, parcelRequire("5p1kJ")).COMMAND_PALETTE_SECTIONS).quickActions,
                priority: (0, (0, parcelRequire("5rVQI")).COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY).checkNotifications,
                components: {
                    LeftIcon: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("6Njc0"))))), {
                            label: formatMessage((0, (0, parcelRequire("2ju1i")).messages).checkNotifications)
                        })
                },
                analytics: {
                    action: 'checkNotifications'
                }
            }
        ];
        (0, (0, parcelRequire("5zCdk")).useRegisterCommands)((0, (0, parcelRequire("1Kt4o")).COMMAND_PALETTE_REGISTRY_IDS).CHECK_NOTIFICATIONS, commands);
    };
});
parcelRegister("5p1kJ", function(module, exports) {
    $parcel$export(module.exports, "COMMAND_PALETTE_SECTIONS", ()=>$7691773a5eed3db6$export$16b06d29ef8f802b);
    $parcel$export(module.exports, "COMMAND_PALETTE_ISSUE_ACTIONS", ()=>$7691773a5eed3db6$export$156ebd2354e25466);
    $parcel$export(module.exports, "COMMAND_PALETTE_PRESSED_ANALYTICS", ()=>$7691773a5eed3db6$export$7fe859528e87e623);
    $parcel$export(module.exports, "COMMAND_PALETTE_ID", ()=>$7691773a5eed3db6$export$9f646ab88e1d2b14);
    $parcel$export(module.exports, "COMMAND_PALETTE_SECTIONS_PRIORITY", ()=>$7691773a5eed3db6$export$abf26cc5daebd2c8);
    $parcel$export(module.exports, "COMMAND_PALETTE_PROJECT_NAVIGATION_PRIORITY", ()=>$7691773a5eed3db6$export$12514ca8899b0521);
    $parcel$export(module.exports, "COMMAND_PALETTE_ATLASSIAN_ADMIN_PRIORITY", ()=>$7691773a5eed3db6$export$5fa0bcf59f933c21);
    $parcel$export(module.exports, "COMMAND_PALETTE_PERSONAL_SETTINGS_PRIORITY", ()=>$7691773a5eed3db6$export$7d9743be0b7d862f);
    $parcel$export(module.exports, "COMMAND_PALETTE_SITE_NAVIGATION_PRIORITY", ()=>$7691773a5eed3db6$export$b4e1633c86db2b70);
    $parcel$export(module.exports, "COMMAND_PALETTE_HELP_PRIORITY", ()=>$7691773a5eed3db6$export$5e5a87aac5d9771f);
    var $7691773a5eed3db6$export$16b06d29ef8f802b;
    (function(COMMAND_PALETTE_SECTIONS) {
        COMMAND_PALETTE_SECTIONS["quickSearch"] = "quick-search";
        COMMAND_PALETTE_SECTIONS["projectNavigation"] = "project-navigation";
        COMMAND_PALETTE_SECTIONS["siteNavigation"] = "site-navigation";
        COMMAND_PALETTE_SECTIONS["help"] = "help";
        COMMAND_PALETTE_SECTIONS["quickActions"] = "quick-actions";
        COMMAND_PALETTE_SECTIONS["personalSettings"] = "personal-settings";
        COMMAND_PALETTE_SECTIONS["jiraSettings"] = "jira-settings";
        COMMAND_PALETTE_SECTIONS["atlassianAdmin"] = "atlassian-admin";
    })($7691773a5eed3db6$export$16b06d29ef8f802b || ($7691773a5eed3db6$export$16b06d29ef8f802b = {}));
    const $7691773a5eed3db6$export$156ebd2354e25466 = 'issueActions';
    const $7691773a5eed3db6$export$7fe859528e87e623 = {
        action: 'pressed',
        actionSubject: 'commandPaletteKeyboardShortcut'
    };
    const $7691773a5eed3db6$export$9f646ab88e1d2b14 = 'commandPalette';
    var $7691773a5eed3db6$export$abf26cc5daebd2c8;
    (function(COMMAND_PALETTE_SECTIONS_PRIORITY) {
        COMMAND_PALETTE_SECTIONS_PRIORITY[COMMAND_PALETTE_SECTIONS_PRIORITY["quickSearch"] = 0] = "quickSearch";
        COMMAND_PALETTE_SECTIONS_PRIORITY[COMMAND_PALETTE_SECTIONS_PRIORITY["issueActions"] = 1] = "issueActions";
        COMMAND_PALETTE_SECTIONS_PRIORITY[COMMAND_PALETTE_SECTIONS_PRIORITY["projectNavigation"] = 2] = "projectNavigation";
        COMMAND_PALETTE_SECTIONS_PRIORITY[COMMAND_PALETTE_SECTIONS_PRIORITY["quickActions"] = 3] = "quickActions";
        COMMAND_PALETTE_SECTIONS_PRIORITY[COMMAND_PALETTE_SECTIONS_PRIORITY["siteNavigation"] = 4] = "siteNavigation";
        COMMAND_PALETTE_SECTIONS_PRIORITY[COMMAND_PALETTE_SECTIONS_PRIORITY["atlassianAdmin"] = 5] = "atlassianAdmin";
        COMMAND_PALETTE_SECTIONS_PRIORITY[COMMAND_PALETTE_SECTIONS_PRIORITY["jiraSettings"] = 6] = "jiraSettings";
        COMMAND_PALETTE_SECTIONS_PRIORITY[COMMAND_PALETTE_SECTIONS_PRIORITY["personalSettings"] = 7] = "personalSettings";
        COMMAND_PALETTE_SECTIONS_PRIORITY[COMMAND_PALETTE_SECTIONS_PRIORITY["help"] = 8] = "help";
    })($7691773a5eed3db6$export$abf26cc5daebd2c8 || ($7691773a5eed3db6$export$abf26cc5daebd2c8 = {}));
    var $7691773a5eed3db6$export$12514ca8899b0521;
    (function(COMMAND_PALETTE_PROJECT_NAVIGATION_PRIORITY) {
        COMMAND_PALETTE_PROJECT_NAVIGATION_PRIORITY[COMMAND_PALETTE_PROJECT_NAVIGATION_PRIORITY["goToBacklog"] = 0] = "goToBacklog";
        COMMAND_PALETTE_PROJECT_NAVIGATION_PRIORITY[COMMAND_PALETTE_PROJECT_NAVIGATION_PRIORITY["goToBoard"] = 1] = "goToBoard";
        COMMAND_PALETTE_PROJECT_NAVIGATION_PRIORITY[COMMAND_PALETTE_PROJECT_NAVIGATION_PRIORITY["goToReports"] = 2] = "goToReports";
        COMMAND_PALETTE_PROJECT_NAVIGATION_PRIORITY[COMMAND_PALETTE_PROJECT_NAVIGATION_PRIORITY["goToTimeline"] = 3] = "goToTimeline";
        COMMAND_PALETTE_PROJECT_NAVIGATION_PRIORITY[COMMAND_PALETTE_PROJECT_NAVIGATION_PRIORITY["goToIssues"] = 4] = "goToIssues";
    })($7691773a5eed3db6$export$12514ca8899b0521 || ($7691773a5eed3db6$export$12514ca8899b0521 = {}));
    var $7691773a5eed3db6$export$5fa0bcf59f933c21;
    (function(COMMAND_PALETTE_ATLASSIAN_ADMIN_PRIORITY) {
        COMMAND_PALETTE_ATLASSIAN_ADMIN_PRIORITY[COMMAND_PALETTE_ATLASSIAN_ADMIN_PRIORITY["userManagement"] = 0] = "userManagement";
        COMMAND_PALETTE_ATLASSIAN_ADMIN_PRIORITY[COMMAND_PALETTE_ATLASSIAN_ADMIN_PRIORITY["billing"] = 1] = "billing";
    })($7691773a5eed3db6$export$5fa0bcf59f933c21 || ($7691773a5eed3db6$export$5fa0bcf59f933c21 = {}));
    var $7691773a5eed3db6$export$7d9743be0b7d862f;
    (function(COMMAND_PALETTE_PERSONAL_SETTINGS_PRIORITY) {
        COMMAND_PALETTE_PERSONAL_SETTINGS_PRIORITY[COMMAND_PALETTE_PERSONAL_SETTINGS_PRIORITY["switchToDarkTheme"] = 0] = "switchToDarkTheme";
        COMMAND_PALETTE_PERSONAL_SETTINGS_PRIORITY[COMMAND_PALETTE_PERSONAL_SETTINGS_PRIORITY["switchToLightTheme"] = 1] = "switchToLightTheme";
        COMMAND_PALETTE_PERSONAL_SETTINGS_PRIORITY[COMMAND_PALETTE_PERSONAL_SETTINGS_PRIORITY["switchToSystemTheme"] = 2] = "switchToSystemTheme";
        COMMAND_PALETTE_PERSONAL_SETTINGS_PRIORITY[COMMAND_PALETTE_PERSONAL_SETTINGS_PRIORITY["goToPersonalSettings"] = 3] = "goToPersonalSettings";
        COMMAND_PALETTE_PERSONAL_SETTINGS_PRIORITY[COMMAND_PALETTE_PERSONAL_SETTINGS_PRIORITY["goToAccountSettings"] = 4] = "goToAccountSettings";
    })($7691773a5eed3db6$export$7d9743be0b7d862f || ($7691773a5eed3db6$export$7d9743be0b7d862f = {}));
    var $7691773a5eed3db6$export$b4e1633c86db2b70;
    (function(COMMAND_PALETTE_SITE_NAVIGATION_PRIORITY) {
        COMMAND_PALETTE_SITE_NAVIGATION_PRIORITY[COMMAND_PALETTE_SITE_NAVIGATION_PRIORITY["viewAllIssues"] = 0] = "viewAllIssues";
        COMMAND_PALETTE_SITE_NAVIGATION_PRIORITY[COMMAND_PALETTE_SITE_NAVIGATION_PRIORITY["goToProjects"] = 1] = "goToProjects";
        COMMAND_PALETTE_SITE_NAVIGATION_PRIORITY[COMMAND_PALETTE_SITE_NAVIGATION_PRIORITY["goToBoards"] = 2] = "goToBoards";
        COMMAND_PALETTE_SITE_NAVIGATION_PRIORITY[COMMAND_PALETTE_SITE_NAVIGATION_PRIORITY["goToFilters"] = 3] = "goToFilters";
        COMMAND_PALETTE_SITE_NAVIGATION_PRIORITY[COMMAND_PALETTE_SITE_NAVIGATION_PRIORITY["goToDashboards"] = 4] = "goToDashboards";
        COMMAND_PALETTE_SITE_NAVIGATION_PRIORITY[COMMAND_PALETTE_SITE_NAVIGATION_PRIORITY["goToPlans"] = 5] = "goToPlans";
    })($7691773a5eed3db6$export$b4e1633c86db2b70 || ($7691773a5eed3db6$export$b4e1633c86db2b70 = {}));
    var $7691773a5eed3db6$export$5e5a87aac5d9771f;
    (function(COMMAND_PALETTE_HELP_PRIORITY) {
        COMMAND_PALETTE_HELP_PRIORITY[COMMAND_PALETTE_HELP_PRIORITY["getHelp"] = 0] = "getHelp";
        COMMAND_PALETTE_HELP_PRIORITY[COMMAND_PALETTE_HELP_PRIORITY["viewKeyboardShortcuts"] = 1] = "viewKeyboardShortcuts";
    })($7691773a5eed3db6$export$5e5a87aac5d9771f || ($7691773a5eed3db6$export$5e5a87aac5d9771f = {}));
});
parcelRegister("5rVQI", function(module, exports) {
    $parcel$export(module.exports, "COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY", ()=>$c7aab549acec9d6e$export$5ea2dca0a260365d);
    var $c7aab549acec9d6e$export$5ea2dca0a260365d;
    (function(COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY) {
        COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY[COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY["createIssue"] = 0] = "createIssue";
        COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY[COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY["searchOnCurrentPage"] = 1] = "searchOnCurrentPage";
        COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY[COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY["openQuickSearch"] = 2] = "openQuickSearch";
        COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY[COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY["copyUrl"] = 3] = "copyUrl";
        COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY[COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY["checkNotifications"] = 4] = "checkNotifications";
    })($c7aab549acec9d6e$export$5ea2dca0a260365d || ($c7aab549acec9d6e$export$5ea2dca0a260365d = {}));
});
parcelRegister("eG9rp", function(module, exports) {
    $parcel$export(module.exports, "useCommandKeywords", ()=>$61ebfb9239c4f8e5$export$4d8fe3ef6385358);
    var $5uXOq = parcelRequire("5uXOq");
    var $koVbs;
    var $dYWZb;
    const $61ebfb9239c4f8e5$export$4d8fe3ef6385358 = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const getKeywords = (0, $5uXOq.useCallback)((keywordGroup)=>(0, (0, parcelRequire("dYWZb")).messages)[keywordGroup] ? formatMessage((0, (0, parcelRequire("dYWZb")).messages)[keywordGroup]) : undefined, [
            formatMessage
        ]);
        return {
            getKeywords: getKeywords
        };
    };
});
parcelRegister("dYWZb", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$df7d7dd7b88156b7$export$defe85febe8b728c);
    var $7VHMR;
    const $df7d7dd7b88156b7$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        searchYourIssuesSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.search-your-issues-synonyms",
            "defaultMessage": "Find my ideas tickets requests tasks work"
        },
        changeStatusSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.change-status-synonyms",
            "defaultMessage": "Move through workflow  Progress Done Complete transition"
        },
        assignToMeSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.assign-to-me-synonyms",
            "defaultMessage": "self"
        },
        assignIssueSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.assign-issue-synonyms",
            "defaultMessage": "Choose person Give work Pick assignee Select ticket idea"
        },
        startWatchingIssueSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.start-watching-issue-synonyms",
            "defaultMessage": "Follow work Track Unwatch Unfollow"
        },
        backlogSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.backlog-synonyms",
            "defaultMessage": "Inventory Go"
        },
        boardSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.board-synonyms",
            "defaultMessage": "Boards Sprint Kanban Swimlanes Do Go"
        },
        reportsSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.reports-synonyms",
            "defaultMessage": "Summary Insights Burndown Go"
        },
        timelineSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.timeline-synonyms",
            "defaultMessage": "Plan Work calendar Schedule Overview Delivery Go Roadmap"
        },
        quickSearchSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.quick-search-synonyms",
            "defaultMessage": "Search products across Atlassian Go to"
        },
        pageSearchSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.page-search-synonyms",
            "defaultMessage": "Control f Command cmd cntrl Go find ctrl"
        },
        createIssueSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.create-issue-synonyms",
            "defaultMessage": "work Start idea New ticket task"
        },
        switchDarkThemeSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.switch-dark-theme-synonyms",
            "defaultMessage": "light Darkmode lightmode Change Appearance Colours colors nightmode daymode"
        },
        matchSystemThemeSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.match-system-theme-synonyms",
            "defaultMessage": "Sync settings Darkmode lightmode Change Appearance Colours colors nightmode daymode"
        },
        copyCurrentPageUrlSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.copy-current-page-url-synonyms",
            "defaultMessage": "link website address"
        },
        getHelpSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.get-help-synonyms",
            "defaultMessage": "In-product Support Documentation Questions Answers"
        },
        viewKeyboardShortcutsSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.view-keyboard-shortcuts-synonyms",
            "defaultMessage": "Hotkeys Show See"
        },
        editDescriptionSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.edit-description-synonyms",
            "defaultMessage": "Completion criteria Info Information Details Add Write"
        },
        addAttachmentSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.add-attachment-synonyms",
            "defaultMessage": "Attach Upload Document Media"
        },
        addCommentSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.add-comment-synonyms",
            "defaultMessage": "Write Message"
        },
        logWorkSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.log-work-synonyms",
            "defaultMessage": "Track Record hours Time spent"
        },
        addRemoveFlagSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.add-remove-flag-synonyms",
            "defaultMessage": "Unflag Mark Unmark Star Unstar"
        },
        cloneIssueSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.clone-issue-synonyms",
            "defaultMessage": "Copy Duplicate Task Idea Request"
        },
        linkConfluencePageSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.link-confluence-page-synonyms",
            "defaultMessage": "Relate Add Connect"
        },
        addAUrlSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.add-a-url-synonyms",
            "defaultMessage": "Connect Weblink Link Website Domain"
        },
        goToIssuesSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.go-to-issues-synonyms",
            "defaultMessage": "Advanced issue search Project Filter JQL search list View all"
        },
        goToPersonalSettingsSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.go-to-personal-settings-synonyms",
            "defaultMessage": "Preferences Notifications Theme Jira labs"
        },
        goToAccountSettingsSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.go-to-account-settings-synonyms",
            "defaultMessage": "Preferences Privacy Profile Your details Personal information"
        },
        viewAllIssuesSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.view-all-issues-synonyms",
            "defaultMessage": "Site issues JQL View all"
        },
        viewAllProjectsSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.view-all-projects-synonyms",
            "defaultMessage": "List Go"
        },
        viewAllPlansSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.view-all-plans-synonyms",
            "defaultMessage": "List Go"
        },
        viewAllBoardsSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.view-all-boards-synonyms",
            "defaultMessage": "List Go"
        },
        viewAllFiltersSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.view-all-filters-synonyms",
            "defaultMessage": "List Go"
        },
        viewAllDashboardsSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.view-all-dashboards-synonyms",
            "defaultMessage": "List Go"
        },
        checkNotificationsSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.check-notifications-synonyms",
            "defaultMessage": "window See Updates"
        },
        goToUserManagementSynonyms: {
            "id": "command-palette-integration.controllers.use-command-keywords.go-to-user-management-synonyms",
            "defaultMessage": "Permissions"
        }
    });
});
parcelRegister("1Kt4o", function(module, exports) {
    $parcel$export(module.exports, "COMMAND_PALETTE_REGISTRY_IDS", ()=>$3add01bee0e3b846$export$689de18177335e39);
    $parcel$export(module.exports, "COMMAND_PALETTE_REGISTRY", ()=>$3add01bee0e3b846$export$96f61eb1261e42c3);
    const $3add01bee0e3b846$export$689de18177335e39 = {
        ADD_CHILD_ISSUE: 'ADD_CHILD_ISSUE',
        ADMIN_NAVIGATION: 'ADMIN_NAVIGATION',
        CHECK_NOTIFICATIONS: 'CHECK_NOTIFICATIONS',
        COPY_URL: 'COPY_URL',
        DESCRIPTION: 'DESCRIPTION',
        ISSUE_ACTIONS: 'ISSUE_ACTIONS',
        ISSUE_FIELD_ASSIGN: 'ISSUE_FIELD_ASSIGN',
        ISSUE_HEADER_ACTIONS: 'ISSUE_HEADER_ACTIONS',
        ISSUE_SUB_HEADER_ACTIONS: 'ISSUE_SUB_HEADER_ACTIONS',
        LOG_WORK: 'LOG_WORK',
        NAVIGATION_COMMANDS: 'NAVIGATION_COMMANDS',
        PARENT: 'PARENT',
        PROJECT_NAVIGATION: 'PROJECT_NAVIGATION',
        QUICK_ADD: 'QUICK_ADD',
        QUICK_SEARCH: 'QUICK_SEARCH',
        SHORTCUTS: 'SHORTCUTS',
        STATUS: 'STATUS',
        STORY_POINTS: 'STORY_POINTS',
        TEST: 'TEST',
        THEME_MODE: 'THEME_MODE'
    };
    const $3add01bee0e3b846$var$teams = {
        Deliveroo: 'Deliveroo'
    };
    const $3add01bee0e3b846$export$96f61eb1261e42c3 = {
        ADMIN_NAVIGATION: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.ADMIN_NAVIGATION,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        CHECK_NOTIFICATIONS: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.CHECK_NOTIFICATIONS,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        COPY_URL: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.COPY_URL,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        DESCRIPTION: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.DESCRIPTION,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        ISSUE_ACTIONS: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.ISSUE_ACTIONS,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        ISSUE_FIELD_ASSIGN: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.ISSUE_FIELD_ASSIGN,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        ISSUE_HEADER_ACTIONS: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.ISSUE_HEADER_ACTIONS,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        ISSUE_SUB_HEADER_ACTIONS: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.ISSUE_SUB_HEADER_ACTIONS,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        LOG_WORK: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.LOG_WORK,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        NAVIGATION_COMMANDS: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.NAVIGATION_COMMANDS,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        PARENT: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.PARENT,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        PROJECT_NAVIGATION: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.PROJECT_NAVIGATION,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        QUICK_ADD: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.QUICK_ADD,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        QUICK_SEARCH: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.QUICK_SEARCH,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        SHORTCUTS: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.SHORTCUTS,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        STATUS: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.STATUS,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        STORY_POINTS: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.STORY_POINTS,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        },
        THEME_MODE: {
            registryId: $3add01bee0e3b846$export$689de18177335e39.THEME_MODE,
            teamName: $3add01bee0e3b846$var$teams.Deliveroo
        }
    };
});
parcelRegister("5zCdk", function(module, exports) {
    $parcel$export(module.exports, "useRegisterCommands", ()=>$c2fc115f119fcec7$export$3f626f8a5dbc29e4);
    var $6QoIp;
    const $c2fc115f119fcec7$export$3f626f8a5dbc29e4 = (0, (0, parcelRequire("6QoIp")).useRegisterCommandsWithRegistry);
});
parcelRegister("6QoIp", function(module, exports) {
    $parcel$export(module.exports, "useRegisterCommandsWithRegistry", ()=>$9d04f6fb396fa7c9$export$8a824ae164ff968d);
    var $5uXOq = parcelRequire("5uXOq");
    var $fIZf0;
    function $9d04f6fb396fa7c9$export$8a824ae164ff968d(registrationId, commands, dependencies = []) {
        const subscribe = (0, (0, parcelRequire("fIZf0")).useSubscribeCommandsWithRegistry)();
        const commandCache = (0, $5uXOq.useRef)(commands);
        (0, $5uXOq.useEffect)(()=>{
            commandCache.current = commands;
        }, [
            commands
        ]);
        (0, $5uXOq.useEffect)(()=>subscribe(registrationId, commandCache.current), [
            ...dependencies,
            subscribe
        ]);
    }
});
parcelRegister("fIZf0", function(module, exports) {
    $parcel$export(module.exports, "useSubscribeCommandsWithRegistry", ()=>$72118e9f2599bc72$export$880fc01e31710b27);
    var $5uXOq = parcelRequire("5uXOq");
    var $d8lE2;
    function $72118e9f2599bc72$export$880fc01e31710b27() {
        const { addCommands: addCommands, removeCommands: removeCommands, updateActiveRegistry: updateActiveRegistry, removeFromActiveRegistry: removeFromActiveRegistry } = (0, (0, parcelRequire("d8lE2")).useActionsCommandPalette)();
        const subscribe = (0, $5uXOq.useCallback)((registrationId, commands)=>{
            updateActiveRegistry(registrationId, commands);
            addCommands(commands);
            return ()=>{
                removeCommands(commands);
                removeFromActiveRegistry(registrationId, commands);
            };
        }, [
            addCommands,
            removeCommands,
            removeFromActiveRegistry,
            updateActiveRegistry
        ]);
        return subscribe;
    }
});
const $309f32bbf2afff80$export$e0226a83abddbd07 = 'check-notifications';
parcelRegister("2ju1i", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$0cb403ddbf46e2b7$export$defe85febe8b728c);
    var $7VHMR;
    const $0cb403ddbf46e2b7$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        checkNotifications: {
            "id": "atlassian-navigation.notifications.command-palette.check-notifications",
            "defaultMessage": "Check notifications"
        }
    });
});
const $3c41b45407aae96c$export$14c62ba6f49b806f = 9;
parcelRegister("b7Ryd", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a4d3829bacc3127c$export$2e2bcd8739ae039);
    var $7VHMR;
    var $a4d3829bacc3127c$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        tooltip: {
            "id": "atlassian-navigation.notifications.tooltip",
            "defaultMessage": "Notifications"
        },
        oldLabel: {
            "id": "atlassian-navigation.notifications.old-label",
            "defaultMessage": "Notifications"
        },
        label: {
            "id": "atlassian-navigation.notifications.label",
            "defaultMessage": "{count, plural, =0{ Notifications} one{{count, number} Notification} other{{count, number} Notifications}}"
        },
        overNineNotifications: {
            "id": "atlassian-navigation.notifications.over-nine-notifications",
            "defaultMessage": "Over 9 Notifications"
        }
    });
});
parcelRegister("1eLMu", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("6D4FW")
    ]).then(()=>parcelRequire('6z8GT'));
});
parcelRegister("cgIES", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("gnhz7")
    ]).then(()=>parcelRequire('hJbTL'));
});
parcelRegister("cvQRb", function(module, exports) {
    $parcel$export(module.exports, "People", ()=>$c53dc2abd00215a4$export$8d3edc4d62e152ad);
    var $5uXOq = parcelRequire("5uXOq");
    var $euiLX;
    var $koVbs;
    var $0Wtmq;
    var $57aZp;
    const $c53dc2abd00215a4$export$8d3edc4d62e152ad = ()=>{
        const { locale: locale } = (0, (0, parcelRequire("koVbs")).default)();
        return ((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("0Wtmq")).PeopleAndTeamsConfigurationProvider), {
            product: "jira",
            locale: (0, (0, parcelRequire("euiLX")).default)(locale)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("57aZp")).People), null)));
    };
});
parcelRegister("57aZp", function(module, exports) {
    $parcel$export(module.exports, "People", ()=>$73ab25b84abebed5$export$8d3edc4d62e152ad);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Rkzz = parcelRequire("8Rkzz");
    var $4mBKK;
    var $2bzBV;
    var $fElvF;
    var $2JsdQ;
    var $bSSG7;
    var $1wn1U;
    var $2N6bN;
    var $2vSU6;
    var $joX8T;
    var $ivDCs;
    var $4mO32;
    var $39xOx;
    var $4R6GH;
    var $pa9q9;
    var $1wpPp;
    var $4ywcn;
    var $8igKv;
    var $eIokK;
    var $lmyls;
    var $2Ui4E;
    var $1J3t1;
    var $8GOso;
    var $6jY6G;
    var $cYlk7;
    var $14XJw;
    var $ldyPw;
    var $9USr3;
    var $at1Nr;
    var $69X1g;
    var $heI7y;
    var $dkqym;
    var $cZpsT;
    var $9OXo1;
    var $fSeA2;
    var $lVJAU;
    var $Mp6qH;
    var $4uXxK;
    var $fVbkk;
    var $j94qH;
    var $gAtvZ;
    var $kAJ4Y;
    var $iPj86;
    var $annPD;
    var $k5sB5;
    var $ccG8Y;
    const $73ab25b84abebed5$var$STORAGE_KEY = 'jwm.invite-business-teams-modal-viwed-timestamp';
    const $73ab25b84abebed5$var$SITE_USER_TRAIT_HAS_VIEW_EXPERIMENT = 'jira_screen_viewed_invitebusinessteamsmodal_first';
    const $73ab25b84abebed5$var$STORAGE_PROVIDER_KEY = 'jira_invite-business-teams-modal';
    const $73ab25b84abebed5$export$c2ed3351cac403d1 = (0, (0, parcelRequire("bSSG7")).createLocalStorageProvider)($73ab25b84abebed5$var$STORAGE_PROVIDER_KEY);
    const $73ab25b84abebed5$var$PeopleMenuDropdown = ()=>{
        const [inviteBusinessTeamsModalData, setInviteBusinessTeamsModalData] = (0, $5uXOq.useState)({
            isOpen: false,
            userCounts: 0
        });
        const [isMenuOpen, { off: closeMenu, on: openMenu }] = (0, (0, parcelRequire("2Ui4E")).useTopMenus)((0, (0, parcelRequire("lmyls")).MENU_ID).PEOPLE);
        const baseUrl = '';
        const { showFlag: showFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        const route = (0, (0, parcelRequire("6jY6G")).useCurrentRoute)();
        const { push: push } = (0, (0, parcelRequire("lVJAU")).useRouterActions)();
        const isHighlighted = (0, (0, parcelRequire("kAJ4Y")).useMenuHighlighted)((0, (0, parcelRequire("lmyls")).MENU_ID).PEOPLE);
        const triggerAnalytics = (0, (0, parcelRequire("iPj86")).useNavigationItemAnalytics)({
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).PEOPLE
        });
        const [isEventFromSpotlightTour] = (0, (0, parcelRequire("gAtvZ")).useFromSpotlightTour)('spotlight');
        const checkUsersGroupPermission = (0, (0, parcelRequire("annPD")).useBrowseUsersGroupsPermissions)();
        const checkUsersGroupPermissionNext = (0, (0, parcelRequire("annPD")).useBrowseUsersGroupsPermissionsNext)(isMenuOpen);
        const isEnabledForTailoredOnboardingM2 = (0, (0, parcelRequire("4R6GH")).fg)('jira_tailored_onboarding_jtbd_feature_gate') ? (0, (0, parcelRequire("8GOso")).useIsTailoredOnboardingM2ForQuickstartEnabled)() : false;
        const addFlag = (0, $5uXOq.useCallback)(({ appearance: appearance, ...flagProps })=>{
            if (appearance === 'success' || appearance === 'normal') showFlag((serviceProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4ywcn")).default), {
                    ...flagProps,
                    ...serviceProps
                }));
            if (appearance === 'warning') showFlag((serviceProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8igKv")).default), {
                    ...flagProps,
                    ...serviceProps
                }));
            if (appearance === 'error') showFlag((serviceProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1wpPp")).default), {
                    ...flagProps,
                    ...serviceProps
                }));
        }, [
            showFlag
        ]);
        const pushRoute = (0, $5uXOq.useCallback)((url)=>push(`${baseUrl}${url}`), [
            baseUrl,
            push
        ]);
        const peopleMenuWrapperRef = (0, (0, parcelRequire("9USr3")).useRegisterNudgeTarget)((0, $6784cbc7d62e5d7c$export$77bc79debbf5c069), true).ref;
        const { data: { user: user } } = (0, (0, parcelRequire("cYlk7")).useCurrentUser)();
        const projectTeamAccessData = (0, (0, parcelRequire("k5sB5")).useTPAExperiment)();
        const teamCreateDialogProps = (0, $5uXOq.useMemo)(()=>{
            const dialogProps = {
                currentUser: {
                    id: user.accountId || '',
                    fullName: user.userFullname,
                    email: user.emailAddress !== undefined ? user.emailAddress : '',
                    avatarUrl: user?.avatarUrls ? user.avatarUrls['16x16'] : '',
                    nickname: user?.userFullname
                },
                enableMembershipSettingsChoice: true,
                projectTeamAccess: projectTeamAccessData
            };
            dialogProps.onClose = ()=>{
                if (peopleMenuWrapperRef?.current) requestAnimationFrame(()=>{
                    peopleMenuWrapperRef?.current?.querySelector('button')?.focus();
                });
            };
            return dialogProps;
        }, [
            projectTeamAccessData,
            user
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (!(0, (0, parcelRequire("4R6GH")).fg)('dont-auto-close-people-menu')) closeMenu();
            return ()=>{
                if (!(0, (0, parcelRequire("4R6GH")).fg)('dont-auto-close-people-menu')) closeMenu();
            };
        }, [
            route
        ]);
        const { data: projectSidebarNavigationData } = (0, (0, parcelRequire("1J3t1")).useProjectSidebarNavigation)();
        const subProduct = (0, (0, parcelRequire("eIokK")).projectTypeToSubProduct)(projectSidebarNavigationData?.projectType);
        const { cloudId: cloudId, atlassianAccountId: atlassianAccountId, appEditions: { software: software } } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const { data: orgId } = (0, (0, parcelRequire("14XJw")).useOrgId)();
        const invitePeopleExperimentalFeatures = (0, $5uXOq.useMemo)(()=>({
                inviteThirdPartyTeams: (0, (0, parcelRequire("joX8T")).VARIATION),
                coreInvitesDriveMultiProductInvites: (0, (0, parcelRequire("4R6GH")).fg)('core_invites_drive_multi_product_invites_gate')
            }), []);
        const continueUrl = window.location.href;
        const entryPoint = (0, (0, parcelRequire("4mO32")).useEntryPoint)((0, (0, parcelRequire("1wn1U")).inviteBusinessTeamsModalEntryPoint), {});
        const [{ traits: traits }] = (0, (0, parcelRequire("2N6bN")).usePersonalizationProvider)();
        const firstActivationDateMs = (0, (0, parcelRequire("dkqym")).useFirstActivationDateMs)();
        const storedValue = $73ab25b84abebed5$export$c2ed3351cac403d1.get($73ab25b84abebed5$var$STORAGE_KEY);
        const isSoftwareProject = subProduct === (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT);
        const isValidAppEdition = software === (0, (0, parcelRequire("ldyPw")).FREE_EDITION) || software === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION);
        const isValidSiteAge = firstActivationDateMs && (0, (0, parcelRequire("4mBKK")).default)(new Date(), firstActivationDateMs) > 28;
        const hasDismissedInviteBefore = storedValue || traits.some((trait)=>trait.name === $73ab25b84abebed5$var$SITE_USER_TRAIT_HAS_VIEW_EXPERIMENT);
        const showModal = Boolean(isSoftwareProject && isValidAppEdition && isValidSiteAge && !hasDismissedInviteBefore);
        const { openModal: openModal } = (0, (0, parcelRequire("2JsdQ")).useInvitePeopleModal)({
            continueUrl: continueUrl,
            enableThirdParty: true,
            experimentalFeatures: invitePeopleExperimentalFeatures,
            displayInfiSpotlight: true,
            orgId: orgId,
            resourceAri: `ari:cloud:jira::site/${cloudId}`,
            showFlag: addFlag,
            source: 'peopleMenu',
            subProduct: subProduct,
            experimental_applicationEdition: software,
            navigation: {
                directoryContextEntryPoint: '/jira'
            },
            onSubmit: async (data)=>{
                const { inviteUsersResponse: inviteUsersResponse } = await data.defaultOnSubmit();
                if (showModal) {
                    const invitedJswUsers = (0, (0, parcelRequire("ccG8Y")).getInvitedJswUsers)(inviteUsersResponse);
                    const invitedJswUserCount = invitedJswUsers.length;
                    if (invitedJswUserCount > 0) {
                        setInviteBusinessTeamsModalData({
                            isOpen: true,
                            userCounts: invitedJswUserCount
                        });
                        $73ab25b84abebed5$export$c2ed3351cac403d1.set($73ab25b84abebed5$var$STORAGE_KEY, String(new Date()));
                        entryPoint.entryPointActions.load();
                    }
                }
            },
            ...showModal ? {
                experimental_onReopenHandler: ()=>{
                    openModal();
                    setInviteBusinessTeamsModalData({
                        isOpen: false,
                        userCounts: 0
                    });
                }
            } : {}
        });
        const invitePeopleHandlers = (0, $5uXOq.useMemo)(()=>({
                handleClick: openModal
            }), [
            openModal
        ]);
        if (!atlassianAccountId) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).PEOPLE,
            packageName: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME),
            fallback: "unmount",
            teamName: "navigation"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($73ab25b84abebed5$export$5d16118c15d58d20, {
            ref: peopleMenuWrapperRef,
            "data-testid": `menu-${(0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).PEOPLE}-wrapper`
        }, isEnabledForTailoredOnboardingM2 && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("at1Nr")).InviteTeamNudgeAsync), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fSeA2")).default), {
            shouldRenderToParent: true,
            product: "jira",
            cloudId: cloudId,
            userId: atlassianAccountId,
            isBrowseUsersEnabled: (0, (0, parcelRequire("4R6GH")).fg)('dont-auto-close-people-menu') ? checkUsersGroupPermissionNext : checkUsersGroupPermission,
            pushRoute: pushRoute,
            isHighlighted: isHighlighted,
            addFlag: addFlag,
            onClick: triggerAnalytics,
            isOpen: isMenuOpen,
            onOpen: openMenu,
            onClose: isEventFromSpotlightTour ? (0, ($parcel$interopDefault($8Rkzz))) : closeMenu,
            customChevronIcon: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVbkk")).ChevronRightIcon), null),
            enablePreFetchingByHovering: false,
            subProduct: subProduct,
            isSSR: false,
            orgId: orgId ?? undefined,
            teamCreateDialogProps: teamCreateDialogProps,
            invitePeopleHandlers: invitePeopleHandlers,
            isSectionList: true
        })), inviteBusinessTeamsModalData.isOpen && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ivDCs")).ModalEntryPointContainer), {
            entryPointReferenceSubject: entryPoint.entryPointReferenceSubject,
            id: "invite-business-teams-modal",
            packageName: "jira-business.invite-business-teams",
            teamName: "jira-werewolves",
            runtimeProps: {
                userCounts: inviteBusinessTeamsModalData.userCounts,
                onClose: ()=>{
                    setInviteBusinessTeamsModalData({
                        isOpen: false,
                        userCounts: 0
                    });
                }
            },
            entryPointActions: entryPoint.entryPointActions
        }));
    };
    const $73ab25b84abebed5$var$PeopleWithAuth = ()=>{
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const { isVisible: isVisible } = (0, (0, parcelRequire("2bzBV")).useOverflowStatus)();
        const [{ hasOpened: shouldShowNudge }] = (0, (0, parcelRequire("heI7y")).useHasOpened)();
        if (isAnonymous) return null;
        if (!false) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "navigation.people-menu.with-nudgetooltip",
            packageName: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME),
            fallback: "unmount",
            teamName: "navigation"
        }, shouldShowNudge ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("69X1g")).NavigationPeopleNavItemWrapperNudgeAsync), {
            isInsideMenu: !isVisible
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j94qH")).FullHeightTextColorWrapper), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fElvF")).InvitePeopleModalContextProvider), null, (0, ($parcel$interopDefault($5uXOq))).createElement($73ab25b84abebed5$var$PeopleMenuDropdown, null)))) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j94qH")).FullHeightTextColorWrapper), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fElvF")).InvitePeopleModalContextProvider), null, (0, ($parcel$interopDefault($5uXOq))).createElement($73ab25b84abebed5$var$PeopleMenuDropdown, null))));
        return (0, ($parcel$interopDefault($5uXOq))).createElement($73ab25b84abebed5$var$PeopleMenuDropdown, null);
    };
    const $73ab25b84abebed5$export$8d3edc4d62e152ad = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).PEOPLE
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($73ab25b84abebed5$var$PeopleWithAuth, null));
    const $73ab25b84abebed5$export$5d16118c15d58d20 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4t3i1osq _16jlkb7n _shj5kb7n",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("1wn1U", function(module, exports) {
    $parcel$export(module.exports, "inviteBusinessTeamsModalEntryPoint", ()=>$0865312f111a9e5e$export$f4db71d255c0573);
    var $eufEc;
    var $fEDBv;
    const $0865312f111a9e5e$export$f4db71d255c0573 = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("2Cc43")).then((module)=>module.InviteBusinessTeamsModal), {
            moduleId: "c8fe5655"
        }),
        getPreloadProps: ()=>({})
    });
});
parcelRegister("2Cc43", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("hPzOn"),
        $6fhBS("sV6r3")
    ]).then(()=>parcelRequire('72cfG'));
});
parcelRegister("joX8T", function(module, exports) {
    $parcel$export(module.exports, "COHORT", ()=>$a85d1363752adaec$export$3d370c33621572df);
    $parcel$export(module.exports, "NOT_ENROLLED", ()=>$a85d1363752adaec$export$5b7423264d48ab04);
    $parcel$export(module.exports, "CONTROL", ()=>$a85d1363752adaec$export$43f367b3e957f219);
    $parcel$export(module.exports, "VARIATION", ()=>$a85d1363752adaec$export$fa04ad826acc90da);
    var $co1wz;
    const $a85d1363752adaec$export$3d370c33621572df = 'cohort';
    const $a85d1363752adaec$export$5b7423264d48ab04 = 'not-enrolled';
    const $a85d1363752adaec$export$43f367b3e957f219 = 'control';
    const $a85d1363752adaec$export$9ba2bc3b1bdc1519 = 'experiment';
    const $a85d1363752adaec$export$fa04ad826acc90da = 'variation';
    const $a85d1363752adaec$export$6486974e39493fa6 = 'variation-1';
    const $a85d1363752adaec$export$7d994996134ad562 = 'variation-2';
    const $a85d1363752adaec$export$795e7d69b248dcd0 = 'variation-3';
    const $a85d1363752adaec$export$c9c947c57470b787 = 'variation-4';
    const $a85d1363752adaec$var$DEFAULT_VALID_EXPERIMENT_GROUPS = [
        $a85d1363752adaec$export$5b7423264d48ab04,
        $a85d1363752adaec$export$43f367b3e957f219,
        $a85d1363752adaec$export$9ba2bc3b1bdc1519,
        $a85d1363752adaec$export$fa04ad826acc90da,
        $a85d1363752adaec$export$6486974e39493fa6,
        $a85d1363752adaec$export$7d994996134ad562,
        $a85d1363752adaec$export$795e7d69b248dcd0,
        $a85d1363752adaec$export$c9c947c57470b787
    ];
    var $a85d1363752adaec$export$2e2bcd8739ae039 = (experimentFeatureFlag, validExperimentGroups = $a85d1363752adaec$var$DEFAULT_VALID_EXPERIMENT_GROUPS)=>(0, (0, parcelRequire("co1wz")).getMultivariateFeatureFlag)(experimentFeatureFlag, $a85d1363752adaec$export$5b7423264d48ab04, validExperimentGroups);
});
parcelRegister("1wpPp", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2ebed3b5115fbfc5$export$2e2bcd8739ae039);
    var $3KRpp;
    var $2ebed3b5115fbfc5$export$2e2bcd8739ae039 = (0, (0, parcelRequire("3KRpp")).ErrorFlag);
});
parcelRegister("4ywcn", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$1a612b92e24f3853$export$2e2bcd8739ae039);
    var $3KRpp;
    var $1a612b92e24f3853$export$2e2bcd8739ae039 = (0, (0, parcelRequire("3KRpp")).SuccessFlag);
});
parcelRegister("8igKv", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$87b804070d792a00$export$2e2bcd8739ae039);
    var $3KRpp;
    var $87b804070d792a00$export$2e2bcd8739ae039 = (0, (0, parcelRequire("3KRpp")).WarningFlag);
});
parcelRegister("eIokK", function(module, exports) {
    $parcel$export(module.exports, "stripUrlToIssueKey", ()=>$2ca77bef45d99b5b$export$1f3c6284caef177b);
    $parcel$export(module.exports, "useInvitePeopleFlags", ()=>$2ca77bef45d99b5b$export$ab5fb7206b83c9ad);
    $parcel$export(module.exports, "projectTypeToSubProduct", ()=>$2ca77bef45d99b5b$export$3500077858760067);
    var $5uXOq = parcelRequire("5uXOq");
    var $pa9q9;
    var $1wpPp;
    var $g9eN3;
    var $4ywcn;
    var $8igKv;
    const $2ca77bef45d99b5b$var$ISSUE_KEY_REGEXP = /(([A-Z][A-Z0-9]+)-([0-9]+))($|\?.*)/;
    const $2ca77bef45d99b5b$export$1f3c6284caef177b = (candidateUrl)=>{
        const match = candidateUrl.match($2ca77bef45d99b5b$var$ISSUE_KEY_REGEXP);
        return match && match[1];
    };
    const $2ca77bef45d99b5b$export$ab5fb7206b83c9ad = ()=>{
        const { showFlag: showFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        const addFlag = (0, $5uXOq.useCallback)(({ appearance: appearance, ...flagProps })=>{
            let FlagComponent = (0, (0, parcelRequire("g9eN3")).default);
            switch(appearance){
                case 'success':
                case 'normal':
                    FlagComponent = (0, (0, parcelRequire("4ywcn")).default);
                    break;
                case 'warning':
                    FlagComponent = (0, (0, parcelRequire("8igKv")).default);
                    break;
                case 'error':
                    FlagComponent = (0, (0, parcelRequire("1wpPp")).default);
                    break;
            }
            showFlag((serviceProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement(FlagComponent, {
                    ...flagProps,
                    ...serviceProps
                }));
        }, [
            showFlag
        ]);
        return {
            addFlag: addFlag
        };
    };
    const $2ca77bef45d99b5b$export$3500077858760067 = (projectType)=>{
        switch(projectType){
            case 'jira_software':
                return 'software';
            case 'jira_service_management':
                return 'servicedesk';
            case 'jira_work_management':
                return 'core';
            case 'jira_product_discovery':
                return 'product-discovery';
            default:
                return undefined;
        }
    };
});
parcelRegister("g9eN3", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$100aabc6f0d895d9$export$2e2bcd8739ae039);
    var $3KRpp;
    var $100aabc6f0d895d9$export$2e2bcd8739ae039 = (0, (0, parcelRequire("3KRpp")).InfoFlag);
});
parcelRegister("8GOso", function(module, exports) {
    $parcel$export(module.exports, "usePersonalisationActions", ()=>$adde9919a40069db$export$5b1d43353c334c00);
    $parcel$export(module.exports, "useCommonEligibilityConditionsForJtbdOnboarding", ()=>$adde9919a40069db$export$c89644b13b6e777f);
    $parcel$export(module.exports, "useTailoredOnboardingPersonalisationAttributes", ()=>$adde9919a40069db$export$9aad8798ea46e07f);
    $parcel$export(module.exports, "useTailoredOnboardingPersonalisationM2", ()=>$adde9919a40069db$export$5875295cb096b1f2);
    $parcel$export(module.exports, "useIsTailoredOnboardingM2ForQuickstartEnabled", ()=>$adde9919a40069db$export$6bc15f8ade1cf36);
    $parcel$export(module.exports, "useFireExposureForTailoredOnboardingM2ForQuickstart", ()=>$adde9919a40069db$export$369994803661d31);
    var $5uXOq = parcelRequire("5uXOq");
    var $2vSU6;
    var $jcw0u;
    var $6s1PB;
    var $4R6GH;
    var $cNd4s;
    var $3sQ5x;
    var $iXqE5;
    var $AK64q;
    var $4mXzi;
    var $iAFnF;
    var $1OQiY;
    const $adde9919a40069db$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'jira-onboarding-personalisation',
        initialState: (0, (0, parcelRequire("AK64q")).initialCachedEndpointState),
        actions: (0, parcelRequire("4mXzi")).actions
    });
    const $adde9919a40069db$export$5b1d43353c334c00 = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($adde9919a40069db$var$store);
    const $adde9919a40069db$export$31a443426d101362 = (0, (0, parcelRequire("3sQ5x")).createHook)($adde9919a40069db$var$store);
    const $adde9919a40069db$export$c89644b13b6e777f = ()=>{
        const isSiteAdmin = (0, (0, parcelRequire("cNd4s")).useIsSiteAdmin)();
        return isSiteAdmin && (0, (0, parcelRequire("4R6GH")).fg)('jira_tailored_onboarding_jtbd_feature_gate');
    };
    const $adde9919a40069db$export$6f87e1662a24f8b9 = ({ isEligible: isEligible, experienceTypes: experienceTypes, additionalParameters: additionalParameters })=>{
        const [{ fetchedOnce: fetchedOnce, isFetching: isFetching, data: data }, { fetchOnboardingPersonalisation: fetchOnboardingPersonalisation }] = $adde9919a40069db$export$31a443426d101362();
        (0, $5uXOq.useEffect)(()=>{
            if (isEligible && !fetchedOnce && !isFetching && data === undefined) fetchOnboardingPersonalisation({
                experienceTypes: experienceTypes,
                additionalParameters: additionalParameters
            });
        }, [
            additionalParameters,
            data,
            experienceTypes,
            fetchOnboardingPersonalisation,
            fetchedOnce,
            isEligible,
            isFetching
        ]);
        if (!isEligible) return {
            data: undefined,
            isReady: true
        };
        return {
            data: data,
            isReady: fetchedOnce && !isFetching
        };
    };
    const $adde9919a40069db$export$9aad8798ea46e07f = ()=>{
        const isEligibleForTailoredOnboardingM2 = $adde9919a40069db$export$c89644b13b6e777f();
        const { data: personalisationData, isReady: personalisationDataIsReady } = $adde9919a40069db$export$6f87e1662a24f8b9({
            isEligible: isEligibleForTailoredOnboardingM2
        });
        return {
            personalisationDataIsReady: personalisationDataIsReady,
            teamType: personalisationData?.matched.variants.find((variant)=>variant.startsWith('teamType.'))?.replace('teamType.', ''),
            jtbd: personalisationData?.matched.variants.filter((variant)=>variant.startsWith('jtbd.option_')).map((option)=>option.replace('jtbd.option_', '')).join(',')
        };
    };
    const $adde9919a40069db$export$5875295cb096b1f2 = ({ isEligible: isEligible, additionalParameters: additionalParameters })=>{
        const hasSentErrorOnce = (0, $5uXOq.useRef)(false);
        const { data: data, isReady: isReady } = $adde9919a40069db$export$6f87e1662a24f8b9({
            isEligible: isEligible,
            experienceTypes: [
                'QUICKSTART',
                'TEMPLATE',
                'LANDING_PAGE'
            ],
            additionalParameters: additionalParameters
        });
        try {
            const conditionalProjectType = `projectType.${additionalParameters?.projectType}`;
            const isMatchingProjectType = Boolean(!additionalParameters?.projectType || data?.matched.variants.some((variant)=>variant === conditionalProjectType));
            const isMatchingTeamType = Boolean(data?.matched.variants.some((variant)=>variant.startsWith('teamType.') && (0, (0, parcelRequire("iAFnF")).ELIGIBLE_TEAM_TYPES_M2_FULL_NOTATION).includes(variant.split('.')[1])));
            const isMatchingJtbd = Boolean(data?.matched.variants.some((variant)=>variant.startsWith('jtbd')));
            const matched = Boolean(data && isMatchingJtbd && isMatchingProjectType && isMatchingTeamType);
            return {
                isReady: isReady,
                data: {
                    matched: matched,
                    matchedVariants: {
                        projectType: isMatchingProjectType,
                        tt: isMatchingTeamType,
                        jtbd: isMatchingJtbd
                    },
                    QUICKSTART: matched && data ? data.experience.QUICKSTART : [],
                    TEMPLATE: matched && data ? data.experience.TEMPLATE?.[0] : undefined,
                    LANDING_PAGE: matched && data ? data.experience.LANDING_PAGE?.[0] : undefined
                }
            };
        } catch (error) {
            if (!hasSentErrorOnce.current) (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: 'useTailoredOnboardingPersonalisationM2',
                    packageName: 'jiraOnboardingCore',
                    teamName: 'jlove-makkuro'
                },
                sendToPrivacyUnsafeSplunk: true
            });
            hasSentErrorOnce.current = true;
        }
        return {
            isReady: isReady,
            data: {
                matched: false,
                matchedVariants: {
                    jtbd: false,
                    tt: false,
                    projectType: false
                },
                QUICKSTART: [],
                TEMPLATE: undefined,
                LANDING_PAGE: undefined
            }
        };
    };
    const $adde9919a40069db$export$6bc15f8ade1cf36 = ()=>{
        const isEligibleForJtbdOnboarding = $adde9919a40069db$export$c89644b13b6e777f();
        const isEnabled = (0, (0, parcelRequire("1OQiY")).isTailoredOnboardingM2ExperimentFlagEnabled)();
        const { isReady: personalisationDataIsReady, data: personalisationData } = $adde9919a40069db$export$5875295cb096b1f2({
            isEligible: isEligibleForJtbdOnboarding,
            additionalParameters: {
                projectType: (0, (0, parcelRequire("2vSU6")).CORE_PROJECT)
            }
        });
        return Boolean(personalisationDataIsReady && personalisationData.QUICKSTART && personalisationData.QUICKSTART?.length > 0 && isEnabled);
    };
    const $adde9919a40069db$export$369994803661d31 = ({ projectType: projectType })=>{
        const isEligibleForJtbdOnboarding = $adde9919a40069db$export$c89644b13b6e777f();
        const { isReady: personalisationDataIsReady, data: personalisationData } = $adde9919a40069db$export$5875295cb096b1f2({
            isEligible: isEligibleForJtbdOnboarding && (0, (0, parcelRequire("4R6GH")).fg)('tailored_onboarding_m2_content_exposure_adjust'),
            additionalParameters: {
                projectType: (0, (0, parcelRequire("2vSU6")).CORE_PROJECT)
            }
        });
        if ((0, (0, parcelRequire("4R6GH")).fg)('tailored_onboarding_m2_content_exposure_adjust') && projectType === (0, (0, parcelRequire("2vSU6")).CORE_PROJECT) && personalisationDataIsReady && personalisationData.matched && personalisationData.QUICKSTART && personalisationData.QUICKSTART?.length > 0) (0, (0, parcelRequire("6s1PB")).expVal)('jira_tailored_onboarding_jtbd', 'isEnabled', false);
    };
});
parcelRegister("4mXzi", function(module, exports) {
    $parcel$export(module.exports, "actions", ()=>$f50a5e3bb7b9f239$export$e324594224ef24da);
    var $7bRcT;
    var $FroD7;
    const $f50a5e3bb7b9f239$var$fetchOnboardingPersonalisation = (0, (0, parcelRequire("7bRcT")).cachedEndpointCall)((args)=>(0, (0, parcelRequire("FroD7")).fetchOnboardingPersonalisationWithRethrow)(args));
    const $f50a5e3bb7b9f239$var$trackPersonalisationAction = ({ additionalParameters: additionalParameters })=>()=>(0, (0, parcelRequire("FroD7")).trackPersonalisation)({
                additionalParameters: additionalParameters
            });
    const $f50a5e3bb7b9f239$export$e324594224ef24da = {
        fetchOnboardingPersonalisation: $f50a5e3bb7b9f239$var$fetchOnboardingPersonalisation,
        trackPersonalisation: $f50a5e3bb7b9f239$var$trackPersonalisationAction
    };
});
parcelRegister("FroD7", function(module, exports) {
    $parcel$export(module.exports, "fetchOnboardingPersonalisationWithRethrow", ()=>$501ec097caecd266$export$6bc39428b6510a7c);
    $parcel$export(module.exports, "trackPersonalisation", ()=>$501ec097caecd266$export$9835c32b78102887);
    var $jcw0u;
    var $eusud;
    var $fv5ut;
    const $501ec097caecd266$var$PERSONALISATION_ENDPOINT = '/rest/personalisation/v1/experience';
    const $501ec097caecd266$var$TRACK_PERSONALISATION_ENDPOINT = '/rest/personalisation/v1/track';
    const $501ec097caecd266$var$responseHasAllRequestedExperienceTypes = ({ response: response, requestedExperienceTypes: requestedExperienceTypes })=>requestedExperienceTypes.every((requestedExperienceType)=>response.experience[requestedExperienceType]);
    const $501ec097caecd266$export$6bc39428b6510a7c = async ({ experienceTypes: experienceTypes = [
        'QUICKSTART',
        'TEMPLATE',
        'LANDING_PAGE'
    ], additionalParameters: additionalParameters })=>{
        try {
            const localStorageOnboardingPersonalisationResponse = (0, (0, parcelRequire("fv5ut")).getLocalStorageOnboardingPersonalisationResponse)();
            if (localStorageOnboardingPersonalisationResponse && $501ec097caecd266$var$responseHasAllRequestedExperienceTypes({
                response: localStorageOnboardingPersonalisationResponse,
                requestedExperienceTypes: experienceTypes
            })) return localStorageOnboardingPersonalisationResponse;
            const response = await (0, (0, parcelRequire("eusud")).performPostRequestWithRetry)($501ec097caecd266$var$PERSONALISATION_ENDPOINT, {
                body: JSON.stringify({
                    experienceTypes: experienceTypes,
                    additionalParameters: additionalParameters
                })
            });
            if (response) {
                const mergedResponse = {
                    experience: {
                        ...localStorageOnboardingPersonalisationResponse?.experience,
                        ...response.experience
                    },
                    matched: response.matched
                };
                (0, (0, parcelRequire("fv5ut")).setLocalStorageOnboardingPersonalisationResponse)(mergedResponse);
                return mergedResponse;
            }
            throw new Error('response from OnboardingPersonalisation is falsy');
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: 'fetchOnboardingPersonalisationWithRethrow',
                    packageName: 'jiraOnboardingCore',
                    teamName: 'jlove-makkuro'
                },
                sendToPrivacyUnsafeSplunk: true
            });
            throw error;
        }
    };
    const $501ec097caecd266$export$9835c32b78102887 = ({ additionalParameters: additionalParameters })=>{
        try {
            (0, (0, parcelRequire("eusud")).performPostRequestWithRetry)($501ec097caecd266$var$TRACK_PERSONALISATION_ENDPOINT, {
                body: JSON.stringify({
                    additionalParameters: additionalParameters
                })
            });
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: 'trackOnboardingPersonalisation',
                    packageName: 'jiraOnboardingCore',
                    teamName: 'jlove-makkuro'
                },
                sendToPrivacyUnsafeSplunk: true
            });
        }
    };
});
parcelRegister("fv5ut", function(module, exports) {
    $parcel$export(module.exports, "getLocalStorageOnboardingPersonalisationResponse", ()=>$673c953eac85fdf0$export$e9fb5b94c03fa495);
    $parcel$export(module.exports, "setLocalStorageOnboardingPersonalisationResponse", ()=>$673c953eac85fdf0$export$dda9cd59669fc243);
    var $bSSG7;
    var $jcw0u;
    const $673c953eac85fdf0$var$LOCALSTORAGE_ONBOARDING_PERSONALISATION_RESPONSE = 'response';
    const $673c953eac85fdf0$var$localStorage = (0, (0, parcelRequire("bSSG7")).createLocalStorageProvider)('jira-onboarding-personalisation');
    const $673c953eac85fdf0$export$e9fb5b94c03fa495 = ()=>{
        try {
            return $673c953eac85fdf0$var$localStorage.get($673c953eac85fdf0$var$LOCALSTORAGE_ONBOARDING_PERSONALISATION_RESPONSE);
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: 'localStorageGetOnboardingPersonalisation',
                    packageName: 'jiraOnboardingCore',
                    teamName: 'jlove-makkuro'
                },
                sendToPrivacyUnsafeSplunk: true
            });
            return undefined;
        }
    };
    const $673c953eac85fdf0$export$dda9cd59669fc243 = (response)=>{
        try {
            $673c953eac85fdf0$var$localStorage.set($673c953eac85fdf0$var$LOCALSTORAGE_ONBOARDING_PERSONALISATION_RESPONSE, response);
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: 'localStorageSetOnboardingPersonalisationResponse',
                    packageName: 'jiraOnboardingCore',
                    teamName: 'jlove-makkuro'
                },
                sendToPrivacyUnsafeSplunk: true
            });
        }
    };
});
parcelRegister("iAFnF", function(module, exports) {
    $parcel$export(module.exports, "ELIGIBLE_TEAM_TYPES_M2_FULL_NOTATION", ()=>$afa1ef0e1c3d15e2$export$1d38dc41bb005254);
    var $5PxqD;
    const $afa1ef0e1c3d15e2$export$1d38dc41bb005254 = [
        (0, (0, parcelRequire("5PxqD")).USER_SEGMENTATION_TEAM_TYPE_MARKETING),
        (0, (0, parcelRequire("5PxqD")).USER_SEGMENTATION_TEAM_TYPE_OPERATIONS)
    ];
});
parcelRegister("5PxqD", function(module, exports) {
    $parcel$export(module.exports, "USER_SEGMENTATION_TEAM_TYPE_MARKETING", ()=>$5d0851fa8905d151$export$e4824f74b9879164);
    $parcel$export(module.exports, "USER_SEGMENTATION_TEAM_TYPE_OPERATIONS", ()=>$5d0851fa8905d151$export$4f3cb0844009af52);
    $parcel$export(module.exports, "userBelongsToBusinessUserSegCategory", ()=>$5d0851fa8905d151$export$517f94ab07724226);
    var $jcw0u;
    var $co1wz;
    const $5d0851fa8905d151$var$USER_SEGMENTATION_ROLE_DESIGNER = 'designer';
    const $5d0851fa8905d151$export$572242e58bd07594 = 'customer-service';
    const $5d0851fa8905d151$export$d7de553940261eba = 'it-support';
    const $5d0851fa8905d151$export$4c29d0d04c15e55d = 'human-resources';
    const $5d0851fa8905d151$export$3950f7f841bf94b6 = 'finance';
    const $5d0851fa8905d151$export$881249b988b6030f = 'legal';
    const $5d0851fa8905d151$export$e4824f74b9879164 = 'marketing';
    const $5d0851fa8905d151$export$4f3cb0844009af52 = 'operations';
    const $5d0851fa8905d151$export$1a65d50c4a641bb4 = 'sales';
    const $5d0851fa8905d151$export$b8b1ac856ba1434b = 'software-development';
    const $5d0851fa8905d151$var$ALL_ROLES_ELIGIBLE = 'all-roles-eligible';
    const $5d0851fa8905d151$export$2f3f9640393bd078 = 'unknown';
    const $5d0851fa8905d151$export$4c9a72dac5ce80ae = '';
    const $5d0851fa8905d151$var$USER_SEGMENTATION_CLIENT_MAPPING = {
        [$5d0851fa8905d151$var$USER_SEGMENTATION_ROLE_DESIGNER]: [
            'Designer',
            'Disainer',
            "Dise\xf1ador",
            'Formgivare',
            "Nh\xe0 thi\u1EBFt k\u1EBF",
            'Projektant',
            'Suunnittelija',
            "Tasar\u0131mc\u0131",
            "Tervez\u0151",
            "\u0414\u0438\u0437\u0430\u0439\u043D\u0435\u0440",
            "\u0E19\u0E31\u0E01\u0E2D\u0E2D\u0E01\u0E41\u0E1A\u0E1A",
            "\u30C7\u30B6\u30A4\u30CA\u30FC",
            "\u8A2D\u8A08\u5E2B",
            "\u8BBE\u8BA1\u5E08",
            "\uB514\uC790\uC774\uB108"
        ],
        [$5d0851fa8905d151$export$572242e58bd07594]: [
            'Asiakaspalvelu',
            'Atendimento ao cliente',
            'Customer Service',
            "D\u1ECBch v\u1EE5 kh\xe1ch h\xe0ng",
            'Klantenservice',
            'Klienditeenindus',
            'Kundenservice',
            'Kundeservice',
            'Kundservice',
            "M\xfc\u015Fteri Hizmetleri",
            "Obs\u0142uga klienta",
            "Service \xe0 la client\xe8le",
            "Servicio de atenci\xf3n al cliente",
            'Servizio clienti',
            "Servi\xe7o de apoio ao cliente",
            "Z\xe1kaznick\xfd servis",
            "Z\xe1kazn\xedcky servis",
            "\xdcgyf\xe9lszolg\xe1lati",
            "\u041E\u0431\u0441\u043B\u0443\u0436\u0438\u0432\u0430\u043D\u0438\u0435 \u043A\u043B\u0438\u0435\u043D\u0442\u043E\u0432",
            "\u0421\u043B\u0443\u0436\u0431\u0430 \u043F\u0456\u0434\u0442\u0440\u0438\u043C\u043A\u0438 \u043A\u043B\u0456\u0454\u043D\u0442\u0456\u0432",
            "\u0E1A\u0E23\u0E34\u0E01\u0E32\u0E23\u0E25\u0E39\u0E01\u0E04\u0E49\u0E32",
            "\u30AB\u30B9\u30BF\u30DE\u30FC \u30B5\u30FC\u30D3\u30B9",
            "\u5BA2\u6237\u670D\u52A1",
            "\u9867\u5BA2\u670D\u52D9",
            "\uACE0\uAC1D \uC11C\uBE44\uC2A4"
        ],
        [$5d0851fa8905d151$export$d7de553940261eba]: [
            'Assistenza IT',
            "BT Deste\u011Fi",
            "H\u1ED7 tr\u1EE3 CNTT",
            'IT',
            'IT Support',
            'IT podpora',
            "IT \u30B5\u30DD\u30FC\u30C8",
            "IT \u652F\u6301",
            "IT \u652F\u63F4",
            "IT \uC9C0\uC6D0",
            'IT_Support',
            "IT_st\xf8tte",
            'IT_support',
            'IT_tugi',
            'IT_tuki',
            "Informatikai t\xe1mogat\xe1si",
            'Soporte de TI',
            'Suporte de TI',
            "Support\xa0IT",
            'Wsparcie IT',
            "\u0418\u0422-\u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0430",
            "\u0421\u043B\u0443\u0436\u0431\u0430 \u0442\u0435\u0445\u043D\u0456\u0447\u043D\u043E\u0457 \u043F\u0456\u0434\u0442\u0440\u0438\u043C\u043A\u0438",
            "\u0E1D\u0E48\u0E32\u0E22\u0E2A\u0E19\u0E31\u0E1A\u0E2A\u0E19\u0E38\u0E19 IT"
        ],
        [$5d0851fa8905d151$export$3950f7f841bf94b6]: [
            'Ekonomi',
            'Finance',
            'Financeiro',
            'Financie',
            'Finans',
            'Finanse',
            'Finants',
            'Finanza',
            'Finanzas',
            'Finanzen',
            "Finan\xe7as",
            "P\xe9nz\xfcgyi",
            'Rahoitus',
            "T\xe0i ch\xednh",
            "\xd8konomi",
            "\u0424\u0438\u043D\u0430\u043D\u0441\u044B",
            "\u0424\u0456\u043D\u0430\u043D\u0441\u0438",
            "\u0E01\u0E32\u0E23\u0E40\u0E07\u0E34\u0E19",
            "\u8CA1\u52D9",
            "\u91D1\u878D",
            "\uC7AC\uBB34"
        ],
        [$5d0851fa8905d151$export$4c29d0d04c15e55d]: [
            'HR',
            "Henkil\xf6st\xf6hallinto",
            'Human Resources',
            'Human resources',
            'human-resources',
            "Lidsk\xe9 zdroje",
            'Menneskelige ressourcer',
            "Nh\xe2n s\u1EF1",
            'Personal',
            "Personalfr\xe5gor",
            'RH',
            'Recursos Humanos',
            'Recursos humanos',
            'Risorse umane',
            "Szem\xe9lyzeti",
            'Zasoby ludzkie',
            "\u0130nsan Kaynaklar\u0131",
            "\u013Dudsk\xe9 zdroje",
            "\u0412\u0456\u0434\u0434\u0456\u043B \u043A\u0430\u0434\u0440\u0456\u0432",
            "\u041A\u0430\u0434\u0440\u043E\u0432\u044B\u0435 \u0432\u043E\u043F\u0440\u043E\u0441\u044B",
            "\u0E17\u0E23\u0E31\u0E1E\u0E22\u0E32\u0E01\u0E23\u0E1A\u0E38\u0E04\u0E04\u0E25",
            "\u4EBA\u4E8B",
            "\u4EBA\u529B\u8CC7\u6E90",
            "\u4EBA\u529B\u8D44\u6E90",
            "\uC778\uC0AC"
        ],
        [$5d0851fa8905d151$export$881249b988b6030f]: [
            "Departamento jur\xeddico",
            'Hukuk',
            'Jogi',
            'Jura',
            'Juridik',
            'Juridisk',
            "Jur\xeddico",
            'Legal',
            'Legale',
            'Oikeudelliset asiat',
            "Ph\xe1p l\xfd",
            'Prawo',
            "Pr\xe1vne oddelenie",
            "Pr\xe1vn\xed odd\u011Blen\xed",
            'Rechtliches',
            'Service juridique',
            "\xd5igus",
            "\u042E\u0440\u0438\u0434\u0438\u0447\u0435\u0441\u043A\u0438\u0435 \u0432\u043E\u043F\u0440\u043E\u0441\u044B",
            "\u042E\u0440\u0438\u0434\u0438\u0447\u043D\u0438\u0439 \u0432\u0456\u0434\u0434\u0456\u043B",
            "\u0E01\u0E0E\u0E2B\u0E21\u0E32\u0E22",
            "\u6CD5\u52D9",
            "\u6CD5\u5F8B",
            "\uBC95\uB960"
        ],
        [$5d0851fa8905d151$export$e4824f74b9879164]: [
            "Markedsf\xf8ring",
            'Marketing',
            'Markkinointi',
            "Marknadsf\xf6ring",
            'Pazarlama',
            "Ti\u1EBFp th\u1ECB",
            'Turundus',
            "\u041C\u0430\u0440\u043A\u0435\u0442\u0438\u043D\u0433",
            "\u0E01\u0E32\u0E23\u0E15\u0E25\u0E32\u0E14",
            "\u30DE\u30FC\u30B1\u30C6\u30A3\u30F3\u30B0",
            "\u8425\u9500",
            "\u884C\u92B7",
            "\uB9C8\uCF00\uD305"
        ],
        [$5d0851fa8905d151$export$4f3cb0844009af52]: [
            'Drift',
            'Haldus',
            'Operacional',
            'Operaciones',
            'Operacje',
            'Operasyonlar',
            'Operations',
            'Operativer Betrieb',
            'Operazioni',
            "Opera\xe7\xf5es",
            "Op\xe9rations",
            "Prev\xe1dzka",
            'Provoz',
            'Toiminnot',
            "V\u1EADn h\xe0nh",
            "\xdczemeltet\xe9si",
            "\u0412\u0438\u0440\u043E\u0431\u043D\u0438\u0447\u0438\u0439 \u0432\u0456\u0434\u0434\u0456\u043B",
            "\u041E\u043F\u0435\u0440\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u0435 \u0432\u043E\u043F\u0440\u043E\u0441\u044B",
            "\u0E1D\u0E48\u0E32\u0E22\u0E1B\u0E0F\u0E34\u0E1A\u0E31\u0E15\u0E34\u0E01\u0E32\u0E23",
            "\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3",
            "\u71DF\u904B",
            "\u8FD0\u8425",
            "\uC6B4\uC601"
        ],
        [$5d0851fa8905d151$export$1a65d50c4a641bb4]: [
            "B\xe1n h\xe0ng",
            "F\xf6rs\xe4ljning",
            'Myynti',
            "M\xfc\xfck",
            'Predaj',
            'Prodej',
            'Sales',
            'Salg',
            "Sat\u0131\u015F",
            "Sprzeda\u017C",
            'Vendas',
            'Vendite',
            'Ventas',
            'Ventes',
            'Vertrieb',
            "\xc9rt\xe9kes\xedt\xe9si",
            "\u041F\u0440\u043E\u0434\u0430\u0436\u0438",
            "\u041F\u0440\u043E\u0434\u0430\u0436\u0456",
            "\u0E1D\u0E48\u0E32\u0E22\u0E02\u0E32\u0E22",
            "\u30BB\u30FC\u30EB\u30B9",
            "\u92B7\u552E",
            "\u9500\u552E",
            "\uC601\uC5C5"
        ],
        [$5d0851fa8905d151$export$b8b1ac856ba1434b]: [
            'Desarrollo de software',
            'Desenvolvimento de software',
            "D\xe9veloppement logiciel",
            'Ohjelmistokehitys',
            "Ph\xe1t tri\u1EC3n ph\u1EA7n m\u1EC1m",
            'Programutveckling',
            'Programvareutvikling',
            'Software Development',
            'Softwareentwicklung',
            'Softwareontwikkeling',
            'Softwareudvikling',
            'Sviluppo software',
            "Szoftverfejleszt\u0151",
            'Tarkvaraarendus',
            'Tworzenie oprogramowania',
            "V\xfdvoj softv\xe9ru",
            "V\xfdvoj softwaru",
            "Yaz\u0131l\u0131m Geli\u015Ftirme",
            "\u0420\u0430\u0437\u0440\u0430\u0431\u043E\u0442\u043A\u0430 \u041F\u041E",
            "\u0420\u043E\u0437\u0440\u043E\u0431\u043A\u0430 \u041F\u0417",
            "\u0E1E\u0E31\u0E12\u0E19\u0E32\u0E0B\u0E2D\u0E1F\u0E15\u0E4C\u0E41\u0E27\u0E23\u0E4C",
            "\u30BD\u30D5\u30C8\u30A6\u30A7\u30A2\u958B\u767A",
            "\u8EDF\u9AD4\u958B\u767C",
            "\u8F6F\u4EF6\u5F00\u53D1",
            "\uC18C\uD504\uD2B8\uC6E8\uC5B4 \uAC1C\uBC1C"
        ]
    };
    const $5d0851fa8905d151$var$BUSINESS_TEAM_TYPES = [
        $5d0851fa8905d151$export$4c29d0d04c15e55d,
        $5d0851fa8905d151$export$3950f7f841bf94b6,
        $5d0851fa8905d151$export$881249b988b6030f,
        $5d0851fa8905d151$export$e4824f74b9879164,
        $5d0851fa8905d151$export$4f3cb0844009af52,
        $5d0851fa8905d151$export$1a65d50c4a641bb4,
        $5d0851fa8905d151$export$b8b1ac856ba1434b
    ];
    const $5d0851fa8905d151$var$BUSINESS_ROLES_PER_TEAM_TYPE = {
        [$5d0851fa8905d151$export$4c29d0d04c15e55d]: [
            $5d0851fa8905d151$var$ALL_ROLES_ELIGIBLE
        ],
        [$5d0851fa8905d151$export$3950f7f841bf94b6]: [
            $5d0851fa8905d151$var$ALL_ROLES_ELIGIBLE
        ],
        [$5d0851fa8905d151$export$881249b988b6030f]: [
            $5d0851fa8905d151$var$ALL_ROLES_ELIGIBLE
        ],
        [$5d0851fa8905d151$export$e4824f74b9879164]: [
            $5d0851fa8905d151$var$ALL_ROLES_ELIGIBLE
        ],
        [$5d0851fa8905d151$export$4f3cb0844009af52]: [
            $5d0851fa8905d151$var$ALL_ROLES_ELIGIBLE
        ],
        [$5d0851fa8905d151$export$1a65d50c4a641bb4]: [
            $5d0851fa8905d151$var$ALL_ROLES_ELIGIBLE
        ],
        [$5d0851fa8905d151$export$b8b1ac856ba1434b]: [
            $5d0851fa8905d151$var$USER_SEGMENTATION_ROLE_DESIGNER
        ]
    };
    const $5d0851fa8905d151$var$getNormalizedEntryNew = (userSegValue)=>{
        const lowerCaseUserSegValue = userSegValue.toLowerCase();
        return Object.entries($5d0851fa8905d151$var$USER_SEGMENTATION_CLIENT_MAPPING).find(([, translatedValues])=>translatedValues.map((v)=>v.toLowerCase()).includes(lowerCaseUserSegValue));
    };
    const $5d0851fa8905d151$var$getNormalizedEntry = (value)=>Object.entries($5d0851fa8905d151$var$USER_SEGMENTATION_CLIENT_MAPPING).find(([, translatedValues])=>translatedValues.includes(value));
    const $5d0851fa8905d151$export$d0bc507f0556398 = (value)=>{
        if (value === '') return $5d0851fa8905d151$export$4c9a72dac5ce80ae;
        const normalizedEntry = (0, (0, parcelRequire("co1wz")).ff)('use-upaas-to-display-template-picker-if-user-seg-is-unknown') ? $5d0851fa8905d151$var$getNormalizedEntryNew(value) : $5d0851fa8905d151$var$getNormalizedEntry(value);
        return normalizedEntry !== undefined ? normalizedEntry[0] : $5d0851fa8905d151$export$2f3f9640393bd078;
    };
    class $5d0851fa8905d151$var$SkipSentryError extends Error {
        constructor(message){
            super(message);
            this.skipSentry = true;
        }
    }
    const $5d0851fa8905d151$var$fireAnalyticsError = ({ id: id, field: field, error: error, unMatchedValue: unMatchedValue })=>{
        (0, (0, parcelRequire("jcw0u")).default)({
            meta: {
                id: id,
                packageName: 'jiraOnboardingCore'
            },
            attributes: {
                field: field,
                unMatchedValue: unMatchedValue
            },
            error: error,
            sendToPrivacyUnsafeSplunk: true
        });
    };
    const $5d0851fa8905d151$export$af8a28b856b3556f = (teamType)=>{
        if (!teamType) return false;
        const normalizedTeamType = $5d0851fa8905d151$export$d0bc507f0556398(teamType);
        if (normalizedTeamType === $5d0851fa8905d151$export$2f3f9640393bd078) $5d0851fa8905d151$var$fireAnalyticsError({
            id: 'isInSoftwareTeam',
            field: 'teamType',
            error: new $5d0851fa8905d151$var$SkipSentryError('teamType mapping is not found'),
            unMatchedValue: teamType
        });
        return normalizedTeamType === $5d0851fa8905d151$export$b8b1ac856ba1434b;
    };
    const $5d0851fa8905d151$export$517f94ab07724226 = (userAccountData)=>{
        const { teamType: teamType = '', role: role = '' } = userAccountData;
        const normalizedTeamType = $5d0851fa8905d151$export$d0bc507f0556398(teamType);
        if (normalizedTeamType === $5d0851fa8905d151$export$2f3f9640393bd078) $5d0851fa8905d151$var$fireAnalyticsError({
            id: 'userBelongsToBusinessUserSegCategory',
            field: 'teamType',
            error: new $5d0851fa8905d151$var$SkipSentryError('teamType mapping is not found'),
            unMatchedValue: teamType
        });
        const normalizedRole = $5d0851fa8905d151$export$d0bc507f0556398(role);
        if (normalizedRole === $5d0851fa8905d151$export$2f3f9640393bd078) $5d0851fa8905d151$var$fireAnalyticsError({
            id: 'userBelongsToBusinessUserSegCategory',
            field: 'role',
            error: new $5d0851fa8905d151$var$SkipSentryError('role mapping is not found'),
            unMatchedValue: role
        });
        const isTeamTypeEligible = $5d0851fa8905d151$var$BUSINESS_TEAM_TYPES.includes(normalizedTeamType);
        const isRoleEligible = $5d0851fa8905d151$var$BUSINESS_ROLES_PER_TEAM_TYPE[normalizedTeamType]?.includes($5d0851fa8905d151$var$ALL_ROLES_ELIGIBLE) || $5d0851fa8905d151$var$BUSINESS_ROLES_PER_TEAM_TYPE[normalizedTeamType]?.includes(normalizedRole);
        return isTeamTypeEligible && isRoleEligible;
    };
    const $5d0851fa8905d151$export$6710e0127706b297 = (userAccountData)=>{
        const { teamType: teamType = '', role: role = '' } = userAccountData;
        const normalizedTeamType = $5d0851fa8905d151$export$d0bc507f0556398(teamType);
        if (normalizedTeamType === $5d0851fa8905d151$export$2f3f9640393bd078) $5d0851fa8905d151$var$fireAnalyticsError({
            id: 'userBelongsToSoftwareUserSegCategory',
            field: 'teamType',
            error: new $5d0851fa8905d151$var$SkipSentryError('teamType mapping is not found'),
            unMatchedValue: teamType
        });
        const normalizedRole = $5d0851fa8905d151$export$d0bc507f0556398(role);
        if (normalizedRole === $5d0851fa8905d151$export$2f3f9640393bd078) $5d0851fa8905d151$var$fireAnalyticsError({
            id: 'userBelongsToSoftwareUserSegCategory',
            field: 'role',
            error: new $5d0851fa8905d151$var$SkipSentryError('role mapping is not found'),
            unMatchedValue: role
        });
        const isTeamTypeEligible = normalizedTeamType === $5d0851fa8905d151$export$b8b1ac856ba1434b;
        const isRoleEligible = normalizedRole !== $5d0851fa8905d151$var$USER_SEGMENTATION_ROLE_DESIGNER;
        return isTeamTypeEligible && isRoleEligible;
    };
});
parcelRegister("1OQiY", function(module, exports) {
    $parcel$export(module.exports, "isTailoredOnboardingM2ExperimentFlagEnabled", ()=>$58aa46dba9dc7a65$export$4f4879e3b4202c87);
    var $6s1PB;
    var $4R6GH;
    const $58aa46dba9dc7a65$export$4f4879e3b4202c87 = ()=>{
        const defaultValue = (0, (0, parcelRequire("4R6GH")).fg)('tailored_onboarding_m2_content_exposure_adjust') ? undefined : false;
        const [expConfig] = (0, (0, parcelRequire("6s1PB")).UNSAFE_noExposureExp)('jira_tailored_onboarding_jtbd');
        return expConfig.get('isEnabled', defaultValue);
    };
});
parcelRegister("9USr3", function(module, exports) {
    $parcel$export(module.exports, "useRegisterNudgeTarget", ()=>$1aa5136c8225a4e1$export$eb8a96124d659f2d);
    $parcel$export(module.exports, "PortalledNudge", ()=>$1aa5136c8225a4e1$export$74f8d45d75dfda4f);
    var $5uXOq = parcelRequire("5uXOq");
    var $7nsd3 = parcelRequire("7nsd3");
    var $fqbGj;
    class $1aa5136c8225a4e1$var$NudgeController {
        static{
            this.instance = new $1aa5136c8225a4e1$var$NudgeController();
        }
        registerNudgeTarget(id, ref) {
            this.nudgeTargetsMap.set(id, ref);
            this.notifySubscribers(id);
            return ()=>{
                this.nudgeTargetsMap.delete(id);
            };
        }
        subscribe(id, callback) {
            const subscribers = this.subscribers.get(id) ?? [];
            subscribers.push(callback);
            this.subscribers.set(id, subscribers);
            return ()=>{
                const subscribersOnCleanup = this.subscribers.get(id) ?? [];
                this.subscribers.set(id, subscribersOnCleanup.filter((cb)=>cb !== callback));
            };
        }
        notifySubscribers(id) {
            const subscribers = this.subscribers.get(id) ?? [];
            subscribers.forEach((subscriber)=>{
                subscriber();
            });
        }
        constructor(){
            this.nudgeTargetsMap = new Map();
            this.subscribers = new Map();
        }
    }
    const $1aa5136c8225a4e1$var$useNudgeController = ()=>$1aa5136c8225a4e1$var$NudgeController.instance;
    const $1aa5136c8225a4e1$export$eb8a96124d659f2d = (id, shouldRegister = true)=>{
        const controller = $1aa5136c8225a4e1$var$useNudgeController();
        const ref = (0, $5uXOq.useRef)(null);
        (0, $5uXOq.useLayoutEffect)(()=>{
            if (shouldRegister) {
                const unregister = controller.registerNudgeTarget(id, ref);
                return ()=>{
                    unregister();
                };
            }
        }, [
            controller,
            id,
            shouldRegister
        ]);
        return {
            ref: ref
        };
    };
    const $1aa5136c8225a4e1$var$useHasNudgeTargetNode = (id)=>{
        const controller = $1aa5136c8225a4e1$var$useNudgeController();
        const [hasRefValue, setHasRefValue] = (0, $5uXOq.useState)(!!controller.nudgeTargetsMap.get(id)?.current);
        (0, $5uXOq.useLayoutEffect)(()=>{
            setHasRefValue(!!controller.nudgeTargetsMap.get(id)?.current);
            const unsubscribe = controller.subscribe(id, ()=>{
                setHasRefValue(!!controller.nudgeTargetsMap.get(id)?.current);
            });
            return ()=>{
                unsubscribe();
            };
        }, [
            controller,
            id
        ]);
        return hasRefValue;
    };
    const $1aa5136c8225a4e1$var$useNudgeRectangle = (isShown, id, nudgeContainer)=>{
        const [innerRectangle, setInnerRectangle] = (0, $5uXOq.useState)(null);
        const frameId = (0, $5uXOq.useRef)(null);
        const controller = $1aa5136c8225a4e1$var$useNudgeController();
        const nudgeTarget = controller.nudgeTargetsMap.get(id)?.current;
        (0, $5uXOq.useLayoutEffect)(()=>{
            if (!isShown || !nudgeTarget || !nudgeContainer.current) {
                if (innerRectangle !== null) setInnerRectangle(null);
                return undefined;
            }
            const loop = ()=>{
                const targetRectangle = controller.nudgeTargetsMap.get(id)?.current?.getBoundingClientRect();
                if (!targetRectangle) return undefined;
                const newRectangle = {
                    top: targetRectangle.top,
                    left: targetRectangle.left,
                    width: targetRectangle.width,
                    height: targetRectangle.height
                };
                if (innerRectangle == null || innerRectangle.top !== newRectangle.top || innerRectangle.left !== newRectangle.left || innerRectangle.width !== newRectangle.width || innerRectangle.height !== newRectangle.height) setInnerRectangle(newRectangle);
                else frameId.current = requestAnimationFrame(loop);
            };
            frameId.current = requestAnimationFrame(loop);
            return ()=>{
                if (frameId.current != null) cancelAnimationFrame(frameId.current);
            };
        }, [
            isShown,
            nudgeContainer,
            innerRectangle,
            nudgeTarget,
            controller.nudgeTargetsMap,
            id
        ]);
        return innerRectangle;
    };
    const $1aa5136c8225a4e1$var$usePortalContainer = ()=>{
        const nudgeContainer = (0, $5uXOq.useRef)(null);
        (0, $5uXOq.useLayoutEffect)(()=>{
            if (!nudgeContainer.current) {
                nudgeContainer.current = document.createElement('div');
                document.body.appendChild(nudgeContainer.current);
            }
            return ()=>{
                if (nudgeContainer.current) document.body.removeChild(nudgeContainer.current);
            };
        }, []);
        return nudgeContainer;
    };
    const $1aa5136c8225a4e1$export$74f8d45d75dfda4f = (props)=>{
        const nudgeContainer = $1aa5136c8225a4e1$var$usePortalContainer();
        const hasRefValue = $1aa5136c8225a4e1$var$useHasNudgeTargetNode(props.nudgeTargetId);
        const innerRectangle = $1aa5136c8225a4e1$var$useNudgeRectangle(!props.hidden, props.nudgeTargetId, nudgeContainer);
        const innerRectanglePrevious = (0, $5uXOq.useRef)(innerRectangle);
        const spotlightRef = (0, $5uXOq.useRef)(null);
        (0, $5uXOq.useEffect)(()=>{
            if (innerRectangle !== null && innerRectanglePrevious.current !== innerRectangle && spotlightRef.current !== null) {
                innerRectanglePrevious.current = innerRectangle;
                if (spotlightRef.current.forceUpdateCardPosition) spotlightRef.current.forceUpdateCardPosition();
            }
        }, [
            innerRectangle
        ]);
        const hasNudgeTarget = hasRefValue;
        if (!nudgeContainer.current || !hasNudgeTarget || !innerRectangle) return null;
        return (0, $7nsd3.createPortal)((0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            style: {
                position: 'fixed',
                top: innerRectangle.top,
                left: innerRectangle.left,
                height: innerRectangle.height,
                width: innerRectangle.width,
                zIndex: props.zIndex
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fqbGj")).NudgeSpotlight), {
            ...props,
            ref: spotlightRef
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            style: {
                height: innerRectangle.height,
                width: innerRectangle.width
            }
        }))), nudgeContainer.current);
    };
});
parcelRegister("at1Nr", function(module, exports) {
    $parcel$export(module.exports, "InviteTeamNudgeAsync", ()=>$8b5bfc2ae277a997$export$a4b4061b22dbc897);
    var $5uXOq = parcelRequire("5uXOq");
    var $7XluA;
    var $4mO32;
    var $cE2Qr;
    var $ahfXN;
    const $8b5bfc2ae277a997$var$runtimeProps = {};
    const $8b5bfc2ae277a997$var$entryPointParams = {};
    const $8b5bfc2ae277a997$export$a4b4061b22dbc897 = ()=>{
        const { entryPointActions: entryPointActions, entryPointReferenceSubject: entryPointReferenceSubject } = (0, (0, parcelRequire("4mO32")).useEntryPoint)((0, (0, parcelRequire("ahfXN")).inviteTeamNudgeEntryPoint), $8b5bfc2ae277a997$var$entryPointParams);
        const [nudgeStep] = (0, (0, parcelRequire("cE2Qr")).useNudgeStep)();
        const [hasNudgeActive] = (0, (0, parcelRequire("cE2Qr")).useHasNudgeActive)();
        const firstLoadRef = (0, $5uXOq.useRef)(true);
        (0, $5uXOq.useEffect)(()=>{
            if (firstLoadRef.current && hasNudgeActive && nudgeStep === (0, $6784cbc7d62e5d7c$export$77bc79debbf5c069)) {
                entryPointActions.load();
                firstLoadRef.current = false;
            }
        }, [
            entryPointActions,
            hasNudgeActive,
            nudgeStep
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7XluA")).JiraEntryPointContainer), {
            id: (0, $dbbf68c32d8c4308$export$3bc551fe07069f89),
            packageName: "jiraInviteTeamOnboarding",
            entryPointReferenceSubject: entryPointReferenceSubject,
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null),
            runtimeProps: $8b5bfc2ae277a997$var$runtimeProps
        });
    };
});
parcelRegister("ahfXN", function(module, exports) {
    $parcel$export(module.exports, "inviteTeamNudgeEntryPoint", ()=>$140395164e9a1c0c$export$ff76bc343b4103b1);
    var $eufEc;
    var $fEDBv;
    const $140395164e9a1c0c$export$ff76bc343b4103b1 = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("8kZhT")), {
            moduleId: "4ca4d8af"
        }),
        getPreloadProps: ()=>({})
    });
});
parcelRegister("8kZhT", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("il1NS").then(()=>parcelRequire('guBtq'));
});
parcelRegister("69X1g", function(module, exports) {
    $parcel$export(module.exports, "NavigationPeopleNavItemWrapperNudgeAsync", ()=>$affd57fc52973db7$export$57ea67608b7cb448);
    var $5uXOq = parcelRequire("5uXOq");
    var $bBbEG;
    var $lfTZh;
    var $kxZPA;
    const $affd57fc52973db7$var$NavigationPeopleNavItemLazy = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("3Fy1g")).then((exportedModule)=>exportedModule.NavigationPeopleNavItemWrapperNudge), {
        ssr: false,
        moduleId: "./src/packages/software/onboarding-nudges/src/ui/navigation-people/index.tsx"
    });
    const $affd57fc52973db7$export$57ea67608b7cb448 = ({ children: children, isInsideMenu: isInsideMenu })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bBbEG")).OnboardingNudgeErrorBoundary), {
            id: (0, $6784cbc7d62e5d7c$export$b74a8fdb2c75b869),
            fallback: children,
            attributes: {
                nudgeId: (0, $6784cbc7d62e5d7c$export$b74a8fdb2c75b869)
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "navigation-people-nav-item-lazy",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($affd57fc52973db7$var$NavigationPeopleNavItemLazy, {
            nudgeId: (0, $6784cbc7d62e5d7c$export$b74a8fdb2c75b869),
            isInsideMenu: isInsideMenu
        }, children)));
});
parcelRegister("3Fy1g", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("cG8r2").then(()=>parcelRequire('5NaVN'));
});
parcelRegister("annPD", function(module, exports) {
    $parcel$export(module.exports, "useBrowseUsersGroupsPermissionsNext", ()=>$72cc6e3d980d69b6$export$f16858a6f1359756);
    $parcel$export(module.exports, "useBrowseUsersGroupsPermissions", ()=>$72cc6e3d980d69b6$export$c12f7f185a3a245f);
    var $5uXOq = parcelRequire("5uXOq");
    var $4R6GH;
    var $eusud;
    var $6yXtV;
    const $72cc6e3d980d69b6$var$deferredPromise = ()=>{
        const deferred = {
            resolve: undefined,
            reject: undefined
        };
        const promise = new Promise((resolve, reject)=>{
            deferred.resolve = (result)=>{
                resolve(result);
            };
            deferred.reject = reject;
        });
        return {
            promise: promise,
            ...deferred
        };
    };
    class $72cc6e3d980d69b6$var$BrowseUsersGroupsCacheClient {
        fetch() {
            const requestUrl = '/rest/internal/latest/userPermission/browseUsersGroups';
            if (this.promise !== null) return this.promise;
            this.promise = (0, (0, parcelRequire("eusud")).performGetRequest)(requestUrl);
            return this.promise;
        }
        constructor(){
            this.promise = null;
        }
    }
    const $72cc6e3d980d69b6$var$client = new $72cc6e3d980d69b6$var$BrowseUsersGroupsCacheClient();
    const $72cc6e3d980d69b6$export$f16858a6f1359756 = (force)=>{
        const [{ isReady: isReady }] = (0, (0, parcelRequire("6yXtV")).useSpaStatePageReady)();
        const [{ promise: promise, resolve: resolve, reject: reject }] = (0, $5uXOq.useState)($72cc6e3d980d69b6$var$deferredPromise());
        (0, $5uXOq.useEffect)(()=>{
            if ((isReady || force) && (0, (0, parcelRequire("4R6GH")).fg)('dont-auto-close-people-menu')) $72cc6e3d980d69b6$var$client.fetch().then(resolve).catch(reject);
        }, [
            isReady,
            reject,
            resolve,
            force
        ]);
        return (0, $5uXOq.useCallback)(()=>promise, [
            promise
        ]);
    };
    const $72cc6e3d980d69b6$export$c12f7f185a3a245f = ()=>{
        const [{ isReady: isReady }] = (0, (0, parcelRequire("6yXtV")).useSpaStatePageReady)();
        const [{ promise: promise, resolve: resolve, reject: reject }] = (0, $5uXOq.useState)($72cc6e3d980d69b6$var$deferredPromise());
        (0, $5uXOq.useEffect)(()=>{
            if (isReady && !(0, (0, parcelRequire("4R6GH")).fg)('dont-auto-close-people-menu')) {
                const requestUrl = '/rest/internal/latest/userPermission/browseUsersGroups';
                (0, (0, parcelRequire("eusud")).performGetRequest)(requestUrl).then(resolve).catch(reject);
            }
        }, [
            isReady,
            reject,
            resolve
        ]);
        return (0, $5uXOq.useCallback)(()=>promise, [
            promise
        ]);
    };
});
parcelRegister("k5sB5", function(module, exports) {
    $parcel$export(module.exports, "useTPAExperiment", ()=>$dda6ff809c818167$export$f72f7a0d0e0f9566);
    var $5uXOq = parcelRequire("5uXOq");
    var $5vjD3;
    var $8zOmE;
    var $2NMNM;
    const $dda6ff809c818167$export$f72f7a0d0e0f9566 = ()=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { value: value, explanation: explanation } = (0, (0, parcelRequire("5vjD3")).getFeatureFlagClient)().getFlagEvaluation('confluence.frontend.growth.team-product-access', {
            default: false
        });
        const reason = explanation?.kind ?? '';
        return (0, $5uXOq.useMemo)(()=>({
                enabled: value,
                reason: reason,
                fireFeatureExposed: ()=>{
                    const event = createAnalyticsEvent({});
                    const cohort = value ? 'variation' : 'not-enrolled';
                    event.context.push({
                        source: 'team-create-dialog',
                        tags: [
                            'people-teams'
                        ]
                    });
                    (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(event, 'feature exposed', {
                        flagKey: 'confluence.frontend.growth.team-product-access',
                        cohort: !value && reason === 'RULE_MATCH' ? 'control' : cohort,
                        value: value,
                        reason: reason
                    });
                },
                growthHoldoutCohort: 'variation'
            }), [
            createAnalyticsEvent,
            reason,
            value
        ]);
    };
});
parcelRegister("ccG8Y", function(module, exports) {
    $parcel$export(module.exports, "getInvitedJswUsers", ()=>$4cc455613015c89b$export$4b81d3bf9fd2c126);
    var $jcw0u;
    var $dXq4S;
    const $4cc455613015c89b$export$4b81d3bf9fd2c126 = (inviteUsersResponse)=>{
        try {
            if (inviteUsersResponse.status === 'fulfilled' && inviteUsersResponse.value.responseStatus === 'success' && 'invited' in inviteUsersResponse.value.response) {
                const invitedUsers = inviteUsersResponse.value.response.invited;
                return invitedUsers.filter((invitedUser)=>{
                    const productInvites = Object.keys(invitedUser.results);
                    return productInvites.some((invite)=>{
                        const ari = (0, (0, parcelRequire("dXq4S")).toAri)(invite);
                        return ari ? (0, (0, parcelRequire("dXq4S")).getAriConfig)(ari).resourceOwner === (0, $c06e1abca7c13910$export$1346c9aa3cbb477d) && invitedUser.results[invite] === 'INVITED' : false;
                    });
                });
            }
        } catch (e) {
            (0, (0, parcelRequire("jcw0u")).default)({
                error: e,
                meta: {
                    id: 'getInvitedJswUsers',
                    packageName: (0, $05e1e29a026d4606$export$b8b9d90f9e5bd72b),
                    teamName: 'growth-tako'
                }
            });
        }
        return [];
    };
});
parcelRegister("1mNGt", function(module, exports) {
    $parcel$export(module.exports, "Plans", ()=>$73b77f33bee79d45$export$fa027c23da0a12ea);
    $parcel$export(module.exports, "OpenOverflowMenuForPlansSpotlightObserver", ()=>$73b77f33bee79d45$export$c741bc6980da6a32);
    var $5uXOq = parcelRequire("5uXOq");
    var $2bzBV;
    var $bcn6L;
    var $koVbs;
    var $lmyls;
    var $8Bc5A;
    var $cE2Qr;
    var $88fdg;
    var $h9iZl;
    var $Mp6qH;
    var $4uXxK;
    var $4XiFK;
    var $ebYJ0;
    var $7Fvqy;
    var $edhzr;
    var $2eMU9;
    var $2FrC0;
    const $73b77f33bee79d45$var$PlansDropdown = ()=>{
        const [isPlansVisible] = (0, (0, parcelRequire("h9iZl")).useNavigationPlansVisibility)();
        const [{ hasFetchedOnce: hasFetchedOncePlans }, { loadFavoritePlans: loadFavorite }] = (0, (0, parcelRequire("bcn6L")).useFavoritePlans)();
        const [{ hasSuccessOnce: hasSuccessOnce }, { load: load }] = (0, (0, parcelRequire("88fdg")).usePlansItems)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const isStandardOrFree = (0, (0, parcelRequire("7Fvqy")).useIsStandardOrFreeJSW)();
        (0, $5uXOq.useEffect)(()=>{
            !hasSuccessOnce && !isStandardOrFree && isPlansVisible && load();
        }, [
            hasSuccessOnce,
            load,
            isPlansVisible,
            isStandardOrFree
        ]);
        const onBeforeMenuOpen = (0, $5uXOq.useCallback)(()=>{
            if (!isStandardOrFree) {
                load();
                if (!hasFetchedOncePlans) loadFavorite((0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV));
            }
        }, [
            load,
            loadFavorite
        ]);
        if (!isPlansVisible) return null;
        const FinalMenuTrigger = (0, $5uXOq.forwardRef)((props, ref)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("edhzr")).MenuTrigger), {
                ...props,
                ref: ref
            }));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4XiFK")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ebYJ0")).Popup), {
            menuId: (0, (0, parcelRequire("lmyls")).MENU_ID).PLANS,
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).PLANS,
            Menu: (0, (0, parcelRequire("2eMU9")).default),
            MenuTrigger: FinalMenuTrigger,
            onBeforeMenuOpen: onBeforeMenuOpen,
            label: formatMessage((0, (0, parcelRequire("2FrC0")).default).plans)
        }, formatMessage((0, (0, parcelRequire("2FrC0")).default).plans)));
    };
    const $73b77f33bee79d45$export$fa027c23da0a12ea = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).PLANS
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($73b77f33bee79d45$var$PlansDropdown, null));
    const $73b77f33bee79d45$export$c741bc6980da6a32 = ()=>{
        const [isPlansVisible] = (0, (0, parcelRequire("h9iZl")).useNavigationPlansVisibility)();
        const [shouldShow] = (0, (0, parcelRequire("cE2Qr")).useShouldShowNudge)({
            itemNames: [
                (0, (0, parcelRequire("8Bc5A")).ADVANCED_ROADMAPS_TASK)
            ]
        });
        const WRAPPER_UID = 'plans-spotlight-target-wrapper';
        const spotlightVisible = !false && document.querySelector(`[data-uid="${WRAPPER_UID}"]`) != null;
        const { openOverflowMenu: openOverflowMenu } = (0, (0, parcelRequire("2bzBV")).useOverflowStatus)();
        (0, $5uXOq.useEffect)(()=>{
            isPlansVisible && shouldShow && !spotlightVisible && openOverflowMenu();
        }, [
            isPlansVisible,
            openOverflowMenu,
            shouldShow,
            spotlightVisible
        ]);
        return null;
    };
});
parcelRegister("7Fvqy", function(module, exports) {
    $parcel$export(module.exports, "useIsStandardOrFreeJSW", ()=>$ac0a44b8f9a76c7a$export$5edaea31b41b30b);
    $parcel$export(module.exports, "useIsPremiumJSW", ()=>$ac0a44b8f9a76c7a$export$d245a3118a8b4004);
    $parcel$export(module.exports, "useIsUnlicensedJSW", ()=>$ac0a44b8f9a76c7a$export$47ec61465121ff53);
    $parcel$export(module.exports, "useIsPremiumJPD", ()=>$ac0a44b8f9a76c7a$export$4d74a4fb813f5cec);
    var $efvDm;
    var $ldyPw;
    var $k9JTq;
    const $ac0a44b8f9a76c7a$export$a2bbf41e67157372 = ()=>{
        const appEditions = (0, (0, parcelRequire("k9JTq")).useAppEditions)();
        const jswEdition = appEditions ? (0, (0, parcelRequire("ldyPw")).getEdition)((0, (0, parcelRequire("efvDm")).JIRA_SOFTWARE), appEditions) : undefined;
        return jswEdition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION);
    };
    const $ac0a44b8f9a76c7a$export$a55cc88b2381611c = ()=>{
        const appEditions = (0, (0, parcelRequire("k9JTq")).useAppEditions)();
        const jswEdition = appEditions ? (0, (0, parcelRequire("ldyPw")).getEdition)((0, (0, parcelRequire("efvDm")).JIRA_SOFTWARE), appEditions) : undefined;
        return jswEdition === (0, (0, parcelRequire("ldyPw")).FREE_EDITION);
    };
    const $ac0a44b8f9a76c7a$export$5edaea31b41b30b = ()=>{
        const appEditions = (0, (0, parcelRequire("k9JTq")).useAppEditions)();
        const jswEdition = appEditions ? (0, (0, parcelRequire("ldyPw")).getEdition)((0, (0, parcelRequire("efvDm")).JIRA_SOFTWARE), appEditions) : undefined;
        return jswEdition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION) || jswEdition === (0, (0, parcelRequire("ldyPw")).FREE_EDITION);
    };
    const $ac0a44b8f9a76c7a$export$d245a3118a8b4004 = ()=>{
        const appEditions = (0, (0, parcelRequire("k9JTq")).useAppEditions)();
        const jswEdition = appEditions ? (0, (0, parcelRequire("ldyPw")).getEdition)((0, (0, parcelRequire("efvDm")).JIRA_SOFTWARE), appEditions) : undefined;
        return jswEdition === (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION);
    };
    const $ac0a44b8f9a76c7a$export$47ec61465121ff53 = ()=>{
        const appEditions = (0, (0, parcelRequire("k9JTq")).useAppEditions)();
        const jswEdition = appEditions ? (0, (0, parcelRequire("ldyPw")).getEdition)((0, (0, parcelRequire("efvDm")).JIRA_SOFTWARE), appEditions) : undefined;
        return !jswEdition || jswEdition === (0, (0, parcelRequire("ldyPw")).UNLICENSED);
    };
    const $ac0a44b8f9a76c7a$export$4d74a4fb813f5cec = ()=>{
        const appEditions = (0, (0, parcelRequire("k9JTq")).useAppEditions)();
        const jpdEdition = appEditions ? (0, (0, parcelRequire("ldyPw")).getEdition)((0, (0, parcelRequire("efvDm")).JIRA_PRODUCT_DISCOVERY), appEditions) : undefined;
        return jpdEdition === (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION);
    };
});
parcelRegister("edhzr", function(module, exports) {
    $parcel$export(module.exports, "MenuTrigger", ()=>$4c1d4dc7eac32f82$export$27d2ad3c5815583e);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVbkk;
    var $fcc8T;
    const $4c1d4dc7eac32f82$export$27d2ad3c5815583e = (0, $5uXOq.forwardRef)((props, ref)=>{
        if (!false) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fcc8T")).MenuTrigger), {
            ...props,
            ref: ref
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVbkk")).MenuTrigger), {
            ...props,
            ref: ref
        });
    });
});
parcelRegister("fcc8T", function(module, exports) {
    $parcel$export(module.exports, "MenuTrigger", ()=>$4bbcc669498d0877$export$27d2ad3c5815583e);
    var $5uXOq = parcelRequire("5uXOq");
    var $jZ4fr;
    var $2bzBV;
    var $imZsD;
    var $5Fk4Z;
    var $dC5iT;
    var $8CUq3;
    var $lmyls;
    var $2Ui4E;
    var $8Bc5A;
    var $cE2Qr;
    var $88fdg;
    var $efvDm;
    var $ldyPw;
    var $dsvCp;
    var $8QMHF;
    var $k9JTq;
    var $fVbkk;
    var $j94qH;
    const $4bbcc669498d0877$var$WRAPPER_UID = 'plans-spotlight-target-wrapper';
    const $4bbcc669498d0877$var$useDelayedSpotlight = ()=>{
        const [ready, setSpotlightReady] = (0, $5uXOq.useState)(false);
        (0, $5uXOq.useLayoutEffect)(()=>{
            const timeoutId = setTimeout(()=>setSpotlightReady(true), 100);
            return ()=>clearTimeout(timeoutId);
        }, []);
        return ready;
    };
    const $4bbcc669498d0877$var$useWrapper = ()=>{
        const ref = (0, $5uXOq.useRef)(null);
        return {
            ref: ref
        };
    };
    const $4bbcc669498d0877$var$state = {
        hasSpotlightShown: false,
        hasSpotlightDone: false
    };
    const $4bbcc669498d0877$var$useSpotlightForceOpen = ()=>{
        const { isVisible: isVisible, openOverflowMenu: openOverflowMenu } = (0, (0, parcelRequire("2bzBV")).useOverflowStatus)();
        const isDropdownItem = !isVisible;
        (0, $5uXOq.useLayoutEffect)(()=>()=>{
                const updateState = ()=>{
                    const spotlightVisible = document.querySelector(`[data-uid="${$4bbcc669498d0877$var$WRAPPER_UID}"]`) != null;
                    $4bbcc669498d0877$var$state.hasSpotlightShown = $4bbcc669498d0877$var$state.hasSpotlightShown || spotlightVisible;
                    if (isDropdownItem && spotlightVisible && $4bbcc669498d0877$var$state.hasSpotlightShown && !$4bbcc669498d0877$var$state.hasSpotlightDone) openOverflowMenu();
                    if ($4bbcc669498d0877$var$state.hasSpotlightShown && !spotlightVisible) $4bbcc669498d0877$var$state.hasSpotlightDone = true;
                };
                queueMicrotask(updateState);
            }, [
            isDropdownItem,
            openOverflowMenu
        ]);
    };
    const $4bbcc669498d0877$export$56d544c4f479b7fb = ({ children: children, isVisible: isVisible })=>{
        const shouldShowNudge = (0, (0, parcelRequire("dsvCp")).useCanShowQuickstartNudge)();
        return shouldShowNudge && !false ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8QMHF")).NavigationPlansNavItemWrapperNudgeAsync), {
            isInsideMenu: !isVisible
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j94qH")).FullHeightTextColorWrapper), null, children)) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
    };
    const $4bbcc669498d0877$var$planOnboardingNudgeProps = !false ? {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    } : {};
    const $4bbcc669498d0877$export$27d2ad3c5815583e = (0, $5uXOq.forwardRef)((props, triggerRef)=>{
        const wrapper = $4bbcc669498d0877$var$useWrapper();
        const ready = $4bbcc669498d0877$var$useDelayedSpotlight();
        const appEditions = (0, (0, parcelRequire("k9JTq")).useAppEditions)();
        const jswEdition = appEditions ? (0, (0, parcelRequire("ldyPw")).getEdition)((0, (0, parcelRequire("efvDm")).JIRA_SOFTWARE), appEditions) : undefined;
        const [shouldShow] = (0, (0, parcelRequire("cE2Qr")).useShouldShowNudge)({
            itemNames: [
                (0, (0, parcelRequire("8Bc5A")).ADVANCED_ROADMAPS_TASK)
            ]
        });
        $4bbcc669498d0877$var$useSpotlightForceOpen();
        const { isVisible: isVisible } = (0, (0, parcelRequire("2bzBV")).useOverflowStatus)();
        const [{ items: items }] = (0, (0, parcelRequire("88fdg")).usePlansItems)();
        const analyticsAttributes = {
            isAbleToCreateSamplePlan: !!items?.links?.createSamplePlan?.isVisible,
            ...jswEdition ? {
                jswEdition: jswEdition
            } : {}
        };
        const [isMenuOpen, { toggle: toggleMenu }] = (0, (0, parcelRequire("2Ui4E")).useTopMenus)((0, (0, parcelRequire("lmyls")).MENU_ID).PLANS);
        (0, $5uXOq.useEffect)(()=>{
            if (shouldShow && !isMenuOpen) toggleMenu();
        }, [
            isMenuOpen,
            shouldShow,
            toggleMenu
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            backgroundColor: "color.background.neutral.subtle",
            xcss: $4bbcc669498d0877$var$triggerWrapperStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("imZsD")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Fk4Z")).default), {
            name: "nav-advanced-roadmaps-plans-spotlight"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            "data-uid": $4bbcc669498d0877$var$WRAPPER_UID,
            ref: wrapper.ref,
            backgroundColor: "color.background.neutral.subtle",
            xcss: $4bbcc669498d0877$var$triggerWrapperStyles,
            ...$4bbcc669498d0877$var$planOnboardingNudgeProps
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($4bbcc669498d0877$export$56d544c4f479b7fb, {
            isVisible: isVisible
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVbkk")).MenuTrigger), {
            ref: triggerRef,
            analyticsAttributes: analyticsAttributes,
            ...props
        })))), ready && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jZ4fr")).EngagementSpotlight), {
            engagementId: "nav-advanced-roadmaps-plans-spotlight"
        })));
    });
    const $4bbcc669498d0877$var$triggerWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        height: '100%',
        display: 'flex',
        flexGrow: 1
    });
});
parcelRegister("8QMHF", function(module, exports) {
    $parcel$export(module.exports, "NavigationPlansNavItemWrapperNudgeAsync", ()=>$721bcfa7775fc750$export$e2e248a8ac2b2891);
    var $5uXOq = parcelRequire("5uXOq");
    var $bBbEG;
    var $lfTZh;
    var $kxZPA;
    const $721bcfa7775fc750$var$NavigationPlansNavItemLazy = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("cxAXK")).then((exportedModule)=>exportedModule.NavigationPlansNavItemWrapperNudge), {
        ssr: false,
        moduleId: "./src/packages/software/onboarding-nudges/src/ui/navigation-plans/index.tsx"
    });
    const $721bcfa7775fc750$export$e2e248a8ac2b2891 = ({ children: children, isInsideMenu: isInsideMenu })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bBbEG")).OnboardingNudgeErrorBoundary), {
            id: (0, $6784cbc7d62e5d7c$export$46d9a3d441dfd22b),
            fallback: children,
            attributes: {
                nudgeId: (0, $6784cbc7d62e5d7c$export$46d9a3d441dfd22b)
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "software-onboarding-navigation-plans-nudge",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($721bcfa7775fc750$var$NavigationPlansNavItemLazy, {
            nudgeId: (0, $6784cbc7d62e5d7c$export$46d9a3d441dfd22b),
            isInsideMenu: isInsideMenu
        }, children)));
});
parcelRegister("cxAXK", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("6qSxH").then(()=>parcelRequire('gf2wp'));
});
parcelRegister("2eMU9", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$c5668dcd9601b742$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $9UCCQ;
    var $imZsD;
    var $5Fk4Z;
    var $lujEp;
    var $baQVS;
    var $g0Gtc;
    var $39xOx;
    var $bcn6L;
    var $lmyls;
    var $6cNb0;
    var $lfTZh;
    var $Mp6qH;
    var $8vzcp;
    var $ag3sS;
    var $9TMfX;
    var $6WLja;
    var $7Fvqy;
    var $hZbyv;
    var $42REw;
    var $53HSR;
    var $6MUS3;
    var $5n6PC;
    var $aProA;
    const $c5668dcd9601b742$var$EVENT_DATA = {
        id: (0, (0, parcelRequire("lmyls")).MENU_ID).PLANS
    };
    const $c5668dcd9601b742$var$Menu = ({ ActionFooter: ActionFooter = (0, (0, parcelRequire("hZbyv")).ActionFooter), AdminFooter: AdminFooter = (0, (0, parcelRequire("42REw")).AdminFooter), Content: Content = (0, (0, parcelRequire("53HSR")).default), FavoriteContent: FavoriteContent = (0, (0, parcelRequire("6MUS3")).FavoriteContent), Error: Error = (0, (0, parcelRequire("8vzcp")).MenuError), JSErrorBoundary: JSErrorBoundary = (0, (0, parcelRequire("39xOx")).JSErrorBoundary), Layout: Layout = (0, (0, parcelRequire("ag3sS")).MenuLayout), LoadStatus: LoadStatus = (0, (0, parcelRequire("5n6PC")).LoadStatus), Section: Section = (0, (0, parcelRequire("9UCCQ")).default), SkeletonContent: SkeletonContent = (0, (0, parcelRequire("9TMfX")).MenuSkeletonContent), SkeletonFooter: SkeletonFooter = (0, (0, parcelRequire("6WLja")).MenuSkeletonFooter), ViewExperienceSuccessTracker: ViewExperienceSuccessTracker = (0, (0, parcelRequire("g0Gtc")).ExperienceSuccessTracker), testIdPrefix: testIdPrefix, useFavorite: useFavorite = (0, (0, parcelRequire("bcn6L")).useFavoritePlans) })=>{
        const [, { loadFavoritePlans: loadFavorite }] = useFavorite();
        const contentTestId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'content');
        const actionFooterTestId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'action-footer');
        const adminFooterTestId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'admin-footer');
        const isStandardOrFree = (0, (0, parcelRequire("7Fvqy")).useIsStandardOrFreeJSW)();
        const { isSpotlightVisible: isSpotlightVisible } = (0, (0, parcelRequire("lujEp")).usePloverviewsSpotlightState)();
        const errorFallback = (0, $5uXOq.useCallback)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement(Error, {
                testIdPrefix: contentTestId
            }), [
            contentTestId
        ]);
        (0, $5uXOq.useEffect)(()=>()=>{
                !isStandardOrFree && loadFavorite((0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV));
            }, [
            loadFavorite,
            isStandardOrFree
        ]);
        const content = (0, ($parcel$interopDefault($5uXOq))).createElement(Layout, {
            isWide: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(JSErrorBoundary, {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).PLANS,
            packageName: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME),
            fallback: errorFallback,
            withExperienceTracker: true,
            extraEventData: $c5668dcd9601b742$var$EVENT_DATA
        }, isStandardOrFree ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aProA")).default), null) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement(Section, {
            isScrollable: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "plans-menu-content",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement(SkeletonContent, {
                testIdPrefix: contentTestId
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(ViewExperienceSuccessTracker, {
            location: "atlassian-navigation--primary-actions--plans--menu-popup--content",
            failureEventAttributes: null,
            parentProviders: null
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(LoadStatus, {
            shouldThrowError: true
        }), (0, ($parcel$interopDefault($5uXOq))).createElement(FavoriteContent, {
            testIdPrefix: contentTestId
        }), (0, ($parcel$interopDefault($5uXOq))).createElement(Content, {
            testIdPrefix: contentTestId
        })))), (0, ($parcel$interopDefault($5uXOq))).createElement(Section, {
            hasSeparator: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "plans-menu-footer",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement(SkeletonFooter, {
                testIdPrefix: contentTestId
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(LoadStatus, null), (0, ($parcel$interopDefault($5uXOq))).createElement(ActionFooter, {
            testIdPrefix: actionFooterTestId
        }), (0, ($parcel$interopDefault($5uXOq))).createElement(AdminFooter, {
            testIdPrefix: adminFooterTestId
        }))))));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("imZsD")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Fk4Z")).default), {
            name: "nav-plans-menu"
        }, content, isSpotlightVisible && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("baQVS")).AsyncPlansOnboarding), null)));
    };
    var $c5668dcd9601b742$export$2e2bcd8739ae039 = $c5668dcd9601b742$var$Menu;
});
parcelRegister("lujEp", function(module, exports) {
    $parcel$export(module.exports, "usePloverviewsSpotlightActions", ()=>$eb8b4f5b21d54c79$export$81d754ec95525914);
    $parcel$export(module.exports, "usePloverviewsSpotlightState", ()=>$eb8b4f5b21d54c79$export$7bfe2272006c8b13);
    $parcel$export(module.exports, "useShowPloverviewsSpotlight", ()=>$eb8b4f5b21d54c79$export$26a833a9dcbf492a);
    var $5uXOq = parcelRequire("5uXOq");
    var $lmyls;
    var $2Ui4E;
    var $3sQ5x;
    var $iXqE5;
    const $eb8b4f5b21d54c79$var$PloverviewsSpotlightStore = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: {
            isSpotlightVisible: false
        },
        actions: {
            showSpotlight: ()=>({ setState: setState })=>{
                    setState({
                        isSpotlightVisible: true
                    });
                },
            hideSpotlight: ()=>({ setState: setState })=>{
                    setState({
                        isSpotlightVisible: false
                    });
                }
        },
        name: 'PloverviewsSpotlightStore'
    });
    const $eb8b4f5b21d54c79$export$81d754ec95525914 = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($eb8b4f5b21d54c79$var$PloverviewsSpotlightStore);
    const $eb8b4f5b21d54c79$export$7bfe2272006c8b13 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($eb8b4f5b21d54c79$var$PloverviewsSpotlightStore);
    const $eb8b4f5b21d54c79$export$26a833a9dcbf492a = ()=>{
        const [_, { on: openPlansMenu }] = (0, (0, parcelRequire("2Ui4E")).useTopMenus)((0, (0, parcelRequire("lmyls")).MENU_ID).PLANS);
        const { showSpotlight: showSpotlight } = $eb8b4f5b21d54c79$export$81d754ec95525914();
        return (0, $5uXOq.useCallback)(()=>{
            openPlansMenu();
            showSpotlight();
        }, [
            openPlansMenu,
            showSpotlight
        ]);
    };
});
parcelRegister("baQVS", function(module, exports) {
    $parcel$export(module.exports, "AsyncPlansOnboarding", ()=>$5a27b2d5c7ab624a$export$95374682e859aeb9);
    var $5uXOq = parcelRequire("5uXOq");
    var $7XluA;
    var $4mO32;
    var $jPeyv;
    const $5a27b2d5c7ab624a$var$runtimeProps = {};
    const $5a27b2d5c7ab624a$var$entryPointParams = {};
    const $5a27b2d5c7ab624a$export$95374682e859aeb9 = ()=>{
        const { entryPointActions: entryPointActions, entryPointReferenceSubject: entryPointReferenceSubject } = (0, (0, parcelRequire("4mO32")).useEntryPoint)((0, (0, parcelRequire("jPeyv")).plansOnboardingEntrypoint), $5a27b2d5c7ab624a$var$entryPointParams);
        (0, $5uXOq.useEffect)(()=>{
            entryPointActions.load();
        }, [
            entryPointActions
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7XluA")).JiraEntryPointContainer), {
            id: "overviews-plans-onboarding-spotlight",
            packageName: "jiraBusinessOnboarding",
            entryPointReferenceSubject: entryPointReferenceSubject,
            runtimeProps: $5a27b2d5c7ab624a$var$runtimeProps
        });
    };
});
parcelRegister("jPeyv", function(module, exports) {
    $parcel$export(module.exports, "plansOnboardingEntrypoint", ()=>$5ad96e13d3fd6834$export$416070deed84cd30);
    var $eufEc;
    var $fEDBv;
    const $5ad96e13d3fd6834$export$416070deed84cd30 = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("cKjdo")).then((module)=>module.PlansOnboarding), {
            moduleId: "d53e714d"
        }),
        getPreloadProps: ()=>({})
    });
});
parcelRegister("cKjdo", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("ewVVV").then(()=>parcelRequire('dxJWW'));
});
parcelRegister("hZbyv", function(module, exports) {
    $parcel$export(module.exports, "ActionFooter", ()=>$adef3029a926b6d1$export$2d2b754ac238f9aa);
    var $5uXOq = parcelRequire("5uXOq");
    var $koVbs;
    var $6cNb0;
    var $88fdg;
    var $gD5Z0;
    var $4LAva;
    var $L0P37;
    var $9EGDV;
    var $eemc9;
    const $adef3029a926b6d1$export$2d2b754ac238f9aa = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [{ items: items }] = (0, (0, parcelRequire("88fdg")).usePlansItems)();
        const isPlanListEmpty = (0, (0, parcelRequire("eemc9")).useIsPlanListEmpty)();
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'action-footer');
        const [, { openDrawer: openCreateNewPlanWizardDrawer }] = (0, (0, parcelRequire("gD5Z0")).useCreateNewPlanWizardDrawer)();
        const [, { open: openCreateSamplePlanModal }] = (0, (0, parcelRequire("4LAva")).useCreateSamplePlan)();
        const onClick = (0, $5uXOq.useCallback)(()=>{
            openCreateNewPlanWizardDrawer();
        }, [
            openCreateNewPlanWizardDrawer
        ]);
        if (!items || !items.links) return null;
        const { links: links } = items;
        const { managePlans: managePlans, createPlan: createPlan, createSamplePlan: createSamplePlan } = links;
        const isAnyItemVisible = managePlans.isVisible || createPlan.isVisible;
        if (!isAnyItemVisible) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testId": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("L0P37")).MenuItem), {
            ...links.managePlans,
            title: formatMessage((0, (0, parcelRequire("9EGDV")).default).managePlans),
            testIdPrefix: testIdPrefix
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("L0P37")).MenuItem), {
            id: links.createPlan.id,
            isVisible: links.createPlan.isVisible && !isPlanListEmpty,
            title: formatMessage((0, (0, parcelRequire("9EGDV")).default).createPlan),
            testIdPrefix: testIdPrefix,
            onClick: onClick
        }), createSamplePlan && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("L0P37")).MenuItem), {
            id: createSamplePlan.id,
            isVisible: createSamplePlan.isVisible && !isPlanListEmpty,
            title: formatMessage((0, (0, parcelRequire("9EGDV")).default).createDemoPlan),
            testIdPrefix: testIdPrefix,
            onClick: openCreateSamplePlanModal
        }));
    };
});
parcelRegister("gD5Z0", function(module, exports) {
    $parcel$export(module.exports, "useCreateNewPlanWizardDrawer", ()=>$13f82bb8cfc1f7f2$export$f3e7d3fc94eda89a);
    $parcel$export(module.exports, "useImportedMessagesForLocale", ()=>$13f82bb8cfc1f7f2$export$52303468eeac3ad0);
    var $5uXOq = parcelRequire("5uXOq");
    var $eTXmK;
    var $4R6GH;
    var $koVbs;
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    const $13f82bb8cfc1f7f2$var$CONTROLLER_NAME = 'create-new-plan-wizard-drawer';
    const $13f82bb8cfc1f7f2$var$initialState = {
        isOpen: false,
        plan: undefined
    };
    const $13f82bb8cfc1f7f2$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: $13f82bb8cfc1f7f2$var$CONTROLLER_NAME,
        initialState: $13f82bb8cfc1f7f2$var$initialState,
        actions: {
            openDrawer: (plan)=>{
                if ((0, (0, parcelRequire("4R6GH")).fg)('add_analytic_to_create_plan')) (0, (0, parcelRequire("eTXmK")).default)('arj-create-plan-wizard-drawer');
                return ({ setState: setState })=>setState({
                        isOpen: true,
                        plan: plan
                    });
            },
            closeDrawer: ()=>({ setState: setState })=>setState({
                        isOpen: false,
                        plan: undefined
                    })
        }
    });
    const $13f82bb8cfc1f7f2$export$f3e7d3fc94eda89a = (0, (0, parcelRequire("3sQ5x")).createHook)($13f82bb8cfc1f7f2$var$Store);
    const $13f82bb8cfc1f7f2$export$9ec7b5762fb72de2 = (0, (0, parcelRequire("gg7kZ")).createContainer)($13f82bb8cfc1f7f2$var$Store, {
        onInit: ()=>({ setState: setState }, { isOpen: isOpen, plan: plan })=>{
                setState({
                    isOpen: isOpen,
                    plan: plan
                });
            }
    });
    const $13f82bb8cfc1f7f2$var$EXCLUDED_I18N_FILES = [
        'index',
        'languages',
        'en'
    ];
    const $13f82bb8cfc1f7f2$var$FALLBACK_INTL_MESSAGES = Object.freeze({});
    const $13f82bb8cfc1f7f2$export$19442c17ab279a67 = async (rawlocale = 'en')=>{
        const locale = rawlocale.split('-')[0];
        if (!locale || $13f82bb8cfc1f7f2$var$EXCLUDED_I18N_FILES.includes(locale)) return {
            default: $13f82bb8cfc1f7f2$var$FALLBACK_INTL_MESSAGES
        };
        try {
            {
                const i18nModule = await (parcelRequire("jUfDk"));
                const result = i18nModule[locale]();
                return result;
            }
            const result = await import(`@atlassian/jira-portfolio-plan-wizard/dist/cjs/i18n/${locale}`);
            return result;
        } catch  {
            return {
                default: $13f82bb8cfc1f7f2$var$FALLBACK_INTL_MESSAGES
            };
        }
    };
    const $13f82bb8cfc1f7f2$export$52303468eeac3ad0 = ()=>{
        const { locale: locale } = (0, (0, parcelRequire("koVbs")).default)();
        const [state, setState] = (0, $5uXOq.useState)({
            locale: locale,
            loading: true,
            intlMessages: undefined
        });
        (0, $5uXOq.useEffect)(()=>{
            let mounted = true;
            const load = async ()=>{
                setState((state)=>({
                        ...state,
                        loading: true
                    }));
                const { default: intlMessages } = await $13f82bb8cfc1f7f2$export$19442c17ab279a67(locale);
                if (!mounted) return;
                setState((state)=>({
                        ...state,
                        loading: false,
                        intlMessages: intlMessages
                    }));
            };
            load();
            return ()=>{
                mounted = false;
            };
        }, [
            locale
        ]);
        return state;
    };
});
parcelRegister("jUfDk", function(module, exports) {
    module.exports = {};
});
parcelRegister("4LAva", function(module, exports) {
    $parcel$export(module.exports, "useCreateSamplePlan", ()=>$8f6b3552bc4c872c$export$53d6fde96f096b86);
    $parcel$export(module.exports, "CreateSamplePlanProvider", ()=>$8f6b3552bc4c872c$export$88b99aa46c223622);
    var $5uXOq = parcelRequire("5uXOq");
    var $jcw0u;
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    var $e0K2C;
    var $hK1fQ;
    const $8f6b3552bc4c872c$var$CONTROLLER_NAME = 'create-sample-plan';
    const $8f6b3552bc4c872c$var$initialState = {
        isOpen: false,
        planId: null,
        taskId: null,
        progressPercentage: null,
        hasBeenInitialised: false
    };
    const $8f6b3552bc4c872c$var$actions = {
        open: ()=>({ setState: setState })=>{
                setState({
                    isOpen: true
                });
            },
        close: ()=>({ setState: setState })=>{
                const nextState = (({ hasBeenInitialised: hasBeenInitialised, ...rest })=>rest)($8f6b3552bc4c872c$var$initialState);
                setState(nextState);
            },
        setPlanId: (planId)=>({ setState: setState })=>{
                setState({
                    planId: planId
                });
            },
        setTaskId: (taskId)=>({ setState: setState })=>{
                setState({
                    taskId: taskId
                });
            },
        setProgressPercentage: (progressPercentage)=>({ setState: setState })=>{
                setState({
                    progressPercentage: progressPercentage
                });
            },
        submitSamplePlan: ({ planName: planName, flags: { success: SuccessFlag, error: ErrorFlag } })=>async ({ setState: setState, getState: getState, dispatch: dispatch }, { showFlag: showFlag, redirectToPlan: redirectToPlan, leadAccountId: leadAccountId })=>{
                try {
                    const taskId = await (0, (0, parcelRequire("hK1fQ")).createSamplePlanAsync)({
                        body: JSON.stringify({
                            title: planName,
                            leadAccountId: leadAccountId
                        })
                    });
                    setState({
                        taskId: taskId
                    });
                    const response = await (0, (0, parcelRequire("hK1fQ")).pollCreateSamplePlan)(taskId, (progressPercentage)=>{
                        const { taskId: latestTaskId } = getState();
                        if (taskId !== latestTaskId) return;
                        setState({
                            progressPercentage: progressPercentage
                        });
                    });
                    const { plan: plan } = response.samplePlan;
                    const currentState = getState();
                    if (currentState.isOpen && taskId === currentState.taskId) {
                        redirectToPlan(plan.id);
                        dispatch($8f6b3552bc4c872c$var$actions.close());
                    } else {
                        const flagId = (0, (0, parcelRequire("e0K2C")).CREATE_SUCCESS_FLAG_ID) + plan.id;
                        showFlag({
                            id: flagId,
                            render: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement(SuccessFlag, {
                                    id: flagId,
                                    planName: plan.name,
                                    planId: plan.id
                                })
                        });
                    }
                } catch (err) {
                    if (!(0, (0, parcelRequire("e0K2C")).USER_SETTINGS_ERRORS).includes(err.message)) (0, (0, parcelRequire("jcw0u")).default)({
                        error: err,
                        meta: {
                            id: `${(0, (0, parcelRequire("e0K2C")).ERROR_REPORTING_PACKAGE)}SubmitFailed`,
                            packageName: (0, (0, parcelRequire("e0K2C")).ERROR_REPORTING_PACKAGE),
                            teamName: (0, (0, parcelRequire("e0K2C")).ERROR_REPORTING_TEAM)
                        },
                        sendToPrivacyUnsafeSplunk: true
                    });
                    const props = {
                        id: (0, (0, parcelRequire("e0K2C")).CREATE_ERROR_FLAG_ID),
                        errorCode: err.message
                    };
                    showFlag({
                        id: (0, (0, parcelRequire("e0K2C")).CREATE_ERROR_FLAG_ID),
                        render: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement(ErrorFlag, props)
                    });
                    dispatch($8f6b3552bc4c872c$var$actions.close());
                }
            }
    };
    const $8f6b3552bc4c872c$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: $8f6b3552bc4c872c$var$CONTROLLER_NAME,
        initialState: $8f6b3552bc4c872c$var$initialState,
        actions: $8f6b3552bc4c872c$var$actions
    });
    const $8f6b3552bc4c872c$export$53d6fde96f096b86 = (0, (0, parcelRequire("3sQ5x")).createHook)($8f6b3552bc4c872c$var$Store);
    const $8f6b3552bc4c872c$export$88b99aa46c223622 = (0, (0, parcelRequire("gg7kZ")).createContainer)($8f6b3552bc4c872c$var$Store, {
        onInit: ()=>({ setState: setState }, { isOpen: isOpen = false })=>{
                setState({
                    isOpen: isOpen,
                    hasBeenInitialised: true
                });
            }
    });
});
parcelRegister("e0K2C", function(module, exports) {
    $parcel$export(module.exports, "ERROR_REPORTING_PACKAGE", ()=>$3613c4dc2e67ca96$export$f27a837467ec20e5);
    $parcel$export(module.exports, "ERROR_REPORTING_TEAM", ()=>$3613c4dc2e67ca96$export$cfeee2ee86ead336);
    $parcel$export(module.exports, "MAX_PLAN_NAME_LENGTH", ()=>$3613c4dc2e67ca96$export$62da4a2465b3b309);
    $parcel$export(module.exports, "CREATE_SUCCESS_FLAG_ID", ()=>$3613c4dc2e67ca96$export$d526356745046017);
    $parcel$export(module.exports, "CREATE_ERROR_FLAG_ID", ()=>$3613c4dc2e67ca96$export$abd17266df221a92);
    $parcel$export(module.exports, "UNKNOWN_EXCEPTION", ()=>$3613c4dc2e67ca96$export$cfba07bc71f884c1);
    $parcel$export(module.exports, "USER_SETTINGS_ERRORS", ()=>$3613c4dc2e67ca96$export$4a3e779cd3c10125);
    $parcel$export(module.exports, "ERROR_CODE_TITLE_MAPPING", ()=>$3613c4dc2e67ca96$export$91ec80863c2c2030);
    $parcel$export(module.exports, "ERROR_CODE_DESCRIPTION_MAPPING", ()=>$3613c4dc2e67ca96$export$c43e20911e13ca0);
    $parcel$export(module.exports, "ERROR_LINK_MAPPING", ()=>$3613c4dc2e67ca96$export$2c5a783ab3215ce1);
    var $2L5wO;
    const $3613c4dc2e67ca96$export$f27a837467ec20e5 = 'jiraPortfolio3CreateSamplePlan';
    const $3613c4dc2e67ca96$export$cfeee2ee86ead336 = 'advanced-roadmaps';
    const $3613c4dc2e67ca96$export$2c16bd1d8b68b11f = 'https://support.atlassian.com/contact';
    const $3613c4dc2e67ca96$export$af6d68522cb370b8 = 'https://support.atlassian.com/jira-software-cloud/docs/view-a-sample-advanced-roadmaps-plan/';
    const $3613c4dc2e67ca96$export$62da4a2465b3b309 = 255;
    const $3613c4dc2e67ca96$export$d526356745046017 = 'samplePlanCreatedSuccessfully';
    const $3613c4dc2e67ca96$export$abd17266df221a92 = 'samplePlanError';
    const $3613c4dc2e67ca96$export$e6515d2009b1dba7 = 'REQUIRED_FIELDS_CONFIGURED_ON_ISSUE';
    const $3613c4dc2e67ca96$export$80ecb4d853a9f09b = 'EMPTY_ISSUE_LINKS';
    const $3613c4dc2e67ca96$export$f31def2c4daaefc0 = 'NO_BROWSE_PROJECTS_PERMISSION';
    const $3613c4dc2e67ca96$export$530d46555d7ccbb = 'NO_CREATE_ISSUES_PERMISSION';
    const $3613c4dc2e67ca96$export$8c852ea52ca9ebe = 'NO_TRANSITION_ISSUES_PERMISSION';
    const $3613c4dc2e67ca96$export$855d898b7009ad1c = 'NO_EDIT_ISSUES_PERMISSION';
    const $3613c4dc2e67ca96$export$5c8703f47a29c462 = 'WATCHING_DISABLED';
    const $3613c4dc2e67ca96$export$cfba07bc71f884c1 = 'UNKNOWN_EXCEPTION';
    const $3613c4dc2e67ca96$export$4a3e779cd3c10125 = [
        $3613c4dc2e67ca96$export$e6515d2009b1dba7,
        $3613c4dc2e67ca96$export$80ecb4d853a9f09b,
        $3613c4dc2e67ca96$export$f31def2c4daaefc0,
        $3613c4dc2e67ca96$export$530d46555d7ccbb,
        $3613c4dc2e67ca96$export$8c852ea52ca9ebe,
        $3613c4dc2e67ca96$export$855d898b7009ad1c,
        $3613c4dc2e67ca96$export$5c8703f47a29c462
    ];
    const $3613c4dc2e67ca96$export$91ec80863c2c2030 = {
        [$3613c4dc2e67ca96$export$e6515d2009b1dba7]: (0, (0, parcelRequire("2L5wO")).default).configurationErrorTitle,
        [$3613c4dc2e67ca96$export$80ecb4d853a9f09b]: (0, (0, parcelRequire("2L5wO")).default).configurationErrorTitle,
        [$3613c4dc2e67ca96$export$f31def2c4daaefc0]: (0, (0, parcelRequire("2L5wO")).default).permissionErrorTitle,
        [$3613c4dc2e67ca96$export$530d46555d7ccbb]: (0, (0, parcelRequire("2L5wO")).default).permissionErrorTitle,
        [$3613c4dc2e67ca96$export$8c852ea52ca9ebe]: (0, (0, parcelRequire("2L5wO")).default).permissionErrorTitle,
        [$3613c4dc2e67ca96$export$855d898b7009ad1c]: (0, (0, parcelRequire("2L5wO")).default).permissionErrorTitle,
        [$3613c4dc2e67ca96$export$5c8703f47a29c462]: (0, (0, parcelRequire("2L5wO")).default).configurationErrorTitle,
        [$3613c4dc2e67ca96$export$cfba07bc71f884c1]: (0, (0, parcelRequire("2L5wO")).default).tryAgainErrorTitle
    };
    const $3613c4dc2e67ca96$export$c43e20911e13ca0 = {
        [$3613c4dc2e67ca96$export$e6515d2009b1dba7]: (0, (0, parcelRequire("2L5wO")).default).requiredFieldsConfiguredOnIssueError,
        [$3613c4dc2e67ca96$export$80ecb4d853a9f09b]: (0, (0, parcelRequire("2L5wO")).default).emptyIssueLinksError,
        [$3613c4dc2e67ca96$export$f31def2c4daaefc0]: (0, (0, parcelRequire("2L5wO")).default).noBrowseProjectsPermissionError,
        [$3613c4dc2e67ca96$export$530d46555d7ccbb]: (0, (0, parcelRequire("2L5wO")).default).noCreateIssuesPermissionError,
        [$3613c4dc2e67ca96$export$8c852ea52ca9ebe]: (0, (0, parcelRequire("2L5wO")).default).noTransitionIssuesPermissionError,
        [$3613c4dc2e67ca96$export$855d898b7009ad1c]: (0, (0, parcelRequire("2L5wO")).default).noEditIssuesPermissionError,
        [$3613c4dc2e67ca96$export$5c8703f47a29c462]: (0, (0, parcelRequire("2L5wO")).default).watchingDisabledError,
        [$3613c4dc2e67ca96$export$cfba07bc71f884c1]: (0, (0, parcelRequire("2L5wO")).default).unknownExceptionError
    };
    const $3613c4dc2e67ca96$export$2c5a783ab3215ce1 = {
        [$3613c4dc2e67ca96$export$e6515d2009b1dba7]: {
            text: (0, (0, parcelRequire("2L5wO")).default).learnMore,
            href: $3613c4dc2e67ca96$export$af6d68522cb370b8
        },
        [$3613c4dc2e67ca96$export$80ecb4d853a9f09b]: {
            text: (0, (0, parcelRequire("2L5wO")).default).learnMore,
            href: $3613c4dc2e67ca96$export$af6d68522cb370b8
        },
        [$3613c4dc2e67ca96$export$f31def2c4daaefc0]: {
            text: (0, (0, parcelRequire("2L5wO")).default).learnMore,
            href: $3613c4dc2e67ca96$export$af6d68522cb370b8
        },
        [$3613c4dc2e67ca96$export$530d46555d7ccbb]: {
            text: (0, (0, parcelRequire("2L5wO")).default).learnMore,
            href: $3613c4dc2e67ca96$export$af6d68522cb370b8
        },
        [$3613c4dc2e67ca96$export$8c852ea52ca9ebe]: {
            text: (0, (0, parcelRequire("2L5wO")).default).learnMore,
            href: $3613c4dc2e67ca96$export$af6d68522cb370b8
        },
        [$3613c4dc2e67ca96$export$855d898b7009ad1c]: {
            text: (0, (0, parcelRequire("2L5wO")).default).learnMore,
            href: $3613c4dc2e67ca96$export$af6d68522cb370b8
        },
        [$3613c4dc2e67ca96$export$5c8703f47a29c462]: {
            text: (0, (0, parcelRequire("2L5wO")).default).learnMore,
            href: $3613c4dc2e67ca96$export$af6d68522cb370b8
        },
        [$3613c4dc2e67ca96$export$cfba07bc71f884c1]: {
            text: (0, (0, parcelRequire("2L5wO")).default).contactSupport,
            href: $3613c4dc2e67ca96$export$2c16bd1d8b68b11f
        }
    };
});
parcelRegister("2L5wO", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$e4969e0d482b1a0a$export$2e2bcd8739ae039);
    var $7VHMR;
    var $e4969e0d482b1a0a$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        configurationErrorTitle: {
            "id": "portfolio-3-create-sample-plan.common.configuration-error-title",
            "defaultMessage": "Your demo plan couldn't be created because of a configuration error"
        },
        permissionErrorTitle: {
            "id": "portfolio-3-create-sample-plan.common.permission-error-title",
            "defaultMessage": "Your demo plan couldn't be created because you\u2019re missing a required permission"
        },
        tryAgainErrorTitle: {
            "id": "portfolio-3-create-sample-plan.common.try-again-error-title",
            "defaultMessage": "Try to create your demo plan again"
        },
        requiredFieldsConfiguredOnIssueError: {
            "id": "portfolio-3-create-sample-plan.common.required-fields-configured-on-issue-error",
            "defaultMessage": "Your issues have required fields which blocked your demo plan from being created. {readMoreLink}"
        },
        emptyIssueLinksError: {
            "id": "portfolio-3-create-sample-plan.common.empty-issue-links-error",
            "defaultMessage": "Your site doesn't have issue links configured, which are required to create a demo plan. {readMoreLink}"
        },
        noBrowseProjectsPermissionError: {
            "id": "portfolio-3-create-sample-plan.common.no-browse-projects-permission-error",
            "defaultMessage": "Your demo plan couldn't be created because you don\u2019t have the <permission-type>Browse Projects</permission-type> permission. {readMoreLink}"
        },
        noCreateIssuesPermissionError: {
            "id": "portfolio-3-create-sample-plan.common.no-create-issues-permission-error",
            "defaultMessage": "Your demo plan couldn't be created because you don\u2019t have the <permission-type>Create Issues</permission-type> permission. {readMoreLink}"
        },
        noTransitionIssuesPermissionError: {
            "id": "portfolio-3-create-sample-plan.common.no-transition-issues-permission-error",
            "defaultMessage": "Your demo plan couldn't be created because you don\u2019t have the <permission-type>Transition Issues</permission-type> permission. {readMoreLink}"
        },
        noEditIssuesPermissionError: {
            "id": "portfolio-3-create-sample-plan.common.no-edit-issues-permission-error",
            "defaultMessage": "Your demo plan couldn't be created because you don\u2019t have the <permission-type>Edit Issues</permission-type> permission. {readMoreLink}"
        },
        watchingDisabledError: {
            "id": "portfolio-3-create-sample-plan.common.watching-disabled-error",
            "defaultMessage": "Your demo plan couldn't be created because you have issue watching disabled. {readMoreLink}"
        },
        unknownExceptionError: {
            "id": "portfolio-3-create-sample-plan.common.unknown-exception-error",
            "defaultMessage": "Your demo plan couldn't be created, but we\u2019re not sure why. If the problem persists, contact Atlassian support. {readMoreLink}"
        },
        learnMore: {
            "id": "portfolio-3-create-sample-plan.common.learn-more",
            "defaultMessage": "Learn more"
        },
        contactSupport: {
            "id": "portfolio-3-create-sample-plan.common.contact-support",
            "defaultMessage": "Contact Atlassian support"
        }
    });
});
parcelRegister("hK1fQ", function(module, exports) {
    $parcel$export(module.exports, "createSamplePlanAsync", ()=>$4a753946a320d2b7$export$fed9130b90cf6213);
    $parcel$export(module.exports, "pollCreateSamplePlan", ()=>$4a753946a320d2b7$export$5d240a69af545c0d);
    var $jcw0u;
    var $fnyT4;
    var $jJZqs;
    var $eusud;
    var $e0K2C;
    const $4a753946a320d2b7$var$throwIfNotOk = async (response)=>{
        if (response.ok) return response;
        if (response.status === 500) throw new (0, (0, parcelRequire("jJZqs")).default)(response.status, (0, (0, parcelRequire("e0K2C")).UNKNOWN_EXCEPTION), response.headers.get((0, (0, parcelRequire("fnyT4")).TRACE_ID_HEADER)) ?? undefined, response);
    };
    const $4a753946a320d2b7$var$throwIfFailed = (result)=>{
        if (result.samplePlan && !result.samplePlan.success) throw new Error(result.samplePlan.error);
    };
    const $4a753946a320d2b7$var$delay = (ms = 3000)=>new Promise((res)=>setTimeout(res, ms));
    const $4a753946a320d2b7$export$fed9130b90cf6213 = async (data)=>{
        const response = await (0, (0, parcelRequire("eusud")).performPostRequest)('/rest/jpo/1.0/plans/sampleplan/async', data);
        const { taskId: taskId } = response;
        if (!taskId) throw new Error('Invalid response from createSamplePlan');
        return taskId;
    };
    const $4a753946a320d2b7$export$5d240a69af545c0d = async (taskId, onUpdate)=>{
        const url = `/rest/jpo/1.0/plans/sampleplan/async/${taskId}`;
        try {
            const response = await fetch(url);
            await $4a753946a320d2b7$var$throwIfNotOk(response);
            const raw = await response.json();
            $4a753946a320d2b7$var$throwIfFailed(raw);
            onUpdate(raw.progressPercent);
            if (raw.done) return raw;
            await $4a753946a320d2b7$var$delay();
            return $4a753946a320d2b7$export$5d240a69af545c0d(taskId, onUpdate);
        } catch (error) {
            if (!(0, (0, parcelRequire("e0K2C")).USER_SETTINGS_ERRORS).includes(error.message)) (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: `${(0, (0, parcelRequire("e0K2C")).ERROR_REPORTING_PACKAGE)}PollingFailed`,
                    packageName: (0, (0, parcelRequire("e0K2C")).ERROR_REPORTING_PACKAGE),
                    teamName: (0, (0, parcelRequire("e0K2C")).ERROR_REPORTING_TEAM)
                },
                attributes: {
                    message: 'Polling create sample plan failed'
                },
                error: error,
                sendToPrivacyUnsafeSplunk: true
            });
            throw error;
        }
    };
});
parcelRegister("L0P37", function(module, exports) {
    $parcel$export(module.exports, "MenuItem", ()=>$3555250889c231c3$export$2ce376c2cc3355c8);
    var $5uXOq = parcelRequire("5uXOq");
    var $6cNb0;
    var $d4B4Y;
    const $3555250889c231c3$export$2ce376c2cc3355c8 = ({ id: id, url: url, title: title, isVisible: isVisible, onClick: onClick, testIdPrefix: testIdPrefix })=>isVisible ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'plansNavigationMenuItem',
                menuItemId: id,
                menuItemType: 'action'
            },
            href: url,
            key: id,
            onClick: onClick,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, `item-${id}`)
        }, title) : null;
});
parcelRegister("9EGDV", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$1b84dd9eb74c4947$export$2e2bcd8739ae039);
    var $7VHMR;
    var $1b84dd9eb74c4947$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        managePlans: {
            "id": "atlassian-navigation.plans.menu.action-footer.manage-plans",
            "defaultMessage": "View all plans"
        },
        createPlan: {
            "id": "atlassian-navigation.plans.menu.action-footer.create-plan",
            "defaultMessage": "Create plan"
        },
        createDemoPlan: {
            "id": "atlassian-navigation.plans.menu.action-footer.create-demo-plan",
            "defaultMessage": "Create demo plan"
        }
    });
});
parcelRegister("eemc9", function(module, exports) {
    $parcel$export(module.exports, "useIsPlanListEmpty", ()=>$6d7bc7f34f360a53$export$5b132e35e5bd9b74);
    var $dMJS0;
    const $6d7bc7f34f360a53$export$5b132e35e5bd9b74 = ()=>{
        const { recentItems: recentItems, favoriteItems: favoriteItems } = (0, (0, parcelRequire("dMJS0")).usePlanNavItems)();
        const hasRecentItems = recentItems && recentItems.length > 0;
        const hasFavoriteItems = favoriteItems && favoriteItems.length > 0;
        return !(hasRecentItems || hasFavoriteItems);
    };
});
parcelRegister("42REw", function(module, exports) {
    $parcel$export(module.exports, "AdminFooter", ()=>$7b2abd444f379ab5$export$d7aa9aad32d41ef6);
    var $5uXOq = parcelRequire("5uXOq");
    var $9UCCQ;
    var $koVbs;
    var $6cNb0;
    var $88fdg;
    var $d4B4Y;
    var $7Josu;
    const $7b2abd444f379ab5$export$d7aa9aad32d41ef6 = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [{ items: items }] = (0, (0, parcelRequire("88fdg")).usePlansItems)();
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'admin-footer');
        if (!items || !items.links) return null;
        const { links: { settings: { id: id, isVisible: isVisible } } } = items;
        if (!isVisible) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            hasSeparator: true,
            testId: testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'planSettingsNavigationMenuItem',
                menuItemId: id,
                menuItemType: 'action'
            },
            href: "/jira/plans/settings/permissions",
            key: id,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, `item-${id}`)
        }, formatMessage((0, (0, parcelRequire("7Josu")).default).settings)));
    };
});
parcelRegister("7Josu", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f7ecad56cc035bca$export$2e2bcd8739ae039);
    var $7VHMR;
    var $f7ecad56cc035bca$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        settings: {
            "id": "atlassian-navigation.plans.menu.admin-footer.settings",
            "defaultMessage": "Settings"
        }
    });
});
parcelRegister("53HSR", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$c5a598b0994a63b7$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $4mNf3;
    var $eXQym;
    var $koVbs;
    var $2EqxS;
    var $6cNb0;
    var $fhtpB;
    var $d4B4Y;
    var $dMJS0;
    var $9ZBW7;
    var $gJFyv;
    const $c5a598b0994a63b7$var$IconPlans = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            height: 24,
            width: 24,
            fill: "none",
            viewBox: "0 0 24 24"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            fill: "var(--ds-icon, #42526E)",
            d: "M10 8a1 1 0 000 2h4a1 1 0 100-2h-4zM10 12a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1zM10 14a1 1 0 100 2h2a1 1 0 100-2h-2z"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            fill: "var(--ds-icon, #42526E)",
            fillRule: "evenodd",
            d: "M8 4a2 2 0 012-2h4a2 2 0 012 2h1a2 2 0 012 2v12a2 2 0 01-2 2H7a2 2 0 01-2-2V6a2 2 0 012-2h1zm6 3a2 2 0 001.732-1H17v12H7V6h1.268A2 2 0 0010 7h4zm0-3h-4v1h4V4z",
            clipRule: "evenodd"
        }));
    const $c5a598b0994a63b7$var$Content = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { recentItems: recentItems, favoriteItems: favoriteItems, changeFavorite: onChangeFavorite, changedItems: changedItems } = (0, (0, parcelRequire("dMJS0")).usePlanNavItems)();
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'items');
        const hasRecentItems = recentItems && recentItems.length > 0;
        const hasFavoriteItems = favoriteItems && favoriteItems.length > 0;
        if (!hasRecentItems && !hasFavoriteItems) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9ZBW7")).default), {
            testIdPrefix: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'empty')
        });
        return hasRecentItems ? (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testId": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), {
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'heading')
        }, formatMessage((0, (0, parcelRequire("gJFyv")).default).recent)), recentItems.map((item, index)=>{
            const { id: id, favourite: isFavourite, title: title } = item;
            const contextItem = changedItems[id.toString()];
            const isPending = contextItem && contextItem.pending === true ? contextItem.pending : false;
            const { id: beforeEntityId, type: beforeEntityType } = (0, (0, parcelRequire("2EqxS")).getBeforeEntity)(favoriteItems);
            const itemTitle = title || formatMessage((0, (0, parcelRequire("gJFyv")).default).untitledPlan);
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
                analytics: {
                    actionSubjectId: 'plansNavigationMenuItem',
                    menuItemId: id,
                    menuItemType: 'recent',
                    position: index
                },
                href: item.url,
                key: item.id,
                testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, `item-${index}`),
                iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement($c5a598b0994a63b7$var$IconPlans, null),
                iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fhtpB")).FavoriteButton), {
                    beforeEntityId: beforeEntityId,
                    beforeEntityType: beforeEntityType,
                    id: String(id),
                    isFavorite: Boolean(isFavourite),
                    favoriteItemName: itemTitle,
                    isPending: isPending,
                    menuItemType: "recent",
                    onChangeFavorite: onChangeFavorite,
                    type: (0, (0, parcelRequire("eXQym")).PLANS_ITEM_TYPE)
                })
            }, itemTitle);
        })) : null;
    };
    var $c5a598b0994a63b7$export$2e2bcd8739ae039 = $c5a598b0994a63b7$var$Content;
});
parcelRegister("9ZBW7", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$72e027cbf3ff743a$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $dC5iT;
    var $8CUq3;
    var $ousew;
    var $6cNb0;
    var $88fdg;
    var $gD5Z0;
    var $4LAva;
    var $8zOmE;
    var $jyltl;
    var $1x6mD = parcelRequire("1x6mD");
    var $iy6PK;
    const $72e027cbf3ff743a$var$EmptyWithCTAs = ({ testIdPrefix: testIdPrefix })=>{
        const [{ items: items }] = (0, (0, parcelRequire("88fdg")).usePlansItems)();
        const [, { openDrawer: openCreateNewPlanWizardDrawer }] = (0, (0, parcelRequire("gD5Z0")).useCreateNewPlanWizardDrawer)();
        const [, { open: openCreateSamplePlanModal }] = (0, (0, parcelRequire("4LAva")).useCreateSamplePlan)();
        const handleRequestCreateSamplePlan = (0, $5uXOq.useCallback)((e, analyticsEvent)=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'navigationCreateSamplePlan');
            openCreateSamplePlanModal();
        }, [
            openCreateSamplePlanModal
        ]);
        const handleRequestCreatePlan = (0, $5uXOq.useCallback)((e, analyticsEvent)=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'navigationCreatePlan');
            openCreateNewPlanWizardDrawer();
        }, [
            openCreateNewPlanWizardDrawer
        ]);
        const createPlan = items?.links?.createPlan;
        const createSamplePlan = items?.links?.createSamplePlan;
        const hasButtons = Boolean(createPlan?.isVisible || createSamplePlan?.isVisible);
        const text = (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement("p", null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), (0, (0, parcelRequire("iy6PK")).default).plansText)), hasButtons && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $72e027cbf3ff743a$var$buttonGroupStyles
        }, createSamplePlan?.isVisible && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            onClick: handleRequestCreateSamplePlan
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), (0, (0, parcelRequire("iy6PK")).default).createDemoPlan)), createPlan?.isVisible && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            appearance: "primary",
            onClick: handleRequestCreatePlan
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), (0, (0, parcelRequire("iy6PK")).default).createPlan))));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jyltl")).MenuInfo), {
            imgAltText: "",
            imgSrc: (0, ($parcel$interopDefault($1x6mD))),
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'empty'),
            text: text
        });
    };
    var $72e027cbf3ff743a$export$2e2bcd8739ae039 = $72e027cbf3ff743a$var$EmptyWithCTAs;
    const $72e027cbf3ff743a$var$buttonGroupStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginTop: 'space.200',
        display: 'flex',
        gap: 'space.100',
        justifyContent: 'center'
    });
});
parcelRegister("1x6mD", function(module, exports) {
    module.exports = new URL("plans-empty.7a647633.svg", import.meta.url).toString();
});
parcelRegister("iy6PK", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$20432d5456b05a6a$export$2e2bcd8739ae039);
    var $7VHMR;
    var $20432d5456b05a6a$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        plansText: {
            "id": "atlassian-navigation.plans.menu.content.empty.plans-text",
            "defaultMessage": "Create up-to-date plans from multiple teams and projects."
        },
        createPlan: {
            "id": "atlassian-navigation.plans.menu.content.empty.create-plan",
            "defaultMessage": "Create plan"
        },
        createDemoPlan: {
            "id": "atlassian-navigation.plans.menu.content.empty.create-demo-plan",
            "defaultMessage": "Create demo plan"
        },
        learnMore: {
            "id": "atlassian-navigation.plans.menu.content.empty.learn-more",
            "defaultMessage": "Learn more"
        }
    });
});
parcelRegister("gJFyv", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9f1da9814d244cf8$export$2e2bcd8739ae039);
    var $7VHMR;
    var $9f1da9814d244cf8$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        recent: {
            "id": "atlassian-navigation.plans.menu.content.recent",
            "defaultMessage": "Recent"
        },
        untitledPlan: {
            "id": "atlassian-navigation.plans.menu.content.untitled-plan",
            "defaultMessage": "Untitled plan"
        }
    });
});
parcelRegister("6MUS3", function(module, exports) {
    $parcel$export(module.exports, "FavoriteContent", ()=>$4f470a059723f912$export$297af018011b145a);
    var $5uXOq = parcelRequire("5uXOq");
    var $4mNf3;
    var $eXQym;
    var $koVbs;
    var $6cNb0;
    var $4TyVn;
    var $Mp6qH;
    var $fhtpB;
    var $d4B4Y;
    var $56fXm;
    var $dMJS0;
    var $fbx6q;
    const $4f470a059723f912$var$IconPlans = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            height: 24,
            width: 24,
            fill: "none",
            viewBox: "0 0 24 24"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            fill: "var(--ds-icon, #42526E)",
            d: "M10 8a1 1 0 000 2h4a1 1 0 100-2h-4zM10 12a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1zM10 14a1 1 0 100 2h2a1 1 0 100-2h-2z"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            fill: "var(--ds-icon, #42526E)",
            fillRule: "evenodd",
            d: "M8 4a2 2 0 012-2h4a2 2 0 012 2h1a2 2 0 012 2v12a2 2 0 01-2 2H7a2 2 0 01-2-2V6a2 2 0 012-2h1zm6 3a2 2 0 001.732-1H17v12H7V6h1.268A2 2 0 0010 7h4zm0-3h-4v1h4V4z",
            clipRule: "evenodd"
        }));
    const $4f470a059723f912$export$297af018011b145a = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { favoriteItems: favoriteItems, changeFavorite: onChangeFavorite, changedItems: changedItems } = (0, (0, parcelRequire("dMJS0")).usePlanNavItems)();
        const itemsToShow = (0, $5uXOq.useMemo)(()=>favoriteItems.slice(0, (0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV)), [
            favoriteItems
        ]);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'items-starred');
        return favoriteItems.length > 0 ? (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testId": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), {
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'heading')
        }, formatMessage((0, (0, parcelRequire("fbx6q")).default).starred)), itemsToShow.map((item, idx)=>{
            const { url: url, id: id, favourite: isFavourite, title: title } = item;
            const contextItem = changedItems[item.id.toString()];
            const isPending = contextItem && contextItem.pending === true ? contextItem.pending : false;
            const itemTitle = title || formatMessage((0, (0, parcelRequire("fbx6q")).default).untitledPlan);
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
                analytics: {
                    actionSubjectId: 'plansNavigationMenuItem',
                    menuItemId: id,
                    menuItemType: 'starred',
                    position: idx,
                    starred: Boolean(isFavourite)
                },
                href: url,
                iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement($4f470a059723f912$var$IconPlans, null),
                iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fhtpB")).FavoriteButton), {
                    id: String(id),
                    isFavorite: Boolean(isFavourite),
                    favoriteItemName: itemTitle,
                    isPending: isPending,
                    menuItemType: "starred",
                    onChangeFavorite: onChangeFavorite,
                    type: (0, (0, parcelRequire("eXQym")).PLANS_ITEM_TYPE)
                }),
                key: id,
                testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, `item-${idx}`)
            }, itemTitle);
        }), favoriteItems.length >= (0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV) && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("56fXm")).MenuItemViewAll), {
            analytics: {
                actionSubjectId: 'plansNavigationMenuItem',
                menuItemId: 'viewAll',
                position: (0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV)
            },
            href: "/jira/plans",
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-view-all'),
            to: (0, (0, parcelRequire("4TyVn")).plansDirectoryRoute)
        })) : null;
    };
});
parcelRegister("4TyVn", function(module, exports) {
    $parcel$export(module.exports, "plansDirectoryRoute", ()=>$ed241e9e9a1b449e$export$143b4a1ef2ffc051);
    var $65Ltd;
    const $ed241e9e9a1b449e$export$143b4a1ef2ffc051 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_PLANS_DIRECTORY),
        path: '/jira/plans'
    };
});
parcelRegister("fbx6q", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2c5162b46fd824b0$export$2e2bcd8739ae039);
    var $7VHMR;
    var $2c5162b46fd824b0$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        starred: {
            "id": "atlassian-navigation.plans.menu.favorite-content.starred",
            "defaultMessage": "Starred"
        },
        untitledPlan: {
            "id": "atlassian-navigation.plans.menu.favorite-content.untitled-plan",
            "defaultMessage": "Untitled plan"
        }
    });
});
parcelRegister("5n6PC", function(module, exports) {
    $parcel$export(module.exports, "LoadStatus", ()=>$28d55793d37ab45d$export$c7df4a3f1b89de01);
    var $5uXOq = parcelRequire("5uXOq");
    var $88fdg;
    var $dHtVS;
    const $28d55793d37ab45d$export$c7df4a3f1b89de01 = ({ LoadStatusCommon: LoadStatusCommon = (0, (0, parcelRequire("dHtVS")).LoadStatus), usePlansItems: usePlansItems = (0, (0, parcelRequire("88fdg")).usePlansItems), shouldThrowError: shouldThrowError = false })=>(0, ($parcel$interopDefault($5uXOq))).createElement(LoadStatusCommon, {
            shouldThrowError: shouldThrowError,
            useProvider: usePlansItems
        });
});
parcelRegister("aProA", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$59ef1621dccedbfe$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $9yOBd;
    var $1dJ6S;
    var $8EYBJ;
    var $dC5iT;
    var $8CUq3;
    var $hxoiH;
    var $iwnVa;
    var $6s1PB;
    var $koVbs;
    var $liGrj;
    var $8zOmE;
    var $dCm1o;
    var $Mp6qH;
    var $iIz7a;
    var $gblI8;
    const $59ef1621dccedbfe$var$LEARN_MORE_LINK = 'https://www.atlassian.com/software/jira/guides/advanced-roadmaps/overview#what-are-advanced-roadmaps';
    const $59ef1621dccedbfe$var$PremiumFeatureGate = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { colorMode: colorMode } = (0, (0, parcelRequire("hxoiH")).default)();
        const { data: { premiumTrialUpgradeLink: premiumTrialUpgradeLink } } = (0, (0, parcelRequire("6s1PB")).expVal)('plans_menu_try_it_free_one_stop_shop_modal', 'isOneStopShopModalEnabled', true) ? {
            data: {
                premiumTrialUpgradeLink: undefined
            }
        } : (0, (0, parcelRequire("iwnVa")).useJswPremiumTrialUpgradeLink)({
            caller: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME)
        });
        const onStartTrialClick = (_, analyticsEvent)=>{
            if ((0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)()) (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'plansNavigationMenuItem', {
                level: 2,
                componentType: 'fixedItemAction',
                parentItemId: 'plans',
                itemId: 'tryItFree'
            });
            else (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'navigationUpgradeToPremiumFromPlansMenu');
        };
        const onLearnMoreClick = (_, analyticsEvent)=>{
            if ((0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)()) (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'plansNavigationMenuItem', {
                level: 2,
                componentType: 'fixedItemAction',
                parentItemId: 'plans',
                itemId: 'readTheGuide'
            });
            else (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'navigationLearnMoreAboutPremiumFromPlansMenu');
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement($59ef1621dccedbfe$var$MenuContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement($59ef1621dccedbfe$var$MenuHeaderContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iIz7a")).FeatureGateLogo), {
            alt: formatMessage((0, (0, parcelRequire("gblI8")).default).imageAlt),
            colorMode: colorMode
        })), (0, ($parcel$interopDefault($5uXOq))).createElement($59ef1621dccedbfe$var$ContentContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1dJ6S")).default), {
            size: "small",
            as: "h3"
        }, formatMessage((0, (0, parcelRequire("gblI8")).default).title)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $59ef1621dccedbfe$var$contentDescriptionStyles
        }, formatMessage((0, (0, parcelRequire("gblI8")).default).description))), (0, ($parcel$interopDefault($5uXOq))).createElement($59ef1621dccedbfe$var$FooterContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement($59ef1621dccedbfe$var$LearnMoreButton, {
            appearance: "link",
            href: $59ef1621dccedbfe$var$LEARN_MORE_LINK,
            target: "_blank",
            onClick: onLearnMoreClick
        }, formatMessage((0, (0, parcelRequire("gblI8")).default).learnMoreLink)), (0, (0, parcelRequire("6s1PB")).expVal)('plans_menu_try_it_free_one_stop_shop_modal', 'isOneStopShopModalEnabled', true) ? (0, ($parcel$interopDefault($5uXOq))).createElement($59ef1621dccedbfe$var$TryPremiumButton, {
            onClick: onStartTrialClick,
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("8EYBJ"))))), {
                size: "small",
                label: formatMessage((0, (0, parcelRequire("gblI8")).default).imageAlt),
                primaryColor: `var(--ds-text-inverse, ${(0, $829f609a65d26a98$exports).N0})`
            })
        }, formatMessage((0, (0, parcelRequire("gblI8")).default).startTrialButton)) : (0, ($parcel$interopDefault($5uXOq))).createElement($59ef1621dccedbfe$var$TryPremiumButtonDirectLink, {
            href: premiumTrialUpgradeLink,
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("8EYBJ"))))), {
                size: "small",
                label: formatMessage((0, (0, parcelRequire("gblI8")).default).imageAlt),
                primaryColor: `var(--ds-text-inverse, ${(0, $829f609a65d26a98$exports).N0})`
            })
        }, formatMessage((0, (0, parcelRequire("gblI8")).default).startTrialButton))));
    };
    var $59ef1621dccedbfe$export$2e2bcd8739ae039 = $59ef1621dccedbfe$var$PremiumFeatureGate;
    const $59ef1621dccedbfe$var$TryPremiumButton = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("liGrj")).PremiumUpgradeModalEntryPointButton), {
            ...props,
            theme: (current, themeProps)=>({
                    buttonStyles: {
                        ...current(themeProps).buttonStyles,
                        display: 'flex',
                        alignItems: 'center',
                        background: `linear-gradient(135deg, ${"var(--ds-background-discovery-bold, #6E5DC6)"}, ${"var(--ds-background-brand-bold, #0C66E4)"})`,
                        color: `var(--ds-text-inverse, ${(0, $829f609a65d26a98$exports).N0})`,
                        outline: 'none',
                        boxSizing: 'border-box',
                        padding: `${"var(--ds-space-050, 4px)"} ${"var(--ds-space-150, 12px)"}`,
                        borderRadius: "var(--ds-space-050, 4px)",
                        height: "var(--ds-space-400, 32px)",
                        border: 'none',
                        fontSize: "var(--ds-font-heading-xsmall, normal 600 14px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
                        gap: "var(--ds-space-025, 2px)"
                    },
                    spinnerStyles: current(themeProps).spinnerStyles
                })
        });
    const $59ef1621dccedbfe$var$TryPremiumButtonDirectLink = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9yOBd")).default), {
            ...props,
            theme: (current, themeProps)=>({
                    buttonStyles: {
                        ...current(themeProps).buttonStyles,
                        display: 'flex',
                        alignItems: 'center',
                        background: `linear-gradient(135deg, ${"var(--ds-background-discovery-bold, #6E5DC6)"}, ${"var(--ds-background-brand-bold, #0C66E4)"})`,
                        color: `var(--ds-text-inverse, ${(0, $829f609a65d26a98$exports).N0})`,
                        outline: 'none',
                        boxSizing: 'border-box',
                        padding: `${"var(--ds-space-050, 4px)"} ${"var(--ds-space-150, 12px)"}`,
                        borderRadius: "var(--ds-space-050, 4px)",
                        height: "var(--ds-space-400, 32px)",
                        border: 'none',
                        fontSize: "var(--ds-font-heading-xsmall, normal 600 14px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
                        gap: "var(--ds-space-025, 2px)"
                    },
                    spinnerStyles: current(themeProps).spinnerStyles
                })
        });
    const $59ef1621dccedbfe$var$LearnMoreButton = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9yOBd")).default), {
            ...props,
            theme: (current, themeProps)=>({
                    buttonStyles: {
                        ...current(themeProps).buttonStyles,
                        maxWidth: '250px',
                        marginRight: "var(--ds-space-200, 16px)"
                    },
                    spinnerStyles: current(themeProps).spinnerStyles
                })
        });
    const $59ef1621dccedbfe$var$MenuContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_vchhusvi _2rko1b66 _19itoyrj _11q717kt _qjkjusvi _1y1m18uv _p12f1l5c",
                __cmplp.className
            ])
        });
    });
    const $59ef1621dccedbfe$var$MenuHeaderContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_11q7gup1 _4t3il1r9 _2rko1xsr _ca0qidpf _u5f3idpf _n3tdidpf _19bvidpf _kqswh2mm",
                __cmplp.className
            ])
        });
    });
    const $59ef1621dccedbfe$var$ContentContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1yt41ejb _11q7vuon",
                __cmplp.className
            ])
        });
    });
    const $59ef1621dccedbfe$var$contentDescriptionStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginTop: 'space.100'
    });
    const $59ef1621dccedbfe$var$FooterContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1yt4gqct _11q7vuon _2rko4dok _1e0c1txw _4cvr1h6o _1bahesu3",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("iwnVa", function(module, exports) {
    $parcel$export(module.exports, "useJswPremiumTrialUpgradeLink", ()=>$fc84e0a2b7204935$export$645eda3f5f2163ec);
    var $2vSU6;
    var $ldyPw;
    var $9OXo1;
    var $7qMMP;
    const $fc84e0a2b7204935$export$645eda3f5f2163ec = ({ deepLink: deepLink = true, skip: skip = false, caller: caller })=>{
        const { productEntitlementDetails: productEntitlementDetails } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const jswEntitlement = productEntitlementDetails?.[0, $c06e1abca7c13910$export$1346c9aa3cbb477d];
        const isJswUsingCcp = jswEntitlement?.billingSourceSystem === 'CCP';
        const { data: data, ...rest } = (0, (0, parcelRequire("7qMMP")).useGetBillingPageUrl)({
            isUsingCcp: isJswUsingCcp,
            editionToUpgradeTo: deepLink ? (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION) : undefined,
            caller: caller,
            skip: skip,
            subProduct: (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT)
        });
        return {
            data: {
                premiumTrialUpgradeLink: data.billingPageUrl
            },
            ...rest
        };
    };
});
parcelRegister("7qMMP", function(module, exports) {
    $parcel$export(module.exports, "useGetBillingPageUrl", ()=>$8899a5adc0f59798$export$7b1495b7581dcd01);
    var $5uXOq = parcelRequire("5uXOq");
    var $cVzoY;
    var $8zOmE;
    var $2NMNM;
    var $14XJw;
    var $ldyPw;
    var $16v4H;
    var $9OXo1;
    var $7j7Ij;
    var $5aye8;
    var $5Q4f1;
    var $iHAwr;
    const $8899a5adc0f59798$export$7b1495b7581dcd01 = ({ isUsingCcp: isUsingCcp, editionToUpgradeTo: editionToUpgradeTo, caller: caller, skip: skip = false, subProduct: subProduct, onError: onError })=>{
        const [billingPageUrl, setBillingPageUrl] = (0, $5uXOq.useState)();
        const [loading, setLoading] = (0, $5uXOq.useState)(true);
        const [error, setError] = (0, $5uXOq.useState)(null);
        const tenantContext = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const contextSubProduct = (0, (0, parcelRequire("16v4H")).useSubProduct)();
        const { data: orgId } = (0, (0, parcelRequire("14XJw")).useOrgId)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const subProductToUse = subProduct || contextSubProduct;
        (0, $5uXOq.useEffect)(()=>{
            if (skip) return;
            const loadBillingInfo = async ()=>{
                try {
                    const explicitlyLicensedProducts = (0, (0, parcelRequire("cVzoY")).default)(tenantContext);
                    const productKey = (0, (0, parcelRequire("iHAwr")).getProductKey)(subProductToUse, explicitlyLicensedProducts);
                    const referrerKey = (0, (0, parcelRequire("5aye8")).getProductReferrerKey)(productKey);
                    const commerceProductKey = productKey === (0, $c06e1abca7c13910$export$202f50ee62667e32) ? productKey : `${productKey}.ondemand`;
                    const entitlementId = productKey ? tenantContext.productEntitlementDetails?.[productKey]?.entitlementId : null;
                    if (isUsingCcp) {
                        if (!entitlementId) {
                            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'RetrieveEntitlementId failed', {
                                caller: caller,
                                productKey: productKey,
                                productEntitlementDetails: JSON.stringify(tenantContext.productEntitlementDetails),
                                subProduct: subProductToUse
                            });
                            throw new Error('Empty entitlement ID');
                        }
                        const ccpTransactionAccountId = await (0, (0, parcelRequire("5aye8")).getCcpTransactionAccountId)(entitlementId);
                        const ccpBaseUrl = `${(0, (0, parcelRequire("5Q4f1")).getAdminBaseUrl)()}/billing/o/${orgId}/${ccpTransactionAccountId}/entitlement/${entitlementId}/change-plan?referrer=${referrerKey}&enableMpucup=true`;
                        switch(editionToUpgradeTo){
                            case 0, (0, parcelRequire("ldyPw")).STANDARD_EDITION:
                            case 0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION:
                                setBillingPageUrl(`${ccpBaseUrl}&offeringName=${(0, (0, parcelRequire("7j7Ij")).EDITION_KEY)[editionToUpgradeTo]}`);
                                break;
                            default:
                                setBillingPageUrl(ccpBaseUrl);
                        }
                    } else switch(editionToUpgradeTo){
                        case 0, (0, parcelRequire("ldyPw")).STANDARD_EDITION:
                        case 0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION:
                            setBillingPageUrl(`${(0, (0, parcelRequire("5Q4f1")).getAdminBaseUrl)()}/s/${tenantContext.cloudId}/billing/applications/change-edition/${commerceProductKey}/${(0, (0, parcelRequire("7j7Ij")).EDITION_KEY)[editionToUpgradeTo]}`);
                            break;
                        default:
                            setBillingPageUrl(`${(0, (0, parcelRequire("5Q4f1")).getAdminBaseUrl)()}/s/${tenantContext.cloudId}/billing/applications/change-edition/${commerceProductKey}`);
                    }
                } catch (e) {
                    setError(e);
                    if (onError && e instanceof Error) onError(e);
                } finally{
                    setLoading(false);
                }
            };
            loadBillingInfo();
        }, [
            caller,
            createAnalyticsEvent,
            editionToUpgradeTo,
            isUsingCcp,
            onError,
            orgId,
            skip,
            subProduct,
            subProductToUse,
            tenantContext,
            tenantContext.cloudId
        ]);
        return {
            data: {
                billingPageUrl: billingPageUrl
            },
            loading: loading,
            error: error
        };
    };
});
parcelRegister("7j7Ij", function(module, exports) {
    $parcel$export(module.exports, "EDITION_KEY", ()=>$2797dd9db1687273$export$d73720c31c622c1f);
    var $ldyPw;
    const $2797dd9db1687273$export$d73720c31c622c1f = {
        STANDARD_EDITION: (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION_KEY),
        PREMIUM_EDITION: (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION_KEY)
    };
    const $2797dd9db1687273$export$5b7423264d48ab04 = 'not-enrolled';
    const $2797dd9db1687273$export$fa04ad826acc90da = 'variation';
});
parcelRegister("5aye8", function(module, exports) {
    $parcel$export(module.exports, "getProductReferrerKey", ()=>$c065e9df012feb0c$export$b4fb3304d5d8fc5f);
    $parcel$export(module.exports, "getCcpTransactionAccountId", ()=>$c065e9df012feb0c$export$5186b9d213f626fd);
    var $7f4tk;
    const $c065e9df012feb0c$export$b4fb3304d5d8fc5f = (productKey)=>{
        if (productKey === (0, $c06e1abca7c13910$export$1346c9aa3cbb477d)) return 'jsw';
        if (productKey === (0, $c06e1abca7c13910$export$c8c1c8c3f8ddba61)) return 'jsm';
        if (productKey === (0, $c06e1abca7c13910$export$202f50ee62667e32)) return 'jpd';
        if (productKey === (0, $c06e1abca7c13910$export$db515c8cec1e6f79)) return 'jwm';
        return null;
    };
    const $c065e9df012feb0c$export$5186b9d213f626fd = async (entitlementId)=>{
        const entitlementDetails = await (0, (0, parcelRequire("7f4tk")).default)(`/gateway/api/ccp/api/v1/entitlements/${entitlementId}/details`);
        if (!entitlementDetails?.transactionAccountId) throw Error('Could not get transactionAccountId');
        return (entitlementDetails ?? {}).transactionAccountId;
    };
});
parcelRegister("liGrj", function(module, exports) {
    $parcel$export(module.exports, "PremiumUpgradeModalEntryPointButton", ()=>$c0d01fc7cb7615a5$export$9901be7804227da7);
    var $5uXOq = parcelRequire("5uXOq");
    var $9yOBd;
    var $47gW9;
    var $ivDCs;
    var $4mO32;
    var $b3Gth;
    const $c0d01fc7cb7615a5$var$entryPointParams = {};
    const $c0d01fc7cb7615a5$export$9901be7804227da7 = (buttonProps)=>{
        const { entryPointActions: entryPointActions, entryPointReferenceSubject: entryPointReferenceSubject } = (0, (0, parcelRequire("4mO32")).useEntryPoint)((0, (0, parcelRequire("b3Gth")).entryPointPremiumUpgradeModal), $c0d01fc7cb7615a5$var$entryPointParams);
        const buttonTrigger = (0, (0, parcelRequire("47gW9")).useEntryPointButtonTrigger)(entryPointActions);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9yOBd")).default), {
            ...buttonProps,
            ref: buttonTrigger
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ivDCs")).ModalEntryPointContainer), {
            entryPointReferenceSubject: entryPointReferenceSubject,
            id: "jira-premium-upgrade-modal",
            packageName: "jiraPremiumUpgradeModal",
            errorFallback: "flag",
            runtimeProps: {
                onClose: entryPointActions.unload
            },
            entryPointActions: entryPointActions
        }));
    };
});
parcelRegister("b3Gth", function(module, exports) {
    $parcel$export(module.exports, "entryPointPremiumUpgradeModal", ()=>$a50fd4829307668f$export$7116d5b1a93db8d6);
    var $eufEc;
    var $fEDBv;
    const $a50fd4829307668f$export$7116d5b1a93db8d6 = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("paDrG")).then((module)=>module.PremiumUpgradeModalEntryPoint), {
            moduleId: "1102b440"
        }),
        getPreloadProps: ()=>({})
    });
});
parcelRegister("paDrG", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("hPzOn"),
        $6fhBS("5azfZ")
    ]).then(()=>parcelRequire('5s8iH'));
});
parcelRegister("iIz7a", function(module, exports) {
    $parcel$export(module.exports, "FeatureGateLogo", ()=>$bde809f68e5981c8$export$83d4ce06fb8c3c0);
    var $5uXOq = parcelRequire("5uXOq");
    var $5qRwM;
    var $bsqOw = parcelRequire("bsqOw");
    var $4pbFL = parcelRequire("4pbFL");
    const $bde809f68e5981c8$export$83d4ce06fb8c3c0 = ({ colorMode: colorMode, alt: alt })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5qRwM")).default), {
            src: colorMode === 'dark' ? (0, ($parcel$interopDefault($bsqOw))) : (0, ($parcel$interopDefault($4pbFL))),
            alt: alt
        });
});
parcelRegister("bsqOw", function(module, exports) {
    module.exports = new URL("plans-standard-dark.fce82c99.svg", import.meta.url).toString();
});
parcelRegister("4pbFL", function(module, exports) {
    module.exports = new URL("plans-standard-light.fde35b0d.svg", import.meta.url).toString();
});
parcelRegister("gblI8", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9ba9ec5dc8deac7d$export$2e2bcd8739ae039);
    var $7VHMR;
    var $9ba9ec5dc8deac7d$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "atlassian-navigation.plans.menu.premium-feature-gate.title",
            "defaultMessage": "Unlock advanced planning tools"
        },
        imageAlt: {
            "id": "atlassian-navigation.plans.menu.premium-feature-gate.image-alt",
            "defaultMessage": "Upgrade to Jira Premium"
        },
        description: {
            "id": "atlassian-navigation.plans.menu.premium-feature-gate.description",
            "defaultMessage": "Boost agility with capacity planning, dependency mapping, and more. Try Jira Premium's high-level cross-team planning tools."
        },
        learnMoreLink: {
            "id": "atlassian-navigation.plans.menu.premium-feature-gate.learn-more-link",
            "defaultMessage": "Read the guide"
        },
        startTrialButton: {
            "id": "atlassian-navigation.plans.menu.premium-feature-gate.start-trial-button",
            "defaultMessage": "Try it free"
        }
    });
});
parcelRegister("2FrC0", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$10a254be368ab7ac$export$2e2bcd8739ae039);
    var $7VHMR;
    var $10a254be368ab7ac$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        plans: {
            "id": "atlassian-navigation.plans.plans",
            "defaultMessage": "Plans"
        }
    });
});
parcelRegister("klog1", function(module, exports) {
    $parcel$export(module.exports, "ProductHome", ()=>$0ec9a9378b2b1a20$export$32d61f70e04b6020);
    var $5uXOq = parcelRequire("5uXOq");
    var $68lE4;
    var $esSwC;
    var $29YN9;
    var $ijIzb;
    var $34gGM;
    var $fxNl3;
    var $epnSS;
    var $kxZMz;
    var $dC5iT;
    var $7XYc4;
    var $4R6GH;
    var $koVbs;
    var $6cNb0;
    var $3HwlS;
    var $kJP8l;
    var $g8Ctp;
    var $lVJAU;
    var $Mp6qH;
    var $4uXxK;
    var $52wwV;
    var $iPj86;
    var $b2u1Z;
    var $l6mLM;
    const $0ec9a9378b2b1a20$export$6d49a893c897b0cc = {
        jira: {
            icon: (0, (0, parcelRequire("29YN9")).JiraIcon),
            logo: (0, (0, parcelRequire("ijIzb")).JiraLogo)
        },
        serviceDesk: {
            icon: (0, (0, parcelRequire("epnSS")).JiraServiceManagementIcon),
            logo: (0, (0, parcelRequire("kxZMz")).JiraServiceManagementLogo)
        },
        'product-discovery': {
            icon: (0, (0, parcelRequire("34gGM")).JiraProductDiscoveryIcon),
            logo: (0, (0, parcelRequire("fxNl3")).JiraProductDiscoveryLogo)
        }
    };
    const $0ec9a9378b2b1a20$var$ProductHome = (0, (0, parcelRequire("7XYc4")).componentWithCondition)(()=>(0, (0, parcelRequire("3HwlS")).getWillShowNav4)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1'), $0ec9a9378b2b1a20$var$ProductHomeNew, $0ec9a9378b2b1a20$var$ProductHomeOld);
    const $0ec9a9378b2b1a20$export$32d61f70e04b6020 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).HOME
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($0ec9a9378b2b1a20$var$ProductHome, props));
    function $0ec9a9378b2b1a20$var$ProductHomeOld({ AkCustomProductHome: AkCustomProductHome = (0, (0, parcelRequire("68lE4")).default), AkProductHome: AkProductHome = (0, (0, parcelRequire("esSwC")).default), useCustomLogo: useCustomLogo = (0, (0, parcelRequire("52wwV")).useCustomLogo), useNavigationItemAnalytics: useNavigationItemAnalytics = (0, (0, parcelRequire("iPj86")).useNavigationItemAnalytics), useProductName: useProductName = (0, (0, parcelRequire("kJP8l")).useProductName), useRouterActions: useRouterActions = (0, (0, parcelRequire("lVJAU")).useRouterActions), useSiteTitle: useSiteTitle = (0, (0, parcelRequire("b2u1Z")).useSiteTitle) }) {
        const testIdPrefix = (0, (0, parcelRequire("6cNb0")).testIdGenerate)('product-home-');
        const logoUrl = useCustomLogo();
        const productName = useProductName();
        const siteTitleData = useSiteTitle();
        const siteTitle = siteTitleData.shouldShow && siteTitleData.value || undefined;
        const triggerAnalytics = useNavigationItemAnalytics({
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).LOGO
        });
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { push: push } = useRouterActions();
        const homeUrl = '/jira';
        const navigateToHome = (0, $5uXOq.useCallback)((ev)=>{
            triggerAnalytics();
            if (ev.ctrlKey || ev.metaKey) return;
            ev.preventDefault();
            push(homeUrl);
        }, [
            triggerAnalytics,
            push,
            homeUrl
        ]);
        if (logoUrl) return (0, ($parcel$interopDefault($5uXOq))).createElement(AkCustomProductHome, {
            iconUrl: logoUrl,
            logoUrl: logoUrl,
            logoAlt: formatMessage((0, (0, parcelRequire("l6mLM")).default).customLogoAltText),
            iconAlt: formatMessage((0, (0, parcelRequire("l6mLM")).default).customIconAltText),
            href: homeUrl,
            onClick: navigateToHome,
            siteTitle: siteTitle,
            testId: testIdPrefix
        });
        if ((0, (0, parcelRequire("4R6GH")).fg)('blu-5786-jswcloud-27436-primary-nav-msg')) {
            const { icon: Icon, logo: Logo } = $0ec9a9378b2b1a20$var$getProductIconAndLogo(productName);
            const label = $0ec9a9378b2b1a20$var$getLogoLabel(formatMessage, productName);
            return (0, ($parcel$interopDefault($5uXOq))).createElement(AkProductHome, {
                icon: (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement(Icon, {
                        ...props,
                        label: label
                    }),
                logo: (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement(Logo, {
                        ...props,
                        label: label
                    }),
                href: homeUrl,
                onClick: navigateToHome,
                siteTitle: siteTitle,
                testId: testIdPrefix
            });
        }
        const { icon: icon, logo: logo } = $0ec9a9378b2b1a20$var$getProductIconAndLogo(productName);
        return (0, ($parcel$interopDefault($5uXOq))).createElement(AkProductHome, {
            icon: icon,
            logo: logo,
            href: homeUrl,
            onClick: navigateToHome,
            siteTitle: siteTitle,
            testId: testIdPrefix
        });
    }
    function $0ec9a9378b2b1a20$var$ProductHomeNew({ useCustomLogo: useCustomLogo = (0, (0, parcelRequire("52wwV")).useCustomLogo), useCustomIcon: useCustomIcon = (0, (0, parcelRequire("52wwV")).useCustomIcon), useNavigationItemAnalytics: useNavigationItemAnalytics = (0, (0, parcelRequire("iPj86")).useNavigationItemAnalytics), useProductName: useProductName = (0, (0, parcelRequire("kJP8l")).useProductName), useRouterActions: useRouterActions = (0, (0, parcelRequire("lVJAU")).useRouterActions) }) {
        const testIdPrefix = (0, (0, parcelRequire("6cNb0")).testIdGenerate)('product-home-');
        const logoUrl = useCustomLogo();
        const iconUrl = useCustomIcon();
        const productName = useProductName();
        const triggerAnalytics = useNavigationItemAnalytics({
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).LOGO
        });
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { push: push } = useRouterActions();
        const homeUrl = '/jira';
        const navigateToHome = (0, $5uXOq.useCallback)((ev)=>{
            triggerAnalytics();
            if (ev.ctrlKey || ev.metaKey) return;
            ev.preventDefault();
            push(homeUrl);
        }, [
            triggerAnalytics,
            push,
            homeUrl
        ]);
        const { icon: ProductIcon, logo: ProductLogo } = $0ec9a9378b2b1a20$var$getProductIconAndLogo(productName);
        const Logo = (0, $5uXOq.useMemo)(()=>logoUrl ? ()=>(0, ($parcel$interopDefault($5uXOq))).createElement($0ec9a9378b2b1a20$var$CustomImgNav4, {
                    alt: formatMessage((0, (0, parcelRequire("l6mLM")).default).customLogoAltText),
                    src: logoUrl,
                    testId: `${testIdPrefix}-logo`
                }) : (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement(ProductLogo, {
                    ...props,
                    testId: `${testIdPrefix}-logo`
                }), [
            logoUrl,
            ProductLogo,
            formatMessage,
            testIdPrefix
        ]);
        const Icon = (0, $5uXOq.useMemo)(()=>logoUrl && iconUrl ? ()=>(0, ($parcel$interopDefault($5uXOq))).createElement($0ec9a9378b2b1a20$var$CustomImgNav4, {
                    alt: formatMessage((0, (0, parcelRequire("l6mLM")).default).customIconAltText),
                    src: iconUrl,
                    testId: `${testIdPrefix}-icon`
                }) : (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement(ProductIcon, {
                    ...props,
                    testId: `${testIdPrefix}-icon`
                }), [
            logoUrl,
            iconUrl,
            ProductIcon,
            formatMessage,
            testIdPrefix
        ]);
        if ((0, (0, parcelRequire("4R6GH")).fg)('blu-5786-jswcloud-27436-primary-nav-msg')) {
            const label = $0ec9a9378b2b1a20$var$getLogoLabel(formatMessage, productName);
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                testId: `${testIdPrefix}-container`
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("g8Ctp")).NavLogo), {
                label: label,
                icon: Icon,
                logo: Logo,
                href: homeUrl,
                onClick: navigateToHome
            }));
        }
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            testId: `${testIdPrefix}-container`
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("g8Ctp")).NavLogo), {
            label: "Jira",
            icon: Icon,
            logo: Logo,
            href: homeUrl,
            onClick: navigateToHome
        }));
    }
    function $0ec9a9378b2b1a20$var$getLogoLabel(formatMessage, productName) {
        let label = '';
        if (productName === 'serviceDesk') label = formatMessage((0, (0, parcelRequire("l6mLM")).default).jsmLogoLabel);
        else if (productName === 'product-discovery') label = formatMessage((0, (0, parcelRequire("l6mLM")).default).jpdLogoLabel);
        else label = formatMessage((0, (0, parcelRequire("l6mLM")).default).jiraLogoLabel);
        return label;
    }
    function $0ec9a9378b2b1a20$var$getProductIconAndLogo(productName) {
        if (productName === 'core' || productName === 'software') return $0ec9a9378b2b1a20$export$6d49a893c897b0cc.jira;
        return $0ec9a9378b2b1a20$export$6d49a893c897b0cc[productName];
    }
    function $0ec9a9378b2b1a20$var$CustomImgNav4({ alt: alt, src: src, testId: testId }) {
        return (0, ($parcel$interopDefault($5uXOq))).createElement("img", {
            src: src,
            alt: alt,
            "data-testid": testId
        });
    }
});
parcelRegister("52wwV", function(module, exports) {
    $parcel$export(module.exports, "useCustomLogo", ()=>$aa86f48989803edf$export$9891fb86d6b41647);
    $parcel$export(module.exports, "useCustomIcon", ()=>$aa86f48989803edf$export$9e69c21df0138642);
    var $5uXOq = parcelRequire("5uXOq");
    var $j8DrX;
    const $aa86f48989803edf$export$9891fb86d6b41647 = ()=>(0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("j8DrX")).default)('ajs-lf-top-adg3-logourl'), []);
    const $aa86f48989803edf$export$9e69c21df0138642 = ()=>(0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("j8DrX")).default)('ajs-fspage-favicon'), []);
});
parcelRegister("b2u1Z", function(module, exports) {
    $parcel$export(module.exports, "useSiteTitle", ()=>$fc4a3f770cbe7bdc$export$3e157e0d79928990);
    var $5uXOq = parcelRequire("5uXOq");
    var $j8DrX;
    const $fc4a3f770cbe7bdc$export$3e157e0d79928990 = ()=>(0, $5uXOq.useMemo)(()=>({
                shouldShow: (0, (0, parcelRequire("j8DrX")).default)('ajs-lf-top-adg3-shouldshowtitle') === 'true',
                value: (0, (0, parcelRequire("j8DrX")).default)('ajs-app-title')
            }), []);
});
parcelRegister("l6mLM", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$874346879bf58ddf$export$2e2bcd8739ae039);
    var $7VHMR;
    var $874346879bf58ddf$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        customLogoAltText: {
            "id": "atlassian-navigation.product-home.custom-logo-alt-text",
            "defaultMessage": "Custom Jira logo"
        },
        customIconAltText: {
            "id": "atlassian-navigation.product-home.custom-icon-alt-text",
            "defaultMessage": "Custom Jira icon"
        },
        jiraLogoLabel: {
            "id": "atlassian-navigation.product-home.jira-logo-label",
            "defaultMessage": "Go to your Jira homepage"
        },
        jsmLogoLabel: {
            "id": "atlassian-navigation.product-home.jsm-logo-label",
            "defaultMessage": "Go to your Jira Service Management homepage"
        },
        jpdLogoLabel: {
            "id": "atlassian-navigation.product-home.jpd-logo-label",
            "defaultMessage": "Go to your Jira Product Discovery homepage"
        }
    });
});
parcelRegister("7bwNu", function(module, exports) {
    $parcel$export(module.exports, "Profile", ()=>$4d6ab55be4705ca2$export$5120f2d0b8d4cab8);
    var $5uXOq = parcelRequire("5uXOq");
    var $jZ4fr;
    var $8Pko5;
    var $ujWmd;
    var $5Fk4Z;
    var $1I7E8;
    var $1bGms;
    var $ixNeq;
    var $kaHBi;
    var $4R6GH;
    var $aWse0;
    var $lmyls;
    var $2Ui4E;
    var $2EqxS;
    var $6cNb0;
    var $3HwlS;
    var $cE2Qr;
    var $cYlk7;
    var $2Ql51;
    var $3t5QD;
    var $9yzc5;
    var $4QigF;
    var $Mp6qH;
    var $4uXxK;
    var $iPj86;
    var $1yM7R;
    var $k7xP2;
    var $ebznH;
    var $ixpv2;
    const $4d6ab55be4705ca2$var$SpotlightTargetDI = ({ children: children, ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Fk4Z")).default), props, (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-test-id": `ak-spotlight-target-${props.name}`
        }, children));
    const $4d6ab55be4705ca2$var$handleMouseEnter = ()=>{
        (0, (0, parcelRequire("4QigF")).LazyOpenMenuItem).preload();
        (0, (0, parcelRequire("9yzc5")).NavigationProfileNavItemWrapperNudgeLazy).preload();
    };
    const $4d6ab55be4705ca2$var$Nav3Profile = ({ AkProfile: AkProfile = (0, (0, parcelRequire("8Pko5")).Profile), Avatar: Avatar = (0, (0, parcelRequire("ujWmd")).default), Menu: Menu = (0, (0, parcelRequire("ebznH")).Menu), Popup: Popup = (0, (0, parcelRequire("1I7E8")).default), SpotlightTarget: SpotlightTarget = $4d6ab55be4705ca2$var$SpotlightTargetDI, useCurrentUser: useCurrentUser = (0, (0, parcelRequire("cYlk7")).useCurrentUser), useExperienceStart: useExperienceStart = (0, (0, parcelRequire("kaHBi")).useExperienceStart), useIntl: useIntl = (0, (0, parcelRequire("aWse0")).useIntlV2), useNavigationItemAnalytics: useNavigationItemAnalytics = (0, (0, parcelRequire("iPj86")).useNavigationItemAnalytics), useTopMenus: useTopMenus = (0, (0, parcelRequire("2Ui4E")).useTopMenus), useUserBoardId: useUserBoardId = (0, (0, parcelRequire("1yM7R")).useUserBoardId) })=>{
        const { formatMessage: formatMessage } = useIntl();
        const [isMenuOpen, { toggle: toggleMenu, off: closeMenu }] = useTopMenus((0, (0, parcelRequire("lmyls")).MENU_ID).PROFILE);
        const triggerAnalytics = useNavigationItemAnalytics({
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).PROFILE
        });
        const { data: { user: user } } = useCurrentUser();
        const onStart = useExperienceStart({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU),
            experienceId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).PROFILE,
            analyticsSource: 'atlassian-navigation'
        });
        const [nudgeStep] = (0, (0, parcelRequire("cE2Qr")).useNudgeStep)();
        const [hasNudgeActive] = (0, (0, parcelRequire("cE2Qr")).useHasNudgeActive)();
        const [userBoardId, userBoardOwnerAccountId] = useUserBoardId(isMenuOpen);
        const testIdPrefix = (0, (0, parcelRequire("6cNb0")).testIdGenerate)('secondary-actions', 'profile');
        const [userTraits, { setUserTraits: setUserTraits }] = (0, (0, parcelRequire("ixNeq")).useUserTraits)();
        const fetchUserTraits = (0, (0, parcelRequire("ixNeq")).useFetchUserTraitsOld)();
        const setUserTraitsIfNotAvailable = (0, $5uXOq.useCallback)(async ()=>{
            if (user.isAnonymous) return;
            if (!userTraits?.attributes?.length && userTraits?.attributes !== null) {
                const newTraits = await fetchUserTraits();
                if (newTraits !== false) setUserTraits(newTraits);
            }
        }, [
            user.isAnonymous,
            userTraits?.attributes,
            fetchUserTraits,
            setUserTraits
        ]);
        const onClick = (0, $5uXOq.useCallback)(()=>{
            onStart();
            toggleMenu();
            triggerAnalytics();
        }, [
            onStart,
            toggleMenu,
            triggerAnalytics
        ]);
        const content = (0, $5uXOq.useCallback)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement(Menu, {
                userBoardId: userBoardId,
                userBoardOwnerAccountId: userBoardOwnerAccountId,
                testIdPrefix: testIdPrefix
            }), [
            userBoardId,
            userBoardOwnerAccountId,
            testIdPrefix
        ]);
        const Trigger = (0, $5uXOq.useCallback)((triggerProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement("div", {
                "data-testid": (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'menu-trigger'),
                onMouseOver: setUserTraitsIfNotAvailable,
                onFocus: setUserTraitsIfNotAvailable
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3t5QD")).ConditionalNudgeWrapper), {
                conditionsToApplyWrapper: (0, $6784cbc7d62e5d7c$export$7aa8bceae32c7862) === nudgeStep && hasNudgeActive,
                Wrapper: (0, (0, parcelRequire("9yzc5")).NavigationProfileNavItemWrapperNudgeAsync)
            }, (0, ($parcel$interopDefault($5uXOq))).createElement(AkProfile, {
                ...triggerProps,
                icon: (0, ($parcel$interopDefault($5uXOq))).createElement(SpotlightTarget, {
                    name: (0, (0, parcelRequire("Mp6qH")).PROFILE_SPOTLIGHT_KEY)
                }, (0, ($parcel$interopDefault($5uXOq))).createElement(Avatar, {
                    src: user.avatarUrls ? user.avatarUrls['48x48'] : undefined,
                    size: "small",
                    borderColor: "transparent"
                })),
                isSelected: isMenuOpen,
                onClick: onClick,
                tooltip: formatMessage((0, (0, parcelRequire("ixpv2")).default).tooltip),
                onMouseEnter: $4d6ab55be4705ca2$var$handleMouseEnter,
                "aria-label": formatMessage((0, (0, parcelRequire("ixpv2")).default).tooltip),
                id: "atlassian-navigation.ui.profile.icon"
            })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jZ4fr")).EngagementSpotlight), {
                engagementId: (0, (0, parcelRequire("Mp6qH")).PROFILE_SPOTLIGHT_KEY)
            })), [
            formatMessage,
            isMenuOpen,
            onClick,
            testIdPrefix,
            user,
            nudgeStep,
            hasNudgeActive
        ]);
        if (user.isAnonymous) return null;
        if (false) return (0, ($parcel$interopDefault($5uXOq))).createElement(Trigger, {
            "aria-expanded": false,
            "aria-haspopup": true
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement(Popup, {
            content: content,
            isOpen: isMenuOpen,
            onClose: closeMenu,
            placement: "bottom-end",
            shouldRenderToParent: true,
            trigger: Trigger,
            label: formatMessage((0, (0, parcelRequire("ixpv2")).default).tooltip),
            role: "menu"
        });
    };
    const $4d6ab55be4705ca2$var$Nav4Profile = ({ AccountMenu: AccountMenu = (0, (0, parcelRequire("1bGms")).AccountMenu), AkProfile: AkProfile = (0, (0, parcelRequire("8Pko5")).Profile), Avatar: Avatar = (0, (0, parcelRequire("ujWmd")).default), MenuNav4: MenuNav4 = (0, (0, parcelRequire("k7xP2")).MenuNav4), useCurrentUser: useCurrentUser = (0, (0, parcelRequire("cYlk7")).useCurrentUser), useIntl: useIntl = (0, (0, parcelRequire("aWse0")).useIntlV2), useUserBoardId: useUserBoardId = (0, (0, parcelRequire("1yM7R")).useUserBoardId) })=>{
        const { formatMessage: formatMessage } = useIntl();
        const { theme: theme, setTheme: setTheme } = (0, (0, parcelRequire("2Ql51")).useThemePreference)();
        const { data: { user: user } } = useCurrentUser();
        const emailAddress = user.emailAddress || '';
        const atlassianSettingsLink = (0, (0, parcelRequire("2EqxS")).useAtlassianPersonalSettingsLink)(emailAddress);
        const switchAccountLink = (0, (0, parcelRequire("2EqxS")).useSwitchAccountLink)();
        const profileLink = (0, (0, parcelRequire("2EqxS")).useProfileLink)();
        const [userBoardId, userBoardOwnerAccountId] = useUserBoardId(false);
        const testIdPrefix = (0, (0, parcelRequire("6cNb0")).testIdGenerate)('secondary-actions', 'profile');
        if (user.isAnonymous) return null;
        if (false && !(0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_2')) return (0, ($parcel$interopDefault($5uXOq))).createElement(AkProfile, {
            "aria-haspopup": true,
            "aria-expanded": false,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'trigger'),
            icon: (0, ($parcel$interopDefault($5uXOq))).createElement(Avatar, {
                size: "small",
                borderColor: "transparent"
            }),
            tooltip: formatMessage((0, (0, parcelRequire("ixpv2")).default).tooltip),
            "aria-label": formatMessage((0, (0, parcelRequire("ixpv2")).default).tooltip),
            id: "atlassian-navigation.ui.profile.icon"
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement(AccountMenu, {
            testId: testIdPrefix,
            name: user.displayName,
            picture: user.avatarUrls ? user.avatarUrls['48x48'] : undefined,
            email: emailAddress,
            viewProfileLink: profileLink,
            manageProfileLink: atlassianSettingsLink,
            switchAccountLink: switchAccountLink,
            logOutLink: "/logout",
            onThemeChange: setTheme,
            currentTheme: theme,
            extraMenuItems: (0, ($parcel$interopDefault($5uXOq))).createElement(MenuNav4, {
                userBoardId: userBoardId,
                userBoardOwnerAccountId: userBoardOwnerAccountId,
                testIdPrefix: testIdPrefix
            })
        });
    };
    const $4d6ab55be4705ca2$var$Profile = (props)=>(0, (0, parcelRequire("3HwlS")).getWillShowNav4)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_new_identity_menu') ? (0, ($parcel$interopDefault($5uXOq))).createElement($4d6ab55be4705ca2$var$Nav4Profile, props) : (0, ($parcel$interopDefault($5uXOq))).createElement($4d6ab55be4705ca2$var$Nav3Profile, props);
    const $4d6ab55be4705ca2$export$5120f2d0b8d4cab8 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).PROFILE
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($4d6ab55be4705ca2$var$Profile, props));
});
parcelRegister("ixNeq", function(module, exports) {
    $parcel$export(module.exports, "useFetchUserTraitsOld", ()=>$5e172a254cf471a7$export$d5a27009aa54a078);
    $parcel$export(module.exports, "useUserTraits", ()=>$5e172a254cf471a7$export$49363c8b904e0dc7);
    var $5uXOq = parcelRequire("5uXOq");
    var $eusud;
    var $90yGq;
    var $koeKL;
    var $3sQ5x;
    var $iXqE5;
    const $5e172a254cf471a7$var$setUserTraits = (newTraits)=>({ getState: getState, setState: setState })=>{
            const currentUserTraits = getState();
            setState({
                ...currentUserTraits,
                attributes: newTraits
            });
        };
    const $5e172a254cf471a7$var$addUserTrait = (trait)=>({ getState: getState, setState: setState })=>{
            const currentUserTraits = getState();
            if (currentUserTraits.attributes?.some((t)=>t.name === trait.name)) return;
            setState({
                attributes: currentUserTraits.attributes?.concat(trait) ?? [
                    trait
                ]
            });
        };
    const $5e172a254cf471a7$var$fetchUserTraits = (cloudId, accountId)=>async ({ getState: getState, setState: setState })=>{
            if (!accountId) return null;
            const state = getState();
            if (state.attributes !== null) return;
            const getUserTraits = async ()=>{
                try {
                    const userTraitsData = await (0, (0, parcelRequire("eusud")).performGetRequest)(`/gateway/api/tap-delivery/api/v3/personalization/site/${cloudId}/user/${accountId}`);
                    return userTraitsData?.attributes ?? [];
                } catch  {
                    return null;
                }
            };
            const userTraits = await getUserTraits();
            setState({
                attributes: userTraits
            });
        };
    const $5e172a254cf471a7$var$actions = {
        setUserTraits: $5e172a254cf471a7$var$setUserTraits,
        fetchUserTraits: $5e172a254cf471a7$var$fetchUserTraits,
        addUserTrait: $5e172a254cf471a7$var$addUserTrait
    };
    const $5e172a254cf471a7$var$initialState = {
        attributes: null
    };
    const $5e172a254cf471a7$export$d5a27009aa54a078 = ()=>{
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const accountId = (0, (0, parcelRequire("90yGq")).useAccountId)();
        const [loading, setLoading] = (0, $5uXOq.useState)(false);
        return (0, $5uXOq.useCallback)(async ()=>{
            if (!accountId) return null;
            const url = `/gateway/api/tap-delivery/api/v3/personalization/site/${cloudId}/user/${accountId}`;
            const getUserTraits = async ()=>{
                try {
                    setLoading(true);
                    const userTraitsData = await (0, (0, parcelRequire("eusud")).performGetRequest)(url);
                    return !userTraitsData?.attributes?.length ? null : userTraitsData?.attributes;
                } catch  {
                    return null;
                } finally{
                    setLoading(false);
                }
            };
            if (!loading) {
                const userTraits = await getUserTraits();
                return userTraits;
            }
            return false;
        }, [
            accountId,
            cloudId,
            loading
        ]);
    };
    const $5e172a254cf471a7$export$6e688ed53ae076d5 = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: $5e172a254cf471a7$var$initialState,
        actions: $5e172a254cf471a7$var$actions,
        name: 'UserTraits'
    });
    const $5e172a254cf471a7$export$49363c8b904e0dc7 = (0, (0, parcelRequire("3sQ5x")).createHook)($5e172a254cf471a7$export$6e688ed53ae076d5);
    const $5e172a254cf471a7$export$4f249a943026a90b = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($5e172a254cf471a7$export$6e688ed53ae076d5);
});
parcelRegister("3t5QD", function(module, exports) {
    $parcel$export(module.exports, "ConditionalNudgeWrapper", ()=>$44a5067dcee1bfd9$export$56d544c4f479b7fb);
    var $5uXOq = parcelRequire("5uXOq");
    const $44a5067dcee1bfd9$export$56d544c4f479b7fb = ({ children: children, Wrapper: Wrapper, conditionsToApplyWrapper: conditionsToApplyWrapper, ...restProps })=>{
        if (conditionsToApplyWrapper && !false && children !== null && children !== false) return (0, ($parcel$interopDefault($5uXOq))).createElement(Wrapper, restProps, children);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
    };
});
parcelRegister("9yzc5", function(module, exports) {
    $parcel$export(module.exports, "NavigationProfileNavItemWrapperNudgeLazy", ()=>$e1b30bf1670573e8$export$3651b15d92c1c280);
    $parcel$export(module.exports, "NavigationProfileNavItemWrapperNudgeAsync", ()=>$e1b30bf1670573e8$export$deb2cae843941204);
    var $5uXOq = parcelRequire("5uXOq");
    var $bBbEG;
    var $lfTZh;
    var $kxZPA;
    const $e1b30bf1670573e8$export$3651b15d92c1c280 = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("iJYhF")).then((exportedModule)=>exportedModule.NavigationProfileNavItemWrapperNudge), {
        ssr: false,
        moduleId: "./src/packages/software/onboarding-nudges/src/ui/navigation-profile/index.tsx"
    });
    const $e1b30bf1670573e8$export$deb2cae843941204 = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bBbEG")).OnboardingNudgeErrorBoundary), {
            id: "asyncProfileNudge",
            fallback: children,
            attributes: {
                NAVIGATION_APPS_PROFILE_ITEM_NUDGE_ID: $6784cbc7d62e5d7c$export$7aa8bceae32c7862
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "navigation-profile-nav-item-wrapper-nudge-lazy",
            fallback: children
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($e1b30bf1670573e8$export$3651b15d92c1c280, {
            nudgeId: (0, $6784cbc7d62e5d7c$export$7aa8bceae32c7862)
        }, children)));
    $e1b30bf1670573e8$export$deb2cae843941204.displayName = 'NavigationProfileNavItemWrapperNudgeAsync';
});
parcelRegister("iJYhF", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("91SdU").then(()=>parcelRequire('doFig'));
});
parcelRegister("4QigF", function(module, exports) {
    $parcel$export(module.exports, "JswOnboardingChecklist", ()=>$a3ce66216f7840ff$export$aea93106aaeec3fc);
    $parcel$export(module.exports, "JswOnboardingButton", ()=>$a3ce66216f7840ff$export$c0cbc1198da400db);
    $parcel$export(module.exports, "LazyOpenMenuItem", ()=>$a3ce66216f7840ff$export$e6a8d3e2a6acc946);
    $parcel$export(module.exports, "OpenMenuItem", ()=>$a3ce66216f7840ff$export$67c4b22563f33286);
    var $5uXOq = parcelRequire("5uXOq");
    var $hYRTO;
    var $39xOx;
    var $4R6GH;
    var $94Uc1;
    var $353uQ;
    var $30rnQ;
    var $iuzc0;
    var $l8ruj;
    var $kxZPA;
    const $a3ce66216f7840ff$var$LazyChecklist = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("bEWzc")).then(({ JswOnboardingChecklistNext: JswOnboardingChecklistNext })=>JswOnboardingChecklistNext), {
        ssr: false,
        moduleId: "./src/packages/platform/ui/onboarding/onboarding-checklist-next/src/ui/onboarding-checklist/index.tsx"
    });
    const $a3ce66216f7840ff$var$FallbackChecklist = ()=>{
        (0, $5uXOq.useLayoutEffect)(()=>{
            (0, (0, parcelRequire("353uQ")).qsChecklistAutoLoadTime).start();
        }, []);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null);
    };
    const $a3ce66216f7840ff$export$aea93106aaeec3fc = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
            name: "quickstart"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "asyncChecklistComponent",
            packageName: "jiraSoftwareOnboardingQuickstart",
            teamName: "jlove-makkuro",
            fallback: "unmount",
            attributes: (0, (0, parcelRequire("30rnQ")).addChunkLoadErrorAnalyticsAttribute)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hYRTO")).default), {
            name: "software-onboarding-checklist-new",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement($a3ce66216f7840ff$var$FallbackChecklist, null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($a3ce66216f7840ff$var$LazyChecklist, null))));
    const $a3ce66216f7840ff$var$LazyOpenButton = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("iC4lO")).then(({ JswOnboardingButtonNext: JswOnboardingButtonNext })=>JswOnboardingButtonNext), {
        ssr: false,
        moduleId: "./src/packages/software/onboarding-quickstart/src/ui/onboarding-button/index.tsx"
    });
    const $a3ce66216f7840ff$var$FallbackButton = ()=>{
        (0, $5uXOq.useLayoutEffect)(()=>{
            (0, (0, parcelRequire("353uQ")).qsButtonAutoLoadTime).start();
        }, []);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null);
    };
    const $a3ce66216f7840ff$export$c0cbc1198da400db = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "asyncChecklistButtonComponent",
            packageName: "jiraSoftwareOnboardingQuickstart",
            teamName: "jlove-makkuro",
            fallback: "unmount",
            attributes: (0, (0, parcelRequire("30rnQ")).addChunkLoadErrorAnalyticsAttribute)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hYRTO")).default), {
            name: "software-onboarding-checklist-button-new",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement($a3ce66216f7840ff$var$FallbackButton, null)
        }, (0, (0, parcelRequire("4R6GH")).fg)('jira_hide_qs_pill_on_bulk_edit') ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iuzc0")).QuickstartPillVisibilityContainer), {
            scope: "ListPageQuickstartPill"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($a3ce66216f7840ff$var$LazyOpenButton, null)) : (0, ($parcel$interopDefault($5uXOq))).createElement($a3ce66216f7840ff$var$LazyOpenButton, null)));
    const $a3ce66216f7840ff$export$e6a8d3e2a6acc946 = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("6LlxU")).then(({ OpenMenuItem: OpenMenuItem })=>{
            (0, (0, parcelRequire("353uQ")).openMenuItemLoadTime).mark((0, (0, parcelRequire("94Uc1")).PERFORMANCE_MARK).BUNDLE_LOAD_END);
            return OpenMenuItem;
        }), {
        moduleId: "./src/packages/software/onboarding-quickstart/src/ui/open-menu-item/index.tsx"
    });
    const $a3ce66216f7840ff$var$SubmitOpenMenuItemLoadStart = ()=>{
        (0, $5uXOq.useLayoutEffect)(()=>{
            (0, (0, parcelRequire("353uQ")).openMenuItemLoadTime).start();
            (0, (0, parcelRequire("353uQ")).openMenuItemLoadTime).mark((0, (0, parcelRequire("94Uc1")).PERFORMANCE_MARK).BUNDLE_LOAD_START);
        }, []);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null);
    };
    const $a3ce66216f7840ff$export$67c4b22563f33286 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "asyncOpenMenuItemComponent",
            packageName: "jiraSoftwareOnboardingQuickstart",
            teamName: "jlove-makkuro",
            fallback: "unmount",
            attributes: (0, (0, parcelRequire("30rnQ")).addChunkLoadErrorAnalyticsAttribute)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hYRTO")).default), {
            name: "software-onboarding-checklist-open-menu-item",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement($a3ce66216f7840ff$var$SubmitOpenMenuItemLoadStart, null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($a3ce66216f7840ff$export$e6a8d3e2a6acc946, props)));
});
parcelRegister("353uQ", function(module, exports) {
    $parcel$export(module.exports, "isMetricRunning", ()=>$56fd78ac33bc6df4$export$5c2d3e87043198cf);
    $parcel$export(module.exports, "openMenuItemLoadTime", ()=>$56fd78ac33bc6df4$export$9aef270bc11ae96b);
    $parcel$export(module.exports, "qsButtonAutoLoadTime", ()=>$56fd78ac33bc6df4$export$844f48c93ed69757);
    $parcel$export(module.exports, "qsChecklistAutoLoadTime", ()=>$56fd78ac33bc6df4$export$c501d2dfab0e9e45);
    $parcel$export(module.exports, "qsChecklistMinimiseInteraction", ()=>$56fd78ac33bc6df4$export$ac78fba7425e64da);
    $parcel$export(module.exports, "qsChecklistDismissInteraction", ()=>$56fd78ac33bc6df4$export$494d4094aaa2bc4d);
    $parcel$export(module.exports, "qsButtonOpenInteraction", ()=>$56fd78ac33bc6df4$export$da7d38041ee220c8);
    $parcel$export(module.exports, "qsButtonDismissInteraction", ()=>$56fd78ac33bc6df4$export$ce2841e9d619cdfd);
    var $dGrdR;
    var $94Uc1;
    const $56fd78ac33bc6df4$export$5c2d3e87043198cf = (metric)=>metric?.state === 'started';
    const $56fd78ac33bc6df4$export$9aef270bc11ae96b = (0, (0, parcelRequire("dGrdR")).metrics).custom({
        key: `${(0, (0, parcelRequire("94Uc1")).PERFORMANCE_METRIC_PREFIX)}.open.menu-item.load`,
        timings: [
            {
                key: (0, (0, parcelRequire("94Uc1")).PERFORMANCE_MARK).BUNDLE_LOAD_START,
                endMark: (0, (0, parcelRequire("94Uc1")).PERFORMANCE_MARK).BUNDLE_LOAD_END
            }
        ]
    });
    const $56fd78ac33bc6df4$export$844f48c93ed69757 = (0, (0, parcelRequire("dGrdR")).metrics).custom({
        key: `${(0, (0, parcelRequire("94Uc1")).PERFORMANCE_METRIC_PREFIX)}.button.load`,
        timings: [
            {
                key: (0, (0, parcelRequire("94Uc1")).PERFORMANCE_MARK).BUNDLE_LOAD_START,
                endMark: (0, (0, parcelRequire("94Uc1")).PERFORMANCE_MARK).BUNDLE_LOAD_END
            }
        ]
    });
    const $56fd78ac33bc6df4$export$c501d2dfab0e9e45 = (0, (0, parcelRequire("dGrdR")).metrics).custom({
        key: `${(0, (0, parcelRequire("94Uc1")).PERFORMANCE_METRIC_PREFIX)}.checklist.load`,
        timings: [
            {
                key: (0, (0, parcelRequire("94Uc1")).PERFORMANCE_MARK).BUNDLE_LOAD_START,
                endMark: (0, (0, parcelRequire("94Uc1")).PERFORMANCE_MARK).BUNDLE_LOAD_END
            }
        ]
    });
    const $56fd78ac33bc6df4$export$ac78fba7425e64da = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: `${(0, (0, parcelRequire("94Uc1")).PERFORMANCE_METRIC_PREFIX)}.checklist.minimize`
    });
    const $56fd78ac33bc6df4$export$494d4094aaa2bc4d = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: `${(0, (0, parcelRequire("94Uc1")).PERFORMANCE_METRIC_PREFIX)}.checklist.dismiss`
    });
    const $56fd78ac33bc6df4$export$da7d38041ee220c8 = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: `${(0, (0, parcelRequire("94Uc1")).PERFORMANCE_METRIC_PREFIX)}.button.open`
    });
    const $56fd78ac33bc6df4$export$ce2841e9d619cdfd = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: `${(0, (0, parcelRequire("94Uc1")).PERFORMANCE_METRIC_PREFIX)}.button.dismiss`
    });
});
parcelRegister("iuzc0", function(module, exports) {
    $parcel$export(module.exports, "QuickstartPillVisibilityContainer", ()=>$eb306ed6e1ecf691$export$1a5202e8ea7b76ab);
    $parcel$export(module.exports, "useQuickstartPillVisibilityStore", ()=>$eb306ed6e1ecf691$export$70dc54081d2075ff);
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    const $eb306ed6e1ecf691$var$actions = {
        toggleShowQuickstart: ({ showQuickstartPill: showQuickstartPill })=>({ setState: setState })=>{
                setState({
                    showQuickstartPill: showQuickstartPill
                });
            }
    };
    const $eb306ed6e1ecf691$var$initialState = {
        showQuickstartPill: null
    };
    const $eb306ed6e1ecf691$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: $eb306ed6e1ecf691$var$initialState,
        actions: $eb306ed6e1ecf691$var$actions,
        name: 'JiraQuickstartPillVisibility'
    });
    const $eb306ed6e1ecf691$export$1a5202e8ea7b76ab = (0, (0, parcelRequire("gg7kZ")).createContainer)($eb306ed6e1ecf691$var$Store, {
        onInit: ()=>({ setState: setState })=>{
                setState({
                    ...$eb306ed6e1ecf691$var$initialState
                });
            }
    });
    const $eb306ed6e1ecf691$export$70dc54081d2075ff = (0, (0, parcelRequire("3sQ5x")).createHook)($eb306ed6e1ecf691$var$Store);
});
parcelRegister("bEWzc", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("craYS")
    ]).then(()=>parcelRequire('5Nzrp'));
});
parcelRegister("iC4lO", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("eNmuD"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("6Iz9r")
    ]).then(()=>parcelRequire('eIh4F'));
});
parcelRegister("6LlxU", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("aOcsJ")
    ]).then(()=>parcelRequire('jVPxT'));
});
parcelRegister("1yM7R", function(module, exports) {
    $parcel$export(module.exports, "useUserBoardId", ()=>$c7748d5d5fa92b7a$export$e6e895063f36c814);
    var $5uXOq = parcelRequire("5uXOq");
    var $eusud;
    var $6yXtV;
    const $c7748d5d5fa92b7a$export$e6e895063f36c814 = (shouldRun)=>{
        const [{ isReady: isReady }] = (0, (0, parcelRequire("6yXtV")).useSpaStatePageReady)();
        const [userBoard, setUserBoard] = (0, $5uXOq.useState)(null);
        const shouldFetch = shouldRun || isReady;
        (0, $5uXOq.useEffect)(()=>{
            if (!shouldFetch || userBoard) return;
            setUserBoard(null);
            const requestUrl = '/rest/greenhopper/1.0/rapidviews/recent?maxResults=1&userBoard=true';
            (0, (0, parcelRequire("eusud")).performGetRequest)(requestUrl).then((result)=>{
                const [board] = result.views;
                if (board) setUserBoard(board);
            }).catch(()=>{});
        }, [
            shouldFetch,
            userBoard,
            setUserBoard
        ]);
        return userBoard && userBoard.location ? [
            userBoard.id,
            userBoard.location.accountId
        ] : [
            null,
            null
        ];
    };
});
parcelRegister("k7xP2", function(module, exports) {
    $parcel$export(module.exports, "MenuNav4", ()=>$b62ef795f8dd8307$export$60113ca6a29c7a92);
    var $5uXOq = parcelRequire("5uXOq");
    var $4mNf3;
    var $9UCCQ;
    var $2vSU6;
    var $3bQtK;
    var $koVbs;
    var $6cNb0;
    var $dAfqg;
    var $5mLQj;
    var $jrUuh;
    var $4QigF;
    var $Mp6qH;
    var $d4B4Y;
    var $7ilb8;
    var $dgPkW;
    var $cEIWv;
    const $b62ef795f8dd8307$export$60113ca6a29c7a92 = ({ testIdPrefix: testIdPrefix, userBoardId: userBoardId, userBoardOwnerAccountId: userBoardOwnerAccountId })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const onSuccess = (0, (0, parcelRequire("3bQtK")).useExperienceSuccess)({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU)
        });
        (0, $5uXOq.useEffect)(()=>{
            onSuccess();
        }, [
            onSuccess
        ]);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'menu-popup');
        const testIdContent = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'content');
        const { data: projectContextData } = (0, (0, parcelRequire("dAfqg")).useProjectContext)();
        const [{ data: jsmOnboardingData }, { fetchOnboardingType: fetchOnboardingType }] = (0, (0, parcelRequire("jrUuh")).useJsmOnboardingType)();
        (0, $5uXOq.useEffect)(()=>{
            fetchOnboardingType(projectContextData?.projectId, projectContextData?.projectType);
        }, [
            fetchOnboardingType,
            projectContextData?.projectId,
            projectContextData?.projectType
        ]);
        const showInJwmProjects = projectContextData?.projectType === (0, (0, parcelRequire("2vSU6")).CORE_PROJECT);
        const showInSoftwareProjects = projectContextData?.projectType === (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT);
        const showInJsmProjects = projectContextData?.projectType === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT) && jsmOnboardingData?.onboardingType === (0, $e7830161eb8674f1$export$6f454e69bce3a691);
        let yourBoardsRoute;
        let yourBoardsParams;
        let yourBoardsQuery;
        if (userBoardId != null) {
            yourBoardsRoute = (0, (0, parcelRequire("5mLQj")).userBoardRoute);
            yourBoardsParams = {
                accountId: userBoardOwnerAccountId,
                boardId: String(userBoardId)
            };
        }
        const boardOffset = userBoardId == null ? -1 : 0;
        const showQuickstartOpenMenuItem = showInJwmProjects || showInSoftwareProjects || showInJsmProjects;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuList), null, showQuickstartOpenMenuItem && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4QigF")).OpenMenuItem), {
            MenuItem: (0, (0, parcelRequire("d4B4Y")).MenuItem)
        }), userBoardId !== null && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            hasSeparator: true,
            testId: testIdContent
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdContent, 'jira')
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), {
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdContent, 'jira', 'heading')
        }, formatMessage((0, (0, parcelRequire("cEIWv")).default).jira)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
            ariaLabel: formatMessage((0, (0, parcelRequire("cEIWv")).default).jira)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'profileNavigationMenuItem',
                menuItemId: 'your-boards',
                menuItemType: 'jira',
                position: 1
            },
            href: `/jira/people/${String(userBoardOwnerAccountId)}/boards/${userBoardId}`,
            params: yourBoardsParams,
            query: yourBoardsQuery,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdContent, 'jira', 'item-your-boards'),
            to: yourBoardsRoute
        }, formatMessage((0, (0, parcelRequire("cEIWv")).default).yourBoards))))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dgPkW")).ExtraItemsNav4), {
            startPosition: 5 + boardOffset,
            testIdPrefix: testIdContent,
            itemAriaLabel: formatMessage((0, (0, parcelRequire("cEIWv")).default).jira)
        }));
    };
});
const $e7830161eb8674f1$export$6f454e69bce3a691 = 'business';
const $e7830161eb8674f1$export$e6952b12ade67489 = 'default';
parcelRegister("jrUuh", function(module, exports) {
    $parcel$export(module.exports, "useJsmOnboardingType", ()=>$664311160f9d0bff$export$5daaa68c3a578af9);
    var $3sQ5x;
    var $iXqE5;
    var $gcnn5;
    const $664311160f9d0bff$export$d4c72bab9d6cc13a = {
        projectId: undefined,
        data: undefined,
        error: undefined,
        isLoading: false
    };
    const $664311160f9d0bff$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: $664311160f9d0bff$export$d4c72bab9d6cc13a,
        actions: (0, parcelRequire("gcnn5")).actions,
        name: 'JSMOnboardingType'
    });
    const $664311160f9d0bff$export$5daaa68c3a578af9 = (0, (0, parcelRequire("3sQ5x")).createHook)($664311160f9d0bff$var$Store);
});
parcelRegister("gcnn5", function(module, exports) {
    $parcel$export(module.exports, "actions", ()=>$efcda9e0ca78f7c5$export$e324594224ef24da);
    var $5R3PH;
    const $efcda9e0ca78f7c5$export$e324594224ef24da = {
        fetchOnboardingType: (0, parcelRequire("5R3PH")).fetchOnboardingType
    };
});
parcelRegister("5R3PH", function(module, exports) {
    $parcel$export(module.exports, "fetchOnboardingType", ()=>$a0d1630df8d288ca$export$ea62467635172b55);
    var $2vSU6;
    var $7f4tk;
    const $a0d1630df8d288ca$var$getOnboardingTypeUrl = (projectId)=>`/rest/servicedesk/onboarding/1/onboarding-type/project/${projectId}`;
    const $a0d1630df8d288ca$export$ea62467635172b55 = (projectId, projectType)=>async ({ getState: getState, setState: setState })=>{
            if (!projectId || projectType !== (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT)) return;
            const { data: data, isLoading: isLoading, projectId: oldProjectId } = getState();
            if (projectId !== oldProjectId) setState({
                data: undefined,
                projectId: undefined
            });
            if (data || isLoading) return;
            setState({
                projectId: projectId,
                isLoading: true,
                error: undefined
            });
            try {
                const onboardingTypeResponse = await (0, (0, parcelRequire("7f4tk")).default)($a0d1630df8d288ca$var$getOnboardingTypeUrl(String(projectId)));
                setState({
                    isLoading: false,
                    data: onboardingTypeResponse
                });
            } catch (error) {
                setState({
                    isLoading: false,
                    error: error
                });
            }
        };
});
parcelRegister("dgPkW", function(module, exports) {
    $parcel$export(module.exports, "ExtraItems", ()=>$78c0b34ec312aabb$export$56021421a935c353);
    $parcel$export(module.exports, "ExtraItemsNav4", ()=>$78c0b34ec312aabb$export$ee2972db91c46b97);
    var $5uXOq = parcelRequire("5uXOq");
    var $9XE6b = parcelRequire("9XE6b");
    var $9UCCQ;
    var $hrlU6;
    var $4R6GH;
    var $6cNb0;
    var $atU1t;
    var $2YP9W;
    var $d4B4Y;
    var $7ilb8;
    const $78c0b34ec312aabb$var$useConnectItems = ()=>{
        const { data: resourceData, loading: loading, error: error, refresh: refresh } = (0, (0, parcelRequire("2YP9W")).useResource)((0, (0, parcelRequire("atU1t")).navigationSidebarGlobalResource));
        const data = resourceData?.response;
        (0, $5uXOq.useEffect)(()=>{
            if (!data && !loading && !error) refresh();
        }, [
            data,
            error,
            loading,
            refresh
        ]);
        return data ? (0, ($parcel$interopDefault($9XE6b)))(data, 'global.profile.items', []).filter((item)=>![
                'view_profile',
                'log_out'
            ].includes(item.id)) : [];
    };
    const $78c0b34ec312aabb$var$Item = ({ url: url, name: name, id: id, styleClass: styleClass, itemAriaLabel: itemAriaLabel, position: position, testId: testId })=>{
        const webItemRef = (0, $5uXOq.useRef)(null);
        const isDialog = styleClass && (0, (0, parcelRequire("hrlU6")).isConnectDialogWebItem)(styleClass);
        const onClick = (0, $5uXOq.useCallback)(()=>{
            if (isDialog) (0, (0, parcelRequire("hrlU6")).withConnectHost)((connectHost)=>{
                if (styleClass && webItemRef.current) connectHost.triggerWebItem(webItemRef.current, styleClass, url);
            });
        }, [
            isDialog,
            styleClass,
            url
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
            key: url,
            ariaLabel: itemAriaLabel || ''
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            ref: webItemRef,
            analytics: {
                actionSubjectId: 'profileNavigationMenuItem',
                menuItemId: id,
                menuItemType: 'jira',
                position: position
            },
            href: isDialog ? undefined : url,
            onClick: isDialog ? onClick : undefined,
            testId: testId
        }, name));
    };
    const $78c0b34ec312aabb$export$56021421a935c353 = ({ startPosition: startPosition, testIdPrefix: testIdPrefix, itemAriaLabel: itemAriaLabel })=>{
        const connectItems = $78c0b34ec312aabb$var$useConnectItems();
        if (!connectItems.length) return null;
        if ((0, (0, parcelRequire("4R6GH")).fg)('blu-4941-connect-webitem-imperative-api')) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, connectItems.map(({ id: id, name: name, url: url, styleClass: styleClass }, idx)=>{
            return (0, ($parcel$interopDefault($5uXOq))).createElement($78c0b34ec312aabb$var$Item, {
                key: url,
                url: url,
                name: name,
                id: id,
                styleClass: styleClass,
                itemAriaLabel: itemAriaLabel,
                position: startPosition + idx,
                testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, `item-addon-${idx}`)
            });
        }));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, connectItems.map((item, idx)=>{
            const className = item.styleClass;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
                key: item.url,
                ariaLabel: itemAriaLabel || ''
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
                analytics: {
                    actionSubjectId: 'profileNavigationMenuItem',
                    menuItemId: item.id,
                    menuItemType: 'jira',
                    position: startPosition + idx
                },
                className: className,
                href: item.url,
                testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, `item-addon-${idx}`)
            }, item.name));
        }));
    };
    const $78c0b34ec312aabb$export$ee2972db91c46b97 = ({ startPosition: startPosition, testIdPrefix: testIdPrefix, itemAriaLabel: itemAriaLabel })=>{
        const connectItems = $78c0b34ec312aabb$var$useConnectItems();
        if (!connectItems.length) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            hasSeparator: true
        }, (0, (0, parcelRequire("4R6GH")).fg)('blu-4941-connect-webitem-imperative-api') ? connectItems.map(({ id: id, name: name, url: url, styleClass: styleClass }, idx)=>{
            return (0, ($parcel$interopDefault($5uXOq))).createElement($78c0b34ec312aabb$var$Item, {
                key: url,
                url: url,
                name: name,
                id: id,
                styleClass: styleClass,
                itemAriaLabel: itemAriaLabel,
                position: startPosition + idx,
                testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, `item-addon-${idx}`)
            });
        }) : connectItems.map((item, idx)=>{
            const className = item.styleClass;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
                key: item.url,
                ariaLabel: itemAriaLabel || ''
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
                analytics: {
                    actionSubjectId: 'profileNavigationMenuItem',
                    menuItemId: item.id,
                    menuItemType: 'jira',
                    position: startPosition + idx
                },
                className: className,
                href: item.url,
                testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, `item-addon-${idx}`)
            }, item.name));
        }));
    };
});
parcelRegister("cEIWv", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$521f3eedcdcca1a6$export$2e2bcd8739ae039);
    var $7VHMR;
    var $521f3eedcdcca1a6$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        giveFeedback: {
            "id": "atlassian-navigation.profile.menu.give-feedback",
            "defaultMessage": "Give us feedback"
        },
        yourBoards: {
            "id": "atlassian-navigation.profile.menu.your-boards",
            "defaultMessage": "Your boards"
        },
        new: {
            "id": "atlassian-navigation.profile.menu.new",
            "defaultMessage": "new"
        },
        personalSettings: {
            "id": "atlassian-navigation.profile.menu.personal-settings",
            "defaultMessage": "Personal settings"
        },
        logout: {
            "id": "atlassian-navigation.profile.menu.logout",
            "defaultMessage": "Log out"
        },
        jira: {
            "id": "atlassian-navigation.profile.menu.jira",
            "defaultMessage": "Jira"
        },
        account: {
            "id": "atlassian-navigation.profile.menu.account",
            "defaultMessage": "Account"
        },
        profile: {
            "id": "atlassian-navigation.profile.menu.profile",
            "defaultMessage": "Profile"
        },
        notifications: {
            "id": "atlassian-navigation.profile.menu.notifications",
            "defaultMessage": "Notifications"
        }
    });
});
parcelRegister("ebznH", function(module, exports) {
    $parcel$export(module.exports, "Menu", ()=>$32d3ea680525e18b$export$d9b273488cd8ce6f);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $jZ4fr;
    var $lj8SI;
    var $4mNf3;
    var $9UCCQ;
    var $5Fk4Z;
    var $ixNeq;
    var $2vSU6;
    var $3bQtK;
    var $koVbs;
    var $2EqxS;
    var $6cNb0;
    var $1C2yb;
    var $cYlk7;
    var $dAfqg;
    var $54b5G;
    var $cojyC;
    var $5mLQj;
    var $jrUuh;
    var $4QigF;
    var $90yGq;
    var $Mp6qH;
    var $d4B4Y;
    var $ag3sS;
    var $7ilb8;
    var $5DUZ0;
    var $dgPkW;
    var $cEIWv;
    var $fUv6Z;
    var $3CMYn;
    const $32d3ea680525e18b$export$d9b273488cd8ce6f = ({ testIdPrefix: testIdPrefix, userBoardId: userBoardId, userBoardOwnerAccountId: userBoardOwnerAccountId })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { data: { user: user } } = (0, (0, parcelRequire("cYlk7")).useCurrentUser)();
        const accountId = (0, (0, parcelRequire("90yGq")).useAccountId)();
        const profileLink = (0, (0, parcelRequire("2EqxS")).useProfileLink)();
        const onSuccess = (0, (0, parcelRequire("3bQtK")).useExperienceSuccess)({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU)
        });
        (0, $5uXOq.useEffect)(()=>{
            onSuccess();
        }, [
            onSuccess
        ]);
        const profileParams = {
            subPath: accountId
        };
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'menu-popup');
        const testIdContent = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'content');
        const testIdFooter = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'footer');
        const [upgradeEditionMenuShown, setUpgradeEditionMenuShown] = (0, $5uXOq.useState)(false);
        const { data: projectContextData } = (0, (0, parcelRequire("dAfqg")).useProjectContext)();
        const [{ data: jsmOnboardingData }, { fetchOnboardingType: fetchOnboardingType }] = (0, (0, parcelRequire("jrUuh")).useJsmOnboardingType)();
        (0, $5uXOq.useEffect)(()=>{
            fetchOnboardingType(projectContextData?.projectId, projectContextData?.projectType);
        }, [
            fetchOnboardingType,
            projectContextData?.projectId,
            projectContextData?.projectType
        ]);
        const showInJwmProjects = projectContextData?.projectType === (0, (0, parcelRequire("2vSU6")).CORE_PROJECT);
        const showInSoftwareProjects = projectContextData?.projectType === (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT);
        const showInJsmProjects = projectContextData?.projectType === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT) && jsmOnboardingData?.onboardingType === (0, $e7830161eb8674f1$export$6f454e69bce3a691);
        let yourBoardsRoute;
        let yourBoardsParams;
        let yourBoardsQuery;
        if (userBoardId != null) {
            yourBoardsRoute = (0, (0, parcelRequire("5mLQj")).userBoardRoute);
            yourBoardsParams = {
                accountId: userBoardOwnerAccountId,
                boardId: String(userBoardId)
            };
        }
        const boardOffset = userBoardId == null ? -1 : 0;
        const [userTraits] = (0, (0, parcelRequire("ixNeq")).useUserTraits)();
        const showNotificationsNewLozenge = (0, $5uXOq.useMemo)(()=>{
            const accountCreationDate = userTraits?.attributes?.find((trait)=>trait?.name === 'jira_first_active')?.value;
            const aWeekAgo = new Date(Date.now() - 604800000);
            let isNewUser = false;
            if (accountCreationDate && typeof accountCreationDate === 'string') isNewUser = new Date(accountCreationDate) > aWeekAgo;
            return !isNewUser;
        }, [
            userTraits
        ]);
        (0, $5uXOq.useEffect)(()=>{
            setTimeout(()=>{
                window.dispatchEvent(new Event('resize'));
            }, 0);
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId,
            role: "dialog",
            "aria-labelledby": "atlassian-navigation-account-dialog-heading",
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_syaz1fxt"
            ])
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ag3sS")).MenuLayout), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            "data-testid": (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdContent, 'account')
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), {
            id: "atlassian-navigation-account-dialog-heading",
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdContent, 'account', 'heading')
        }, formatMessage((0, (0, parcelRequire("cEIWv")).default).account)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5DUZ0")).AccountDetails), {
            email: user.emailAddress || '',
            userFullname: user.userFullname,
            avatarSrc: user.avatarUrls ? user.avatarUrls['32x32'] : undefined,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdContent, 'account')
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            hasSeparator: upgradeEditionMenuShown
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3CMYn")).UpgradeEditionMenuItem), {
            setUpgradeEditionMenuShown: setUpgradeEditionMenuShown
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            hasSeparator: upgradeEditionMenuShown || !upgradeEditionMenuShown,
            testId: testIdContent
        }, (showInJwmProjects || showInSoftwareProjects || showInJsmProjects) && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4QigF")).OpenMenuItem), {
            MenuItem: (0, (0, parcelRequire("d4B4Y")).MenuItem)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdContent, 'jira')
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), {
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdContent, 'jira', 'heading')
        }, formatMessage((0, (0, parcelRequire("cEIWv")).default).jira)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuList), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
            ariaLabel: formatMessage((0, (0, parcelRequire("cEIWv")).default).jira)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'profileNavigationMenuItem',
                menuItemId: 'profile',
                menuItemType: 'jira',
                position: 0
            },
            href: profileLink,
            params: profileParams,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdContent, 'jira', 'item-profile'),
            to: (0, (0, parcelRequire("54b5G")).peopleProfilesRoute)
        }, formatMessage((0, (0, parcelRequire("cEIWv")).default).profile))), userBoardId !== null && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
            ariaLabel: formatMessage((0, (0, parcelRequire("cEIWv")).default).jira)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'profileNavigationMenuItem',
                menuItemId: 'your-boards',
                menuItemType: 'jira',
                position: 1
            },
            href: `/jira/people/${String(userBoardOwnerAccountId)}/boards/${userBoardId}`,
            params: yourBoardsParams,
            query: yourBoardsQuery,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdContent, 'jira', 'item-your-boards'),
            to: yourBoardsRoute
        }, formatMessage((0, (0, parcelRequire("cEIWv")).default).yourBoards))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
            ariaLabel: formatMessage((0, (0, parcelRequire("cEIWv")).default).jira)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'profileNavigationMenuItem',
                menuItemId: 'personal-settings',
                menuItemType: 'jira',
                position: 2 + boardOffset
            },
            href: (0, (0, parcelRequire("1C2yb")).getGeneralPersonalSettingsPath)(),
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdContent, 'jira', 'item-personal-settings'),
            to: (0, (0, parcelRequire("cojyC")).getGeneralPersonalSettingsRouteForLink)()
        }, formatMessage((0, (0, parcelRequire("cEIWv")).default).personalSettings))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
            ariaLabel: formatMessage((0, (0, parcelRequire("cEIWv")).default).jira)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Fk4Z")).default), {
            name: "notifications-menu-spotlight"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'profileNavigationMenuItem',
                menuItemId: 'notifications',
                menuItemType: 'jira',
                position: 3 + boardOffset
            },
            href: (0, (0, parcelRequire("1C2yb")).getNotificationsPersonalSettingsPath)(),
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdContent, 'jira', 'item-notifications'),
            to: (0, (0, parcelRequire("cojyC")).getNotificationsPersonalSettingsRouteForLink)(),
            iconAfter: showNotificationsNewLozenge ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lj8SI")).default), {
                appearance: "new"
            }, formatMessage((0, (0, parcelRequire("cEIWv")).default).new)) : null
        }, formatMessage((0, (0, parcelRequire("cEIWv")).default).notifications)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jZ4fr")).EngagementSpotlight), {
            engagementId: "notifications-menu-spotlight"
        }))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
            ariaLabel: formatMessage((0, (0, parcelRequire("cEIWv")).default).jira)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fUv6Z")).default), {
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdContent, 'jira', 'item-theme'),
            itemPosition: 4 + boardOffset
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dgPkW")).ExtraItems), {
            startPosition: 5 + boardOffset,
            testIdPrefix: testIdContent,
            itemAriaLabel: formatMessage((0, (0, parcelRequire("cEIWv")).default).jira)
        })))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            hasSeparator: true,
            testId: testIdFooter
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdFooter, 'items')
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'profileNavigationMenuItem',
                menuItemId: 'logout',
                menuItemType: 'action',
                position: 0
            },
            href: "/logout",
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdFooter, 'items', 'item-log-out')
        }, formatMessage((0, (0, parcelRequire("cEIWv")).default).logout))))));
    };
    const $32d3ea680525e18b$var$styles = null;
});
parcelRegister("1C2yb", function(module, exports) {
    $parcel$export(module.exports, "LEGACY_PERSONAL_SETTINGS_PATH", ()=>$63706130a90445cd$export$b468ccdb67523656);
    $parcel$export(module.exports, "GENERAL_PERSONAL_SETTINGS_PATH", ()=>$63706130a90445cd$export$693b70d1bcef8627);
    $parcel$export(module.exports, "NOTIFICATIONS_PERSONAL_SETTINGS_PATH", ()=>$63706130a90445cd$export$21946ea4c58fd4c9);
    $parcel$export(module.exports, "OPS_NOTIFICATIONS_PERSONAL_SETTINGS_PATH", ()=>$63706130a90445cd$export$2321c59d517801c8);
    $parcel$export(module.exports, "getGeneralPersonalSettingsPath", ()=>$63706130a90445cd$export$db9e3ccc1e5e3317);
    $parcel$export(module.exports, "getNotificationsPersonalSettingsPath", ()=>$63706130a90445cd$export$64a672523a583594);
    const $63706130a90445cd$export$b468ccdb67523656 = '/secure/ViewPersonalSettings.jspa';
    const $63706130a90445cd$export$a974f4af282a75ba = '/jira/settings/personal';
    const $63706130a90445cd$export$693b70d1bcef8627 = `${$63706130a90445cd$export$a974f4af282a75ba}/general`;
    const $63706130a90445cd$export$21946ea4c58fd4c9 = `${$63706130a90445cd$export$a974f4af282a75ba}/notifications`;
    const $63706130a90445cd$export$2321c59d517801c8 = `${$63706130a90445cd$export$a974f4af282a75ba}/alert-notifications`;
    function $63706130a90445cd$export$db9e3ccc1e5e3317() {
        return $63706130a90445cd$export$693b70d1bcef8627;
    }
    function $63706130a90445cd$export$88a45ae8c4b1c664() {
        return $63706130a90445cd$export$2321c59d517801c8;
    }
    function $63706130a90445cd$export$64a672523a583594() {
        return $63706130a90445cd$export$21946ea4c58fd4c9;
    }
});
parcelRegister("54b5G", function(module, exports) {
    $parcel$export(module.exports, "peopleProfilesRoute", ()=>$9c4266ad8ee353d0$export$1338132568be849);
    var $65Ltd;
    const $9c4266ad8ee353d0$export$1338132568be849 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_PEOPLE_INDEX),
        path: '/jira/people/:subPath?'
    };
});
parcelRegister("cojyC", function(module, exports) {
    $parcel$export(module.exports, "legacyPersonalSettingsRoute", ()=>$e797a93ba9e40aac$export$dd556eb586bb36b8);
    $parcel$export(module.exports, "generalPersonalSettingsRoute", ()=>$e797a93ba9e40aac$export$7a6c9715a86f906b);
    $parcel$export(module.exports, "notificationsPersonalSettingsRoute", ()=>$e797a93ba9e40aac$export$55ab09b9d6beb8be);
    $parcel$export(module.exports, "opsNotificationsPersonalSettingsRoute", ()=>$e797a93ba9e40aac$export$68ebdd4eccb7b383);
    $parcel$export(module.exports, "getGeneralPersonalSettingsRouteForLink", ()=>$e797a93ba9e40aac$export$cbf079e07fb96768);
    $parcel$export(module.exports, "getNotificationsPersonalSettingsRouteForLink", ()=>$e797a93ba9e40aac$export$9b4c78a3c84b549f);
    var $65Ltd;
    var $1C2yb;
    const $e797a93ba9e40aac$export$dd556eb586bb36b8 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_PERSONAL_SETTINGS),
        path: (0, (0, parcelRequire("1C2yb")).LEGACY_PERSONAL_SETTINGS_PATH)
    };
    const $e797a93ba9e40aac$export$7a6c9715a86f906b = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_PERSONAL_SETTINGS_GENERAL),
        path: (0, (0, parcelRequire("1C2yb")).GENERAL_PERSONAL_SETTINGS_PATH)
    };
    const $e797a93ba9e40aac$export$55ab09b9d6beb8be = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_PERSONAL_SETTINGS_NOTIFICATIONS),
        path: (0, (0, parcelRequire("1C2yb")).NOTIFICATIONS_PERSONAL_SETTINGS_PATH)
    };
    const $e797a93ba9e40aac$export$68ebdd4eccb7b383 = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_PERSONAL_SETTINGS_OPS_NOTIFICATIONS),
        path: (0, (0, parcelRequire("1C2yb")).OPS_NOTIFICATIONS_PERSONAL_SETTINGS_PATH)
    };
    const $e797a93ba9e40aac$export$cbf079e07fb96768 = ()=>{
        return $e797a93ba9e40aac$export$7a6c9715a86f906b;
    };
    const $e797a93ba9e40aac$export$9b4c78a3c84b549f = ()=>{
        return $e797a93ba9e40aac$export$55ab09b9d6beb8be;
    };
});
parcelRegister("5DUZ0", function(module, exports) {
    $parcel$export(module.exports, "AccountDetails", ()=>$a1d7ada73782364f$export$66e04d0aacdb3b2);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $ujWmd;
    var $17Ngn;
    var $3Z96c;
    var $koVbs;
    var $2EqxS;
    var $6cNb0;
    var $d4B4Y;
    var $7ilb8;
    var $8ZSbf;
    const $a1d7ada73782364f$export$66e04d0aacdb3b2 = ({ email: email, userFullname: userFullname, avatarSrc: avatarSrc, testId: testId })=>{
        const atlassianSettingsLink = (0, (0, parcelRequire("2EqxS")).useAtlassianPersonalSettingsLink)(email);
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement($a1d7ada73782364f$export$8f1912fff52a55aa, null, (0, ($parcel$interopDefault($5uXOq))).createElement($a1d7ada73782364f$export$33e9904f45a9e453, null, (0, ($parcel$interopDefault($5uXOq))).createElement($a1d7ada73782364f$export$f7513421c53ce141, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ujWmd")).default), {
            appearance: "circle",
            size: "medium",
            src: avatarSrc
        })), (0, ($parcel$interopDefault($5uXOq))).createElement($a1d7ada73782364f$export$e0d971bef63c8b8f, null, (0, ($parcel$interopDefault($5uXOq))).createElement($a1d7ada73782364f$export$101fbca3a406e1fd, null, userFullname), (0, ($parcel$interopDefault($5uXOq))).createElement("small", null, email)))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuList), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'profileNavigationMenuItem',
                menuItemId: 'manage-account',
                menuItemType: 'account',
                position: 1
            },
            href: atlassianSettingsLink,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'manage-account'),
            target: "_blank",
            iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("17Ngn"))))), {
                size: "small",
                primaryColor: `var(--ds-icon-subtle, ${(0, $829f609a65d26a98$exports).N100})`,
                label: ""
            })
        }, formatMessage((0, (0, parcelRequire("8ZSbf")).default).manageAccount)))));
    };
    const $a1d7ada73782364f$export$8f1912fff52a55aa = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("3Z96c")).default), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_lcxvglyw",
                __cmplp.className
            ])
        });
    });
    const $a1d7ada73782364f$export$33e9904f45a9e453 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _2lx2vrvc",
                __cmplp.className
            ])
        });
    });
    const $a1d7ada73782364f$export$f7513421c53ce141 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1bah1h6o _4cvr1h6o _2hwxu2gc",
                __cmplp.className
            ])
        });
    });
    const $a1d7ada73782364f$export$101fbca3a406e1fd = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_vwz4gktf",
                __cmplp.className
            ])
        });
    });
    const $a1d7ada73782364f$export$e0d971bef63c8b8f = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_2lx29ee3",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("8ZSbf", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$818e6cdec46716cd$export$2e2bcd8739ae039);
    var $7VHMR;
    var $818e6cdec46716cd$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        manageAccount: {
            "id": "atlassian-navigation.profile.menu.account-details.manage-account",
            "defaultMessage": "Manage account"
        }
    });
});
parcelRegister("fUv6Z", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f86718ba415abca4$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $lfTZh;
    var $kxZPA;
    var $e2wv7;
    const $f86718ba415abca4$export$31f1740ae531af94 = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("etU94")), {
        moduleId: "./src/packages/navigation-apps/theme-switcher-menu/src/index.tsx"
    });
    const $f86718ba415abca4$var$AsyncThemeSwitcherItem = ({ testId: testId, itemPosition: itemPosition })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "nav-theme-options-list-menu",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("e2wv7")).default), {
                testId: testId,
                itemPosition: itemPosition,
                isDisabled: true
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($f86718ba415abca4$export$31f1740ae531af94, {
            renderTriggerButton: (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("e2wv7")).default), {
                    testId: testId,
                    itemPosition: itemPosition,
                    ...props
                })
        }));
    var $f86718ba415abca4$export$2e2bcd8739ae039 = $f86718ba415abca4$var$AsyncThemeSwitcherItem;
});
parcelRegister("e2wv7", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$0fbe3dba557d8dae$export$2e2bcd8739ae039);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $ebd3R;
    var $kJpBP;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $1lFrC;
    const $0fbe3dba557d8dae$var$TriggerButton = (0, $5uXOq.forwardRef)(({ testId: testId, itemPosition: itemPosition, ...rest }, ref)=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kJpBP")).default), {
            ...rest,
            ref: ref,
            onClick: (e)=>{
                e.stopPropagation();
                const analyticsEvent = createAnalyticsEvent({
                    action: 'clicked',
                    actionSubject: 'navigationMenuItem'
                });
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'profileNavigationMenuItem', {
                    menuItemId: 'theme',
                    menuItemType: 'jira',
                    position: itemPosition
                });
            },
            testId: testId,
            iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("ebd3R"))))), {
                primaryColor: `var(--ds-icon-subtle, ${(0, $829f609a65d26a98$exports).N100})`,
                label: ""
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($0fbe3dba557d8dae$var$Text, null, formatMessage((0, (0, parcelRequire("1lFrC")).default).text)));
    });
    var $0fbe3dba557d8dae$export$2e2bcd8739ae039 = $0fbe3dba557d8dae$var$TriggerButton;
    const $0fbe3dba557d8dae$var$Text = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_2hwxpxbi",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("1lFrC", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$fe8ea74039b76a2c$export$2e2bcd8739ae039);
    var $7VHMR;
    var $fe8ea74039b76a2c$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        text: {
            "id": "atlassian-navigation.profile.menu.theme-switcher-item.trigger-button.text",
            "defaultMessage": "Theme"
        }
    });
});
parcelRegister("etU94", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("isB7h")
    ]).then(()=>parcelRequire('dFrEV'));
});
parcelRegister("3CMYn", function(module, exports) {
    $parcel$export(module.exports, "UpgradeEditionMenuItem", ()=>$f3e948dade64bc0d$export$30403e009ec5f9cd);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $17Ngn;
    var $lj8SI;
    var $4mNf3;
    var $iMd5l;
    var $cVzoY;
    var $39xOx;
    var $aWse0;
    var $lpwtK;
    var $8zOmE;
    var $5oFIy;
    var $2NMNM;
    var $ldyPw;
    var $9OXo1;
    var $eZxaN;
    var $cMquZ;
    var $d4B4Y;
    var $7ilb8;
    var $5s42E;
    const $f3e948dade64bc0d$export$4a94253c58c6e2cb = ({ product: product })=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const tenantContext = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const productEdition = product ? (0, (0, parcelRequire("iMd5l")).getProductEdition)(product, tenantContext) : (0, (0, parcelRequire("ldyPw")).FREE_EDITION);
        const trialLength = (0, (0, parcelRequire("eZxaN")).getTrialLength)(productEdition, (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION));
        const freeEditionMenuItemInfo = (0, (0, parcelRequire("5s42E")).getFreeEditionMenuItem)((0, (0, parcelRequire("aWse0")).useIntlV2), trialLength);
        const [, { mountUpflowModal: mountUpflowModal }] = (0, (0, parcelRequire("cMquZ")).useUpflowModal)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireUiAnalytics), {
            eventName: (0, $8f22f2c66c400ef9$export$23de212b4b92e749),
            attributes: {
                currentEdition: productEdition,
                growthFeature: (0, $8f22f2c66c400ef9$export$2c864ccb7debe0d4),
                touchpointId: freeEditionMenuItemInfo.touchpointId,
                flowId: freeEditionMenuItemInfo.flowId,
                computedProduct: product
            }
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), null, freeEditionMenuItemInfo.heading), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuList), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'persistentUpgradeNavigationMenuItem',
                menuItemId: freeEditionMenuItemInfo.touchpointId,
                menuItemType: 'action',
                position: 0
            },
            onClick: ()=>{
                const analyticEvent = createAnalyticsEvent({});
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticEvent, (0, $8f22f2c66c400ef9$export$2b421d13e998fdbb), {
                    currentEdition: productEdition,
                    growthFeature: (0, $8f22f2c66c400ef9$export$2c864ccb7debe0d4),
                    touchpointId: freeEditionMenuItemInfo.touchpointId,
                    flowId: freeEditionMenuItemInfo.flowId,
                    computedProduct: product
                });
                if (product && freeEditionMenuItemInfo.displayUpflowModal === true) mountUpflowModal({
                    targetEdition: (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION),
                    product: product,
                    flow: (0, $8f22f2c66c400ef9$export$195e756c24f2ef95),
                    touchpointId: freeEditionMenuItemInfo.touchpointId
                });
            },
            href: (0, (0, parcelRequire("5s42E")).getHref)(freeEditionMenuItemInfo, product),
            target: product ? undefined : '_blank',
            iconAfter: product ? undefined : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("17Ngn"))))), {
                size: "small",
                primaryColor: `var(--ds-icon-subtle, ${(0, $829f609a65d26a98$exports).N100})`,
                label: ""
            })
        }, freeEditionMenuItemInfo.content, (0, ($parcel$interopDefault($5uXOq))).createElement($f3e948dade64bc0d$var$LozengeContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lj8SI")).default), {
            appearance: freeEditionMenuItemInfo.lozengeAppearance,
            isBold: true
        }, freeEditionMenuItemInfo.tagContent, ' '))))));
    };
    const $f3e948dade64bc0d$export$30403e009ec5f9cd = ({ setUpgradeEditionMenuShown: setUpgradeEditionMenuShown })=>{
        const tenantContext = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const { data: projectData, loading: projectDataLoading } = (0, (0, parcelRequire("lpwtK")).useProject)();
        if (projectDataLoading) return null;
        const { isSiteAdmin: isSiteAdmin } = tenantContext;
        const explicitlyLicensedProducts = (0, (0, parcelRequire("cVzoY")).default)(tenantContext);
        const applicationKey = (0, (0, parcelRequire("5s42E")).getActivePageApplicationKey)(projectData?.type, explicitlyLicensedProducts);
        if (!(0, (0, parcelRequire("5s42E")).shouldRenderMenuItem)(isSiteAdmin, applicationKey, explicitlyLicensedProducts, tenantContext)) {
            setUpgradeEditionMenuShown(false);
            return null;
        }
        setUpgradeEditionMenuShown(true);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: (0, $8f22f2c66c400ef9$export$5fa54bc771a7eb8f),
            packageName: (0, $8f22f2c66c400ef9$export$b8b9d90f9e5bd72b),
            fallback: "unmount"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($f3e948dade64bc0d$export$4a94253c58c6e2cb, {
            product: applicationKey
        }));
    };
    const $f3e948dade64bc0d$var$LozengeContainer = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_18u0u2gc",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("iMd5l", function(module, exports) {
    $parcel$export(module.exports, "getProductEdition", ()=>$658df12da4e62335$export$53e066a63a960439);
    const $658df12da4e62335$export$53e066a63a960439 = (product, tenantContext)=>{
        if (product === (0, $c06e1abca7c13910$export$1346c9aa3cbb477d)) return tenantContext.appEditions.software;
        if (product === (0, $c06e1abca7c13910$export$c8c1c8c3f8ddba61)) return tenantContext.appEditions.serviceDesk;
        return tenantContext.appEditions.core;
    };
});
parcelRegister("eZxaN", function(module, exports) {
    $parcel$export(module.exports, "getTrialLength", ()=>$5e237061bb3a578d$export$23d271f9e8cb9c4);
    var $ldyPw;
    const $5e237061bb3a578d$export$23d271f9e8cb9c4 = (currentEdition, targetEdition)=>{
        if (currentEdition === (0, (0, parcelRequire("ldyPw")).FREE_EDITION) && targetEdition === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION)) return 0, $554094dc1f31a42a$export$b7a3952d12e24333;
        return 0, $554094dc1f31a42a$export$940ef4a039f45970;
    };
});
const $554094dc1f31a42a$export$b7a3952d12e24333 = 14;
const $554094dc1f31a42a$export$940ef4a039f45970 = 30;
parcelRegister("cMquZ", function(module, exports) {
    $parcel$export(module.exports, "useUpflowModal", ()=>$85614d266b32fe16$export$7025cc2729b71e44);
    var $ldyPw;
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    var $51nlc;
    const $85614d266b32fe16$var$initialState = {
        renderUpflowModal: false,
        product: 'jira-software',
        touchpointId: '',
        flow: 'learn-more',
        onClose: ()=>{},
        targetEdition: (0, (0, parcelRequire("ldyPw")).FREE_EDITION)
    };
    const $85614d266b32fe16$var$actions = {
        mountUpflowModal: ({ targetEdition: targetEdition, product: product, flow: flow, touchpointId: touchpointId, subRoute: subRoute, onClose: onClose, onOpen: onOpen })=>({ setState: setState })=>{
                setState({
                    targetEdition: targetEdition,
                    product: product,
                    flow: flow,
                    touchpointId: touchpointId,
                    subRoute: subRoute,
                    onClose: onClose,
                    onOpen: onOpen,
                    renderUpflowModal: true
                });
            },
        unMountUpflowModal: ()=>({ setState: setState })=>{
                setState({
                    renderUpflowModal: false
                });
            }
    };
    const $85614d266b32fe16$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: (0, $3a066fc4381e3c91$export$675a4427bf3750a2),
        initialState: $85614d266b32fe16$var$initialState,
        actions: $85614d266b32fe16$var$actions
    });
    const $85614d266b32fe16$export$6d73384be122c2dd = (0, (0, parcelRequire("51nlc")).createSubscriber)($85614d266b32fe16$var$Store);
    const $85614d266b32fe16$export$e672be732bd57171 = (0, (0, parcelRequire("gg7kZ")).createContainer)($85614d266b32fe16$var$Store);
    const $85614d266b32fe16$export$7025cc2729b71e44 = (0, (0, parcelRequire("3sQ5x")).createHook)($85614d266b32fe16$var$Store);
});
const $3a066fc4381e3c91$export$675a4427bf3750a2 = 'upflow-modal-store';
const $8f22f2c66c400ef9$export$ad9850d60736acbb = 'jira-software';
const $8f22f2c66c400ef9$export$195e756c24f2ef95 = 'persistent-upgrade';
const $8f22f2c66c400ef9$export$2c864ccb7debe0d4 = 'persistentUpgrade';
const $8f22f2c66c400ef9$export$b8b9d90f9e5bd72b = 'persistentUpgrade';
const $8f22f2c66c400ef9$export$5fa54bc771a7eb8f = 'persistentUpgradeMenu';
const $8f22f2c66c400ef9$export$2b421d13e998fdbb = 'persistentUpgradeMenu clicked';
const $8f22f2c66c400ef9$export$23de212b4b92e749 = 'persistentUpgradeMenu viewed';
parcelRegister("5s42E", function(module, exports) {
    $parcel$export(module.exports, "shouldRenderMenuItem", ()=>$19aa5f523a249503$export$6335c904eace2f3);
    $parcel$export(module.exports, "getFreeEditionMenuItem", ()=>$19aa5f523a249503$export$cce6104d08ee5a15);
    $parcel$export(module.exports, "getActivePageApplicationKey", ()=>$19aa5f523a249503$export$44df08af6d5522d3);
    $parcel$export(module.exports, "getHref", ()=>$19aa5f523a249503$export$81842c1ae19d378b);
    var $2vSU6;
    var $iMd5l;
    var $ldyPw;
    var $if9zJ;
    const $19aa5f523a249503$var$isFreeEdition = (product, tenantContext)=>{
        const productEdition = (0, (0, parcelRequire("iMd5l")).getProductEdition)(product, tenantContext);
        return productEdition === (0, (0, parcelRequire("ldyPw")).FREE_EDITION);
    };
    const $19aa5f523a249503$var$productIsJSMOrJSW = (product)=>product === (0, $c06e1abca7c13910$export$1346c9aa3cbb477d) || product === (0, $c06e1abca7c13910$export$c8c1c8c3f8ddba61);
    const $19aa5f523a249503$var$hasFreeJSMOrJSW = (explicitlyLicensedProducts, tenantContext)=>explicitlyLicensedProducts.some((product)=>$19aa5f523a249503$var$productIsJSMOrJSW(product) && $19aa5f523a249503$var$isFreeEdition(product, tenantContext));
    const $19aa5f523a249503$export$6335c904eace2f3 = (isSiteAdmin, product, explicitlyLicensedProducts, tenantContext)=>{
        if (!isSiteAdmin) return false;
        if (!product) return $19aa5f523a249503$var$hasFreeJSMOrJSW(explicitlyLicensedProducts, tenantContext);
        if (product && $19aa5f523a249503$var$productIsJSMOrJSW(product)) return $19aa5f523a249503$var$isFreeEdition(product, tenantContext);
        return false;
    };
    const $19aa5f523a249503$export$cce6104d08ee5a15 = (intl, trialLength)=>({
            heading: intl().formatMessage((0, (0, parcelRequire("if9zJ")).default).upgrade),
            content: intl().formatMessage((0, (0, parcelRequire("if9zJ")).default).tryStandardPlan),
            tagContent: intl().formatMessage((0, (0, parcelRequire("if9zJ")).default).trialPeriod, {
                trialLength: trialLength
            }),
            lozengeAppearance: 'inprogress',
            touchpointId: 'preTrialMenu',
            flowId: 'upgradeFlow',
            displayUpflowModal: true
        });
    const $19aa5f523a249503$export$636823a5af1468e4 = (subProduct, explicitlyLicensedProducts)=>{
        if (subProduct === 'software') return 0, $c06e1abca7c13910$export$1346c9aa3cbb477d;
        if (subProduct === 'serviceDesk') return 0, $c06e1abca7c13910$export$c8c1c8c3f8ddba61;
        if (subProduct === 'core') return 0, $c06e1abca7c13910$export$db515c8cec1e6f79;
        if (explicitlyLicensedProducts.length === 1) return explicitlyLicensedProducts[0];
        return null;
    };
    const $19aa5f523a249503$export$b6487772e5a4e151 = (projectType)=>{
        switch(projectType){
            case 0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT:
                return 0, $c06e1abca7c13910$export$1346c9aa3cbb477d;
            case 0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT:
                return 0, $c06e1abca7c13910$export$c8c1c8c3f8ddba61;
            case 0, (0, parcelRequire("2vSU6")).CORE_PROJECT:
                return 0, $c06e1abca7c13910$export$db515c8cec1e6f79;
            case 0, (0, parcelRequire("2vSU6")).PRODUCT_DISCOVERY_PROJECT:
                return 0, $c06e1abca7c13910$export$202f50ee62667e32;
            default:
                return null;
        }
    };
    const $19aa5f523a249503$export$44df08af6d5522d3 = (projectType, explicitlyLicensedProducts)=>{
        const applicationKey = $19aa5f523a249503$export$b6487772e5a4e151(projectType);
        if (applicationKey !== null) return applicationKey;
        if (explicitlyLicensedProducts.length === 1) return explicitlyLicensedProducts[0];
        return null;
    };
    const $19aa5f523a249503$export$81842c1ae19d378b = (freeEditionMenuItemInfo, product)=>{
        if (!product) return '/admin/billing/applications';
        return freeEditionMenuItemInfo.href ? freeEditionMenuItemInfo.href(freeEditionMenuItemInfo.touchpointId) : '';
    };
});
parcelRegister("if9zJ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$4771b447ad228e16$export$2e2bcd8739ae039);
    var $7VHMR;
    var $4771b447ad228e16$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        upgrade: {
            "id": "atlassian-navigation.profile.menu.upgrade-edition.upgrade",
            "defaultMessage": "upgrade"
        },
        tryStandardPlan: {
            "id": "atlassian-navigation.profile.menu.upgrade-edition.try-standard-plan",
            "defaultMessage": "Try the Standard plan"
        },
        trialPeriod: {
            "id": "atlassian-navigation.profile.menu.upgrade-edition.trial-period",
            "defaultMessage": "free {trialLength}-day trial"
        }
    });
});
parcelRegister("ixpv2", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2baac40d089a40ee$export$2e2bcd8739ae039);
    var $7VHMR;
    var $2baac40d089a40ee$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        tooltip: {
            "id": "atlassian-navigation.profile.tooltip",
            "defaultMessage": "Your profile and settings"
        }
    });
});
parcelRegister("a5zoF", function(module, exports) {
    $parcel$export(module.exports, "Projects", ()=>$a73319cb81e190a5$export$ca6fbb51b35e375c);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $cmzJ2;
    var $kklBj;
    var $13iNl;
    var $kaHBi;
    var $bcn6L;
    var $koVbs;
    var $d40Fj;
    var $lmyls;
    var $8zOmE;
    var $2NMNM;
    var $apeap;
    var $hchBL;
    var $90yGq;
    var $k9JTq;
    var $cZpsT;
    var $DVljb;
    var $9OXo1;
    var $Mp6qH;
    var $4uXxK;
    var $fVbkk;
    var $ebYJ0;
    var $f7TWB;
    var $b21tm;
    const $a73319cb81e190a5$var$ProjectsAnonymous = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const baseUrl = '';
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVbkk")).MenuTrigger), {
            href: `${baseUrl}/jira/projects`,
            menuId: (0, (0, parcelRequire("lmyls")).MENU_ID).PROJECTS,
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).PROJECTS
        }, formatMessage((0, (0, parcelRequire("b21tm")).default).projects));
    };
    const $a73319cb81e190a5$var$ProjectsLoggedIn = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const locale = (0, (0, parcelRequire("DVljb")).useLocale)();
        const accountId = (0, (0, parcelRequire("90yGq")).useAccountId)();
        const { cloudId: cloudId, licensedProducts: licensedProducts, firstActivationDateMs: firstActivationDateMs, areProductSuggestionsEnabled: areProductSuggestionsEnabled } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const [, { register: register }] = (0, (0, parcelRequire("hchBL")).useGlobalRefStoreActions)();
        const [{ hasFetchedOnce: hasFetchedOnceFavorites }, { loadFavoriteProjects: loadFavorite }] = (0, (0, parcelRequire("bcn6L")).useFavoriteProjects)();
        const [, { loadRecentProjects: loadRecent }] = (0, (0, parcelRequire("apeap")).useRecentProjects)();
        const [, { loadRecommendations: loadRecommendations }] = (0, (0, parcelRequire("cmzJ2")).useProjectMenuRecommendations)();
        const { software: edition } = (0, (0, parcelRequire("k9JTq")).useAppEditions)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const environment = (0, $8Uumt.useRelayEnvironment)();
        const [{ hasSuccessOnce: hasFetchedCreateProjectPermissions, canCreateProject: canCreateProject }] = (0, (0, parcelRequire("d40Fj")).useCreateProjectPermission)();
        const onStart = (0, (0, parcelRequire("kaHBi")).useExperienceStart)({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU),
            experienceId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).PROJECTS,
            analyticsSource: 'atlassian-navigation'
        });
        const onBeforeMenuOpen = (0, $5uXOq.useCallback)(()=>{
            onStart();
            (0, (0, parcelRequire("kklBj")).projectsMenuDropdownRecommendationSelector).start();
            loadRecent((0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_RECENT));
            if (accountId) {
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'dropdown viewed', 'projectsMenuDropdown');
                if (hasFetchedCreateProjectPermissions) loadRecommendations((0, (0, parcelRequire("13iNl")).recommendations), {
                    locale: locale,
                    cloudId: cloudId,
                    accountId: accountId,
                    licensedProducts: licensedProducts,
                    edition: edition,
                    firstActivationDateMs: firstActivationDateMs,
                    areProductSuggestionsEnabled: areProductSuggestionsEnabled,
                    environment: environment,
                    canCreateProject: canCreateProject
                }, createAnalyticsEvent({}));
            }
            if (!hasFetchedOnceFavorites) loadFavorite((0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV));
        }, [
            onStart,
            loadRecent,
            accountId,
            hasFetchedOnceFavorites,
            loadFavorite,
            createAnalyticsEvent,
            hasFetchedCreateProjectPermissions,
            loadRecommendations,
            locale,
            cloudId,
            licensedProducts,
            edition,
            firstActivationDateMs,
            areProductSuggestionsEnabled,
            environment,
            canCreateProject
        ]);
        const onRef = (0, $5uXOq.useCallback)((ref)=>{
            const refToFirstChild = ref && ref.firstChild;
            const refToFirstGrandchild = refToFirstChild && refToFirstChild.firstChild;
            const refToFirstGreatGrandchild = refToFirstGrandchild && refToFirstGrandchild.firstChild;
            register(`NAVIGATION_${(0, (0, parcelRequire("lmyls")).MENU_ID).PROJECTS}`, refToFirstGreatGrandchild || refToFirstGrandchild || refToFirstChild || ref);
        }, [
            register
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($a73319cb81e190a5$export$e6dfd72ab29f8598, {
            ref: onRef
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ebYJ0")).Popup), {
            menuId: (0, (0, parcelRequire("lmyls")).MENU_ID).PROJECTS,
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).PROJECTS,
            Menu: (0, (0, parcelRequire("f7TWB")).Menu),
            onBeforeMenuOpen: onBeforeMenuOpen,
            label: formatMessage((0, (0, parcelRequire("b21tm")).default).projects)
        }, formatMessage((0, (0, parcelRequire("b21tm")).default).projects)));
    };
    const $a73319cb81e190a5$var$ProjectWithAuth = ()=>{
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        return isAnonymous ? (0, ($parcel$interopDefault($5uXOq))).createElement($a73319cb81e190a5$var$ProjectsAnonymous, null) : (0, ($parcel$interopDefault($5uXOq))).createElement($a73319cb81e190a5$var$ProjectsLoggedIn, null);
    };
    const $a73319cb81e190a5$export$ca6fbb51b35e375c = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).PROJECTS
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($a73319cb81e190a5$var$ProjectWithAuth, null));
    const $a73319cb81e190a5$export$e6dfd72ab29f8598 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_syaz1fxt _4t3i1osq _ae4v1h6o _11l41osq",
                false && "_1kibidpf _1laeidpf _1f5yidpf _kq8jidpf",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("hchBL", function(module, exports) {
    $parcel$export(module.exports, "useGlobalRef", ()=>$b03364e48d8512c5$export$361e4f658203e95);
    $parcel$export(module.exports, "useGlobalRefStoreActions", ()=>$b03364e48d8512c5$export$3c6c6911d09d7d8a);
    $parcel$export(module.exports, "withGlobalRef", ()=>$b03364e48d8512c5$export$6b4b878b04fd3017);
    var $5uXOq = parcelRequire("5uXOq");
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    const $b03364e48d8512c5$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: {},
        actions: {
            register: (key, ref)=>({ setState: setState })=>{
                    setState({
                        [key]: ref || undefined
                    });
                }
        },
        name: 'refs-store'
    });
    const $b03364e48d8512c5$export$4beaa3baa53198e4 = (0, (0, parcelRequire("gg7kZ")).createContainer)($b03364e48d8512c5$var$store);
    const $b03364e48d8512c5$var$useGlobalRefStateSelector = (0, (0, parcelRequire("3sQ5x")).createStateHook)($b03364e48d8512c5$var$store, {
        selector: (state, key)=>state[key]
    });
    const $b03364e48d8512c5$export$361e4f658203e95 = (key)=>[
            $b03364e48d8512c5$var$useGlobalRefStateSelector(key)
        ];
    const $b03364e48d8512c5$export$3c6c6911d09d7d8a = (0, (0, parcelRequire("3sQ5x")).createHook)($b03364e48d8512c5$var$store, {
        selector: null
    });
    const $b03364e48d8512c5$export$6b4b878b04fd3017 = (propName, refName)=>(Component)=>(props)=>{
                const [ref] = $b03364e48d8512c5$export$361e4f658203e95(refName);
                return (0, ($parcel$interopDefault($5uXOq))).createElement(Component, {
                    ...props,
                    [propName]: ref
                });
            };
});
parcelRegister("f7TWB", function(module, exports) {
    $parcel$export(module.exports, "Menu", ()=>$14a03ca5c167a73f$export$d9b273488cd8ce6f);
    var $5uXOq = parcelRequire("5uXOq");
    var $9UCCQ;
    var $5Fk4Z;
    var $13iNl;
    var $bEK4U;
    var $9oIax;
    var $39xOx;
    var $93jKl;
    var $bcn6L;
    var $d40Fj;
    var $lmyls;
    var $6cNb0;
    var $lfTZh;
    var $Mp6qH;
    var $8vzcp;
    var $ag3sS;
    var $9TMfX;
    var $6WLja;
    var $2KGAL;
    var $1xLtH;
    var $4nCpW;
    var $4ci63;
    var $i4bJa;
    const $14a03ca5c167a73f$var$EVENT_DATA = {
        id: (0, (0, parcelRequire("lmyls")).MENU_ID).PROJECTS
    };
    const $14a03ca5c167a73f$export$d9b273488cd8ce6f = ({ AuthenticationErrorBoundary: AuthenticationErrorBoundary = (0, (0, parcelRequire("9oIax")).AuthenticationErrorBoundary), Empty: Empty = (0, (0, parcelRequire("2KGAL")).Empty), Error: Error = (0, (0, parcelRequire("8vzcp")).MenuError), FavoriteContent: FavoriteContent = (0, (0, parcelRequire("1xLtH")).FavoriteContent), Footer: Footer = (0, (0, parcelRequire("4nCpW")).Footer), JSErrorBoundary: JSErrorBoundary = (0, (0, parcelRequire("39xOx")).JSErrorBoundary), Layout: Layout = (0, (0, parcelRequire("ag3sS")).MenuLayout), LoadStatus: LoadStatus = (0, (0, parcelRequire("4ci63")).LoadStatus), RecentContent: RecentContent = (0, (0, parcelRequire("i4bJa")).RecentContent), RecommendationSelector: RecommendationSelector = (0, (0, parcelRequire("bEK4U")).RecommendationSelector), recommendations: recommendations = (0, (0, parcelRequire("13iNl")).recommendations), Section: Section = (0, (0, parcelRequire("9UCCQ")).default), SkeletonContent: SkeletonContent = (0, (0, parcelRequire("9TMfX")).MenuSkeletonContent), SkeletonFooter: SkeletonFooter = (0, (0, parcelRequire("6WLja")).MenuSkeletonFooter), SpotlightTarget: SpotlightTarget = (0, (0, parcelRequire("5Fk4Z")).default), testIdPrefix: testIdPrefix, useCreateProjectPermission: useCreateProjectPermission = (0, (0, parcelRequire("d40Fj")).useCreateProjectPermission), useExperienceFail: useExperienceFail = (0, (0, parcelRequire("93jKl")).useExperienceFail), useFavorite: useFavorite = (0, (0, parcelRequire("bcn6L")).useFavoriteProjects) })=>{
        const [, { loadFavoriteProjects: loadFavorite }] = useFavorite();
        const [{ hasFetchedOnce: hasFetchedOncePermissions, isFetching: isFetching }, { fetchCreateProjectPermission: fetchCreateProjectPermission }] = useCreateProjectPermission();
        const onFail = useExperienceFail({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU)
        });
        (0, $5uXOq.useEffect)(()=>()=>{
                loadFavorite((0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV));
            }, [
            loadFavorite
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (!hasFetchedOncePermissions && !isFetching) fetchCreateProjectPermission();
        }, [
            hasFetchedOncePermissions,
            isFetching,
            fetchCreateProjectPermission
        ]);
        const testIdContent = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'content');
        const testIdFooter = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'footer');
        const errorFallback = (0, $5uXOq.useCallback)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement(Error, {
                testIdPrefix: testIdContent
            }), [
            testIdContent
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement(SpotlightTarget, {
            name: (0, (0, parcelRequire("Mp6qH")).PROJECTS_SPOTLIGHT_KEY)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(Layout, {
            isWide: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(Section, {
            isScrollable: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(JSErrorBoundary, {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).PROJECTS,
            packageName: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME),
            fallback: errorFallback,
            withExperienceTracker: true,
            extraEventData: $14a03ca5c167a73f$var$EVENT_DATA,
            onError: onFail
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(AuthenticationErrorBoundary, {
            render: errorFallback
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(Empty, {
            testIdPrefix: testIdContent
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "filters",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement(SkeletonContent, {
                testIdPrefix: testIdContent
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(LoadStatus, {
            shouldThrowError: true
        }), (0, ($parcel$interopDefault($5uXOq))).createElement(FavoriteContent, {
            testIdPrefix: testIdContent
        }), (0, ($parcel$interopDefault($5uXOq))).createElement(RecentContent, {
            testIdPrefix: testIdContent
        })))))), (0, ($parcel$interopDefault($5uXOq))).createElement(RecommendationSelector, {
            recommendations: recommendations
        }), (0, ($parcel$interopDefault($5uXOq))).createElement(Section, {
            hasSeparator: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "footer",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement(SkeletonFooter, {
                testIdPrefix: testIdFooter
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(LoadStatus, null), (0, ($parcel$interopDefault($5uXOq))).createElement(Footer, {
            testIdPrefix: testIdFooter
        })))));
    };
});
parcelRegister("bEK4U", function(module, exports) {
    $parcel$export(module.exports, "RecommendationSelector", ()=>$3c478d901993099b$export$f97cd4c3e697ffee);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $iXVV6;
    var $ce4pa;
    var $cmzJ2;
    var $kklBj;
    const $3c478d901993099b$export$f97cd4c3e697ffee = ({ recommendations: recommendations })=>{
        const [state] = (0, (0, parcelRequire("cmzJ2")).useProjectMenuRecommendations)();
        if (state.status !== 'success') return null;
        const selectedRecommendation = recommendations.find((recommendation)=>recommendation.key === state.recommendationKey);
        return selectedRecommendation ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: `projects-menu-recommendation.${selectedRecommendation.key}`,
            packageName: "jiraAtlassianNavigationRecommendations",
            onError: ()=>(0, (0, parcelRequire("kklBj")).projectsMenuDropdownRecommendationSelector).failure(),
            fallback: "unmount",
            teamName: "navigation"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            attributes: {
                recommendationKey: selectedRecommendation.key
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ce4pa")).ExperienceSuccess), {
            experience: (0, (0, parcelRequire("kklBj")).projectsMenuDropdownRecommendationSelector)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement(selectedRecommendation.Component, null))) : null;
    };
});
parcelRegister("2KGAL", function(module, exports) {
    $parcel$export(module.exports, "Empty", ()=>$bae626c9288cadc7$export$1f74963c34e8bfec);
    var $5uXOq = parcelRequire("5uXOq");
    var $cgL5K;
    var $3bQtK;
    var $bcn6L;
    var $aWse0;
    var $6cNb0;
    var $apeap;
    var $cZpsT;
    var $Mp6qH;
    var $jyltl;
    var $dMJS0;
    var $5cgeH = parcelRequire("5cgeH");
    var $RQqjE;
    const $bae626c9288cadc7$var$learnMoreHref = 'https://confluence.atlassian.com/adminjiracloud/creating-editing-deleting-and-hiding-projects-844500729.html';
    const $bae626c9288cadc7$export$1f74963c34e8bfec = ({ MenuInfo: MenuInfo = (0, (0, parcelRequire("jyltl")).MenuInfo), children: children, testIdPrefix: testIdPrefix, useExperienceSuccess: useExperienceSuccess = (0, (0, parcelRequire("3bQtK")).useExperienceSuccess), useFavorite: useFavorite = (0, (0, parcelRequire("bcn6L")).useFavoriteProjects), useIntl: useIntl = (0, (0, parcelRequire("aWse0")).useIntlV2), useIsAnonymous: useIsAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous), useItems: useItems = (0, (0, parcelRequire("dMJS0")).useProjectNavItems), useRecent: useRecent = (0, (0, parcelRequire("apeap")).useRecentProjects) })=>{
        const { formatMessage: formatMessage } = useIntl();
        const isAnonymous = useIsAnonymous();
        const onSuccess = useExperienceSuccess({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU)
        });
        const text = (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            as: "p"
        }, formatMessage((0, (0, parcelRequire("RQqjE")).default).emptyText))), (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, (0, ($parcel$interopDefault($5uXOq))).createElement("a", {
            href: $bae626c9288cadc7$var$learnMoreHref,
            target: "_blank",
            rel: "noopener noreferrer"
        }, formatMessage((0, (0, parcelRequire("RQqjE")).default).learnMore))));
        const [{ isFetching: isFetchingFavorite, hasSuccessOnce: hasSuccessOnceFavorite, fetchError: fetchErrorFavorite }] = useFavorite();
        const [{ isFetching: isFetchingRecent, hasSuccessOnce: hasSuccessOnceRecent, fetchError: fetchErrorRecent }] = useRecent();
        const { favoriteItems: favoriteItems, recentItems: recentItems } = useItems();
        const notEmpty = favoriteItems.length || recentItems.length || isFetchingFavorite && !hasSuccessOnceFavorite || isFetchingRecent && !hasSuccessOnceRecent || fetchErrorFavorite || fetchErrorRecent;
        const shouldShowEmptyForAnonymous = isAnonymous;
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'empty');
        (0, $5uXOq.useEffect)(()=>{
            const isSuccess = hasSuccessOnceFavorite && hasSuccessOnceRecent;
            if (isSuccess) onSuccess();
        }, [
            hasSuccessOnceFavorite,
            hasSuccessOnceRecent,
            onSuccess
        ]);
        return notEmpty && !shouldShowEmptyForAnonymous ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children) : (0, ($parcel$interopDefault($5uXOq))).createElement(MenuInfo, {
            imgAltText: formatMessage((0, (0, parcelRequire("RQqjE")).default).emptyImg),
            imgSrc: (0, ($parcel$interopDefault($5cgeH))),
            imgHeight: 138,
            testId: testId,
            text: text
        });
    };
});
parcelRegister("5cgeH", function(module, exports) {
    module.exports = new URL("project-empty.0864e274.svg", import.meta.url).toString();
});
parcelRegister("RQqjE", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$15aec9727c6dd925$export$2e2bcd8739ae039);
    var $7VHMR;
    var $15aec9727c6dd925$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        emptyText: {
            "id": "atlassian-navigation.projects.menu.empty.empty-text",
            "defaultMessage": "Make the most of your Atlassian products by extending them with projects."
        },
        learnMore: {
            "id": "atlassian-navigation.projects.menu.empty.learn-more",
            "defaultMessage": "Learn more"
        },
        emptyImg: {
            "id": "atlassian-navigation.projects.menu.empty.empty-img",
            "defaultMessage": "An image showing that the projects dropdown menu is empty"
        }
    });
});
parcelRegister("1xLtH", function(module, exports) {
    $parcel$export(module.exports, "FavoriteContent", ()=>$e1a4963a7cc4d825$export$297af018011b145a);
    var $5uXOq = parcelRequire("5uXOq");
    var $ujWmd;
    var $4mNf3;
    var $eXQym;
    var $koVbs;
    var $2EqxS;
    var $6cNb0;
    var $Mp6qH;
    var $fhtpB;
    var $d4B4Y;
    var $7ilb8;
    var $56fXm;
    var $dMJS0;
    var $Yyrw8;
    const $e1a4963a7cc4d825$export$297af018011b145a = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { favoriteItems: favoriteItems, changeFavorite: onChangeFavorite, changedItems: changedItems } = (0, (0, parcelRequire("dMJS0")).useProjectNavItems)();
        const itemsToShow = (0, $5uXOq.useMemo)(()=>favoriteItems.slice(0, (0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV)), [
            favoriteItems
        ]);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'items-starred');
        const projectsUrl = '/jira/projects?page=1&sortKey=favourite&sortOrder=DESC';
        return favoriteItems.length > 0 ? (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), {
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'heading')
        }, formatMessage((0, (0, parcelRequire("Yyrw8")).default).starred)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuList), null, itemsToShow.map((item, idx)=>{
            const { url: url, id: id, favourite: isFavourite, metadata: metadata, title: title } = item;
            const contextItem = changedItems[item.id.toString()];
            const isPending = contextItem && contextItem.pending === true ? contextItem.pending : false;
            const { params: params, query: query, route: to } = (0, (0, parcelRequire("2EqxS")).getLinkFromProject)(item);
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
                key: id,
                ariaLabel: formatMessage((0, (0, parcelRequire("Yyrw8")).default).starred)
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
                analytics: {
                    actionSubjectId: 'projectsNavigationMenuItem',
                    menuItemId: id,
                    menuItemType: 'starred',
                    position: idx,
                    starred: Boolean(isFavourite)
                },
                description: metadata,
                href: url,
                iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ujWmd")).default), {
                    appearance: "square",
                    size: "small",
                    borderColor: "transparent",
                    src: item.avatarUrl
                }),
                iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fhtpB")).FavoriteButton), {
                    id: String(id),
                    isFavorite: Boolean(isFavourite),
                    favoriteItemName: title,
                    isPending: isPending,
                    menuItemType: "starred",
                    onChangeFavorite: onChangeFavorite,
                    type: (0, (0, parcelRequire("eXQym")).PROJECTS_ITEM_TYPE)
                }),
                params: params,
                query: query,
                testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, `item-${idx}`),
                to: to
            }, title));
        })), favoriteItems.length >= (0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV) && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("56fXm")).MenuItemViewAll), {
            analytics: {
                actionSubjectId: 'projectsNavigationMenuItem',
                menuItemId: 'viewAll',
                position: (0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_FAV)
            },
            href: projectsUrl,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-view-all'),
            to: projectsUrl
        })) : null;
    };
});
parcelRegister("Yyrw8", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$826ed05c79892831$export$2e2bcd8739ae039);
    var $7VHMR;
    var $826ed05c79892831$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        starred: {
            "id": "atlassian-navigation.projects.menu.favorite-content.starred",
            "defaultMessage": "Starred"
        }
    });
});
parcelRegister("4nCpW", function(module, exports) {
    $parcel$export(module.exports, "Footer", ()=>$feb4a5abece4d737$export$a06f1c675e846f6f);
    var $5uXOq = parcelRequire("5uXOq");
    var $koVbs;
    var $d40Fj;
    var $6cNb0;
    var $1xIol;
    var $e9QkH;
    var $cZpsT;
    var $Mp6qH;
    var $d4B4Y;
    var $kdlJi;
    const $feb4a5abece4d737$export$a06f1c675e846f6f = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const [, { on: openProjectCreateDrawer }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("Mp6qH")).MODAL_ID).PROJECT_CREATE);
        const [{ canCreateProject: canCreateProject }] = (0, (0, parcelRequire("d40Fj")).useCreateProjectPermission)();
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'items');
        return (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'projectsNavigationMenuItem',
                menuItemId: 'viewAll',
                menuItemType: 'action',
                position: 0
            },
            href: "/jira/projects",
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-view-all'),
            to: (0, (0, parcelRequire("1xIol")).projectsDirectoryRoute)
        }, formatMessage((0, (0, parcelRequire("kdlJi")).default).viewAll)), !isAnonymous && canCreateProject && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'projectsNavigationMenuItem',
                menuItemId: 'create',
                menuItemType: 'action',
                position: 1
            },
            onClick: openProjectCreateDrawer,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-create')
        }, formatMessage((0, (0, parcelRequire("kdlJi")).default).createProject)));
    };
});
parcelRegister("1xIol", function(module, exports) {
    $parcel$export(module.exports, "projectsDirectoryRoute", ()=>$4b60f76eec2e5934$export$74114064f06cd71b);
    $parcel$export(module.exports, "manageProjectsDirectoryRoute", ()=>$4b60f76eec2e5934$export$281a4490a0f8fb4c);
    $parcel$export(module.exports, "projectsDirectoryRedirectRoute", ()=>$4b60f76eec2e5934$export$d44f1be3782f0fde);
    $parcel$export(module.exports, "projectsDirectoryLegacyPathRedirectRoute", ()=>$4b60f76eec2e5934$export$c713f57300a82898);
    $parcel$export(module.exports, "projectsDirectoryManageRedirectRoute", ()=>$4b60f76eec2e5934$export$dbd3b6c012e8b6dc);
    $parcel$export(module.exports, "projectsDirectoryManageRootRedirectRoute", ()=>$4b60f76eec2e5934$export$4531e3b2e25fad23);
    var $65Ltd;
    const $4b60f76eec2e5934$export$74114064f06cd71b = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_PROJECTS_DIRECTORY),
        path: '/jira/projects',
        exact: true
    };
    const $4b60f76eec2e5934$export$281a4490a0f8fb4c = {
        name: (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_PROJECTS_DIRECTORY_MANAGE),
        path: '/jira/settings/projects/manage'
    };
    const $4b60f76eec2e5934$export$d44f1be3782f0fde = {
        name: `${(0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_PROJECTS_DIRECTORY)}-redirect`,
        path: '/projects',
        exact: true
    };
    const $4b60f76eec2e5934$export$c713f57300a82898 = {
        name: `${(0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_PROJECTS_DIRECTORY_LEGACY_PATH_SPA)}-redirect`,
        path: '/secure/BrowseProjects.jspa'
    };
    const $4b60f76eec2e5934$export$dbd3b6c012e8b6dc = {
        name: `${(0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_PROJECTS_DIRECTORY_MANAGE)}-redirect`,
        path: '/secure/project/ViewProjects.jspa'
    };
    const $4b60f76eec2e5934$export$4531e3b2e25fad23 = {
        name: `${(0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_PROJECTS_DIRECTORY_MANAGE)}-root-redirect`,
        path: '/jira/settings/projects',
        exact: true
    };
});
parcelRegister("kdlJi", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$54e62ef08eaa20a3$export$2e2bcd8739ae039);
    var $7VHMR;
    var $54e62ef08eaa20a3$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        createProject: {
            "id": "atlassian-navigation.projects.menu.footer.create-project",
            "defaultMessage": "Create project"
        },
        viewAll: {
            "id": "atlassian-navigation.projects.menu.footer.view-all",
            "defaultMessage": "View all projects"
        },
        importProject: {
            "id": "atlassian-navigation.projects.menu.footer.import-project",
            "defaultMessage": "Import project"
        }
    });
});
parcelRegister("4ci63", function(module, exports) {
    $parcel$export(module.exports, "LoadStatus", ()=>$0e36b7ec65a0b373$export$c7df4a3f1b89de01);
    var $5uXOq = parcelRequire("5uXOq");
    var $2N6bN;
    var $bcn6L;
    var $d40Fj;
    var $apeap;
    var $dHtVS;
    const $0e36b7ec65a0b373$export$c7df4a3f1b89de01 = ({ LoadStatusCommon: LoadStatusCommon = (0, (0, parcelRequire("dHtVS")).LoadStatus), shouldThrowError: shouldThrowError = false, useCreateProjectPermission: useCreateProjectPermission = (0, (0, parcelRequire("d40Fj")).useCreateProjectPermission), useFavorite: useFavorite = (0, (0, parcelRequire("bcn6L")).useFavoriteProjects), useRecent: useRecent = (0, (0, parcelRequire("apeap")).useRecentProjects) })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement(LoadStatusCommon, {
            shouldThrowError: shouldThrowError,
            useProvider: useCreateProjectPermission
        }), (0, ($parcel$interopDefault($5uXOq))).createElement(LoadStatusCommon, {
            shouldThrowError: shouldThrowError,
            useProvider: useFavorite
        }), (0, ($parcel$interopDefault($5uXOq))).createElement(LoadStatusCommon, {
            shouldThrowError: shouldThrowError,
            useProvider: useRecent
        }), (0, ($parcel$interopDefault($5uXOq))).createElement(LoadStatusCommon, {
            shouldThrowError: false,
            useProvider: (0, (0, parcelRequire("2N6bN")).usePersonalizationProvider)
        }));
});
parcelRegister("i4bJa", function(module, exports) {
    $parcel$export(module.exports, "RecentContent", ()=>$dd89c0b17853b373$export$a2873dc4e96d8e90);
    var $5uXOq = parcelRequire("5uXOq");
    var $ujWmd;
    var $4mNf3;
    var $eXQym;
    var $koVbs;
    var $2EqxS;
    var $6cNb0;
    var $Mp6qH;
    var $fhtpB;
    var $d4B4Y;
    var $7ilb8;
    var $dMJS0;
    var $9uulb;
    const $dd89c0b17853b373$export$a2873dc4e96d8e90 = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { recentItems: recentItems, favoriteItems: favoriteItems, changeFavorite: onChangeFavorite, changedItems: changedItems } = (0, (0, parcelRequire("dMJS0")).useProjectNavItems)();
        const itemsToShow = (0, $5uXOq.useMemo)(()=>recentItems.slice(0, (0, (0, parcelRequire("Mp6qH")).DEFAULT_LOAD_COUNT_RECENT)), [
            recentItems
        ]);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'items-recent');
        return recentItems.length > 0 ? (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), {
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'heading')
        }, formatMessage((0, (0, parcelRequire("9uulb")).default).recent)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuList), null, itemsToShow.map((item, idx)=>{
            const { url: url, id: id, favourite: isFavourite, metadata: metadata, title: title } = item;
            const contextItem = changedItems[item.id.toString()];
            const isPending = contextItem && contextItem.pending === true ? contextItem.pending : false;
            const { id: beforeEntityId, type: beforeEntityType } = (0, (0, parcelRequire("2EqxS")).getBeforeEntity)(favoriteItems);
            const { params: params, query: query, route: to } = (0, (0, parcelRequire("2EqxS")).getLinkFromProject)(item);
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
                key: id,
                ariaLabel: formatMessage((0, (0, parcelRequire("9uulb")).default).recent)
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
                analytics: {
                    actionSubjectId: 'projectsNavigationMenuItem',
                    menuItemId: id,
                    menuItemType: 'recent',
                    position: idx,
                    starred: Boolean(isFavourite)
                },
                description: metadata,
                href: url,
                iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ujWmd")).default), {
                    appearance: "square",
                    size: "small",
                    borderColor: "transparent",
                    src: item.avatarUrl
                }),
                iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fhtpB")).FavoriteButton), {
                    beforeEntityId: beforeEntityId,
                    beforeEntityType: beforeEntityType,
                    id: String(id),
                    isFavorite: Boolean(isFavourite),
                    favoriteItemName: title,
                    isPending: isPending,
                    menuItemType: "recent",
                    onChangeFavorite: onChangeFavorite,
                    type: (0, (0, parcelRequire("eXQym")).PROJECTS_ITEM_TYPE)
                }),
                params: params,
                query: query,
                testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, `item-${idx}`),
                to: to
            }, title));
        }))) : null;
    };
});
parcelRegister("9uulb", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$58ba7fa57975d970$export$2e2bcd8739ae039);
    var $7VHMR;
    var $58ba7fa57975d970$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        recent: {
            "id": "atlassian-navigation.projects.menu.recent-content.recent",
            "defaultMessage": "Recent"
        }
    });
});
parcelRegister("b21tm", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7d7261af77ace424$export$2e2bcd8739ae039);
    var $7VHMR;
    var $7d7261af77ace424$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        projects: {
            "id": "atlassian-navigation.projects.projects",
            "defaultMessage": "Projects"
        }
    });
});
parcelRegister("el0Fl", function(module, exports) {
    $parcel$export(module.exports, "useShowRoadmaps", ()=>$767dc22cd21e9a9b$export$9564042b1b0a7d8f);
    $parcel$export(module.exports, "Roadmaps", ()=>$767dc22cd21e9a9b$export$a68bc1ba98e0d580);
    var $5uXOq = parcelRequire("5uXOq");
    var $jP8zA;
    var $koVbs;
    var $lmyls;
    var $efvDm;
    var $ldyPw;
    var $9OXo1;
    var $Mp6qH;
    var $4uXxK;
    var $fVbkk;
    var $6yVM4;
    const $767dc22cd21e9a9b$var$JPD_ROADMAPS_PATH = '/jira/polaris/roadmaps';
    const $767dc22cd21e9a9b$var$useIsJpdPremiumEdition = ()=>{
        const { appEditions: appEditions } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const jpdEdition = (0, (0, parcelRequire("ldyPw")).getEdition)((0, (0, parcelRequire("efvDm")).JIRA_PRODUCT_DISCOVERY), appEditions);
        return jpdEdition === (0, (0, parcelRequire("ldyPw")).toEdition)((0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION));
    };
    const $767dc22cd21e9a9b$export$9564042b1b0a7d8f = (0, (0, parcelRequire("jP8zA")).functionWithFF)('polaris.show-roadmaps-menu-entry', ()=>{
        const { appPermissions: appPermissions } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const isJpdPremium = $767dc22cd21e9a9b$var$useIsJpdPremiumEdition();
        return appPermissions.hasProductDiscoveryAccess && isJpdPremium;
    }, ()=>false);
    const $767dc22cd21e9a9b$export$90296ede40c0af4a = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { isSiteAdmin: isSiteAdmin } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVbkk")).MenuTrigger), {
            href: $767dc22cd21e9a9b$var$JPD_ROADMAPS_PATH,
            menuId: (0, (0, parcelRequire("lmyls")).MENU_ID).ROADMAPS,
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).ROADMAPS,
            analyticsAttributes: {
                isSiteAdmin: isSiteAdmin
            }
        }, formatMessage((0, (0, parcelRequire("6yVM4")).default).titleJpdRoadmaps));
    };
    const $767dc22cd21e9a9b$export$a68bc1ba98e0d580 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).ROADMAPS
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($767dc22cd21e9a9b$export$90296ede40c0af4a, null));
});
parcelRegister("jP8zA", function(module, exports) {
    $parcel$export(module.exports, "functionWithFF", ()=>$5ce3dbf423abf4c9$export$3b8cbae22108d228);
    var $co1wz;
    var $e5wyW;
    const $5ce3dbf423abf4c9$export$3b8cbae22108d228 = (featureFlagName, functionTrue, functionFalse)=>(0, (0, parcelRequire("e5wyW")).functionWithCondition)(()=>(0, (0, parcelRequire("co1wz")).ff)(featureFlagName), functionTrue, functionFalse);
});
parcelRegister("6yVM4", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2fe7682072c5451b$export$2e2bcd8739ae039);
    var $7VHMR;
    var $2fe7682072c5451b$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        titleJpdRoadmaps: {
            "id": "atlassian-navigation.roadmaps.title-jpd-roadmaps",
            "defaultMessage": "Roadmaps"
        }
    });
});
parcelRegister("0396H", function(module, exports) {
    $parcel$export(module.exports, "NavigationRouterListener", ()=>$fe5862efafb61dce$export$85575e1c48f0a517);
    var $5uXOq = parcelRequire("5uXOq");
    var $doZTA;
    var $hUGiV;
    const $fe5862efafb61dce$export$85575e1c48f0a517 = ()=>{
        const [{ route: route, location: location, match: match }] = (0, (0, parcelRequire("doZTA")).useRouter)();
        const onEnd = (0, (0, parcelRequire("hUGiV")).useAtlassianNavigationEnd)();
        (0, $5uXOq.useLayoutEffect)(()=>{
            onEnd();
        }, [
            route,
            match,
            location,
            onEnd
        ]);
        return null;
    };
});
parcelRegister("hUGiV", function(module, exports) {
    $parcel$export(module.exports, "useAtlassianNavigationEnd", ()=>$c2d6674a75325f63$export$4b9602c0fdb8b1bb);
    var $5uXOq = parcelRequire("5uXOq");
    var $4oDnX;
    const $c2d6674a75325f63$export$4b9602c0fdb8b1bb = ()=>{
        const [, { setAppReadyWithAppId: setAppReadyWithAppId }] = (0, (0, parcelRequire("4oDnX")).useSpaStateActions)();
        return (0, $5uXOq.useCallback)(()=>{
            setAppReadyWithAppId((0, $146ecce9d079a34d$export$3a54edb6ba4daa82));
        }, [
            setAppReadyWithAppId
        ]);
    };
});
const $146ecce9d079a34d$export$3a54edb6ba4daa82 = 'TOP_NAV';
const $146ecce9d079a34d$export$2ee9e5a3556c1e43 = 'SIDEBAR';
parcelRegister("fwJYI", function(module, exports) {
    $parcel$export(module.exports, "Search", ()=>$e3b17dd8db2850a9$export$4b85d3515bd863a5);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $4R6GH;
    var $koVbs;
    var $lfTZh;
    var $kxZPA;
    var $Mp6qH;
    var $gIk9n;
    var $crtj2;
    var $gIJdF;
    const $e3b17dd8db2850a9$var$LoadingSkeleton = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        if ((0, (0, parcelRequire("4R6GH")).fg)('blu-4155-search-skeleton-features')) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gIJdF")).Skeleton), {
            placeholder: formatMessage((0, (0, parcelRequire("gIk9n")).default).interactiveSearchSkeletonPlaceholder)
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gIJdF")).Skeleton), null);
    };
    const $e3b17dd8db2850a9$var$SearchDialogMetricStart = ()=>{
        (0, $5uXOq.useEffect)(()=>{
            (0, (0, parcelRequire("crtj2")).searchDialogMetric).startFromPageLoad();
            (0, (0, parcelRequire("crtj2")).searchDialogMetric).mark((0, (0, parcelRequire("crtj2")).SEARCH_DIALOG_DOWNLOAD_START_MARK));
        }, []);
        return null;
    };
    const $e3b17dd8db2850a9$var$SearchDialogMetricEnd = ()=>{
        (0, $5uXOq.useEffect)(()=>{
            (0, (0, parcelRequire("crtj2")).searchDialogMetric).mark((0, (0, parcelRequire("crtj2")).SEARCH_DIALOG_DOWNLOAD_END_MARK));
        }, []);
        return null;
    };
    const $e3b17dd8db2850a9$var$LazySearch = (0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>(parcelRequire("6Ax1j")).then((m)=>m.Search), {
        ssr: false,
        moduleId: "./src/packages/navigation-apps/atlassian-navigation/src/ui/search/main.tsx"
    });
    const $e3b17dd8db2850a9$export$4b85d3515bd863a5 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "search",
            packageName: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME),
            fallback: "unmount"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($e3b17dd8db2850a9$var$SearchDialogMetricStart, null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "lazy-search",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement($e3b17dd8db2850a9$var$LoadingSkeleton, null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($e3b17dd8db2850a9$var$LazySearch, props), (0, ($parcel$interopDefault($5uXOq))).createElement($e3b17dd8db2850a9$var$SearchDialogMetricEnd, null)));
});
parcelRegister("gIk9n", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b88bffd5106f4b78$export$2e2bcd8739ae039);
    var $7VHMR;
    var $b88bffd5106f4b78$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        updated: {
            "id": "atlassian-navigation.search.updated",
            "defaultMessage": "Updated"
        },
        interactiveSearchSkeletonPlaceholder: {
            "id": "atlassian-navigation.search.interactive-search-skeleton-placeholder",
            "defaultMessage": "Search"
        }
    });
});
parcelRegister("crtj2", function(module, exports) {
    $parcel$export(module.exports, "SEARCH_DIALOG_DOWNLOAD_START_MARK", ()=>$f04658be14f95c1c$export$8cd6a987249a6f33);
    $parcel$export(module.exports, "SEARCH_DIALOG_DOWNLOAD_END_MARK", ()=>$f04658be14f95c1c$export$80ab6637ae730993);
    $parcel$export(module.exports, "searchDialogMetric", ()=>$f04658be14f95c1c$export$46d64d80a1040e9b);
    var $dGrdR;
    const $f04658be14f95c1c$var$SEARCH_DIALOG_METRIC_KEY = 'search-dialog';
    const $f04658be14f95c1c$export$8cd6a987249a6f33 = 'download-start';
    const $f04658be14f95c1c$export$80ab6637ae730993 = 'download-end';
    const $f04658be14f95c1c$export$46d64d80a1040e9b = (0, (0, parcelRequire("dGrdR")).metrics).pageSegmentLoad({
        key: $f04658be14f95c1c$var$SEARCH_DIALOG_METRIC_KEY,
        timings: [
            {
                key: 'download',
                startMark: $f04658be14f95c1c$export$8cd6a987249a6f33,
                endMark: $f04658be14f95c1c$export$80ab6637ae730993
            }
        ]
    });
});
parcelRegister("gIJdF", function(module, exports) {
    $parcel$export(module.exports, "Skeleton", ()=>$315a2135ffa56c8b$export$8f31e4c4a37b8e9c);
    var $5uXOq = parcelRequire("5uXOq");
    var $4R6GH;
    var $3HwlS;
    var $al2bB;
    var $bPysz;
    var $eoNiZ;
    const $315a2135ffa56c8b$export$8f31e4c4a37b8e9c = ({ ProductSearchInputSkeleton: ProductSearchInputSkeleton = (0, (0, parcelRequire("bPysz")).ProductSearchInputSkeleton), useTheme: useTheme = (0, (0, parcelRequire("eoNiZ")).useTheme), placeholder: placeholder })=>{
        const theme = useTheme();
        const newTheme = (0, (0, parcelRequire("al2bB")).useLegacySearchTheme)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement(ProductSearchInputSkeleton, {
            shouldFillContainer: (0, (0, parcelRequire("3HwlS")).getWillShowNav4)(),
            theme: (0, (0, parcelRequire("3HwlS")).getWillShowNav4)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_search_theming') ? newTheme : theme && theme.mode && theme.mode.search,
            placeholder: placeholder
        });
    };
});
parcelRegister("6Ax1j", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("dsiAd"),
        $6fhBS("3G8pv")
    ]).then(()=>parcelRequire('1jMgA'));
});
parcelRegister("ag3PC", function(module, exports) {
    $parcel$export(module.exports, "Settings", ()=>$f891c4e4f71955d4$export$c72f6eaae7b9adff);
    var $5uXOq = parcelRequire("5uXOq");
    var $22Gxp;
    var $42CjB;
    var $epOG5;
    var $1I7E8;
    var $3bDjY;
    var $kaHBi;
    var $4R6GH;
    var $koVbs;
    var $lmyls;
    var $2Ui4E;
    var $6cNb0;
    var $3HwlS;
    var $cZpsT;
    var $8TWpC;
    var $Mp6qH;
    var $4uXxK;
    var $amOJR;
    var $iPj86;
    var $hrU50;
    var $asDBE;
    const $f891c4e4f71955d4$var$SettingsInner = ()=>{
        const [isOpen, { toggle: toggleMenu, off: closeMenu }] = (0, (0, parcelRequire("2Ui4E")).useTopMenus)((0, (0, parcelRequire("lmyls")).MENU_ID).SETTINGS);
        const triggerAnalytics = (0, (0, parcelRequire("iPj86")).useNavigationItemAnalytics)({
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).SETTINGS
        });
        const onStart = (0, (0, parcelRequire("kaHBi")).useExperienceStart)({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU),
            experienceId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).SETTINGS,
            analyticsSource: 'atlassian-navigation'
        });
        const onClick = (0, $5uXOq.useCallback)(()=>{
            if (!(0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) onStart();
            toggleMenu();
            triggerAnalytics({
                shownCommandPalettePrompt: true
            });
        }, [
            onStart,
            toggleMenu,
            triggerAnalytics
        ]);
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const testIdPrefix = (0, (0, parcelRequire("6cNb0")).testIdGenerate)('secondary-actions', 'settings');
        const id = (0, (0, parcelRequire("22Gxp")).useUID)();
        const titleId = `top-navigation-settings-header-${id}-title`;
        const content = (0, $5uXOq.useCallback)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hrU50")).Menu), {
                testIdPrefix: testIdPrefix,
                titleId: titleId
            }), [
            testIdPrefix,
            titleId
        ]);
        const OldTrigger = (0, $5uXOq.useCallback)((triggerProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement("div", {
                "data-testid": (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'menu-trigger')
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("amOJR")).Settings), {
                ...triggerProps,
                onClick: onClick,
                tooltip: formatMessage((0, (0, parcelRequire("asDBE")).default).settings),
                isSelected: isOpen
            })), [
            formatMessage,
            isOpen,
            onClick,
            testIdPrefix
        ]);
        const NewTrigger = (0, $5uXOq.useCallback)((triggerProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement("div", {
                "data-testid": (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'menu-trigger')
            }, (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_settings_theming') ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8TWpC")).Settings), {
                ...triggerProps,
                onClick: onClick,
                isSelected: isOpen,
                interactionName: "nav4-topNav-settings"
            }, formatMessage((0, (0, parcelRequire("asDBE")).default).settings)) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
                content: formatMessage((0, (0, parcelRequire("asDBE")).default).settings)
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("42CjB")).default), {
                ...triggerProps,
                icon: (0, ($parcel$interopDefault((0, parcelRequire("epOG5"))))),
                onClick: onClick,
                appearance: "subtle",
                label: formatMessage((0, (0, parcelRequire("asDBE")).default).settings),
                isSelected: isOpen,
                interactionName: "nav4-topNav-settings"
            }))), [
            isOpen,
            formatMessage,
            onClick,
            testIdPrefix
        ]);
        const Trigger = (0, (0, parcelRequire("3HwlS")).getWillShowNav4)() ? NewTrigger : OldTrigger;
        if (isAnonymous) return null;
        if (false) return (0, ($parcel$interopDefault($5uXOq))).createElement(Trigger, {
            "aria-expanded": false,
            "aria-haspopup": true
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1I7E8")).default), {
            titleId: titleId,
            isOpen: isOpen,
            content: content,
            onClose: closeMenu,
            placement: "bottom-end",
            trigger: Trigger,
            label: formatMessage((0, (0, parcelRequire("asDBE")).default).settings)
        });
    };
    const $f891c4e4f71955d4$export$c72f6eaae7b9adff = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).SETTINGS
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($f891c4e4f71955d4$var$SettingsInner, null));
});
parcelRegister("amOJR", function(module, exports) {
    $parcel$export(module.exports, "Settings", ()=>$70f9abb464fd04b1$export$c72f6eaae7b9adff);
    var $5uXOq = parcelRequire("5uXOq");
    var $3rGy0;
    var $cmbsU;
    var $3HwlS;
    const $70f9abb464fd04b1$export$c72f6eaae7b9adff = (0, $5uXOq.forwardRef)((props, ref)=>{
        if (false) {
            if ((0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cmbsU")).Nav4SkeletonSettingsButton), {
                label: props.tooltip
            });
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cmbsU")).SkeletonSettingsButton), {
                label: props.tooltip
            });
        }
        const buttonProps = {
            ...props,
            ref: ref
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3rGy0")).Settings), buttonProps);
    });
});
parcelRegister("hrU50", function(module, exports) {
    $parcel$export(module.exports, "Menu", ()=>$c4588c969d854f97$export$d9b273488cd8ce6f);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $9UCCQ;
    var $eaGfl;
    var $iwwXK;
    var $5U49F;
    var $39xOx;
    var $3bQtK;
    var $koVbs;
    var $lmyls;
    var $6cNb0;
    var $3HwlS;
    var $daoB1;
    var $itmJb;
    var $cNd4s;
    var $l8ruj;
    var $Mp6qH;
    var $ag3sS;
    var $jNHMi;
    var $e57bL;
    var $asDBE;
    var $6svfi;
    var $b59xm;
    const $c4588c969d854f97$export$d9b273488cd8ce6f = ({ testIdPrefix: testIdPrefix, titleId: titleId })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const isAdmin = (0, (0, parcelRequire("itmJb")).useIsAdmin)();
        const isSiteAdmin = (0, (0, parcelRequire("cNd4s")).useIsSiteAdmin)();
        const appPermissions = (0, (0, parcelRequire("daoB1")).useApplicationPermissions)();
        const manageUsersPermission = (0, (0, parcelRequire("jNHMi")).useManageUsersPermission)();
        const willShowNav4 = (0, (0, parcelRequire("3HwlS")).getWillShowNav4)();
        const settingsNavigationItems = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("5U49F")).getGlobalSettingsAdminItems)(formatMessage, appPermissions, {
                isAdmin: isAdmin,
                isSiteAdmin: isSiteAdmin,
                manageUsersPermission: manageUsersPermission
            }, willShowNav4), [
            formatMessage,
            appPermissions,
            isAdmin,
            isSiteAdmin,
            manageUsersPermission,
            willShowNav4
        ]);
        const onSuccess = (0, (0, parcelRequire("3bQtK")).useExperienceSuccess)({
            experience: (0, (0, parcelRequire("Mp6qH")).EXPERIENCE_NAVIGATION_TOP_MENU)
        });
        (0, $5uXOq.useEffect)(()=>{
            if (!willShowNav4) onSuccess();
        }, [
            onSuccess,
            willShowNav4
        ]);
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'menu-popup');
        const testIdHeader = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'header');
        if (willShowNav4) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
            name: "nav4-topNav-settings"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).SETTINGS,
            packageName: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME),
            fallback: "flag"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ag3sS")).MenuLayout), {
            isWide: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6svfi")).Personal), {
            testIdPrefix: testId
        }), settingsNavigationItems.length > 0 && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("e57bL")).Home), {
            settingsDrawerItems: settingsNavigationItems,
            testIdPrefix: testId
        }), manageUsersPermission.loading ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eaGfl")).default), {
            testId: `${testId}--loading-skeleton--heading`,
            isShimmering: true
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iwwXK")).default), {
            testId: `${testId}--loading-skeleton--item`,
            isShimmering: true,
            hasIcon: true
        })) : settingsNavigationItems.length === 0 && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("b59xm")).Warning), {
            testIdPrefix: testId
        }))))));
        return (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ag3sS")).MenuLayout), {
            isWide: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement($c4588c969d854f97$var$SettingsTitle, {
            ...testIdHeader != null ? {
                'data-testid': testIdHeader
            } : {},
            id: titleId
        }, formatMessage((0, (0, parcelRequire("asDBE")).default).settings)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6svfi")).Personal), {
            testIdPrefix: testId
        }), settingsNavigationItems.length > 0 && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("e57bL")).Home), {
            settingsDrawerItems: settingsNavigationItems,
            testIdPrefix: testId
        }), manageUsersPermission.loading ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eaGfl")).default), {
            testId: `${testId}--loading-skeleton--heading`,
            isShimmering: true
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iwwXK")).default), {
            testId: `${testId}--loading-skeleton--item`,
            isShimmering: true,
            hasIcon: true
        })) : settingsNavigationItems.length === 0 && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("b59xm")).Warning), {
            testIdPrefix: testId
        }))));
    };
    const $c4588c969d854f97$var$SettingsTitle = (0, $5uXOq.forwardRef)(({ as: C = "h2", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1wybo0gd _vwz44jg8 _1dyzvkfx _1yt4rwue",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("5U49F", function(module, exports) {
    $parcel$export(module.exports, "IconWrapper", ()=>$a2085cc62fde1196$export$be58b4326e23250f);
    $parcel$export(module.exports, "getGlobalSettingsAdminItems", ()=>$a2085cc62fde1196$export$d637bf5a394b4534);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $3fDjf;
    var $1ZDWg;
    var $yJt0Y;
    var $gmyWi;
    var $ggICg;
    var $dXQDI;
    var $9fIhE;
    var $c781K;
    var $hPSf5;
    var $apKqz;
    var $5DSin;
    var $bP37O;
    var $8sgpE;
    var $kFO36;
    var $3Jz4b;
    var $17Ngn;
    var $dC5iT;
    var $kgoHi;
    var $cgL5K;
    var $8CUq3;
    var $aIZdc;
    var $dzeHJ;
    var $2f2jJ;
    var $4R6GH;
    var $6awYZ;
    var $9Y7t8;
    var $3JGmH;
    var $6lwNs;
    const $a2085cc62fde1196$var$getApplicationsMenuLink = (appPermissions)=>{
        if (appPermissions.hasSoftwareAccess) return '/jira/settings/products/jira-software-configuration';
        if (appPermissions.hasServiceDeskAccess) return '/jira/settings/products/jira-service-management-configuration';
        return '/plugins/servlet/applinks/listApplicationLinks';
    };
    const $a2085cc62fde1196$export$f3a5db2467c22913 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement($a2085cc62fde1196$export$be58b4326e23250f, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("3Jz4b"))))), {
            label: "",
            size: "small",
            primaryColor: "var(--ds-icon-inverse, #FFFFFF)"
        }));
    const $a2085cc62fde1196$export$3055c15ea575d46c = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement($a2085cc62fde1196$export$be58b4326e23250f, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("hPSf5"))))), {
            label: "",
            size: "small",
            primaryColor: "var(--ds-icon-inverse, #FFFFFF)"
        }));
    const $a2085cc62fde1196$export$43db94b81c32328a = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement($a2085cc62fde1196$export$be58b4326e23250f, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("bP37O"))))), {
            label: "",
            size: "small",
            primaryColor: "var(--ds-icon-inverse, #FFFFFF)"
        }));
    const $a2085cc62fde1196$export$583f26efcd800ae2 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement($a2085cc62fde1196$export$be58b4326e23250f, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("8sgpE"))))), {
            label: "",
            size: "small",
            primaryColor: "var(--ds-icon-inverse, #FFFFFF)"
        }));
    const $a2085cc62fde1196$export$627b4c7b9568cd5d = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement($a2085cc62fde1196$export$be58b4326e23250f, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("apKqz"))))), {
            label: "",
            size: "small",
            primaryColor: "var(--ds-icon-inverse, #FFFFFF)"
        }));
    const $a2085cc62fde1196$export$4464648b9631e67f = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement($a2085cc62fde1196$export$be58b4326e23250f, {
            dark: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("kFO36"))))), {
            label: "",
            size: "small",
            primaryColor: "var(--ds-icon-inverse, #FFFFFF)"
        }));
    const $a2085cc62fde1196$export$7b659dc9c22493b = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement($a2085cc62fde1196$export$be58b4326e23250f, {
            dark: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("yJt0Y"))))), {
            label: "",
            LEGACY_size: "small",
            LEGACY_fallbackIcon: (0, ($parcel$interopDefault((0, parcelRequire("5DSin"))))),
            color: "var(--ds-icon-inverse, #FFFFFF)"
        }));
    const $a2085cc62fde1196$var$getAdminSectionOld = (formatMessage, appPermissions, isHideAppGlobalSettingForFedrampEnabled = false)=>({
            id: (0, (0, parcelRequire("3JGmH")).JIRA_SETTINGS_SECTION_IDS).SYSTEM_ADMIN,
            type: 'Group',
            hasSeparator: true,
            sideHeading: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement($a2085cc62fde1196$export$b79014a8ad5d31bf, {
                    "aria-label": formatMessage((0, (0, parcelRequire("2f2jJ")).isMacOs)() ? (0, (0, parcelRequire("6lwNs")).default).jiraSettingsCommandPaletteMacScreenReader : (0, (0, parcelRequire("6lwNs")).default).jiraSettingsCommandPaletteOtherScreenReader)
                }, formatMessage((0, (0, parcelRequire("2f2jJ")).isMacOs)() ? (0, (0, parcelRequire("6lwNs")).default).jiraSettingsCommandPaletteMac : (0, (0, parcelRequire("6lwNs")).default).jiraSettingsCommandPaletteOther, {
                    strong: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
                            as: "strong",
                            size: "small",
                            color: "inherit"
                        }, chunks),
                    wrapper: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement($a2085cc62fde1196$var$CommandPaletteShortcutsContainer, null, chunks),
                    key: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement($a2085cc62fde1196$var$KeyboardShortcut, null, chunks),
                    command: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement($a2085cc62fde1196$var$KeyboardShortcut, {
                            isMeta: true
                        }, chunks)
                })),
            items: [
                {
                    goTo: 'root/global-settings/system',
                    id: (0, (0, parcelRequire("3JGmH")).ADMIN_SETTINGS_ITEM_IDS).SYSTEM,
                    legacyId: 'admin_system_menu',
                    href: '/jira/settings/system/general-configuration',
                    text: formatMessage((0, (0, parcelRequire("6lwNs")).default).system),
                    type: 'Item',
                    before: $a2085cc62fde1196$export$f3a5db2467c22913
                },
                {
                    goTo: 'root/global-settings/applications',
                    id: (0, (0, parcelRequire("3JGmH")).ADMIN_SETTINGS_ITEM_IDS).APPLICATIONS,
                    legacyId: 'admin_applications_menu',
                    href: $a2085cc62fde1196$var$getApplicationsMenuLink(appPermissions),
                    text: formatMessage((0, (0, parcelRequire("6lwNs")).default).applications),
                    type: 'Item',
                    before: $a2085cc62fde1196$export$3055c15ea575d46c
                },
                {
                    goTo: 'root/global-settings/projects',
                    id: (0, (0, parcelRequire("3JGmH")).ADMIN_SETTINGS_ITEM_IDS).MANAGE_PROJECTS,
                    legacyId: 'admin_project_menu',
                    href: '/jira/settings/projects/manage',
                    text: formatMessage((0, (0, parcelRequire("6lwNs")).default).projects),
                    type: 'Item',
                    before: $a2085cc62fde1196$export$43db94b81c32328a
                },
                {
                    goTo: 'root/global-settings/issues',
                    id: (0, (0, parcelRequire("3JGmH")).ADMIN_SETTINGS_ITEM_IDS).ISSUES,
                    legacyId: 'admin_issues_menu',
                    href: '/jira/settings/issues/issue-types',
                    text: formatMessage((0, (0, parcelRequire("6lwNs")).default).issues),
                    type: 'Item',
                    before: $a2085cc62fde1196$export$583f26efcd800ae2
                },
                ...!(isHideAppGlobalSettingForFedrampEnabled && (0, (0, parcelRequire("aIZdc")).isFedRamp)()) ? [
                    {
                        goTo: 'root/global-settings/addons',
                        id: (0, (0, parcelRequire("3JGmH")).ADMIN_SETTINGS_ITEM_IDS).PLUGINS,
                        legacyId: 'admin_plugins_menu',
                        href: (0, (0, parcelRequire("6awYZ")).getEmceeDiscoverLink)({
                            source: 'side_nav_find_new_addons'
                        }),
                        text: formatMessage((0, (0, parcelRequire("6lwNs")).default).addons),
                        type: 'Item',
                        before: $a2085cc62fde1196$export$627b4c7b9568cd5d
                    }
                ] : []
            ]
        });
    const $a2085cc62fde1196$var$getAdminSectionNew = (formatMessage, appPermissions, isHideAppGlobalSettingForFedrampEnabled = false)=>({
            id: (0, (0, parcelRequire("3JGmH")).JIRA_SETTINGS_SECTION_IDS).SYSTEM_ADMIN,
            type: 'Group',
            hasSeparator: true,
            heading: formatMessage((0, (0, parcelRequire("6lwNs")).default).jiraAdminSettingsNonFinal),
            items: [
                {
                    goTo: 'root/global-settings/system',
                    id: (0, (0, parcelRequire("3JGmH")).ADMIN_SETTINGS_ITEM_IDS).SYSTEM,
                    legacyId: 'admin_system_menu',
                    href: '/jira/settings/system/general-configuration',
                    text: formatMessage((0, (0, parcelRequire("6lwNs")).default).system),
                    type: 'Item',
                    before: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("9fIhE"))))), {
                            label: "",
                            color: "var(--ds-icon, #44546F)"
                        }),
                    description: formatMessage((0, (0, parcelRequire("6lwNs")).default).systemDescriptionNonFinal)
                },
                {
                    goTo: 'root/global-settings/applications',
                    id: (0, (0, parcelRequire("3JGmH")).ADMIN_SETTINGS_ITEM_IDS).APPLICATIONS,
                    legacyId: 'admin_applications_menu',
                    href: $a2085cc62fde1196$var$getApplicationsMenuLink(appPermissions),
                    text: formatMessage((0, (0, parcelRequire("6lwNs")).default).applications),
                    type: 'Item',
                    before: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("3fDjf"))))), {
                            label: "",
                            color: "var(--ds-icon, #44546F)"
                        }),
                    description: formatMessage((0, (0, parcelRequire("6lwNs")).default).productsDescriptionNonFinal)
                },
                {
                    goTo: 'root/global-settings/projects',
                    id: (0, (0, parcelRequire("3JGmH")).ADMIN_SETTINGS_ITEM_IDS).MANAGE_PROJECTS,
                    legacyId: 'admin_project_menu',
                    href: '/jira/settings/projects/manage',
                    text: formatMessage((0, (0, parcelRequire("6lwNs")).default).projects),
                    type: 'Item',
                    before: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("dXQDI"))))), {
                            label: "",
                            color: "var(--ds-icon, #44546F)"
                        }),
                    description: formatMessage((0, (0, parcelRequire("6lwNs")).default).projectsDescriptionNonFinal)
                },
                {
                    goTo: 'root/global-settings/issues',
                    id: (0, (0, parcelRequire("3JGmH")).ADMIN_SETTINGS_ITEM_IDS).ISSUES,
                    legacyId: 'admin_issues_menu',
                    href: '/jira/settings/issues/issue-types',
                    text: formatMessage((0, (0, parcelRequire("6lwNs")).default).issues),
                    type: 'Item',
                    before: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("gmyWi"))))), {
                            label: "",
                            color: "var(--ds-icon, #44546F)"
                        }),
                    description: formatMessage((0, (0, parcelRequire("6lwNs")).default).issuesDescriptionNonFinal)
                },
                ...!(isHideAppGlobalSettingForFedrampEnabled && (0, (0, parcelRequire("aIZdc")).isFedRamp)()) ? [
                    {
                        goTo: 'root/global-settings/addons',
                        id: (0, (0, parcelRequire("3JGmH")).ADMIN_SETTINGS_ITEM_IDS).PLUGINS,
                        legacyId: 'admin_plugins_menu',
                        href: (0, (0, parcelRequire("9Y7t8")).manageAppsRoute).path,
                        text: formatMessage((0, (0, parcelRequire("6lwNs")).default).addons),
                        type: 'Item',
                        before: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("1ZDWg"))))), {
                                label: "",
                                color: "var(--ds-icon, #44546F)"
                            }),
                        description: formatMessage((0, (0, parcelRequire("6lwNs")).default).appsDescriptionNonFinal)
                    }
                ] : [],
                {
                    id: 'keyboad-shortcut-tip',
                    type: 'Content',
                    content: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                            as: (0, (0, parcelRequire("4R6GH")).fg)('blu-4172-enghealth-13259-settings-a11y-fix') ? 'li' : 'div',
                            xcss: (0, (0, parcelRequire("4R6GH")).fg)('blu-4172-enghealth-13259-settings-a11y-fix') ? $a2085cc62fde1196$var$jiraCommandPalletteTipContainerStyles : $a2085cc62fde1196$var$oldJiraCommandPalletteTipContainerStyles,
                            "aria-label": formatMessage((0, (0, parcelRequire("2f2jJ")).isMacOs)() ? (0, (0, parcelRequire("6lwNs")).default).jiraSettingsCommandPaletteMacScreenReader : (0, (0, parcelRequire("6lwNs")).default).jiraSettingsCommandPaletteOtherScreenReader)
                        }, formatMessage((0, (0, parcelRequire("2f2jJ")).isMacOs)() ? (0, (0, parcelRequire("6lwNs")).default).jiraSettingsCommandPaletteMac : (0, (0, parcelRequire("6lwNs")).default).jiraSettingsCommandPaletteOther, {
                            strong: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
                                    size: "small"
                                }, chunks),
                            wrapper: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                                    xcss: $a2085cc62fde1196$var$commandPalleteShortcutStyles
                                }, chunks),
                            key: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kgoHi")).default), {
                                    alignItems: "center",
                                    justifyContent: "center",
                                    xcss: $a2085cc62fde1196$var$keyboardShortcutStyles
                                }, chunks),
                            command: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kgoHi")).default), {
                                    alignItems: "center",
                                    justifyContent: "center",
                                    xcss: $a2085cc62fde1196$var$keyboardShortcutStyles
                                }, chunks)
                        }))
                }
            ]
        });
    const $a2085cc62fde1196$export$8c65af5cbebd63ff = (formatMessage, isSiteAdmin, canManageUsers, isHideBillingGlobalSettingForFedrampAdminsEnabled = false)=>({
            id: (0, (0, parcelRequire("3JGmH")).JIRA_SETTINGS_SECTION_IDS).SITE_ADMIN,
            heading: formatMessage((0, (0, parcelRequire("6lwNs")).default).atlassianAdminSettingsNonFinal),
            type: 'Group',
            hasSeparator: false,
            items: [
                ...canManageUsers || isSiteAdmin ? [
                    {
                        id: (0, (0, parcelRequire("3JGmH")).SITE_ADMIN_SETTINGS_ITEM_IDS).USER_MANAGEMENT,
                        legacyId: 'user_management_section',
                        href: (0, (0, parcelRequire("3JGmH")).SITE_ADMIN_SETTINGS_ITEM_HREFS).USER_MANAGEMENT,
                        text: formatMessage((0, (0, parcelRequire("6lwNs")).default).userManagement),
                        type: 'Item',
                        before: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("c781K"))))), {
                                label: "",
                                color: "var(--ds-icon, #44546F)"
                            }),
                        after: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("ggICg"))))), {
                                label: "",
                                color: "var(--ds-icon, #44546F)"
                            }),
                        target: '_blank',
                        description: formatMessage((0, (0, parcelRequire("6lwNs")).default).userManagementDescriptionNonFinal)
                    }
                ] : [],
                ...isSiteAdmin && !(isHideBillingGlobalSettingForFedrampAdminsEnabled && (0, (0, parcelRequire("aIZdc")).isFedRamp)()) ? [
                    {
                        id: (0, (0, parcelRequire("3JGmH")).SITE_ADMIN_SETTINGS_ITEM_IDS).BILLING,
                        legacyId: 'admin_billing_section',
                        href: (0, (0, parcelRequire("3JGmH")).SITE_ADMIN_SETTINGS_ITEM_HREFS).BILLING,
                        text: formatMessage((0, (0, parcelRequire("6lwNs")).default).billing),
                        type: 'Item',
                        before: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("yJt0Y"))))), {
                                label: "",
                                color: "var(--ds-icon, #44546F)"
                            }),
                        after: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("ggICg"))))), {
                                label: "",
                                color: "var(--ds-icon, #44546F)"
                            }),
                        target: '_blank',
                        description: formatMessage((0, (0, parcelRequire("6lwNs")).default).billingDescriptionNonFinal)
                    }
                ] : []
            ]
        });
    const $a2085cc62fde1196$export$ed6905715e0eb76 = (formatMessage, isSiteAdmin, canManageUsers, isHideBillingGlobalSettingForFedrampAdminsEnabled = false)=>({
            id: (0, (0, parcelRequire("3JGmH")).JIRA_SETTINGS_SECTION_IDS).SITE_ADMIN,
            type: 'Group',
            hasSeparator: false,
            items: [
                ...canManageUsers || isSiteAdmin ? [
                    {
                        id: (0, (0, parcelRequire("3JGmH")).SITE_ADMIN_SETTINGS_ITEM_IDS).USER_MANAGEMENT,
                        legacyId: 'user_management_section',
                        href: (0, (0, parcelRequire("3JGmH")).SITE_ADMIN_SETTINGS_ITEM_HREFS).USER_MANAGEMENT,
                        text: formatMessage((0, (0, parcelRequire("6lwNs")).default).userManagement),
                        type: 'Item',
                        before: $a2085cc62fde1196$export$4464648b9631e67f,
                        after: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("17Ngn"))))), {
                                size: "small"
                            }),
                        target: '_blank'
                    }
                ] : [],
                ...isSiteAdmin && !(isHideBillingGlobalSettingForFedrampAdminsEnabled && (0, (0, parcelRequire("aIZdc")).isFedRamp)()) ? [
                    {
                        id: (0, (0, parcelRequire("3JGmH")).SITE_ADMIN_SETTINGS_ITEM_IDS).BILLING,
                        legacyId: 'admin_billing_section',
                        href: (0, (0, parcelRequire("3JGmH")).SITE_ADMIN_SETTINGS_ITEM_HREFS).BILLING,
                        text: formatMessage((0, (0, parcelRequire("6lwNs")).default).billing),
                        type: 'Item',
                        before: $a2085cc62fde1196$export$7b659dc9c22493b,
                        after: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("17Ngn"))))), {
                                size: "small"
                            }),
                        target: '_blank'
                    }
                ] : []
            ]
        });
    const $a2085cc62fde1196$export$d637bf5a394b4534 = (formatMessage, appPermissions, userRoles, willShowNav4)=>{
        const { isAdmin: isAdmin, isSiteAdmin: isSiteAdmin, manageUsersPermission: manageUsersPermission } = userRoles;
        const result = [];
        if (isAdmin) {
            const getAdminSection = willShowNav4 ? $a2085cc62fde1196$var$getAdminSectionNew : $a2085cc62fde1196$var$getAdminSectionOld;
            result.push(getAdminSection(formatMessage, appPermissions, (0, (0, parcelRequire("4R6GH")).fg)('nin_hide_apps_in_global_settings_for_fedramp')));
        }
        if (isSiteAdmin || manageUsersPermission.loading === false && manageUsersPermission.canManageUsers) {
            const getSiteAdminSection = willShowNav4 ? $a2085cc62fde1196$export$8c65af5cbebd63ff : $a2085cc62fde1196$export$ed6905715e0eb76;
            result.push(getSiteAdminSection(formatMessage, userRoles.isSiteAdmin, manageUsersPermission.canManageUsers, (0, (0, parcelRequire("4R6GH")).fg)('nin_hide_billing_global_setting_for_fedramp_admins')));
        }
        return result;
    };
    const $a2085cc62fde1196$export$be58b4326e23250f = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { dark: dark, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c116y _4cvr1h6o _1bah1h6o _4t3i1tcg _1bsb1tcg _2rko1l7b",
                __cmplp.dark ? "_11q7gkf6" : "_11q71pom",
                __cmplp.className
            ])
        });
    });
    const $a2085cc62fde1196$var$CommandPaletteShortcutsContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c116y _zulpv77o",
                __cmplp.className
            ])
        });
    });
    const $a2085cc62fde1196$var$KeyboardShortcut = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("dzeHJ")).FilledLozenge), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1ule _1bsb1wug _1ul9dlk8 _4t3idlk8 _16jlidpf _vwz4wk7l _y3gn1h6o _1yt41nxw _vchhusvi _1p1dglyw",
                __cmplp.isMeta ? "_1wyb1bt6" : "_1wybc4v4",
                __cmplp.className
            ])
        });
    });
    const $a2085cc62fde1196$export$b79014a8ad5d31bf = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_11c81vlj _k48p1nn1 _syaz1ddj _1p1dglyw",
                __cmplp.className
            ])
        });
    });
    const $a2085cc62fde1196$var$commandPalleteShortcutStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'inline-flex',
        gap: 'space.025'
    });
    const $a2085cc62fde1196$var$oldJiraCommandPalletteTipContainerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        font: "var(--ds-font-body-small, normal 400 11px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
        marginInlineStart: 'space.200',
        marginBlock: 'space.200',
        color: 'color.text.subtle'
    });
    const $a2085cc62fde1196$var$jiraCommandPalletteTipContainerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        font: "var(--ds-font-body-small, normal 400 11px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
        marginInlineStart: 'space.200',
        marginBlock: 'space.200',
        color: 'color.text.subtle',
        listStyleType: 'none'
    });
    const $a2085cc62fde1196$var$keyboardShortcutStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        minWidth: '14px',
        height: '14px',
        backgroundColor: 'color.background.accent.blue.subtlest',
        color: 'color.text.subtle',
        padding: 'space.025',
        borderRadius: 'border.radius',
        font: "var(--ds-font-body-small, normal 400 11px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
    });
});
parcelRegister("dzeHJ", function(module, exports) {
    $parcel$export(module.exports, "OutlinedLozenge", ()=>$0f9445e21ac061f1$export$224f9ad7f31e0de6);
    $parcel$export(module.exports, "FilledLozenge", ()=>$0f9445e21ac061f1$export$586a428fe10f5ae0);
    $parcel$export(module.exports, "SearchLozenge", ()=>$0f9445e21ac061f1$export$2ccc7861eeb7b337);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $0f9445e21ac061f1$var$Lozenge = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o _1bah1h6o _2rko1l7b _1o9zidpf _4t3igktf _1bsbgktf _11c8qk37 _vwz41crf _syaz1aqp",
                __cmplp.className
            ])
        });
    });
    const $0f9445e21ac061f1$export$224f9ad7f31e0de6 = (0, $5uXOq.forwardRef)(({ as: C = $0f9445e21ac061f1$var$Lozenge, style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_11c81u0j _k48p1pd9 _1p1dangw _bfhk1j9a _19it1x8r _syazuxqy _vchhusvi",
                __cmplp.className
            ])
        });
    });
    const $0f9445e21ac061f1$export$586a428fe10f5ae0 = (0, $5uXOq.forwardRef)(({ as: C = $0f9445e21ac061f1$var$Lozenge, style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswh2mm _11c81u0j _1p1dangw _1pbykb7n _1cs8stnw _1kt9b3bt _1s6k1d2d _154psxj9 _rfx31l7b _1aukidpf _19woidpf _oounidpf _qnecidpf _1g0v1mrw",
                __cmplp.className
            ])
        });
    });
    const $0f9445e21ac061f1$export$2ccc7861eeb7b337 = (0, $5uXOq.forwardRef)(({ as: C = $0f9445e21ac061f1$var$Lozenge, style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1yt4otz2 _1bsb1ris _11c81oud _vwz4dlk8 _bfhk1yrq",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("3JGmH", function(module, exports) {
    $parcel$export(module.exports, "JIRA_SETTINGS_SECTION_IDS", ()=>$421ed2d9886b9bc8$export$7266225366740ba0);
    $parcel$export(module.exports, "ADMIN_SETTINGS_ITEM_IDS", ()=>$421ed2d9886b9bc8$export$4718c8ac53a477e1);
    $parcel$export(module.exports, "SITE_ADMIN_SETTINGS_ITEM_IDS", ()=>$421ed2d9886b9bc8$export$b23679adb37958a8);
    $parcel$export(module.exports, "SITE_ADMIN_SETTINGS_ITEM_HREFS", ()=>$421ed2d9886b9bc8$export$9cc74e3c0b8831f9);
    const $421ed2d9886b9bc8$export$7266225366740ba0 = {
        SETTINGS_TITLE: 'settings-title',
        SITE_ADMIN: 'site-admin',
        SYSTEM_ADMIN: 'system-admin',
        TRUSTED_USER: 'trusted-user',
        PERSONAL: 'personal-settings'
    };
    const $421ed2d9886b9bc8$export$4718c8ac53a477e1 = {
        SYSTEM: 'admin-system-menu',
        APPLICATIONS: 'admin-applications-menu',
        ISSUES: 'admin-issues-menu',
        PLUGINS: 'admin-plugins-menu',
        RECOMMENDATIONS: 'admin-recommendations',
        MANAGE_PROJECTS: 'admin-manage-project-menu'
    };
    const $421ed2d9886b9bc8$export$b23679adb37958a8 = {
        USER_MANAGEMENT: 'user-management-section',
        BILLING: 'admin-billing-section'
    };
    const $421ed2d9886b9bc8$export$58a409d1d7fded14 = {
        USER_MANAGEMENT: 'trusted-admin-user-management-section',
        DISCOVER_APPLICATIONS: 'discover-applications-section'
    };
    const $421ed2d9886b9bc8$export$9cc74e3c0b8831f9 = {
        USER_MANAGEMENT: '/admin/users?referrerName=JIRA',
        BILLING: '/admin/billing-preview?referrerName=JIRA'
    };
});
parcelRegister("6lwNs", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9f568e348dc45627$export$2e2bcd8739ae039);
    var $7VHMR;
    var $9f568e348dc45627$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        settings: {
            "id": "navigation-next.navigation-views.root.common.settings",
            "defaultMessage": "Jira settings"
        },
        system: {
            "id": "navigation-next.navigation-views.root.global-settings.home.system",
            "defaultMessage": "System"
        },
        applications: {
            "id": "navigation-next.navigation-views.root.global-settings.home.applications",
            "defaultMessage": "Products"
        },
        projects: {
            "id": "navigation-next.navigation-views.root.global-settings.home.projects",
            "defaultMessage": "Projects"
        },
        issues: {
            "id": "navigation-next.navigation-views.root.global-settings.home.issues",
            "defaultMessage": "Issues"
        },
        addons: {
            "id": "navigation-next.navigation-views.root.global-settings.home.addons",
            "defaultMessage": "Apps"
        },
        recommendations: {
            "id": "navigation-next.navigation-views.root.global-settings.home.recommendations",
            "defaultMessage": "Recommendations"
        },
        userManagement: {
            "id": "navigation-next.navigation-views.root.global-settings.home.user-management",
            "defaultMessage": "User management"
        },
        billing: {
            "id": "navigation-next.navigation-views.root.global-settings.home.billing",
            "defaultMessage": "Billing"
        },
        discoverApplications: {
            "id": "navigation-next.navigation-views.root.global-settings.home.discover-applications",
            "defaultMessage": "Discover applications"
        },
        jiraSettingsCommandPaletteMac: {
            "id": "navigation-next.navigation-views.root.global-settings.home.jira-settings-command-palette-mac",
            "defaultMessage": "<strong>Tip:</strong> Try <wrapper><command>\u2318</command><key>K</key></wrapper> to search Jira settings"
        },
        jiraSettingsCommandPaletteOther: {
            "id": "navigation-next.navigation-views.root.global-settings.home.jira-settings-command-palette-other",
            "defaultMessage": "<strong>Tip:</strong> Try <wrapper><key>Ctrl</key><key>K</key></wrapper> to search Jira settings"
        },
        jiraSettingsCommandPaletteMacScreenReader: {
            "id": "navigation-next.navigation-views.root.global-settings.home.jira-settings-command-palette-mac-screen-reader",
            "defaultMessage": "Tip: Try command K to search Jira settings"
        },
        jiraSettingsCommandPaletteOtherScreenReader: {
            "id": "navigation-next.navigation-views.root.global-settings.home.jira-settings-command-palette-other-screen-reader",
            "defaultMessage": "Tip: Try control K to search Jira settings"
        },
        'site-admin': {
            "id": "navigation-apps.settings-drawer.section.site-admin",
            "defaultMessage": "Atlassian admin"
        },
        'system-admin': {
            "id": "navigation-apps.settings-drawer.section.system-admin",
            "defaultMessage": "Jira settings"
        },
        'trusted-user': {
            "id": "navigation-apps.settings-drawer.section.trusted-user",
            "defaultMessage": "Atlassian admin"
        },
        'personal-settings': {
            "id": "navigation-apps.settings-drawer.section.personal-settings",
            "defaultMessage": "Personal settings"
        },
        'admin-system-menu': {
            "id": "navigation-apps.settings-drawer.section.item.admin-system-menu",
            "defaultMessage": "Manage your general configuration, global permissions, look and feel and more."
        },
        'admin-applications-menu': {
            "id": "navigation-apps.settings-drawer.section.item.admin-applications-menu",
            "defaultMessage": "Manage your Jira products' settings and integrations."
        },
        'admin-manage-project-menu': {
            "id": "navigation-apps.settings-drawer.section.item.admin-manage-project-menu",
            "defaultMessage": "Manage your project settings, categories, and more."
        },
        'admin-issues-menu': {
            "id": "navigation-apps.settings-drawer.section.item.admin-issues-menu",
            "defaultMessage": "Configure your issue types, workflows, screens, custom fields and more."
        },
        'admin-plugins-menu': {
            "id": "navigation-apps.settings-drawer.section.item.admin-plugins-menu",
            "defaultMessage": "Add and manage Jira Marketplace apps."
        },
        'user-management-section': {
            "id": "navigation-apps.settings-drawer.section.item.user-management-section",
            "defaultMessage": "Add users, groups, and manage access requests."
        },
        'admin-billing-section': {
            "id": "navigation-apps.settings-drawer.section.item.admin-billing-section",
            "defaultMessage": "Update your billing details, manage your subscriptions and more."
        },
        'trusted-admin-user-management-section': {
            "id": "navigation-apps.settings-drawer.section.item.trusted-admin-user-management-section",
            "defaultMessage": "Invite users to your site."
        },
        'discover-applications-section': {
            "id": "navigation-apps.settings-drawer.section.item.discover-applications-section",
            "defaultMessage": "Try more Atlassian products."
        },
        personalJiraSettingsNonFinal: {
            "id": "atlassian-navigation-nav4.common.settings-content.personal-jira-settings-non-final",
            "defaultMessage": "Personal Jira settings"
        },
        jiraAdminSettingsNonFinal: {
            "id": "atlassian-navigation-nav4.common.settings-content.jira-admin-settings-non-final",
            "defaultMessage": "Jira admin settings"
        },
        systemDescriptionNonFinal: {
            "id": "atlassian-navigation-nav4.common.settings-content.system-description-non-final",
            "defaultMessage": "Manage general configuration, security, automation, user interface, and more"
        },
        productsDescriptionNonFinal: {
            "id": "atlassian-navigation-nav4.common.settings-content.products-description-non-final",
            "defaultMessage": "Manage Jira products' access, settings, and integrations"
        },
        projectsDescriptionNonFinal: {
            "id": "atlassian-navigation-nav4.common.settings-content.projects-description-non-final",
            "defaultMessage": "Manage project settings, categories, and more"
        },
        issuesDescriptionNonFinal: {
            "id": "atlassian-navigation-nav4.common.settings-content.issues-description-non-final",
            "defaultMessage": "Configure issue types, workflows, screens, fields, and more"
        },
        appsDescriptionNonFinal: {
            "id": "atlassian-navigation-nav4.common.settings-content.apps-description-non-final",
            "defaultMessage": "Add and manage Jira Marketplace apps and integrations"
        },
        atlassianAdminSettingsNonFinal: {
            "id": "atlassian-navigation-nav4.common.settings-content.atlassian-admin-settings-non-final",
            "defaultMessage": "Atlassian admin settings"
        },
        userManagementDescriptionNonFinal: {
            "id": "atlassian-navigation-nav4.common.settings-content.user-management-description-non-final",
            "defaultMessage": "Manage users, groups, and access requests"
        },
        billingDescriptionNonFinal: {
            "id": "atlassian-navigation-nav4.common.settings-content.billing-description-non-final",
            "defaultMessage": "Update your billing details, manage subscriptions, and more"
        }
    });
});
parcelRegister("jNHMi", function(module, exports) {
    $parcel$export(module.exports, "useManageUsersPermission", ()=>$723fd3490632416b$export$d156f64dc61f3ce9);
    var $5uXOq = parcelRequire("5uXOq");
    var $jcw0u;
    var $eusud;
    var $14XJw;
    var $90yGq;
    var $cNd4s;
    var $3sQ5x;
    var $iXqE5;
    const $723fd3490632416b$var$initialState = {
        loading: true,
        canManageUsers: undefined
    };
    const $723fd3490632416b$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: $723fd3490632416b$var$initialState,
        actions: {
            fetch: ({ orgId: orgId, accountId: accountId, isSiteAdmin: isSiteAdmin })=>async ({ setState: setState, getState: getState })=>{
                    if (getState().canManageUsers !== undefined) return;
                    if (accountId == null || orgId == null) {
                        setState({
                            loading: false,
                            canManageUsers: false
                        });
                        return;
                    }
                    if (isSiteAdmin) {
                        setState({
                            loading: false,
                            canManageUsers: true
                        });
                        return;
                    }
                    setState({
                        loading: true
                    });
                    try {
                        const { permitted: permitted } = await (0, (0, parcelRequire("eusud")).performPostRequest)('/gateway/api/permissions/permitted', {
                            body: JSON.stringify({
                                resourceId: `ari:cloud:platform::org/${orgId}`,
                                principalId: `ari:cloud:identity::user/${accountId}`,
                                permissionId: 'manage-users'
                            })
                        });
                        setState({
                            loading: false,
                            canManageUsers: permitted
                        });
                    } catch (error) {
                        setState({
                            loading: false,
                            canManageUsers: false
                        });
                        (0, (0, parcelRequire("jcw0u")).default)({
                            meta: {
                                id: 'fetchManageUsersPermission',
                                packageName: 'jiraAtlassianNavigation',
                                teamName: 'navigation'
                            },
                            error: error,
                            sendToPrivacyUnsafeSplunk: true
                        });
                    }
                }
        },
        name: 'ManageUsersPermissionInternal'
    });
    const $723fd3490632416b$var$useManageUsersPermissionInternal = (0, (0, parcelRequire("3sQ5x")).createHook)($723fd3490632416b$var$Store);
    const $723fd3490632416b$export$d156f64dc61f3ce9 = ()=>{
        const { data: orgId } = (0, (0, parcelRequire("14XJw")).useOrgId)();
        const accountId = (0, (0, parcelRequire("90yGq")).useAccountId)();
        const isSiteAdmin = (0, (0, parcelRequire("cNd4s")).useIsSiteAdmin)();
        const [state, { fetch: fetch }] = $723fd3490632416b$var$useManageUsersPermissionInternal();
        (0, $5uXOq.useEffect)(()=>{
            fetch({
                accountId: accountId,
                orgId: orgId,
                isSiteAdmin: isSiteAdmin
            });
        }, [
            fetch,
            accountId,
            orgId,
            isSiteAdmin
        ]);
        return state;
    };
});
parcelRegister("e57bL", function(module, exports) {
    $parcel$export(module.exports, "Home", ()=>$f8508cd3b3bbf491$export$b94310965bed44e6);
    var $5uXOq = parcelRequire("5uXOq");
    var $9UCCQ;
    var $6lwNs;
    var $koVbs;
    var $6cNb0;
    var $3HwlS;
    var $d4B4Y;
    var $7ilb8;
    var $4drrb;
    const $f8508cd3b3bbf491$export$b94310965bed44e6 = ({ messages: messages = (0, (0, parcelRequire("6lwNs")).default), settingsDrawerItems: settingsDrawerItems, testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        if ((0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, settingsDrawerItems.map((group)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
                key: group.id,
                testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, group.id)
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4drrb")).HomeHeading), {
                group: group,
                messages: messages,
                testIdPrefix: testIdPrefix
            }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuList), null, group.items && group.items.map((item, idx)=>{
                if (item.type === 'Content' && item.content) return item.content({
                    update: ()=>Promise.resolve(null),
                    isOpen: false,
                    setInitialFocusRef: ()=>null
                });
                if (item.href === null || item.href === undefined) return null;
                if (item.text === null || item.text === undefined) return null;
                return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
                    key: item.id,
                    ariaLabel: formatMessage(messages[group.id])
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
                    analytics: {
                        actionSubjectId: 'settingsNavigationMenuItem',
                        menuItemId: item.id,
                        menuItemType: group.id === 'site-admin' ? 'adminSettings' : 'jiraSettings',
                        position: idx
                    },
                    iconBefore: item.before ? (0, ($parcel$interopDefault($5uXOq))).createElement(item.before, null) : null,
                    iconAfter: item.after ? (0, ($parcel$interopDefault($5uXOq))).createElement(item.after, {
                        isHover: true
                    }) : null,
                    href: item.href,
                    target: item.target,
                    testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, group.id, `item-${item.id}`),
                    description: item.description || ''
                }, item.text || ''));
            })))));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, settingsDrawerItems.map((group)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
                key: group.id,
                testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, group.id)
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4drrb")).HomeHeading), {
                group: group,
                messages: messages,
                testIdPrefix: testIdPrefix
            }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuList), null, group.items && group.items.map((item, idx)=>{
                if (item.href === null || item.href === undefined) return null;
                if (item.text === null || item.text === undefined) return null;
                return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
                    key: item.id,
                    ariaLabel: formatMessage(messages[group.id])
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
                    analytics: {
                        actionSubjectId: 'settingsNavigationMenuItem',
                        menuItemId: item.id,
                        menuItemType: group.id === 'site-admin' ? 'adminSettings' : 'jiraSettings',
                        position: idx
                    },
                    iconBefore: item.before ? (0, ($parcel$interopDefault($5uXOq))).createElement(item.before, null) : null,
                    iconAfter: item.after ? (0, ($parcel$interopDefault($5uXOq))).createElement(item.after, {
                        isHover: true
                    }) : null,
                    href: item.href,
                    target: item.target,
                    testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, group.id, `item-${item.id}`),
                    description: messages[item.id] ? formatMessage(messages[item.id]) : undefined
                }, item.text || ''));
            })))));
    };
});
parcelRegister("4drrb", function(module, exports) {
    $parcel$export(module.exports, "HomeHeading", ()=>$fd56d3368a0677f4$export$e2344e30272a44c1);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $4mNf3;
    var $koVbs;
    var $6cNb0;
    var $3HwlS;
    const $fd56d3368a0677f4$export$e2344e30272a44c1 = ({ group: group, messages: messages, testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const headingMessage = (0, $5uXOq.useMemo)(()=>formatMessage(messages[group.id]), [
            formatMessage,
            group.id,
            messages
        ]);
        if ((0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) return (0, ($parcel$interopDefault($5uXOq))).createElement("p", {
            "data-testid": (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, group.id, 'heading'),
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_11c8qk37 _syaz131l _k48p1pd9 _ahbqpxbi _19pku2gc _otyr12x7"
            ])
        }, group.heading || '');
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), {
            headingLevel: 3,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, group.id, 'heading')
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($fd56d3368a0677f4$var$HeadingContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement("span", null, headingMessage), !!group.sideHeading && (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            "data-testid": (0, $f524727bc2d3810c$export$f707547fc95f31c2)
        }, group.sideHeading())));
    };
    const $fd56d3368a0677f4$var$HeadingContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o _1bah1yb4",
                __cmplp.className
            ])
        });
    });
    const $fd56d3368a0677f4$var$sectionHeadingStyles = null;
});
const $f524727bc2d3810c$export$f707547fc95f31c2 = 'atlassian-navigation.ui.settings.menu.home.heading.side-heading';
parcelRegister("asDBE", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6e49dd60f5b63059$export$2e2bcd8739ae039);
    var $7VHMR;
    var $6e49dd60f5b63059$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        settings: {
            "id": "atlassian-navigation.settings.menu.settings",
            "defaultMessage": "Settings"
        }
    });
});
parcelRegister("6svfi", function(module, exports) {
    $parcel$export(module.exports, "Personal", ()=>$3dfa9666dd9cff1a$export$3965927bf7a81700);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $jDorM;
    var $1bKdn;
    var $23aI7;
    var $3B33y;
    var $17Ngn;
    var $4mNf3;
    var $9UCCQ;
    var $3JGmH;
    var $5U49F;
    var $6lwNs;
    var $koVbs;
    var $2EqxS;
    var $6cNb0;
    var $3HwlS;
    var $1C2yb;
    var $cYlk7;
    var $cojyC;
    var $d4B4Y;
    var $7ilb8;
    var $6zdS6;
    const $3dfa9666dd9cff1a$export$3965927bf7a81700 = ({ testIdPrefix: testIdPrefix })=>{
        const { data: data } = (0, (0, parcelRequire("cYlk7")).useCurrentUser)();
        const email = data.user?.emailAddress;
        const atlassianPersonalSettingsLink = (0, (0, parcelRequire("2EqxS")).useAtlassianPersonalSettingsLink)(email);
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'personal-settings');
        if ((0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            testId: testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("p", {
            "data-testid": (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'heading'),
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_11c8qk37 _syaz131l _k48p1pd9 _ahbqpxbi _19pku2gc _otyr12x7"
            ])
        }, formatMessage((0, (0, parcelRequire("6lwNs")).default).personalJiraSettingsNonFinal)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuList), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
            ariaLabel: formatMessage((0, (0, parcelRequire("6lwNs")).default).personalJiraSettingsNonFinal)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'settingsNavigationMenuItem',
                menuItemId: 'personal-jira-settings',
                menuItemType: 'personalSettings',
                position: 0
            },
            description: formatMessage((0, (0, parcelRequire("6zdS6")).default).generalSettingsDescriptionNonFinal),
            href: (0, (0, parcelRequire("1C2yb")).getGeneralPersonalSettingsPath)(),
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("1bKdn"))))), {
                label: "",
                color: "var(--ds-icon, #44546F)"
            }),
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-personal-jira-settings'),
            to: (0, (0, parcelRequire("cojyC")).getGeneralPersonalSettingsRouteForLink)()
        }, formatMessage((0, (0, parcelRequire("6zdS6")).default).generalSettingsLabelNonFinal))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
            ariaLabel: formatMessage((0, (0, parcelRequire("6lwNs")).default).personalJiraSettingsNonFinal)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'settingsNavigationMenuItem',
                menuItemId: 'account-settings',
                menuItemType: 'personalSettings',
                position: 1
            },
            description: formatMessage((0, (0, parcelRequire("6zdS6")).default).notificationSettingsDescriptionNonFinal),
            href: "/jira/settings/personal/notifications",
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("jDorM"))))), {
                label: "",
                color: "var(--ds-icon, #44546F)"
            }),
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-account-settings')
        }, formatMessage((0, (0, parcelRequire("6zdS6")).default).notificationSettingsLabelNonFinal)))));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            testId: testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4mNf3")).default), {
            headingLevel: 3,
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'heading')
        }, formatMessage((0, (0, parcelRequire("6lwNs")).default)[(0, (0, parcelRequire("3JGmH")).JIRA_SETTINGS_SECTION_IDS).PERSONAL])), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuList), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
            ariaLabel: formatMessage((0, (0, parcelRequire("6lwNs")).default)[(0, (0, parcelRequire("3JGmH")).JIRA_SETTINGS_SECTION_IDS).PERSONAL])
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'settingsNavigationMenuItem',
                menuItemId: 'account-settings',
                menuItemType: 'personalSettings',
                position: 0
            },
            description: formatMessage((0, (0, parcelRequire("6zdS6")).default).accountSettingsDescription),
            href: atlassianPersonalSettingsLink,
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5U49F")).IconWrapper), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("23aI7"))))), {
                size: "small",
                primaryColor: "var(--ds-icon-inverse, #FFFFFF)",
                label: ""
            })),
            iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("17Ngn"))))), {
                size: "small",
                label: ""
            }),
            target: "_blank",
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-account-settings')
        }, formatMessage((0, (0, parcelRequire("6zdS6")).default).accountSettings))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ilb8")).MenuListItem), {
            ariaLabel: formatMessage((0, (0, parcelRequire("6lwNs")).default)[(0, (0, parcelRequire("3JGmH")).JIRA_SETTINGS_SECTION_IDS).PERSONAL])
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d4B4Y")).MenuItem), {
            analytics: {
                actionSubjectId: 'settingsNavigationMenuItem',
                menuItemId: 'personal-jira-settings',
                menuItemType: 'personalSettings',
                position: 1
            },
            description: formatMessage((0, (0, parcelRequire("6zdS6")).default).personalJiraSettingsDescription),
            href: (0, (0, parcelRequire("1C2yb")).getGeneralPersonalSettingsPath)(),
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5U49F")).IconWrapper), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("3B33y"))))), {
                size: "small",
                primaryColor: "var(--ds-icon-inverse, #FFFFFF)",
                label: ""
            })),
            testId: (0, (0, parcelRequire("6cNb0")).testIdConcat)(testId, 'item-personal-jira-settings'),
            to: (0, (0, parcelRequire("cojyC")).getGeneralPersonalSettingsRouteForLink)()
        }, formatMessage((0, (0, parcelRequire("6zdS6")).default).personalJiraSettings)))));
    };
    const $3dfa9666dd9cff1a$var$sectionHeadingStyles = null;
});
parcelRegister("6zdS6", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$73a9428fb9b2a405$export$2e2bcd8739ae039);
    var $7VHMR;
    var $73a9428fb9b2a405$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        accountSettings: {
            "id": "atlassian-navigation.settings.menu.personal.account-settings",
            "defaultMessage": "Atlassian account settings"
        },
        accountSettingsDescription: {
            "id": "atlassian-navigation.settings.menu.personal.account-settings-description",
            "defaultMessage": "Manage your language, time zone, and other profile information."
        },
        personalJiraSettings: {
            "id": "atlassian-navigation.settings.menu.personal.personal-jira-settings",
            "defaultMessage": "Personal Jira settings"
        },
        personalJiraSettingsDescription: {
            "id": "atlassian-navigation.settings.menu.personal.personal-jira-settings-description",
            "defaultMessage": "Manage your email notifications and other Jira settings."
        },
        generalSettingsLabelNonFinal: {
            "id": "atlassian-navigation.settings.menu.personal.general-settings-label-non-final",
            "defaultMessage": "General settings"
        },
        generalSettingsDescriptionNonFinal: {
            "id": "atlassian-navigation.settings.menu.personal.general-settings-description-non-final",
            "defaultMessage": "Manage language, time zone, and other personal preferences"
        },
        notificationSettingsLabelNonFinal: {
            "id": "atlassian-navigation.settings.menu.personal.notification-settings-label-non-final",
            "defaultMessage": "Notification settings"
        },
        notificationSettingsDescriptionNonFinal: {
            "id": "atlassian-navigation.settings.menu.personal.notification-settings-description-non-final",
            "defaultMessage": "Manage email and in-product notifications from Jira"
        }
    });
});
parcelRegister("b59xm", function(module, exports) {
    $parcel$export(module.exports, "Warning", ()=>$d53d46adbad8e7db$export$c688898c4e3580ee);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $e4yMr;
    var $9UCCQ;
    var $ousew;
    var $aWse0;
    var $6cNb0;
    var $8Vm26;
    const $d53d46adbad8e7db$export$c688898c4e3580ee = ({ testIdPrefix: testIdPrefix })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("aWse0")).useIntlV2)();
        const testId = (0, (0, parcelRequire("6cNb0")).testIdConcat)(testIdPrefix, 'warning');
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            testId: testId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($d53d46adbad8e7db$export$e6dfd72ab29f8598, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), {
            ...(0, (0, parcelRequire("8Vm26")).default).noAdminPermissionMessage,
            values: {
                contact: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("e4yMr")).default), {
                    href: "/secure/ContactAdministrators!default.jspa"
                }, formatMessage((0, (0, parcelRequire("8Vm26")).default).noAdminPermissionMessageContact))
            }
        })));
    };
    const $d53d46adbad8e7db$export$e6dfd72ab29f8598 = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement($d53d46adbad8e7db$var$Outer, null, (0, ($parcel$interopDefault($5uXOq))).createElement($d53d46adbad8e7db$var$Inner, null, children));
    const $d53d46adbad8e7db$var$Outer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1yt4ev0u",
                __cmplp.className
            ])
        });
    });
    const $d53d46adbad8e7db$var$Inner = (0, $5uXOq.forwardRef)(({ as: C = "p", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_p12f1kdj _bfhk8jms _2rko1l7b _1yt4pxbi",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("8Vm26", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ed016ce81d29e02d$export$2e2bcd8739ae039);
    var $7VHMR;
    var $ed016ce81d29e02d$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        noAdminPermissionMessage: {
            "id": "atlassian-navigation.settings.menu.warning.no-admin-permission-message",
            "defaultMessage": "You do not have access to Jira settings or Atlassian admin, {contact} to grant you access."
        },
        noAdminPermissionMessageContact: {
            "id": "atlassian-navigation.settings.menu.warning.no-admin-permission-message-contact",
            "defaultMessage": "contact your Jira admin"
        }
    });
});
parcelRegister("2YzXO", function(module, exports) {
    $parcel$export(module.exports, "SignIn", ()=>$ecced68b9b5eee26$export$b64708c6f1b3ed96);
    var $5uXOq = parcelRequire("5uXOq");
    var $35I4u;
    var $krXdA;
    var $pkYLA;
    var $4R6GH;
    var $aWse0;
    var $3HwlS;
    var $cZpsT;
    var $hDzhb;
    var $Mp6qH;
    var $4uXxK;
    var $iPj86;
    var $avqpn;
    var $jZF14;
    const $ecced68b9b5eee26$var$SignIn = ({ AkSignIn: AkSignIn = (0, (0, parcelRequire("35I4u")).SignIn), Link: Link = (0, (0, parcelRequire("hDzhb")).default), useDestinationParam: useDestinationParam = (0, (0, parcelRequire("jZF14")).useDestinationParam), useIntl: useIntl = (0, (0, parcelRequire("aWse0")).useIntlV2), useNavigationItemAnalytics: useNavigationItemAnalytics = (0, (0, parcelRequire("iPj86")).useNavigationItemAnalytics), useIsAnonymous: useIsAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous) })=>{
        const destinationParam = useDestinationParam();
        const isAnonymous = useIsAnonymous();
        const { formatMessage: formatMessage } = useIntl();
        const triggerAnalytics = useNavigationItemAnalytics({
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).SIGN_IN
        });
        if (!isAnonymous) return null;
        if ((0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("krXdA")).default), {
            href: `/login.jsp?${destinationParam}`,
            icon: (0, ($parcel$interopDefault((0, parcelRequire("pkYLA"))))),
            label: formatMessage((0, (0, parcelRequire("avqpn")).default).signIn),
            isTooltipDisabled: false,
            appearance: "subtle",
            onClick: triggerAnalytics
        });
        if ((0, (0, parcelRequire("4R6GH")).fg)('blu-4155-enghealth-10869-anonymous-signin')) return (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            role: "listitem"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("krXdA")).default), {
            href: `/login.jsp?${destinationParam}`,
            icon: (0, ($parcel$interopDefault((0, parcelRequire("pkYLA"))))),
            label: formatMessage((0, (0, parcelRequire("avqpn")).default).signIn),
            isTooltipDisabled: false,
            appearance: "subtle",
            onClick: triggerAnalytics
        }));
        return ((0, ($parcel$interopDefault($5uXOq))).createElement(Link, {
            href: `/login.jsp?${destinationParam}`,
            onClick: triggerAnalytics
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(AkSignIn, {
            tooltip: formatMessage((0, (0, parcelRequire("avqpn")).default).signIn)
        })));
    };
    const $ecced68b9b5eee26$export$b64708c6f1b3ed96 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).SIGN_IN
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ecced68b9b5eee26$var$SignIn, props));
});
parcelRegister("avqpn", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$04389e942ceaefeb$export$2e2bcd8739ae039);
    var $7VHMR;
    var $04389e942ceaefeb$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        signIn: {
            "id": "atlassian-navigation.sign-in.sign-in",
            "defaultMessage": "Sign in"
        }
    });
});
parcelRegister("jZF14", function(module, exports) {
    $parcel$export(module.exports, "useDestinationParam", ()=>$80954d3accb3f049$export$c9c176e0725165cc);
    var $j0xEB;
    const $80954d3accb3f049$export$c9c176e0725165cc = ()=>{
        const { href: href = null } = (0, (0, parcelRequire("j0xEB")).default)() || {};
        if (href && !href.includes('login.jsp')) {
            const encodedRedirectUrl = encodeURIComponent(href);
            return `os_destination=${encodedRedirectUrl}`;
        }
        return '';
    };
});
parcelRegister("lbp13", function(module, exports) {
    $parcel$export(module.exports, "YourWorkDropdown", ()=>$4aa4f1efd8bce12b$export$3f0b217fc9bdcb2b);
    var $5uXOq = parcelRequire("5uXOq");
    var $3zm3p;
    var $co1wz;
    var $koVbs;
    var $lmyls;
    var $koeKL;
    var $jFpmD;
    var $Mp6qH;
    var $4uXxK;
    var $4XiFK;
    var $ebYJ0;
    var $e8iSm;
    var $bJsBT;
    var $fFuPf;
    const $4aa4f1efd8bce12b$var$YourWorkDropdown = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const activityClient = (0, (0, parcelRequire("jFpmD")).useActivityClient)('v3', 'jira');
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        if ((0, (0, parcelRequire("co1wz")).ff)('alerts-in-your-work_9l8fa')) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3zm3p")).AlertsStoreContainer), {
            cloudId: cloudId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("e8iSm")).RecentActivityContainer), {
            activityClient: activityClient,
            cloudId: cloudId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4XiFK")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ebYJ0")).Popup), {
            menuId: (0, (0, parcelRequire("lmyls")).MENU_ID).HOME,
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).HOME,
            Menu: (0, (0, parcelRequire("bJsBT")).AsyncMenu),
            label: formatMessage((0, (0, parcelRequire("fFuPf")).default).title)
        }, formatMessage((0, (0, parcelRequire("fFuPf")).default).title)))));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("e8iSm")).RecentActivityContainer), {
            activityClient: activityClient,
            cloudId: cloudId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4XiFK")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ebYJ0")).Popup), {
            menuId: (0, (0, parcelRequire("lmyls")).MENU_ID).HOME,
            navigationItemId: (0, (0, parcelRequire("Mp6qH")).NAVIGATION_ITEM_ID).HOME,
            Menu: (0, (0, parcelRequire("bJsBT")).AsyncMenu)
        }, formatMessage((0, (0, parcelRequire("fFuPf")).default).title))));
    };
    const $4aa4f1efd8bce12b$export$3f0b217fc9bdcb2b = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uXxK")).TopLevelErrorBoundary), {
            id: (0, (0, parcelRequire("lmyls")).MENU_ID).HOME
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($4aa4f1efd8bce12b$var$YourWorkDropdown, null));
});
parcelRegister("3zm3p", function(module, exports) {
    $parcel$export(module.exports, "useAlertsStore", ()=>$d7740626dfebecb7$export$52a528b1f45cfa42);
    $parcel$export(module.exports, "AlertsStoreContainer", ()=>$d7740626dfebecb7$export$dac8df4ae2328540);
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    var $DWjzc;
    var $aT1mD;
    const $d7740626dfebecb7$export$a2290b0841306a43 = {
        items: null,
        isFetching: false,
        fetchError: null,
        promise: null,
        hasFetchedOnce: false,
        hasSuccessOnce: false,
        totalCount: 0
    };
    const $d7740626dfebecb7$export$d4c72bab9d6cc13a = {};
    const $d7740626dfebecb7$var$loadAlerts = ({ searchParams: searchParams, key: key, shouldOverwrite: shouldOverwrite = true })=>async ({ setState: setState, getState: getState }, { cloudId: cloudId })=>{
            if (!cloudId) throw new Error('`cloudId` is required please wrap your component with `AlertsStoreContainer`, and provide the prop.');
            if (!key) throw new Error('`key` is required please provie a unique key for each query.');
            try {
                const promise = (0, (0, parcelRequire("aT1mD")).fetchAlerts)({
                    cloudId: cloudId,
                    searchParams: searchParams
                });
                setState({
                    ...getState(),
                    [key]: {
                        ...$d7740626dfebecb7$export$a2290b0841306a43,
                        ...getState()[key],
                        isFetching: true,
                        fetchError: null,
                        promise: promise
                    }
                });
                const response = await promise;
                const alerts = response?.values ?? null;
                const newItems = shouldOverwrite ? alerts : [
                    ...getState()[key]?.items ?? [],
                    ...alerts
                ];
                setState({
                    ...getState(),
                    [key]: {
                        ...getState()[key],
                        items: newItems,
                        totalCount: newItems.length,
                        hasSuccessOnce: true,
                        hasFetchedOnce: true,
                        isFetching: false
                    }
                });
            } catch (error) {
                setState({
                    ...getState(),
                    [key]: {
                        ...$d7740626dfebecb7$export$a2290b0841306a43,
                        ...getState()[key],
                        fetchError: error,
                        hasFetchedOnce: true,
                        isFetching: false
                    }
                });
                throw error;
            }
        };
    const $d7740626dfebecb7$var$removeAlert = ({ alertId: alertId, key: key })=>async ({ setState: setState, getState: getState })=>{
            setState({
                ...getState(),
                [key]: {
                    ...$d7740626dfebecb7$export$a2290b0841306a43,
                    ...getState()[key],
                    items: getState()[key]?.items?.filter((alert)=>alert.id !== alertId) ?? []
                }
            });
        };
    const $d7740626dfebecb7$export$e324594224ef24da = {
        loadAlerts: $d7740626dfebecb7$var$loadAlerts,
        removeAlert: $d7740626dfebecb7$var$removeAlert
    };
    const $d7740626dfebecb7$var$alertsStore = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: (0, (0, parcelRequire("DWjzc")).ALERTS_STORE_NAME),
        actions: $d7740626dfebecb7$export$e324594224ef24da,
        initialState: $d7740626dfebecb7$export$d4c72bab9d6cc13a
    });
    const $d7740626dfebecb7$export$52a528b1f45cfa42 = (0, (0, parcelRequire("3sQ5x")).createHook)($d7740626dfebecb7$var$alertsStore, {
        selector: (state)=>state
    });
    const $d7740626dfebecb7$export$dac8df4ae2328540 = (0, (0, parcelRequire("gg7kZ")).createContainer)($d7740626dfebecb7$var$alertsStore);
});
parcelRegister("DWjzc", function(module, exports) {
    $parcel$export(module.exports, "ALERTS_STORE_NAME", ()=>$44a8ac0f9ca87731$export$6793992c0b67d9c9);
    $parcel$export(module.exports, "PredefinedQueries", ()=>$44a8ac0f9ca87731$export$ed6721f2880ce367);
    const $44a8ac0f9ca87731$export$6793992c0b67d9c9 = 'navigation-alerts-store';
    const $44a8ac0f9ca87731$var$ONLY_MY_SELECTOR_QUERY = '(acknowledgedBy: me OR owner: me OR responders: me)';
    const $44a8ac0f9ca87731$export$ed6721f2880ce367 = {
        MY_OPEN_ALERTS: `status: open AND acknowledged: false AND snoozed: false AND ${$44a8ac0f9ca87731$var$ONLY_MY_SELECTOR_QUERY}`,
        MY_ACKED_ALERTS: `status: open AND acknowledged: true AND ${$44a8ac0f9ca87731$var$ONLY_MY_SELECTOR_QUERY}`,
        MY_SNOOZED_ALERTS: `status: open AND acknowledged: false AND snoozed: true AND ${$44a8ac0f9ca87731$var$ONLY_MY_SELECTOR_QUERY}`
    };
});
parcelRegister("aT1mD", function(module, exports) {
    $parcel$export(module.exports, "fetchAlerts", ()=>$523404c6486132da$export$f7b1e2075b3da718);
    var $jcw0u;
    var $7f4tk;
    var $g9E9n;
    const $523404c6486132da$export$f7b1e2075b3da718 = async ({ cloudId: cloudId, searchParams: searchParams })=>{
        try {
            const params = new URLSearchParams(searchParams ?? {});
            const result = await (0, (0, parcelRequire("7f4tk")).default)(`/gateway/api/jsm/ops/web/${cloudId}/v1/alerts/?${params.toString()}`);
            if (!result) throw Error('Could not retrieve alerts');
            (0, (0, parcelRequire("g9E9n")).getAnalyticsWebClient)().getInstance().sendOperationalEvent({
                action: 'succeeded',
                actionSubject: 'jiraPlatformServicesAlertsStore.fetchAlerts',
                source: 'alertsStore'
            });
            return result;
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: 'fetchAlerts',
                    packageName: 'jiraAlertsStore',
                    teamName: 'alexstrasza'
                },
                attributes: {
                    error: error,
                    searchParams: searchParams
                },
                error: error
            });
            throw error;
        }
    };
});
parcelRegister("e8iSm", function(module, exports) {
    $parcel$export(module.exports, "RecentActivityContainer", ()=>$468ae279ad84e880$export$7143acbf492040ba);
    $parcel$export(module.exports, "useRecentActivities", ()=>$468ae279ad84e880$export$623d449f25492804);
    $parcel$export(module.exports, "useRecentActivitiesActions", ()=>$468ae279ad84e880$export$394af696e502ba5d);
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    var $8PtXb;
    const $468ae279ad84e880$var$initialState = {
        items: null,
        fetchError: null,
        hasSuccessOnce: false,
        isFetching: false,
        promise: null
    };
    const $468ae279ad84e880$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        actions: (0, parcelRequire("8PtXb")).actions,
        initialState: $468ae279ad84e880$var$initialState,
        name: 'navigation-your-work-recent-activites'
    });
    const $468ae279ad84e880$export$7143acbf492040ba = (0, (0, parcelRequire("gg7kZ")).createContainer)($468ae279ad84e880$var$store);
    const $468ae279ad84e880$export$623d449f25492804 = (0, (0, parcelRequire("3sQ5x")).createHook)($468ae279ad84e880$var$store);
    const $468ae279ad84e880$export$394af696e502ba5d = (0, (0, parcelRequire("3sQ5x")).createHook)($468ae279ad84e880$var$store, {
        selector: null
    });
});
parcelRegister("8PtXb", function(module, exports) {
    $parcel$export(module.exports, "actions", ()=>$b1f4ac60f5b38eb1$export$e324594224ef24da);
    var $4R6GH;
    const $b1f4ac60f5b38eb1$var$PRODUCTS = [
        'jira'
    ];
    const $b1f4ac60f5b38eb1$var$loadRecentActivities = ()=>async ({ setState: setState }, meta)=>{
            setState({
                isFetching: true,
                fetchError: null
            });
            try {
                const filter = (0, (0, parcelRequire("4R6GH")).fg)('blu-3344-worked-on-menu-fix') ? {
                    limit: 10,
                    rootContainerIds: [
                        `ari:cloud:platform::site/${meta.cloudId}`
                    ],
                    products: $b1f4ac60f5b38eb1$var$PRODUCTS
                } : {
                    limit: 10,
                    rootContainerIds: [
                        `ari:cloud:platform::site/${meta.cloudId}`
                    ],
                    objectTypes: [
                        'issue'
                    ],
                    products: $b1f4ac60f5b38eb1$var$PRODUCTS
                };
                const promise = meta.activityClient.fetchActivities([
                    50,
                    125,
                    250
                ], filter, [
                    'workedOn'
                ]);
                setState({
                    promise: promise
                });
                const activityItems = await promise;
                setState({
                    hasSuccessOnce: true,
                    items: activityItems,
                    isFetching: false
                });
            } catch (error) {
                setState({
                    isFetching: false,
                    fetchError: error
                });
            }
        };
    const $b1f4ac60f5b38eb1$export$e324594224ef24da = {
        loadRecentActivities: $b1f4ac60f5b38eb1$var$loadRecentActivities
    };
});
parcelRegister("bJsBT", function(module, exports) {
    $parcel$export(module.exports, "AsyncMenu", ()=>$6ff062438cc4c15e$export$81adf29ff528dc9c);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $lfTZh;
    var $kxZPA;
    var $Mp6qH;
    const $6ff062438cc4c15e$var$LazyMenu = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("gNJ9B")).then(({ Menu: Menu })=>Menu), {
        moduleId: "./src/packages/navigation-apps/atlassian-navigation/src/ui/your-work-dropdown/menu/index.tsx"
    });
    const $6ff062438cc4c15e$export$81adf29ff528dc9c = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "your-work-dropdown-menu",
            packageName: (0, (0, parcelRequire("Mp6qH")).PACKAGE_NAME),
            teamName: "navigation",
            fallback: "flag"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "your-work-dropdown-menu",
            fallback: null
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($6ff062438cc4c15e$var$LazyMenu, null)));
});
parcelRegister("gNJ9B", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("hPzOn"),
        $6fhBS("fzehJ")
    ]).then(()=>parcelRequire('j5yhG'));
});
parcelRegister("fFuPf", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$29c95956b90fd313$export$2e2bcd8739ae039);
    var $7VHMR;
    var $29c95956b90fd313$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "atlassian-navigation.your-work-dropdown.title",
            "defaultMessage": "Your work"
        }
    });
});
const $4cff3ad70ea80f75$export$6c8cb487d6ffc522 = 'QueryError';
const $4cff3ad70ea80f75$export$b171488d52070cb3 = 'JiraIssueFieldUnsupportedErrorExtension';
const $4cff3ad70ea80f75$export$14b50ab9fbf2d370 = 'JiraIssueFieldValueValidationErrorExtension';
const $4cff3ad70ea80f75$export$3d1cda9bd50505e8 = 'SERVICE_REQUEST';
const $4cff3ad70ea80f75$export$48c144830383423 = 'INCIDENT_MANAGEMENT';
const $4cff3ad70ea80f75$export$1ea6ead7bcee4494 = 'PROBLEM_MANAGEMENT';
const $4cff3ad70ea80f75$export$b92ccb6dc3cdb548 = 'CHANGE_MANAGEMENT';
const $4cff3ad70ea80f75$export$a4a741b969f6509c = 'POST_INCIDENT_REVIEW';
const $4cff3ad70ea80f75$export$81e62964cc725098 = 'DEVELOPER_ESCALATION';
const $4cff3ad70ea80f75$export$92ab80c1dc9baf44 = 'REQUEST_TYPE';
const $4cff3ad70ea80f75$export$ebb48b08205007c5 = 'ISSUE_TYPE';
const $4cff3ad70ea80f75$export$c835df30ebbd529d = 'UNASSIGNED_REQUEST_TYPE';
